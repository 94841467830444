import { Stack } from "@mui/material"
import React, { Children } from "react"
import { RenderElementProps } from "slate-react"

import { Chip, ItemLabel } from "~/components"
import { ActionPlanType } from "~/domains/communication/chat/components/InputMessage/ActionPlan/ActionPlanType"
import { useContextMessage } from "~/domains/communication/chat/components/MessageContext"
import { MessageActionPlanTitle } from "~/domains/communication/chat/types"
import { stopPropagation } from "~/utils"

type Props = Omit<RenderElementProps, "element"> & { element: MessageActionPlanTitle }

export const ActionPlanTitleElement: React.FC<Props> = ({ attributes, element, children }) => {
    const message = useContextMessage()
    return (
        <Stack direction="row" component="li" marginTop={1} marginBottom={-1} {...attributes}>
            <Stack flexGrow={1}>
                <ItemLabel>{children}</ItemLabel>
            </Stack>
            <Stack contentEditable={false} onClick={stopPropagation}>
                {message ? (
                    element.data?.type && <Chip size="small">{element.data.type}</Chip>
                ) : (
                    <ActionPlanType element={element} />
                )}
            </Stack>
        </Stack>
    )
}
