import { useCallback } from "react"

import { DataGridNoRows } from "~/components/DataGrid/DataGridNoRows"
import { catalogActions } from "~/domains/transactions/catalog/store/catalogSlice"
import { useAppDispatch } from "~/store/hooks"

export const ProductsSearchReset = () => {
    const dispatch = useAppDispatch()

    const resetSearchQuery = useCallback(() => {
        dispatch(catalogActions.setSearchQuery({ query: null }))
    }, [dispatch])

    return <DataGridNoRows resetSearchQuery={resetSearchQuery} />
}
