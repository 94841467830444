import * as t from "io-ts"

import { MessageIO } from "~/domains/communication/chat/api/adapters/messageFromApiAdapter"
import { OrganizationId } from "~/types"
import { Opaque } from "~/utils"
import { optional } from "~/utils/io-ts"

import { MessageI } from "./Message"

export type RoomId = Opaque<string, { readonly T: unique symbol }>

const RoomInvolvedOrganizationIO = t.partial({
    organizationId: optional(t.string),
})

export const RoomIO = t.intersection([
    t.type({
        id: t.string,
        isShared: t.boolean,
    }),
    t.partial({
        pinnedMessages: optional(t.array(MessageIO)),
        name: optional(t.string),
        lastMessages: optional(t.array(MessageIO)),
        involvedOrganization: optional(t.array(RoomInvolvedOrganizationIO)),
    }),
])

export type RoomInvolvedOrganizationI = t.TypeOf<typeof RoomInvolvedOrganizationIO> & { organizationId: OrganizationId }
export type RoomI = t.TypeOf<typeof RoomIO> & {
    id: RoomId
    involvedOrganization: RoomInvolvedOrganizationI[]
    pinnedMessages: MessageI[]
}
