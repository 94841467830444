import { useCallback, useState } from "react"

import { NewUploadOCRResultI } from "~/domains/_shared/ocr/api/ocrApi"
import { ocrApi } from "~/domains/_shared/ocr/api/ocrApi"
import { useAppDispatch } from "~/store/hooks"
import { ocrActions } from "~/store/ocr/ocrSlice"
import { InvoiceUserType, OrganizationId } from "~/types"

export const useUploadOCRDocument = (initiator: InvoiceUserType, organizationId?: OrganizationId) => {
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)

    const uploadOCRDocument = useCallback(
        async (file: File): Promise<NewUploadOCRResultI | undefined> => {
            setLoading(true)

            try {
                dispatch(ocrActions.newUpload())
                const response = await ocrApi.newUploadInvoice(file, initiator, organizationId)

                dispatch(ocrActions.newUploadSuccess(response.id))

                return response
            } catch (error) {
                dispatch(ocrActions.newUploadFailed())
            } finally {
                setLoading(false)
            }
        },
        [dispatch, initiator, organizationId]
    )

    return { uploadOCRDocument, loading }
}
