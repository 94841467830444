import React from "react"
import { IntlProvider, MessageFormatElement } from "react-intl"

import { selectLang } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

interface LocalesProviderProps extends React.PropsWithChildren {
    messages: Record<string, Record<string, string> | Record<string, MessageFormatElement[]> | undefined>
    locale: string
}

export const LocalesProvider = ({ messages, locale, children }: LocalesProviderProps) => {
    const lang = useAppSelector(selectLang)

    return (
        <IntlProvider messages={messages[lang]} locale={lang} defaultLocale={locale}>
            {children}
        </IntlProvider>
    )
}
