import { HandleProps, Position } from "@xyflow/react"
import type { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { Users } from "react-feather"

import { FetchPartnershipNode as FetchPartnershipNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

export const FetchPartnershipNode: FC<NodeProps<NodeType<FetchPartnershipNodeType>>> = ({ data, selected }) => {
    const handles: HandleProps[] = [
        {
            type: "target",
            position: Position.Top,
        },
        {
            type: "source",
            position: Position.Bottom,
            id: `${data.slug}-source`,
        },
    ]

    const error = !data.partnerId

    return (
        <Node
            type={data.type}
            name={data.name}
            icon={Users}
            handles={handles}
            selected={selected}
            slug={data.slug}
            error={error}
        />
    )
}
