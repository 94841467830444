import { Avatar, Grid, Stack } from "@mui/material"
import { KeyboardEvent, useEffect, useRef, useState } from "react"
import { Command } from "react-feather"
import { defineMessage, useIntl } from "react-intl"
import { Navigate } from "react-router-dom"

import { CenterViewport, HeaderH1, Loader } from "~/components"
import "~/components/UploadDocument/UploadDocument/UploadDocument.scss"
import { useCreateMessageMutation, useCreateThreadMutation } from "~/domains/orchestration/copilot/api/copilotApi"
import { CopilotCompletion } from "~/domains/orchestration/copilot/components/CopilotCompletion"
import { CopilotMessage } from "~/domains/orchestration/copilot/components/CopilotMessage"
import { CopilotPromptBar } from "~/domains/orchestration/copilot/components/CopilotPromptBar"
import { UserMessage } from "~/domains/orchestration/copilot/components/UserMessage"
import { Conversation, From, MessageResult } from "~/domains/orchestration/copilot/types/Copilot"
import { DASHBOARD_ROUTE } from "~/routes/routes"
import { selectUser } from "~/store/account/accountSlice"
import { selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

import "./Prompt.scss"

const title = defineMessage({
    id: "domains.copilot.prompt.pages.Prompt.title",
    defaultMessage: "Flowie Copilot",
})

const FlowieCopilotIcon = () => (
    <Avatar sx={{ bgcolor: "transparent", border: "1px solid var(--color-grey-light)", width: 32, height: 32 }}>
        <Command size={16} color="var(--color-grey)" />
    </Avatar>
)

export function Prompt() {
    const { formatMessage, locale } = useIntl()
    const textToSpeechEnabled = useRef(false)

    const displayMenu = useAppSelector(selectKeepSidebarOpened)
    const user = useAppSelector(selectUser)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const [createThread, { isLoading: threadLoading, data: createThreadData }] = useCreateThreadMutation()
    const [createMessage, { isLoading: messageLoading, data: createMessageData }] = useCreateMessageMutation()

    const [message, setMessage] = useState("")
    const [file, setFile] = useState<File | undefined>(undefined)
    const [completion, setCompletion] = useState<MessageResult["completion"]>()
    const [conversation, setConversation] = useState<Conversation[]>([])
    const [threadId, setThreadId] = useState("")

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight)
    }, [conversation])

    useEffect(() => {
        if (!currentOrganizationId || !user?.id || !!threadId) return

        createThread({ organizationId: currentOrganizationId, userId: user.id })
    }, [currentOrganizationId, user, threadId, createThread])

    useEffect(() => {
        if (!createThreadData) return

        setThreadId(createThreadData.id)
    }, [createThreadData])

    useEffect(() => {
        if (!createMessageData) return

        setCompletion(createMessageData.completion)
        setConversation((prev) => [
            ...prev,
            { from: From.COPILOT, message: createMessageData.message, autoTextToSpeech: textToSpeechEnabled.current },
        ])
    }, [createMessageData, locale])

    const setTextToSpeechEanbled = (newValue: boolean) => {
        textToSpeechEnabled.current = newValue
    }

    const handleSendMessage = async () => {
        if (!message || !threadId) return
        setConversation((prev) => [...prev, { from: From.USER, message }])

        await createMessage({ threadId, userId: user.id, message, file })

        setMessage("")
        setFile(undefined)
    }

    const handlePressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === "Enter") {
            event.stopPropagation()
            event.preventDefault()
            handleSendMessage()
            return false
        }
    }

    if (currentOrganizationId && !isFeatureEnabled(Features.ChatBot, currentOrganizationId)) {
        return <Navigate to={DASHBOARD_ROUTE} />
    }

    return (
        <div className="copilot-page">
            <HeaderH1 title={formatMessage(title)} icon={<Command />} />
            <Grid container justifyContent="center">
                {threadLoading ? (
                    <CenterViewport>
                        <Loader />
                    </CenterViewport>
                ) : (
                    <>
                        <Stack direction="row" gap={6} className="conversation-container">
                            <Stack gap={4} alignItems="center" className="messages-container">
                                {conversation.map((conversationMessage, key) => {
                                    if (conversationMessage.from === From.USER) {
                                        return <UserMessage conversationMessage={conversationMessage} key={key} />
                                    }
                                    return <CopilotMessage conversationMessage={conversationMessage} key={key} />
                                })}
                                {messageLoading ? (
                                    <Stack direction="row" alignItems="center" gap={2} className="message-flowie">
                                        <FlowieCopilotIcon />
                                        <Loader small />
                                    </Stack>
                                ) : null}
                            </Stack>
                            <CopilotCompletion completion={completion} />
                        </Stack>

                        <CopilotPromptBar
                            displayMenu={displayMenu}
                            file={file}
                            conversation={conversation}
                            message={message}
                            handleUpload={setFile}
                            handleChangeMessage={setMessage}
                            handlePressEnter={handlePressEnter}
                            handleSendMessage={handleSendMessage}
                            setTextToSpeechEnabled={setTextToSpeechEanbled}
                        />
                    </>
                )}
            </Grid>
        </div>
    )
}
