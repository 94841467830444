import { MessageResult } from "~/domains/orchestration/copilot/types/Copilot"

export const copilotMessageFromApiAdapter = (data: any): MessageResult => {
    return {
        message: data.message,
        completion: data.completion,
        status: data.status,
        filled_json: data.filled_json,
    }
}
