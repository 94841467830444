import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useState } from "react"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, UpdateTripletexLedgerNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const UpdateTripletexLedgerConfiguration: FC<ConfigurationProps<UpdateTripletexLedgerNode>> = ({
    selectedNode,
    advancedFields,
}) => {
    const { formatMessage } = useIntl()

    const [currentNode, setCurrentNode] = useState(selectedNode)

    const handleChange =
        (key: keyof UpdateTripletexLedgerNode) =>
        (e: ChangeEvent<HTMLInputElement>): void => {
            const valueToSet = e.target.value
            setCurrentNode({
                ...currentNode,
                [key]: valueToSet,
            })
        }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <Stack gap={3} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.updateTripletexLedgerConfiguration.title} />

                <TextField
                    label={formatMessage(messages.updateTripletexLedgerConfiguration.accountToCreditLabel)}
                    value={currentNode.accountToCredit}
                    onChange={handleChange("accountToCredit")}
                />

                <TextField
                    label={formatMessage(messages.updateTripletexLedgerConfiguration.accountToDebitLabel)}
                    value={currentNode.accountToDebit}
                    onChange={handleChange("accountToDebit")}
                />

                <TextField
                    label={formatMessage(messages.updateTripletexLedgerConfiguration.ledgerDescriptionLabel)}
                    value={currentNode.ledgerDescription}
                    onChange={handleChange("ledgerDescription")}
                />
            </Stack>

            <AdvancedFields<UpdateTripletexLedgerNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
