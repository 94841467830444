import { DocumentRelationKpiAmount } from "~/domains/transactions/_shared/document-relations/types/DocumentRelation"

export const documentsDifferencesKPIFromApiAdapter = (kpi: DocumentRelationKpiAmount): DocumentRelationKpiAmount => {
    return {
        amount: kpi.amount,
        filled: kpi.filled,
        remaining: kpi.remaining,
        fillRate: kpi.fillRate,
    }
}
