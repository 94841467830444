import { Stack, Tooltip } from "@mui/material"
import { FC } from "react"
import { FormattedNumber, defineMessages, useIntl } from "react-intl"

import { Card, DataTable, DataTableColumn, StatusChip } from "~/components"
import { TagObjectType } from "~/domains/analytics/tags/types"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import { appendInvoiceCustomFieldsToLines } from "~/domains/transactions/invoices-v1/utils/appendInvoiceCustomFieldsToLines"
import { getTemplateLineCustomFields } from "~/domains/transactions/invoices-v1/utils/getTemplateLineCustomFields"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { InvoiceI, InvoiceLineI } from "~/types"

import { LineTags } from "../../../_shared/components/Items/LineTags"

const messages = defineMessages({
    items: {
        id: "purchase.requests.request.items",
        defaultMessage: "Items",
    },
    editItem: {
        id: "purchase.requests.request.items.edit",
        defaultMessage: "Edit items",
    },
    addItem: {
        id: "purchase.requests.request.items.addItem",
        defaultMessage: "Add new items",
    },
    description: {
        id: "purchase.requests.request.items.description",
        defaultMessage: "Description",
    },
    quantity: {
        id: "purchase.requests.request.items.quantity",
        defaultMessage: "Qty",
    },
    taxRate: {
        id: "purchase.requests.request.items.taxRate",
        defaultMessage: "Tax",
    },
    priceHT: {
        id: "purchase.requests.request.items.priceHT",
        defaultMessage: "Price (tax excl)",
    },
    priceTTC: {
        id: "purchase.requests.request.items.priceTTC",
        defaultMessage: "Price (tax incl)",
    },
    actions: {
        id: "purchase.requests.request.items.actions",
        defaultMessage: "Actions",
    },
    total: {
        id: "purchase.requests.request.items.total",
        defaultMessage: "Total",
    },
    discountRate: {
        id: "purchase.requests.request.items.discountRate",
        defaultMessage: "Discount",
    },
    approval: {
        id: "purchase.requests.request.items.approval",
        defaultMessage: "Approval status",
    },
})

interface ItemsInvoiceProps {
    invoice: InvoiceI
    mode: PurchaseViewType
}

export const ItemsInvoice: FC<ItemsInvoiceProps> = ({ invoice, mode }) => {
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const totalPriceWithoutTax = invoice.totalPriceWithoutTax
    const totalAmountDue = invoice.totalAmountDue

    const linesWithCustomFields = appendInvoiceCustomFieldsToLines(invoice, organizationId || "")

    const templateLineCustomFields = getTemplateLineCustomFields(invoice, organizationId || "")

    const getColumns = (): DataTableColumn<InvoiceLineI>[] => {
        const customFieldColumns = (templateLineCustomFields || []).map(({ id, name, description }) => ({
            id: `customField_${id}`,
            key: `customField_${id}`,
            label: name || "-",
            renderCell: () => {
                const customField = linesWithCustomFields[0]?.customFields?.[id]

                return (
                    <Tooltip title={description || ""}>
                        <span>{customField ? customField.toString() : "-"}</span>
                    </Tooltip>
                )
            },
        }))

        return [
            {
                id: "description",
                key: "description",
                style: {
                    maxWidth: 130,
                },
                label: formatMessage(messages.description),
                sorter: true,
                renderCell: (params: InvoiceLineI) => {
                    const description = params?.description || ""
                    return (
                        <Tooltip title={description}>
                            <span className="truncate-text item-description">{description}</span>
                        </Tooltip>
                    )
                },
                aggregationFunction: () => formatMessage(messages.total),
            },
            ...customFieldColumns,
            {
                id: "quantity",
                key: "quantity",
                label: formatMessage(messages.quantity),
                sorter: true,
            },
            {
                id: "taxRate",
                key: "tax",
                label: formatMessage(messages.taxRate),
                sorter: true,
                renderCell: (params: InvoiceLineI) => (+(params?.tax || 1) / 100).toFixed(1) + "%",
            },
            {
                id: "totalAmountExcludingTax",
                key: "totalExcludedTaxes",
                label: formatMessage(messages.priceHT),
                sorter: true,
                renderCell: (params: InvoiceLineI) => (
                    <FormattedNumber value={+params?.priceWithoutTax} currency={params?.currency} style="currency" />
                ),
                aggregationFunction: () => (
                    <Stack>
                        {Object.entries(totalPriceWithoutTax).map(([currency, amount]) => (
                            <p key={currency}>
                                <FormattedNumber value={+amount} currency={currency} style="currency" />
                            </p>
                        ))}
                    </Stack>
                ),
            },
            {
                id: "totalAmount",
                key: "total",
                label: formatMessage(messages.priceTTC),
                sorter: true,
                renderCell: (params: InvoiceLineI) => (
                    <FormattedNumber value={+params?.priceWithoutTax} currency={params?.currency} style="currency" />
                ),
                aggregationFunction: () => (
                    <Stack>
                        {Object.entries(totalAmountDue).map(([currency, amount]) => (
                            <p key={currency}>
                                <FormattedNumber value={+amount} currency={currency} style="currency" />
                            </p>
                        ))}
                    </Stack>
                ),
            },
            {
                id: "discountRate",
                key: "discountRate",
                label: formatMessage(messages.discountRate),
                sorter: true,
            },
            {
                id: "approvalStatus",
                key: "approvalStatus",
                label: formatMessage(messages.approval),
                sorter: true,
                renderCell: (params: InvoiceLineI) => {
                    const approvalStatus = params?.approvalStatus

                    return !!approvalStatus && <StatusChip status={approvalStatus}>{approvalStatus}</StatusChip>
                },
            },
            {
                id: "tags",
                key: "tags",
                label: "tags",
                renderCell: (line: InvoiceLineI) => (
                    <LineTags
                        organizationId={organizationId || ""}
                        line={line}
                        objectId={line.id}
                        objectType={TagObjectType.PURCHASE_REQUEST_LINE}
                    />
                ),
            },
        ]
    }

    const columns = getColumns()

    return (
        <Card className={"items " + (mode === PurchaseViewType.EDIT ? "editable" : "")}>
            {Object.values(linesWithCustomFields).length ? (
                <DataTable
                    classNames="purchase-requests-table"
                    hidePagination={true}
                    data={Object.values(linesWithCustomFields) as unknown as InvoiceLineI[]}
                    withAggregatedFooter
                    columns={columns}
                />
            ) : null}
        </Card>
    )
}
