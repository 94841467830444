import { Stack } from "@mui/material"
import { FC } from "react"

import { Card } from "~/components"
import { Conversation } from "~/domains/orchestration/copilot/types/Copilot"

interface UserMessageProps {
    conversationMessage: Conversation
}

export const UserMessage: FC<UserMessageProps> = ({ conversationMessage }) => (
    <Stack direction="row" alignSelf="flex-end" justifyContent="flex-end" gap={2} className="message-user">
        <Card sx={{ paddingBlock: 1 }}>
            <p>{conversationMessage.message}</p>
        </Card>
    </Stack>
)
