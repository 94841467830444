import { Stack, Tooltip } from "@mui/material"
import { FC } from "react"
import { useIntl } from "react-intl"

import { Card } from "~/components"
import { FlowCardHeader } from "~/domains/orchestration/flows/components/FlowCardHeader"
import { messages } from "~/domains/orchestration/flows/locale"
import { FlowItem } from "~/domains/orchestration/flows/types"

import "./FlowCard.scss"

interface Props {
    flow: FlowItem
}

export const FlowCard: FC<Props> = ({ flow }) => {
    const { version, enabled } = flow
    const { formatMessage } = useIntl()

    return (
        <Card header={<FlowCardHeader flow={flow} />} className="flows-list-flowCard">
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
                <p className="flows-list-flowCard-version">version : {version}</p>
                <Tooltip title={formatMessage(enabled ? messages.common.enabled : messages.common.disabled)}>
                    <span className={`flows-list-flowCard-dot-${enabled ? "enabled" : "disabled"}`} />
                </Tooltip>
            </Stack>
        </Card>
    )
}
