import {
    OrganizationPermission,
    PermissionId,
    RoleOrTemplate,
    RoleTemplate,
    RoleWithEntityIds,
} from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { SharedObjectWithName } from "~/types/SharedObjects"

export interface PendingInvitation {
    email: string
    name: string
}

export interface RolesPermissionsState {
    loading: boolean
    error: string | null
    usersLoading: boolean
    usersError: string | null
    organizationId: string | null
    roleWithEntityIds: RoleWithEntityIds[]
    roleTemplates: RoleTemplate[]
    selectedRole: RoleOrTemplate | null
    permissions: PermissionId[]
    jwtPermissions: OrganizationPermission[]
    shares: SharedObjectWithName[]
    sharesLoading: boolean
    sharesError: string | null
    pendingTeams: string[]
    pendingMembers: string[]
    pendingInvitations: PendingInvitation[]
}

export const rolesPermissionsState: RolesPermissionsState = {
    loading: false,
    error: null,
    usersLoading: false,
    usersError: null,
    organizationId: null,
    roleWithEntityIds: [],
    roleTemplates: [],
    selectedRole: null,
    permissions: [],
    jwtPermissions: [],
    shares: [],
    sharesLoading: false,
    sharesError: null,
    pendingTeams: [],
    pendingMembers: [],
    pendingInvitations: [],
}
