import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import {
    AuthorizationFirstLetter,
    DomainName,
    DomainShorthand,
    ScopeName,
    ScopeShorthand,
} from "~/domains/identity/roles-permissions/types/RolesPermissions"
import {
    getPermissionIdString,
    permissionMessages,
    scopeMessages,
} from "~/domains/identity/roles-permissions/utils/permissions"
import { useAppSelector } from "~/store/hooks"
import { AuthorizationName, AuthorizationNameExtra } from "~/types"
import { isDefined } from "~/utils/isDefined"

import { selectJwtPermissions } from "../rolesPermissionsSlice"

const findPermission = (permission: string, permissions: string[]): boolean => {
    return !!permissions.find((perm) => perm === permission)
}

const decodePermission = (shorthandAuthorization: string, domain: string, scope: string): string => {
    return `${AuthorizationFirstLetter[shorthandAuthorization]}:${
        domain.length <= 3 ? DomainShorthand[domain] : domain
    }:${scope.length <= 3 ? ScopeShorthand[scope] : scope}`
}

const decodePermissions = (encodedPermissions: string[]): string[] => {
    return encodedPermissions.flatMap((encodedPerm) => {
        const [shorthandAuthorization, domain, scope] = encodedPerm.split(":")
        return shorthandAuthorization
            .split("")
            .map((shorthandAuthorization) => decodePermission(shorthandAuthorization, domain, scope))
    })
}

export const useHasPermissions = (
    organizationId: string,
    authorization: AuthorizationName | AuthorizationNameExtra,
    domain: DomainName,
    scope: ScopeName
): { hasPermission: boolean; permissionError: string | null } => {
    const { formatMessage } = useIntl()
    const [hasPermission, setHasPermission] = useState<boolean>(false)
    const [permissionError, setPermissionError] = useState<string | null>(null)

    const userPermissions = useAppSelector(selectJwtPermissions)

    useEffect(() => {
        if (!isDefined(organizationId) || !Object.keys(userPermissions).length) {
            setHasPermission(false)
            setPermissionError("This user has no permission")
            return
        }

        if (!userPermissions[organizationId]) {
            setHasPermission(false)
            setPermissionError("This user has no permission for this organization")
            return
        }

        const organizationPermissions = decodePermissions(userPermissions[organizationId])
        const permission = `${authorization}:${domain}:${scope}`
        const userHasPermission = findPermission(permission, organizationPermissions)
        setHasPermission(userHasPermission)
        if (!userHasPermission) {
            setPermissionError(
                formatMessage(permissionMessages.errorPermission, {
                    permissionString: getPermissionIdString(permission, domain, scope, formatMessage),
                    scope: formatMessage(scopeMessages[scope]).toLowerCase(),
                })
            )
        }
    }, [authorization, domain, scope, userPermissions, organizationId])

    return { hasPermission, permissionError }
}
