import { ButtonProps, Tooltip } from "@mui/material"
import { PropsWithChildren } from "react"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage, TooltipWarning } from "~/components"
import { useHasMandatoryTagGroupIds } from "~/domains/analytics/tags/hooks/useMandatoryTagGroupIds"
import { SelectedTagI, TagObjectType } from "~/domains/analytics/tags/types"

interface SubmitButtonWithMandatoryTagGroupsProps extends PropsWithChildren {
    organizationId: string
    tagObjectType: TagObjectType
    children: React.ReactElement<ButtonProps>
    selectedTags?: SelectedTagI[]
}

export const ButtonWithMandatoryTagGroups: React.FC<SubmitButtonWithMandatoryTagGroupsProps> = ({
    children,
    organizationId,
    tagObjectType,
    selectedTags,
}) => {
    const { hasMandatoryTagGroupIds, missingTagGroups } = useHasMandatoryTagGroupIds(
        tagObjectType,
        organizationId,
        selectedTags
    )

    const button = <children.type {...children.props} disabled={children.props.disabled || !hasMandatoryTagGroupIds} />

    if (hasMandatoryTagGroupIds) {
        return (
            <Tooltip arrow title={<SafeFormattedMessage {...commonMessages.submit} />}>
                <div>{button}</div>
            </Tooltip>
        )
    }

    return (
        <TooltipWarning
            title={
                <SafeFormattedMessage
                    {...commonMessages.missingMandatoryTagGroups}
                    values={{
                        tagGroups: missingTagGroups.map((tagGroup) => tagGroup.name).join(", "),
                        count: missingTagGroups.length,
                    }}
                />
            }
        >
            <div>{button}</div>
        </TooltipWarning>
    )
}
