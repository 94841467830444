import { useHasPermissions } from "~/domains/identity/roles-permissions/store/hooks"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/"
import { AuthorizationName } from "~/types"

export const usePermission = () => {
    const organizationId = useOrganizationId()

    const { hasPermission: hasWorkflowCreatePermission } = useHasPermissions(
        organizationId,
        AuthorizationName.CREATE,
        DomainName.ORCHESTRATION,
        ScopeName.WORKFLOWS
    )

    const { hasPermission: hasWorkflowDeletePermission } = useHasPermissions(
        organizationId,
        AuthorizationName.DELETE,
        DomainName.ORCHESTRATION,
        ScopeName.WORKFLOWS
    )

    const { hasPermission: hasWorkflowUpdatePermission } = useHasPermissions(
        organizationId,
        AuthorizationName.UPDATE,
        DomainName.ORCHESTRATION,
        ScopeName.WORKFLOWS
    )

    return {
        hasWorkflowCreatePermission,
        hasWorkflowDeletePermission,
        hasWorkflowUpdatePermission,
        permissionError: !useOrganizationId,
    }
}
