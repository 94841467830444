import {
    isElementActionPlan,
    isElementActionPlanItem,
    isElementActionPlanTitle,
} from "~/domains/communication/chat/components/InputMessage/ActionPlan"
import { isElementMention } from "~/domains/communication/chat/components/InputMessage/Mentions"
import { ActionPlanCheckListI } from "~/domains/communication/chat/types"
import {
    ActionPlanI,
    ActionPlanStatus,
    CommunicationChannelI,
    MentionInActionPlanI,
} from "~/domains/communication/chat/types/CommunicationChannel"

const isOverdue = (dueDate: string | null) => !!dueDate && new Date(dueDate) < new Date()

const getActionPlanStatus = (data: ActionPlanCheckListI) => {
    const { checked, dueDate } = data
    if (checked) return ActionPlanStatus.COMPLETE
    if (isOverdue(dueDate)) return ActionPlanStatus.OVERDUE
    return ActionPlanStatus.OPEN
}

export const populateActionPlansInCommunicationChannels = (actions: CommunicationChannelI): CommunicationChannelI => {
    const actionPlans = actions.messages.reduce((acc: ActionPlanI[], message) => {
        return message.content.reduce((acc: ActionPlanI[], content) => {
            if (isElementActionPlan(content)) {
                const actionPlanItems = content.children.reduce(
                    (
                        acc: {
                            actionPlanCheckedItems: number
                            actionsPlanItems: number
                            overdueUnchekedItems: number
                            involvedPeople: MentionInActionPlanI[]
                            title: string | null
                            type: string | null
                        },
                        item
                    ) => {
                        if (isElementActionPlanTitle(item)) {
                            return {
                                ...acc,
                                type: item.data.type,
                                title: item.children.map((child) => child.text).join(" "),
                            }
                        }
                        if (isElementActionPlanItem(item)) {
                            const involvedPeople = item.children.reduce(
                                (involvedPeople: MentionInActionPlanI[], child) => {
                                    if (isElementMention(child)) {
                                        involvedPeople.push({
                                            mention: child.data,
                                            status: getActionPlanStatus(item.data),
                                        })
                                    }
                                    return involvedPeople
                                },
                                acc.involvedPeople
                            )
                            if (item.data.checked) {
                                return {
                                    ...acc,
                                    actionPlanCheckedItems: acc.actionPlanCheckedItems + 1,
                                    actionsPlanItems: acc.actionsPlanItems + 1,
                                    overdueUnchekedItems: acc.overdueUnchekedItems,
                                    involvedPeople,
                                }
                            }
                            return {
                                ...acc,
                                actionsPlanItems: acc.actionsPlanItems + 1,
                                overdueUnchekedItems: acc.overdueUnchekedItems + (isOverdue(item.data.dueDate) ? 1 : 0),
                                involvedPeople,
                            }
                        }
                        return acc
                    },
                    {
                        actionPlanCheckedItems: 0,
                        actionsPlanItems: 0,
                        overdueUnchekedItems: 0,
                        involvedPeople: [],
                        type: null,
                        title: null,
                    }
                )
                const status =
                    actionPlanItems.actionPlanCheckedItems === actionPlanItems.actionsPlanItems
                        ? ActionPlanStatus.COMPLETE
                        : actionPlanItems.overdueUnchekedItems > 0
                          ? ActionPlanStatus.OVERDUE
                          : ActionPlanStatus.OPEN

                acc.push({
                    ...actionPlanItems,
                    status,
                })
            }
            return acc
        }, acc)
    }, [])
    return {
        ...actions,
        actionPlans,
    }
}
