import React, { useEffect } from "react"
import { defineMessages, useIntl } from "react-intl"

import { DatatableFilters, DatatableFiltersActions } from "~/components"
import {
    purchaseRequestsActions,
    selectPurchaseRequestFilter,
} from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { SpiceDBPermissionsResult } from "~/types"

const messages = defineMessages({
    newPR: {
        id: "purchase.requests.newPR",
        defaultMessage: "Request a purchase",
    },
})

interface Props {
    actions: DatatableFiltersActions[]
    permissions?: SpiceDBPermissionsResult | null
}

export const Filters: React.FC<Props> = ({ actions, permissions }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const filter = useAppSelector(selectPurchaseRequestFilter)

    const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(purchaseRequestsActions.setFilter(e.target.value))
    }

    useEffect(() => {
        return () => {
            dispatch(purchaseRequestsActions.setFilter(""))
        }
    }, [dispatch])

    return (
        <DatatableFilters
            onChangeFilter={handleChangeFilter}
            valueFilter={filter}
            labelButton={formatMessage(messages.newPR)}
            actionsMenu={true}
            actions={actions}
            hasPermission={permissions?.create ?? false}
        />
    )
}
