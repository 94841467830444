import { Grid } from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { FC, useEffect, useMemo } from "react"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { useGetFlowsQuery } from "~/domains/orchestration/flows/api/flowsApi"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"

import { FlowCard } from "./FlowCard"

interface Props {
    filter: string
}

export const List: FC<Props> = ({ filter }) => {
    // TODO: query params should be dynamic
    const organizationId = useOrganizationId()
    const { data, error, isLoading, refetch } = useGetFlowsQuery(false)
    const { formatMessage } = useIntl()
    const filteredFlows = useMemo(
        () => data?.flows.filter((flow) => flow.name.toLowerCase().includes(filter.toLowerCase())) || [],
        [data, filter]
    )

    useEffect(() => {
        refetch()
    }, [organizationId])

    if (isLoading) {
        return <Loader fullscreen />
    }

    if (error) {
        Sentry.captureException(error)
        toast.error(formatMessage(messages.error.fetchingFlows))
        return null
    }

    if (!data) {
        return null
    }

    return (
        <Grid container spacing={2}>
            {filteredFlows.map((flow) => (
                <Grid item xs={12} sm={6} md={4} key={flow.id}>
                    <FlowCard flow={flow} />
                </Grid>
            ))}
        </Grid>
    )
}
