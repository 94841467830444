/* eslint-disable complexity */
import { Stack } from "@mui/material"
import { useEffect, useMemo, useState } from "react"

import { commonMessages } from "~/common-messages"
import { ActionsMenu, ShareButton, StatusChip } from "~/components"
import { useFetchPartnership } from "~/domains/identity/partners/store/hooks"
import useSupplierAllowed from "~/domains/identity/partners/store/hooks/useSupplierAllowed"
import { useHasPermissions } from "~/domains/identity/roles-permissions/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { OrganizationSummary } from "~/domains/transactions/_shared/components/Organizations/Organizations"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import {
    ApprovePO,
    CancelEditPO,
    ClosePO,
    DeletePO,
    DownloadPOPdf,
    EditPO,
    OutForDeliveryPO,
    ReOpenPO,
    ReceiveOrDeliverPO,
    RejectPO,
    SavePO,
    SendPO,
    SubmitPO,
} from "~/domains/transactions/purchase-orders/components/Actions"
import { allLinesRejected } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import {
    PurchaseOrderProgressStatus,
    PurchaseOrderStatus,
    PurchaseOrderStatusOrder,
    PurchaseOrders,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { AuthorizationName, OrganizationI, ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"

import { MarkAllInPreparation } from "./MarkAllInPreparation"

interface PropsPOActions {
    PO: PurchaseOrders
    organization: OrganizationI
    buyerOrganization: OrganizationSummary
    supplierOrganization: OrganizationSummary
    mode: PurchaseViewType
    viewType: ViewTypeI
    allLinesApproved: boolean
    isOnApprovalSide: boolean
    needsNewApproval: boolean
}

export const ActionsHeader: React.FC<PropsPOActions> = ({
    PO,
    organization,
    buyerOrganization,
    supplierOrganization,
    mode,
    viewType,
    allLinesApproved,
    isOnApprovalSide,
    needsNewApproval,
}) => {
    const { partnershipData, loading: partnershipDataLoading } = useFetchPartnership(organization.id, PO.supplierId)

    const isSupplier = viewType === ViewTypeI.supplier
    const isBuyer = viewType === ViewTypeI.buyer
    const [, setSupplierAllowed] = useState(false)

    const areAllLinesRejected = useMemo(() => allLinesRejected(PO.lines, viewType), [PO.lines, viewType])

    const canReceiveOrDeliver =
        (isBuyer &&
            PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHARED) &&
            PurchaseOrderStatusOrder.indexOf(PO.progress) <
                PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.RECEIVED)) ||
        (isSupplier && PO.progress === PurchaseOrderProgressStatus.SHIPPED)

    const { hasPermission: hasPOEditPermission } = useHasPermissions(
        organization.id ?? "",
        AuthorizationName.UPDATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )

    const supplierAllowed = useSupplierAllowed(partnershipData, partnershipDataLoading)

    useEffect(() => {
        setSupplierAllowed(supplierAllowed)

        return () => {
            setSupplierAllowed(false)
        }
    }, [partnershipData, partnershipDataLoading, supplierAllowed])

    const canSubmit = PO.progress === PurchaseOrderProgressStatus.DRAFT

    const canEdit = PO.status !== PurchaseOrderStatus.CLOSED && hasPOEditPermission

    const canDelete = [PurchaseOrderStatus.DRAFT, PurchaseOrderStatus.SUBMITTED].includes(PO.status)

    const canClose = PO.status === PurchaseOrderStatus.OPEN
    const canReOpen = PO.status === PurchaseOrderStatus.CLOSED
    const canReject = isOnApprovalSide && !areAllLinesRejected
    const canApprove = isOnApprovalSide && !allLinesApproved
    const canMarkAllInPreparation = isSupplier && PO.progress === PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED
    const canMarlOutForDelivery = isSupplier && PO.progress === PurchaseOrderProgressStatus.IN_PREPARATION
    const canDownload = PO.status !== PurchaseOrderStatus.DRAFT

    const cannotSend = !(isOnApprovalSide && allLinesApproved && isBuyer && PO.status === PurchaseOrderStatus.SUBMITTED)

    const actions = [
        ...(canEdit ? [{ render: <EditPO PO={PO} display="menu-item" /> }] : []),
        {
            render: (
                <ShareButton
                    objectId={PO.id}
                    objectType={SharedObjectType.PurchaseOrder}
                    organizationId={organization.id}
                    buyerOrganization={buyerOrganization}
                    supplierOrganization={supplierOrganization}
                    buttonMessage={commonMessages.shareInternally}
                />
            ),
        },
        ...(canDownload ? [{ render: <DownloadPOPdf purchaseOrder={PO} /> }] : []),
        ...(canDelete ? [{ render: <DeletePO PO={PO} viewType={viewType} display="menu-item" /> }] : []),
        ...(canClose ? [{ render: <ClosePO PO={PO} viewType={viewType} display="menu-item" /> }] : []),
        ...(canReOpen ? [{ render: <ReOpenPO PO={PO} viewType={viewType} display="menu-item" /> }] : []),
    ]

    return (
        <Stack direction="row" spacing={1}>
            {mode === PurchaseViewType.VIEW ? (
                <>
                    <ActionsMenu isActionIcon={false} actions={actions} />

                    {canSubmit && <SubmitPO PO={PO} />}

                    {canReject && <RejectPO PO={PO} needsNewApproval={needsNewApproval} />}

                    {canApprove && <ApprovePO PO={PO} needsNewApproval={needsNewApproval} />}

                    {!cannotSend && <SendPO PO={PO} viewType={viewType} />}

                    {canMarkAllInPreparation && <MarkAllInPreparation PO={PO} />}

                    {canMarlOutForDelivery && <OutForDeliveryPO PO={PO} />}

                    {canReceiveOrDeliver && <ReceiveOrDeliverPO PO={PO} isBuyer={isBuyer} />}

                    {canReOpen && <StatusChip status={PO.status}>{PO.status}</StatusChip>}
                </>
            ) : (
                <>
                    <CancelEditPO PO={PO} />
                    <SavePO PO={PO} />
                </>
            )}
        </Stack>
    )
}
