import { AssignedObject, ServerAssignedObject } from "~/domains/payment/payment-batches/types"

export const assignedObjectFromApiAdapter = (assignedObject: ServerAssignedObject): AssignedObject => {
    return {
        id: assignedObject.id,
        amount: assignedObject.amount,
        currency: assignedObject.currency,
        objectId: assignedObject.object_id,
        objectType: assignedObject.object_type,
        payeeId: assignedObject.payee_id,
        dateScheduled: assignedObject.date_scheduled,
        payeePaymentMethodDetailsId: assignedObject.payee_payment_method_details_id,
        paymentTransactionBatchInstanceId: assignedObject.payment_transaction_batch_instance_id,
        status: assignedObject.status,
    }
}

export const assignedObjectToApiAdapter = (assignedObject: AssignedObject): ServerAssignedObject => {
    return {
        id: assignedObject.id,
        amount: assignedObject.amount,
        currency: assignedObject.currency,
        object_id: assignedObject.objectId,
        object_type: assignedObject.objectType,
        payee_id: assignedObject.payeeId,
        date_scheduled: assignedObject.dateScheduled,
        payee_payment_method_details_id: assignedObject.payeePaymentMethodDetailsId,
        payment_transaction_batch_instance_id: assignedObject.paymentTransactionBatchInstanceId,
        status: assignedObject.status,
    }
}
