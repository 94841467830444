import { useIntl } from "react-intl"

import { ProgressBarItem, ProgressBarItemDisplay } from "~/components"
import { globalStatus } from "~/constants/globalStatus"
import { InvoiceI } from "~/types"

export const useLineItemsByStatusComparedToBudget = (invoice: InvoiceI) => {
    const { formatMessage, formatNumber } = useIntl()

    const groupedItemsByStatus = (invoice?.lineItems || []).reduce(
        (acc, item) => {
            const { approvalStatus, total, currency } = item

            if (!approvalStatus) return acc

            if (!acc?.[currency]) {
                acc[currency] = {}
            }

            if (!acc?.[currency][approvalStatus]) {
                acc[currency][approvalStatus] = {
                    label: formatMessage(globalStatus[approvalStatus]),
                    value: 0,
                    percentage: 0,
                    color: globalStatus[approvalStatus]?.color || "var(--color-grey-light)",
                }
            }
            const budgetForCurrency = invoice?.budgets?.find((budget) => budget?.budget?.currency === currency)?.budget

            acc[currency][approvalStatus].value += parseFloat((+total).toFixed(2))

            if (budgetForCurrency?.currentAmount) {
                acc[currency][approvalStatus].percentage =
                    acc[currency][approvalStatus].value / budgetForCurrency.currentAmount
            }

            return acc
        },
        {} as Record<string, { [key: string]: ProgressBarItem }>
    )

    const lineItemsByStatusAndCurrencyValues = Object.entries(groupedItemsByStatus).reduce(
        (acc, [currency, currencyValues]) => ({
            ...acc,
            [currency]: Object.values(currencyValues).map((item) => ({
                ...item,
                value: formatNumber(item.value ?? 0, {
                    style: "currency",
                    currency,
                }),
            })),
        }),
        {} as Record<string, ProgressBarItemDisplay[]>
    )

    const firstCurrency = Object.keys(lineItemsByStatusAndCurrencyValues)[0]
    const lineItemsByStatusAndCurrencyValuesWithoutRejected = firstCurrency
        ? lineItemsByStatusAndCurrencyValues[firstCurrency].filter(
              (item) => item.label !== formatMessage(globalStatus.REJECTED)
          )
        : []

    const totalPercentageWithoutRejected = lineItemsByStatusAndCurrencyValuesWithoutRejected.reduce(
        (acc, item) => acc + +(item.percentage || 0),
        0
    )

    return {
        lineItemsByStatusAndCurrencyValues,
        lineItemsByStatusAndCurrencyValuesWithoutRejected,
        totalPercentageWithoutRejected,
    }
}
