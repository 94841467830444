import { Tooltip } from "@mui/material"
import React, { useState } from "react"
import { Share2 } from "react-feather"
import { useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import {
    SpiceDBObjectType,
    sharedObjectPermissionsModalMessages,
} from "~/domains/identity/roles-permissions/types/SpiceDB"
import { OrganizationId } from "~/types"

import { ShareObjectPermissionsModal } from "./ShareObjectPermissionsModal"

interface ShareObjectPermissionsButtonProps {
    organizationId: OrganizationId
    objectType: SpiceDBObjectType
    objectId: string
    display?: "button" | "menu-item"
}

export const ShareObjectPermissionsButton: React.FC<ShareObjectPermissionsButtonProps> = ({
    organizationId,
    objectType,
    objectId,
    display,
}) => {
    const { formatMessage } = useIntl()
    const [shareObjectModalOpen, setShareObjectModalOpen] = useState<boolean>(false)

    const showShareObjectModal = () => setShareObjectModalOpen(true)
    const hideShareObjectModal = () => setShareObjectModalOpen(false)

    return (
        <>
            {display === "button" ? (
                <Tooltip arrow title={formatMessage(sharedObjectPermissionsModalMessages.share)}>
                    <span>
                        <Button type="grey" onClick={showShareObjectModal}>
                            <Share2 size={18} />
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button type="menu-item" onClick={showShareObjectModal}>
                    <Share2 size={16} />
                    <SafeFormattedMessage {...sharedObjectPermissionsModalMessages.share} />
                </Button>
            )}
            {shareObjectModalOpen && (
                <ShareObjectPermissionsModal
                    open={shareObjectModalOpen}
                    close={hideShareObjectModal}
                    organizationId={organizationId}
                    objectType={objectType}
                    objectId={objectId}
                />
            )}
        </>
    )
}
