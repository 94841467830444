import { UserCheck } from "react-feather"

import { CreateTaskConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { CreateTaskNode } from "~/domains/orchestration/flows/components/nodes"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { CreateTaskNode as CreateTaskNodeType, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const createTaskNode: Omit<CreateTaskNodeType, keyof Node> = {
    title: "",
    description: "",
    dueDate: "",
    priority: undefined,
    status: undefined,
    assignee: "",
    parentTaskId: "",
    public: false,
    followers: [],
    parties: [],
    type: NodeType.CREATE_TASK_NODE,
    nextNode: null,
}

const advancedFields = []

const createTaskConfig: Configuration<NodeType.CREATE_TASK_NODE> = {
    type: NodeType.CREATE_TASK_NODE,
    baseSlug: "create-task",
    Icon: UserCheck,
    Configuration: CreateTaskConfiguration,
    advancedFields,
    Node: CreateTaskNode,
    factory: (props: FactoryProps): CreateTaskNodeType => ({
        ...createTaskNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { createTaskConfig, advancedFields as createTaskAdvancedFields }
