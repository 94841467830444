import { TextField, Typography } from "@mui/material"
import { FormEvent, useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Button, Modal, SafeFormattedMessage } from "~/components"
import { useSaveMessageAsTemplate } from "~/domains/communication/chat/hooks"
import { CustomElement } from "~/domains/communication/chat/types/Slate"
import { OrganizationId } from "~/types"
import { pipeResult } from "~/types/Result"

const messages = defineMessages({
    modalTitle: {
        id: "communication.saveAsTemplateModal.title",
        defaultMessage: "Save as template",
    },
    templateName: {
        id: "communication.saveAsTemplateModal.templateName",
        defaultMessage: "Template's name",
    },
    notSaved: {
        id: "communication.saveAsTemplateModal.notSaved",
        defaultMessage: "Your message has not been saved as a template: an unexpected error occurred.",
    },
})

interface Props {
    open: boolean
    handleClose: (isSaved?: boolean) => void
    organizationId: OrganizationId
    message: CustomElement[]
}

export const SaveAsTemplateModal = ({ handleClose, message, open, organizationId }: Props) => {
    const { formatMessage } = useIntl()
    const [name, setName] = useState("")
    const onClose = useCallback(() => handleClose(), [handleClose])

    const saveMessageAsTemplate = useSaveMessageAsTemplate(organizationId)

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const result = await saveMessageAsTemplate({ name, content: message })
            pipeResult(
                result,
                () => {
                    setName("")
                    handleClose(true)
                },
                () => {
                    toast.error(formatMessage(messages.notSaved))
                }
            )
        },
        [name, message, handleClose, saveMessageAsTemplate, formatMessage]
    )
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>
                <Typography variant="h4" gutterBottom>
                    <SafeFormattedMessage {...messages.modalTitle} />
                </Typography>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Content>
                    <TextField
                        required
                        name="name"
                        label={formatMessage(messages.templateName)}
                        placeholder={formatMessage(messages.templateName)}
                        onChange={(e) => setName(e.currentTarget.value)}
                        value={name}
                        fullWidth
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button type="transparent" onClick={onClose}>
                        {formatMessage(commonMessages.cancel)}
                    </Button>
                    <Button type="primary" buttonType="submit">
                        {formatMessage(commonMessages.save)}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
