import { Step, StepIconProps, StepLabel, Stepper, Typography } from "@mui/material"
import { FC } from "react"

import { StatusIcon } from "~/domains/orchestration/flows/components/StatusIcon"
import { RUN_STATUS_COLORS } from "~/domains/orchestration/flows/constants"
import { Run, RunStatus } from "~/domains/orchestration/flows/types"

interface Props {
    data: Run
}

const StepIcon: FC<StepIconProps> = ({ active, completed, error }) => {
    if (completed) {
        return <StatusIcon status={RunStatus.FINISHED} />
    }
    if (error) {
        return <StatusIcon status={RunStatus.FAILED} />
    }
    if (active) {
        return <StatusIcon status={RunStatus.SUSPENDED} />
    }
    return <StatusIcon status={RunStatus.FINISHED} />
}

export const RunStepper: FC<Props> = ({ data }) => {
    const errorStatuses = [RunStatus.FAILED]
    const warningStatuses = [RunStatus.CANCELED, RunStatus.SUSPENDED]

    const hasError = errorStatuses.includes(data.status)
    const hasWarning = warningStatuses.includes(data.status)
    const color = RUN_STATUS_COLORS[data.status]

    return (
        <Stepper activeStep={data.state.pathTaken.length - 1} orientation="vertical">
            {data.state.pathTaken.map((step, index) => (
                <Step key={index}>
                    <StepLabel error={hasError} StepIconComponent={StepIcon} StepIconProps={{ active: hasWarning }}>
                        <Typography
                            variant="body2"
                            color={index === data.state.pathTaken.length - 1 ? color : "var(--color-grey)"}
                        >
                            {step}
                        </Typography>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    )
}
