/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PayloadAction } from "@reduxjs/toolkit"
import { all, call, put, select, takeLatest } from "redux-saga/effects"

import { userApi } from "~/domains/identity/account/api/userApi"
import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import {
    approveInvoice,
    fetchInvolvedUsers,
    rejectInvoice,
    schedulePayment,
    sendRequest,
    setAsPaid,
    validateInvoice,
} from "~/store/invoice/buyerSaga"
import { markAsResolved } from "~/store/invoice/supplierSaga"
import { EventI, MessageI, MessageOrEventI, UserI, UserId } from "~/types"

import { RootState } from ".."
import { invoiceActions } from "./invoiceSlice"

const getAllUsers = (state: RootState) => state.invoice.userIds

function appendUserInData<T extends MessageOrEventI>(data: T[], users: UserI[]): T[] {
    return data.map((elem: T) => {
        elem.user = users.filter((user) => user.id === elem.userId)[0]
        return elem
    })
}

function* fetchCommunication(action: PayloadAction<string>) {
    try {
        // TODO refactor
        const response: MessageI[] = yield call(invoiceApi.getCommunications, action.payload)
        const userIds = response.map((data) => data.userId)
        yield put(invoiceActions.addPeople(userIds))
        const allIds: UserId[] = yield select(getAllUsers)
        const res: UserI[] = yield call(userApi.findUsersByIds, allIds)
        const communications = appendUserInData(response, res)
        yield put(invoiceActions.fetchCommunicationSuccess(communications))
    } catch (error) {
        console.error(`Failed to get communications`, error)
        yield put(invoiceActions.fetchCommunicationFailed(`${error}`))
    }
}

function* fetchEvents(action: PayloadAction<string>) {
    try {
        const response: EventI[] = yield call(invoiceApi.getEvents, action.payload)
        const userIds = response.map((data) => data.userId)
        yield put(invoiceActions.addPeople(userIds))
        const allIds: UserId[] = yield select(getAllUsers)
        const res: UserI[] = yield call(userApi.findUsersByIds, allIds)
        const events = appendUserInData(response, res)
        yield put(invoiceActions.fetchEventsSuccess(events))
    } catch (error) {
        console.error(`Failed to get events`, error)
        yield put(invoiceActions.fetchEventsFailed(`${error}`))
    }
}

export default function* invoiceSaga() {
    yield all([
        takeLatest(invoiceActions.fetchCommunication.type, fetchCommunication),
        takeLatest(invoiceActions.fetchEvents.type, fetchEvents),
        // buyer's sagas
        takeLatest(invoiceActions.fetchInvolvedUsers.type, fetchInvolvedUsers),
        takeLatest(invoiceActions.sendRequest.type, sendRequest),
        takeLatest(invoiceActions.validateInvoice.type, validateInvoice),
        takeLatest(invoiceActions.approveInvoice.type, approveInvoice),
        takeLatest(invoiceActions.rejectInvoice.type, rejectInvoice),
        takeLatest(invoiceActions.schedulePayment.type, schedulePayment),
        takeLatest(invoiceActions.setAsPaid.type, setAsPaid),
        // supplier's sagas
        takeLatest(invoiceActions.markAsResolved.type, markAsResolved),
    ])
}
