import { HandleProps, Position } from "@xyflow/react"
import type { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { Mail } from "react-feather"
import { useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/locale"
import { EmailMailType, SendEmailNode as SendEmailNodeType } from "~/domains/orchestration/flows/types"
import { getEmailMailType } from "~/domains/orchestration/flows/utils"

import { Node } from "./Node"

export const SendEmailNode: FC<NodeProps<NodeType<SendEmailNodeType>>> = ({ data, selected }) => {
    const { formatMessage } = useIntl()
    const handles: HandleProps[] = [
        {
            type: "target",
            isConnectableEnd: true,
            position: Position.Top,
        },
        {
            type: "source",
            position: Position.Bottom,
            isConnectableStart: true,
        },
    ]

    const hasInvalidEmail = data.recipientAddresses.some((email) => getEmailMailType(email) === EmailMailType.ERROR)

    const error = !data.recipientAddresses.length || !data.subject || hasInvalidEmail

    const errorMessage = error && hasInvalidEmail ? formatMessage(messages.sendEmailNode.invalidEmailError) : undefined

    return (
        <Node
            type={data.type}
            name={data.name}
            information={data.subject}
            icon={Mail}
            handles={handles}
            selected={selected}
            slug={data.slug}
            error={error}
            errorMessage={errorMessage}
        ></Node>
    )
}
