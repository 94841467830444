import { nodeConfig } from "~/domains/orchestration/flows/core/"
import { Configuration, EditorNode, EmailMailType, Flow, NodeType } from "~/domains/orchestration/flows/types"
import { isEmailValid } from "~/utils/email"

/**
 *
 * @param flow
 * @returns Flow
 * @description bump the version of the flow
 */
const bumpFlow = (flow: Flow): Flow => ({ ...flow, version: flow.version + 1 })

/**
 *
 * @param nodeType
 * @param currentNodes
 * @returns string
 * @description generate a readable id for a node
 */
const generateReadableId = (nodeType: NodeType, currentNodes: EditorNode[]): string => {
    const configuration: Configuration<NodeType> = nodeConfig[nodeType]
    const BaseSlug = configuration.baseSlug
    if (!BaseSlug) {
        throw new Error(`Unknown node type: ${nodeType}`)
    }

    const existingNodes = currentNodes.filter((node) => node.type === nodeType)

    const BaseSlugFilter = (id: string) => (node: EditorNode) => node.slug.startsWith(id)
    const isSameBaseSlug = BaseSlugFilter(BaseSlug)

    const getIdCountFromNode = (node: EditorNode) => {
        const match = node.slug.match(new RegExp(`${BaseSlug}_(\\d+)$`))
        return match ? parseInt(match[1], 10) : 0
    }

    const existingIds = existingNodes.filter(isSameBaseSlug).map(getIdCountFromNode)

    const maxNumber = Math.max(0, ...existingIds)
    return `${BaseSlug}_${maxNumber + 1}`
}

/**
 *
 * @param email
 * @returns EmailMailType
 * @description get the type of the email address (valid, expression or error)
 */
const getEmailMailType = (email: string): EmailMailType => {
    // Check if it's an expression (enclosed in {{ }})
    if (email.includes("{{") && email.includes("}}")) {
        return EmailMailType.EXPRESSION
    }

    // Check if it's a valid email

    return isEmailValid(email) ? EmailMailType.VALID : EmailMailType.ERROR
}

export { bumpFlow, generateReadableId, getEmailMailType }
