import React, { useState } from "react"
import { Download } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, Loader, SafeFormattedMessage } from "~/components"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { PurchaseOrderPDFDTO, PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganization } from "~/store/organization/hooks"
import { OrganizationI } from "~/types"

const messages = defineMessages({
    pdf: {
        id: "purchase.orders.order.download.pdf",
        defaultMessage: "Download PDF",
    },
    errorAddress: {
        id: "purchase.orders.order.download.errorAddress",
        defaultMessage: "Please fill in the billing and shipping addresses before downloading the PDF",
    },
    errorDownloading: {
        id: "purchase.orders.order.download.errorDownloading",
        defaultMessage: "An error occurred while downloading the PDF",
    },
    prefixPOPdf: {
        id: "purchase.orders.order.download.purchaseOrder.prefix",
        defaultMessage: "Purchase Order",
    },
})

interface Props {
    purchaseOrder: PurchaseOrders
}

export const DownloadPOPdf = ({ purchaseOrder }: Props) => {
    const [loading, setLoading] = useState(false)
    const { organization } = useFetchOrganization(purchaseOrder.supplierId)
    const language = useAppSelector((state) => state.global.lang)
    const { formatMessage } = useIntl()

    const purchaseOrderToPdf = (po: PurchaseOrders, supplier: OrganizationI) => {
        const items = po.lines.map((line) => {
            return {
                description: line.description,
                quantity: line.quantity,
                unitPriceExcludingTax: line.unitPriceExcludingTax,
                taxRate: line.taxRate,
                totalAmount: line.totalAmount,
            }
        })
        if (po.billingAddress && po.shippingAddress) {
            const purchaseOrderPdf: PurchaseOrderPDFDTO = {
                supplier: {
                    name: po.supplierName,
                    address: {
                        street: supplier.address?.addressLine ?? "",
                        street2: supplier.address?.secondaryAddressLine ?? "",
                        zipCode: supplier.address?.zipCode ?? "",
                        city: supplier.address?.city ?? "",
                        country: supplier.address?.country ?? "",
                    },
                },
                buyer: {
                    name: po.buyerName,
                    billingAddress: po.billingAddress,
                    shippingAddress: po.shippingAddress,
                },
                description: po.description,
                purchaseOrderId: po.shortId,
                issueDate: po.creationDate,
                deliveryDate: po.expectedDeliveryDate,
                paymentTerms: "A définir",
                items: items,
                currency: po.currency,
                totalAmount: po.totalAmount,
                totalAmountExcludingTax: po.totalAmountExcludingTax,
                totalTax: po.totalTax,
                language,
            }
            return purchaseOrderPdf
        } else {
            toast.error(formatMessage(messages.errorAddress))
            return false
        }
    }

    const handleDownload = async () => {
        setLoading(true)
        try {
            if (purchaseOrder && organization) {
                const poData = purchaseOrderToPdf(purchaseOrder, organization)

                if (poData) {
                    const fileName = `[${formatMessage(messages.prefixPOPdf)}]${poData.buyer.name}-${poData.supplier.name}-${poData.purchaseOrderId}.pdf`
                    await purchaseOrdersApi.toPDF(poData, fileName)
                }
            }
        } catch (error) {
            console.error(error)
            toast.error(formatMessage(messages.errorDownloading))
        } finally {
            setLoading(false)
        }
    }
    return !loading && organization ? (
        <Button onClick={handleDownload} type="menu-item">
            <Download size={16} />
            <SafeFormattedMessage {...messages.pdf} />
        </Button>
    ) : (
        <Loader small={true} />
    )
}
