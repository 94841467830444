import { Stack } from "@mui/material"
import { useEffect } from "react"

import { Loader } from "~/components"
import {
    InfoCards,
    PaymentMethodDetailsDeletedToggle,
    PaymentMethodDetailsList,
    PaymentMethodTabs,
} from "~/domains/payment/payment-method-details/components"
import { PaymentMethodDetailsActions } from "~/domains/payment/payment-method-details/components/header"
import { usePaymentMethodDetailsRows } from "~/domains/payment/payment-method-details/hooks/usePaymentMethodDetailsRows"
import { paymentMethodDetailsActions } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { selectActiveTab } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodDetails as PaymentMethodDetailsI } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { DefaultPaymentTerms } from "~/domains/payment/payment-terms/components"
import { ObjectType } from "~/domains/payment/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

interface PaymentMethodDetailsProps {
    isLoading: boolean
    organizationId: string
    currentOrganizationId: string
    items: PaymentMethodDetailsI[]
    objectType: ObjectType
    onPaymentMethodDetailsCreated?: (data: PaymentMethodDetailsI) => void
}

export const PaymentMethodDetails = ({
    isLoading,
    organizationId,
    currentOrganizationId,
    items,
    onPaymentMethodDetailsCreated,
    objectType = ObjectType.ORGANIZATION,
}: PaymentMethodDetailsProps) => {
    const activeTab = useAppSelector(selectActiveTab)
    const dispatch = useAppDispatch()
    const { isLoading: isFechingRows, rowsToApprove } = usePaymentMethodDetailsRows({
        items,
        organizationId,
        activeTab,
    })

    const handleTabChange = (tab: PaymentMethodType) => {
        dispatch(paymentMethodDetailsActions.setActiveTab(tab))
    }

    useEffect(() => {
        dispatch(paymentMethodDetailsActions.reset())
        dispatch(paymentMethodDetailsActions.setSelectedOrganizationId(organizationId))
        return () => {
            dispatch(paymentMethodDetailsActions.reset())
        }
    }, [organizationId, dispatch])

    if (isLoading) {
        return <Loader position="center" />
    }

    return (
        <Stack spacing={2}>
            <InfoCards items={items} />
            <DefaultPaymentTerms
                organizationId={organizationId}
                currentOrganizationId={currentOrganizationId}
                objectType={objectType}
            />

            <Stack gap={2} direction="row" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                <PaymentMethodTabs currentTab={activeTab} onTabChange={handleTabChange} />

                <Stack direction="row" flexWrap="wrap" gap={1}>
                    <PaymentMethodDetailsDeletedToggle />
                    <PaymentMethodDetailsActions
                        activeTab={activeTab}
                        organizationId={organizationId}
                        onPaymentMethodDetailsCreated={onPaymentMethodDetailsCreated}
                    />
                </Stack>
            </Stack>

            <PaymentMethodDetailsList loading={isFechingRows} items={items} rowsToApprove={rowsToApprove} />
        </Stack>
    )
}
