import { Grid } from "@mui/material"
import { useMemo } from "react"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { ItemLabel, Loader } from "~/components"
import { useGetPaymentBatchInstanceQuery } from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { AssignedObject } from "~/domains/payment/payment-batches/types"
import { PartyPaymentMethodDetails } from "~/domains/payment/payment-method-details/components"

interface ObjectAssignInfoProps {
    assignedObject: AssignedObject
}

export const ObjectAssignInfo = ({ assignedObject }: ObjectAssignInfoProps) => {
    const { formatMessage, formatDate } = useIntl()
    const instanceId = assignedObject.paymentTransactionBatchInstanceId
    const { data, isLoading } = useGetPaymentBatchInstanceQuery(instanceId, { skip: !instanceId })

    const dataItems = useMemo(
        () => [
            {
                label: formatMessage(commonMessages.name),
                value: data?.name,
            },
            {
                label: formatMessage(commonMessages.dateScheduled),
                value: formatDate(data?.dateScheduled ?? ""),
            },
            {
                label: formatMessage(commonMessages.paymentMethod),
                value: (
                    <PartyPaymentMethodDetails mode="inline" paymentMethodDetailsId={data?.paymentMethodDetailsId} />
                ),
            },
            {
                label: formatMessage(commonMessages.status),
                value: data?.status,
            },
        ],
        [data]
    )

    if (isLoading) {
        return <Loader small />
    }

    return (
        <Grid container spacing={2}>
            {dataItems.map(({ label, value }) => (
                <Grid key={label} item xs={12} sm={6}>
                    <ItemLabel>{label}</ItemLabel>
                    <p>{value || "-"}</p>
                </Grid>
            ))}
        </Grid>
    )
}
