import { Typography } from "@mui/material"
import { defineMessages } from "react-intl"
import { useNavigate } from "react-router-dom"

import { Button, SafeFormattedMessage } from "~/components"

import { LeftChevronIcon } from "../Icons"

const messages = defineMessages({
    back: {
        id: "common.h1.backLink",
        defaultMessage: "Back",
    },
})

interface Props {
    urlOrFunction: string | (() => void)
}

export const BackLink = ({ urlOrFunction }: Props) => {
    const navigate = useNavigate()

    const handleGoBack = () => {
        if (typeof urlOrFunction === "string") {
            navigate(urlOrFunction)
            return
        }

        urlOrFunction()
    }

    return (
        <Button type="transparent" onClick={handleGoBack} size="small">
            <LeftChevronIcon size={14} color="var(--primary-color)" strokeWidth="3" />
            <Typography color="var(--primary-color)">
                <SafeFormattedMessage {...messages.back} />
            </Typography>
        </Button>
    )
}
