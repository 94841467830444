import { FormControlLabel, FormHelperText, Radio, RadioGroup, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { QuestionValue } from "~/domains/identity/custom-forms/types/CustomForms"

interface CustomFormsRadioInputProps {
    savedValue: QuestionValue
    onValueChanged: (value: QuestionValue) => void
    disabled?: boolean
    isInvalid?: boolean
    isFocused?: boolean
}

export const CustomFormsRadioInput = ({
    savedValue,
    onValueChanged,
    disabled = false,
    isInvalid = false,
    isFocused = false,
}: CustomFormsRadioInputProps) => {
    const [value, setValue] = useState<QuestionValue>(savedValue)
    const [invalid, setInvalid] = useState(isInvalid)
    const [focused, setFocused] = useState(isFocused)
    const handleChange = (event: React.SyntheticEvent) => {
        const newValue = (event.target as HTMLInputElement).value
        onValueChanged(newValue)
        setValue(newValue)
        setInvalid(false)
    }

    const renderRadioControl = () => <Radio sx={{ color: invalid ? "var(--color-red)" : "var(--color-black)" }} />

    useEffect(() => {
        setInvalid(isInvalid)
        setFocused(isFocused)
    }, [isInvalid, isFocused])

    return (
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group" value={value}>
            <Stack direction="row">
                <FormControlLabel
                    inputRef={(input: HTMLElement) => {
                        if (input && focused) {
                            input.scrollIntoView({ behavior: "smooth", block: "center" })
                            input.focus()
                        }
                    }}
                    onChange={handleChange}
                    value="true"
                    control={renderRadioControl()}
                    label={
                        <Typography color={invalid ? "error" : "black"}>
                            <SafeFormattedMessage {...commonMessages.radioYes} />
                        </Typography>
                    }
                    disabled={disabled}
                />
                <FormControlLabel
                    onChange={handleChange}
                    value="false"
                    control={renderRadioControl()}
                    label={
                        <Typography color={invalid ? "error" : "black"}>
                            <SafeFormattedMessage {...commonMessages.radioNo} />
                        </Typography>
                    }
                    disabled={disabled}
                />
            </Stack>
            {invalid && (
                <FormHelperText component="span" sx={{ color: "var(--color-red)", height: "0px" }}>
                    <SafeFormattedMessage {...commonMessages.errorRequiredField} />
                </FormHelperText>
            )}
        </RadioGroup>
    )
}
