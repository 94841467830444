/* eslint-disable max-lines */
// FIXME: remove eslint-disable complexity

/* eslint-disable complexity */
import { Box, Grid, Stack } from "@mui/material"
import React, { ChangeEvent, lazy, useCallback, useEffect, useMemo, useState } from "react"
import { CheckCircle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { Button, Card, HeaderH1, Loader } from "~/components"
import { ResizableCol } from "~/components/Layout/ResizableCol"
import { ResizableGrid } from "~/components/Layout/ResizableGrid"
import { ModalConfirm } from "~/components/ModalConfirm/ModalConfirm"
import { Documents, DraftDocumentI } from "~/components/UploadDocument/Documents"
import { withSocketIOProvider } from "~/domains/_shared/subscriptions/components/SocketIOContext"
import { TagsSelector } from "~/domains/analytics/tags/components/TagsSelector"
import {
    TagsSelectorForLineCells,
    TagsSelectorWithStateForLineCells,
} from "~/domains/analytics/tags/components/TagsSelector/TagsSelectorForLineCells"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks"
import { tagsActions } from "~/domains/analytics/tags/store/tagsSlice"
import { TagGroupI, TagObjectType } from "~/domains/analytics/tags/types"
import { CommunicationRoom } from "~/domains/communication/chat/components/CommunicationRoom"
import { LinesTabs } from "~/domains/identity/custom-fields/components/LinesTabs"
import { CustomFieldObjectType } from "~/domains/identity/custom-fields/types/CustomFieldObjectType"
import { DocumentObjectType } from "~/domains/identity/documents/types/UploadDocumentDto"
import { SavePayloadType } from "~/domains/identity/organization/components/ModalOrganizationSelectorDetails/types"
import usePartnerBrandname from "~/domains/identity/partners/store/hooks/usePartnerBrandname"
import { ApprovalBlock } from "~/domains/orchestration/flows-v0/components/ApprovalBlock"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"
import { Description } from "~/domains/transactions/_shared/components/Description/Description"
import { useTagsForLines } from "~/domains/transactions/_shared/components/Items/hooks/useTagsForLines"
import { TransactionInBudgets } from "~/domains/transactions/_shared/components/TransactionInBudgets/TransactionInBudgets"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import { BudgetDataWithMetricsI, TransactionType } from "~/domains/transactions/budget/types"
import { organizationAddressToAddress } from "~/domains/transactions/purchase-orders/core/organizationAddressToAddress"
import { ActionsHeader } from "~/domains/transactions/purchase-requests/components/Actions/ActionsHeader"
import { ActionsHeaderCreate } from "~/domains/transactions/purchase-requests/components/Actions/ActionsHeaderCreate"
import { PurchaseRequestLeftColumn } from "~/domains/transactions/purchase-requests/components/PurchaseRequestLeftColumn/PurchaseRequestLeftColumn"
import { StepsPurchaseRequest } from "~/domains/transactions/purchase-requests/components/StepsPurchaseRequest"
import { TagsForCreatingPurchaseRequest } from "~/domains/transactions/purchase-requests/components/TagsForCreatingPurchaseRequest"
import { PURCHASE_REQUESTS_ROUTE, PURCHASE_REQUEST_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import {
    useFetchPurchaseRequest,
    usePurchaseRequestValidationActions,
    useUpdatePurchaseRequest,
} from "~/domains/transactions/purchase-requests/store/hooks"
import { useCreateDraftPurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/useCreateDraftPurchaseRequest"
import { usePurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/usePurchaseRequest"
import {
    purchaseRequestsActions,
    selectPurchaseRequestsError,
    selectPurchaseRequestsLoading,
    selectShouldUpdatePR,
} from "~/domains/transactions/purchase-requests/store/purchaseRequestsSlice"
import {
    PurchaseRequestDetails,
    PurchaseRequestLine,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { useTitle } from "~/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { organizationActions, selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationI, OrganizationId, ViewTypeI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"
import useQueryParams from "~/utils/hooks/useQueryParams"

const Items = lazy(() =>
    import("~/domains/transactions/purchase-requests/components/PRList/ItemsPR").then(({ ItemsPR }) => ({
        default: ItemsPR,
    }))
)
const OrganizationsLazy = lazy(() =>
    import("~/domains/transactions/_shared/components/Organizations/Organizations").then(({ Organizations }) => ({
        default: Organizations,
    }))
)

const STATE_KEY = "transaction"

const messages = defineMessages({
    htmlTitle: { id: "purchase.requests.request.htmlTitle", defaultMessage: "Purchase request" },
    titleEdit: { id: "purchase.requests.request.titleEdit", defaultMessage: "Edit PR" },
    titleNew: { id: "purchase.requests.request.titleNew", defaultMessage: "New PR" },
    titleView: { id: "purchase.requests.request.titleView", defaultMessage: "PR" },
    confirm: {
        id: "purchase.requests.request.modalConfirm.title",
        defaultMessage: "Would you send the purchase request for approval?",
    },
    submitForApproval: {
        id: "purchase.requests.request.askForSubmit",
        defaultMessage: "Would you like to submit this purchase request for approval?",
    },
    saveAsDraft: { id: "purchase.requests.request.modalConfirm.saveDraft", defaultMessage: "Save it as draft" },
    saveAndSend: { id: "purchase.requests.request.modalConfirm.send", defaultMessage: "Send for approval" },
    errorChangedOrganization: {
        id: "purchase.requests.request.errorChangedOrganization",
        defaultMessage: "You have changed the organization. This organization does not have this purchase request.",
    },
    tagsTitle: { id: "purchase.requests.request.tagsTitle", defaultMessage: "Tags" },
})

interface Props extends JSX.IntrinsicAttributes {
    edit?: boolean
    newPR?: boolean
}

interface ViewProps extends Props {
    organization: OrganizationI
    purchaseRequest: PurchaseRequestDetails
}

/* const getPRSideFromSupplierId = (purchaseRequestSupplier: OrganizationId, currentOrgId: OrganizationId): ViewTypeI =>
    purchaseRequestSupplier === currentOrgId ? ViewTypeI.supplier : ViewTypeI.buyer */

const editablePurchaseRequestStatuses: Partial<Record<PurchaseRequestStatus, boolean>> = {
    [PurchaseRequestStatus.DRAFT]: true,
    [PurchaseRequestStatus.SUBMITTED]: true,
}

export const PurchaseRequestView: React.FC<ViewProps> = ({ edit, newPR, organization, purchaseRequest: PR }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const loading = useAppSelector(selectPurchaseRequestsLoading)
    const { updatePR, loading: updateLoading } = useUpdatePurchaseRequest(organization.id, PR.id)
    const shouldUpdatePR = useAppSelector(selectShouldUpdatePR)
    const navigate = useNavigate()
    /* const view = getPRSideFromSupplierId(PR.supplierId, organization.id) */
    const brandName = usePartnerBrandname(PR.supplierId, PR?.supplierName)

    const { fetchPurchaseRequest } = useFetchPurchaseRequest(organization.id)
    const { currentUserCanValidate, approve, refuse, submit } = usePurchaseRequestValidationActions(PR)

    const [draftDocuments, setDraftDocuments] = useState<DraftDocumentI[]>([])
    const [draftBudgets, setDraftBudgets] = useState<BudgetDataWithMetricsI[]>([])

    const pageMode = edit || newPR ? PurchaseViewType.EDIT : PurchaseViewType.VIEW

    const titleKey = pageMode === PurchaseViewType.EDIT ? "titleEdit" : "titleView"
    const title = formatMessage(messages[newPR ? "titleNew" : titleKey])

    const documentsObjectType = DocumentObjectType.PURCHASE_REQUEST

    const [showModalSubmit, setShowModalSubmit] = useState<boolean>(false)

    const { tagGroups } = useOrganizationTagGroups(organization?.id)
    const {
        selectedTags: tags,
        setSelectedTags: setTags,
        fetchTags,
    } = useTagsForLines({
        lines: PR.lines,
        organizationId: organization?.id,
        tagGroups,
        objectType: TagObjectType.PURCHASE_REQUEST_LINE,
    })

    useEffect(() => {
        return () => {
            dispatch(purchaseRequestsActions.resetData())
            dispatch(tagsActions.resetPendingTags())
        }
    }, [dispatch])

    useEffect(() => {
        if (shouldUpdatePR && !updateLoading) {
            updatePR().then(() => {
                dispatch(purchaseRequestsActions.setShouldUpdatePR(false))
            })
        }
    }, [updatePR, shouldUpdatePR, updateLoading, dispatch])

    useEffect(() => {
        if (tagGroups && tagGroups.length) {
            fetchTags()
        }
    }, [fetchTags, tagGroups])

    const handleSubmit = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault()
            submit?.()
            window.setTimeout(() => {
                navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: PR.id }))
                setShowModalSubmit(false)
            }, 500)
        },
        [PR.id, navigate, submit]
    )

    const handleChangeDescription = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            dispatch(
                purchaseRequestsActions.updateData({
                    field: "description",
                    value: e.target.value,
                })
            )
        },
        [dispatch]
    )

    const handleUpdateAddressesOrContact = useCallback(
        (payload: SavePayloadType) => {
            if (payload.sendingAddress) {
                dispatch(
                    purchaseRequestsActions.updateData({
                        field: "shippingAddress",
                        value: organizationAddressToAddress(payload.sendingAddress),
                    })
                )
            }
            if (payload.contact) {
                dispatch(
                    purchaseRequestsActions.updateData({
                        field: "supplier",
                        value: payload.contact,
                    })
                )
            }
            dispatch(purchaseRequestsActions.setShouldUpdatePR(true))
        },
        [dispatch]
    )

    const handleReviewed = useCallback(
        (isApproved: boolean) => {
            if (!currentUserCanValidate) return
            isApproved ? approve?.() : refuse?.()
        },
        [approve, refuse, currentUserCanValidate]
    )

    const handleAddToBudgetSuccess = useCallback(async () => {
        fetchPurchaseRequest(PR.id)
    }, [dispatch, PR])

    const buyerOrganization = useMemo(
        () => ({
            id: organization.id,
            name: organization.name,
            type: ViewTypeI.buyer,
        }),
        [organization]
    )

    const supplierOrganization = useMemo(
        () => ({
            id: PR.supplierId ?? null,
            name: brandName,
            type: ViewTypeI.supplier,
        }),
        [PR.supplierId, brandName]
    )

    const otherOrganizations: OrganizationId[] = [] // Purchase requests are not shared between organizations

    const renderLineTags = useCallback(
        (line: PurchaseRequestLine, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => {
            if (!user || !organization) return null

            if (line.id) {
                return (
                    <TagsSelectorForLineCells
                        objectId={line.id}
                        tags={tags}
                        setTags={setTags}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            if (line.temporaryId) {
                return (
                    <TagsSelectorWithStateForLineCells
                        temporaryId={line.temporaryId}
                        tagGroups={tagGroups ?? []}
                        tagGroupId={tagGroupId ?? ""}
                        usedTagGroups={usedTagGroups ?? []}
                    />
                )
            }

            return null
        },
        [user, organization, tags, setTags, tagGroups]
    )

    const tagSelectorContext = useMemo(
        () => ({
            page: "purchase-request",
            purchaseRequest: PR,
            organization,
            user,
        }),
        [PR, organization, user]
    )

    if (!organization) {
        return null
    }

    const showActions = pageMode === PurchaseViewType.VIEW && !PR.purchaseOrder

    return (
        <div className="purchase-page">
            <HeaderH1 title={title + (!newPR ? "#" + PR.number : "")} backLink={PURCHASE_REQUESTS_ROUTE}>
                {newPR ? (
                    <ActionsHeaderCreate
                        organizationId={organization.id}
                        draftDocuments={draftDocuments}
                        draftBudgets={draftBudgets}
                        setDraftDocuments={setDraftDocuments}
                        setDraftBudgets={setDraftBudgets}
                    />
                ) : (
                    <ActionsHeader
                        PR={PR}
                        id={PR.id}
                        status={PR.status}
                        organizationId={PR.organizationId}
                        mode={pageMode}
                        isConvertedToPO={!!PR.purchaseOrder}
                        permissions={PR.permissions}
                    />
                )}
            </HeaderH1>

            <Box className="main-box">
                {loading ? (
                    <div className="loader-wrapper">
                        <Loader fullscreen />
                    </div>
                ) : (
                    <>
                        <Grid container spacing={0} gap={2}>
                            <StepsPurchaseRequest PR={PR} />

                            <ResizableGrid stateKey={STATE_KEY}>
                                <ResizableCol minSize={200} maxSize={400} defaultSize={300}>
                                    <PurchaseRequestLeftColumn
                                        PR={PR}
                                        newPR={newPR}
                                        pageMode={pageMode}
                                        updatePR={updatePR}
                                    />
                                </ResizableCol>

                                <ResizableCol minSize={400}>
                                    <Stack gap={1}>
                                        <OrganizationsLazy
                                            to={supplierOrganization}
                                            from={buyerOrganization}
                                            updateAddressesOrContact={handleUpdateAddressesOrContact}
                                            shippingAddress={PR.shippingAddress}
                                            readOnly={!editablePurchaseRequestStatuses[PR.status]}
                                            showOrganizationSelector={
                                                !PR.supplierId ||
                                                (pageMode === PurchaseViewType.EDIT &&
                                                    !(PR.purchaseOrder && PR.purchaseOrder.id))
                                            }
                                            objectType={DocumentObjectType.PURCHASE_REQUEST}
                                        />
                                        <Description
                                            description={PR.description}
                                            pageMode={pageMode}
                                            handleChange={handleChangeDescription}
                                        />

                                        <LinesTabs
                                            items={PR.lines}
                                            idKey="id"
                                            temporaryIdKey="temporaryId"
                                            contextType={CustomFieldObjectType.PURCHASE_REQUEST}
                                            contextId={PR.id}
                                            currency={PR.currency}
                                            organizationId={organization.id}
                                            renderLineTags={renderLineTags}
                                        >
                                            <Items
                                                mode={pageMode}
                                                id={PR.id}
                                                organizationId={PR.organizationId}
                                                lines={PR.lines}
                                                totalAmount={PR.totalAmount ?? 0}
                                                totalAmountExcludingTax={PR.totalAmountExcludingTax}
                                                currency={PR.currency}
                                                status={PR.status}
                                                permissions={PR.permissions}
                                            />
                                        </LinesTabs>
                                    </Stack>
                                </ResizableCol>

                                {organization && (
                                    <ResizableCol minSize={200} maxSize={400} defaultSize={300}>
                                        <Stack gap={1} className="column">
                                            {showActions && (
                                                <ApprovalBlock
                                                    organisationId={PR.organizationId}
                                                    objectId={PR.id}
                                                    objectType={ApprovalObjectType.PURCHASE_REQUEST}
                                                    onReviewed={handleReviewed}
                                                    displayType="block"
                                                />
                                            )}
                                            <TransactionInBudgets
                                                organizationId={organization.id}
                                                transaction={PR}
                                                objectType={TransactionType.PURCHASE_REQUEST}
                                                withGauge
                                                handleSuccess={handleAddToBudgetSuccess}
                                                draftBudgets={draftBudgets}
                                                setDraftBudgets={setDraftBudgets}
                                            />
                                            {!newPR ? (
                                                <>
                                                    <Card title={formatMessage(messages.tagsTitle)} expandable>
                                                        <TagsSelector
                                                            organizationId={organization.id}
                                                            objectId={PR.id}
                                                            userId={user.id}
                                                            objectType={TagObjectType.PURCHASE_REQUEST}
                                                            context={tagSelectorContext}
                                                        />
                                                    </Card>
                                                    <Documents
                                                        objectId={PR.id}
                                                        objectType={DocumentObjectType.PURCHASE_REQUEST}
                                                        organizationId={organization.id}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Card title={formatMessage(messages.tagsTitle)} expandable>
                                                        <TagsForCreatingPurchaseRequest
                                                            organizationId={organization.id}
                                                        />
                                                    </Card>
                                                    <Documents
                                                        organizationId={organization?.id || ""}
                                                        objectType={documentsObjectType}
                                                        draftDocuments={draftDocuments}
                                                        setDraftDocuments={setDraftDocuments}
                                                    />
                                                </>
                                            )}
                                            {PR.id && (
                                                <CommunicationRoom
                                                    organizationId={organization.id}
                                                    objectId={PR.id}
                                                    objectType={SharedObjectType.PurchaseRequest}
                                                    otherOrganizations={otherOrganizations}
                                                />
                                            )}
                                        </Stack>
                                    </ResizableCol>
                                )}
                            </ResizableGrid>
                        </Grid>

                        <ModalConfirm
                            message={formatMessage(messages.submitForApproval)}
                            icon={
                                <div className="icon-container green">
                                    <CheckCircle size={30} />
                                </div>
                            }
                            button1={
                                <Button type="neutral" onClick={() => setShowModalSubmit(false)}>
                                    {formatMessage(messages.saveAsDraft)}
                                </Button>
                            }
                            button2={
                                <Button type="primary" onClick={handleSubmit}>
                                    {formatMessage(messages.saveAndSend)}
                                </Button>
                            }
                            open={showModalSubmit}
                        />
                    </>
                )}
            </Box>
        </div>
    )
}

export const PurchaseRequest = withSocketIOProvider<Props>(({ edit = false }: Props) => {
    const navigate = useNavigate()
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const { purchaseRequestId } = useParams()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)

    const organization = useAppSelector(selectCurrentOrganization)
    const user = useAppSelector(selectUser)
    const { s, o: sharedOrganizationId, u: sharedUserId } = useQueryParams()
    const isFromSharedEmail = !!(s && s === "t")

    const purchaseRequestOrganizationId =
        isFromSharedEmail && sharedOrganizationId ? sharedOrganizationId : organization?.id
    const { purchaseRequest: PR, changedOrganization } = usePurchaseRequest(
        purchaseRequestOrganizationId,
        purchaseRequestId
    )
    const error = useAppSelector(selectPurchaseRequestsError)

    const isFromSharedObject = useMemo(
        () => isFromSharedEmail && sharedOrganizationId && sharedUserId && user.id === sharedUserId,
        [isFromSharedEmail, sharedOrganizationId, sharedUserId, user.id]
    )

    useEffect(() => {
        if (isFromSharedObject && purchaseRequestId) {
            const organizationAlreadyExists = !!user.organizations.find((org) => org.id === sharedOrganizationId)
            if (organizationAlreadyExists) {
                dispatch(organizationActions.setCurrentOrganization(sharedOrganizationId))
                navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId }))
            }
        }
    }, [user, isFromSharedObject, sharedOrganizationId, purchaseRequestId, dispatch, navigate])

    useEffect(() => {
        if (error) {
            toast.error(changedOrganization ? formatMessage(messages.errorChangedOrganization) : error)
            navigate(PURCHASE_REQUESTS_ROUTE)
        }
    }, [error, changedOrganization, formatMessage, navigate])

    if (!organization) {
        return null
    }
    return <PurchaseRequestView edit={edit} organization={organization} purchaseRequest={PR} />
})

export function PurchaseRequestNew() {
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)
    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectCurrentOrganization)

    useEffect(() => {
        dispatch(purchaseRequestsActions.resetData())
        dispatch(tagsActions.resetPendingTags())

        return () => {
            dispatch(purchaseRequestsActions.resetData())
            dispatch(tagsActions.resetPendingTags())
        }
    }, [dispatch])

    const purchaseRequest = useCreateDraftPurchaseRequest(organization?.id)

    if (!organization || !purchaseRequest) {
        return null
    }
    return (
        <PurchaseRequestView edit={true} newPR={true} organization={organization} purchaseRequest={purchaseRequest} />
    )
}
