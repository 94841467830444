import { Box } from "@mui/material"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Card, Loader, SafeFormattedMessage } from "~/components"
import { ErrorMessage } from "~/components/ErrorMessage"
import { LightButton } from "~/components/LightButton"
import { UploadDocumentModal } from "~/components/UploadDocument/UploadDocument"
import { DocumentDataI, DraftDocumentI } from "~/components/UploadDocument/core/Documents"
import { useFetchObjectDocuments } from "~/components/UploadDocument/hooks/useFetchObjectDocuments"
import { DocumentObjectType } from "~/domains/identity/documents/types"
import { OrganizationId } from "~/types"

import "./Documents.scss"
import { DocumentsList } from "./DocumentsList"

const messages = defineMessages({
    title: {
        id: "documents.files.title",
        defaultMessage: "Documents",
    },
    addButton: {
        id: "documents.files.addButton",
        defaultMessage: "Add +",
    },
    fileName: {
        id: "common.name",
        defaultMessage: "Name",
    },
})

type DocumentsProps = {
    objectId?: string
    organizationId: OrganizationId
    objectType: DocumentObjectType
    readonly?: boolean
} & (
    | {
          draftDocuments?: undefined
          setDraftDocuments?: undefined
          objectId: string
      }
    | {
          draftDocuments: DraftDocumentI[]
          setDraftDocuments: Dispatch<SetStateAction<DraftDocumentI[]>>
          objectId?: undefined
      }
)

export const Documents: React.FC<DocumentsProps> = ({
    objectId,
    organizationId,
    objectType,
    draftDocuments,
    setDraftDocuments,
    readonly = false,
}) => {
    const { formatMessage } = useIntl()
    const [showUploadDocumentModal, setShowUploadDocumentModal] = useState<boolean>(false)
    const [displayableDocuments, setDisplayableDocuments] = useState<DocumentDataI[] | DraftDocumentI[]>([])
    const { documents, loading: documentsLoading, loadingUpload, error } = useFetchObjectDocuments(objectId ?? "")

    useEffect(() => {
        if (objectId) {
            setDisplayableDocuments(documents)
        } else if (draftDocuments) {
            setDisplayableDocuments(draftDocuments)
        }
    }, [draftDocuments, documents, objectId, documentsLoading, error])

    const displayUploadDocumentModal = useCallback(() => {
        setShowUploadDocumentModal(true)
    }, [])

    const hideUploadDocumentModal = useCallback(() => {
        setShowUploadDocumentModal(false)
    }, [])

    const handleAddDocument = () => {
        displayUploadDocumentModal()
    }

    return (
        <Card
            header={
                <Box display="flex" alignItems="center" gap={1}>
                    <h4>{formatMessage(messages.title)}</h4>
                    {!error && !readonly && (
                        <LightButton onClick={handleAddDocument}>{formatMessage(messages.addButton)}</LightButton>
                    )}
                </Box>
            }
            expandable
        >
            {error ? (
                <ErrorMessage>
                    <div>
                        <SafeFormattedMessage {...commonMessages.error} />
                    </div>
                </ErrorMessage>
            ) : (
                <>
                    {loadingUpload && draftDocuments && draftDocuments.length > 0 && <Loader fullscreen />}
                    {documentsLoading ? (
                        <Loader small />
                    ) : (
                        <DocumentsList documents={displayableDocuments} setDraftDocuments={setDraftDocuments} />
                    )}
                    <UploadDocumentModal
                        organizationId={organizationId}
                        objectId={objectId}
                        objectType={objectType}
                        open={showUploadDocumentModal}
                        close={hideUploadDocumentModal}
                        setDraftDocuments={setDraftDocuments}
                    />
                </>
            )}
        </Card>
    )
}
