import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import {
    useAddObjectToBatchMutation,
    useGetAssignedObjectsByObjectIdQuery,
} from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { ObjectAssignForm, ObjectAssignInfo } from "~/domains/payment/payment-batches/components"
import { AssignedObject } from "~/domains/payment/payment-batches/types"

const messages = defineMessages({
    paymentBatch: {
        id: "payment.batches.paymentBatch",
        defaultMessage: "Payment batch",
    },
    addToBatch: {
        id: "payment.batches.addToBatch",
        defaultMessage: "Add to batch",
    },
    addToBatchSuccess: {
        id: "payment.batches.addToBatchSuccess",
        defaultMessage: "Object added to batch successfully",
    },
})

interface ObjectAssignPickerProps {
    objectId: string
    payload: AssignedObject
}

export const ObjectAssignPicker = ({ objectId, payload }: ObjectAssignPickerProps) => {
    const { formatMessage } = useIntl()
    const { data: objectsData, isLoading: isLoadingObjects } = useGetAssignedObjectsByObjectIdQuery(
        { objectId },
        { skip: !objectId }
    )
    const assignedObjects = objectsData?.items ?? []
    const assignedObject = assignedObjects[0] ?? null

    const [addToBatch, { isLoading: isAddingToBatch }] = useAddObjectToBatchMutation()

    const handleAddToBatch = async (batchId: string, dateScheduled: string) => {
        const newObject = await addToBatch({
            assignedObject: {
                ...payload,
                dateScheduled,
            },
            batchId,
        }).unwrap()

        if (newObject?.id) {
            toast.success(formatMessage(messages.addToBatchSuccess))
        }
    }

    if (isLoadingObjects) {
        return <Loader small />
    }

    return assignedObject ? (
        <ObjectAssignInfo assignedObject={assignedObject} />
    ) : (
        <ObjectAssignForm loading={isAddingToBatch} onSubmit={handleAddToBatch} />
    )
}
