import { CurrencyCodes } from "~/types"

export enum PaymentBatchInstanceStatus {
    ACTIVE = "Active",
    DISABLED = "Disabled",
    EXECUTED = "Executed",
    FAILED = "Failed",
    UPLOADED = "Uploaded",
    VALIDATED = "Validated",
    CANCELLED = "Cancelled",
}

export interface PaymentBatchInstance {
    id: string
    paymentTransactionBatchId: string
    name: string
    ownerId: string
    dateScheduled: string | null
    dateExecuted: string | null
    status: PaymentBatchInstanceStatus
    totalAmount: number | null
    maxTotalAmount: number | null
    responsibleId: string | null
    currency: CurrencyCodes
    paymentMethodId?: string | null
    paymentMethodDetailsId?: string | null
}

export interface ServerPaymentBatchInstance {
    id: string
    payment_transaction_batch_id: string
    name: string
    owner_id: string
    date_scheduled: string | null
    date_executed: string | null
    status: PaymentBatchInstanceStatus
    total_amount: number | null
    max_total_amount: number | null
    responsible_id: string | null
    currency: CurrencyCodes
    payment_method_id?: string | null
    payment_method_details_id?: string | null
}

export type CreatePaymentBatchInstance = Omit<PaymentBatchInstance, "id"> & { id?: string }
