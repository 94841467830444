import { Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent, Tooltip, capitalize } from "@mui/material"
import classNames from "classnames"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Info, Trash2 } from "react-feather"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { ConfirmModal } from "~/components"
import {
    SpiceDBAuthorizationName,
    SpiceDBObjectAuthorizationName,
    SpiceDBObjectType,
    sharedObjectPermissionsModalMessages,
    spiceDBAuthorizationMessages,
} from "~/domains/identity/roles-permissions/types/SpiceDB"
import {
    hasUserAuthorization,
    removeAllPermissionsExceptUnauthorized,
    removeAllUnauthorizedPermissions,
    renderSelectValue,
} from "~/domains/identity/roles-permissions/utils/spiceDB"
import { isDefined } from "~/utils/isDefined"

import "./PermissionsSelect.scss"

const messages = defineMessages({
    removeAll: { id: "share.object.permissions.object.removeAll", defaultMessage: "Remove all permissions" },
})

const orderPermissions = (permissions: SpiceDBAuthorizationName[]) => {
    const permissionSet = new Set(permissions)

    return Object.values(SpiceDBAuthorizationName).filter((authorization) => permissionSet.has(authorization))
}

interface Props {
    currentUserPermissions: SpiceDBAuthorizationName[]
    objectPermissions: SpiceDBAuthorizationName[]
    objectType: SpiceDBObjectType
    handleChangePermission: (permissions: SpiceDBAuthorizationName[]) => void
    canSelect?: boolean
    showRemoveAll?: boolean
}

const renderMenuItem = (
    authorization: SpiceDBAuthorizationName,
    permissionsSelected: SpiceDBAuthorizationName[],
    text: string,
    disabled: boolean
) => {
    return (
        <MenuItem
            className="share-permissions-select-option"
            key={authorization}
            value={authorization}
            disabled={disabled}
        >
            <Checkbox checked={permissionsSelected.indexOf(authorization) > -1} />
            <ListItemText primary={text} />
        </MenuItem>
    )
}

const renderAuthorizationItems = (
    permissionsSelected: SpiceDBAuthorizationName[],
    authorizations: SpiceDBAuthorizationName[],
    currentUserPermissions: SpiceDBAuthorizationName[],
    isUnauthorized: boolean,
    formatMessage: (message: MessageDescriptor) => string
) =>
    authorizations.map((authorization) =>
        isUnauthorized && permissionsSelected.indexOf(authorization) === -1
            ? null
            : renderMenuItem(
                  authorization,
                  permissionsSelected,
                  capitalize(formatMessage(spiceDBAuthorizationMessages[authorization])),
                  isUnauthorized || !hasUserAuthorization(currentUserPermissions, authorization)
              )
    )

export const PermissionsSelect: React.FC<Props> = ({
    currentUserPermissions,
    objectPermissions,
    objectType,
    handleChangePermission,
    canSelect = true,
    showRemoveAll = true,
}) => {
    const { formatMessage } = useIntl()
    const [permissionsSelected, setPermissionsSelected] = useState<SpiceDBAuthorizationName[]>([])
    const [confirmRemoveAllPermissions, setConfirmRemoveAllPermissions] = useState<boolean>(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const authorizedAuthorizations = useMemo(
        () => SpiceDBObjectAuthorizationName[objectType]?.authorized ?? [],
        [objectType]
    )

    const unauthorizedAuthorizations = useMemo(
        () => SpiceDBObjectAuthorizationName[objectType]?.unauthorized ?? [],
        [objectType]
    )

    const hasOwnerOrCheckerPermission = useMemo(() => {
        return (
            permissionsSelected.includes(SpiceDBAuthorizationName.OWNER) ||
            permissionsSelected.includes(SpiceDBAuthorizationName.CHECK)
        )
    }, [permissionsSelected])

    useEffect(() => {
        setPermissionsSelected(orderPermissions(objectPermissions))
    }, [objectPermissions])

    const onPermissionsChange = useCallback(
        (event: SelectChangeEvent<SpiceDBAuthorizationName[]>) => {
            const {
                target: { value },
            } = event

            if ((value as string[]).includes("removeAll")) {
                return
            }

            const newPermissions =
                typeof value === "string" ? (value.split(",").filter(isDefined) as SpiceDBAuthorizationName[]) : value
            handleChangePermission(newPermissions)
            setPermissionsSelected(newPermissions)
        },
        [setPermissionsSelected, handleChangePermission]
    )

    const showConfirmRemoveAllPermissions = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault()
            if (removeAllUnauthorizedPermissions(objectType, permissionsSelected, currentUserPermissions).length) {
                setConfirmRemoveAllPermissions(true)
            }
        },
        [objectType, permissionsSelected, currentUserPermissions, setConfirmRemoveAllPermissions]
    )
    const hideConfirmRemoveAllPermissions = () => setConfirmRemoveAllPermissions(false)

    const handleRemoveAllPermissions = useCallback(() => {
        handleChangePermission(
            removeAllPermissionsExceptUnauthorized(objectType, permissionsSelected, currentUserPermissions)
        )
        handleClose()
        return true
    }, [permissionsSelected, objectType, currentUserPermissions, handleChangePermission])

    const handleOpen = () => setIsDropdownOpen(true)
    const handleClose = () => setIsDropdownOpen(false)

    const renderValueHandler = useCallback(
        (selected: SpiceDBAuthorizationName[]) =>
            renderSelectValue(
                selected,
                canSelect ? sharedObjectPermissionsModalMessages.permissions : null,
                formatMessage
            ),
        [formatMessage, canSelect]
    )

    const classes = classNames("share-permissions-select", { disabled: !canSelect })

    return (
        <>
            <Select
                className={classes}
                multiple
                displayEmpty
                value={permissionsSelected}
                renderValue={renderValueHandler}
                onChange={onPermissionsChange}
                disabled={!canSelect}
                open={isDropdownOpen}
                onClose={handleClose}
                onOpen={handleOpen}
            >
                {renderAuthorizationItems(
                    permissionsSelected,
                    unauthorizedAuthorizations,
                    currentUserPermissions,
                    true,
                    formatMessage
                )}
                {renderAuthorizationItems(
                    permissionsSelected,
                    authorizedAuthorizations,
                    currentUserPermissions,
                    false,
                    formatMessage
                )}
                {showRemoveAll && (
                    <MenuItem
                        className="share-permissions-select-option remove-option"
                        onClick={showConfirmRemoveAllPermissions}
                        value="removeAll"
                    >
                        <div className="remove-option-container">
                            <Trash2 size={16} />
                            <ListItemText primary={formatMessage(messages.removeAll)} />
                            {hasOwnerOrCheckerPermission && (
                                <Tooltip
                                    title={formatMessage(sharedObjectPermissionsModalMessages.removeAllTooltip)}
                                    arrow
                                    placement="top"
                                >
                                    <Info color="var(--color-grey)" size={16} />
                                </Tooltip>
                            )}
                        </div>
                    </MenuItem>
                )}
            </Select>
            <ConfirmModal
                open={confirmRemoveAllPermissions}
                close={hideConfirmRemoveAllPermissions}
                onConfirm={handleRemoveAllPermissions}
                title={formatMessage(sharedObjectPermissionsModalMessages.confirmRemoveAllPermissionsTitle)}
            >
                {formatMessage(sharedObjectPermissionsModalMessages.confirmRemoveAllPermissions)}
            </ConfirmModal>
        </>
    )
}
