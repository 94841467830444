import { Stack, TextField, Typography } from "@mui/material"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal, SafeFormattedMessage } from "~/components"

interface Props {
    open: boolean
    close: () => void
    organizationId: string
    onFormCreated: () => void
}

const messages = defineMessages({
    newForm: {
        id: "smartForms.headingNewForm",
        defaultMessage: "New form",
    },
    newFormName: {
        id: "smartForms.newFormName",
        defaultMessage: "Form name",
    },
})

export const CreateCustomFormModal = ({ open, close, onFormCreated }: Props) => {
    const handleCreateForm = () => {
        // for now jsut close the modal and redirect to the new form page
        onFormCreated()
    }
    return (
        <Modal open={open} onClose={close}>
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    <SafeFormattedMessage {...messages.newForm} />
                </Typography>
            </Modal.Header>
            <Stack>
                <TextField label={<SafeFormattedMessage {...messages.newFormName} />} variant="outlined" fullWidth />
            </Stack>
            <Modal.Footer className="mt-12">
                <Button onClick={handleCreateForm}>
                    <SafeFormattedMessage {...commonMessages.create} />
                </Button>

                <Button onClick={close} type="neutral">
                    <SafeFormattedMessage id="common.close" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
