import { useMemo, useState } from "react"
import { Edit, Trash } from "react-feather"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { ActionsMenu, ConfirmModal, Loader, SafeFormattedMessage } from "~/components"
import { PaymentBatchAddAndEditModal } from "~/domains/payment/payment-batches/components"
import { usePaymentBatchesManagement } from "~/domains/payment/payment-batches/hooks"
import { PaymentBatch } from "~/domains/payment/payment-batches/types"

const messages = defineMessages({
    deleteBatchMessage: {
        id: "payment.batches.deleteBatchMessage",
        defaultMessage: "Are you sure you want to delete this batch?",
    },
})

interface BatchLineActionsProps {
    row: PaymentBatch
}

export const BatchLineActions = ({ row }: BatchLineActionsProps) => {
    const [open, setOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const { isFetching, deleteBatchById } = usePaymentBatchesManagement(
        { organizationId: row.ownerId ?? "" },
        { skipList: true }
    )

    const handleEdit = () => {
        setEditOpen(true)
    }

    const handleDelete = async () => {
        const success = await deleteBatchById(row.id)
        if (success) {
            setOpen(false)
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleCloseEdit = () => {
        setEditOpen(false)
    }

    const handleOpenDeleteConfirmation = () => {
        setOpen(true)
    }
    const actions = useMemo(
        () => [
            {
                label: <SafeFormattedMessage {...commonMessages.edit} />,
                icon: <Edit size={14} />,
                action: handleEdit,
            },
            {
                label: <SafeFormattedMessage {...commonMessages.delete} />,
                icon: <Trash size={14} />,
                action: handleOpenDeleteConfirmation,
            },
        ],
        [row]
    )

    return (
        <>
            <ActionsMenu actions={actions} />
            <ConfirmModal
                open={open}
                title={<SafeFormattedMessage {...messages.deleteBatchMessage} />}
                close={handleClose}
                onConfirm={handleDelete}
                {...(isFetching ? { confirmButtonText: <Loader small /> } : {})}
            />
            <PaymentBatchAddAndEditModal selectedItem={row} open={editOpen} onClose={handleCloseEdit} />
        </>
    )
}
