import { DataGridPremium, GridPaginationModel } from "@mui/x-data-grid-premium"
import { useMemo, useState } from "react"
import { defineMessages } from "react-intl"

import { Card } from "~/components"
import { DataGridNoRows } from "~/components/DataGrid/DataGridNoRows"
import { selectActiveTab } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodDetails } from "~/domains/payment/payment-method-details/types"
import { getGridColumnsByActiveTab } from "~/domains/payment/payment-method-details/utils/getGridColumnsByActiveTab"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppSelector } from "~/store/hooks"
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "~/utils"

const messages = defineMessages({
    noRows: {
        id: "payment.paymentMethodDetailsList.noRows",
        defaultMessage: "No payment methods",
    },
})

const renderNoRows = () => {
    return <DataGridNoRows message={messages.noRows} />
}

interface PaymentMethodDetailsListProps {
    items: PaymentMethodDetails[]
    rowsToApprove: PaymentMethodDetails[]
    loading?: boolean
}

export const PaymentMethodDetailsList = ({ items, rowsToApprove, loading = false }: PaymentMethodDetailsListProps) => {
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    })
    const activeTab = useAppSelector(selectActiveTab)
    const columns = useMemo(() => getGridColumnsByActiveTab(activeTab), [activeTab])
    const rows = useMemo(
        () =>
            activeTab === PaymentMethodType.TO_APPROVE
                ? rowsToApprove
                : items.filter((item) => item.payment_method_type === activeTab),
        [activeTab, items, rowsToApprove]
    )

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPaginationModel(model)
    }

    return (
        <Card>
            <DataGridPremium
                columns={columns}
                rows={rows}
                getRowId={(row) => row.id}
                rowCount={rows.length}
                loading={loading}
                rowSelection={false}
                autosizeOnMount
                slots={{ noResultsOverlay: renderNoRows, noRowsOverlay: renderNoRows }}
                pagination
                paginationMode="client"
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
            />
        </Card>
    )
}
