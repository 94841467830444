import { useCallback } from "react"

import { selectPendingTags, tagsActions } from "~/domains/analytics/tags/store/tagsSlice"
import { SelectedTagI } from "~/domains/analytics/tags/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

type TagsforLine = [
    SelectedTagI[],
    (tagsOrFunction: SelectedTagI[] | ((state: SelectedTagI[]) => SelectedTagI[])) => void,
]
export const useTagsForCreatingPurchaseRequest = (): TagsforLine => {
    const dispatch = useAppDispatch()
    const pendingTags = useAppSelector(selectPendingTags)
    const setPendingTags = useCallback(
        (tagsOrFunction: SelectedTagI[] | ((state: SelectedTagI[]) => SelectedTagI[])) => {
            dispatch(tagsActions.setPendingTags(tagsOrFunction))
        },
        [dispatch]
    )
    return [pendingTags, setPendingTags]
}
