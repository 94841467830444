import { CurrencyCodes } from "~/types"

export enum PaymentsTabsI {
    PAYMENTS = "",
    PAYMENT_BATCHES = "payment-batches",
    PAYMENT_BATCH_INSTANCES = "payment-batch-instances",
    PAYMENT_METHOD_DETAILS = "payment-method-details",
}

export enum BatchRecurrenceType {
    ONESHOT = "Oneshot",
    DAILY = "Daily",
    WEEKLY = "Weekly",
    MONTHLY = "Monthly",
    QUARTERLY = "Quarterly",
    YEARLY = "Yearly",
    CUSTOM = "Custom",
}

export interface PaymentBatch {
    id: string
    name: string
    description?: string
    recurrenceType: BatchRecurrenceType
    startDate: string | null
    endDate?: string | null
    creatorUserId?: string | null
    recurrenceInterval?: number | null
    aggregatePayments: boolean
    collectPayments: boolean
    ownerId: string | null
    defaultTotalAmount: number | null
    defaultCurrency: CurrencyCodes
    defaultPaymentMethodId?: string | null
    defaultPaymentMethodDetailsId?: string | null
}

export type CreatePaymentBatch = Omit<PaymentBatch, "id"> & { id?: string }

export interface ServerPaymentBatch {
    id: string
    name: string
    description?: string
    recurrence_type: BatchRecurrenceType
    start_date: string | null
    end_date?: string | null
    recurrence_interval?: number | null
    creator_user_id?: string | null
    aggregate_payments: boolean
    collect_payments: boolean
    owner_id: string | null
    default_total_amount: number | null
    default_currency: CurrencyCodes
    default_payment_method_id?: string | null
    default_payment_method_details_id?: string | null
}

export type ServerCreatePaymentBatch = Omit<ServerPaymentBatch, "id"> & { id?: string }
