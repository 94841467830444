import { GlobalFilterType, TagFilterProps } from "~/store/global/globalSlice"

export const isTagFilterProps = (props: any): props is TagFilterProps => "tagGroups" in props

export const getFilterProps = (type: GlobalFilterType, props: any): TagFilterProps | null => {
    switch (type) {
        case GlobalFilterType.TAGS:
            if (isTagFilterProps(props)) {
                return props
            }
            return null
        default:
            return null
    }
}
