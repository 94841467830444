import { Box } from "@mui/material"
import { useMemo, useState } from "react"
import { Plus } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { ActionsMenu, Button, DatatableFiltersActions } from "~/components"
import { PaymentMethodDetailsCreateOrEditModal } from "~/domains/payment/payment-method-details/components/modal/PaymentMethodDetailsCreateOrEditModal"
import { PaymentMethodDetailsI } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const messages = defineMessages({
    createPaymentMethodDetails: {
        id: "payment.paymentMethodDetails.newMethod",
        defaultMessage: "New method",
    },
    uploadPaymentMethodDetails: {
        id: "payment.paymentMethodDetails.upload.title",
        defaultMessage: "Upload a payment method",
    },
})

interface PaymentMethodDetailsActionsProps {
    organizationId: string
    activeTab: PaymentMethodType
    onPaymentMethodDetailsCreated?: (data: PaymentMethodDetailsI) => void
}

export const PaymentMethodDetailsActions = ({
    organizationId,
    activeTab,
    onPaymentMethodDetailsCreated,
}: PaymentMethodDetailsActionsProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const [createModalVisible, setCreateModalVisible] = useState(false)

    const openCreatePaymentMethodDetailsModal = () => setCreateModalVisible(true)
    const closeCreatePaymentMethodDetailsModal = () => setCreateModalVisible(false)

    const actions: DatatableFiltersActions[] = useMemo(() => {
        const handleCreatePaymentMethodDetails = () => {
            openCreatePaymentMethodDetailsModal()
        }

        return [{ label: formatMessage(messages.createPaymentMethodDetails), action: handleCreatePaymentMethodDetails }]
    }, [formatMessage, navigate])

    return (
        <>
            {actions.length > 1 ? (
                <ActionsMenu actions={actions} labelButton="Add" buttonType="primary" size="small" />
            ) : (
                <Button onClick={actions[0].action} size="small">
                    <Plus size={16} />
                    <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>{actions[0].label}</Box>
                </Button>
            )}

            <PaymentMethodDetailsCreateOrEditModal
                open={createModalVisible}
                activeTab={activeTab}
                organizationId={organizationId}
                onPaymentMethodDetailsCreated={onPaymentMethodDetailsCreated}
                onClose={closeCreatePaymentMethodDetailsModal}
                onOpen={openCreatePaymentMethodDetailsModal}
            />
        </>
    )
}
