import { Box, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { Briefcase, Check, Clock, User, X } from "react-feather"
import { useIntl } from "react-intl"

import {
    ApprovalCheckI,
    ApprovalReviewerI,
    ApprovalReviewerType,
} from "~/domains/orchestration/flows-v0/types/Approval"
import { OrganizationTeamI, UserI } from "~/types"

import { refusalMessages } from "./RefusalReason"

interface ReviewerRowProps {
    reviewer: ApprovalReviewerI
    check: ApprovalCheckI
    users: UserI[]
    teams: OrganizationTeamI[]
}

interface ReviewStatusProps {
    check: ApprovalCheckI
    id: string
    reviewerTeamUsersIds: string[]
}
// Status icon component
const ReviewStatus: FC<ReviewStatusProps> = ({ check, id, reviewerTeamUsersIds }) => {
    if (check.review.approvers.some((review) => review.userId === id || reviewerTeamUsersIds.includes(review.userId))) {
        return <Check data-testid="check-icon" color="var(--color-green)" size={16} />
    }
    if (check.review.refusers.some((review) => review.userId === id || reviewerTeamUsersIds.includes(review.userId))) {
        return <X data-testid="x-icon" color="var(--color-red)" size={16} />
    }
    return <Clock data-testid="clock-icon" color="var(--color-grey-light)" size={16} />
}

// Main component
export const ReviewerRow: FC<ReviewerRowProps> = ({ reviewer, check, users, teams }: ReviewerRowProps) => {
    const { formatMessage } = useIntl()
    const isUser = reviewer.type === ApprovalReviewerType.USER

    const entity = isUser
        ? users.find((user) => user.id === reviewer.userId)
        : teams.find((team) => team.teamId === reviewer.teamId)

    if (!entity) return null

    const reviewerTeamUsersIds = isUser
        ? []
        : (teams.find((team) => team.teamId === reviewer.teamId)?.members.map((member) => member.userId) ?? [])

    const IconComponent = isUser ? User : Briefcase
    const id = isUser ? (entity as UserI).id : (entity as OrganizationTeamI).teamId
    const name = isUser ? (entity as UserI).fullName : (entity as OrganizationTeamI).name

    const refuser = check.review.refusers.find((r) => r.userId === id || reviewerTeamUsersIds.includes(r.userId))
    const userRefusalReason = refuser?.refusalReason?.reason ?? ""
    const userRefusalMessage = refuser?.refusalReason?.message ?? ""

    return (
        <Stack direction="row" gap={1} key={id}>
            <Stack direction="row" gap={1} flexShrink={0} alignItems="top">
                <IconComponent size={16} color="var(--color-grey-light)" />
                <Typography variant="caption" lineHeight="var(--font-line-height-sm)">
                    {name}
                </Typography>
                <Box>
                    <ReviewStatus check={check} id={id} reviewerTeamUsersIds={reviewerTeamUsersIds} />
                </Box>
                {userRefusalReason && (
                    <Typography
                        variant="caption"
                        color="var(--color-red)"
                        fontWeight="bold"
                        lineHeight="var(--font-line-height-sm)"
                    >
                        {refusalMessages[userRefusalReason]
                            ? formatMessage(refusalMessages[userRefusalReason])
                            : userRefusalReason}
                    </Typography>
                )}
            </Stack>
            {userRefusalMessage && (
                <Typography variant="caption" lineHeight="var(--font-line-height-sm)">
                    {userRefusalMessage}
                </Typography>
            )}
        </Stack>
    )
}
