import { Link } from "@mui/material"
import { FC } from "react"
import { generatePath } from "react-router-dom"

import { InvoiceRelationI } from "~/domains/transactions/_shared/document-relations/types"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"

interface Props {
    invoiceRelation: InvoiceRelationI
    purchaseOrder?: PurchaseOrders | null
}

export const PurchaseOrderLink: FC<Props> = ({ invoiceRelation, purchaseOrder }) => {
    const label = purchaseOrder
        ? `PO #${purchaseOrder.shortId}${purchaseOrder.supplierName ? `: ${purchaseOrder.supplierName}` : ""}`
        : "PO"

    return (
        <Link href={generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: invoiceRelation.purchaseOrderId })}>
            {label}
        </Link>
    )
}
