import { IconButton, Stack, styled } from "@mui/material"
import { FC, useEffect, useRef, useState } from "react"
import { Command, StopCircle, Volume2 } from "react-feather"
import { useIntl } from "react-intl"

import FormattedText from "~/components/FormattedText/FormattedText"
import { Conversation } from "~/domains/orchestration/copilot/types/Copilot"

interface CopilotMessageProps {
    conversationMessage: Conversation
}

const StyledCommand = styled(Command)({
    flexBasis: 16,
    flexShrink: 0,
    marginTop: "var(--spacing-xxs)",
})

const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

const speechSynthesisAvailable = !!window.speechSynthesis

export const CopilotMessage: FC<CopilotMessageProps> = ({ conversationMessage }) => {
    const { locale } = useIntl()
    const [activeSpeechSynthesis, setActiveSpeechSynthesis] = useState(false)
    const activeSpeechSynthesisRef = useRef(activeSpeechSynthesis)

    useEffect(() => {
        activeSpeechSynthesisRef.current = activeSpeechSynthesis
    }, [activeSpeechSynthesis])

    const playMessage = () => {
        const utterance = new SpeechSynthesisUtterance(conversationMessage.message.replace(urlRegex, ""))
        utterance.lang = locale
        utterance.onerror = () => {
            setActiveSpeechSynthesis(false)
        }
        utterance.onpause = () => {
            setActiveSpeechSynthesis(false)
        }
        utterance.onresume = () => {
            setActiveSpeechSynthesis(true)
        }
        utterance.onend = () => {
            setActiveSpeechSynthesis(false)
        }
        utterance.onstart = () => {
            setActiveSpeechSynthesis(true)
        }
        window.speechSynthesis.cancel()
        window.speechSynthesis.speak(utterance)
    }
    useEffect(() => {
        if (conversationMessage.autoTextToSpeech && window.speechSynthesis) {
            playMessage()
        }
        return () => {
            if (activeSpeechSynthesisRef.current) {
                return window.speechSynthesis?.cancel()
            }
        }
    }, [])

    const cancelSpeechSynthesis = () => {
        if (speechSynthesisAvailable) {
            if (activeSpeechSynthesis) {
                return window.speechSynthesis.cancel()
            }
            playMessage()
        }
    }
    return (
        <Stack direction="row" gap={2} className="message-flowie">
            <StyledCommand size={16} color="var(--color-grey)" />
            <Stack gap={1}>
                {conversationMessage.message.split("\n").map((messageChunk, messageChunkKey) => (
                    <p key={messageChunkKey}>
                        <FormattedText text={messageChunk} />
                    </p>
                ))}
                {speechSynthesisAvailable && (
                    <Stack direction="row" gap={1}>
                        <IconButton onClick={cancelSpeechSynthesis} size="small">
                            {activeSpeechSynthesis ? <StopCircle size={12} /> : <Volume2 size={12} />}
                        </IconButton>
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}
