import { Feather } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { useParams } from "react-router-dom"

import { HeaderH1 } from "~/components"
import { FormEditor } from "~/domains/identity/custom-forms/components/FormEditor"

const messages = defineMessages({
    title: {
        id: "smartForms.formViewer.title",
        defaultMessage: "Form",
    },
})

export const CustomFormEditor = () => {
    const { formId } = useParams<{ formId: string }>()
    const { formatMessage } = useIntl()

    return (
        <>
            <HeaderH1 title={formatMessage(messages.title)} icon={<Feather />} />
            {formId && <FormEditor />}
        </>
    )
}
