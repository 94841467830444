import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { OrganizationPermission, RoleOrTemplate } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { RootState } from "~/store"

import { rolesPermissionsState } from "./rolesPermissionsState"

const initialState = rolesPermissionsState

const rolesPermissionsSlice = createSlice({
    name: "roles-permissions",
    initialState: initialState,
    reducers: {
        setPermissions(state, action: PayloadAction<OrganizationPermission[]>) {
            state.jwtPermissions = action.payload
        },
        reset() {
            return initialState
        },
        addPendingTeams(state, action: PayloadAction<string[]>) {
            state.pendingTeams = [...new Set([...state.pendingTeams, ...action.payload])]
        },
        resetPendingTeams(state) {
            state.pendingTeams = []
        },
        addPendingMembers(state, action: PayloadAction<string[]>) {
            state.pendingMembers = [...new Set([...state.pendingMembers, ...action.payload])]
        },
        resetPendingMembers(state) {
            state.pendingMembers = []
        },
        addPendingInvitation(state, action: PayloadAction<{ email: string; name: string }>) {
            const alreadyExists = state.pendingInvitations.some(
                (invitation) => invitation.email === action.payload.email
            )
            if (!alreadyExists) {
                state.pendingInvitations = [...state.pendingInvitations, action.payload]
            }
        },
        resetPendingInvitations(state) {
            state.pendingInvitations = []
        },
        setSelectedRole(state, action: PayloadAction<RoleOrTemplate | null>) {
            state.selectedRole = action.payload
        },
    },
})

export const rolesPermissionsActions = rolesPermissionsSlice.actions

// // Selectors
export const selectPermissions = (state: RootState) => state.rolesPermissions.permissions
export const selectRoleUsersLoading = (state: RootState) => state.rolesPermissions.usersLoading
export const selectRoleUsersError = (state: RootState) => state.rolesPermissions.usersError
export const selectJwtPermissions = (state: RootState) => state.rolesPermissions.jwtPermissions
export const selectPendingInvitations = (state: RootState) => state.rolesPermissions.pendingInvitations
export const selectPendingMembers = (state: RootState) => state.rolesPermissions.pendingMembers
export const selectPendingTeams = (state: RootState) => state.rolesPermissions.pendingTeams

// // Reducer
const rolesPermissionsReducer = rolesPermissionsSlice.reducer
export default rolesPermissionsReducer
