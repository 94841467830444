import { styled } from "@mui/material"

import { SelectedTagI, TagAndTagGroupNameI, TagGroupI, TagI } from "~/domains/analytics/tags/types"
import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { isDefined } from "~/utils/isDefined"
import { WHITE_SPACE_REGEXP } from "~/utils/string"

export const NOTION_URL_BATCH_TAGS =
    "https://get-flowie.notion.site/Flowie-Documentation-File-Format-for-Tag-Tag-Group-d06e3d2afa2e4d6b8d32e8353b3f8cfa"
export const ACCEPTED_FILE_EXTENSIONS: string[] = ["csv", "xlsx"]
export const FILTER_TYPE = "tagGroups"

export const noFilter = () => true

export const getTagGroupsFiltered = (searchValue: string) => {
    if (!searchValue) {
        return (tagGroup: TagGroupI) => tagGroup
    }

    const searchValues = searchValue.toLowerCase().split(WHITE_SPACE_REGEXP)

    // Helper function to check if a tag or its descendants match the filter
    const filterTagsRecursive = (tag: TagI): TagI | null => {
        const words = `${tag.name} ${tag.description ?? ""}`.toLowerCase().split(WHITE_SPACE_REGEXP)
        const matches = searchValues.every((word) => words.some((tagWord) => tagWord.includes(word)))

        // Recursively filter the tag's children
        const filteredChildren = tag.children ? tag.children.map(filterTagsRecursive).filter(isDefined) : []

        // Return null if it doesn't match and if no children match
        if (!matches && filteredChildren.length === 0) return null

        return {
            ...tag,
            children: filteredChildren,
        }
    }

    return (tagGroup: TagGroupI): TagGroupI | undefined => {
        const combinedWords = `${tagGroup.name} ${tagGroup.description ?? ""}`.toLowerCase().split(WHITE_SPACE_REGEXP)

        // Filter the tags recursively
        const filteredTags = tagGroup.tags.map(filterTagsRecursive).filter(isDefined)

        // Check if the tag group itself matches the filter
        const tagGroupMatches = searchValues.every((word) =>
            combinedWords.some((combinedWord) => combinedWord.includes(word))
        )

        // If neither the tag group nor any of its tags/children match, exclude the group
        if (filteredTags.length === 0 && !tagGroupMatches) {
            return undefined
        }

        // Return the tag group, with filtered tags
        return {
            ...tagGroup,
            tags: filteredTags,
        }
    }
}

export const getTagGroupsFromIds = (tagGroups: TagGroupI[] | null, selected: string[]): TagGroupI[] => {
    return tagGroups
        ? selected.reduce((acc, s) => {
              const tagGroup = tagGroups.find((tagGroup) => tagGroup.tagGroupId === s)
              if (tagGroup) {
                  acc.push(tagGroup)
              }

              return acc
          }, [] as TagGroupI[])
        : []
}

export const getTagsFromIds = (tagGroups: TagGroupI[] | null, selected: string[]): TagAndTagGroupNameI[] => {
    const tags = getFlattenedTags(tagGroups)
    if (!tags || !tags.length) {
        return []
    }

    return selected.reduce((acc, s) => {
        const tag = tags.find((tag) => tag.tagId === s)
        if (tag) {
            acc.push(tag)
        }
        return acc
    }, [] as TagAndTagGroupNameI[])
}

export const isTagGroup = (obj: TagGroupI | TagAndTagGroupNameI): obj is TagGroupI => {
    return obj && "assignmentRule" in obj
}

// Helper function to recursively flatten tags, including nested children
const flattenTags = (tags: TagI[], tagGroupName: string, tagGroupShortName?: string | null): TagAndTagGroupNameI[] => {
    return tags.flatMap((tag) => ({
        ...tag,
        tagGroupName,
        tagGroupShortName,
    }))
}

export const getFlattenedTags = (tagGroups: TagGroupI[] | null): TagAndTagGroupNameI[] => {
    if (!tagGroups) return []

    return tagGroups.flatMap(({ tags, name, shortName }) => flattenTags(tags, name, shortName))
}

const updateTag = (tag: TagI, tagGroupName: string, tagGroupShortName?: string | null): TagAndTagGroupNameI => {
    // Update tag with group name and group short name
    const updatedTag: TagAndTagGroupNameI = {
        ...tag,
        id: tag.tagId,
        tagGroupName,
        tagGroupShortName,
    }

    // If the tag has children, recursively update them too
    if (tag.children && tag.children.length > 0) {
        updatedTag.children = tag.children.map((childTag) => updateTag(childTag, tagGroupName, tagGroupShortName))
    }

    return updatedTag
}

export const updateTagGroupNamesRecursively = (tagGroups: TagGroupI[]): TagGroupI[] => {
    // Iterate through each tag group and update its tags recursively
    return tagGroups.map((tagGroup) => {
        // Update each tag in the group
        const updatedTags = tagGroup.tags.reduce((acc, tag: TagI) => {
            acc.push(updateTag(tag, tagGroup.name, tagGroup.shortName))
            return acc
        }, [] as TagAndTagGroupNameI[])

        // Return the updated tag group with its updated tags
        return {
            ...tagGroup,
            id: tagGroup.tagGroupId,
            tags: updatedTags,
        }
    })
}

// Recursive function to add the tag, handling nested children
export const addTagRecursively = (tags: TagI[], newTag: TagI): TagI[] => {
    if (newTag.parentId) {
        return tags.map((stateTag: TagI) => {
            if (stateTag.tagId === newTag.parentId) {
                return {
                    ...stateTag,
                    children: stateTag.children ? [...stateTag.children, newTag] : [newTag],
                }
            }

            return {
                ...stateTag,
                children: stateTag.children ? addTagRecursively(stateTag.children, newTag) : stateTag.children,
            }
        })
    }

    return [...tags, newTag]
}

// Recursive function to update the tag (and its children if necessary)
export const updateTagRecursively = (tags: TagI[], updatedTag: TagI): TagI[] => {
    return tags.reduce((acc: TagI[], stateTag: TagI) => {
        if (stateTag.tagId === updatedTag.tagId) {
            return [...acc, { ...updatedTag, children: stateTag.children }]
        }

        const updatedStateTag = {
            ...stateTag,
            children: stateTag.children ? updateTagRecursively(stateTag.children, updatedTag) : stateTag.children,
        }

        return [...acc, updatedStateTag]
    }, [])
}

// Recursive function to remove tags and their nested children
export const removeTagsRecursively = (tags: TagI[], tagIdsToRemove: string[]): TagI[] => {
    return tags.reduce((acc: TagI[], tag: TagI) => {
        if (tagIdsToRemove.includes(tag.tagId)) {
            return acc
        }

        const updatedTag = {
            ...tag,
            children: tag.children ? removeTagsRecursively(tag.children, tagIdsToRemove) : tag.children,
        }

        return [...acc, updatedTag]
    }, [])
}

export const findTagRecursively = (tags: TagI[], tagId: string): TagI | undefined => {
    for (const t of tags) {
        if (t.tagId === tagId) {
            return t
        }

        if (t.children) {
            const foundInChildren = findTagRecursively(t.children, tagId)
            if (foundInChildren) return foundInChildren
        }
    }
    return undefined
}

export const flattenTagsWithGroup = (
    tags: TagI[],
    tagGroupName: string,
    tagGroupShortName?: string | null
): TagAndTagGroupNameI[] => {
    return tags.flatMap((tag) => {
        const flattenedTag: TagAndTagGroupNameI = {
            ...tag,
            tagGroupName,
            tagGroupShortName,
        }

        const flattenedChildren = tag.children
            ? flattenTagsWithGroup(tag.children, tagGroupName, tagGroupShortName)
            : []

        return [flattenedTag, ...flattenedChildren]
    })
}

export const getNbTags = (data: TagGroupI | TagAndTagGroupNameI) => {
    return (isTagGroup(data) ? data.tags?.length : data.children?.length) ?? 0
}

export const CustomP = styled("p")({
    fontWeight: 500,
    lineHeight: "22px",
    marginBottom: "8px",
})
export const PrimarySpan = styled("span")({
    color: "var(--color-red)",
})

export const sortTagGroups = (tagGroups: TagGroupI[]) => {
    return [...tagGroups].sort((a, b) => a.name.localeCompare(b.name))
}

export const isSelectedTagI = (tag: TagAndTagGroupNameI | SelectedTagI): tag is SelectedTagI => {
    return "ratio" in tag || "fromRecommandation" in tag || "tagGroup" in tag || "tagGroupName" in tag
}

const findTagParents = (currentTag: TagI, allTags: TagI[]): string[] => {
    if (!currentTag.parentId) return [currentTag.name]

    const parentTag = allTags.find((t) => t.tagId === currentTag.parentId)
    if (parentTag) {
        return [...findTagParents(parentTag, allTags), currentTag.name]
    }

    return [currentTag.name]
}

const calculateReducedPath = (path: string[], maxChars: number): string[] => {
    let charCount = path[0].length
    const reducedPath = [path[0]]

    for (let i = 1; i < path.length - 1; i++) {
        if (charCount + path[i].length > maxChars - path[path.length - 1].length) break
        reducedPath.push(path[i])
        charCount += path[i].length
    }

    reducedPath.push(path[path.length - 1])
    return reducedPath
}

const MAX_FULL_PATH_LENGTH = 50
export const buildTagHierarchalPath = (
    tag: TagI,
    tagGroups: TagGroupI[] | null
): { fullPath: string[]; reducedPath: string[] } | undefined => {
    const tagGroup = tagGroups?.find((group) => group.tags.some((t) => t.tagId === tag.tagId))

    if (!tagGroup) return undefined

    const tagPath = findTagParents(tag, tagGroup.tags)
    const fullPath = [tagGroup.name, ...tagPath]

    const reducedPath =
        fullPath.join("").length > MAX_FULL_PATH_LENGTH
            ? calculateReducedPath(fullPath, MAX_FULL_PATH_LENGTH)
            : fullPath

    return { fullPath, reducedPath }
}

const filterTagsRecursively = (tags: TagI[], filteredTagIds: Set<string>): TagI[] => {
    return tags.reduce<TagI[]>((acc, tag) => {
        // Filter children recursively
        const filteredChildren = tag.children ? filterTagsRecursively(tag.children, filteredTagIds) : []

        // Check if this tag match the tagObjectRecord
        if (filteredTagIds.has(tag.tagId)) {
            acc.push(tag)
        }

        // Check if any of its children match the tagObjectRecord
        if (filteredChildren.length > 0) {
            acc.push(...filteredChildren)
        }

        return acc
    }, [])
}

// Filter tagGroups that have at least one tag present in the tagObjectRecord
export const filterTagGroupsByTagObjectRecord = (
    tagGroups: TagGroupI[] | null,
    tagObjectRecord?: TagObjectRecordI
): TagGroupI[] => {
    if (!tagGroups || !tagGroups.length) return []
    if (!tagObjectRecord) return tagGroups

    // Step 1: Create a Set of all tagIds from tagObjectRecord for fast lookup
    const filteredTagIds = new Set<string>()
    for (const tags of Object.values(tagObjectRecord)) {
        for (const tag of tags) {
            filteredTagIds.add(tag.tagId)
        }
    }

    // Step 2: Filter tagGroups to include only those with matching tags
    return tagGroups.reduce<TagGroupI[]>((acc, tagGroup) => {
        const existingTags = filterTagsRecursively(tagGroup.tags, filteredTagIds)

        if (existingTags.length > 0) {
            acc.push({ ...tagGroup, tags: existingTags })
        }

        return acc
    }, [])
}
