import { Autocomplete, AutocompleteChangeReason, Divider, Stack, TextField, Typography } from "@mui/material"
import { ChangeEvent, FC, useState } from "react"
import { useIntl } from "react-intl"
import { v4 as uuid } from "uuid"

import { Button } from "~/components"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks"
import { TagI } from "~/domains/analytics/tags/types"
import { Condition } from "~/domains/orchestration/flows/components/configuration/BranchConfiguration"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"

import "./ConditionBuilder.scss"

interface Props {
    condition: Condition | null
    handleSave: (c: Condition) => void
    handleCancel?: () => void
}

export const ConditionBuilder: FC<Props> = ({ handleSave, handleCancel, condition }) => {
    const { formatMessage } = useIntl()
    const [conditionValue, setConditionValue] = useState(condition?.condition || "")

    const organizationId = useOrganizationId()

    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const tags = tagGroups?.flatMap((tagGroup) => tagGroup.tags) || []

    // Regex to find tag id in the condition : {{ trigger_event.id }} taggedWith 'baf18856-b8d9-4641-9990-5fa5bb0b97a7'
    // it gets the tag id between the quotes
    const tagIdRegex = /taggedWith '(.*)'/
    const initialTag = tags.find((tag) => tag.tagId === tagIdRegex.exec(conditionValue)?.[1]) || null

    const [tag, setTag] = useState<TagI | null>(initialTag)

    const handleChangeTag = (_, value: TagI | null, reason: AutocompleteChangeReason) => {
        if (reason === "clear") {
            setConditionValue(conditionValue.replace(tagIdRegex, ""))
            setTag(null)
            return
        }

        if (!value) return

        const conditionValueWithTag = `{{ trigger_event.id }} taggedWith '${value.tagId}'`

        setConditionValue(conditionValueWithTag)
        setTag(value)
    }

    const validateCondition = (value: string): boolean => {
        const trimmedValue = value.trim()
        return trimmedValue.length > 0
    }

    const handleSaveNewCondition = () => {
        const trimmedValue = conditionValue.trim()
        if (!validateCondition(trimmedValue)) return

        const newCondition: Condition = {
            id: condition?.id || uuid(),
            condition: trimmedValue,
        }

        handleSave(newCondition)
    }

    const handleChangeCondition = (e: ChangeEvent<HTMLInputElement>) => {
        setConditionValue(e.target.value)
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault()
            if (validateCondition(conditionValue)) {
                handleSaveNewCondition()
            }
        } else if (e.key === "Escape") {
            handleCancel?.()
        }
    }

    const tagsLoading = tagGroups === null

    return (
        <Stack gap={2} className="flows-conditionBuilder">
            <TextField
                label={formatMessage(messages.conditionBuilder.label)}
                value={conditionValue}
                onChange={handleChangeCondition}
                onKeyDown={handleKeyPress}
                fullWidth
                multiline
            />

            <Divider />

            <Stack gap={2}>
                <Typography variant="caption">{formatMessage(messages.conditionBuilder.isTaggedWithLabel)}</Typography>
                <Autocomplete
                    loading={tagsLoading}
                    options={tags}
                    value={tag}
                    onChange={handleChangeTag}
                    getOptionLabel={(t: TagI) => t.name}
                    isOptionEqualToValue={(option: TagI, value: TagI) => option.tagId === value.tagId}
                    getOptionKey={(option: TagI) => option.tagId}
                    renderInput={(params) => (
                        <TextField {...params} label={formatMessage(messages.assignTagConfiguration.tagLabel)} />
                    )}
                />
            </Stack>

            <Stack direction="row" gap={1} justifyContent="flex-end" className="flows-conditionBuilder-actions">
                {handleCancel && (
                    <Button onClick={handleCancel} type="neutral">
                        {formatMessage(messages.conditionBuilder.cancelLabel)}
                    </Button>
                )}

                <Button onClick={handleSaveNewCondition} type="primary-light" disabled={!conditionValue.trim()}>
                    {formatMessage(messages.conditionBuilder.saveLabel)}
                </Button>
            </Stack>
        </Stack>
    )
}
