import { Stack, Typography } from "@mui/material"
import { defineMessages } from "react-intl"

import { Button, Modal, SafeFormattedMessage } from "~/components"
import { authActions } from "~/store/auth/authSlice"
import { useAppDispatch } from "~/store/hooks"

const messages = defineMessages({
    modalTitle: {
        id: "modal.login.title",
        defaultMessage: "Your session has expired",
    },
    messageSignIn: {
        id: "modal.login.messageSignIn",
        defaultMessage: "You need to be logged in if you perform this action",
    },
    buttonSignIn: {
        id: "modal.login.buttonSignIn",
        defaultMessage: "Sign In again",
    },
    cancelLogin: {
        id: "modal.login.cancel",
        defaultMessage: "Ignore",
    },
})

export function ModalReconnect() {
    const dispatch = useAppDispatch()

    const handleClose = (event, reason) => {
        if (reason === "escapeKeyDown" || reason === "backdropClick") {
            return false
        }
        dispatch(authActions.logout())
    }

    const handleLogin = () => {
        dispatch(authActions.openPopupSignIn())
    }

    const handleCancelLogin = () => {
        dispatch(authActions.logout())
    }

    return (
        <Modal open={true} onClose={handleClose} aria-labelledby="modal-reconnect" disableEscapeKeyDown={true}>
            <Modal.Content>
                <Stack justifyContent="center" alignItems="center" gap={2}>
                    <Typography variant="h2" gutterBottom>
                        <SafeFormattedMessage {...messages.modalTitle} />
                    </Typography>
                    <SafeFormattedMessage {...messages.messageSignIn} />
                    <span>
                        <Button type="tertiary" onClick={handleLogin}>
                            <SafeFormattedMessage {...messages.buttonSignIn} />
                        </Button>
                    </span>
                    <Button onClick={handleCancelLogin} type="transparent">
                        <SafeFormattedMessage {...messages.cancelLogin} />
                    </Button>
                </Stack>
            </Modal.Content>
        </Modal>
    )
}
