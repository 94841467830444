/* eslint-disable max-lines */
import { MessageDescriptor } from "react-intl"

import { BulkActionButton } from "~/components"
import {
    CreatePurchaseOrderDTO,
    CreatePurchaseOrderDTOV2,
    CreatePurchaseOrderStatus,
    ListPurchaseOrders,
    PurchaseOrderApprovalStatus,
    PurchaseOrderLine,
    PurchaseOrderProgressStatus,
    PurchaseOrders,
    PurchaseOrdersResponseV2,
    PurchaseOrdersTab,
    UpdatePurchaseOrderDTOV2,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { CreateLineDTOV2, CreatePOLineDTO } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { CurrencyCodes, ViewTypeI } from "~/types"
import { SharedObjectType, SharedObjectWithName } from "~/types/SharedObjects"
import { isDefined } from "~/utils/isDefined"

export const purchaseOrdersTabMessages: Record<PurchaseOrdersTab, MessageDescriptor> = {
    [PurchaseOrdersTab.ALL]: {
        id: "purchase.orders.tabs.all",
        defaultMessage: "All",
    },
    [PurchaseOrdersTab.OPEN]: {
        id: "common.status.open",
        defaultMessage: "Open",
    },
    [PurchaseOrdersTab.DRAFT]: {
        id: "purchase.orders.tabs.drafts",
        defaultMessage: "Drafts",
    },
    [PurchaseOrdersTab.SUBMITTED]: {
        id: "purchase.orders.tabs.submitted",
        defaultMessage: "Submitted",
    },
    [PurchaseOrdersTab.INTERNALLY_APPROVED_BUYER]: {
        id: "purchase.orders.tabs.internalApprovedBuyer",
        defaultMessage: "Approved",
    },
    [PurchaseOrdersTab.INTERNALLY_APPROVED_SELLER]: {
        id: "purchase.orders.tabs.internalApprovedSeller",
        defaultMessage: "Approved by client",
    },
    [PurchaseOrdersTab.SHARED]: {
        id: "purchase.orders.tabs.shared",
        defaultMessage: "Shared",
    },
    [PurchaseOrdersTab.MUTUALLY_ACCEPTED_BUYER]: {
        id: "purchase.orders.tabs.mutuallyAcceptedBuyer",
        defaultMessage: "Accepted by vendor",
    },
    [PurchaseOrdersTab.MUTUALLY_ACCEPTED_SELLER]: {
        id: "purchase.orders.tabs.mutuallyAcceptedSeller",
        defaultMessage: "Accepted",
    },
    [PurchaseOrdersTab.IN_PREPARATION]: {
        id: "purchase.orders.tabs.inPreparation",
        defaultMessage: "In preparation",
    },
    [PurchaseOrdersTab.SHIPPED]: {
        id: "purchase.orders.tabs.inTransit",
        defaultMessage: "In transit",
    },
    [PurchaseOrdersTab.RECEIVED_BUYER]: {
        id: "purchase.orders.tabs.receivedBuyer",
        defaultMessage: "Received",
    },
    [PurchaseOrdersTab.RECEIVED_SELLER]: {
        id: "purchase.orders.tabs.receivedSeller",
        defaultMessage: "Delivered",
    },
    [PurchaseOrdersTab.CLOSED]: {
        id: "purchase.orders.tabs.closed",
        defaultMessage: "Closed",
    },
}

export const ToDoTabsActionBySupplier: Partial<Record<PurchaseOrdersTab, boolean>> = {
    [PurchaseOrdersTab.DRAFT]: true,
    [PurchaseOrdersTab.SUBMITTED]: true,
    [PurchaseOrdersTab.INTERNALLY_APPROVED_SELLER]: true,
    [PurchaseOrdersTab.SHARED]: true,
    [PurchaseOrdersTab.MUTUALLY_ACCEPTED_SELLER]: false,
    [PurchaseOrdersTab.IN_PREPARATION]: false,
    [PurchaseOrdersTab.SHIPPED]: false,
    [PurchaseOrdersTab.RECEIVED_SELLER]: false,
    [PurchaseOrdersTab.CLOSED]: false,
}

export const ToDoTabsActionByBuyer: Partial<Record<PurchaseOrdersTab, boolean>> = {
    [PurchaseOrdersTab.DRAFT]: true,
    [PurchaseOrdersTab.SUBMITTED]: true,
    [PurchaseOrdersTab.INTERNALLY_APPROVED_BUYER]: true,
    [PurchaseOrdersTab.SHARED]: true,
    [PurchaseOrdersTab.MUTUALLY_ACCEPTED_BUYER]: false,
    [PurchaseOrdersTab.IN_PREPARATION]: false,
    [PurchaseOrdersTab.SHIPPED]: false,
    [PurchaseOrdersTab.RECEIVED_BUYER]: false,
    [PurchaseOrdersTab.CLOSED]: false,
}

export type POStatusCounts = Partial<Record<PurchaseOrdersTab, number>>
export const statusCounts = (purchaseOrders: { progress: string }[], view: ViewTypeI | string) => {
    const isbuyerView = view === ViewTypeI.buyer
    const isSupplierView = view === ViewTypeI.supplier
    const countsInitial: POStatusCounts = {
        DRAFT: 0,
        SUBMITTED: 0,
        INTERNALLY_APPROVED_BUYER: 0,
        INTERNALLY_APPROVED_SELLER: 0,
        SHARED: 0,
        MUTUALLY_ACCEPTED_BUYER: 0,
        MUTUALLY_ACCEPTED_SELLER: 0,
        IN_PREPARATION: 0,
        SHIPPED: 0,
        RECEIVED_BUYER: 0,
        RECEIVED_SELLER: 0,
        CLOSED: 0,
    }

    return purchaseOrders.reduce((count, { progress }) => {
        switch (progress) {
            case PurchaseOrderProgressStatus.INTERNALLY_APPROVED:
                if (isbuyerView) {
                    count[PurchaseOrdersTab.INTERNALLY_APPROVED_BUYER] = count[
                        PurchaseOrdersTab.INTERNALLY_APPROVED_BUYER
                    ]
                        ? count[PurchaseOrdersTab.INTERNALLY_APPROVED_BUYER] + 1
                        : 1
                }
                if (isSupplierView) {
                    count[PurchaseOrdersTab.INTERNALLY_APPROVED_SELLER] = count[
                        PurchaseOrdersTab.INTERNALLY_APPROVED_SELLER
                    ]
                        ? count[PurchaseOrdersTab.INTERNALLY_APPROVED_SELLER] + 1
                        : 1
                }
                break
            case PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED:
                if (isbuyerView) {
                    count[PurchaseOrdersTab.MUTUALLY_ACCEPTED_BUYER] = count[PurchaseOrdersTab.MUTUALLY_ACCEPTED_BUYER]
                        ? count[PurchaseOrdersTab.MUTUALLY_ACCEPTED_BUYER] + 1
                        : 1
                }
                if (isSupplierView) {
                    count[PurchaseOrdersTab.MUTUALLY_ACCEPTED_SELLER] = count[
                        PurchaseOrdersTab.MUTUALLY_ACCEPTED_SELLER
                    ]
                        ? count[PurchaseOrdersTab.MUTUALLY_ACCEPTED_SELLER] + 1
                        : 1
                }
                break

            case PurchaseOrderProgressStatus.RECEIVED:
                if (isbuyerView) {
                    count[PurchaseOrdersTab.RECEIVED_BUYER] = count[PurchaseOrdersTab.RECEIVED_BUYER]
                        ? count[PurchaseOrdersTab.RECEIVED_BUYER] + 1
                        : 1
                }
                if (isSupplierView) {
                    count[PurchaseOrdersTab.RECEIVED_SELLER] = count[PurchaseOrdersTab.RECEIVED_SELLER]
                        ? count[PurchaseOrdersTab.RECEIVED_SELLER] + 1
                        : 1
                }
                break
            default:
                count[progress] = count[progress] ? count[progress] + 1 : 1
                break
        }
        return count
    }, countsInitial)
}

export const getPurchaseOrderTabs = (view: ViewTypeI | string): PurchaseOrdersTab[] => {
    const isbuyerView = view === ViewTypeI.buyer
    return [
        PurchaseOrdersTab.ALL,
        PurchaseOrdersTab.OPEN,
        PurchaseOrdersTab.DRAFT,
        PurchaseOrdersTab.SUBMITTED,
        isbuyerView ? PurchaseOrdersTab.INTERNALLY_APPROVED_BUYER : PurchaseOrdersTab.INTERNALLY_APPROVED_SELLER,
        PurchaseOrdersTab.SHARED,
        isbuyerView ? PurchaseOrdersTab.MUTUALLY_ACCEPTED_BUYER : PurchaseOrdersTab.MUTUALLY_ACCEPTED_SELLER,
        PurchaseOrdersTab.IN_PREPARATION,
        PurchaseOrdersTab.SHIPPED,
        isbuyerView ? PurchaseOrdersTab.RECEIVED_BUYER : PurchaseOrdersTab.RECEIVED_SELLER,
        PurchaseOrdersTab.CLOSED,
    ]
}

export const getPOsByViewAndTab = (tab: PurchaseOrdersTab, purchaseOrders: ListPurchaseOrders) => {
    switch (tab) {
        case PurchaseOrdersTab.ALL:
            return filterPOsByStatus(Object.values(PurchaseOrderProgressStatus), purchaseOrders)
        case PurchaseOrdersTab.OPEN:
            return filterPOsByStatus([PurchaseOrderProgressStatus.OPEN], purchaseOrders)
        case PurchaseOrdersTab.DRAFT:
            return filterPOsByStatus([PurchaseOrderProgressStatus.DRAFT], purchaseOrders)
        case PurchaseOrdersTab.SUBMITTED:
            return filterPOsByStatus([PurchaseOrderProgressStatus.SUBMITTED], purchaseOrders)
        case PurchaseOrdersTab.INTERNALLY_APPROVED_BUYER:
        case PurchaseOrdersTab.INTERNALLY_APPROVED_SELLER:
            return filterPOsByStatus([PurchaseOrderProgressStatus.INTERNALLY_APPROVED], purchaseOrders)
        case PurchaseOrdersTab.SHARED:
            return filterPOsByStatus([PurchaseOrderProgressStatus.SHARED], purchaseOrders)
        case PurchaseOrdersTab.MUTUALLY_ACCEPTED_BUYER:
        case PurchaseOrdersTab.MUTUALLY_ACCEPTED_SELLER:
            return filterPOsByStatus([PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED], purchaseOrders)
        case PurchaseOrdersTab.IN_PREPARATION:
            return filterPOsByStatus([PurchaseOrderProgressStatus.IN_PREPARATION], purchaseOrders)
        case PurchaseOrdersTab.SHIPPED:
            return filterPOsByStatus([PurchaseOrderProgressStatus.SHIPPED], purchaseOrders)
        case PurchaseOrdersTab.RECEIVED_BUYER:
        case PurchaseOrdersTab.RECEIVED_SELLER:
            return filterPOsByStatus([PurchaseOrderProgressStatus.RECEIVED], purchaseOrders)
        case PurchaseOrdersTab.CLOSED:
            return filterPOsByStatus([PurchaseOrderProgressStatus.CLOSED], purchaseOrders)
    }
}

const filterPOsByStatus = (
    statuses: PurchaseOrderProgressStatus[],
    purchaseOrders: ListPurchaseOrders
): ListPurchaseOrders => {
    return purchaseOrders.filter((po) => statuses.includes(po.progress))
}

export const filterResults = (pos: ListPurchaseOrders, searchTerm: string, side: ViewTypeI): ListPurchaseOrders => {
    return pos.filter((po) =>
        side === ViewTypeI.buyer
            ? po.buyerName?.toLowerCase().includes(searchTerm)
            : po.supplierName?.toLowerCase().includes(searchTerm)
    )
}

export const allLinesApproved = (lines: PurchaseOrderLine[], viewType: ViewTypeI) => {
    return lines.every(
        (line) =>
            line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] ===
            PurchaseOrderApprovalStatus.APPROVED
    )
}

export const allLinesRejected = (lines: PurchaseOrderLine[], viewType: ViewTypeI) => {
    return lines.every(
        (line) =>
            line[viewType === ViewTypeI.supplier ? "supplierApprovalStatus" : "buyerApprovalStatus"] ===
            PurchaseOrderApprovalStatus.REJECTED
    )
}

export const getPOBulkActionButtons = (
    currentTab: PurchaseOrdersTab,
    view: ViewTypeI | string,
    handleValidate: (currentTab: PurchaseOrdersTab) => BulkActionButton,
    handleSend: (currentTab: PurchaseOrdersTab) => BulkActionButton,
    handleReceived: (currentTab: PurchaseOrdersTab) => BulkActionButton,
    handleAddTags: (currentTab: PurchaseOrdersTab) => BulkActionButton
): BulkActionButton[] => {
    const isSupplierView = view === ViewTypeI.supplier
    switch (currentTab) {
        case PurchaseOrdersTab.ALL:
            return [
                handleAddTags(currentTab),
                handleValidate(currentTab),
                handleSend(currentTab),
                handleReceived(currentTab),
            ]
        case PurchaseOrdersTab.SUBMITTED:
            return [handleAddTags(currentTab), handleValidate(currentTab), handleSend(currentTab)]
        case PurchaseOrdersTab.IN_PREPARATION:
            return isSupplierView
                ? [handleAddTags(currentTab)]
                : [handleAddTags(currentTab), handleReceived(currentTab)]
        case PurchaseOrdersTab.SHIPPED:
            return isSupplierView
                ? [handleAddTags(currentTab)]
                : [handleAddTags(currentTab), handleReceived(currentTab)]
        case PurchaseOrdersTab.RECEIVED_BUYER:
        case PurchaseOrdersTab.RECEIVED_SELLER:
            return isSupplierView
                ? [handleAddTags(currentTab)]
                : [handleAddTags(currentTab), handleReceived(currentTab)]
        default:
            return [handleAddTags(currentTab)]
    }
}

export const getApprovable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    view: ViewTypeI
): ListPurchaseOrders => {
    const isBuyerView = view === ViewTypeI.buyer
    return purchaseOrderIds
        .map((id) => purchaseOrders.find((po) => po.id === id))
        .filter(isDefined)
        .filter(
            (po) =>
                ((isBuyerView && po.progress === PurchaseOrderProgressStatus.SUBMITTED) ||
                    (!isBuyerView && po.progress === PurchaseOrderProgressStatus.SHARED)) &&
                !allLinesApproved(po.lines, view)
        )
}

export const getSendable = (
    purchaseOrderIds: string[],
    purchaseOrders: ListPurchaseOrders,
    view: ViewTypeI | string,
    organizationSharings: SharedObjectWithName[]
): string[] => {
    const sharedPOs = organizationSharings.filter(
        (share) => purchaseOrderIds.includes(share.objectId) && share.objectType === SharedObjectType.PurchaseOrder
    )
    return purchaseOrderIds
        .map((id) => purchaseOrders.find((po) => po.id === id))
        .filter(isDefined)
        .filter(
            (po) =>
                sharedPOs.find((share) => share.objectId === po.id) &&
                view === ViewTypeI.buyer &&
                po.progress === PurchaseOrderProgressStatus.INTERNALLY_APPROVED &&
                allLinesApproved(po.lines, view)
        )
        .map((po) => po.id)
}

const receivablePurchaseOrderStatus: Partial<Record<PurchaseOrderProgressStatus, boolean>> = {
    [PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED]: true,
    [PurchaseOrderProgressStatus.IN_PREPARATION]: true,
    [PurchaseOrderProgressStatus.SHIPPED]: true,
}
export const getReceivable = (purchaseOrderIds: string[], purchaseOrders: ListPurchaseOrders): ListPurchaseOrders => {
    return purchaseOrderIds
        .map((id) => purchaseOrders.find((po) => po.id === id))
        .filter(isDefined)
        .filter((po) => receivablePurchaseOrderStatus[po.progress])
}

export const getPurchaseOrderId = (
    r: PromiseSettledResult<unknown>,
    index: number,
    purchaseOrderIds: string[]
): string | undefined => (r.status === "rejected" ? undefined : purchaseOrderIds[index])

export const showBulkActionButtonCount: Partial<Record<PurchaseOrdersTab, boolean>> = {
    [PurchaseOrdersTab.ALL]: true,
    [PurchaseOrdersTab.SUBMITTED]: true,
}

export const convertLineToCreatePOLineDTO = (line: PurchaseOrderLine): CreatePOLineDTO => {
    return {
        description: line.description,
        quantity: line.quantity,
        units: line.units ?? "",
        unitPrice: line.unitPrice,
        unitPriceExcludingTax: line.unitPriceExcludingTax,
        taxRate: line.taxRate,
        totalAmount: line.totalAmount,
        totalAmountExcludingTax: line.totalAmountExcludingTax,
        ...(line.supplierItemId ? { supplierItemId: line.supplierItemId } : { buyerItemId: line.buyerItemId }),
        totalTax: line.totalTax,
    }
}

export const convertPOtoCreatePODTO = (
    PO: PurchaseOrders,
    status: CreatePurchaseOrderStatus = CreatePurchaseOrderStatus.DRAFT
): CreatePurchaseOrderDTO => {
    return {
        supplierId: PO.supplierId,
        buyerId: PO.buyerId,
        status,
        expectedDeliveryDate: PO.expectedDeliveryDate,
        requesterUserId: PO.requesterUserId ?? undefined,
        billingAddress: PO.billingAddress ?? undefined,
        shippingAddress: PO.shippingAddress ?? undefined,
        description: PO.description,
        currency: PO.currency,
        lines: PO.lines.map((line) => {
            return convertLineToCreatePOLineDTO(line)
        }),
    }
}

export const convertPOV2toPO = (purchaseOrder: PurchaseOrdersResponseV2): PurchaseOrders => {
    const {
        buyerId,
        creationDate,
        expectedDeliveryDate,
        id,
        requesterUserId,
        shippingAddress,
        shortId,
        status,
        progress,
        statusPresentation,
        fulfillmentPresentation,
        supplierId,
        purchaseRequest,
        description,
        totals: purchaseOrderTotals,
        lines: purchaseOrderLines,
    } = purchaseOrder

    const currencyKey = Object.keys(purchaseOrderTotals)[0]

    const lines = purchaseOrderLines.map((line) => {
        const {
            id: lineId,
            description: lineDescription,
            quantity,
            units,
            taxRate,
            totals: lineTotals,
            unitPrice,
            unitPriceExcludingTax,
            deliveryNote = "",
            temporaryId = "",
            hasChanged = false,
            buyerFulfillment,
            supplierFulfillment,
            supplierItemId,
            buyerItemId,
            buyerApprovalStatus,
            supplierApprovalStatus,
        } = line

        return {
            id: lineId,
            description: lineDescription,
            quantity,
            taxRate,
            units: units ?? "",
            totalAmount: lineTotals ? +lineTotals.amount.amount : 0,
            totalAmountExcludingTax: lineTotals ? +lineTotals.amountExcludingTax.amount : 0,
            totalTax: lineTotals ? +lineTotals.tax.amount : 0,
            unitPrice: unitPrice ? +unitPrice.amount : 0,
            unitPriceExcludingTax: unitPriceExcludingTax ? +unitPriceExcludingTax.amount : 0,
            deliveryNote,
            temporaryId,
            hasChanged,
            buyerFulfillment,
            supplierFulfillment,
            supplierItemId,
            buyerItemId,
            buyerApprovalStatus,
            supplierApprovalStatus,
        }
    })

    return {
        buyerId,
        creationDate,
        currency: currencyKey as CurrencyCodes,
        expectedDeliveryDate,
        id,
        requesterUserId,
        shippingAddress,
        shortId,
        status,
        progress,
        statusPresentation,
        fulfillmentPresentation,
        supplierId,
        totalAmount: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].amount?.amount : 0,
        totalAmountExcludingTax: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].amountExcludingTax?.amount : 0,
        totalTax: purchaseOrderTotals ? +purchaseOrderTotals[currencyKey].tax?.amount : 0,
        purchaseRequest,
        description,
        lines,
        supplierName: "",
        buyerName: "",
    }
}

const convertLines = (lines: CreatePOLineDTO[] | PurchaseOrderLine[], currency: CurrencyCodes): CreateLineDTOV2[] => {
    return lines.map((line: CreatePOLineDTO | PurchaseOrderLine) => {
        const {
            description,
            quantity,
            units,
            taxRate,
            unitPrice,
            unitPriceExcludingTax,
            id,
            buyerItemId,
            supplierItemId,
        } = line
        return {
            description,
            quantity,
            units: units ?? "",
            taxRate: taxRate.toFixed(8),
            unitPrice: { amount: unitPrice.toFixed(8), currency },
            unitPriceExcludingTax: { amount: unitPriceExcludingTax.toString(), currency },
            ...(id ? { id } : {}),
            ...(buyerItemId ? { buyerItemId } : {}),
            ...(supplierItemId ? { supplierItemId } : {}),
        }
    })
}

export const convertPOtoPOV2 = (purchaseOrder: CreatePurchaseOrderDTO): CreatePurchaseOrderDTOV2 => {
    const {
        status,
        billingAddress,
        description,
        expectedDeliveryDate,
        shippingAddress,
        supplierId,
        buyerId,
        lines,
        currency,
    } = purchaseOrder

    return {
        status,
        billingAddress,
        description,
        shippingAddress,
        supplierId,
        buyerId,
        lines: convertLines(lines, currency),
        ...(expectedDeliveryDate ? { expectedDeliveryDate } : {}),
    }
}

export const convertPOtoPOV2ForUpdate = (purchaseOrder: PurchaseOrders): UpdatePurchaseOrderDTOV2 => {
    const { billingAddress, description, expectedDeliveryDate, shippingAddress, lines, currency } = purchaseOrder

    return {
        billingAddress,
        description,
        expectedDeliveryDate,
        shippingAddress,
        lines: convertLines(lines, currency),
    }
}
