import { IconButton, Typography } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid-premium"
import dayjs from "dayjs"
import { Link2 } from "react-feather"
import { defineMessages } from "react-intl"
import { Link } from "react-router-dom"
import { generatePath } from "react-router-dom"

import { SafeFormattedMessage, StatusChip, TooltipConditional } from "~/components"
import { DateComponent } from "~/components/Date/DateComponent"
import { distanceToIssueDate } from "~/domains/identity/partners/core/getInvoiceTimes"
import { AmountInList } from "~/domains/transactions/_shared/components/AmountInList"
import { columnsLabels } from "~/domains/transactions/invoices/components/list/InvoicesDataGrid/columnsLabels"
import { InvoiceI, InvoiceId } from "~/domains/transactions/invoices/types/InvoiceTypes"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"

const messages = defineMessages({
    vendor: { id: "invoices.vendor", defaultMessage: "Vendor" },
    tooltipDueDateInLessThan7Days: {
        id: "account.documents.dueDateInLessThan7Days",
        defaultMessage: "Due date in less than 7 days",
    },
})

export const useGetInvoicesListTableColumns = (
    organizationId: string,
    vendorNames: Record<InvoiceId, string>,
    paidInvoices: Record<InvoiceId, string | null>,
    actionColumn?: GridColDef<InvoiceI>
): GridColDef<InvoiceI>[] => {
    return [
        {
            field: "reference",
            sortable: false,
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelReference} />,
        },
        {
            field: "vendor",
            flex: 1,
            sortable: false,
            renderHeader: () => <SafeFormattedMessage {...messages.vendor} />,
            valueGetter: (params) => vendorNames[params.row.invoiceId],
        },
        {
            field: "description",
            flex: 2,
            sortable: false,
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelDescription} />,
            valueGetter: (params) => params.row.description,
        },
        {
            field: "total",
            sortable: false,
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelAmount} />,
            valueGetter: (params) => params.row.total,
            renderCell: ({ row: invoice }) => (
                <AmountInList amount={invoice.totalExcludedTaxes ?? 0} currency={invoice.currency} />
            ),
        },
        {
            field: "status",
            sortable: false,
            minWidth: 80,
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelStatus} />,
            valueGetter: (params) => params.row.status,
            renderCell: ({ row: invoice }) => (
                <StatusChip status={invoice.lifecycleStatus?.toUpperCase() || "DRAFT"}>
                    {invoice.lifecycleStatus}
                </StatusChip>
            ),
        },
        {
            field: "purchaseOrder",
            sortable: false,
            width: 40,
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelPONumber} />,
            valueGetter: (params) => {
                const purchaseOrderNumber = params.row.purchaseOrderNumber

                return (
                    purchaseOrderNumber && (
                        <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                            <Link
                                to={generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: purchaseOrderNumber })}
                                target="_blank"
                            >
                                <Link2 size={16} />
                            </Link>
                        </IconButton>
                    )
                )
            },
        },
        {
            field: "elapsedTime",
            sortable: false,
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelElapsedTime} />,
            valueGetter: (params) => distanceToIssueDate(params.row),
        },
        {
            field: "issuedAt",
            width: 100,
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelIssueDate} />,
            valueGetter: (params) => params.row.issuedAt ?? "-",
            renderCell: ({ row: invoice }) => <DateComponent value={invoice.issuedAt} />,
        },
        {
            field: "dueDate",
            width: 100,
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelDueDate} />,
            valueGetter: (params) => params.row.dueDate,
            renderCell: ({ row: invoice }) => {
                const isDueDateInLessThan7Days = dayjs(invoice.dueDate).diff(dayjs(), "days") < 7
                const paidDate = paidInvoices[invoice.invoiceId]
                const shouldDisplayWarning = isDueDateInLessThan7Days && !paidDate

                return (
                    <TooltipConditional
                        title={<SafeFormattedMessage {...messages.tooltipDueDateInLessThan7Days} />}
                        condition={shouldDisplayWarning}
                        type="danger"
                        arrow
                    >
                        <Typography variant="body2" color={shouldDisplayWarning ? "error" : "inherit"}>
                            <DateComponent value={invoice.dueDate} />
                        </Typography>
                    </TooltipConditional>
                )
            },
        },
        {
            field: "paidDate",
            sortable: false,
            valueGetter: (params) => params.row.paidAt,
            renderHeader: () => <SafeFormattedMessage {...columnsLabels.labelPaidAt} />,
            renderCell: ({ row: invoice }) =>
                paidInvoices[invoice.invoiceId] ? <DateComponent value={paidInvoices[invoice.invoiceId]} /> : "-",
        },
        ...(actionColumn ? [actionColumn] : []),
    ]
}
