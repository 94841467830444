import { Stack } from "@mui/material"
import React from "react"
import { defineMessages, useIntl } from "react-intl"

import { TotalAmount } from "~/domains/transactions/_shared/components/TotalAmount/TotalAmount"
import { PurchaseViewType } from "~/domains/transactions/_shared/types"
import { PurchaseOrderLink } from "~/domains/transactions/purchase-requests/components/PurchaseOrderLink/PurchaseOrderLink"
import { PurchaseRequestInfos } from "~/domains/transactions/purchase-requests/components/PurchaseRequestInfos/PurchaseRequestInfos"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

const messages = defineMessages({
    taxLabel: { id: "purchase.requests.request.taxLabel", defaultMessage: "Tax excl." },
    purchaseOrder: { id: "purchase.requests.request.purchaseOrder", defaultMessage: "Purchase order" },
})

interface Props {
    PR: PurchaseRequestDetails
    newPR: boolean | undefined
    pageMode: PurchaseViewType
    updatePR: (showToast?: boolean) => Promise<PurchaseRequestDetails>
}

export const PurchaseRequestLeftColumn: React.FC<Props> = ({ PR, newPR, pageMode, updatePR }) => {
    const { formatMessage } = useIntl()

    return (
        <Stack gap={1} className="column">
            <TotalAmount
                amount={PR.totalAmountExcludingTax}
                currency={PR.currency}
                taxLabel={formatMessage(messages.taxLabel)}
            />
            <PurchaseRequestInfos
                PR={PR}
                newPR={newPR}
                updatePR={updatePR}
                pageMode={pageMode}
                organizationId={PR.organizationId}
            />
            {PR.purchaseOrder && (
                <PurchaseOrderLink purchaseOrder={PR.purchaseOrder} label={formatMessage(messages.purchaseOrder)} />
            )}
        </Stack>
    )
}
