import { TextField } from "@mui/material"
import { ChangeEvent, FC } from "react"
import { useIntl } from "react-intl"

import { extractMessages } from "~/domains/transactions/invoices/_views/supplier/components/Extract/extractMessages"
import { UpdateExtractDataFunction } from "~/domains/transactions/invoices/_views/supplier/components/Extract/types"
import { OCRInputTypes } from "~/domains/transactions/invoices/components/OcrExtract/extract"
import { useToggleShowShapes } from "~/domains/transactions/invoices/components/OcrExtract/hooks"

interface ExtractPurchaseOrderNumberProps {
    purchaseOrderNumber: string
    updateData: UpdateExtractDataFunction
    disabled?: boolean
}

export const ExtractPurchaseOrderNumber: FC<ExtractPurchaseOrderNumberProps> = ({
    purchaseOrderNumber,
    updateData,
    disabled,
}) => {
    const { formatMessage } = useIntl()
    const toggleShowShapes = useToggleShowShapes("purchaseOrderNumber", OCRInputTypes.TEXT)

    const onPurchaseOrderNumberChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateData({
            purchaseOrderNumber: event.currentTarget.value,
        })
    }
    return (
        <TextField
            id="purchaseOrderNumber"
            name="purchaseOrderNumber"
            label={formatMessage(extractMessages.purchaseOrderNumber)}
            placeholder={formatMessage(extractMessages.purchaseOrderNumber)}
            onChange={onPurchaseOrderNumberChange}
            value={purchaseOrderNumber}
            disabled={disabled}
            onFocus={toggleShowShapes}
            fullWidth
        />
    )
}
