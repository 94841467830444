// FIXME: Refactor this file and the  onConnect function to reduce the complexity

/* eslint-disable complexity */
import { Stack, styled } from "@mui/material"
import {
    Background,
    BackgroundVariant,
    type Connection,
    ControlButton,
    Controls,
    MiniMap,
    type Node,
    NodeMouseHandler,
    Panel,
    ReactFlow,
    XYPosition,
    addEdge,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import debounce from "lodash/debounce"
import React, { FC, memo, useDeferredValue, useEffect, useMemo, useRef, useState } from "react"
import { AlertTriangle, Grid } from "react-feather"
import { useIntl } from "react-intl"
import Selecto, { OnDragStart } from "react-selecto"

import { ButtonEdge } from "~/domains/orchestration/flows/components/ButtonEdge"
import { ButtonEdgeWithLabel } from "~/domains/orchestration/flows/components/ButtonEdgeWithLabel"
import { EdgeWithLabel } from "~/domains/orchestration/flows/components/EdgeWithLabel"
import { FlowWrapper } from "~/domains/orchestration/flows/components/FlowWrapper"
import { SideBar, SideBarProps } from "~/domains/orchestration/flows/components/editor/SideBar"
import { NODE_WIDTH, SIDE_BAR_WIDTH } from "~/domains/orchestration/flows/constants"
import { useEditor, useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { FLOW_NODE_TYPES, adaptFlowToEdges, adaptFlowToNodes } from "~/domains/orchestration/flows/core"
import { createNode } from "~/domains/orchestration/flows/core"
import { getLayoutedNodes } from "~/domains/orchestration/flows/core/"
import { useFlowValidator } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import {
    EditorNode,
    type Flow,
    NodeType,
    isAddToBudgetNode,
    isApprovePurchaseOrderLineNode,
    isApprovePurchaseOrderNode,
    isApprovePurchaseRequestLineNode,
    isApprovePurchaseRequestNode,
    isAssignTagGroupNode,
    isBranchNode,
    isConvertPrToPoNode,
    isCreateCustomFieldNode,
    isCreateSurveyNode,
    isCreateTaskNode,
    isEventTriggerNode,
    isFetchCustomFieldsNode,
    isFetchPartnershipNode,
    isGetTagByGroupNode,
    isMappingNode,
    isRetractReviewsNode,
    isSendEmailNode,
    isSetInvoiceLifecycleStatusNode,
    isSetPartnershipFieldNode,
    isSetPaymentMethodDetailsFieldNode,
    isUpdateCustomFieldNode,
    isUpdateTripletexLedgerNode,
} from "~/domains/orchestration/flows/types"
import { generateReadableId } from "~/domains/orchestration/flows/utils"

interface Props {
    flow: Flow
    hasWorkflowUpdatePermission: boolean
}

const StyledControls = styled(Controls)`
    position: absolute;
    top: 10px;
    box-shadow: none;
    height: fit-content;
    border: none;
`

const StyledError = styled(Panel)`
    color: var(--color-yellow);
    padding: var(--spacing-md);
    background-color: var(--sidebar-color);
    border-radius: var(--border-radius-sm);
`

const shouldUpdate = (prevProps: SideBarProps, nextProps: SideBarProps) => {
    return prevProps.selectedNode?.slug === nextProps.selectedNode?.slug
}

const MemoizedSidebar = memo(SideBar, shouldUpdate)

export const EditorPanel: FC<Props> = ({ flow, hasWorkflowUpdatePermission }) => {
    const reactFlowWrapper = useRef(null)
    const { fitView } = useReactFlow()
    const { formatMessage } = useIntl()
    const state = useEditor()
    const currentFlow = state.flow || flow
    const { error } = state

    const [nodes, setNodes, onNodesChange] = useNodesState(adaptFlowToNodes(currentFlow))
    const [edges, setEdges, onEdgesChange] = useEdgesState(adaptFlowToEdges(currentFlow))
    const [selectedNode, setSelectedNode] = useState<EditorNode>()
    const { screenToFlowPosition } = useReactFlow()
    const dispatch = useEditorDispatch()

    const [currentNodeType, setCurrentNodeType] = useState<NodeType | null>(null)
    const [showSidebar, setShowSidebar] = useState(true)

    const deferedNodes = useDeferredValue(nodes)
    const deferedEdges = useDeferredValue(edges)

    const fitViewDelayed = (delay = 0) => {
        const timeoutId = setTimeout(async () => {
            await fitView()
        }, delay)
        return () => clearTimeout(timeoutId)
    }

    const edgeTypes = useMemo(
        () => ({
            button: ButtonEdge,
            buttonWithLabel: ButtonEdgeWithLabel,
            withLabel: EdgeWithLabel,
        }),
        []
    )

    const updateFlow = (f: Flow) => {
        dispatch({
            type: "SET_FLOW",
            payload: f,
        })

        dispatch({
            type: "SET_FLOW_PUBLISHABLE",
            payload: true,
        })
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault()
        event.dataTransfer.dropEffect = "move"
    }

    const updatePosition = (node: Node) => {
        const { nodes: flowNodes } = currentFlow
        const currentFlowNode = flowNodes.find((fn) => fn.slug === node.id)
        if (!currentFlowNode) return
        const position = {
            x: Math.floor(node.position.x),
            y: Math.floor(node.position.y),
        }

        const hasPositionChanged =
            currentFlowNode.metadata.position.x !== position.x || currentFlowNode.metadata.position.y !== position.y

        if (hasPositionChanged) {
            dispatch({
                type: "UPDATE_NODE",
                payload: {
                    ...currentFlowNode,
                    metadata: { ...currentFlowNode.metadata, position },
                },
            })
            dispatch({
                type: "SET_FLOW_PUBLISHABLE",
                payload: true,
            })
        }
    }

    const handleDragSelectionStop = (event: React.MouseEvent, nds: Node[]): void => {
        event.preventDefault()

        for (const node of nds) {
            updatePosition(node)
        }
    }

    const selectCallback = (nodeType: NodeType) => {
        setCurrentNodeType(nodeType)
    }

    const onConnect = (params: Connection) => {
        const nodesToUpdate = [...currentFlow.nodes]
        const sourceNodeIndex = nodesToUpdate.findIndex((node) => node.slug === params.source)
        if (sourceNodeIndex === -1) return

        const isDefaultBranchHandle =
            isBranchNode(nodesToUpdate[sourceNodeIndex]) &&
            params.sourceHandle === `${nodesToUpdate[sourceNodeIndex].slug}-default`

        if (
            isEventTriggerNode(nodesToUpdate[sourceNodeIndex]) ||
            isSetPartnershipFieldNode(nodesToUpdate[sourceNodeIndex]) ||
            isSendEmailNode(nodesToUpdate[sourceNodeIndex]) ||
            isAddToBudgetNode(nodesToUpdate[sourceNodeIndex]) ||
            isSetInvoiceLifecycleStatusNode(nodesToUpdate[sourceNodeIndex]) ||
            isUpdateTripletexLedgerNode(nodesToUpdate[sourceNodeIndex]) ||
            isApprovePurchaseOrderNode(nodesToUpdate[sourceNodeIndex]) ||
            isApprovePurchaseOrderLineNode(nodesToUpdate[sourceNodeIndex]) ||
            isConvertPrToPoNode(nodesToUpdate[sourceNodeIndex]) ||
            isApprovePurchaseRequestNode(nodesToUpdate[sourceNodeIndex]) ||
            isApprovePurchaseRequestLineNode(nodesToUpdate[sourceNodeIndex]) ||
            isSetPaymentMethodDetailsFieldNode(nodesToUpdate[sourceNodeIndex]) ||
            isCreateSurveyNode(nodesToUpdate[sourceNodeIndex]) ||
            isRetractReviewsNode(nodesToUpdate[sourceNodeIndex]) ||
            isFetchCustomFieldsNode(nodesToUpdate[sourceNodeIndex]) ||
            isMappingNode(nodesToUpdate[sourceNodeIndex]) ||
            isGetTagByGroupNode(nodesToUpdate[sourceNodeIndex]) ||
            isUpdateCustomFieldNode(nodesToUpdate[sourceNodeIndex]) ||
            isCreateTaskNode(nodesToUpdate[sourceNodeIndex]) ||
            isFetchPartnershipNode(nodesToUpdate[sourceNodeIndex]) ||
            isCreateCustomFieldNode(nodesToUpdate[sourceNodeIndex])
        ) {
            nodesToUpdate[sourceNodeIndex] = { ...nodesToUpdate[sourceNodeIndex], nextNode: params.target }
        } else if (isAssignTagGroupNode(nodesToUpdate[sourceNodeIndex])) {
            nodesToUpdate[sourceNodeIndex] = {
                ...nodesToUpdate[sourceNodeIndex],
                nextNode: params.target,
                nodes: nodesToUpdate[sourceNodeIndex].nodes.map((node, index, array) => ({
                    ...node,
                    nextNode: index === array.length - 1 ? params.target : node.nextNode,
                })),
            }
        } else if (isBranchNode(nodesToUpdate[sourceNodeIndex])) {
            if (isDefaultBranchHandle) {
                nodesToUpdate[sourceNodeIndex] = {
                    ...nodesToUpdate[sourceNodeIndex],
                    default: params.target,
                }
            } else {
                // regex to get the index from the handle index {index}
                const handleIndex = parseInt(params.sourceHandle?.match(/\{(\d+)\}/)?.[1] || "0")

                nodesToUpdate[sourceNodeIndex] = {
                    ...nodesToUpdate[sourceNodeIndex],
                    default: isDefaultBranchHandle ? params.target : nodesToUpdate[sourceNodeIndex].default,
                    branches: nodesToUpdate[sourceNodeIndex].branches.map((branch, index) => ({
                        ...branch,
                        nextNode: handleIndex === index ? params.target : branch.nextNode,
                    })),
                }
            }
        } else {
            nodesToUpdate[sourceNodeIndex] = params.sourceHandle?.includes("success")
                ? { ...nodesToUpdate[sourceNodeIndex], nextIfSuccess: params.target }
                : { ...nodesToUpdate[sourceNodeIndex], nextIfFailure: params.target }
        }

        updateFlow({ ...currentFlow, nodes: nodesToUpdate })
        setEdges((eds) => addEdge(params, eds))
    }

    const createAndAddNode = (nodeType: NodeType, position: XYPosition) => {
        const id = generateReadableId(nodeType, currentFlow.nodes)

        const eventTriggerEventType = currentFlow.nodes.find(isEventTriggerNode)?.event || null

        const title = messages.nodeTitle[nodeType]

        const name = title ? formatMessage(title) : ""

        const node = createNode({ type: nodeType, slug: id, position, eventTriggerEventType, name })

        // TODO: Handle the case when node is null
        if (!node) return

        updateFlow({ ...currentFlow, nodes: [...currentFlow.nodes, node] })

        const newNode = {
            id,
            type: nodeType,
            position,
            data: node,
        }

        setNodes((nds) => nds.concat(newNode))
    }

    const handleDragStop = (event: React.MouseEvent, node: Node): void => {
        event.preventDefault()
        updatePosition(node)
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()

        if (!currentNodeType) return

        // TODO: For now node width is hardcoded, it will be dynamic if needed
        const position = screenToFlowPosition({
            x: Math.floor(event.clientX - NODE_WIDTH),
            y: Math.floor(event.clientY),
        })

        createAndAddNode(currentNodeType, position)
        if (currentFlow.nodes.length === 0) {
            fitViewDelayed(100)
        }
    }

    const handleNodeClick = (nodeType: NodeType) => {
        setCurrentNodeType(nodeType)

        const centerX = window.innerWidth / 2
        const centerY = window.innerHeight / 2

        // TODO: For now node width is hardcoded, it will be dynamic if needed
        const position = screenToFlowPosition({
            x: Math.floor(centerX - NODE_WIDTH / 2),
            y: Math.floor(centerY),
        })

        createAndAddNode(nodeType, position)
    }

    const handlSelectNode: NodeMouseHandler<Node> = (_, node) => {
        const selected = currentFlow.nodes.find((n) => n.slug === node.data.slug)

        if (!selected) return
        if (selectedNode && selected.slug === selectedNode.slug) return

        setSelectedNode(selected)
        dispatch({
            type: "SET_FLOW",
            payload: currentFlow,
        })
        setShowSidebar(true)
    }

    const handleUnselectNode = () => {
        setSelectedNode(undefined)
    }

    const handleDragStart =
        (nodeType: NodeType) =>
        (event: React.DragEvent<HTMLDivElement>): void => {
            selectCallback(nodeType)
            event.dataTransfer.effectAllowed = "move"
        }

    const handleSelect = (e: OnDragStart) => {
        // This prevent select box appearing when connecting nodes
        e.preventDrag()
    }

    const handleAutoLayout = async () => {
        const layoutedNodes = await getLayoutedNodes(nodes, edges)
        setNodes(layoutedNodes)

        updateFlow({
            ...currentFlow,
            nodes: currentFlow.nodes.map((node) => {
                const layoutedNode = layoutedNodes.find((n) => n.id === node.slug)
                return {
                    ...node,
                    metadata: { ...node.metadata, position: layoutedNode?.position || node.metadata.position },
                }
            }),
        })
        fitViewDelayed(100)
    }

    const handleToggleSideBar = () => {
        setShowSidebar((prev) => !prev)
    }

    useEffect(() => {
        const debouncedSetNodes = debounce(() => {
            setNodes(
                adaptFlowToNodes(currentFlow, {
                    currentNode: selectedNode,
                })
            )
            setEdges(adaptFlowToEdges(currentFlow))
        }, 500)

        debouncedSetNodes()

        return () => debouncedSetNodes.cancel()
    }, [currentFlow, selectedNode, setEdges, setNodes])

    useEffect(() => {
        if (!selectedNode) return

        const currentNode = currentFlow.nodes.find((node) => node.slug === selectedNode?.slug)

        if (!currentNode) handleUnselectNode()
    }, [currentFlow, selectedNode])

    useFlowValidator(currentFlow)

    useEffect(() => {
        dispatch({ type: "SET_FLOW", payload: flow })
        dispatch({ type: "SET_RUN", payload: null })

        fitViewDelayed(50)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hasError = error !== null && error.trim().length > 0

    const sideBarWidth = showSidebar ? SIDE_BAR_WIDTH : 0

    const hasSideBar = hasWorkflowUpdatePermission
    return (
        <>
            {reactFlowWrapper.current && (
                <Selecto
                    container={reactFlowWrapper.current}
                    dragContainer={reactFlowWrapper.current}
                    selectableTargets={[".react-flow__node"]}
                    boundContainer={reactFlowWrapper.current}
                    continueSelect={false}
                    keyContainer={reactFlowWrapper.current}
                    hitRate={100}
                    onDragStart={handleSelect}
                />
            )}
            <FlowWrapper ref={reactFlowWrapper} $sidebarWidth={sideBarWidth}>
                <ReactFlow
                    key={currentFlow.id}
                    nodes={deferedNodes}
                    edges={deferedEdges}
                    nodeTypes={FLOW_NODE_TYPES}
                    edgeTypes={edgeTypes}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onDragOver={handleDragOver}
                    onSelectionDragStop={handleDragSelectionStop}
                    onDrop={handleDrop}
                    onNodeDragStop={handleDragStop}
                    onNodeClick={handlSelectNode}
                    onPaneClick={handleUnselectNode}
                    snapToGrid
                    snapGrid={[50, 50]}
                    minZoom={0.1}
                    fitView
                    fitViewOptions={{
                        padding: 50,
                    }}
                    proOptions={{
                        hideAttribution: true,
                    }}
                >
                    <StyledControls>
                        <ControlButton onClick={handleAutoLayout}>
                            <Grid />
                        </ControlButton>
                    </StyledControls>
                    {hasError && (
                        <StyledError position="top-center">
                            <Stack alignItems="center" gap={1} direction="row">
                                <AlertTriangle size={18} color="var(--color-yellow)" />
                                {error}
                            </Stack>
                        </StyledError>
                    )}
                    <Background color="var(--primary-color)" variant={BackgroundVariant.Dots} />
                    <MiniMap nodeStrokeWidth={1} bgColor="transparent" />
                </ReactFlow>
            </FlowWrapper>
            {hasSideBar && (
                <MemoizedSidebar
                    key={showSidebar ? "open" : "closed"}
                    isOpen={showSidebar}
                    handleDragStart={handleDragStart}
                    selectedNode={selectedNode}
                    handleNodeClick={handleNodeClick}
                    handleUnselectNode={handleUnselectNode}
                    handleToggleSideBar={handleToggleSideBar}
                />
            )}
        </>
    )
}
