import { useCallback, useEffect } from "react"

import { useLazyGetObjectChecksQuery } from "~/domains/orchestration/flows-v0/api/approvalApi"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types"
import {
    paymentMethodDetailsActions,
    selectRowsToApprove,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { PaymentMethodDetails, PaymentMethodDetailsStatus } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

interface PaymentMethodDetailsRowsI {
    items: PaymentMethodDetails[]
    organizationId: string
    activeTab: PaymentMethodType
}

export const usePaymentMethodDetailsRows = ({ items, organizationId, activeTab }: PaymentMethodDetailsRowsI) => {
    const [triggerGetObjectChecks, { isLoading }] = useLazyGetObjectChecksQuery()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const rowsToApprove = useAppSelector(selectRowsToApprove)
    const userId = useAppSelector(selectUserId)
    const dispatch = useAppDispatch()

    const getApprovedRows = useCallback(async () => {
        if (!currentOrganizationId || !items.length) return []

        const objectType =
            currentOrganizationId === organizationId
                ? ApprovalObjectType.PAYMENT_METHOD_DETAILS
                : ApprovalObjectType.PARTNER_PAYMENT_METHOD_DETAILS

        const responses = await Promise.all(
            items.map(({ id }) =>
                triggerGetObjectChecks({
                    objectId: id,
                    organisationId: currentOrganizationId,
                    objectType,
                }).unwrap()
            )
        )

        return items.filter((item, index) => {
            const checks = responses[index]?.checks

            if (!checks.length || item.status === PaymentMethodDetailsStatus.DELETED) return false

            return (
                checks?.length &&
                checks.some((check) => {
                    const userHasReviewed =
                        check.reviewers.some((reviewer) => reviewer.userId === userId) &&
                        ![...check.review.approvers, ...check.review.refusers].some(
                            (reviewer) => reviewer.userId === userId
                        )
                    return userHasReviewed
                })
            )
        })
    }, [items, organizationId, triggerGetObjectChecks, userId])

    useEffect(() => {
        if (!items.length) return

        getApprovedRows().then((rowsForApproval) => {
            dispatch(paymentMethodDetailsActions.setRows(rowsForApproval))
        })
    }, [items, activeTab, getApprovedRows, dispatch])

    return { rowsToApprove, isLoading, getApprovedRows }
}
