import * as Sentry from "@sentry/browser"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { messages } from "~/domains/orchestration/flows/locale"
import { bumpFlow } from "~/domains/orchestration/flows/utils"

import { useCreateFlowMutation, useLazyGetFlowQuery, useUpdateFlowMutation } from "../api/flowsApi"
import type { Flow } from "../types"

type CopyProps = Pick<Flow, "id" | "version" | "name">
type DeleteProps = Pick<Flow, "id" | "version">
type CreateProps = Pick<Flow, "name">
type UpdateProps = {
    id: string
    flow: Flow
}

export const useFlowActions = () => {
    const [getFlowTrigger] = useLazyGetFlowQuery()
    const [createFlowTrigger] = useCreateFlowMutation()
    const [updateFlowTrigger] = useUpdateFlowMutation()
    const { formatMessage } = useIntl()

    const createFlow = async ({ name }: CreateProps) => {
        try {
            const response = await createFlowTrigger({ body: { name, enabled: false, nodes: [] } })

            if ("error" in response) {
                throw new Error("Unable to create flow")
            }

            toast.success(formatMessage(messages.success.flowCreated))
        } catch (error) {
            Sentry.captureException(error, {
                extra: {
                    message: "Unable to create flow",
                },
            })
            toast.error(formatMessage(messages.error.createFlow))
        }
    }

    const copyFlow = async ({ id, version, name }: CopyProps) => {
        try {
            const { data, isError } = await getFlowTrigger({ flowId: id, params: { version: version } })

            if (!data || isError) {
                throw new Error("Unable to copy flow")
            }

            const copyName = formatMessage(messages.success.copyName, { name })

            const body = {
                name: copyName,
                enabled: false,
                nodes: data.nodes,
            }
            const response = await createFlowTrigger({ body })

            if ("error" in response) {
                throw new Error("Unable to copy flow")
            }

            toast.success(formatMessage(messages.success.flowCreated))
        } catch (error) {
            Sentry.captureException(error, {
                extra: {
                    message: "Unable to copy flow",
                },
            })
            toast.error(formatMessage(messages.error.copyFlow))
        }
    }

    const archiveFlow = async ({ id, version }: DeleteProps) => {
        try {
            const { data, isError } = await getFlowTrigger({ flowId: id, params: { version: version } })

            if (!data || isError) {
                throw new Error("Unable to archive flow")
            }

            const bumpedFlow = bumpFlow(data)

            const response = await updateFlowTrigger({ flowId: id, body: { ...bumpedFlow, archived: true } })

            if ("error" in response) {
                throw new Error("Unable to archive flow")
            }

            toast.success(formatMessage(messages.success.flowArchived))
        } catch (error) {
            Sentry.captureException(error, {
                extra: {
                    message: "Unable to archive flow",
                },
            })
            toast.error(formatMessage(messages.error.archiveFlow))
        }
    }

    const updateFlow = async ({ id, flow }: UpdateProps) => {
        const bumpedFlow = bumpFlow(flow)
        try {
            const response = await updateFlowTrigger({ flowId: id, body: bumpedFlow })

            if ("error" in response) {
                throw new Error("Unable to update flow")
            }
            toast.success(formatMessage(messages.success.flowUpdated))
            return bumpedFlow
        } catch (error) {
            Sentry.captureException(error, {
                extra: {
                    message: "Unable to update flow",
                },
            })
            toast.error(formatMessage(messages.error.updatingFlow))
            return null
        }
    }

    return { createFlow, copyFlow, archiveFlow, updateFlow }
}
