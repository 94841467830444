import { useCallback, useState } from "react"
import { defineMessage, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { DocumentType } from "~/domains/identity/documents/types"
import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { InvoiceId, OrganizationId } from "~/types"

const messageInvoiceUpdated = defineMessage({
    id: "invoice.ocrInvoice.documentType.updated",
    defaultMessage: "Document type updated.",
})

const messageInvoiceCantBeUpdated = defineMessage({
    id: "invoice.ocrInvoice.documentType.cantBeUpdated",
    defaultMessage: "The type of the document couldn't be changed.",
})

export const useUpdateInvoiceType = (invoiceId: InvoiceId, organizationId: OrganizationId | null | undefined) => {
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const updateInvoiceType = useCallback(
        async (documentType: DocumentType) => {
            if (!organizationId) {
                return false
            }
            setLoading(true)
            try {
                await invoiceApi.updateInvoiceType(organizationId, invoiceId, documentType)
                toast.success(formatMessage(messageInvoiceUpdated))
                return true
            } catch (err) {
                toast.error(formatMessage(messageInvoiceCantBeUpdated))
                return false
            } finally {
                setLoading(false)
            }
        },
        [invoiceId, organizationId]
    )
    return {
        updateInvoiceType,
        loading,
    }
}
