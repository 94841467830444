import { Checkbox, Stack, Typography } from "@mui/material"
import { FC } from "react"
import { Check, X } from "react-feather"
import { useIntl } from "react-intl"

import { Button, Card, SafeFormattedMessage } from "~/components"
import { approvalObjectType } from "~/domains/orchestration/approval/locale"
import { useApprovalReview } from "~/domains/orchestration/flows-v0/hooks/useApprovalReview"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"

import { messages } from "./ApprovalBlock"
import { ApprovalCheck } from "./ApprovalCheck"

interface Props {
    organisationId: string
    objectId: string
    objectType: ApprovalObjectType
    className?: string
    showRetract: boolean
    showRetractAll: boolean
    onApproved: () => void
    onRefused: () => void
    onRetract: () => void
    onReviewed?: (result: boolean) => void
    onApprovalRequired?: () => void
    onRetractAll?: () => void
}

export const UserReviewCard: FC<Props> = ({
    organisationId,
    objectId,
    objectType,
    onReviewed,
    onApproved,
    onRefused,
    onRetract,
    onApprovalRequired,
    className,
}) => {
    const { formatMessage } = useIntl()

    const {
        loading,
        isReviewing,
        isUserChecks,
        reviewed,
        progressResponse,
        users,
        teams,
        refuseLoading,
        handleApprove,
        handleUserChecks,
        handleUpdateApproval,
        isApprovalRequired,
        isCurrentUserApprovalRequired,
    } = useApprovalReview({
        organisationId,
        objectId,
        objectType,
        onReviewed,
        onApproved,
        onRetract,
        onApprovalRequired,
    })

    if (loading || !progressResponse || !isApprovalRequired) return null

    const handleRefuse = () => {
        onRefused?.()
    }

    const handleCheck = () => {
        handleUserChecks()
    }

    return (
        <Card className={className} title={formatMessage(messages.approval)} expandable>
            {isCurrentUserApprovalRequired &&
                (reviewed ? (
                    <Stack
                        direction={{ md: "column", lg: "row" }}
                        alignItems="center"
                        justifyContent="space-between"
                        gap={1}
                    >
                        <SafeFormattedMessage
                            {...messages.objectAlreadyReviewed}
                            values={{ objectType: formatMessage(approvalObjectType[objectType]) }}
                        />
                        <Button
                            type="primary-light"
                            size="small"
                            onClick={handleUpdateApproval}
                            disabled={refuseLoading}
                        >
                            <SafeFormattedMessage {...messages.retractReview} />
                        </Button>
                    </Stack>
                ) : (
                    <Stack direction="row" justifyContent="flex-end" gap={1} marginBottom={1}>
                        <Button
                            type="error-light"
                            onClick={handleRefuse}
                            disabled={isReviewing}
                            disableDelay={0}
                            size="small"
                        >
                            <SafeFormattedMessage {...messages.refuse} />
                            <X size={16} />
                        </Button>
                        <Button
                            type="primary-light"
                            onClick={handleApprove}
                            disabled={isReviewing}
                            disableDelay={0}
                            size="small"
                        >
                            <SafeFormattedMessage {...messages.approve} />
                            <Check size={16} />
                        </Button>
                    </Stack>
                ))}
            <Stack direction="row" alignItems="center" justifyContent="flex-end" className="mt-12">
                <Typography variant="caption">
                    <SafeFormattedMessage {...messages.showMyApprovals} />
                </Typography>
                <Checkbox checked={isUserChecks} onChange={handleCheck} />
            </Stack>
            <Stack gap={3}>
                {progressResponse.checks.map((check, index) => (
                    <ApprovalCheck key={`${check.name}-${index}`} check={check} users={users} teams={teams} />
                ))}
            </Stack>
        </Card>
    )
}
