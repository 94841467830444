import { Tooltip } from "@mui/material"
import { useEffect, useState } from "react"

import { fetchAndDecodeOrganization } from "~/store/organization/hooks"
import { OrganizationI } from "~/types"

export const ResponsePartnerCell = ({
    partnerOrganisationId,
    email,
}: {
    partnerOrganisationId: string
    email?: string
}) => {
    const [partnerName, setPartnerName] = useState("")

    useEffect(() => {
        const fetchPartnerName = async () => {
            const partnerOrganization: OrganizationI = await fetchAndDecodeOrganization(partnerOrganisationId)
            setPartnerName(partnerOrganization.name)
        }

        fetchPartnerName()
    }, [partnerOrganisationId])

    return (
        <Tooltip title={email}>
            <p>{partnerName}</p>
        </Tooltip>
    )
}
