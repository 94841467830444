import { Grid, Stack } from "@mui/material"
import { ExternalLink } from "react-feather"
import { generatePath } from "react-router-dom"

import { Button } from "~/components"
import { BUDGET_ROUTE } from "~/domains/transactions/budget/routes"
import { BudgetDataWithMetricsI } from "~/domains/transactions/budget/types"

interface Props {
    budgets: BudgetDataWithMetricsI[]
}

export function BudgetLink({ budgets }: Props) {
    const handleClick = (id: string) => {
        window.open(generatePath(BUDGET_ROUTE, { budgetId: id }), "_blank")
    }

    return (
        <Grid container alignItems="center" gap={1}>
            <Stack direction="row" flexWrap="wrap" gap={2}>
                {budgets.map((budget, index) => (
                    <Button type="primary-light" key={budget.budget.id} onClick={() => handleClick(budget.budget.id)}>
                        {budget.budget.name}
                        {index !== budgets.length - 1 ? ", " : ""}
                        <ExternalLink size={16} />
                    </Button>
                ))}
            </Stack>
        </Grid>
    )
}
