// FIXME: Refactor this file and the  onConnect function to reduce the complexity

/* eslint-disable complexity */
import { styled } from "@mui/material"
import {
    Background,
    BackgroundVariant,
    type Connection,
    MiniMap,
    Panel,
    ReactFlow,
    addEdge,
    useEdgesState,
    useNodesState,
    useReactFlow,
} from "@xyflow/react"
import "@xyflow/react/dist/style.css"
import React, { FC, useEffect, useMemo, useRef, useState } from "react"

import { ButtonEdge } from "~/domains/orchestration/flows/components/ButtonEdge"
import { ButtonEdgeWithLabel } from "~/domains/orchestration/flows/components/ButtonEdgeWithLabel"
import { EdgeWithLabel } from "~/domains/orchestration/flows/components/EdgeWithLabel"
import { FlowWrapper } from "~/domains/orchestration/flows/components/FlowWrapper"
import { SIDE_BAR_WIDTH_RUN_EXPLORER } from "~/domains/orchestration/flows/constants"
import { useEditor } from "~/domains/orchestration/flows/context/editorContext"
import { FLOW_NODE_TYPES, adaptFlowToEdges, adaptFlowToNodes } from "~/domains/orchestration/flows/core"
import {
    type Flow,
    isAddToBudgetNode,
    isApprovePurchaseOrderLineNode,
    isApprovePurchaseOrderNode,
    isApprovePurchaseRequestLineNode,
    isApprovePurchaseRequestNode,
    isAssignTagGroupNode,
    isBranchNode,
    isConvertPrToPoNode,
    isCreateCustomFieldNode,
    isCreateSurveyNode,
    isCreateTaskNode,
    isEventTriggerNode,
    isFetchCustomFieldsNode,
    isFetchPartnershipNode,
    isGetTagByGroupNode,
    isMappingNode,
    isRetractReviewsNode,
    isSendEmailNode,
    isSetInvoiceLifecycleStatusNode,
    isSetPartnershipFieldNode,
    isSetPaymentMethodDetailsFieldNode,
    isUpdateCustomFieldNode,
    isUpdateTripletexLedgerNode,
} from "~/domains/orchestration/flows/types"

import { RunExplorerSideBar } from "./RunExplorerSideBar"

interface Props {
    flow: Flow
}

const StyledError = styled(Panel)`
    color: var(--color-red);
    padding: var(--spacing-md);
    background-color: var(--sidebar-color);
    border-radius: var(--border-radius-sm);
`

export const RunExplorerPanel: FC<Props> = ({ flow }) => {
    const reactFlowWrapper = useRef(null)
    const [showSidebar, setShowSidebar] = useState(true)
    const { fitView } = useReactFlow()

    const state = useEditor()

    const { error } = state

    const [nodes, setNodes, onNodesChange] = useNodesState(adaptFlowToNodes(flow))
    const [edges, setEdges, onEdgesChange] = useEdgesState(
        adaptFlowToEdges(flow, {
            readOnly: true,
        })
    )
    const edgeTypes = useMemo(
        () => ({
            button: ButtonEdge,
            buttonWithLabel: ButtonEdgeWithLabel,
            withLabel: EdgeWithLabel,
        }),
        []
    )

    const fitViewDelayed = (delay = 0) => {
        const timeoutId = setTimeout(async () => {
            await fitView()
        }, delay)
        return () => clearTimeout(timeoutId)
    }

    const onConnect = (params: Connection) => {
        const nodesToUpdate = [...flow.nodes]
        const sourceNodeIndex = nodesToUpdate.findIndex((node) => node.slug === params.source)
        if (sourceNodeIndex === -1) return

        let sourceNode = nodesToUpdate[sourceNodeIndex]

        // TODO: Create a function to handle this

        const isDefaultBranchHandle =
            isBranchNode(sourceNode) && params.sourceHandle === `${nodesToUpdate[sourceNodeIndex].slug}-default`

        if (
            isEventTriggerNode(sourceNode) ||
            isSetPartnershipFieldNode(sourceNode) ||
            isSendEmailNode(sourceNode) ||
            isAddToBudgetNode(sourceNode) ||
            isSetInvoiceLifecycleStatusNode(sourceNode) ||
            isAssignTagGroupNode(sourceNode) ||
            isUpdateTripletexLedgerNode(sourceNode) ||
            isApprovePurchaseOrderNode(sourceNode) ||
            isApprovePurchaseOrderLineNode(sourceNode) ||
            isConvertPrToPoNode(sourceNode) ||
            isApprovePurchaseRequestNode(sourceNode) ||
            isApprovePurchaseRequestLineNode(sourceNode) ||
            isSetPaymentMethodDetailsFieldNode(sourceNode) ||
            isCreateSurveyNode(sourceNode) ||
            isRetractReviewsNode(sourceNode) ||
            isFetchCustomFieldsNode(sourceNode) ||
            isMappingNode(sourceNode) ||
            isGetTagByGroupNode(sourceNode) ||
            isUpdateCustomFieldNode(sourceNode) ||
            isCreateTaskNode(sourceNode) ||
            isFetchPartnershipNode(sourceNode) ||
            isCreateCustomFieldNode(sourceNode)
        ) {
            sourceNode = { ...sourceNode, nextNode: params.target }
        } else if (isBranchNode(sourceNode)) {
            const handleIndex = parseInt(params.sourceHandle || "0")

            sourceNode = {
                ...sourceNode,
                default: isDefaultBranchHandle ? params.target : sourceNode.default,
                branches: sourceNode.branches.map((branch, index) => ({
                    ...branch,
                    nextNode: handleIndex === index ? params.target : branch.nextNode,
                })),
            }
        } else {
            sourceNode = params.sourceHandle?.includes("success")
                ? { ...sourceNode, nextIfSuccess: params.target }
                : { ...sourceNode, nextIfFailure: params.target }
        }

        setEdges((eds) => addEdge(params, eds))
    }
    useEffect(() => {
        setNodes(adaptFlowToNodes(flow))
        setEdges(
            adaptFlowToEdges(flow, {
                readOnly: true,
            })
        )
    }, [flow, setEdges, setNodes])

    useEffect(() => {
        fitViewDelayed(50)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggleSideBar = () => {
        setShowSidebar((prev) => !prev)
    }

    const hasError = error !== null && error.trim().length > 0

    const sideBarWidth = showSidebar ? SIDE_BAR_WIDTH_RUN_EXPLORER : 0

    return (
        <>
            <FlowWrapper ref={reactFlowWrapper} $sidebarWidth={sideBarWidth}>
                <ReactFlow
                    key={flow.id}
                    nodes={nodes}
                    edges={edges}
                    edgeTypes={edgeTypes}
                    nodeTypes={FLOW_NODE_TYPES}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    snapToGrid
                    snapGrid={[50, 50]}
                    fitView
                    minZoom={0.1}
                    fitViewOptions={{
                        padding: 50,
                    }}
                    proOptions={{
                        hideAttribution: true,
                    }}
                >
                    <MiniMap nodeStrokeWidth={1} bgColor="transparent" />
                    {hasError && <StyledError position="top-center">{error}</StyledError>}
                    <Background color="var(--primary-color)" variant={BackgroundVariant.Dots} />
                </ReactFlow>
            </FlowWrapper>
            <RunExplorerSideBar handleToggleSideBar={handleToggleSideBar} flowId={flow.id} isOpen={showSidebar} />
        </>
    )
}
