import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { ApiRefusalReasons, RefusalReasons } from "~/domains/orchestration/flows-v0/types"
import {
    ApprovalApiResponse,
    ApprovalObjectType,
    ToCheckObjectsResponse,
} from "~/domains/orchestration/flows-v0/types/Approval"

import { refusalReasonsFromApiAdapter } from "./adapters/refusalReasonsFromApiAdapters"

const BASE_URL = import.meta.env.VITE_API_APPROVAL_URL

export enum FetchErrorType {
    HTTP_REQUEST_ERROR = "HTTP_REQUEST_ERROR",
    PARSING_ERROR = "PARSING_ERROR",
}

export const approvalApi = createApi({
    reducerPath: "approvalApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["ApprovalChecks", "RefusalReasons"],
    endpoints: (builder) => ({
        getObjectChecks: builder.query<
            ApprovalApiResponse,
            { organisationId: string; objectId: string; objectType: ApprovalObjectType; userChecks?: boolean }
        >({
            query: ({ organisationId, objectId, objectType, userChecks = false }) => ({
                url: `v2/organizations/${organisationId}/objects/${objectId}/checks-progress/`,
                params: { objectType, userChecks },
            }),
            providesTags: ["ApprovalChecks"],
        }),
        approveObject: builder.mutation<number, { objectId: string; objectType: ApprovalObjectType }>({
            query: ({ objectId, objectType }) => ({
                url: `v1/objects/${objectId}/approve/`,
                method: "POST",
                params: { objectType },
                responseHandler: async (response) => {
                    return response.status
                },
            }),
            transformErrorResponse: (response: FetchBaseQueryError) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
            invalidatesTags: ["ApprovalChecks"],
        }),
        refuseObject: builder.mutation<
            number,
            { objectId: string; objectType: ApprovalObjectType; body?: { reason: string; message: string } }
        >({
            query: ({ objectId, objectType, body }) => ({
                url: `v1/objects/${objectId}/refuse/`,
                method: "POST",
                params: { objectType },
                body,
                responseHandler: async (response) => {
                    return response.status
                },
            }),
            transformErrorResponse: (response: FetchBaseQueryError) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
            invalidatesTags: ["ApprovalChecks"],
        }),
        refusalReasons: builder.query<RefusalReasons, { organisationId: string; objectType: ApprovalObjectType }>({
            query: ({ organisationId, objectType }) => ({
                url: `v1/organizations/${organisationId}/objects/refusal-reasons/`,
                params: { objectType },
            }),
            transformResponse: (response: ApiRefusalReasons): RefusalReasons => refusalReasonsFromApiAdapter(response),
            transformErrorResponse: (response: FetchBaseQueryError) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
            providesTags: ["RefusalReasons"],
        }),
        customRefusalReasons: builder.mutation<
            RefusalReasons,
            { organisationId: string; objectType: ApprovalObjectType; body: { reasons: string[] } }
        >({
            query: ({ organisationId, objectType, body }) => ({
                url: `v1/organizations/${organisationId}/objects/refusal-reasons/`,
                method: "PUT",
                params: { objectType },
                body,
            }),
            transformErrorResponse: (response: FetchBaseQueryError) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
            invalidatesTags: ["RefusalReasons"],
        }),
        retractReview: builder.mutation<number, { objectId: string; objectType: ApprovalObjectType }>({
            query: ({ objectId, objectType }) => ({
                url: `v1/objects/${objectId}/retract/`,
                method: "POST",
                params: { objectType },
                responseHandler: async (response) => {
                    return response.status
                },
            }),
            transformErrorResponse: (response: FetchBaseQueryError) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
            invalidatesTags: ["ApprovalChecks"],
        }),
        retractAllReviews: builder.mutation<number, { objectId: string; objectType: ApprovalObjectType }>({
            query: ({ objectId, objectType }) => ({
                url: `v1/objects/${objectId}/retract-all/`,
                method: "POST",
                params: { objectType },
                responseHandler: async (response) => {
                    return response.status
                },
            }),
            transformErrorResponse: (response: FetchBaseQueryError) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
            invalidatesTags: ["ApprovalChecks"],
        }),
        getToCheckObjects: builder.query<
            ToCheckObjectsResponse,
            { organizationId: string; objectType: ApprovalObjectType }
        >({
            query: ({ organizationId, objectType }) => ({
                url: `v1/organizations/${organizationId}/objects/to-check`,
                params: { objectType },
            }),
            transformErrorResponse: (response: FetchBaseQueryError) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
    }),
})

export const {
    useRefusalReasonsQuery,
    useGetObjectChecksQuery,
    useLazyGetObjectChecksQuery,
    useApproveObjectMutation,
    useRefuseObjectMutation,
    useCustomRefusalReasonsMutation,
    useGetToCheckObjectsQuery,
    useRetractReviewMutation,
    useRetractAllReviewsMutation,
} = approvalApi
