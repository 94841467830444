import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"

import { SpeechToTextProvider } from "~/domains/orchestration/copilot/core/SpeechToTextProvider"

export const useSpeechToText = (
    handleChangeMessage: Dispatch<SetStateAction<string>>,
    handleSendMessage: () => void,
    setTextToSpeechEnabled: (value: boolean) => void
) => {
    const { locale } = useIntl()
    const recognition = SpeechToTextProvider.getInstance()
    const [isActive, setIsActive] = useState(false)
    const handleSendMessageRef = useRef(handleSendMessage)

    useEffect(() => {
        handleSendMessageRef.current = handleSendMessage
    }, [handleSendMessage])

    const onEnd = () => {
        setIsActive(false)
        setTextToSpeechEnabled(true)
        handleSendMessageRef.current()
    }
    const toggle = () => {
        if (isActive) {
            recognition.stop()
            return
        }
        setIsActive(true)
        recognition.start(locale, handleChangeMessage, onEnd)
    }

    return { isActive, toggle, isEnabled: recognition.isAvailable() }
}
