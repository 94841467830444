import { userApi } from "~/domains/identity/account/api/userApi"
import { UserProvisionAndJoinOrganizationI, UserProvisionI } from "~/domains/identity/account/api/userApi"
import { ProvisionnedUserI } from "~/types"

export async function getOrCreateUserByEmail(userProvision: UserProvisionI): Promise<ProvisionnedUserI> {
    return await userApi.provisionUserAccount(userProvision)
}

export async function getOrCreateUserByEmailAndJoinOrganization(
    userProvision: UserProvisionAndJoinOrganizationI
): Promise<ProvisionnedUserI> {
    return await userApi.provisionUserAccountAndJoinOrganization(userProvision)
}
