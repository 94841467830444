import { Box, Stack } from "@mui/material"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage, TooltipWhite } from "~/components"
import { UserSurveyStatsMetrics } from "~/domains/identity/custom-forms/types/CustomForms"

const messages = defineMessages({
    detailedMetrics: {
        id: "smartForms.detailedMetrics",
        defaultMessage: "Detailed metrics",
    },
})

export const MetricsCell = ({ metrics }: { metrics: UserSurveyStatsMetrics }) => {
    const { total, sections } = metrics

    if (!total?.value) return "-"

    // Display value with 1 decimal place if it's not an integer
    const displayValue = (value: number) => Number(value.toFixed(1))

    const tooltipContent = (
        <Box>
            <SafeFormattedMessage {...messages.detailedMetrics} />
            {sections?.map(({ sectionId, name, metric }) => (
                <Box key={sectionId}>
                    <strong>{name}</strong>: {displayValue(metric.value)}
                </Box>
            ))}
        </Box>
    )

    return (
        <TooltipWhite title={tooltipContent} arrow>
            <Stack alignItems="center" direction="row">
                {displayValue(total.value)}
            </Stack>
        </TooltipWhite>
    )
}
