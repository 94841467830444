import styled from "@emotion/styled"
import React, { useMemo } from "react"
import { ChevronRight } from "react-feather"

import { getInitialFromName } from "~/components/Avatar/getInitialFromName"
import { SpiceDBObjectType, SpiceDBPermissionsLiteI } from "~/domains/identity/roles-permissions/types/SpiceDB"

import { Name, UserTeamContainer } from "./ObjectPermissions"

interface Props {
    sharedPermissions?: SpiceDBPermissionsLiteI[] | null
    setShowOnlyList: (showOnlyList: boolean) => void
}

const Container = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 4px",
    width: "100%",
    cursor: "pointer",
})
const NameContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
})
const IconContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
})

const MAX_NAMES = 3
const getObjectNamesString = (sharedPermissions: SpiceDBPermissionsLiteI[]) => {
    const names = sharedPermissions.reduce<string[]>((acc, { objectType, name }) => {
        if (name && (objectType === SpiceDBObjectType.USER || objectType === SpiceDBObjectType.TEAM)) {
            acc.push(name)
        }
        return acc
    }, [])

    return getFirstNames(names, MAX_NAMES)
}

const getFirstNames = (names: string[], maxNames: number): string => {
    return `${names.slice(0, maxNames).join(", ")}${names.length > maxNames ? `, +${names.length - maxNames}` : ""}`
}

export const CollapsedObjects: React.FC<Props> = ({ sharedPermissions, setShowOnlyList }) => {
    const namesString = useMemo(() => {
        return sharedPermissions && sharedPermissions.length ? getObjectNamesString(sharedPermissions) : ""
    }, [sharedPermissions])

    const showList = () => setShowOnlyList(true)

    if (!sharedPermissions || !sharedPermissions.length) return null

    return (
        <Container onClick={showList}>
            <UserTeamContainer>
                <span className="company-logo">
                    <figure className="no-image noto-semi-bold">
                        {getInitialFromName(sharedPermissions[0].name as string)}
                    </figure>
                </span>
                <NameContainer>
                    <Name>{namesString}</Name>
                </NameContainer>
            </UserTeamContainer>
            <IconContainer>
                <ChevronRight size={18} />
            </IconContainer>
        </Container>
    )
}
