import { capitalize } from "@mui/material"
import * as Sentry from "@sentry/browser"
import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { sharedObjectMessages } from "~/components"
import { useTagsApi } from "~/domains/analytics/tags/tagsApi"
import { TagObjectType } from "~/domains/analytics/tags/types"
import { useCustomFieldsApi } from "~/domains/identity/custom-fields/customFieldsApi"
import { CustomFieldObjectType } from "~/domains/identity/custom-fields/types/CustomFieldObjectType"
import { useShareObjectMutation } from "~/domains/identity/roles-permissions/api/sharesApi"
import { sharedObjectPermissionsModalMessages } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import {
    PurchaseRequestDetails,
    PurchaseRequestLine,
    PurchaseRequestLineSummary,
    PurchaseRequestSummary,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { PurchaseRequestConversionStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { fetchOrganization } from "~/store/organization/hooks"
import { getOrCreateUserByEmailAndJoinOrganization } from "~/store/users/utils"
import { OrganizationId } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"
import { isDefined } from "~/utils/isDefined"

const messages = defineMessages({
    success: {
        id: "purchase.requests.request.convert.success",
        defaultMessage: "Purchase request converted to purchase order",
    },
    error: {
        id: "purchase.requests.update.error",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
    deliveryDateNeeded: {
        id: "purchase.requests.request.deliveryDateNeeded",
        defaultMessage: "You need to select a delivery date before converting this PR to a PO",
    },
})

export const useConvertToPurchaseOrder = (organizationId?: OrganizationId) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [convertLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const currentUser = useAppSelector(selectUser)
    const tagsApi = useTagsApi()
    const customFieldsApi = useCustomFieldsApi()
    const [createSharedObject, { isLoading: loadingCreateShare }] = useShareObjectMutation()

    const convertToPO = useCallback(
        async (
            purchaseRequest: PurchaseRequestDetails | PurchaseRequestSummary,
            expectedDeliveryDate: string | null = null,
            status?: PurchaseRequestConversionStatus,
            sharedEmail?: string,
            sharedOrganizationId?: OrganizationId,
            showToast: boolean = true
        ) => {
            if (!organizationId) return
            try {
                setLoading(true)
                if (!expectedDeliveryDate && !purchaseRequest.expectedDeliveryDate) {
                    throw new Error(formatMessage(messages.deliveryDateNeeded))
                }
                const result = await purchaseRequestApi.convertToPurchaseOrder(
                    organizationId,
                    purchaseRequest.id,
                    expectedDeliveryDate,
                    status
                )
                setLoading(false)
                showToast && toast.success(formatMessage(messages.success))

                try {
                    if (sharedEmail && sharedOrganizationId) {
                        const sharedOrganization = await fetchOrganization(sharedOrganizationId, dispatch)
                        const user = await getOrCreateUserByEmailAndJoinOrganization({
                            email: sharedEmail,
                            identifier: sharedOrganization.identifier,
                            countryCode: sharedOrganization.registration.countryCode,
                        })
                        setTimeout(async () => {
                            await createSharedObject({
                                organizationId,
                                objectId: result.id,
                                objectType: SharedObjectType.PurchaseOrder,
                                userId: user.userId,
                                userOrganizationId: sharedOrganizationId,
                                sendShareEmail: true,
                                creator: currentUser.id,
                            })

                            toast.success(
                                formatMessage(sharedObjectPermissionsModalMessages.success, {
                                    objectType: capitalize(
                                        formatMessage(sharedObjectMessages[SharedObjectType.PurchaseOrder])
                                    ),
                                })
                            )
                        }, 500)
                    }

                    await tagsApi.duplicateObjectTags(
                        organizationId,
                        purchaseRequest.id,
                        result.id,
                        TagObjectType.PURCHASE_ORDER
                    )

                    const purchaseOrder = await purchaseOrdersApi.findById(organizationId, result.id)
                    await Promise.allSettled(
                        purchaseRequest.lines
                            .map((purchaseRequestLine: PurchaseRequestLine | PurchaseRequestLineSummary, i: number) => {
                                if (!purchaseRequestLine.id) return null
                                const duplicateTagsPromise = tagsApi.duplicateObjectTags(
                                    organizationId,
                                    purchaseRequestLine.id,
                                    purchaseOrder.lines[i].id ?? "",
                                    TagObjectType.PURCHASE_ORDER_LINE
                                )

                                return Promise.allSettled([
                                    duplicateTagsPromise,
                                    customFieldsApi.duplicateCustomFields(
                                        purchaseRequestLine.id,
                                        purchaseOrder.lines[i].id ?? "",
                                        purchaseOrder.id,
                                        CustomFieldObjectType.PURCHASE_ORDER
                                    ),
                                ])
                            })
                            .filter(isDefined)
                    )

                    return purchaseOrder
                } catch (e) {
                    console.error(e)
                    Sentry.captureException(e, {
                        extra: {
                            action: "convertPRtoPO.duplicateTags",
                            purchaseRequestId: purchaseRequest.id,
                            purchaseOrderId: result.id,
                        },
                    })
                }
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                showToast && toast.error(formatMessage(messages.error))
                throw e
            }
        },
        [tagsApi, customFieldsApi, organizationId, dispatch, currentUser.id]
    )

    return {
        convertToPO,
        error,
        convertLoading: convertLoading ?? loadingCreateShare,
    }
}
