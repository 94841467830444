import { useIntl } from "react-intl"

import { CurrencyCodes } from "~/types"

interface CurrencySymbolProps {
    currency: CurrencyCodes
}

export function CurrencySymbol({ currency }: CurrencySymbolProps) {
    const intl = useIntl()

    const formattedCurrency = intl.formatNumber(0, {
        style: "currency",
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })

    const symbol = formattedCurrency.replace(/\d/g, "").trim()

    return <span>{symbol}</span>
}
