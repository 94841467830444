import { styled } from "@mui/material"
import React, { useMemo, useState } from "react"

import { ContactSuggestions, PartnerContactsSelector } from "~/domains/identity/partners/components/PartnerSelectors"
import { OCR_ID, PartnerProfileContactI } from "~/domains/identity/partners/types"
import { ImportInvoiceCompanyInfoI, OrganizationId } from "~/types"

import { AddContactBlock } from "./AddContactBlock"

const ContactManagementContainer = styled("div")({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "8px",
    alignItems: "center",
    margin: "8px 0 0 0",
})

interface Props {
    currentOrganizationId: OrganizationId | undefined
    partnerId: OrganizationId | null
    updateInvoiceContact: (contact: PartnerProfileContactI | null) => void
    suggestedOcrContact?: ImportInvoiceCompanyInfoI | null
    invoiceContact?: ImportInvoiceCompanyInfoI | null
}

export const InvoiceContact = ({
    currentOrganizationId,
    partnerId,
    updateInvoiceContact,
    suggestedOcrContact,
    invoiceContact,
}: Props) => {
    const ocrDataAsPartnerContact = useMemo(
        () =>
            suggestedOcrContact?.contactName || suggestedOcrContact?.email
                ? ({
                      id: OCR_ID,
                      lastName: suggestedOcrContact?.contactName ?? invoiceContact?.contactName ?? "",
                      email: suggestedOcrContact?.email ?? invoiceContact?.email ?? "",
                  } as PartnerProfileContactI)
                : null,
        [suggestedOcrContact]
    )

    const [selectedContact, setSelectedContact] = useState<PartnerProfileContactI | null>(ocrDataAsPartnerContact)
    const isDisplayable = !currentOrganizationId || !partnerId || currentOrganizationId !== partnerId

    const onContactChange = (contact: PartnerProfileContactI | null) => {
        setSelectedContact(contact)
        updateInvoiceContact(contact)
    }

    return (
        <>
            {isDisplayable && (
                <>
                    <PartnerContactsSelector
                        currentOrganizationId={currentOrganizationId}
                        partnerId={partnerId}
                        selectedContact={selectedContact}
                        onContactChange={onContactChange}
                    />
                    <ContactManagementContainer>
                        <ContactSuggestions suggestedContact={suggestedOcrContact} onContactChange={onContactChange} />
                        {currentOrganizationId && partnerId && currentOrganizationId !== partnerId && (
                            <AddContactBlock
                                currentOrganizationId={currentOrganizationId}
                                partnerId={partnerId}
                                selectedContact={selectedContact}
                            />
                        )}
                    </ContactManagementContainer>
                </>
            )}
        </>
    )
}
