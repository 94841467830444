import {
    ConditionAcceptedBySupplier,
    ObjectStrictDecision,
    SecheCustomFields,
    SecheErp,
    secheConditionallyRequiredCustomFields,
    secheRequiredCustomFields,
} from "~/domains/identity/custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"
import { CreateCustomFieldObjectPayload } from "~/domains/identity/custom-fields/types/CustomFieldValue"

export const useHasRequiredSecheCustomFields = (customFields: CreateCustomFieldObjectPayload[]): boolean => {
    const requiredFields = secheRequiredCustomFields
    const conditionallyRequiredFields = secheConditionallyRequiredCustomFields

    const hasRequiredFields = requiredFields.every((field) =>
        customFields.some((customField) => customField?.name === field && customField?.value)
    )

    const hasConditionallyRequiredFields = conditionallyRequiredFields.every((field) => {
        const conditionallyRequiredField = customFields.find((customField) => customField?.name === field)
        switch (field) {
            case SecheCustomFields.IS_EXISTING_ENTITY:
                return (
                    (conditionallyRequiredField?.value === ObjectStrictDecision.YES &&
                        customFields.find(
                            (customField) => customField?.name === SecheCustomFields.EXISTING_ENTITY_ID
                        )) ||
                    conditionallyRequiredField?.value === ObjectStrictDecision.NO
                )
            case SecheCustomFields.IS_FACTOR:
                return (
                    (conditionallyRequiredField?.value === ObjectStrictDecision.YES &&
                        customFields.find((customField) => customField?.name === SecheCustomFields.FACTOR_ID)) ||
                    conditionallyRequiredField?.value === ObjectStrictDecision.NO
                )
            case SecheCustomFields.CONDITIONS_ACCEPTED_BY_SUPPLIER:
                return conditionallyRequiredField?.value !== ConditionAcceptedBySupplier.NO
            case SecheCustomFields.ERP:
                if (conditionallyRequiredField?.value === SecheErp.IFS) {
                    return customFields.find((customField) => customField?.name === SecheCustomFields.IFS_INTERNAL_ID)
                }
                if (conditionallyRequiredField?.value === SecheErp.X3) {
                    return customFields.find((customField) => customField?.name === SecheCustomFields.X3_INTERNAL_ID)
                }
                if (conditionallyRequiredField?.value === SecheErp.IFSX3) {
                    return (
                        customFields.find((customField) => customField?.name === SecheCustomFields.IFS_INTERNAL_ID) &&
                        customFields.find((customField) => customField?.name === SecheCustomFields.X3_INTERNAL_ID)
                    )
                }
                return false
            default:
                return false
        }
    })

    return hasRequiredFields && hasConditionallyRequiredFields
}
