import { useMemo } from "react"
import { MessageDescriptor } from "react-intl"

import { CollaborationStatusOptionsDefault } from "~/domains/identity/partners/types"
import { CollaborationStatusAll, partnershipCollaborationStatusMessages } from "~/domains/identity/partners/types"
import { CollaborationStatusOptionsEiffage } from "~/domains/identity/partners/types"
import { CollaborationStatusOptionsSeche } from "~/domains/identity/partners/types"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

export const useCollaborationStatuses = (currentOrganizationId: string | undefined) => {
    const collaborationStatuses = useMemo(() => {
        // Determine which collaboration statuses to use based on the organization and features
        let statuses = CollaborationStatusOptionsDefault

        if (currentOrganizationId) {
            if (isFeatureEnabled(Features.SecheCustomFields, currentOrganizationId)) {
                statuses = CollaborationStatusOptionsSeche
            } else if (isFeatureEnabled(Features.EiffageCustomFields, currentOrganizationId)) {
                statuses = CollaborationStatusOptionsEiffage
            }
        }

        // Build the record of collaboration status messages
        return Object.keys(partnershipCollaborationStatusMessages)
            .filter((key) => statuses.includes(key))
            .reduce(
                (acc, key) => {
                    acc[key as CollaborationStatusAll] = partnershipCollaborationStatusMessages[key]
                    return acc
                },
                {} as Record<CollaborationStatusAll, MessageDescriptor>
            )
    }, [currentOrganizationId])

    return collaborationStatuses
}
