import { Autocomplete, Box, TextField, TextFieldProps, TextFieldVariants } from "@mui/material"
import { HTMLAttributes, useMemo } from "react"
import { useIntl } from "react-intl"

import { Loader } from "~/components"
import { PaymentTerm } from "~/domains/payment/payment-terms/types"

interface PaymentTermsSelectorProps {
    value: string | null
    label: string
    items: PaymentTerm[]
    variant?: TextFieldVariants
    required?: boolean
    disabled?: boolean
    loading?: boolean
    onChange: (value: PaymentTerm | null) => void
}

export const PaymentTermsSelector = ({
    value,
    label,
    items,
    onChange,
    variant = "outlined",
    required = false,
    disabled = false,
    loading = false,
}: PaymentTermsSelectorProps) => {
    const { locale } = useIntl()
    const selectedValue = items.find((item) => item.id === value) || null

    const renderInput = (params: TextFieldProps) => (
        <TextField
            {...params}
            required={required}
            label={label}
            variant={variant}
            fullWidth
            InputProps={{
                ...params.InputProps,
                endAdornment: (
                    <>
                        {loading ? <Loader small /> : null} {params.InputProps?.endAdornment}
                    </>
                ),
            }}
        />
    )

    const renderOption = useMemo(() => {
        const RenderOptionComponent = (
            props: HTMLAttributes<HTMLLIElement>,
            { name, description, description_translations, id }: PaymentTerm
        ) => (
            <Box
                {...props}
                key={id}
                component="li"
                display="flex"
                flexDirection="column"
                alignItems="flex-start !important"
            >
                <Box>{name}</Box>
                <Box sx={{ color: "var(--color-grey)", fontSize: "var(--font-size-sm-light)" }}>
                    {description_translations[locale] ?? description}
                </Box>
            </Box>
        )

        return RenderOptionComponent
    }, [locale])

    const handleChange = (_: React.SyntheticEvent<Element, Event>, newValue: PaymentTerm | null): void => {
        onChange(newValue)
    }

    return (
        <Autocomplete
            disableClearable={true}
            value={selectedValue as PaymentTerm}
            disabled={disabled}
            options={items}
            getOptionLabel={(option) => option.name}
            onChange={handleChange}
            renderInput={renderInput}
            renderOption={renderOption}
        />
    )
}
