import { MenuItem, TextField } from "@mui/material"
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, Modal, Size } from "~/components"
import TrimmedTextField from "~/components/Form/TrimmedTextField"
import {
    CREATION_ID,
    CreatePartnerProfileContactI,
    OCR_ID,
    PartnerProfileContactI,
} from "~/domains/identity/partners/types"
import { LanguageCode, languageCodeMessages } from "~/types/LanguageCode"

const messages = defineMessages({
    titleNew: { id: "partners.bookofrelations.modalContact.titleNew", defaultMessage: "New contact" },
    titleUpdate: { id: "partners.bookofrelations.modalContact.titleUpdate", defaultMessage: "Update contact" },
    lastName: { id: "partners.bookofrelations.contact.lastName", defaultMessage: "Last name" },
    firstName: { id: "partners.bookofrelations.contact.firstName", defaultMessage: "First name" },
    email: { id: "partners.bookofrelations.contact.email", defaultMessage: "Email" },
    phoneNumber: { id: "partners.bookofrelations.contact.phoneNumber", defaultMessage: "Phone number" },
    position: { id: "partners.bookofrelations.contact.position", defaultMessage: "Position" },
    language: { id: "partners.bookofrelations.contact.language", defaultMessage: "Language" },
    timezone: { id: "partners.bookofrelations.contact.timezone", defaultMessage: "Timezone" },
    save: { id: "partners.bookofrelations.contact.save", defaultMessage: "Add a contact" },
    update: { id: "partners.bookofrelations.contact.update", defaultMessage: "Update contact" },
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
})

const timezones = Intl.supportedValuesOf("timeZone")

const isExistingContact = (contact: CreatePartnerProfileContactI | PartnerProfileContactI | null | undefined) =>
    contact && "id" in contact && contact.id !== OCR_ID && contact.id !== CREATION_ID

interface Props {
    save: (payload: CreatePartnerProfileContactI | PartnerProfileContactI) => void
    open: boolean
    close: () => void
    contact?: CreatePartnerProfileContactI | PartnerProfileContactI | null
    requiredFields?: string[]
    label?: string
}

export const ModalContact: React.FC<Props> = ({ save, open, close, contact, requiredFields, label }) => {
    const { formatMessage } = useIntl()
    const [formData, setFormData] = useState(contact)
    const isNew = !isExistingContact(contact)

    useEffect(() => {
        if (contact) {
            setFormData(contact)
        }
    }, [contact])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (isNew) {
            const payload: CreatePartnerProfileContactI | PartnerProfileContactI = { ...formData }
            save(payload)
        } else {
            save({ ...contact, ...formData })
        }
        close()
    }

    return (
        <Modal open={open} className="modal-add-items" size={Size.MD}>
            <Modal.Header>
                <h4>{label ?? formatMessage(isNew ? messages.titleNew : messages.titleUpdate)}</h4>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Content>
                    <TextField
                        id="contact.lastName"
                        name="lastName"
                        className="mb-12"
                        label={formatMessage(messages.lastName)}
                        placeholder={formatMessage(messages.lastName)}
                        onChange={handleChange}
                        value={formData?.lastName}
                        fullWidth
                        required={requiredFields?.includes("lastName")}
                    />
                    <TextField
                        id="contact.firstName"
                        name="firstName"
                        className="mb-12"
                        label={formatMessage(messages.firstName)}
                        placeholder={formatMessage(messages.firstName)}
                        onChange={handleChange}
                        value={formData?.firstName}
                        fullWidth
                        required={requiredFields?.includes("firstName")}
                    />
                    <TrimmedTextField
                        id="contact.email"
                        name="email"
                        className="mb-12"
                        label={formatMessage(messages.email)}
                        placeholder={formatMessage(messages.email)}
                        onChange={handleChange}
                        value={formData?.email ?? ""}
                        fullWidth
                        type="email"
                        required={requiredFields?.includes("email")}
                    />
                    <TextField
                        id="contact.phoneNumber"
                        name="phoneNumber"
                        className="mb-12"
                        label={formatMessage(messages.phoneNumber)}
                        placeholder={formatMessage(messages.phoneNumber)}
                        onChange={handleChange}
                        value={formData?.phoneNumber ?? ""}
                        fullWidth
                        required={requiredFields?.includes("phoneNumber")}
                    />
                    <TextField
                        id="contact.position"
                        name="position"
                        className="mb-12"
                        label={formatMessage(messages.position)}
                        placeholder={formatMessage(messages.position)}
                        onChange={handleChange}
                        value={formData?.position ?? ""}
                        fullWidth
                        required={requiredFields?.includes("position")}
                    />
                    <TextField
                        select
                        id="contact.language"
                        name="language"
                        className="mb-12"
                        label={formatMessage(messages.language)}
                        placeholder={formatMessage(messages.language)}
                        onChange={handleChange}
                        value={formData?.language ?? ""}
                        fullWidth
                        required={requiredFields?.includes("language")}
                    >
                        {Object.values(LanguageCode).map((language) => (
                            <MenuItem key={language} value={language}>
                                {formatMessage(languageCodeMessages[language])}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        id="contact.timezone"
                        name="timezone"
                        className="mb-12"
                        label={formatMessage(messages.timezone)}
                        placeholder={formatMessage(messages.timezone)}
                        onChange={handleChange}
                        value={formData?.timezone ?? ""}
                        fullWidth
                        required={requiredFields?.includes("timezone")}
                    >
                        {timezones.map((tz) => (
                            <MenuItem key={tz} value={tz}>
                                {tz}
                            </MenuItem>
                        ))}
                    </TextField>
                </Modal.Content>
                <Modal.Footer>
                    <Button type="neutral" onClick={close}>
                        {formatMessage(messages.cancel)}
                    </Button>
                    <Button type="primary" buttonType="submit">
                        {isNew ? formatMessage(messages.save) : formatMessage(messages.update)}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
