import { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/components"

import { INVOICES_ROUTE } from "./routes"

const InvoicesPageLazy = lazy(() =>
    import("~/domains/transactions/invoices/pages/index").then(({ InvoicesPage }) => ({
        default: InvoicesPage,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key={INVOICES_ROUTE}>
            <Route path={INVOICES_ROUTE} element={<InvoicesPageLazy />} />
        </Route>,
    ],
}
