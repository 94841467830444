// FIXME: split this file

/* eslint-disable max-lines */
import { Box } from "@mui/material"
import classNames from "classnames"
import { lazy, useCallback, useEffect, useMemo, useState } from "react"
import { Check, Package, Send, Tag } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import {
    BulkActionButton,
    BulkActions,
    ConfirmModal,
    DatatableFiltersActions,
    Tabs,
    ViewSwitcherTab,
} from "~/components"
import { ModalBatchImport } from "~/components/ModalBatchImport/ModalBatchImport"
import { ModalResultImport } from "~/components/ModalBatchImport/ModalResultImport"
import { CheckFileTypeResult } from "~/components/UploadDocument/UploadDocument"
import { withSocketIOProvider } from "~/domains/_shared/subscriptions/components/SocketIOContext"
import { ModalAddTags } from "~/domains/analytics/tags/components/ModalAddTags"
import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks"
import { TagI, TagObjectType } from "~/domains/analytics/tags/types"
import { useFetchSharedObjects } from "~/domains/identity/roles-permissions/store/hooks/useFetchSharedObjects"
import { useHasPermissions } from "~/domains/identity/roles-permissions/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { useTagsWithTagGroupName } from "~/domains/transactions/invoices/components/list/InvoicesList/useTagsWithTagGroupName"
import { Filters } from "~/domains/transactions/purchase-orders/components/Filters"
import {
    ToDoTabsActionByBuyer,
    ToDoTabsActionBySupplier,
    getApprovable,
    getPOBulkActionButtons,
    getPOsByViewAndTab,
    getPurchaseOrderTabs,
    getReceivable,
    getSendable,
    purchaseOrdersTabMessages,
    showBulkActionButtonCount,
    statusCounts,
} from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { useAddTagsToPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useAddTagsToPurchaseOrders"
import { useApprovePurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useApprovePurchaseOrders"
import { useMarkAsReceivedPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useMarkAsReceivedPurchaseOrders"
import { useSendPurchaseOrders } from "~/domains/transactions/purchase-orders/hooks/useSendPurchaseOrders"
import { PURCHASE_ORDERS_ROUTE, PURCHASE_ORDER_NEW_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { useFetchPurchaseOrders } from "~/domains/transactions/purchase-orders/store/hooks"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { ListPurchaseOrders, PurchaseOrdersTab } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useImportBatchPurchaseOrder } from "~/domains/transactions/purchase-requests/store/hooks/useImportBatchPurchaseOrder"
import { useTitle } from "~/hooks"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization, selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { AuthorizationName, ViewTypeI } from "~/types"
import { ImportBatchResponseI } from "~/types/ImportBatch"

const ListLazy = lazy(() =>
    import("~/domains/transactions/purchase-orders/components/POList/List").then(({ List }) => ({ default: List }))
)

const messages = defineMessages({
    title: {
        id: "purchase.orders.list.title",
        defaultMessage: "Purchase orders",
    },
    labelSupplier: {
        id: "purchase.orders.list.labelSupplier",
        defaultMessage: "From buyer",
    },
    labelBuyer: {
        id: "purchase.orders.list.labelBuyer",
        defaultMessage: "Sent to supplier",
    },
    errorWrongFileType: {
        id: "purchase.list.modalImportBatch.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept CSV and XLSX files.",
    },
    modalUploadTitle: {
        id: "purchase.list.modalImportBatch.title",
        defaultMessage: "Import Purchase Orders in batch",
    },
    modalUploadTip: {
        id: "purchase.list.modalImportBatch.uploadBox.tip",
        defaultMessage: "Supported format : csv, xlsx. No more than 2mb",
    },
    selected: { id: "purchase.requests.table.selected", defaultMessage: "selected" },
    approve: { id: "purchase.orders.list.approve", defaultMessage: "Approve" },
    send: { id: "purchase.orders.list.send", defaultMessage: "Send" },
    received: { id: "purchase.orders.list.received", defaultMessage: "Received" },
    addTags: { id: "purchase.orders.list.addTags", defaultMessage: "Add tags" },
    nothingToApprove: {
        id: "purchase.orders.list.bulk.approve.nothingToApprove",
        defaultMessage:
            'Nothing to approve. To be able to approve a purchase order, it should be in status "Awaiting your approval".',
    },
    confirmApproveTitle: {
        id: "purchase.orders.list.bulk.approve.confirmApproveTitle",
        defaultMessage: "Approve purchase orders",
    },
    confirmApproveMessage: {
        id: "purchase.orders.list.bulk.approve.confirmApproveMessage",
        defaultMessage: "Are you sure to approve {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToSend: {
        id: "purchase.orders.list.bulk.send.nothingToSend",
        defaultMessage:
            "Nothing to send. To be able to send a purchase order, it must be shared with a supplier and all lines need to be approved.",
    },
    confirmSendTitle: {
        id: "purchase.orders.list.bulk.send.confirmSendTitle",
        defaultMessage: "Send purchase orders",
    },
    confirmSendMessage: {
        id: "purchase.orders.list.bulk.send.confirmSendMessage",
        defaultMessage: "Are you sure to send {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToMarkAsReceived: {
        id: "purchase.orders.list.bulk.received.nothingToMarkAsReceived",
        defaultMessage:
            "Nothing to mark as received. To be able to mark as received a purchase order, it must be at least mutually accepted.",
    },
    confirmReceivedTitle: {
        id: "purchase.orders.list.bulk.received.confirmReceivedTitle",
        defaultMessage: "Mark purchase orders as received",
    },
    confirmReceivedMessage: {
        id: "purchase.orders.list.bulk.received.confirmReceivedMessage",
        defaultMessage:
            "Are you sure to mark as received {count} purchase {count, plural, =0 {} one {order} other {orders}}?",
    },
    nothingToAddTags: { id: "purchase.orders.list.bulk.addTags.nothingToAddTags", defaultMessage: "No tag to add" },
    createSinglePO: { id: "purchase.orders.createSinglePo", defaultMessage: "Create a single PO" },
    importBatch: { id: "purchase.orders.importBatch", defaultMessage: "Import in batch" },
})

const getTabLabel = (
    message: string,
    tab: PurchaseOrdersTab,
    view: ViewTypeI | string,
    count: number = 0
): JSX.Element => {
    const classes = classNames("tab-count", {
        todo:
            (view === ViewTypeI.supplier && ToDoTabsActionBySupplier[tab]) ||
            (view === ViewTypeI.buyer && ToDoTabsActionByBuyer[tab]),
    })
    return (
        <span>
            {message}
            {tab === PurchaseOrdersTab.ALL || count === 0 ? null : <div className={classes}> {count} </div>}
        </span>
    )
}

const ACCEPTED_FILE_EXTENSIONS: string[] = ["csv", "xlsx"]
const NOTION_URL_BATCH_PR =
    "https://get-flowie.notion.site/Flowie-Documentation-File-Format-for-Purchase-Order-Upload-5c146e1f97ac4277baea19ff61a5d9d9?pvs=4"

export const PurchaseOrders = withSocketIOProvider(() => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const organization = useAppSelector(selectCurrentOrganization)
    const [selectedPOs, setSelectedPOs] = useState<string[]>([])
    const [modalBatchImportVisible, setModalBatchImportVisible] = useState<boolean>(false)
    const [modalResultImportVisible, setModalResultImportVisible] = useState<boolean>(false)
    const [resultBatchImport, setResultBatchImport] = useState<ImportBatchResponseI | undefined>(undefined)
    const [tabValue, setTabValue] = useState<PurchaseOrdersTab>(PurchaseOrdersTab.ALL)
    const [bulkActionButtons, setBulkActionButtons] = useState<BulkActionButton[]>([])
    const [selectedForApprove, setSelectedForApprove] = useState<ListPurchaseOrders>([])
    const [displayModalApprove, setDisplayModalApprove] = useState<boolean>(false)
    const [selectedForSend, setSelectedForSend] = useState<string[]>([])
    const [displayModalSend, setDisplayModalSend] = useState<boolean>(false)
    const [selectedForReceived, setSelectedForReceived] = useState<ListPurchaseOrders>([])
    const [displayModalReceived, setDisplayModalReceived] = useState<boolean>(false)
    const [selectedForAddTags, setSelectedForAddTags] = useState<string[]>([])
    const [displayModalAddTags, setDisplayModalAddTags] = useState<boolean>(false)

    const pageName = formatMessage(messages.title)
    useTitle(pageName)
    const { viewType } = useParams()
    const navigate = useNavigate()
    const view = useMemo(() => viewType ?? ViewTypeI.buyer, [viewType]) as ViewTypeI

    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const { importBatchPO, loading: loadingBatchImport } = useImportBatchPurchaseOrder(organizationId as string)
    const {
        purchaseOrders,
        loading: loadingPurchaseOrders,
        refetchPurchaseOrders,
    } = useFetchPurchaseOrders(view, organizationId)

    const organizationSharings = useFetchSharedObjects(organizationId)

    const { hasPermission: hasPOCreatePermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.CREATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )

    const approvePurchaseOrders = useApprovePurchaseOrders()
    const sendPurchaseOrders = useSendPurchaseOrders()
    const receivedPurchaseOrders = useMarkAsReceivedPurchaseOrders()
    const { addTagsToPurchaseOrders } = useAddTagsToPurchaseOrders()
    const tags = useTagsWithTagGroupName(tagGroups)

    const handleApprovePurchaseOrder = useCallback(
        (selected: ListPurchaseOrders) => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToApprove))
            }

            setSelectedForApprove(selected)
            setDisplayModalApprove(true)
        },
        [formatMessage]
    )

    const handleSendPurchaseOrder = useCallback(
        (selected: string[]) => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToSend))
            }

            setSelectedForSend(selected)
            setDisplayModalSend(true)
        },
        [formatMessage]
    )

    const handleReceivedPurchaseOrder = useCallback(
        (selected: ListPurchaseOrders) => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToMarkAsReceived))
            }

            setSelectedForReceived(selected)
            setDisplayModalReceived(true)
        },
        [formatMessage]
    )

    const handleAddTagsPurchaseOrder = useCallback(
        (selected: string[]) => {
            if (!selected.length) {
                return toast.error(formatMessage(messages.nothingToAddTags))
            }

            setSelectedForAddTags(selected)
            setDisplayModalAddTags(true)
        },
        [formatMessage]
    )

    const getApproveBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            return {
                label: formatMessage(messages.approve),
                count: showBulkActionButtonCount[currentTab]
                    ? getApprovable(selectedPOs, purchaseOrders, view).length
                    : undefined,
                icon: <Check size={16} />,
                onClick: () => handleApprovePurchaseOrder(getApprovable(selectedPOs, purchaseOrders, view)),
            }
        },
        [selectedPOs, purchaseOrders, formatMessage, view, handleApprovePurchaseOrder]
    )

    const getSendBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            return {
                label: formatMessage(messages.send),
                count: showBulkActionButtonCount[currentTab]
                    ? getSendable(selectedPOs, purchaseOrders, view, organizationSharings).length
                    : undefined,
                icon: <Send size={16} />,
                onClick: () =>
                    handleSendPurchaseOrder(getSendable(selectedPOs, purchaseOrders, view, organizationSharings)),
            }
        },
        [selectedPOs, purchaseOrders, organizationSharings, formatMessage, handleSendPurchaseOrder, view]
    )

    const getReceivedBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            return {
                label: formatMessage(messages.received),
                count: showBulkActionButtonCount[currentTab]
                    ? getReceivable(selectedPOs, purchaseOrders).length
                    : undefined,
                icon: <Package size={16} />,
                onClick: () => handleReceivedPurchaseOrder(getReceivable(selectedPOs, purchaseOrders)),
            }
        },
        [selectedPOs, purchaseOrders, formatMessage, handleReceivedPurchaseOrder]
    )

    const getAddTagsBulkActionButton = useCallback(
        (currentTab: PurchaseOrdersTab): BulkActionButton => {
            return {
                label: formatMessage(messages.addTags),
                count: showBulkActionButtonCount[currentTab] ? selectedPOs.length : undefined,
                icon: <Tag size={16} />,
                onClick: () => handleAddTagsPurchaseOrder(selectedPOs),
            }
        },
        [selectedPOs, formatMessage, handleAddTagsPurchaseOrder]
    )

    useEffect(() => {
        const buttons = getPOBulkActionButtons(
            tabValue,
            view,
            getApproveBulkActionButton,
            getSendBulkActionButton,
            getReceivedBulkActionButton,
            getAddTagsBulkActionButton
        )
        setBulkActionButtons(buttons)
    }, [
        formatMessage,
        selectedPOs,
        purchaseOrders,
        tabValue,
        view,
        getApproveBulkActionButton,
        getSendBulkActionButton,
        getReceivedBulkActionButton,
        getAddTagsBulkActionButton,
    ])

    const handleSelectedPR = (rowSelectionIds: string[]) => {
        setSelectedPOs(rowSelectionIds)
    }

    const handleFile = useCallback(
        async (file: File) => {
            try {
                const result: ImportBatchResponseI = await importBatchPO(file)
                setResultBatchImport(result)
                closeModalBatchImport()
                showResultImportModal()
                await refetchPurchaseOrders()
            } catch (error) {
                console.error(error)
            }
        },
        [importBatchPO, refetchPurchaseOrders]
    )

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult => {
            const fileExtension = file.name.split(".").pop()
            if (fileExtension && ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)) {
                return { result: true, error: null }
            }

            return { result: false, error: formatMessage(messages.errorWrongFileType) }
        },
        [formatMessage]
    )

    const handleClickInstructions = () => {
        window.open(NOTION_URL_BATCH_PR, "_blank")
    }

    const handleChangeTab = (newValue: number | string) => {
        setTabValue(newValue as PurchaseOrdersTab)
        clearSelection()
        dispatch(purchaseOrdersActions.setCurrentPurchaseOrdersTab(newValue as PurchaseOrdersTab))
    }

    const handleConfirmModalApprove = useCallback(async () => {
        if (organizationId) {
            setDisplayModalApprove(false)
            await approvePurchaseOrders(selectedForApprove, organizationId)
            clearSelection()
            setSelectedForApprove([])
            return true
        }
        return false
    }, [selectedForApprove, organizationId, approvePurchaseOrders])

    const handleConfirmModalSend = useCallback(async () => {
        if (organizationId) {
            setDisplayModalSend(false)
            await sendPurchaseOrders(selectedForSend, organizationId)
            clearSelection()
            setSelectedForSend([])
            return true
        }
        return false
    }, [selectedForSend, organizationId, sendPurchaseOrders])

    const handleConfirmModalReceived = useCallback(async () => {
        if (organizationId) {
            setDisplayModalReceived(false)
            await receivedPurchaseOrders(selectedForReceived, organizationId)
            clearSelection()
            setSelectedForReceived([])
            return true
        }
        return false
    }, [selectedForReceived, organizationId, receivedPurchaseOrders])

    const handleConfirmModalAddTags = useCallback(
        async (tagsToAdd: TagI[], tagsToRemove: TagI[]) => {
            if (organizationId) {
                setDisplayModalAddTags(false)
                await addTagsToPurchaseOrders(
                    organizationId,
                    selectedForAddTags,
                    TagObjectType.PURCHASE_ORDER,
                    purchaseOrders,
                    tagsToAdd,
                    tagsToRemove
                )
                clearSelection()
                setSelectedForAddTags([])
                return true
            }
            return false
        },
        [selectedForAddTags, organizationId, purchaseOrders, addTagsToPurchaseOrders]
    )

    const handleHideModalApprove = () => setDisplayModalApprove(false)
    const handleHideModalSend = () => setDisplayModalSend(false)
    const handleHideModalReceived = () => setDisplayModalReceived(false)
    const closeModalBatchImport = () => setModalBatchImportVisible(false)
    const showResultImportModal = () => setModalResultImportVisible(true)
    const closeModalResultImport = () => setModalResultImportVisible(false)
    const onClickBatchImport = () => setModalBatchImportVisible(true)
    const clearSelection = () => setSelectedPOs([])

    const countByStatus = useMemo(() => statusCounts(purchaseOrders, view), [purchaseOrders, view])
    const tabs = useMemo(() => getPurchaseOrderTabs(view), [view])

    const onClickNewDraftPo = () => navigate(PURCHASE_ORDER_NEW_ROUTE)
    const actions: DatatableFiltersActions[] = [
        { label: formatMessage(messages.createSinglePO), action: onClickNewDraftPo },
        { label: formatMessage(messages.importBatch), action: onClickBatchImport },
    ]

    const handleSwitchViewToBuyers = () => navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.buyer }))
    const handleSwitchViewToSuppliers = () =>
        navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.supplier }))

    return (
        <Box className="main-box no-header">
            {organizationId && (
                <>
                    <Filters actions={actions} hasPermission={hasPOCreatePermission} />
                    <ViewSwitcherTab
                        view={view}
                        labelSupplier={formatMessage(messages.labelSupplier)}
                        labelBuyer={formatMessage(messages.labelBuyer)}
                        actionBuyer={handleSwitchViewToBuyers}
                        actionSupplier={handleSwitchViewToSuppliers}
                    />

                    <Tabs
                        defaultValue={tabValue}
                        setChange={handleChangeTab}
                        tabs={tabs.map((tab) => ({
                            label: getTabLabel(
                                formatMessage(purchaseOrdersTabMessages[tab]),
                                tab,
                                view,
                                countByStatus[tab]
                            ),
                            value: tab,
                            component: (
                                <>
                                    <ListLazy
                                        purchaseOrders={getPOsByViewAndTab(tab, purchaseOrders)}
                                        organizationId={organizationId}
                                        view={view}
                                        tags={tags}
                                        rowSelectionModel={selectedPOs}
                                        handleSelectedRows={handleSelectedPR}
                                        loading={loadingPurchaseOrders}
                                    />
                                </>
                            ),
                        }))}
                    />
                </>
            )}
            <ModalBatchImport
                open={modalBatchImportVisible}
                close={closeModalBatchImport}
                handleFile={handleFile}
                checkFileType={checkFileType}
                handleClickInstructions={handleClickInstructions}
                loading={loadingBatchImport}
                uploadTip={messages.modalUploadTip}
                title={messages.modalUploadTitle}
            />
            <ModalResultImport
                open={modalResultImportVisible}
                close={closeModalResultImport}
                showNewImportModal={onClickBatchImport}
                resultBatchImport={resultBatchImport}
                title={messages.modalUploadTitle}
            />
            <ConfirmModal
                title={formatMessage(messages.confirmApproveTitle)}
                open={displayModalApprove}
                close={handleHideModalApprove}
                onConfirm={handleConfirmModalApprove}
                confirmButtonType="primary"
            >
                {formatMessage(messages.confirmApproveMessage, {
                    count: selectedForApprove.length,
                })}
            </ConfirmModal>
            <ConfirmModal
                title={formatMessage(messages.confirmSendTitle)}
                open={displayModalSend}
                close={handleHideModalSend}
                onConfirm={handleConfirmModalSend}
                confirmButtonType="primary"
            >
                {formatMessage(messages.confirmSendMessage, {
                    count: selectedForSend.length,
                })}
            </ConfirmModal>
            <ConfirmModal
                title={formatMessage(messages.confirmReceivedTitle)}
                open={displayModalReceived}
                close={handleHideModalReceived}
                onConfirm={handleConfirmModalReceived}
                confirmButtonType="primary"
            >
                {formatMessage(messages.confirmReceivedMessage, {
                    count: selectedForReceived.length,
                })}
            </ConfirmModal>
            {organization && (
                <ModalAddTags
                    organization={organization}
                    selected={selectedForAddTags}
                    objects={purchaseOrders}
                    displayModal={displayModalAddTags}
                    setDisplayModal={setDisplayModalAddTags}
                    onConfirm={handleConfirmModalAddTags}
                />
            )}
            {selectedPOs.length > 0 && (
                <BulkActions
                    selected={selectedPOs}
                    onClose={clearSelection}
                    buttons={bulkActionButtons}
                    selectedMessage={formatMessage(messages.selected, {
                        s: selectedPOs.length > 1 ? "s" : "",
                    })}
                />
            )}
        </Box>
    )
})
