/* eslint-disable max-lines */
import { MessageDescriptor, defineMessages } from "react-intl"

import {
    AllAdvancedFieldsUnion,
    Event,
    NodeType,
    ObjectType,
    PartnershipFieldToUpdate,
    RunStatus,
    TaskPriority,
    TaskStatus,
    TransactionType,
} from "~/domains/orchestration/flows/types"

const page = defineMessages({
    title: {
        id: "flows.title",
        defaultMessage: "Flows (beta)",
    },
    flowsTab: {
        id: "flows.flowTab",
        defaultMessage: "Flows",
    },
    runsTab: {
        id: "flows.runsTab",
        defaultMessage: "Runs",
    },
    noOrganization: {
        id: "flows.noOrganization",
        defaultMessage: "No organization",
    },
})

const runsTable = defineMessages({
    id: {
        id: "runs.table.id",
        defaultMessage: "Id",
    },
    status: {
        id: "runs.table.status",
        defaultMessage: "Status",
    },
    startedAt: {
        id: "runs.table.startedAt",
        defaultMessage: "Started at",
    },
    finishedAt: {
        id: "runs.table.finishedAt",
        defaultMessage: "Finished at",
    },
})

const runInformation = defineMessages({
    id: {
        id: "runs.information.id",
        defaultMessage: "Run ID",
    },
    flowId: {
        id: "runs.information.flowId",
        defaultMessage: "Flow ID",
    },
    flowVersion: {
        id: "runs.information.flowVersion",
        defaultMessage: "Flow Version",
    },
    status: {
        id: "runs.information.status",
        defaultMessage: "Status",
    },
    state: {
        id: "runs.information.state",
        defaultMessage: "State",
    },
    error: {
        id: "runs.information.error",
        defaultMessage: "Error",
    },
    startedAt: {
        id: "runs.information.startedAt",
        defaultMessage: "Started at",
    },
    finishedAt: {
        id: "runs.information.finishedAt",
        defaultMessage: "Finished at",
    },
})

const runStatus: Record<RunStatus, MessageDescriptor> = defineMessages({
    [RunStatus.CANCELED]: {
        id: "runs.status.CANCELED",
        defaultMessage: "Canceled",
    },
    [RunStatus.FAILED]: {
        id: "runs.status.FAILED",
        defaultMessage: "Failed",
    },
    [RunStatus.FINISHED]: {
        id: "runs.status.FINISHED",
        defaultMessage: "Finished",
    },
    [RunStatus.RUNNING]: {
        id: "runs.status.RUNNING",
        defaultMessage: "Running",
    },
    [RunStatus.SUSPENDED]: {
        id: "runs.status.SUSPENDED",
        defaultMessage: "Suspended",
    },
})

const filter = defineMessages({
    newFlow: {
        id: "flows.filters.newFlowButtonlabel",
        defaultMessage: "New flow +",
    },
})

const modalCreate = defineMessages({
    title: { id: "flow.modalCreateFlow.title", defaultMessage: "Create a new flow" },
    flowName: { id: "flow.modalCreateFlow.flowName", defaultMessage: "Name of your flow" },
})

const editor = defineMessages({
    title: {
        id: "flows.editor.title",
        defaultMessage: "Flows",
    },
    enabled: {
        id: "flows.editor.enabled",
        defaultMessage: "Active",
    },
    disabled: {
        id: "flows.editor.disabled",
        defaultMessage: "Inactive",
    },
    archived: {
        id: "flows.editor.archived",
        defaultMessage: "Flow has been archived",
    },
    publish: {
        id: "flows.editor.publish",
        defaultMessage: "publish",
    },
    editButtonTooltip: {
        id: "flows.editor.editButtonTooltip",
        defaultMessage: "Edit flow's name",
    },
    confirmArchivedTitle: {
        id: "flows.editor.confirmArchivedTitle",
        defaultMessage: "Archive a flow",
    },
    confirmArchivedMessage: {
        id: "flows.editor.confirmArchivedMessage",
        defaultMessage: "Are you sure to archive {name} flow? ",
    },
    version: {
        id: "flows.editor.version",
        defaultMessage: "v{version} (latest)",
    },
    editorTab: {
        id: "flows.editor.editorTab",
        defaultMessage: "Editor",
    },
    explorerTab: {
        id: "flows.editor.explorerTab",
        defaultMessage: "Run",
    },
})

const eventTriggerNode = defineMessages({
    noTriggerError: {
        id: "flows.eventTriggerNode.noTriggerError",
        defaultMessage: "Missing trigger conditions",
    },
    information: {
        id: "flows.eventTriggerNode.information",
        defaultMessage: "Run when {event}",
    },
})

const event: Record<Event, MessageDescriptor> = defineMessages({
    [Event.BUDGET_CREATED]: {
        id: "flows.event.BUDGET_CREATED",
        defaultMessage: "Budget is created",
    },
    [Event.BUDGET_UPDATED]: {
        id: "flows.event.BUDGET_UPDATED",
        defaultMessage: "Budget is updated",
    },
    [Event.BUYER_PURCHASE_ORDER_CREATED]: {
        id: "flows.event.BUYER_PURCHASE_ORDER_CREATED",
        defaultMessage: "Buyer purchase order is created",
    },
    [Event.INVOICE_CREATED]: {
        id: "flows.event.INVOICE_CREATED",
        defaultMessage: "Invoice is created",
    },
    [Event.INVOICE_UPDATED]: {
        id: "flows.event.INVOICE_UPDATED",
        defaultMessage: "Invoice is updated",
    },
    [Event.PARTNERSHIP_CREATED]: {
        id: "flows.event.PARTNERSHIP_CREATED",
        defaultMessage: "Partnership is created",
    },
    [Event.SUPPLIER_PURCHASE_ORDER_CREATED]: {
        id: "flows.event.SUPPLIER_PURCHASE_ORDER_CREATED",
        defaultMessage: "Supplier purchase order is created",
    },
    [Event.INVOICE_TO_PURCHASE_ORDER_LINKED]: {
        id: "flows.event.INVOICE_TO_PURCHASE_ORDER_LINKED",
        defaultMessage: "Invoice is linked to purchase order",
    },
    [Event.SUPPLIER_PURCHASE_ORDER_LINE_CREATED]: {
        id: "flows.event.SUPPLIER_PURCHASE_ORDER_LINE_CREATED",
        defaultMessage: "Supplier purchase order line is created",
    },
    [Event.BUYER_PURCHASE_ORDER_LINE_CREATED]: {
        id: "flows.event.BUYER_PURCHASE_ORDER_LINE_CREATED",
        defaultMessage: "Buyer purchase order line is created",
    },
    [Event.PURCHASE_REQUEST_CREATED]: {
        id: "flows.event.PURCHASE_REQUEST_CREATED",
        defaultMessage: "Purchase request is created",
    },
    [Event.PURCHASE_REQUEST_UPDATED]: {
        id: "flows.event.PURCHASE_REQUEST_UPDATED",
        defaultMessage: "Purchase request is updated",
    },
    [Event.PURCHASE_REQUEST_LINE_CREATED]: {
        id: "flows.event.PURCHASE_REQUEST_LINE_CREATED",
        defaultMessage: "Purchase request line is created",
    },
    [Event.PAYMENT_METHOD_DETAILS_CREATED]: {
        id: "flows.event.PAYMENT_METHOD_DETAILS_CREATED",
        defaultMessage: "Payment method details is created",
    },
    [Event.PARTNER_PAYMENT_METHOD_DETAILS_CREATED]: {
        id: "flows.event.PARTNER_PAYMENT_METHOD_DETAILS_CREATED",
        defaultMessage: "Partner payment method details is created",
    },
    [Event.SURVEY_COMPLETED]: {
        id: "flows.event.SURVEY_COMPLETED",
        defaultMessage: "Survey is completed",
    },
})

const objectType: Record<ObjectType, MessageDescriptor> = defineMessages({
    [ObjectType.ORGANIZATION_RELATIONSHIP]: {
        id: "flows.objectType.ORGANIZATION_RELATIONSHIP",
        defaultMessage: "Partnership",
    },
    [ObjectType.INVOICE]: {
        id: "flows.objectType.INVOICE",
        defaultMessage: "Invoice",
    },
    [ObjectType.BUDGET]: {
        id: "flows.objectType.BUDGET",
        defaultMessage: "Budget",
    },
    [ObjectType.PURCHASE_ORDER]: {
        id: "flows.objectType.PURCHASE_ORDER",
        defaultMessage: "Purchase Order",
    },
    [ObjectType.PURCHASE_ORDER_LINE]: {
        id: "flows.objectType.PURCHASE_ORDER_LINE",
        defaultMessage: "Purchase Order Line",
    },
    [ObjectType.PURCHASE_REQUEST]: {
        id: "flows.objectType.PURCHASE_REQUEST",
        defaultMessage: "Purchase Request",
    },
    [ObjectType.PURCHASE_REQUEST_LINE]: {
        id: "flows.objectType.PURCHASE_REQUEST_LINE",
        defaultMessage: "Purchase Request Line",
    },
    [ObjectType.PAYMENT_METHOD_DETAILS]: {
        id: "flows.objectType.PAYMENT_METHOD_DETAILS",
        defaultMessage: "Payment method details",
    },
    [ObjectType.PARTNER_PAYMENT_METHOD_DETAILS]: {
        id: "flows.objectType.PARTNER_PAYMENT_METHOD_DETAILS",
        defaultMessage: "Partner payment method details",
    },
    //
    // [ObjectType.CONTACT]: {
    //     id: "flows.objectType.CONTACT",
    //     defaultMessage: "Contact",
    // },
    // [ObjectType.PAYMENT]: {
    //     id: "flows.objectType.PAYMENT",
    //     defaultMessage: "Payment",
    // },
})

const transactionType: Record<TransactionType, MessageDescriptor> = defineMessages({
    [TransactionType.INVOICE]: {
        id: "flows.transactionType.INVOICE",
        defaultMessage: "Invoice",
    },
    [TransactionType.PURCHASE_ORDER]: {
        id: "flows.transactionType.PURCHASE_ORDER",
        defaultMessage: "Purchase Order",
    },
    [TransactionType.PURCHASE_REQUEST]: {
        id: "flows.transactionType.PURCHASE_REQUEST",
        defaultMessage: "Purchase Request",
    },
    // [TransactionType.PAYMENT]: {
    //     id: "flows.transactionType.PAYMENT",
    //     defaultMessage: "Payment",
    // },
    // [TransactionType.DEPOSIT]: {
    //     id: "flows.transactionType.DEPOSIT",
    //     defaultMessage: "Deposit",
    // },
})

const nodeTitle: Record<NodeType, MessageDescriptor> = defineMessages({
    [NodeType.ADD_TO_BUDGET_NODE]: {
        id: "flows.nodeTitle.ADD_TO_BUDGET_NODE",
        defaultMessage: "Add to Budget",
    },
    [NodeType.EVENT_TRIGGER_NODE]: {
        id: "flows.nodeTitle.EVENT_TRIGGER_NODE",
        defaultMessage: "Event Trigger",
    },
    [NodeType.IF_NODE]: {
        id: "flows.nodeTitle.IF_NODE",
        defaultMessage: "Condition",
    },
    [NodeType.CHECK_NODE]: {
        id: "flows.nodeTitle.CHECK_NODE",
        defaultMessage: "Check Node",
    },
    [NodeType.SET_PARTNERSHIP_FIELD_NODE]: {
        id: "flows.nodeTitle.SET_PARTNERSHIP_FIELD_NODE",
        defaultMessage: "Set Partnership Field",
    },
    [NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE]: {
        id: "flows.nodeTitle.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE",
        defaultMessage: "Set Payment Method Details Field",
    },
    [NodeType.SEND_EMAIL_NODE]: {
        id: "flows.nodeTitle.SEND_EMAIL_NODE",
        defaultMessage: "Send Email",
    },
    [NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE]: {
        id: "flows.nodeTitle.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE",
        defaultMessage: "Check if invoice is linked to purchase order",
    },
    [NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE]: {
        id: "flows.nodeTitle.SET_INVOICE_LIFECYCLE_STATUS_NODE",
        defaultMessage: "Set invoice lifecycle status",
    },
    [NodeType.FITS_TO_BUDGET_NODE]: {
        id: "flows.nodeTitle.FITS_TO_BUDGET_NODE",
        defaultMessage: "Fits to Budget",
    },
    [NodeType.ASSIGN_TAG_NODE]: {
        id: "flows.nodeTitle.ASSIGN_TAG_NODE",
        defaultMessage: "Assign Tag",
    },
    [NodeType.ASSIGN_TAG_GROUP_NODE]: {
        id: "flows.nodeTitle.ASSIGN_TAG_NODE",
        defaultMessage: "Assign Tag Group",
    },
    [NodeType.UPDATE_TRIPLETEX_LEDGER_NODE]: {
        id: "flows.nodeTitle.UPDATE_TRIPLETEX_LEDGER_NODE",
        defaultMessage: "Update Tripletex Ledger",
    },
    [NodeType.APPROVE_PURCHASE_ORDER_NODE]: {
        id: "flows.nodeTitle.APPROVE_PURCHASE_ORDER_NODE",
        defaultMessage: "Approve Purchase Order",
    },
    [NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE]: {
        id: "flows.nodeTitle.APPROVE_PURCHASE_ORDER_LINE_NODE",
        defaultMessage: "Approve Purchase Order Line",
    },
    [NodeType.CONVERT_PR_TO_PO_NODE]: {
        id: "flows.nodeTitle.CONVERT_PR_TO_PO_NODE",
        defaultMessage: "Convert Purchase Request to Purchase Order",
    },
    [NodeType.APPROVE_PURCHASE_REQUEST_NODE]: {
        id: "flows.nodeTitle.APPROVE_PURCHASE_REQUEST_NODE",
        defaultMessage: "Approve Purchase Request",
    },
    [NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE]: {
        id: "flows.nodeTitle.APPROVE_PURCHASE_REQUEST_LINE_NODE",
        defaultMessage: "Approve Purchase Request Line",
    },
    [NodeType.BRANCH_NODE]: {
        id: "flows.nodeTitle.BRANCH_NODE",
        defaultMessage: "Branch",
    },
    [NodeType.CREATE_SURVEY_NODE]: {
        id: "flows.nodeTitle.CREATE_SURVEY_NODE",
        defaultMessage: "Create Survey",
    },
    [NodeType.RETRACT_REVIEWS_NODE]: {
        id: "flows.nodeTitle.RETRACT_REVIEWS_NODE",
        defaultMessage: "Retract Reviews",
    },
    [NodeType.FETCH_CUSTOM_FIELDS_NODE]: {
        id: "flows.nodeTitle.FETCH_CUSTOM_FIELDS_NODE",
        defaultMessage: "Fetch Custom Fields",
    },
    [NodeType.MAPPING_NODE]: {
        id: "flows.nodeTitle.MAPPING_NODE",
        defaultMessage: "Mapping",
    },
    [NodeType.GET_TAG_BY_GROUP_NODE]: {
        id: "flows.nodeTitle.GET_TAG_BY_GROUP_NODE",
        defaultMessage: "Get Tag By Group",
    },
    [NodeType.UPDATE_CUSTOM_FIELD_NODE]: {
        id: "flows.nodeTitle.UPDATE_CUSTOM_FIELD_NODE",
        defaultMessage: "Update Custom Field",
    },
    [NodeType.CREATE_TASK_NODE]: {
        id: "flows.nodeTitle.CREATE_TASK_NODE",
        defaultMessage: "Create Task",
    },
    [NodeType.FETCH_PARTNERSHIP_NODE]: {
        id: "flows.nodeTitle.FETCH_PARTNERSHIP_NODE",
        defaultMessage: "Fetch Partnership",
    },
    [NodeType.CREATE_CUSTOM_FIELD_NODE]: {
        id: "flows.nodeTitle.CREATE_CUSTOM_FIELD_NODE",
        defaultMessage: "Create Custom Field",
    },
})

const nodeDescription: Record<NodeType, MessageDescriptor> = defineMessages({
    [NodeType.EVENT_TRIGGER_NODE]: {
        id: "flows.nodeDescription.EVENT_TRIGGER_NODE",
        defaultMessage: "This node is used to trigger a flow when an event occurs",
    },
    [NodeType.IF_NODE]: {
        id: "flows.nodeDescription.IF_NODE",
        defaultMessage: "This node is used to evaluate a condition",
    },
    [NodeType.CHECK_NODE]: {
        id: "flows.nodeDescription.CHECK_NODE",
        defaultMessage: "This node is used to require an approval",
    },
    [NodeType.SET_PARTNERSHIP_FIELD_NODE]: {
        id: "flows.nodeDescription.SET_PARTNERSHIP_FIELD_NODE",
        defaultMessage: "This node is used to set a partnership field",
    },
    [NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE]: {
        id: "flows.nodeDescription.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE",
        defaultMessage: "This node is used to set a payment method details field",
    },
    [NodeType.SEND_EMAIL_NODE]: {
        id: "flows.nodeDescription.SEND_EMAIL_NODE",
        defaultMessage: "This node is used to send an email",
    },
    [NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE]: {
        id: "flows.nodeDescription.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE",
        defaultMessage: "This node is used to check if an invoice is linked to a purchase order",
    },
    [NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE]: {
        id: "flows.nodeDescription.SET_INVOICE_LIFECYCLE_STATUS_NODE",
        defaultMessage: "This node is used to set the lifecycle status of an invoice",
    },
    [NodeType.FITS_TO_BUDGET_NODE]: {
        id: "flows.nodeDescription.FITS_TO_BUDGET_NODE",
        defaultMessage: "This node is used to check if an invoice fits to a budget",
    },
    [NodeType.ASSIGN_TAG_NODE]: {
        id: "flows.nodeDescription.ASSIGN_TAG_NODE",
        defaultMessage: "This node is used to assign a tag",
    },
    [NodeType.ASSIGN_TAG_GROUP_NODE]: {
        id: "flows.nodeDescription.ASSIGN_TAG_NODE",
        defaultMessage: "This node is used to assign a tag group to an invoice",
    },
    [NodeType.UPDATE_TRIPLETEX_LEDGER_NODE]: {
        id: "flows.nodeDescription.UPDATE_TRIPLETEX_LEDGER_NODE",
        defaultMessage: "This node is used to update Tripletex ledger",
    },
    [NodeType.APPROVE_PURCHASE_ORDER_NODE]: {
        id: "flows.nodeDescription.APPROVE_PURCHASE_ORDER_NODE",
        defaultMessage: "This node is used to approve a purchase order",
    },
    [NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE]: {
        id: "flows.nodeDescription.APPROVE_PURCHASE_ORDER_LINE_NODE",
        defaultMessage: "This node is used to approve a purchase order line",
    },
    [NodeType.CONVERT_PR_TO_PO_NODE]: {
        id: "flows.nodeDescription.CONVERT_PR_TO_PO_NODE",
        defaultMessage: "This node is used to convert a purchase request to a purchase order",
    },
    [NodeType.APPROVE_PURCHASE_REQUEST_NODE]: {
        id: "flows.nodeDescription.APPROVE_PURCHASE_REQUEST_NODE",
        defaultMessage: "This node is used to approve a purchase request",
    },
    [NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE]: {
        id: "flows.nodeDescription.APPROVE_PURCHASE_REQUEST_LINE_NODE",
        defaultMessage: "This node is used to approve a purchase request line",
    },
    [NodeType.BRANCH_NODE]: {
        id: "flows.nodeDescription.BRANCH_NODE",
        defaultMessage: "This node is used to add various branches to the flow depending on a condition",
    },
    [NodeType.CREATE_SURVEY_NODE]: {
        id: "flows.nodeDescription.CREATE_SURVEY_NODE",
        defaultMessage: "This node is used to create a survey",
    },
    [NodeType.RETRACT_REVIEWS_NODE]: {
        id: "flows.nodeDescription.RETRACT_REVIEWS_NODE",
        defaultMessage: "This node is used to retract reviews",
    },
    [NodeType.FETCH_CUSTOM_FIELDS_NODE]: {
        id: "flows.nodeDescription.FETCH_CUSTOM_FIELDS_NODE",
        defaultMessage: "This node is used to fetch custom fields",
    },
    [NodeType.ADD_TO_BUDGET_NODE]: {
        id: "flows.nodeDescription.ADD_TO_BUDGET_NODE",
        defaultMessage: "This node is used to add an amount to a budget",
    },
    [NodeType.MAPPING_NODE]: {
        id: "flows.nodeDescription.MAPPING_NODE",
        defaultMessage: "This node is used to map a value to a new value",
    },
    [NodeType.GET_TAG_BY_GROUP_NODE]: {
        id: "flows.nodeDescription.GET_TAG_BY_GROUP_NODE",
        defaultMessage: "This node is used to get a tag by group",
    },
    [NodeType.UPDATE_CUSTOM_FIELD_NODE]: {
        id: "flows.nodeDescription.UPDATE_CUSTOM_FIELD_NODE",
        defaultMessage: "This node is used to update a custom field",
    },
    [NodeType.CREATE_TASK_NODE]: {
        id: "flows.nodeDescription.CREATE_TASK_NODE",
        defaultMessage: "This node is used to create a task",
    },
    [NodeType.FETCH_PARTNERSHIP_NODE]: {
        id: "flows.nodeDescription.FETCH_PARTNERSHIP_NODE",
        defaultMessage: "This node is used to fetch a partnership",
    },
    [NodeType.CREATE_CUSTOM_FIELD_NODE]: {
        id: "flows.nodeDescription.CREATE_CUSTOM_FIELD_NODE",
        defaultMessage: "This node is used to create a custom field",
    },
})

const ifNode = defineMessages({
    conditionLabel: {
        id: "flows.ifNode.conditionLabel",
        defaultMessage: "Conditions",
    },
    noConditionError: {
        id: "flows.ifNode.noConditionError",
        defaultMessage: "Missing if conditions",
    },
    trueLabel: {
        id: "flows.ifNode.trueLabel",
        defaultMessage: "True",
    },
    falseLabel: {
        id: "flows.ifNode.falseLabel",
        defaultMessage: "False",
    },
})

const checkNode = defineMessages({
    passLabel: {
        id: "flows.checkNode.passLabel",
        defaultMessage: "Pass",
    },
    refused: {
        id: "flows.checkNode.refused",
        defaultMessage: "Refused",
    },
    objectTypeLabel: {
        id: "flows.checkNode.objectTypeLabel",
        defaultMessage: "Object Type",
    },
    reviewersLabel: {
        id: "flows.checkNode.reviewersLabel",
        defaultMessage: "Reviewers",
    },
    noReviewers: {
        id: "flows.checkNode.noReviewers",
        defaultMessage: "No reviewers",
    },
    teamIdLabel: {
        id: "flows.checkNode.teamIdLabel",
        defaultMessage: "Team ID",
    },
    typeLabel: {
        id: "flows.checkNode.typeLabel",
        defaultMessage: "Type",
    },
    passThresholdLabel: {
        id: "flows.checkNode.passThresholdLabel",
        defaultMessage: "Pass threshold",
    },
    refuseThresholdLabel: {
        id: "flows.checkNode.refuseThresholdLabel",
        defaultMessage: "Refuse threshold",
    },
})

const setPartnershipFieldNode = defineMessages({
    label: {
        id: "flows.setPartnershipFieldNode.label",
        defaultMessage: "Partnership Field",
    },
    partnershipIdLabel: {
        id: "flows.setPartnershipFieldNode.partnershipIdLabel",
        defaultMessage: "Partnership ID",
    },
    fieldLabel: {
        id: "flows.setPartnershipFieldNode.fieldLabel",
        defaultMessage: "Field to update",
    },
    valueLabel: {
        id: "flows.setPartnershipFieldNode.valueLabel",
        defaultMessage: "Value to set",
    },
})

const sendEmailNode = defineMessages({
    subjectLabel: {
        id: "flows.sendEmailNode.subjectLabel",
        defaultMessage: "Subject",
    },
    noMessageError: {
        id: "flows.sendEmailNode.noMessageError",
        defaultMessage: "Missing message",
    },
    invalidEmailError: {
        id: "flows.sendEmailNode.invalidEmailError",
        defaultMessage: "Invalid email address",
    },
})

const addToBudgetNode = defineMessages({
    amountLabel: {
        id: "flows.addToBudgetNode.amountLabel",
        defaultMessage: "Amount",
    },
})

const invoiceToPurchaseOrderMatchNode = defineMessages({
    matched: {
        id: "flows.InvoiceToPurchaseOrderMatchNode.matched",
        defaultMessage: "Linked",
    },
    unmatched: {
        id: "flows.InvoiceToPurchaseOrderMatchNode.unmatched",
        defaultMessage: "Not matched",
    },
})

const node = defineMessages({
    errorDefault: {
        id: "flows.node.errorDefault",
        defaultMessage: "Missing configuration",
    },
})

const common = defineMessages({
    nodeTitle: {
        id: "flows.common.version",
        defaultMessage: "version",
    },
    enabled: {
        id: "flows.common.enabled",
        defaultMessage: "Enabled",
    },
    disabled: {
        id: "flows.common.disabled",
        defaultMessage: "Disabled",
    },
    edit: {
        id: "flows.common.edit",
        defaultMessage: "Edit",
    },
    copy: {
        id: "flows.common.copy",
        defaultMessage: "Copy",
    },
    archive: {
        id: "flows.common.archive",
        defaultMessage: "Archive",
    },
    delete: {
        id: "flows.common.delete",
        defaultMessage: "Delete",
    },
})

const fitsToBudgetNode = defineMessages({
    fitsLabel: {
        id: "flows.fitsToBudgetNode.fitsLabel",
        defaultMessage: "Fits",
    },
    doesNotFitLabel: {
        id: "flows.fitsToBudgetNode.doesNotFitLabel",
        defaultMessage: "Does not fit",
    },
})

const branchNode = defineMessages({
    defaultBranch: {
        id: "flows.branchNode.defaultBranch",
        defaultMessage: "Default",
    },
})

const sideBar = defineMessages({
    listTitle: {
        id: "flows.sideBar.listTitle",
        defaultMessage: "Flow nodes",
    },
    configurationTitle: {
        id: "flows.sideBar.configurationTitle",
        defaultMessage: "Configuration",
    },
    explorerTitle: {
        id: "flows.sideBar.explorerTitle",
        defaultMessage: "Run history",
    },
})

const eventTriggerConfiguration = defineMessages({
    eventTitle: {
        id: "flows.eventTriggerConfiguration.eventTitle",
        defaultMessage: "Event Trigger",
    },
    eventSelectLabel: {
        id: "flows.eventTriggerConfiguration.eventSelectLabel",
        defaultMessage: "Event",
    },
    conditionsTitle: {
        id: "flows.eventTriggerConfiguration.conditionsTitle",
        defaultMessage: "Conditions",
    },
    edit: {
        id: "flows.eventTriggerConfiguration.edit",
        defaultMessage: "Configuration",
    },
    enableConditionsLabel: {
        id: "flows.eventTriggerConfiguration.enableConditionsLabel",
        defaultMessage: "Filter events",
    },
    andConditionsTitle: {
        id: "flows.eventTriggerConfiguration.andConditionsTitle",
        defaultMessage: "And conditions",
    },
    andConditionFirstLabel: {
        id: "flows.eventTriggerConfiguration.andConditionFirstLabel",
        defaultMessage: "This condition",
    },
    andConditionOtherLabel: {
        id: "flows.eventTriggerConfiguration.andConditionOtherLabel",
        defaultMessage: "And",
    },
    orConditionFirstLabel: {
        id: "flows.eventTriggerConfiguration.orConditionFirstLabel",
        defaultMessage: "if",
    },
    orConditionOtherLabel: {
        id: "flows.eventTriggerConfiguration.orConditionOtherLabel",
        defaultMessage: "or",
    },
    newConditionLabel: {
        id: "flows.eventTriggerConfiguration.newConditionLabel",
        defaultMessage: "Add condition",
    },
    newConditionGroupLabel: {
        id: "flows.eventTriggerConfiguration.newConditionGroupLabel",
        defaultMessage: "Add new condition",
    },
})

const branchConfiguration = defineMessages({
    newBranchLabel: {
        id: "flows.branchConfiguration.newBranchLabel",
        defaultMessage: "Add new branch",
    },
    branchTitle: {
        id: "flows.branchConfiguration.branchTitle",
        defaultMessage: "Branch {index}",
    },
    newConditionGroupLabel: {
        id: "flows.branchConfiguration.newConditionGroupLabel",
        defaultMessage: "Add new condition",
    },
})

const getTagByGroupConfiguration = defineMessages({
    tagLabel: {
        id: "flows.getTagByGroupConfiguration.tagLabel",
        defaultMessage: "Tag Group",
    },
})

const updateCustomFieldConfiguration = defineMessages({
    customFieldIdLabel: {
        id: "flows.updateCustomFieldConfiguration.customFieldIdLabel",
        defaultMessage: "Custom Field ID",
    },
    customFieldValueLabel: {
        id: "flows.updateCustomFieldConfiguration.customFieldValueLabel",
        defaultMessage: "Custom Field Value",
    },
})

const createCustomFieldConfiguration = defineMessages({
    customFieldNameLabel: {
        id: "flows.createCustomFieldConfiguration.customFieldNameLabel",
        defaultMessage: "Custom Field Name",
    },
    customFieldValueLabel: {
        id: "flows.createCustomFieldConfiguration.customFieldValueLabel",
        defaultMessage: "Custom Field Value",
    },
})

const configurationNode = defineMessages({
    nameLabel: {
        id: "common.name",
        defaultMessage: "Name",
    },
    slugLabel: {
        id: "flows.configurationNode.slugLabel",
        defaultMessage: "ID :",
    },
    saveConfigurationLabel: {
        id: "flows.configurationNode.saveConfigurationLabel",
        defaultMessage: "Save",
    },
    cancelConfigurationLabel: {
        id: "flows.configurationNode.cancelConfigurationLabel",
        defaultMessage: "Cancel",
    },
    deleteNodeLabel: {
        id: "flows.configurationNode.deleteNodeLabel",
        defaultMessage: "Delete",
    },
    advanced: {
        id: "flows.configurationNode.advanced",
        defaultMessage: "Advanced",
    },
})

const printLineConfiguration = defineMessages({
    title: {
        id: "flows.printLineConfiguration.title",
        defaultMessage: "Message to print",
    },
    label: {
        id: "flows.printLineConfiguration.label",
        defaultMessage: "Message",
    },
})

const partnershipFieldToUpdate: Record<PartnershipFieldToUpdate, MessageDescriptor> = defineMessages({
    [PartnershipFieldToUpdate.PREFERRED_STATUS]: {
        id: "flows.partnershipFieldToUpdate.PREFERRED_STATUS",
        defaultMessage: "Preferred status",
    },
    [PartnershipFieldToUpdate.VERIFIED_STATUS]: {
        id: "flows.partnershipFieldToUpdate.VERIFIED_STATUS",
        defaultMessage: "Verified status",
    },
    [PartnershipFieldToUpdate.ALLOW_TO_SEND_PO]: {
        id: "flows.partnershipFieldToUpdate.ALLOW_TO_SEND_PO",
        defaultMessage: "Allow to send PO",
    },
    [PartnershipFieldToUpdate.ALLOW_TO_PAY_INVOICE]: {
        id: "flows.partnershipFieldToUpdate.ALLOW_TO_PAY_INVOICE",
        defaultMessage: "Allow to pay invoice",
    },
    [PartnershipFieldToUpdate.COLLABORATION_STATUS]: {
        id: "flows.partnershipFieldToUpdate.COLLABORATION_STATUS",
        defaultMessage: "Collaboration status",
    },
})

const setPartnershipFieldConfiguration = defineMessages({
    fieldTitle: {
        id: "flows.setPartnershipFieldConfiguration.fieldTitle",
        defaultMessage: "Partnership field to update",
    },
    valueLabel: {
        id: "flows.setPartnershipFieldConfiguration.valueLabel",
        defaultMessage: "Value to set",
    },
    fieldLabel: {
        id: "flows.setPartnershipFieldConfiguration.fieldLabel",
        defaultMessage: "Field to update",
    },
    editPartnershipIdLabel: {
        id: "flows.setPartnershipFieldConfiguration.editPartnershipIdLabel",
        defaultMessage: "Partnership Id",
    },
    verifiedStatus: {
        id: "flows.setPartnershipFieldConfiguration.verifiedStatusLabel",
        defaultMessage: "Mark as verified",
    },
    preferredStatus: {
        id: "flows.setPartnershipFieldConfiguration.preferredStatusLabel",
        defaultMessage: "Mark as preferred",
    },
    allowToSendPO: {
        id: "flows.setPartnershipFieldConfiguration.allowToSendPOLabel",
        defaultMessage: "Enable sending PO",
    },
    allowToPayInvoice: {
        id: "flows.setPartnershipFieldConfiguration.allowToPayInvoiceLabel",
        defaultMessage: "Enable payment of invoices",
    },
})

const checkNodeConfiguration = defineMessages({
    usersTitle: {
        id: "flows.checkNodeConfiguration.usersTitle",
        defaultMessage: "Users to review",
    },
    teamsTitle: {
        id: "flows.checkNodeConfiguration.teamsTitle",
        defaultMessage: "Teams to review",
    },
    objectType: {
        id: "flows.checkNodeConfiguration.objectType",
        defaultMessage: "Object Type",
    },
    objectId: {
        id: "flows.checkNodeConfiguration.objectId",
        defaultMessage: "Object ID",
    },
    user: {
        id: "flows.checkNodeConfiguration.user",
        defaultMessage: "Users",
    },
    team: {
        id: "flows.checkNodeConfiguration.team",
        defaultMessage: "Teams",
    },
    type: {
        id: "flows.checkNodeConfiguration.type",
        defaultMessage: "Type",
    },
    userId: {
        id: "flows.checkNodeConfiguration.userId",
        defaultMessage: "User ID",
    },
    passThreshold: {
        id: "flows.checkNodeConfiguration.passThreshold",
        defaultMessage: "Pass threshold",
    },
    refuseThreshold: {
        id: "flows.checkNodeConfiguration.refuseThreshold",
        defaultMessage: "Refuse threshold",
    },
})

const sendEmailConfiguration = defineMessages({
    title: {
        id: "flows.configuration.sendEmail.title",
        defaultMessage: "Send Email Configuration",
    },
    subjectLabel: {
        id: "flows.configuration.sendEmail.subjecrLabel",
        defaultMessage: "Subject",
    },
    toLabel: {
        id: "flows.configuration.sendEmail.toLabel",
        defaultMessage: "To",
    },
    toHelper: {
        id: "flows.configuration.sendEmail.toHelper",
        defaultMessage: "Separate multiple email addresses with commas",
    },
    bodyLabel: {
        id: "flows.configuration.sendEmail.bodyLabel",
        defaultMessage: "Message",
    },
})

const addToBudgetConfiguration = defineMessages({
    title: {
        id: "flows.configuration.addToBudget.title",
        defaultMessage: "Add to Budget Configuration",
    },
    budgetIdLabel: {
        id: "flows.configuration.addToBudget.budgetIdLabel",
        defaultMessage: "Budget ID",
    },
    transactionIdLabel: {
        id: "flows.configuration.addToBudget.transactionIdLabel",
        defaultMessage: "Transaction ID",
    },
    amountLabel: {
        id: "flows.configuration.addToBudget.amountLabel",
        defaultMessage: "Amount",
    },
    failIfOverbudgetLabel: {
        id: "flows.configuration.addToBudget.failIfOverbudgetLabel",
        defaultMessage: "Fail if over budget",
    },
    currencyLabel: {
        id: "flows.configuration.addToBudget.currencyLabel",
        defaultMessage: "Currency",
    },
})

const sharedBudgetConfiguration = defineMessages({
    selectBudgetsLabel: {
        id: "flows.sharedBudgetConfiguration.selectBudgetsLabel",
        defaultMessage: "Select budgets",
    },
})

const InvoiceToPurchaseOrderMatchNodeConfiguration = defineMessages({
    title: {
        id: "flows.invoiceToPurchaseOrderMatchConfigration.title",
        defaultMessage: "Check if invoice is linked to purchase order",
    },
    invoiceIdLabel: {
        id: "flows.invoiceToPurchaseOrderMatchConfigration.invoiceIdLabel",
        defaultMessage: "Invoice ID",
    },
})

const setInvoiceLifecycleStatusConfiguration = defineMessages({
    title: {
        id: "flows.configuration.setInvoiceLifecycleStatus.title",
        defaultMessage: "Set invoice lifecycle status configuration",
    },
    invoiceIdLabel: {
        id: "flows.configuration.setInvoiceLifecycleStatus.invoiceIdLabel",
        defaultMessage: "Invoice ID",
    },
    statusLabel: {
        id: "flows.configuration.setInvoiceLifecycleStatus.statusLabel",
        defaultMessage: "Status to set",
    },
})

const fitsToBudgetConfiguration = defineMessages({
    title: {
        id: "flows.fitsToBudgetConfiguration.title",
        defaultMessage: "Fits to Budget configuration",
    },
    budgetIdLabel: {
        id: "flows.fitsToBudgetConfiguration.budgetIdLabel",
        defaultMessage: "Budget ID",
    },
    transactionIdLabel: {
        id: "flows.fitsToBudgetConfiguration.transactionIdLabel",
        defaultMessage: "Transaction ID",
    },
    transactionTypeLabel: {
        id: "flows.fitsToBudgetConfiguration.transactionTypeLabel",
        defaultMessage: "Transaction Type",
    },
    amountLabel: {
        id: "flows.fitsToBudgetConfiguration.amountLabel",
        defaultMessage: "Amount",
    },
    currencyLabel: {
        id: "flows.fitsToBudgetConfiguration.currencyLabel",
        defaultMessage: "Currency",
    },
})

const assignTagConfiguration = defineMessages({
    objectIdLabel: {
        id: "flows.assignTagConfiguration.objectIdLabel",
        defaultMessage: "Object ID",
    },
    tagLabel: {
        id: "flows.assignTagConfiguration.tagLabel",
        defaultMessage: "Tag",
    },
})

const updateTripletexLedgerConfiguration = defineMessages({
    title: {
        id: "flows.updateTripletexLedgerConfiguration.title",
        defaultMessage: "Update Tripletex Ledger configuration",
    },
    ledgerIdLabel: {
        id: "flows.updateTripletexLedgerConfiguration.ledgerIdLabel",
        defaultMessage: "Ledger ID",
    },
    ledgerDescriptionLabel: {
        id: "flows.updateTripletexLedgerConfiguration.ledgerDescriptionLabel",
        defaultMessage: "Ledger description",
    },
    amountLabel: {
        id: "flows.updateTripletexLedgerConfiguration.amountLabel",
        defaultMessage: "Amount",
    },
    amountGrossLabel: {
        id: "flows.updateTripletexLedgerConfiguration.amountGrossLabel",
        defaultMessage: "Amount gross",
    },
    currencyLabel: {
        id: "flows.updateTripletexLedgerConfiguration.currencyLabel",
        defaultMessage: "Currency",
    },
    ledgerDateLabel: {
        id: "flows.updateTripletexLedgerConfiguration.ledgerDateLabel",
        defaultMessage: "Ledger date",
    },
    accountToCreditLabel: {
        id: "flows.updateTripletexLedgerConfiguration.accountToCreditLabel",
        defaultMessage: "Account to credit",
    },
    accountToDebitLabel: {
        id: "flows.updateTripletexLedgerConfiguration.accountToDebitLabel",
        defaultMessage: "Account to debit",
    },
})

const taskPriority: Record<TaskPriority, MessageDescriptor> = defineMessages({
    [TaskPriority.LOW]: {
        id: "flows.taskPriority.low",
        defaultMessage: "Low",
    },
    [TaskPriority.MEDIUM]: {
        id: "flows.taskPriority.medium",
        defaultMessage: "Medium",
    },
    [TaskPriority.HIGH]: {
        id: "flows.taskPriority.high",
        defaultMessage: "High",
    },
    [TaskPriority.URGENT]: {
        id: "flows.taskPriority.urgent",
        defaultMessage: "Urgent",
    },
})

const taskStatus: Record<TaskStatus, MessageDescriptor> = defineMessages({
    [TaskStatus.PENDING]: {
        id: "flows.taskStatus.pending",
        defaultMessage: "Pending",
    },
    [TaskStatus.IN_PROGRESS]: {
        id: "flows.taskStatus.inProgress",
        defaultMessage: "In Progress",
    },
    [TaskStatus.COMPLETED]: {
        id: "flows.taskStatus.completed",
        defaultMessage: "Completed",
    },
})
const createTaskConfiguration = defineMessages({
    title: {
        id: "flows.createTaskNode.title",
        defaultMessage: "Task Title",
    },
    description: {
        id: "flows.createTaskNode.description",
        defaultMessage: "Description",
    },
    dueDate: {
        id: "flows.createTaskNode.dueDate",
        defaultMessage: "Due Date",
    },
    priority: {
        id: "flows.createTaskNode.priority",
        defaultMessage: "Priority",
    },
    status: {
        id: "flows.createTaskNode.status",
        defaultMessage: "Status",
    },
    assignee: {
        id: "flows.createTaskNode.assignee",
        defaultMessage: "Assignee",
    },
    parentTaskId: {
        id: "flows.createTaskNode.parentTaskId",
        defaultMessage: "Parent Task ID",
    },
    public: {
        id: "flows.createTaskNode.public",
        defaultMessage: "Public",
    },
    followers: {
        id: "flows.createTaskNode.followers",
        defaultMessage: "Followers",
    },
    parties: {
        id: "flows.createTaskNode.parties",
        defaultMessage: "Parties",
    },
})

const mappingConfiguration = defineMessages({
    valueToMapLabel: {
        id: "flows.mappingConfiguration.valueToMapLabel",
        defaultMessage: "Value to Map",
    },
    defaultValuesTitle: {
        id: "flows.mappingConfiguration.defaultValuesTitle",
        defaultMessage: "Default Values",
    },
    mappingTableTitle: {
        id: "flows.mappingConfiguration.mappingTableTitle",
        defaultMessage: "Mapping Table",
    },
    labelFieldLabel: {
        id: "flows.mappingConfiguration.labelFieldLabel",
        defaultMessage: "Label",
    },
    valueFieldLabel: {
        id: "flows.mappingConfiguration.valueFieldLabel",
        defaultMessage: "Value",
    },
    addDefaultValueButton: {
        id: "flows.mappingConfiguration.addDefaultValueButton",
        defaultMessage: "Add Default Value",
    },
    newMappingKeyLabel: {
        id: "flows.mappingConfiguration.newMappingKeyLabel",
        defaultMessage: "New Mapping Key",
    },
    addElementButton: {
        id: "flows.mappingConfiguration.addElementButton",
        defaultMessage: "Add Element",
    },
    duplicateKeyError: {
        id: "flows.mappingConfiguration.duplicateKeyError",
        defaultMessage: "This key already exists",
    },
})

const suggestions = defineMessages({
    equals: {
        id: "flows.suggestions.equals",
        defaultMessage: "equals",
    },
    true: {
        id: "flows.suggestions.true",
        defaultMessage: "true",
    },
    false: {
        id: "flows.suggestions.false",
        defaultMessage: "false",
    },
    partnershipTypeSupplier: {
        id: "flows.suggestions.partnershipTypeSupplier",
        defaultMessage: "Supplier",
    },
    partnershipTypeBuyer: {
        id: "flows.suggestions.partnershipTypeBuyer",
        defaultMessage: "Buyer",
    },
    eventTriggerNewPartnership: {
        id: "flows.suggestions.eventTriggerNewPartnership",
        defaultMessage: "Partnership",
    },
})

const suggestionTypes = defineMessages({
    operator: {
        id: "flows.suggestions.operator",
        defaultMessage: "Operators",
    },
    property: {
        id: "flows.suggestions.property",
        defaultMessage: "Properties",
    },
    value: {
        id: "flows.suggestions.value",
        defaultMessage: "Values",
    },
    trigger: {
        id: "flows.suggestions.trigger",
        defaultMessage: "Triggers",
    },
})

const conditionBuilder = defineMessages({
    isTaggedWithLabel: {
        id: "flows.conditionBuilder.isTaggedWithLabel",
        defaultMessage: "Is tagged with",
    },
    operator: {
        id: "flows.conditionBuilder.operator",
        defaultMessage: "=",
    },
    leftHand: {
        id: "flows.conditionBuilder.leftHand",
        defaultMessage: "Select a property",
    },
    rightHand: {
        id: "flows.conditionBuilder.rightHand",
        defaultMessage: "Select a value",
    },
    saveLabel: {
        id: "flows.conditionBuilder.saveLabel",
        defaultMessage: "Save",
    },
    cancelLabel: {
        id: "flows.conditionBuilder.cancelLabel",
        defaultMessage: "Cancel",
    },
    label: {
        id: "flows.conditionBuilder.label",
        defaultMessage: "Condition",
    },
})

const error = defineMessages({
    noEventTrigger: {
        id: "flows.error.noEventTrigger",
        defaultMessage: "Flow must have one event trigger node",
    },
    multipleEventTriggers: {
        id: "flows.error.multipleEventTriggers",
        defaultMessage: "Flow must have only one event trigger node",
    },
    triggerNodeNotConnected: {
        id: "flows.error.triggerNodeNotConnected",
        defaultMessage: "Event trigger node is not connected to any other node",
    },
    cycleDetected: {
        id: "flows.error.cycleDetected",
        defaultMessage: "Nodes cannot have the same next node",
    },
    loadingFlow: {
        id: "flows.error.loadingFlow",
        defaultMessage: "Unable to load this flow",
    },
    updatingFlow: {
        id: "flows.error.updatingFlow",
        defaultMessage: "Unable to update this flow",
    },
    archiveFlow: {
        id: "flows.error.archiveFlow",
        defaultMessage: "Unable to archive this flow",
    },
    copyFlow: {
        id: "flows.error.copyFlow",
        defaultMessage: "Unable to copy this flow",
    },
    createFlow: {
        id: "flows.error.createFlow",
        defaultMessage: "Unable to create this flow",
    },
    fetchingFlows: {
        id: "flows.error.fetchingFlows",
        defaultMessage: "Unable to fetch flows",
    },
    permission: {
        id: "flows.error.permission",
        defaultMessage: "Permission error",
    },
    loadingRunInformation: {
        id: "flows.error.loadingRunInformation",
        defaultMessage: "Unable to load this run",
    },
    loadingRuns: {
        id: "flows.error.loadingRuns",
        defaultMessage: "Unable to load runs",
    },
})

const success = defineMessages({
    flowCreated: {
        id: "flows.success.flowCreated",
        defaultMessage: "Flow created",
    },
    flowArchived: {
        id: "flows.success.flowArchived",
        defaultMessage: "Flow archived",
    },
    flowUpdated: {
        id: "flows.success.flowUpdated",
        defaultMessage: "Flow updated",
    },
    copyName: {
        id: "flows.success.copyName",
        defaultMessage: "{name} copy",
    },
})

const advancedFields: Record<AllAdvancedFieldsUnion, MessageDescriptor> = defineMessages({
    [AllAdvancedFieldsUnion.OBJECT_TYPE]: {
        id: "flows.advancedFields.objectType",
        defaultMessage: "Object type",
    },
    [AllAdvancedFieldsUnion.OBJECT_ID]: {
        id: "flows.advancedFields.objectId",
        defaultMessage: "Object ID",
    },
    [AllAdvancedFieldsUnion.TRANSACTION_TYPE]: {
        id: "flows.advancedFields.transactionType",
        defaultMessage: "Transaction type",
    },
    [AllAdvancedFieldsUnion.VALUE_TO_SET]: {
        id: "flows.advancedFields.valueToSet",
        defaultMessage: "Value to set",
    },
    [AllAdvancedFieldsUnion.PARTNERSHIP_ID]: {
        id: "flows.advancedFields.partnershipId",
        defaultMessage: "Partnership ID",
    },
    [AllAdvancedFieldsUnion.INVOICE_ID]: {
        id: "flows.advancedFields.invoiceId",
        defaultMessage: "Invoice ID",
    },
    [AllAdvancedFieldsUnion.STATUS_TO_SET]: {
        id: "flows.advancedFields.statusToSet",
        defaultMessage: "Status to set",
    },
    [AllAdvancedFieldsUnion.FIELD_TO_UPDATE]: {
        id: "flows.advancedFields.fieldToUpdate",
        defaultMessage: "Field to update",
    },
    [AllAdvancedFieldsUnion.CURRENCY]: {
        id: "flows.advancedFields.currency",
        defaultMessage: "Currency",
    },
    [AllAdvancedFieldsUnion.AMOUNT]: {
        id: "flows.advancedFields.amount",
        defaultMessage: "Amount",
    },
    [AllAdvancedFieldsUnion.AMOUNT_GROSS]: {
        id: "flows.advancedFields.amountGross",
        defaultMessage: "Amount gross",
    },
    [AllAdvancedFieldsUnion.TRANSACTION_ID]: {
        id: "flows.advancedFields.transactionId",
        defaultMessage: "Transaction ID",
    },
    [AllAdvancedFieldsUnion.LEDGER_ID]: {
        id: "flows.advancedFields.ledgerId",
        defaultMessage: "Ledger ID",
    },
    [AllAdvancedFieldsUnion.LEDGER_DATE]: {
        id: "flows.advancedFields.ledgerDate",
        defaultMessage: "Ledger date",
    },
    [AllAdvancedFieldsUnion.PURCHASE_ORDER_ID]: {
        id: "flows.advancedFields.purchaseOrderId",
        defaultMessage: "Purchase order ID",
    },
    [AllAdvancedFieldsUnion.PURCHASE_ORDER_LINE_ID]: {
        id: "flows.advancedFields.purchaseOrderLineId",
        defaultMessage: "Purchase order line ID",
    },
    [AllAdvancedFieldsUnion.PURCHASE_REQUEST_ID]: {
        id: "flows.advancedFields.purchaseRequestId",
        defaultMessage: "Purchase request ID",
    },
    [AllAdvancedFieldsUnion.PURCHASE_REQUEST_LINE_ID]: {
        id: "flows.advancedFields.purchaseRequestLineId",
        defaultMessage: "Purchase request line ID",
    },
    [AllAdvancedFieldsUnion.PAYMENT_METHOD_DETAILS_ID]: {
        id: "flows.advancedFields.paymentMethodDetailsId",
        defaultMessage: "Payment method details ID",
    },
    [AllAdvancedFieldsUnion.FORM_ID]: {
        id: "flows.advancedFields.formId",
        defaultMessage: "Form ID",
    },
    [AllAdvancedFieldsUnion.RESPONDENT_ORGANIZATION_ID]: {
        id: "flows.advancedFields.respondentOrganizationId",
        defaultMessage: "Respondent organization ID",
    },
    [AllAdvancedFieldsUnion.PARTNER_ID]: {
        id: "flows.advancedFields.partnerId",
        defaultMessage: "Partner ID",
    },
})

export const messages = {
    advancedFields,
    nodeTitle,
    nodeDescription,
    conditionBuilder,
    runsTable,
    runInformation,
    runStatus,
    configurationNode,
    eventTriggerConfiguration,
    printLineConfiguration,
    setPartnershipFieldConfiguration,
    checkNodeConfiguration,
    sendEmailConfiguration,
    addToBudgetConfiguration,
    sharedBudgetConfiguration,
    InvoiceToPurchaseOrderMatchNodeConfiguration,
    setInvoiceLifecycleStatusConfiguration,
    fitsToBudgetConfiguration,
    assignTagConfiguration,
    updateTripletexLedgerConfiguration,
    createTaskConfiguration,
    taskPriority,
    taskStatus,
    branchConfiguration,
    getTagByGroupConfiguration,
    updateCustomFieldConfiguration,
    createCustomFieldConfiguration,
    objectType,
    event,
    transactionType,
    partnershipFieldToUpdate,
    page,
    common,
    filter,
    modalCreate,
    editor,
    sideBar,
    eventTriggerNode,
    sendEmailNode,
    addToBudgetNode,
    ifNode,
    setPartnershipFieldNode,
    checkNode,
    node,
    suggestions,
    suggestionTypes,
    invoiceToPurchaseOrderMatchNode,
    fitsToBudgetNode,
    branchNode,
    success,
    error,
    mappingConfiguration,
}
