import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"

import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { BudgetDataI, BudgetId, BudgetTransactionI } from "~/domains/transactions/budget/types"
import { RootState } from "~/store"

import budgetState from "./budgetState"

const initialState = budgetState

const budgetSlice = createSlice({
    name: "budget",
    initialState,
    reducers: {
        addBudget(state, action: PayloadAction<BudgetDataI>) {
            state.budgetsData = [action.payload, ...(state.budgetsData ?? [])]
        },
        setBudgetFilter(state, action: PayloadAction<string>) {
            state.budgetsFilter = action.payload
        },
        fetchBudgetsData: (state) => {
            state.loading = true
            state.error = null
        },
        fetchBudgetsDataSuccess: (state, action: PayloadAction<BudgetDataI[]>) => {
            state.loading = false
            state.budgetsData = action.payload
        },
        fetchBudgetsDataFailed: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.error = action.payload
        },
        updateBudget(state) {
            state.loading = true
            state.error = null
        },
        updateBudgetSuccess(state, action: PayloadAction<BudgetDataI>) {
            state.loading = false
            if (state.budgetsData) {
                const index = state.budgetsData.findIndex((t) => t.id === action.payload.id)
                if (index !== -1) {
                    state.budgetsData[index] = action.payload
                }
            }
            if (state.budgetDetails) {
                state.budgetDetails = action.payload
            }
        },
        updateBudgetFailed(state, action: PayloadAction<string>) {
            state.loading = false
            state.error = action.payload
        },
        deleteBudget: (state) => {
            state.loading = true
            state.error = null
        },
        deleteBudgetSuccess: (state, action: PayloadAction<BudgetId>) => {
            state.loading = false
            state.budgetsData = state.budgetsData.filter((budget) => budget.id !== action.payload)
        },
        deleteBudgetFailed: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.error = action.payload
        },
        fetchBudgetDetails: (state) => {
            state.loading = true
            state.error = null
        },
        fetchBudgetDetailsSuccess: (state, action: PayloadAction<BudgetDataI>) => {
            state.loading = false
            state.budgetDetails = action.payload
        },
        fetchBudgetDetailsFailed: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.error = action.payload
        },
        addBudgetTransactions(state, action: PayloadAction<(BudgetTransactionI | null)[]>) {
            if (state.budgetDetails && state.budgetDetails.transactions)
                state.budgetDetails.transactions = [
                    ...(action.payload as BudgetTransactionI[]),
                    ...(state.budgetDetails.transactions ?? []),
                ]
        },
        deleteBudgetTransaction: (state) => {
            state.loading = true
            state.error = null
        },
        deleteBudgetTransactionSuccess: (state, action: PayloadAction<BudgetId>) => {
            state.loading = false
            if (state.budgetDetails && state.budgetDetails.transactions)
                state.budgetDetails.transactions = state.budgetDetails.transactions.filter(
                    (t) => t.id !== action.payload
                )
        },
        deleteBudgetTransactionFailed: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.error = action.payload
        },
        setBudgetTransactionsFilter(state, action: PayloadAction<string>) {
            state.transactionsFilter = action.payload
        },
        setCurrentTab(state, action: PayloadAction<string>) {
            state.currentTab = action.payload
        },
        setTagsLoading(state, action: PayloadAction<boolean>) {
            state.tagsLoading = action.payload
        },
        setTags(state, action: PayloadAction<TagObjectRecordI | undefined>) {
            state.tags = action.payload
        },
    },
})

export const budgetActions = budgetSlice.actions

export const selectBudgetsDataField = (state: RootState) => state.budget.budgetsData
export const selectBudgetsFilter = (state: RootState) => state.budget.budgetsFilter
export const selectBudgetDetails = (state: RootState) => state.budget.budgetDetails
export const selectBudgetLoading = (state: RootState) => state.budget.loading
export const selectBudgetError = (state: RootState) => state.budget.error
export const selectTransactionsFilter = (state: RootState) => state.budget.transactionsFilter
export const selectCurrentTab = (state: RootState) => state.budget.currentTab

export const selectBudgetsData = createSelector(
    [selectBudgetsDataField, selectBudgetsFilter, selectBudgetLoading, selectBudgetError],
    (budgetsData, budgetsFilter, loading, error) => ({
        budgetsData,
        budgetsFilter,
        loading,
        error,
    })
)

export const selectBudgetDetailsData = createSelector(
    [selectBudgetDetails, selectBudgetLoading, selectBudgetError],
    (budgetDetails, loading, error) => ({
        budgetDetails,
        loading,
        error,
    })
)

export const selectBudgetsTags = (state: RootState) => state.budget.tags
export const selectBudgetsTagsLoading = (state: RootState) => state.budget.tagsLoading

const budgetReducer = budgetSlice.reducer
export default budgetReducer
