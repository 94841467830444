import { Divider, Grid, Stack, Typography, styled } from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { FC, useEffect } from "react"
import { FormattedDate, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { RunStepper } from "~/domains/orchestration/flows/components/RunStepper"
import { RUN_STATUS_COLORS } from "~/domains/orchestration/flows/constants"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/"
import { useRunQueryWithFlowData } from "~/domains/orchestration/flows/hooks/useRunQueryWithFlowData"
import { messages } from "~/domains/orchestration/flows/locale"
import { RunId, RunStatus } from "~/domains/orchestration/flows/types"

interface Props {
    runId: RunId
}

const SelectableIDs = styled(Stack)({
    userSelect: "text",
})

export const RunExplorerItem: FC<Props> = ({ runId }) => {
    const { formatMessage } = useIntl()

    const organizationId = useOrganizationId()
    const { data, isLoading, isError, error, refetch } = useRunQueryWithFlowData(runId)

    useEffect(() => {
        if (organizationId) {
            refetch()
        }
    }, [organizationId, refetch])

    if (isLoading) {
        return <Loader fullscreen />
    }
    if (isError) {
        toast.error(formatMessage(messages.error.loadingRuns))
        Sentry.captureException(error)
        return null
    }
    if (!data) {
        return null
    }

    const color = RUN_STATUS_COLORS[data.status]

    return (
        <Stack gap={2}>
            <Divider />
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">
                    {formatMessage(messages.runInformation.flowVersion)}: {data.flowVersion}
                </Typography>

                <Typography variant="body2" color={color}>
                    {formatMessage(messages.runInformation.status)}: {data.status}
                </Typography>
            </Stack>

            <Divider />
            <Grid container>
                <Grid item xs={6}>
                    <RunStepper key={data.id} data={data} />
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Stack>
                        <Typography variant="caption" color={color}>
                            {formatMessage(messages.runInformation.startedAt)}:{" "}
                            <FormattedDate value={data.startedAt} dateStyle="short" timeStyle="short" />
                        </Typography>
                        {data.finishedAt ? (
                            <Typography variant="caption" color={color}>
                                {formatMessage(messages.runInformation.finishedAt)}:
                                <FormattedDate value={data.finishedAt} dateStyle="short" timeStyle="short" />
                            </Typography>
                        ) : null}
                    </Stack>
                </Grid>
            </Grid>

            {data.error && (
                <Typography variant="caption" color={RUN_STATUS_COLORS[RunStatus.FAILED]}>
                    {formatMessage(messages.runInformation.error)}: {data.error}
                </Typography>
            )}

            <Divider />
            <SelectableIDs>
                <Typography variant="caption" color="text.secondary">
                    {formatMessage(messages.runInformation.id)}: {data.id}
                </Typography>

                <Typography variant="caption" color="text.secondary">
                    {formatMessage(messages.runInformation.flowId)}: {data.flowId}
                </Typography>
            </SelectableIDs>
        </Stack>
    )
}
