import { autocompleteClasses, styled } from "@mui/material"
import Popper from "@mui/material/Popper"

export const ibanStyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: "4px 8px",
        },
        ".autocomplete-iban": {
            padding: "4px 8px",
            margin: "4px 0px",
            cursor: "pointer",
        },
        ".autocomplete-iban-bankName": {
            padding: "0 0 0 4px",
            color: "var(--color-grey-light)",
        },
        ".autocomplete-iban-number": {
            color: "var(--color-grey)",
        },
    },
})

export const contactStyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
        },
        ".autocomplete-contact": {
            padding: "4px 8px",
            cursor: "pointer",
        },
        ".autocomplete-contact-group": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "4px 8px",
        },
        ".autocomplete-contact-label": {
            color: "var(--color-secondary)",
        },
        ".autocomplete-contact-email": {
            color: "var(--color-grey-light)",
        },
        ".autocomplete-contact-actions": {
            backgroundColor: "var(--primary-color-light)",
        },
        ".autocomplete-contact-actions-label": {
            color: "var(--primary-color)",
            fontSize: "var(--font-size-sm)",
            padding: "0 8px",
        },
    },
})
