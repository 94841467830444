import {
    OrganisationEstablishment,
    OrganisationEstablishmentResponse,
} from "~/domains/identity/organization/types/OrganisationV2Types"

export const organisationEstablishmentFromApiAdapter = (
    organisationEstablishment: OrganisationEstablishmentResponse
) => {
    return {
        siret: organisationEstablishment.siret,
        isHeadOffice: organisationEstablishment.isHeadOffice,
        mainEstablishment: mainEstablishmentFromApiAdapter(organisationEstablishment.mainEstablishment),
    }
}

export const mainEstablishmentFromApiAdapter = (mainEstablishment: OrganisationEstablishment) => {
    return {
        siret: mainEstablishment.siret,
        isHead: mainEstablishment.isHead,
        addressLine1: mainEstablishment.addressLine1,
        city: mainEstablishment.city,
        country: mainEstablishment.country,
    }
}
