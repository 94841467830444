import { Shuffle } from "react-feather"

import { MappingConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { MappingNode } from "~/domains/orchestration/flows/components/nodes"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { MappingNode as MappingNodeType, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const mappingNode: Omit<MappingNodeType, keyof Node> = {
    type: NodeType.MAPPING_NODE,
    nextNode: null,
    valueToMap: "",
    mappingTable: {},
    defaultValues: [],
}

const advancedFields: (keyof typeof mappingNode)[] = []

const mappingConfig: Configuration<NodeType.MAPPING_NODE> = {
    type: NodeType.MAPPING_NODE,
    baseSlug: "mapping",
    Icon: Shuffle,
    Configuration: MappingConfiguration,
    advancedFields,
    Node: MappingNode,
    factory: (props: FactoryProps): MappingNodeType => ({
        ...mappingNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { mappingConfig, advancedFields as mappingAdvancedFields }
