import { Tooltip } from "@mui/material"
import { Save } from "react-feather"
import { useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { useUpdatePurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

interface SavePOProps {
    PO: PurchaseOrders
    display?: "button" | "menu-item"
}

export const SavePO = ({ PO }: SavePOProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const organization = useAppSelector(selectCurrentOrganization)
    const { updatePO } = useUpdatePurchaseOrder(PO.id, organization?.id)

    const handleSave = async () => {
        await updatePO(PO)
        navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: PO.id }))
    }

    return (
        <Tooltip title={formatMessage(commonMessages.save)}>
            <Button type="primary-light" onClick={handleSave}>
                <Save size={18} />
                <SafeFormattedMessage {...commonMessages.save} />
            </Button>
        </Tooltip>
    )
}
