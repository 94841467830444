import dayjs from "dayjs"

import {
    InvoiceLineItemPayload,
    InvoiceV1Payload,
    PartyPayload,
} from "~/domains/transactions/invoices-v1/types/InvoicePayload"

export const invoiceToApiAdapter = (invoice: Partial<InvoiceV1Payload>): Partial<InvoiceV1Payload> => {
    const apiInvoice = {
        number: invoice.reference,
        issuedAt: dayjs(invoice.issueDate).format("YYYY-MM-DD"),
        dueDate: invoice.dueDate ? dayjs(invoice.dueDate || invoice.dueDate).format("YYYY-MM-DD") : undefined,
        payerId: invoice.payerId || invoice.buyer?.organizationId,
        sellerId: invoice.sellerId || invoice.supplier?.organizationId,
        parties: partiesToApiAdapter(invoice.parties),
        lineItems: invoice.lineItems?.map(lineItemToApiAdapter) ?? undefined,
        totalPriceWithoutTax: invoice.totalPriceWithoutTax,
        tax: invoice.tax,
        totalAmountDue: invoice.totalAmountDue,
        discountRate: invoice.discountRate,
        flatDiscount: invoice.flatDiscount || {},
        paymentTerms: invoice.paymentTerms,
        paymentInstructions: invoice.paymentInstructions,
        notes: invoice.description,
    }

    return JSON.parse(JSON.stringify(apiInvoice))
}

export const lineItemToApiAdapter = (lineItem: Partial<InvoiceLineItemPayload>) => {
    return {
        ...lineItem,
        unitPrice: lineItem.unitPrice?.toString(),
    }
}

export const partyToApiAdapter = (party: Partial<PartyPayload>) => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { template, ...partyPayload } = party
    return partyPayload
}

export const partiesToApiAdapter = (parties?: Record<string, PartyPayload>) => {
    return !parties
        ? undefined
        : Object.entries(parties).reduce(
              (acc, [key, value]) => {
                  acc[key] = partyToApiAdapter(value)
                  return acc
              },
              {} as Record<string, PartyPayload>
          )
}
