import { useEffect } from "react"

import { useCommunicationApi } from "~/domains/communication/chat"
import { communicationActions } from "~/domains/communication/chat/store/CommunicationSlice"
import { selectMessageTemplates } from "~/domains/communication/chat/store/selectors"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { pipeResult } from "~/types/Result"

export const useMessageTemplates = (organizationId: OrganizationId | undefined) => {
    const communicationApi = useCommunicationApi()
    const templatesByOrganization = useAppSelector(selectMessageTemplates)
    const templates = (organizationId && templatesByOrganization[organizationId]) || []
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!organizationId) return
        communicationApi.getTemplates(organizationId).then((result) => {
            pipeResult(result, (templates) => {
                dispatch(communicationActions.setMessageTemplates({ organizationId, templates }))
            })
        })
    }, [organizationId, communicationApi, dispatch])

    return templates
}
