import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useMemo, useState } from "react"
import { DollarSign, Download, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { ActionsMenu, ConfirmModal, Loader, SafeFormattedMessage } from "~/components"
import {
    useExecuteAllPaymentsMutation,
    useLazyDownloadPaymentFileQuery,
    useUpdateBatchInstanceMutation,
} from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { PaymentBatchInstance, PaymentBatchInstanceStatus } from "~/domains/payment/payment-batches/types"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    executeAllPayments: {
        id: "payment.batches.executeAllPayments",
        defaultMessage: "Execute all payments",
    },
    downloadPaymentFile: {
        id: "payment.batches.downloadPaymentFile",
        defaultMessage: "Download payment file",
    },
    cancelAllPayments: {
        id: "payment.batches.cancelAllPayments",
        defaultMessage: "Cancel all payments",
    },
    cancelAllPaymentsMessage: {
        id: "payment.batches.cancelAllPaymentsMessage",
        defaultMessage: "Are you sure you want to cancel all payments?",
    },
    cancelAllPaymentsSuccessMessage: {
        id: "payment.batches.cancelAllPaymentsSuccessMessage",
        defaultMessage: "All payments have been successfully cancelled",
    },
    executionSuccessMessage: {
        id: "payment.batches.executionSuccessMessage",
        defaultMessage: "All payments have been successfully executed",
    },
})

interface BatchInstanceLineActionsProps {
    row: PaymentBatchInstance
}

export const BatchInstanceLineActions = ({ row }: BatchInstanceLineActionsProps) => {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState(false)

    const currentUserId = useAppSelector(selectUserId)

    const [executePayments, { isLoading: isExecuting }] = useExecuteAllPaymentsMutation()
    const [downloadPaymentFile, { isLoading: isDownloading }] = useLazyDownloadPaymentFileQuery()
    const [updateBatchInstanceStatus, { isLoading: isUpdating }] = useUpdateBatchInstanceMutation()
    const isLoading = isExecuting || isDownloading

    const handleExecuteAllPayments = async () => {
        try {
            await executePayments(row.id).unwrap()
            toast.success(formatMessage(messages.executionSuccessMessage))
        } catch (error) {
            toast.error((error as Error).message ?? formatMessage(commonMessages.error))
        }
    }

    const handleDownloadPaymentFile = async () => {
        try {
            await downloadPaymentFile({
                id: row.id,
            }).unwrap()
        } catch (error) {
            toast.error(((error as FetchBaseQueryError).data as Error)?.message ?? formatMessage(commonMessages.error))
        }
    }

    const handleOpenCancelAllPayments = () => {
        setOpen(true)
    }

    const handleCloseCancelAllPayments = () => {
        setOpen(false)
    }

    const handleCancelAllPayments = async () => {
        const updatedBatchInstance = await updateBatchInstanceStatus({
            ...row,
            status: PaymentBatchInstanceStatus.CANCELLED,
            responsibleId: currentUserId,
        }).unwrap()

        if (updatedBatchInstance?.id) {
            toast.success(formatMessage(messages.cancelAllPaymentsSuccessMessage))
            setOpen(false)
        }
    }

    const actions = useMemo(
        () => [
            ...(row.status === PaymentBatchInstanceStatus.ACTIVE
                ? [
                      {
                          label: <SafeFormattedMessage {...messages.executeAllPayments} />,
                          icon: <DollarSign size={14} />,
                          action: handleExecuteAllPayments,
                      },
                  ]
                : []),
            ...(row.status === PaymentBatchInstanceStatus.ACTIVE
                ? [
                      {
                          label: <SafeFormattedMessage {...messages.cancelAllPayments} />,
                          icon: <X size={14} />,
                          action: handleOpenCancelAllPayments,
                      },
                  ]
                : []),
            ...(row.status !== PaymentBatchInstanceStatus.CANCELLED
                ? [
                      {
                          label: <SafeFormattedMessage {...messages.downloadPaymentFile} />,
                          icon: <Download size={14} />,
                          action: handleDownloadPaymentFile,
                      },
                  ]
                : []),
        ],
        [row]
    )

    return (
        <>
            <ActionsMenu disabled={isLoading} actions={actions} />
            <ConfirmModal
                open={open}
                title={<SafeFormattedMessage {...messages.cancelAllPaymentsMessage} />}
                close={handleCloseCancelAllPayments}
                onConfirm={handleCancelAllPayments}
                {...(isUpdating ? { confirmButtonText: <Loader small /> } : {})}
            />
        </>
    )
}
