import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import {
    useCreatePaymentBatchMutation,
    useDeletePaymentBatchMutation,
    useGetPaymentBatchesByOrganizationQuery,
    useUpdatePaymentBatchMutation,
} from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { CreatePaymentBatch } from "~/domains/payment/payment-batches/types"

interface PaymentBatchesManagementI {
    organizationId: string | undefined
}

interface PaymentBatchesManagementConfig {
    skipList?: boolean | undefined
}

export const usePaymentBatchesManagement = (
    { organizationId }: PaymentBatchesManagementI,
    config?: PaymentBatchesManagementConfig
) => {
    const { formatMessage } = useIntl()
    const {
        data,
        isLoading: isLoadingBatches,
        isFetching: isFetchingBatches,
    } = useGetPaymentBatchesByOrganizationQuery({ organizationId }, { skip: config?.skipList || !organizationId })
    const batches = data?.items || []

    const [createBatch, { isLoading: isCreatingBatch }] = useCreatePaymentBatchMutation()
    const [deleteBatch, { isLoading: isDeletingBatch }] = useDeletePaymentBatchMutation()
    const [updateBatch, { isLoading: isUpdatingBatch }] = useUpdatePaymentBatchMutation()

    const deleteBatchById = async (id: string): Promise<boolean> => {
        try {
            await deleteBatch(id).unwrap()
            toast.success(
                formatMessage(commonMessages.successDeleteMessage, { instance: formatMessage(commonMessages.batch) })
            )
            return true
        } catch (error) {
            const errorMessage =
                (error as any)?.data?.message && typeof (error as any).data.message === "string"
                    ? (error as any).data.message
                    : formatMessage(commonMessages.errorDeleteMessage, {
                          instance: formatMessage(commonMessages.batch)?.toLocaleLowerCase(),
                      })

            toast.error(errorMessage)
            return false
        }
    }

    const createPaymentBatch = async (batch: CreatePaymentBatch): Promise<boolean> => {
        try {
            const newBatch = await createBatch(batch).unwrap()

            if (!newBatch?.id) {
                return false
            }

            toast.success(
                formatMessage(commonMessages.successSaveMessage, { instance: formatMessage(commonMessages.batch) })
            )
            return true
        } catch (error) {
            toast.error(
                formatMessage(commonMessages.errorSaveMessage, {
                    instance: formatMessage(commonMessages.batch)?.toLocaleLowerCase(),
                })
            )
            return false
        }
    }

    const updatePaymentBatch = async (batch: CreatePaymentBatch): Promise<boolean> => {
        try {
            const newBatch = await updateBatch(batch).unwrap()

            if (!newBatch?.id) {
                return false
            }

            toast.success(
                formatMessage(commonMessages.successSaveMessage, { instance: formatMessage(commonMessages.batch) })
            )
            return true
        } catch (error) {
            toast.error(
                formatMessage(commonMessages.errorSaveMessage, {
                    instance: formatMessage(commonMessages.batch)?.toLocaleLowerCase(),
                })
            )
            return false
        }
    }

    return {
        batches,
        isLoading: isLoadingBatches,
        isFetching: isFetchingBatches || isDeletingBatch || isCreatingBatch || isUpdatingBatch,
        deleteBatchById,
        createPaymentBatch,
        updatePaymentBatch,
    }
}
