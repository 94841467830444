/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable complexity */

/* eslint-disable no-nested-ternary */
import { Grid, Tooltip } from "@mui/material"
import dayjs from "dayjs"
import { useCallback, useEffect, useState } from "react"
import { CheckCircle, Info, Plus, RotateCw } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Button, ProgressBar as CommonProgressBar, ItemLabel, SafeFormattedMessage } from "~/components"
import { DateComponent } from "~/components/Date"
import { SpinningLoader } from "~/components/SpinningLoader/SpinningLoader"
import { StatusIcon } from "~/components/StatusIcon/StatusIcon"
import { DocumentDataI } from "~/components/UploadDocument/Documents"
import { useFetchObjectDocuments } from "~/components/UploadDocument/hooks"
import { PennylaneSyncOutcome } from "~/domains/_shared/ocr/types/pennylaneSynchronizationStatus"
import { ProgressBar } from "~/domains/identity/account/components/ProgressBar/ProgressBar"
import { understandingApi } from "~/domains/identity/documents/api/understandingApi"
import { DocumentType } from "~/domains/identity/documents/types"
import { ContractCheckI, createEmptyContractCheck } from "~/domains/identity/documents/types/UnderstandingTypes"
import { selectInvoiceRelationsState } from "~/domains/transactions/_shared/document-relations/store/documentRelationsSlice"
import { useLineItemsByStatusComparedToBudget } from "~/domains/transactions/invoices-v1/hooks/useLineItemsByStatusComparedToBudget"
import { useInvoicePennylaneSynchronizationStatus } from "~/domains/transactions/invoices/hooks/useInvoicePennylaneSynchronizationStatus"
import { usePushInvoicePennylaneSynchronization } from "~/domains/transactions/invoices/hooks/usePushInvoicePennylaneSynchronization"
import { selectUserId } from "~/store/account/accountSlice"
import { useGetReceivedDocuments } from "~/store/account/hooks"
import { getIsConnected } from "~/store/auth/authSlice"
import { useAppSelector } from "~/store/hooks"
import { getInvoiceProgress } from "~/store/organization/utils"
import { DocumentI, InvoiceI, InvoiceStatus } from "~/types"

const messages = defineMessages({
    title: { id: "buyer.invoice.page.check.title", defaultMessage: "Auto-check" },
    description: {
        id: "buyer.invoice.page.check.description",
        defaultMessage: "Auto check to pre-validate the informations",
    },
    formatInvoice: { id: "buyer.invoice.page.check.formatInvoice", defaultMessage: "Format of the invoice" },
    formatInvoiceTooltip: {
        id: "buyer.invoice.page.check.formatInvoiceTooltip",
        defaultMessage: "All prerequisites to validate this invoice are fulfilled",
    },
    POPresent: {
        id: "buyer.invoice.page.check.POPresent",
        defaultMessage:
            "{count, plural, =0 {No Purchase order linked} one {1 Purchase order linked} other {{count} Purchase orders linked}}",
    },
    POPresentTooltip: {
        id: "buyer.invoice.page.check.POPresentTooltip",
        defaultMessage:
            "{count, plural, =0 {No Purchase Order has been linked to this invoice} one {1 Purchase Order has been linked} other {{count} Purchase Orders have been linked}}",
    },
    invoiceSigned: { id: "buyer.invoice.page.check.invoiceSigned", defaultMessage: "Invoice digitally signed" },
    invoiceSignedTooltip: {
        id: "buyer.invoice.page.check.invoiceSignedTooltip",
        defaultMessage: "The invoice has been signed by a trusted third party",
    },
    invoiceNotYetSignedTooltip: {
        id: "buyer.invoice.page.check.invoiceNotYetSignedTooltip",
        defaultMessage: "The invoice has not been signed yet by a trusted third party",
    },
    invoiceHasNoBudget: {
        id: "buyer.invoice.page.check.invoiceHasNoBudget",
        defaultMessage: "The invoice has no budget",
    },
    invoiceOverBudget: {
        id: "buyer.invoice.page.check.invoiceOverBudget",
        defaultMessage: "The invoice is over budget",
    },
    invoiceInBudget: {
        id: "buyer.invoice.page.check.invoiceInBudget",
        defaultMessage: "The invoice is in budget",
    },
    conditions: { id: "buyer.invoice.page.check.conditions", defaultMessage: "Payment condition matching contract" },
    matching: { id: "buyer.invoice.page.check.matching", defaultMessage: "Matching previous invoices" },
    matchingTooltip: {
        id: "buyer.invoice.page.check.matchingTooltip",
        defaultMessage: "List of invoices issued by your supplier and already paid",
    },
    showMore: { id: "buyer.invoice.page.check.related.invoices.showMore", defaultMessage: "Show more..." },
    pennylaneSync: {
        id: "buyer.invoice.page.check.related.invoices.pennylaneSync",
        defaultMessage: "Pennylane sync {outcome}",
    },
    [PennylaneSyncOutcome.SUCCESS]: {
        id: "buyer.invoice.page.check.related.invoices.pennylaneSync.outcome.succes",
        defaultMessage: "success",
    },
    [PennylaneSyncOutcome.FAILURE]: {
        id: "buyer.invoice.page.check.related.invoices.pennylaneSync.outcome.failure",
        defaultMessage: "failure",
    },
    retry: {
        id: "buyer.invoice.page.check.related.invoices.retry",
        defaultMessage: "Retry",
    },
    pennylaneSyncRetrySuccess: {
        id: "buyer.invoice.page.check.related.invoices.pennylaneSyncRetrySuccess",
        defaultMessage: "The synchronization is in progress and may take several minutes to complete.",
    },
    pennylaneSyncRetryError: {
        id: "common.errorContact",
        defaultMessage: "Something went wrong. Please contact your administrator",
    },
    pennylaneSyncRetryForbiddenError: {
        id: "buyer.invoice.page.check.related.invoices.pennylaneSyncRetryForbiddenError",
        defaultMessage:
            'You don\'t have right to perform a retry. You need "approve" permission on invoices to be able to perform this action.',
    },
    pennylaneSyncAlreadyInProgressError: {
        id: "buyer.invoice.page.check.related.invoices.pennylaneSyncAlreadyInProgressError",
        defaultMessage: "A synchronization is already in progress. Please wait a bit and refresh the page.",
    },
})

interface Props {
    invoice: InvoiceI
    invoiceV1?: boolean
}

const MAX_NB_RELATED_DOCUMENTS = 5

export default function AutoCheck({ invoice, invoiceV1 = false }: Props) {
    const { formatMessage, locale } = useIntl()
    const [contract, setContract] = useState<DocumentDataI | null>(null)
    const [contractCheckDetail, setContractCheckDetail] = useState<ContractCheckI | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [invoiceProgess, setInvoiceProgess] = useState<number>(1 / 3)
    const [relatedDocuments, setRelatedDocuments] = useState<DocumentI[]>([])
    const [reducedRelatedDocuments, setReducedRelatedDocuments] = useState<DocumentI[]>([])
    const [nbRelatedDocuments, setNbRelatedDocuments] = useState<number>(MAX_NB_RELATED_DOCUMENTS)
    const [showShowMoreButton, setShowShowMoreButton] = useState<boolean>(true)

    const userId = useAppSelector(selectUserId)
    const isConnected = useAppSelector(getIsConnected)
    const documentsRelation = useAppSelector(selectInvoiceRelationsState)

    const { documents } = useFetchObjectDocuments(invoice.id ?? "")
    const { receivedDocuments } = useGetReceivedDocuments(
        {
            organizationId: invoice.buyer?.organizationId ?? undefined,
            userId,
        },
        true
    )
    const { totalPercentageWithoutRejected } = useLineItemsByStatusComparedToBudget(invoice)
    const { status: pennylaneStatus } = useInvoicePennylaneSynchronizationStatus(invoice)
    const { loading: pushLoading, pushInvoicePennylaneSynchronization } = usePushInvoicePennylaneSynchronization(
        invoice.id
    )
    const pennylaneSyncSuccess = pennylaneStatus?.pennylaneSyncOutcome === PennylaneSyncOutcome.SUCCESS
    const isInvoiceConfirmed = invoice.status === InvoiceStatus.CONFIRMED

    useEffect(() => {
        if (invoice) {
            setInvoiceProgess(
                getInvoiceProgress(
                    invoice,
                    contractCheckDetail,
                    isLoading,
                    pennylaneStatus
                        ? (pennylaneStatus?.pennylaneSyncOutcome as PennylaneSyncOutcome) ===
                              PennylaneSyncOutcome.SUCCESS
                        : undefined
                )
            )
        }
    }, [invoice, isLoading, contractCheckDetail, pennylaneStatus])

    const numberOfPOLinked = documentsRelation?.invoiceRelations?.length ?? 0
    const POLinkStatus = numberOfPOLinked > 0

    // TODO: check if undefined works here
    useEffect(() => {
        if (isConnected && documents) {
            const contract = documents.find((document) => document.documentType === DocumentType.CONTRACT)
            if (contract) {
                setIsLoading(true)
                setContract(contract)

                if (!contract.latestFileVersion) {
                    setContractCheckDetail(createEmptyContractCheck())
                    setIsLoading(false)
                    return
                }

                const dueDate = dayjs(invoice.dueDate).format("DD/MM/YYYY")
                const receivedAt = dayjs(Date()).format("DD/MM/YYYY")
                understandingApi
                    .contractCheck(contract.latestFileVersion, dueDate, receivedAt)
                    .then((details) => setContractCheckDetail(details))
                    .finally(() => setIsLoading(false))
            }
        }
    }, [documents, isConnected])

    useEffect(() => {
        if (receivedDocuments.length) {
            setRelatedDocuments(
                receivedDocuments.filter(
                    (doc) =>
                        doc.supplierName === invoice.supplier?.name &&
                        [InvoiceStatus.PAID, InvoiceStatus.MARKED_AS_PAID].includes(doc.status)
                )
            )
        }
    }, [receivedDocuments])

    useEffect(() => {
        if (relatedDocuments.length) {
            const length = Math.min(nbRelatedDocuments, relatedDocuments.length)
            const slicedDocuments = relatedDocuments.slice(0, length)
            setShowShowMoreButton(length < relatedDocuments.length)
            setReducedRelatedDocuments(slicedDocuments)
        }
    }, [relatedDocuments, nbRelatedDocuments])

    const showMoreRelatedDocuments = useCallback(() => {
        setNbRelatedDocuments((prev) => prev + MAX_NB_RELATED_DOCUMENTS)
    }, [nbRelatedDocuments])

    const retryHandler = useCallback(async () => {
        const statusResonse = await pushInvoicePennylaneSynchronization()
        if (statusResonse) {
            if (statusResonse === 202) {
                toast.success(formatMessage(messages.pennylaneSyncRetrySuccess))
            } else if (statusResonse === 403) {
                toast.error(formatMessage(messages.pennylaneSyncRetryForbiddenError))
            } else if (statusResonse === 409) {
                toast.error(formatMessage(messages.pennylaneSyncAlreadyInProgressError))
            } else {
                toast.error(formatMessage(messages.pennylaneSyncRetryError))
            }
        }
    }, [pushInvoicePennylaneSynchronization])

    const invoiceBudgetLabel = !invoice.budgets?.length
        ? "invoiceHasNoBudget"
        : totalPercentageWithoutRejected <= 1
          ? "invoiceInBudget"
          : "invoiceOverBudget"

    return (
        <Grid container className="auto-check">
            {invoiceV1 ? (
                <h4>{`${invoiceProgess * 100}%`}</h4>
            ) : (
                <Grid item md={12}>
                    <h4>
                        <SafeFormattedMessage {...messages.title} />
                    </h4>
                    <ItemLabel>
                        <SafeFormattedMessage {...messages.description} />
                    </ItemLabel>
                </Grid>
            )}

            <Grid item md={12} className="progressbar-wrapper">
                {invoiceV1 ? (
                    <CommonProgressBar values={[{ percentage: invoiceProgess, color: "var(--primary-color)" }]} />
                ) : (
                    <ProgressBar progress={invoiceProgess} displayPercentage={true} />
                )}
            </Grid>
            <Grid item md={12} className="checklist">
                <ul>
                    {pennylaneStatus && (!isInvoiceConfirmed || (isInvoiceConfirmed && !pennylaneSyncSuccess)) && (
                        <li>
                            <div className="checklist-li">
                                <StatusIcon type={pennylaneSyncSuccess ? "done" : "error"} />
                                <span className="label">
                                    <SafeFormattedMessage
                                        {...messages.pennylaneSync}
                                        values={{
                                            outcome: pennylaneStatus.pennylaneSyncOutcome
                                                ? formatMessage(messages[pennylaneStatus.pennylaneSyncOutcome])
                                                : "",
                                        }}
                                    />
                                </span>
                                <span className="pennylane-date">
                                    <DateComponent
                                        value={dayjs(pennylaneStatus.pennylaneLastSyncDate).toISOString()}
                                        timeLocale={locale}
                                    />
                                </span>
                                {!pennylaneSyncSuccess && (
                                    <>
                                        <Tooltip
                                            title={pennylaneStatus.pennylaneSyncOutcomeReason}
                                            arrow
                                            placement="top"
                                        >
                                            <span className="infos">
                                                <Info size={24} />
                                            </span>
                                        </Tooltip>
                                        {!isInvoiceConfirmed && (
                                            <Button
                                                className="retry-button"
                                                type="primary-light"
                                                onClick={retryHandler}
                                                disabled={pushLoading}
                                            >
                                                <span>{formatMessage(messages.retry)}</span>
                                                {pushLoading ? <SpinningLoader /> : <RotateCw size={14} />}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                        </li>
                    )}
                    <Tooltip title={<SafeFormattedMessage {...messages.formatInvoiceTooltip} />} arrow placement="left">
                        <li>
                            <StatusIcon type="done" />
                            <span className="label">
                                <SafeFormattedMessage {...messages.formatInvoice} />
                            </span>
                            <span className="infos">
                                <Info size={24} />
                            </span>
                        </li>
                    </Tooltip>
                    <Tooltip
                        title={
                            <SafeFormattedMessage
                                {...(invoice.signed
                                    ? messages.invoiceSignedTooltip
                                    : messages.invoiceNotYetSignedTooltip)}
                            />
                        }
                        arrow
                        placement="left"
                    >
                        <li>
                            <StatusIcon type={invoice.signed ? "done" : "error"} />
                            <span className="label">
                                <SafeFormattedMessage {...messages.invoiceSigned} />
                            </span>
                            <span className="infos">
                                <Info size={24} />
                            </span>
                        </li>
                    </Tooltip>
                    {!contract ? null : (
                        <Tooltip
                            title={contractCheckDetail?.reasonExplained}
                            arrow
                            placement="left"
                            disableHoverListener={!contractCheckDetail?.reasonExplained}
                        >
                            <li>
                                {isLoading ? (
                                    <SpinningLoader />
                                ) : (
                                    <StatusIcon
                                        type={contractCheckDetail?.matchWithPaymentCondition ? "done" : "error"}
                                    />
                                )}

                                <span className="label">
                                    <SafeFormattedMessage {...messages.conditions} />
                                </span>

                                {isLoading ? null : (
                                    <span className="infos">
                                        <Info size={24} />
                                    </span>
                                )}
                            </li>
                        </Tooltip>
                    )}

                    {POLinkStatus ? (
                        <Tooltip
                            title={
                                <SafeFormattedMessage
                                    {...messages.POPresentTooltip}
                                    values={{ count: numberOfPOLinked }}
                                />
                            }
                            arrow
                            placement="left"
                        >
                            <li>
                                <span className={`status status-${POLinkStatus ? "done" : "error"}`}>
                                    <CheckCircle />
                                </span>
                                <span className="label">
                                    <SafeFormattedMessage
                                        {...messages.POPresent}
                                        values={{ count: numberOfPOLinked }}
                                    />
                                </span>
                                <span className="infos">
                                    <Info size={24} />
                                </span>
                            </li>
                        </Tooltip>
                    ) : null}

                    {!reducedRelatedDocuments.length ? null : (
                        <>
                            <Tooltip
                                title={<SafeFormattedMessage {...messages.matchingTooltip} />}
                                arrow
                                placement="left"
                            >
                                <li>
                                    <span className="status">
                                        <Info size={24} color="var(--color-green)" />
                                    </span>
                                    <span className="label">
                                        <SafeFormattedMessage {...messages.matching} />
                                    </span>
                                    <span className="infos">
                                        <Info size={24} />
                                    </span>
                                </li>
                            </Tooltip>
                            <li className="li-list">
                                <ul>
                                    {reducedRelatedDocuments.map((doc, index) => (
                                        <li key={index}>
                                            <a
                                                href={"/buyer/invoice/" + doc.invoiceId}
                                                rel="noreferrer"
                                                target="_blank"
                                                title=""
                                            >
                                                {`${doc.total} € - ${doc.reference} - `}
                                                <DateComponent value={doc.issueDate} />
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                                {showShowMoreButton && (
                                    <span className="span-button" onClick={showMoreRelatedDocuments}>
                                        <span>
                                            <Plus />
                                        </span>
                                        <SafeFormattedMessage {...messages.showMore} />
                                    </span>
                                )}
                            </li>
                        </>
                    )}

                    {
                        <>
                            <Tooltip
                                title={<SafeFormattedMessage {...messages[invoiceBudgetLabel]} />}
                                arrow
                                placement="left"
                            >
                                <li>
                                    <span className="status">
                                        <StatusIcon type={totalPercentageWithoutRejected <= 1 ? "done" : "error"} />
                                    </span>
                                    <span className="label">
                                        <SafeFormattedMessage {...messages[invoiceBudgetLabel]} />
                                    </span>
                                    <span className="infos">
                                        <Info size={24} />
                                    </span>
                                </li>
                            </Tooltip>
                        </>
                    }
                </ul>
            </Grid>
        </Grid>
    )
}
