import { capitalize, useMediaQuery, useTheme } from "@mui/material"
import { Box } from "@mui/material"
import { Stack } from "@mui/material"
import { Tooltip } from "@mui/material"
import { isNumber } from "lodash"
import { defineMessages, useIntl } from "react-intl"

import { ItemLabel } from "~/components"
import { PurchaseOrderProgressStatus } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"

const messages = defineMessages({
    items: {
        id: "purchase.orders.order.steps.items",
        defaultMessage: "{current}/{total} items",
    },
})

export enum PurchaseOrderExtraProgressStatus {
    INVOICED = "INVOICED",
}

export type PurchaseOrderProgressStatusExtended = PurchaseOrderProgressStatus | PurchaseOrderExtraProgressStatus

interface StepProps {
    status?: string
    icon: React.ReactNode
    name: string
    description?: string
    totalLines?: number
    linesInProgress?: number
    onClick?: (name: string) => void
}

export const Step = ({ status, icon, name, description, totalLines, linesInProgress, onClick }: StepProps) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()
    const hideLabels = useMediaQuery(theme.breakpoints.down("lg"))
    const hideCount = useMediaQuery(theme.breakpoints.down("md"))

    const handleOnClick = () => {
        onClick?.(name)
    }

    const hasLines = isNumber(totalLines) && isNumber(linesInProgress)

    const isDone = status === "done"

    return (
        <Tooltip title={description || name} arrow>
            <Stack
                direction={{ lg: "column", xl: "row" }}
                alignItems="center"
                justifyContent="center"
                columnGap={2}
                height={hasLines ? 100 : 60}
                padding={2}
                onClick={handleOnClick}
                color={isDone ? "var(--color-green)" : "var(--color-grey)"}
                className={onClick ? "cursor-pointer" : ""}
            >
                <Box height={24} width={24}>
                    {icon}
                </Box>

                <Stack alignItems={hideLabels ? "center" : "flex-start"}>
                    {!hideLabels && <Box>{capitalize(name)}</Box>}

                    {!hideCount && hasLines && (
                        <ItemLabel>
                            {formatMessage(messages.items, {
                                current: linesInProgress,
                                total: totalLines,
                            })}
                        </ItemLabel>
                    )}
                </Stack>
            </Stack>
        </Tooltip>
    )
}
