/* eslint-disable no-nested-ternary */
import { Portal, styled } from "@mui/material"
import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { SafeFormattedMessage } from "~/components"
import { sortTagGroups } from "~/domains/analytics/tags/core/tagGroupsAndTags"
import { SelectedTagI } from "~/domains/analytics/tags/types"
import { TagGroupI } from "~/domains/analytics/tags/types/TagGroup"
import { ORGANIZATION_TAGS_ROUTE } from "~/domains/identity/account/routes"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { stopPropagation } from "~/utils"

import { TagGroup } from "./TagGroup"
import { TagInputSuggestions } from "./TagInputSuggestions"
import { getTagsFilter } from "./getTagsFilter"

const messages = defineMessages({
    placeholder: {
        id: "tags.TagSelector.placeholder",
        defaultMessage: "Add tag…",
    },
    noResults: {
        id: "tags.TagSelector.noResults",
        defaultMessage: 'No matching tags for "{searchTerm}"',
    },
    noTagsInOrganization: {
        id: "tags.TagSelector.noTagsInOrganization",
        defaultMessage: "There are currently no tags in this organization. Create some in your <em>settings</em>.",
    },
})

interface Props {
    tagGroups: TagGroupI[]
    selectedTags: SelectedTagI[]
    setSelectedTags: Dispatch<SetStateAction<SelectedTagI[]>>
    suggestions?: SelectedTagI[]
}

const Input = styled("input")({
    display: "block",
    border: 0,
    flex: "1 1 auto",
    width: "1%",
    background: "transparent",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-body-size)",
})

const TagsListContainer = styled("div")({
    display: "block",
    width: "100%",
    position: "absolute",
    backgroundColor: "white",
    zIndex: 1300,
    boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.25)",
    minHeight: "56px",
    overflowY: "auto",
    overflowX: "hidden",
})

const HiddenValueContainer = styled("span")({
    position: "absolute",
    opacity: 0,
    zIndex: -1,
})

const StyledMessage = styled("div")({
    marginTop: "8px",
    marginLeft: "8px",
    color: "var(--color-grey-light)",
})

const tagGroupHasTag = (tagGroup: TagGroupI) => tagGroup.tags.length > 0

const getTagByIndex = (tagGroups: TagGroupI[], index: number) => {
    let j = 0
    for (let i = 0, tagGroup: TagGroupI; i < tagGroups.length; ++i) {
        tagGroup = tagGroups[i]
        j += tagGroup.tags.length
        if (j > index) {
            return tagGroup.tags[index + tagGroup.tags.length - j]
        }
    }
    return null
}

export const TagInput = forwardRef<HTMLInputElement, Props>(
    ({ tagGroups, selectedTags, setSelectedTags, suggestions }, ref) => {
        const { formatMessage } = useIntl()
        const [searchValue, setSearchValue] = useState("")
        const [width, setWidth] = useState(0)
        const [isFocused, setIsFocused] = useState(false)
        const [isSearching, setIsSearching] = useState(false)
        const hiddenValueRef = useRef<HTMLSpanElement>(null)
        const tagListRef = useRef<HTMLDivElement>(null)
        const inputRef = useRef<HTMLInputElement>(null)
        const [index, setIndex] = useState(0)
        const navigate = useNavigate()

        const sortedTagGroups = useMemo(() => sortTagGroups(tagGroups), [tagGroups])

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement)

        useEffect(() => {
            setSearchValue("")
            setIndex(0)
        }, [selectedTags])

        useEffect(() => {
            if (hiddenValueRef.current) {
                setWidth(hiddenValueRef.current.offsetWidth)
            }
        }, [searchValue])

        const onChange = useCallback(
            (event: ChangeEvent<HTMLInputElement>) => {
                setSearchValue(event.currentTarget.value)
                setIndex(0)
            },
            [setSearchValue]
        )

        const onFocus = useCallback(() => {
            if (!isFocused) {
                setIsFocused(true)
                const onDocumentClick = () => {
                    setIsFocused(false)
                    window.removeEventListener("click", onDocumentClick)
                }
                window.addEventListener("click", onDocumentClick, false)
            }
        }, [setIsFocused, isFocused])

        const filteredTagGroups = useMemo(() => {
            if (searchValue.trim().length === 0) {
                setIsSearching(false)
                return sortedTagGroups
            }

            setIsSearching(true)
            return sortedTagGroups
                .map((tagGroup) => ({
                    ...tagGroup,
                    tags: tagGroup.tags.filter(getTagsFilter(searchValue, tagGroup)),
                }))
                .filter(tagGroupHasTag)
        }, [sortedTagGroups, searchValue])

        const highlightTag = useMemo(
            () => (isFocused ? getTagByIndex(filteredTagGroups, index) : null),
            [isFocused, filteredTagGroups, index]
        )

        const renderTagGroup = useCallback(
            (tagGroup: TagGroupI) => {
                const unfilteredTagGroup = sortedTagGroups.find((tg) => tg.tagGroupId === tagGroup.tagGroupId)
                if (!unfilteredTagGroup) return null
                return (
                    <TagGroup
                        key={tagGroup.tagGroupId}
                        tagGroup={tagGroup}
                        unfilteredTags={unfilteredTagGroup.tags}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                        highlightTag={highlightTag}
                        defaultExpanded={isSearching}
                    />
                )
            },
            [sortedTagGroups, selectedTags, setSelectedTags, highlightTag, isSearching]
        )

        const setTagsContainerPosition = useCallback(() => {
            if (tagListRef.current && inputRef.current && inputRef.current.parentElement) {
                const rect = inputRef.current.parentElement.getBoundingClientRect()
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const width = Math.max(200, rect.width + 10)
                const left = rect.left + window.scrollX - 10
                const top = rect.bottom + window.scrollY
                let maxHeight = Math.max(200, Math.min(400, window.innerHeight - top))
                if (left + width >= window.innerWidth) {
                    tagListRef.current.style.right = `${
                        document.body.clientWidth - (rect.left + rect.width + window.scrollX)
                    }px`
                    tagListRef.current.style.left = "auto"
                } else {
                    tagListRef.current.style.left = `${left}px`
                }
                if (top + maxHeight > window.innerHeight) {
                    const bottom = document.body.clientHeight - (rect.top + window.scrollY)
                    maxHeight = Math.max(maxHeight, 200, Math.min(400, window.innerHeight - bottom))
                    tagListRef.current.style.top = "auto"
                    tagListRef.current.style.bottom = `${document.body.clientHeight - (rect.top + window.scrollY)}px`
                } else {
                    tagListRef.current.style.top = `${top}px`
                }
                tagListRef.current.style.maxWidth = `${width}px`
                tagListRef.current.style.maxHeight = `${maxHeight}px`
                tagListRef.current.scrollTop = 0
            }
        }, [])

        useEffect(() => {
            requestAnimationFrame(setTagsContainerPosition)
        }, [isFocused, selectedTags])

        const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
        const handleClick = useCallback(() => {
            navigate(generatePath(ORGANIZATION_TAGS_ROUTE, { organizationId: currentOrganizationId as string }))
        }, [currentOrganizationId])

        const placeholder = formatMessage(messages.placeholder)
        const tagsToDisplay = filteredTagGroups.map(renderTagGroup)

        return (
            <>
                <Input
                    value={searchValue}
                    onChange={onChange}
                    onFocus={onFocus}
                    placeholder={placeholder}
                    ref={inputRef}
                    sx={{ width }}
                />
                <HiddenValueContainer ref={hiddenValueRef}>{searchValue || placeholder}</HiddenValueContainer>
                {isFocused && (
                    <Portal>
                        <TagsListContainer onClick={stopPropagation} ref={tagListRef}>
                            {suggestions && (
                                <TagInputSuggestions setSelectedTags={setSelectedTags} suggestions={suggestions} />
                            )}
                            {!sortedTagGroups.length ? (
                                <StyledMessage onClick={handleClick}>
                                    <SafeFormattedMessage
                                        {...messages.noTagsInOrganization}
                                        values={{ em: (msg) => <em>{msg}</em> }}
                                    />
                                </StyledMessage>
                            ) : tagsToDisplay.length ? (
                                tagsToDisplay
                            ) : searchValue ? (
                                <StyledMessage>
                                    {formatMessage(messages.noResults, { searchTerm: searchValue })}
                                </StyledMessage>
                            ) : null}
                        </TagsListContainer>
                    </Portal>
                )}
            </>
        )
    }
)
TagInput.displayName = "TagInput"
