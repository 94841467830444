/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Check, Edit3, Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { StepI, Steps } from "~/components"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

const messages = defineMessages({
    draft: {
        id: "purchase.requests.request.steps.draft",
        defaultMessage: "Draft",
    },
    sent: {
        id: "purchase.requests.request.steps.sent",
        defaultMessage: "Submitted",
    },
    create: {
        id: "purchase.requests.request.steps.create",
        defaultMessage: "Create",
    },
    done: {
        id: "purchase.requests.request.steps.done",
        defaultMessage: "Done",
    },
    approval: {
        id: "purchase.requests.request.steps.approval",
        defaultMessage: "Approval",
    },
    APPROVED: {
        id: "purchase.requests.request.steps.approved",
        defaultMessage: "Approved",
    },
    REJECTED: {
        id: "purchase.requests.request.steps.rejected",
        defaultMessage: "Rejected",
    },
    CONVERTED: {
        id: "purchase.requests.request.steps.converted",
        defaultMessage: "Done",
    },
    items: {
        id: "purchase.orders.order.steps.items",
        defaultMessage: "{current}/{total} items",
    },
})

interface StepsProps {
    PR: PurchaseRequestDetails
}

export const StepsPurchaseRequest = ({ PR }: StepsProps) => {
    const { formatMessage, formatDate } = useIntl()

    const steps: StepI[] = [
        {
            key: "draft",
            name: formatMessage(messages.draft),
            value: PR.status === PurchaseRequestStatus.DRAFT ? "Create" : "Done",
            status: "done",
            done: true,
            icon: <Edit3 size={18} />,
        },
        {
            key: "sent",
            name: formatMessage(messages.sent),
            value: PR.submittedDate ? formatDate(PR.submittedDate) : "-",
            status: PR.status !== PurchaseRequestStatus.DRAFT ? "done" : "next",
            done: PR.status !== PurchaseRequestStatus.DRAFT,
            icon: <Send size={18} />,
        },
        {
            key: "validation",
            name: formatMessage(messages.approval),
            value: (() => {
                if (PR.purchaseOrder) {
                    return formatMessage(messages.CONVERTED)
                } else if (
                    PR.status === PurchaseRequestStatus.APPROVED ||
                    PR.status === PurchaseRequestStatus.REJECTED
                ) {
                    return formatMessage(messages[PR.status])
                } else {
                    return "-"
                }
            })(),
            done: PR.status === PurchaseRequestStatus.APPROVED || PR.status === PurchaseRequestStatus.REJECTED,
            status:
                PR.status === PurchaseRequestStatus.APPROVED || PR.status === PurchaseRequestStatus.REJECTED
                    ? "done"
                    : "next",
            rejected: PR.status === PurchaseRequestStatus.REJECTED,
            icon: <Check size={18} />,
        },
    ]

    return <Steps steps={steps} />
}
