import { Tooltip } from "@mui/material"
import { X } from "react-feather"
import { useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { PURCHASE_REQUEST_ROUTE } from "~/domains/transactions/purchase-requests/routes"

interface CancelEditPRProps {
    id: string
    display?: "button" | "menu-item"
}

export const CancelEditPR = ({ id, display = "button" }: CancelEditPRProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const handleCancelEdit = () => {
        navigate(generatePath(PURCHASE_REQUEST_ROUTE, { purchaseRequestId: id }))
    }

    return display === "button" ? (
        <Tooltip title={formatMessage(commonMessages.edit)}>
            <Button type="grey" onClick={handleCancelEdit}>
                <X size={16} />
                <SafeFormattedMessage {...commonMessages.cancel} />
            </Button>
        </Tooltip>
    ) : (
        <Button type="menu-item" onClick={handleCancelEdit}>
            <X size={16} />
            <SafeFormattedMessage {...commonMessages.cancel} />
        </Button>
    )
}
