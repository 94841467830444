import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"

import { KYBType } from "~/domains/identity/kyb/components/KYB"
import { RootState } from "~/store"
import { OrganizationId } from "~/types"

import { DocumentDataI, DocumentId } from "../Documents"
import documentState, { DocumentByOrgaState } from "./documentState"

const initialState = documentState

const emptyDocumentState: DocumentByOrgaState = {
    loading: true,
    loadingUpload: false,
    documents: [],
    error: null,
}

const documentSlice = createSlice({
    name: "document",
    initialState,
    reducers: {
        fetchDocumentsLoading: (state) => {
            state.loading = true
            state.error = null
        },
        fetchDocumentsFailed: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.error = action.payload
        },
        fetchDocumentsSuccess: (state, action: PayloadAction<DocumentDataI[]>) => {
            state.loading = false
            state.documents = action.payload ?? []
        },
        fetchDocumentsByTypeLoading: (
            state,
            action: PayloadAction<{ organizationId: OrganizationId; type: KYBType }>
        ) => {
            state.documentsByOrganization[action.payload.organizationId] = {
                ...state.documentsByOrganization[action.payload.organizationId],
                [action.payload.type]: {
                    loading: true,
                    loadingUpload: false,
                    error: null,
                    documents: [],
                },
            }
        },
        uploadDocumentLoading(state) {
            state.loadingUpload = true
            state.error = null
        },
        uploadDocumentSuccess(state, action: PayloadAction<DocumentDataI>) {
            state.loadingUpload = false
            state.documents = [action.payload, ...(state.documents ?? [])]
        },
        uploadDocumentFailed(state, action: PayloadAction<string>) {
            state.loadingUpload = false
            state.error = action.payload
        },
        fetchDocumentsByTypeFailed: (
            state,
            action: PayloadAction<{ organizationId: OrganizationId; type: KYBType; error: string }>
        ) => {
            state.documentsByOrganization[action.payload.organizationId] = {
                ...state.documentsByOrganization[action.payload.organizationId],
                [action.payload.type]: {
                    loading: false,
                    loadingUpload: false,
                    error: action.payload.error,
                    documents: [],
                },
            }
        },
        fetchDocumentsByTypeSuccess: (
            state,
            action: PayloadAction<{ organizationId: OrganizationId; type: KYBType; documents: DocumentDataI[] }>
        ) => {
            state.documentsByOrganization[action.payload.organizationId] = {
                ...state.documentsByOrganization[action.payload.organizationId],
                [action.payload.type]: {
                    loading: false,
                    loadingUpload: false,
                    error: null,
                    documents: action.payload.documents,
                },
            }
        },
        uploadDocumentByTypeLoading(state, action: PayloadAction<{ organizationId: OrganizationId; type: KYBType }>) {
            const stateByOrga = state.documentsByOrganization[action.payload.organizationId] ?? {}
            state.documentsByOrganization[action.payload.organizationId] = {
                ...stateByOrga,
                [action.payload.type]: {
                    ...(stateByOrga[action.payload.type] ?? emptyDocumentState),
                    loadingUpload: true,
                },
            }
        },
        uploadDocumentByTypeSuccess(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; type: KYBType; document: DocumentDataI }>
        ) {
            const stateByOrga = state.documentsByOrganization[action.payload.organizationId] ?? {}
            state.documentsByOrganization[action.payload.organizationId] = {
                ...stateByOrga,
                [action.payload.type]: {
                    ...(stateByOrga[action.payload.type] ?? emptyDocumentState),
                    loadingUpload: false,
                    documents: [
                        ...(stateByOrga[action.payload.type] ?? emptyDocumentState).documents,
                        action.payload.document,
                    ],
                },
            }
        },
        uploadDocumentByTypeFailed(
            state,
            action: PayloadAction<{ organizationId: OrganizationId; type: KYBType; error: string }>
        ) {
            const stateByOrga = state.documentsByOrganization[action.payload.organizationId] ?? {}
            state.documentsByOrganization[action.payload.organizationId] = {
                ...stateByOrga,
                [action.payload.type]: {
                    ...(stateByOrga[action.payload.type] ?? emptyDocumentState),
                    loadingUpload: false,
                    error: action.payload.error,
                },
            }
        },
        deleteDocumentSuccess(state, action: PayloadAction<DocumentId>) {
            state.loading = false
            state.documents = state.documents.filter((document) => document.id !== action.payload)
        },
    },
})

export const documentActions = documentSlice.actions

export const selectDocumentsData = (state: RootState) => state.document.documents
export const selectLoading = (state: RootState) => state.document.loading
export const selectLoadingUpload = (state: RootState) => state.document.loadingUpload
export const selectError = (state: RootState) => state.document.error

export const selectDocumentsByOrganization = (state: RootState) => state.document.documentsByOrganization

export const selectDocuments = createSelector(
    [selectDocumentsData, selectLoading, selectLoadingUpload, selectError],
    (documents, loading, loadingUpload, error) => ({
        documents,
        loading,
        loadingUpload,
        error,
    })
)

const documentReducer = documentSlice.reducer
export default documentReducer
