import { Box, Button, Divider, FormHelperText, Stack, TextField, Typography } from "@mui/material"
import cls from "classnames"
import React, { FC, useState } from "react"
import { Plus, Trash2 } from "react-feather"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { EditableText } from "~/components/EditableText"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, MappingNode, MappingNodeElement } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const MappingConfiguration: FC<ConfigurationProps<MappingNode>> = ({ selectedNode, advancedFields }) => {
    const { formatMessage } = useIntl()
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const [newMappingKey, setNewMappingKey] = useState("")
    const [editingKey, setEditingKey] = useState<string | null>(null)
    const [editingKeyValue, setEditingKeyValue] = useState("")
    const [keyError, setKeyError] = useState<string | null>(null)

    const handleValueToMapChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            valueToMap: event.target.value,
        }))
    }

    const handleAddDefaultValue = () => {
        const newDefaultValue = { label: "", value: "" }
        setCurrentNode((prev) => {
            const updatedMappingTable = Object.entries(prev.mappingTable).reduce(
                (acc, [key, values]) => ({
                    ...acc,
                    [key]: [...values, newDefaultValue],
                }),
                {}
            )

            return {
                ...prev,
                defaultValues: [...prev.defaultValues, newDefaultValue],
                mappingTable: updatedMappingTable,
            }
        })
    }

    const handleUpdateDefaultValue =
        (index: number, field: keyof MappingNodeElement) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            const elementAdapter = (item: MappingNodeElement, i: number) =>
                i === index ? { ...item, [field]: value } : item

            setCurrentNode((prev) => {
                if (field === "label") {
                    // If we update the label, we need to update the mapping table as well
                    const updatedMappingTable = Object.entries(prev.mappingTable).reduce(
                        (acc, [key, values]) => ({
                            ...acc,
                            [key]: values.map(elementAdapter),
                        }),
                        {}
                    )
                    return {
                        ...prev,
                        defaultValues: prev.defaultValues.map(elementAdapter),
                        mappingTable: updatedMappingTable,
                    }
                }

                return {
                    ...prev,
                    defaultValues: prev.defaultValues.map(elementAdapter),
                }
            })
        }

    const handleRemoveDefaultValue = (index: number) => () => {
        const indexFilter = (_: MappingNodeElement, i: number) => i !== index

        setCurrentNode((prev) => {
            const updatedMappingTable = Object.entries(prev.mappingTable).reduce(
                (acc, [key, values]) => ({
                    ...acc,
                    [key]: values.filter(indexFilter),
                }),
                {}
            )

            return {
                ...prev,
                defaultValues: prev.defaultValues.filter(indexFilter),
                mappingTable: updatedMappingTable,
            }
        })
    }

    const handleAddMappingKey = (key: string) => {
        if (!key.trim()) return

        if (currentNode.mappingTable[key]) {
            const message = formatMessage(messages.mappingConfiguration.duplicateKeyError)
            setKeyError(message)
            return
        }

        const newItem = currentNode.defaultValues.map((item) => ({
            ...item,
            value: "",
        }))

        setCurrentNode((prev) => ({
            ...prev,
            mappingTable: {
                ...prev.mappingTable,
                [key]: newItem,
            },
        }))
        setKeyError(null)
    }

    const handleUpdateMappingValue = (key: string, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setCurrentNode((prev) => ({
            ...prev,
            mappingTable: {
                ...prev.mappingTable,
                [key]: prev.mappingTable[key].map((item, i) => (i === index ? { ...item, value } : item)),
            },
        }))
    }

    const handleRemoveMappingKey = (key: string) => () => {
        setCurrentNode((prev) => {
            const newMappingTable = { ...prev.mappingTable }
            delete newMappingTable[key]
            return {
                ...prev,
                mappingTable: newMappingTable,
            }
        })
    }

    const handleNewMappingKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewMappingKey(event.target.value)
        setKeyError(null)
    }

    const handleNewMappingKeySubmit = () => {
        if (newMappingKey.trim()) {
            handleAddMappingKey(newMappingKey.trim())
            setNewMappingKey("")
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault()
            handleNewMappingKeySubmit()
        }
    }

    const handleStartEditKey = (key: string) => () => {
        setEditingKey(key)
        setEditingKeyValue(key)
    }

    const handleUpdateMappingKey = (oldKey: string, newKey: string) => () => {
        if (!newKey.trim() || oldKey === newKey) {
            setEditingKey(null)
            return
        }

        const message = formatMessage(messages.mappingConfiguration.duplicateKeyError)
        if (currentNode.mappingTable[newKey]) {
            setKeyError(message)
            return
        }

        setCurrentNode((prev) => {
            const newMappingTable = { ...prev.mappingTable }
            const values = newMappingTable[oldKey]
            delete newMappingTable[oldKey]
            newMappingTable[newKey] = values
            return {
                ...prev,
                mappingTable: newMappingTable,
            }
        })
        setEditingKey(null)
        setKeyError(null)
    }

    const handleKeyEditKeyDown = (oldKey: string) => (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault()
            handleUpdateMappingKey(oldKey, editingKeyValue)
        } else if (event.key === "Escape") {
            setEditingKey(null)
        }
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <TextField
                    fullWidth
                    size="small"
                    label={formatMessage(messages.mappingConfiguration.valueToMapLabel)}
                    value={currentNode.valueToMap}
                    onChange={handleValueToMapChange}
                    margin="normal"
                />
                <Divider />
                <Stack gap={2}>
                    <Typography variant="subtitle2">
                        <SafeFormattedMessage {...messages.mappingConfiguration.defaultValuesTitle} />
                    </Typography>
                    <Stack gap={2}>
                        {currentNode.defaultValues.map((defaultValue, index) => (
                            <Stack key={index} gap={1} direction="row" alignItems="center">
                                <TextField
                                    size="small"
                                    label={formatMessage(messages.mappingConfiguration.labelFieldLabel)}
                                    value={defaultValue.label}
                                    onChange={handleUpdateDefaultValue(index, "label")}
                                />
                                <TextField
                                    size="small"
                                    label={formatMessage(messages.mappingConfiguration.valueFieldLabel)}
                                    value={defaultValue.value}
                                    onChange={handleUpdateDefaultValue(index, "value")}
                                />

                                <IconButton onClick={handleRemoveDefaultValue(index)} type="grey-light" size="small">
                                    <Trash2 size={12} color="var(--color-grey)" />
                                </IconButton>
                            </Stack>
                        ))}
                    </Stack>
                    <Button startIcon={<Plus />} onClick={handleAddDefaultValue} size="small">
                        <SafeFormattedMessage {...messages.mappingConfiguration.addDefaultValueButton} />
                    </Button>
                </Stack>

                <Stack gap={2}>
                    <Typography variant="subtitle2">
                        <SafeFormattedMessage {...messages.mappingConfiguration.mappingTableTitle} />
                    </Typography>
                    {Object.entries(currentNode.mappingTable).map(([key, values]) => (
                        <Stack key={key} gap={2} className={configurationNodeItemClassName}>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Box flex={1}>
                                    {editingKey === key ? (
                                        <EditableText
                                            onKeyDown={handleKeyEditKeyDown(key)}
                                            onChange={(e) => setEditingKeyValue(e.target.value)}
                                            onBlur={handleUpdateMappingKey(key, editingKeyValue)}
                                            value={editingKeyValue}
                                            fullWidth
                                        />
                                    ) : (
                                        <EditableText onClick={handleStartEditKey(key)} value={key} />
                                    )}
                                </Box>
                                <IconButton onClick={handleRemoveMappingKey(key)} type="grey-light" size="small">
                                    <Trash2 size={12} color="var(--color-grey)" />
                                </IconButton>
                            </Stack>

                            {values.map((value, index) => (
                                <Stack key={value.label} gap={2} direction="row" alignItems="center">
                                    <TextField
                                        size="small"
                                        label={formatMessage(messages.mappingConfiguration.labelFieldLabel)}
                                        value={value.label}
                                        disabled
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        size="small"
                                        label={formatMessage(messages.mappingConfiguration.valueFieldLabel)}
                                        value={value.value}
                                        onChange={handleUpdateMappingValue(key, index)}
                                    />
                                </Stack>
                            ))}
                        </Stack>
                    ))}
                </Stack>
                <Stack direction="row" gap={1}>
                    <Box flex={1}>
                        <TextField
                            size="small"
                            label={formatMessage(messages.mappingConfiguration.newMappingKeyLabel)}
                            value={newMappingKey}
                            onChange={handleNewMappingKeyChange}
                            onKeyDown={handleKeyDown}
                            error={!!keyError}
                            fullWidth
                        />
                        {keyError && (
                            <FormHelperText error>
                                <SafeFormattedMessage {...messages.mappingConfiguration.duplicateKeyError} />
                            </FormHelperText>
                        )}
                    </Box>
                    <Button
                        startIcon={<Plus />}
                        onClick={handleNewMappingKeySubmit}
                        size="small"
                        disabled={!newMappingKey}
                    >
                        <SafeFormattedMessage {...messages.mappingConfiguration.addElementButton} />
                    </Button>
                </Stack>
            </Stack>

            <AdvancedFields<MappingNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
