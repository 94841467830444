import * as t from "io-ts"

import { optional } from "~/utils/io-ts"

export const FinancialMetricsIO = t.intersection([
    t.type({
        financialStatementToDate: optional(t.string),
        yearlyRevenue: t.array(t.type({ value: t.number, currency: t.string })),
    }),
    t.partial({
        financialStatementDuration: optional(t.string),
        informationScopeDescription: optional(t.string),
        informationScopeDnBCode: optional(t.number),
        reliabilityDescription: optional(t.string),
        reliabilityDnBCode: optional(t.number),
        unitCode: optional(t.string),
    }),
])

export type FinancialMetricsI = t.TypeOf<typeof FinancialMetricsIO>
