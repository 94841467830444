import { FormControlLabel, Grid, Stack, Switch } from "@mui/material"
import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { OrganizationContactForm } from "~/domains/identity/organization/components/ModalOrganizationSelectorDetails/OrganizationContactForm"
import { OrganizationAddressI, OrganizationId, OrganizationRegistrationI } from "~/types"

import { AddressForm } from "./AddressForm"
import { OrganizationRegistrationForm } from "./OrganizationRegistrationForm"
import { createEmptyAddress } from "./createEmptyAddress"
import { SavePayloadType } from "./types"

const messages = defineMessages({
    billingAddress: {
        id: "identity.organizations.modalOrganizationDetails.billingAddress",
        defaultMessage: "Billing address",
    },
    deliveryAddress: {
        id: "identity.organizations.modalOrganizationDetails.deliveryAddress",
        defaultMessage: "Delivery address",
    },
    copyAddress: {
        id: "identity.organizations.modalOrganizationDetails.copyAddress",
        defaultMessage: "Use for delivery address",
    },
})

interface Props {
    id?: string
    onSave?: (payload: SavePayloadType) => void
    registrationInfo: OrganizationRegistrationI
    companyAddress?: OrganizationAddressI | null
    sendingAddress?: OrganizationAddressI | null
    withOrganizationAddress?: boolean
    withSendingAddress?: boolean
    registrationInfoReadOnly?: boolean
    adressesReadOnly?: boolean
    readOnly?: boolean
    organizationId?: OrganizationId
}

export const ModalOrganizationDetails: React.FC<Props> = ({
    id,
    onSave,
    registrationInfo,
    companyAddress,
    sendingAddress: initialSendingAddress,
    withOrganizationAddress = false,
    withSendingAddress = false,
    registrationInfoReadOnly = false,
    adressesReadOnly = false,
    readOnly,
    organizationId,
}) => {
    const { formatMessage } = useIntl()
    const [organizationRegistration, setOrganizationRegistration] =
        useState<OrganizationRegistrationI>(registrationInfo)
    const [organizationAddress, setOrganizationAddress] = useState<OrganizationAddressI>(
        companyAddress ?? createEmptyAddress()
    )
    const [sendingAddress, setSendingAddress] = useState<OrganizationAddressI>(
        initialSendingAddress ?? createEmptyAddress()
    )

    const [useSameAddress, setUseSameAddress] = useState<boolean>(false)
    const shouldUseSameAddress = useSameAddress && withOrganizationAddress && withSendingAddress
    const toggleUseSameAddress = useCallback(() => setUseSameAddress((currentValue) => !currentValue), [])

    useEffect(() => {
        if (initialSendingAddress) {
            setSendingAddress(initialSendingAddress)
        }
    }, [initialSendingAddress])

    const onRegistrationChange = (payload: Partial<OrganizationRegistrationI>) => {
        setOrganizationRegistration((currentRegistration) => ({
            ...currentRegistration,
            ...payload,
        }))
    }

    const onOrganizationAddressChange = (payload: Partial<OrganizationAddressI>) => {
        setOrganizationAddress((currentAddress) => ({
            ...currentAddress,
            ...payload,
        }))
    }

    const onSendingAddressChange = (payload: Partial<OrganizationAddressI>) => {
        setSendingAddress((currentAddress) => ({
            ...currentAddress,
            ...payload,
        }))
    }

    const onSubmit = (event: FormEvent) => {
        event.preventDefault()
        event.stopPropagation()

        if (!onSave) return

        const sa = useSameAddress ? organizationAddress : sendingAddress

        onSave({
            organizationRegistration,
            organizationAddress: withOrganizationAddress ? organizationAddress : undefined,
            sendingAddress: withSendingAddress ? sa : undefined,
        })
    }

    return (
        <form id={id} onSubmit={onSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Stack gap={2}>
                        <OrganizationRegistrationForm
                            registration={organizationRegistration}
                            onChange={onRegistrationChange}
                            readOnly={registrationInfoReadOnly}
                            organizationId={organizationId}
                        />
                        <OrganizationContactForm onSave={onSave} organizationId={organizationId} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    {withOrganizationAddress && (
                        <Stack>
                            <AddressForm
                                address={organizationAddress}
                                onChange={adressesReadOnly ? undefined : onOrganizationAddressChange}
                                label={formatMessage(messages.billingAddress)}
                            />

                            {!readOnly && withSendingAddress && (
                                <FormControlLabel
                                    control={<Switch checked={useSameAddress} onChange={toggleUseSameAddress} />}
                                    label={formatMessage(messages.copyAddress)}
                                />
                            )}
                        </Stack>
                    )}
                    {withSendingAddress && (
                        <Stack>
                            <AddressForm
                                address={shouldUseSameAddress ? organizationAddress : sendingAddress}
                                onChange={shouldUseSameAddress || adressesReadOnly ? undefined : onSendingAddressChange}
                                label={formatMessage(messages.deliveryAddress)}
                            />
                        </Stack>
                    )}
                </Grid>
            </Grid>
        </form>
    )
}
