import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"

import { PurchaseRequests } from "./pages"
import { PurchaseRequest, PurchaseRequestNew } from "./pages/[ID]"
import {
    PURCHASE_REQUESTS_ROUTE,
    PURCHASE_REQUEST_EDIT_ROUTE,
    PURCHASE_REQUEST_NEW_ROUTE,
    PURCHASE_REQUEST_ROUTE,
} from "./routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="prs_route">
            <Route path={PURCHASE_REQUESTS_ROUTE} element={<PurchaseRequests />} />
            <Route path={PURCHASE_REQUEST_ROUTE} element={<PurchaseRequest />} />
            <Route path={PURCHASE_REQUEST_NEW_ROUTE} element={<PurchaseRequestNew />} />
            <Route path={PURCHASE_REQUEST_EDIT_ROUTE} element={<PurchaseRequest edit={true} />} />
        </Route>,
    ],
}
