import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { priceFromApiAdapters } from "~/domains/transactions/catalog-v1/api/adapters/catalogFromApiAdapters"
import {
    CatalogProductVariantPrice,
    CreateCatalogProductVariantPricePayload,
    DeleteCatalogProductVariantPricePayload,
    GetCatalogProductVariantPriceByIdQuery,
    GetCatalogProductVariantPricesQuery,
    UpdateCatalogProductVariantPricePayload,
} from "~/domains/transactions/catalog-v1/types/CatalogProductVariantPrice.type"
import { PaginatedOffsetLimitResponse } from "~/types/Pagination"

const BASE_URL = import.meta.env.VITE_API_CATALOG_V1_URL

export const catalogProductVariantPriceApi = createApi({
    reducerPath: "catalogProductVariantPriceApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["CatalogProductPrice"],
    endpoints: (builder) => ({
        getPrices: builder.query<
            PaginatedOffsetLimitResponse<CatalogProductVariantPrice>,
            GetCatalogProductVariantPricesQuery
        >({
            query: ({ organizationId, productId, variantId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/prices`,
            }),
            transformResponse: (response: PaginatedOffsetLimitResponse<CatalogProductVariantPrice>) => ({
                items: response.items.map(priceFromApiAdapters),
                offset: response.offset,
                limit: response.limit,
                totalResults: response.totalResults,
            }),
            providesTags: ["CatalogProductPrice"],
        }),

        getPriceById: builder.query<CatalogProductVariantPrice, GetCatalogProductVariantPriceByIdQuery>({
            query: ({ organizationId, productId, variantId, priceId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/prices/${priceId}`,
            }),
            transformResponse: (response: CatalogProductVariantPrice) => priceFromApiAdapters(response),
            providesTags: ["CatalogProductPrice"],
        }),

        createPrice: builder.mutation<CatalogProductVariantPrice, CreateCatalogProductVariantPricePayload>({
            query: ({ organizationId, productId, variantId, price }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/prices`,
                method: "POST",
                body: price,
            }),
            invalidatesTags: ["CatalogProductPrice"],
        }),

        updatePrice: builder.mutation<CatalogProductVariantPrice, UpdateCatalogProductVariantPricePayload>({
            query: ({ organizationId, productId, variantId, priceId, price }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/prices/${priceId}`,
                method: "PATCH",
                body: price,
            }),
            invalidatesTags: ["CatalogProductPrice"],
        }),

        deletePrice: builder.mutation<void, DeleteCatalogProductVariantPricePayload>({
            query: ({ organizationId, productId, variantId, priceId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/prices/${priceId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["CatalogProductPrice"],
        }),
    }),
})

export const {
    useGetPriceByIdQuery,
    useGetPricesQuery,
    useCreatePriceMutation,
    useUpdatePriceMutation,
    useDeletePriceMutation,
} = catalogProductVariantPriceApi
