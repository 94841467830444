import { useEditor } from "~/domains/orchestration/flows/context/editorContext"
import { RunStatus } from "~/domains/orchestration/flows/types"

export const useRunNodeStatus = (slug: string) => {
    const state = useEditor()
    const { run } = state
    const isLastNode = run?.state.pathTaken[run?.state.pathTaken.length - 1] === slug
    const isError = run?.status === RunStatus.FAILED
    const isWarning = run?.status === RunStatus.SUSPENDED || run?.status === RunStatus.CANCELED
    const isInPath = Boolean(run?.state.pathTaken.find((s) => s === slug))
    const isSuccess = run?.status === RunStatus.FINISHED
    const isExplorer = Boolean(run)

    return {
        isExplorer,
        isLastNode,
        isError,
        isWarning,
        isInPath,
        isSuccess,
    }
}
