import { MentionI, MessageI } from "~/domains/communication/chat/types/Message"
import { OrganizationId } from "~/types"

export enum ActionPlanStatus {
    COMPLETE = 0,
    OPEN = 1,
    OVERDUE = 2,
}

export type MentionInActionPlanI = {
    mention: MentionI
    status: ActionPlanStatus
}

export type ActionPlanI = {
    title: string | null
    type: string | null
    status: ActionPlanStatus
    actionPlanCheckedItems: number
    actionsPlanItems: number
    involvedPeople: MentionInActionPlanI[]
}

export type CommunicationChannelI = {
    parterId: OrganizationId
    partnerName?: string
    createdAt: string
    lastUpdateAt: string
    messages: MessageI[]
    actionPlans: ActionPlanI[]
}
