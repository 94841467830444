import { withSocketIOProvider } from "~/domains/_shared/subscriptions/components/SocketIOContext"
import { CommunicationRoom } from "~/domains/communication/chat/components/CommunicationRoom/CommunicationRoom"
import { InvoiceI } from "~/types"
import { SharedObjectType } from "~/types/SharedObjects"

interface InvoiceCommunicationProps extends JSX.IntrinsicAttributes {
    invoice: InvoiceI
}
export const InvoiceCommunication = withSocketIOProvider<InvoiceCommunicationProps>(({ invoice }) => {
    return (
        <CommunicationRoom
            organizationId={invoice.payerId}
            objectId={invoice.id}
            objectType={SharedObjectType.Invoice}
            otherOrganizations={[invoice.sellerId]}
        />
    )
})
