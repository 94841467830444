import { Stack, Tooltip } from "@mui/material"
import React from "react"
import { Check, X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { Button } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useApprovalReview } from "~/domains/orchestration/flows-v0/hooks"
import { useProcessBulkReviewItems } from "~/domains/orchestration/flows-v0/hooks/useProcessBulkReviewItems"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types"
import { usePurchaseRequestValidationActions } from "~/domains/transactions/purchase-requests/store/hooks"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

const messages = defineMessages({
    validate: {
        id: "purchase.requests.request.validate",
        defaultMessage: "Validate",
    },
    refuse: {
        id: "purchase.requests.request.reject",
        defaultMessage: "Refuse",
    },
    checkDone: {
        id: "purchase.requests.checkDone",
        defaultMessage: "Check done {checkDone}/{totalChecks}",
    },
    waitingForApproval: {
        id: "purchase.requests.waitingForOtherApproval",
        defaultMessage: "Waiting for {peopleNames} approval",
    },
})

interface Props {
    purchaseRequest: PurchaseRequestDetails
}

export const ApproveAllLines: React.FC<Props> = ({ purchaseRequest }) => {
    const { formatMessage } = useIntl()
    const { currentUserCanValidate, currentUserApproval, approve, refuse } =
        usePurchaseRequestValidationActions(purchaseRequest)

    const { isApprovalRequired } = useApprovalReview({
        organisationId: purchaseRequest.organizationId,
        objectId: purchaseRequest.id,
        objectType: ApprovalObjectType.PURCHASE_REQUEST,
    })

    const approvePermission = purchaseRequest.permissions?.approve
    const canApprove = (approvePermission && typeof approvePermission !== "undefined") ?? false
    const permissionLabel = !canApprove ? formatMessage(permissionMessages.errorNoAccessAdministrator) : ""

    const { processBulkReviewItems } = useProcessBulkReviewItems()

    const handleReview = async (isApprove: boolean) => {
        if (purchaseRequest) {
            await processBulkReviewItems(
                purchaseRequest.lines.map((line) => line.id || ""),
                isApprove,
                ApprovalObjectType.PURCHASE_REQUEST_LINE
            )
        }

        if (isApprove && approve && canApprove) {
            approve()
        } else if (!isApprove && refuse && canApprove) {
            refuse()
        }
    }

    const handleRefuse = () => {
        handleReview(false)
    }

    const handleApprove = () => {
        handleReview(true)
    }

    if (!currentUserCanValidate || isApprovalRequired || purchaseRequest.status !== PurchaseRequestStatus.SUBMITTED)
        return null

    return (
        <>
            <Stack gap={1} direction="row" alignItems="center" justifyContent="flex-end">
                <Tooltip arrow title={permissionLabel}>
                    <Button
                        type="error-light"
                        onClick={handleRefuse}
                        disabled={!canApprove || currentUserApproval === false}
                        disableDelay={0}
                    >
                        <SafeFormattedMessage {...messages.refuse} />
                        <X size={16} />
                    </Button>
                </Tooltip>
                <Tooltip arrow title={permissionLabel}>
                    <Button
                        type="primary-light"
                        onClick={handleApprove}
                        disabled={!canApprove || currentUserApproval === true}
                        disableDelay={0}
                    >
                        <SafeFormattedMessage {...messages.validate} />
                        <Check size={16} />
                    </Button>
                </Tooltip>
            </Stack>
        </>
    )
}
