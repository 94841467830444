import { Stack } from "@mui/material"
import classNames from "classnames"
import { useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"

import { SafeFormattedMessage } from "~/components/SafeFormattedMessage/SafeFormattedMessage"
import { SideBarMenuItemI } from "~/components/Sidebar/sideBarMenuItems"
import { TooltipConditional } from "~/components/Tooltip/Tooltip"
import { selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

interface SideBarMenuItemProps {
    menuItem: SideBarMenuItemI
    routeDefault?: string
    disabled: boolean
}

export const SideBarMenuItem = ({ menuItem, routeDefault, disabled }: SideBarMenuItemProps) => {
    const { formatMessage } = useIntl()
    const location = useLocation()

    const sideBarOpened = useAppSelector(selectKeepSidebarOpened)

    const active = location.pathname.startsWith(routeDefault || menuItem.route)

    const to = disabled ? "#" : menuItem.route

    return (
        <li className={classNames({ active, disabled })}>
            <TooltipConditional
                condition={!sideBarOpened}
                type="primary"
                title={formatMessage(menuItem.message)}
                placement="right"
                arrow
            >
                <Link to={to}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <menuItem.icon size={18} />
                        {sideBarOpened && <SafeFormattedMessage {...menuItem.message} />}
                    </Stack>
                </Link>
            </TooltipConditional>
        </li>
    )
}
