import { customOnboardingIds } from "~/domains/identity/custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"

interface CustomRules {
    hasOnlySupplierCreation: boolean
    hasContactCreation: boolean
    hasCustomFields: boolean
    hasPartnershipAttributes: boolean
}

const DEFAULT_CUSTOM_RULES: CustomRules = {
    hasOnlySupplierCreation: false,
    hasContactCreation: false,
    hasCustomFields: false,
    hasPartnershipAttributes: true,
}

export const customRulesListByOrganizationId: Record<string, Partial<CustomRules>> = {
    ...Object.fromEntries(
        customOnboardingIds.map((id) => [
            id,
            {
                hasOnlySupplierCreation: true,
                hasContactCreation: true,
                hasCustomFields: true,
                hasPartnershipAttributes: false,
            },
        ])
    ),
}

export const getCustomRules = (organizationId: string): CustomRules => {
    const orgSpecificRules = customRulesListByOrganizationId[organizationId] || {}
    return { ...DEFAULT_CUSTOM_RULES, ...orgSpecificRules }
}
