import axiosClient from "~/api/axiosClient"
import { AttachmentStatus, OcrDocumentId, ReadStatus } from "~/domains/_shared/ocr/types"
import {
    AttachementI,
    AttachementId,
    PaginatedReceivedEmailsI,
    parseAttachment,
    parsePaginatedReceivedEmails,
} from "~/domains/_shared/ocr/types/ReceivedEmail"
import { ContractResultI } from "~/domains/identity/documents/types/ContractResult"
import { parseCreateInvoiceResponse } from "~/domains/transactions/invoices/types/InvoiceParsers"
import { AttachmentI, CreateInvoiceResponseI, InvoiceUserType, NewOcrInvoiceI, OrganizationId } from "~/types"
import { Result, ResultError, getResultErrorValue, isResultError } from "~/types/Result"
import { PaginationQuery } from "~/utils"
import { FetchError, FetchErrorType } from "~/utils/apiClient/errors"

const OCR_BASE_URL = import.meta.env.VITE_API_OCR_URL

export interface UploadOCRResultI {
    invoice?: CreateInvoiceResponseI
    rawDocument?: unknown
    images?: string[]
}

export interface NewUploadOCRResultI {
    id: OcrDocumentId
    content?: Record<string, any>
}
export interface UploadOCRResultV2I {
    jobId: OcrDocumentId
}

export const ocrApi = {
    getAttachmentLink(fileId: string) {
        return `${OCR_BASE_URL}resources/attachment/${fileId}`
    },
    async createInvoiceFromOcrData(
        initiator: InvoiceUserType,
        ocrInvoice: NewOcrInvoiceI
    ): Promise<CreateInvoiceResponseI> {
        const response: Record<string | number | symbol, any> = await axiosClient.post(
            `${OCR_BASE_URL}invoice/create-from-ocr?initiator=${initiator}`,
            ocrInvoice
        )
        return parseCreateInvoiceResponse({
            ...response,
            invoice: {
                ...response?.invoice,
                id: ocrInvoice.id ?? "",
            },
        })
    },
    async newUploadInvoice(
        file: File,
        initiator: InvoiceUserType,
        organizationId?: OrganizationId
    ): Promise<NewUploadOCRResultI> {
        const formData = new FormData()
        formData.append("file", file)
        const response: NewUploadOCRResultI = await axiosClient.post(
            `${OCR_BASE_URL}upload-invoice?initiator=${initiator}${
                organizationId ? `&organizationId=${organizationId}` : ""
            }`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        return response
    },
    async newUploadv2(
        file: File,
        initiator: InvoiceUserType,
        organizationId?: OrganizationId
    ): Promise<UploadOCRResultV2I> {
        const formData = new FormData()
        formData.append("file", file)
        const response: UploadOCRResultV2I = await axiosClient.post(
            `${OCR_BASE_URL}v2/upload-invoice?initiator=${initiator}${
                organizationId ? `&organizationId=${organizationId}` : ""
            }`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        return response
    },
    async uploadPaymentMethod(file: File, organizationId?: OrganizationId): Promise<UploadOCRResultV2I> {
        const r = await this.newUploadv2(file, InvoiceUserType.BUYER, organizationId)

        return r

        /* const formData = new FormData()
        formData.append("file", file)
        formData.append("organizationId", organizationId ?? "")

        const result: NewUploadOCRResultI = await axiosClient.post(
            `${OCR_BASE_URL}upload-payment-method-details${organizationId ? `&organizationId=${organizationId}` : ""}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )

        const bankTransfer: BankTransferPaymentMethod = result?.content?.bankTransfer || {
            payment_method_type: PaymentMethodType.BANK_TRANSFER,
            bank_account_number: "123",
            bank_name: "123",
            iban: "123",
            bic_swift: "123",
            holder_name: "123",
            id: "123",
            owner: "123",
            payment_method_id: "123",
            country: null,
            currency: null,
            status: PaymentMethodDetailsStatus.PENDING,
        }

        const response: NewUploadOCRResultI = {
            id: "123",
            content: {
                bankTransfer: mapToEdit(bankTransfer),
            },
        }
        return response */
    },
    async uploadContract(fileUrl: string): Promise<ContractResultI> {
        const response = await axiosClient.post<ContractResultI>(`${OCR_BASE_URL}upload-contract`, { fileUrl })
        return response as unknown as ContractResultI
    },

    async fetchOrganizationEmails(
        organizationId: OrganizationId,
        query?: PaginationQuery,
        read?: ReadStatus | undefined,
        attachments?: AttachmentStatus | undefined
    ): Promise<Result<PaginatedReceivedEmailsI, FetchError<PaginatedReceivedEmailsI>>> {
        const url = `${OCR_BASE_URL}inbox/organization/${organizationId}${query || read || attachments ? "?" : ""}${
            query ? `${new URLSearchParams(query as Record<string, string>).toString()}&` : ""
        }${read ? `read=${read}&` : ""}${attachments ? `attachments=${attachments}&` : ""}`

        try {
            const response = await axiosClient.get(url)
            const result = parsePaginatedReceivedEmails(response)
            if (isResultError(result)) {
                return ResultError({
                    type: FetchErrorType.PARSING_ERROR,
                    partialResults: [],
                    errors: [getResultErrorValue(result)],
                })
            }
            return result
        } catch {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `An unexpected error occurred`,
            })
        }
    },

    async relaunchAttachmentProcessing(
        organizationId: OrganizationId,
        attachmentId: AttachementId
    ): Promise<Result<AttachementI, FetchError<AttachmentI>>> {
        const url = `${OCR_BASE_URL}inbox/organization/${organizationId}/attachment/${attachmentId}/relaunch`
        try {
            const response = await axiosClient.post(url)
            const result = parseAttachment(response)

            if (isResultError(result)) {
                return ResultError({
                    type: FetchErrorType.PARSING_ERROR,
                    partialResults: [],
                    errors: [getResultErrorValue(result)],
                })
            }
            return result
        } catch (error) {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `${error}`,
            })
        }
    },

    async markReceivedEmailAsRead(organizationId: OrganizationId, emailId: string): Promise<boolean> {
        const url = `${OCR_BASE_URL}inbox/organization/${organizationId}/emails/${emailId}/read`
        return await axiosClient.post(url)
    },
}
