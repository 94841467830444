import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { validate as validateUUID } from "uuid"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { PAGE_SIZE } from "~/constants/pagination"
import { invoiceAnalyticsFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/invoiceAnalyticsFromApiAdapter"
import { invoiceFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/invoiceFromApiAdapter"
import {
    invoiceToApiAdapter,
    lineItemToApiAdapter,
} from "~/domains/transactions/invoices-v1/api/adapters/invoiceToApiAdapter"
import {
    GetInvoicesAnalyticsQuery,
    GetInvoicesByIdsQuery,
    GetInvoicesQuery,
} from "~/domains/transactions/invoices-v1/api/types/invoiceApi.type"
import { InvoiceAPI, InvoiceAnalyticsAPI } from "~/domains/transactions/invoices-v1/types/Invoice"
import { InvoiceLineItemPayload, InvoiceV1Payload } from "~/domains/transactions/invoices-v1/types/InvoicePayload"
import { InvoiceI, InvoiceLineI } from "~/types"
import { PaginatedCursorResponse } from "~/types/Pagination"

const BASE_OLD_URL = import.meta.env.VITE_API_INVOICES_URL + "v1"
const BASE_URL = import.meta.env.VITE_API_TRANSACTION_V2_INVOICES_URL + "invoices"
const BASE_ANALYTICS_URL = import.meta.env.VITE_API_TRANSACTION_V2_INVOICES_URL + "analytics/invoices"

export const invoiceApi = createApi({
    reducerPath: "invoiceApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getInvoices: builder.query<PaginatedCursorResponse<InvoiceI>, GetInvoicesQuery>({
            query: ({
                organizationIds,
                cursor,
                pageSize = PAGE_SIZE,
                status = "",
                dueDateBefore,
                dueDateAfter,
                sortBy = "issuedAt",
                ascending = true,
            }) => {
                const params = new URLSearchParams({
                    ...(cursor ? { cursor } : {}),
                    ...(status ? { status } : {}),
                    ...(dueDateBefore ? { dueDateBefore } : {}),
                    ...(dueDateAfter ? { dueDateAfter } : {}),
                    pageSize: pageSize.toString(),
                    sortBy,
                    ascending: ascending.toString(),
                })

                organizationIds?.forEach((id) => params.append("organizationId", id))

                return {
                    url: "",
                    params,
                }
            },
            transformResponse: (response: InvoiceAPI[]) => ({
                items: (response || []).map((invoice) => invoiceFromApiAdapter(invoice)),
            }),
        }),
        getInvoicesByIds: builder.query<InvoiceI[], GetInvoicesByIdsQuery>({
            query: ({ organizationId, ids }) => {
                const uuids = ids.filter((id) => validateUUID(id))
                return {
                    url: "/fetch",
                    method: "POST",
                    body: { organizationId, ids: uuids },
                }
            },
            transformResponse: (response: InvoiceAPI[]) =>
                (response || []).map((invoice) => invoiceFromApiAdapter(invoice)),
        }),
        getInvoicesAnalytics: builder.query<InvoiceAnalyticsAPI, GetInvoicesAnalyticsQuery>({
            query: (body) => {
                return { url: BASE_ANALYTICS_URL, method: "POST", body }
            },
            transformResponse: invoiceAnalyticsFromApiAdapter,
        }),
        getInvoice: builder.query<InvoiceI, string>({
            query: (id: string) => ({ url: `/${id}` }),
            transformResponse: (response: InvoiceAPI) => invoiceFromApiAdapter(response),
        }),
        createInvoice: builder.mutation<InvoiceI, Partial<InvoiceV1Payload>>({
            query: (invoice: Partial<InvoiceV1Payload>) => ({
                url: "",
                method: "POST",
                body: invoiceToApiAdapter(invoice),
            }),
            transformResponse: (response: InvoiceAPI) => invoiceFromApiAdapter(response),
            transformErrorResponse: (response: FetchBaseQueryError) => {
                return response.data
            },
        }),
        updateInvoice: builder.mutation({
            query: ({ id, ...invoice }: InvoiceV1Payload) => ({
                url: `/${id}`,
                method: "PUT",
                body: invoiceToApiAdapter(invoice),
            }),
        }),
        deleteInvoice: builder.mutation({
            query: (id: string) => ({ url: `/${id}`, method: "DELETE" }),
        }),
        patchUpdateInvoice: builder.mutation({
            query: ({ id, ...invoice }) => ({
                url: `/${id}`,
                method: "PATCH",
                body: invoiceToApiAdapter(invoice),
            }),
        }),
        downloadInvoices: builder.mutation({
            query: (invoiceIds: string[]) => ({
                url: `${BASE_OLD_URL}/resources/invoice-pdf/batch`,
                method: "POST",
                body: { invoiceIds },
                headers: {
                    Accept: "application/octet-stream",
                },
                responseHandler: async (response) =>
                    window.location.assign(window.URL.createObjectURL(await response.blob())),
            }),
        }),
        createLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, line }: { invoiceId: string; line: InvoiceLineI }) => ({
                url: `/${invoiceId}/line-items`,
                method: "POST",
                body: line,
            }),
        }),
        putUpdateLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, line }: { invoiceId: string; line: InvoiceLineI }) => ({
                url: `/${invoiceId}/line-items/${line.id}`,
                method: "PUT",
                body: line,
            }),
        }),
        deleteLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, id }: { invoiceId: string; id: string }) => ({
                url: `/${invoiceId}/line-items/${id}`,
                method: "DELETE",
            }),
        }),
        patchUpdateLineItemInInvoice: builder.mutation({
            query: ({ invoiceId, line }: { invoiceId: string; line: Partial<InvoiceLineItemPayload> }) => ({
                url: `/${invoiceId}/line-items/${line.id}`,
                method: "PATCH",
                body: lineItemToApiAdapter(line),
            }),
        }),
    }),
})

export const {
    useGetInvoicesQuery,
    useGetInvoicesByIdsQuery,
    useGetInvoicesAnalyticsQuery,
    useGetInvoiceQuery,
    useCreateInvoiceMutation,
    useUpdateInvoiceMutation,
    useDeleteInvoiceMutation,
    usePatchUpdateInvoiceMutation,
    useDownloadInvoicesMutation,
    useCreateLineItemInInvoiceMutation,
    usePutUpdateLineItemInInvoiceMutation,
    useDeleteLineItemInInvoiceMutation,
    usePatchUpdateLineItemInInvoiceMutation,
} = invoiceApi
