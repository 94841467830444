import { Tooltip } from "@mui/material"
import { Trash2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    moveToTrash: { id: "invoice.ocrInvoice.moveToTrash", defaultMessage: "Move to trash" },
    deleteButtonTooltip: {
        id: "buyer.invoice.actions.button.delete.tooltip",
        defaultMessage: "Delete the invoice",
    },
})

interface DeleteInvoiceProps {
    handleDelete?: () => void
    display?: "menu-item" | "button"
}

export const DeleteInvoice = ({ handleDelete, display = "button" }: DeleteInvoiceProps) => {
    const { formatMessage } = useIntl()

    return display === "button" ? (
        <Tooltip title={formatMessage(messages.deleteButtonTooltip)} arrow>
            <div>
                <Button onClick={handleDelete} type="primary-light">
                    <Trash2 size={18} />
                    <SafeFormattedMessage {...messages.moveToTrash} />
                </Button>
            </div>
        </Tooltip>
    ) : (
        <div>
            <Button onClick={handleDelete} type="menu-item">
                <Trash2 size={18} />
                <SafeFormattedMessage {...messages.moveToTrash} />
            </Button>
        </div>
    )
}
