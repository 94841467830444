import { Tooltip } from "@mui/material"
import { Edit2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { PURCHASE_ORDER_EDIT_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"

const messages = defineMessages({
    edit: { id: "purchase.orders.actionsHeader.edit", defaultMessage: "Edit" },
})

interface EditPOProps {
    PO: PurchaseOrders
    display?: "button" | "menu-item"
}

export const EditPO = ({ PO, display = "menu-item" }: EditPOProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const handleEdit = () => {
        navigate(generatePath(PURCHASE_ORDER_EDIT_ROUTE, { purchaseOrderId: PO.id }))
    }

    return display === "button" ? (
        <Tooltip title={formatMessage(commonMessages.edit)}>
            <Button type="menu-item" onClick={handleEdit}>
                <Edit2 size={16} />
            </Button>
        </Tooltip>
    ) : (
        <Button type="menu-item" onClick={handleEdit}>
            <Edit2 size={16} />
            <SafeFormattedMessage {...messages.edit} />
        </Button>
    )
}
