import { Stack } from "@mui/material"
import { DataGridPremium, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-premium"
import { skipToken } from "@reduxjs/toolkit/query"
import React from "react"
import { FormattedDate, defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Card, SafeFormattedMessage } from "~/components"
import { useGetCustomFormQuery } from "~/domains/identity/custom-forms/api/customFormsApi"
import { UserSurveyStats } from "~/domains/identity/custom-forms/types/CustomForms"
import { PAGE_SIZE_OPTIONS } from "~/utils"
import { isDefined } from "~/utils/isDefined"

import { MetricsCell } from "./MetricsCell"
import { ProgressCell } from "./ProgressCell"
import { ResponsePartnerCell } from "./ResponsePartnerCell"

const messages = defineMessages({
    metrics: {
        id: "smartForms.tabHeader.metrics",
        defaultMessage: "Metrics",
    },
    responses: {
        id: "smartForms.tabHeader.responses",
        defaultMessage: "Responses",
    },
    responsesHeader: {
        id: "smartForms.header.responses",
        defaultMessage: "Partner responses",
    },
    columnHeaderScore: {
        id: "smartForms.surveyScore",
        defaultMessage: "Alerting score",
    },
    columnHeaderPartner: {
        id: "smartForms.partner",
        defaultMessage: "Partner",
    },
    columnHeaderProgress: {
        id: "smartForms.progress",
        defaultMessage: "Status",
    },
    columnHeaderLastUpdated: {
        id: "smartForms.lastUpdated",
        defaultMessage: "Last updated",
    },
})

const FormNameCell = ({
    formId,
    formName,
    organisationId,
}: {
    formId: string
    formName?: string
    organisationId?: string
}) => {
    const { data: form } = useGetCustomFormQuery(
        organisationId && formId && !formName ? { organisationId, formId } : skipToken
    )
    return <>{formName || form?.name}</>
}

export const ResponsesDatagrid = ({
    surveys,
    formName,
    organisationId,
    partnerOrganisationId,
}: {
    surveys: UserSurveyStats[]
    formName: string
    organisationId?: string
    partnerOrganisationId?: string
}) => {
    const SURVEY_URL = `${import.meta.env.VITE_APP_URL}custom-forms/survey/`

    const columns: GridColDef[] = [
        {
            flex: 1,
            field: "Form",
            renderHeader: () => <SafeFormattedMessage {...commonMessages.from} />,
            renderCell: (params: GridRenderCellParams<UserSurveyStats>) =>
                FormNameCell({ formId: params.row.formId, formName, organisationId }),
        },
        {
            flex: 1,
            field: "alertScore",
            renderHeader: () => <SafeFormattedMessage {...messages.columnHeaderScore} />,
        },
        {
            flex: 1,
            field: "Metrics",
            renderHeader: () => <SafeFormattedMessage {...messages.metrics} />,
            renderCell: (params: GridRenderCellParams<UserSurveyStats>) => MetricsCell({ metrics: params.row.metrics }),
        },
        {
            flex: 1,
            field: "Progress",
            renderHeader: () => <SafeFormattedMessage {...messages.columnHeaderProgress} />,
            renderCell: ({ row }: GridRenderCellParams<UserSurveyStats>) => ProgressCell({ status: row.status }),
        },
        {
            flex: 1,
            field: "Partner",
            renderHeader: () => <SafeFormattedMessage {...messages.columnHeaderPartner} />,
            renderCell: (params: GridRenderCellParams<UserSurveyStats>) => {
                if (!params.row.respondentOrganizationId) {
                    return null
                }
                return ResponsePartnerCell({
                    partnerOrganisationId: params.row.respondentOrganizationId,
                    email: params.row.respondentContacts[0]?.email,
                })
            },
        },
        {
            flex: 1,
            field: "createdAt",
            renderHeader: () => <SafeFormattedMessage {...commonMessages.createdDate} />,
            renderCell: (params: GridRenderCellParams<UserSurveyStats>) => (
                <FormattedDate value={params.row.createdAt} />
            ),
        },
        {
            flex: 1,
            field: "updatedAt",
            renderHeader: () => <SafeFormattedMessage {...messages.columnHeaderLastUpdated} />,
            renderCell: (params: GridRenderCellParams<UserSurveyStats>) => (
                <FormattedDate value={params.row.updatedAt} />
            ),
        },
    ]

    const handleRowClick = (params: GridRowParams<UserSurveyStats>) => {
        window.open(`${SURVEY_URL}${params.row.token}?view=true`, "_blank") // open in view mode
    }

    if (!surveys) {
        return null
    }

    return (
        <Stack marginTop={1} spacing={2} direction="column">
            <h3>
                <SafeFormattedMessage {...messages.responsesHeader} />
            </h3>
            <Card>
                <DataGridPremium
                    onRowClick={handleRowClick}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    columns={columns}
                    rows={surveys}
                    pagination
                    rowCount={surveys.length ?? 0}
                    disableRowSelectionOnClick
                    rowSelection={false}
                    autosizeOnMount
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                Scores: isDefined(partnerOrganisationId),
                                Partner: !isDefined(partnerOrganisationId),
                            },
                        },
                    }}
                />
            </Card>
        </Stack>
    )
}
