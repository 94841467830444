import * as Sentry from "@sentry/react"
import { useCallback } from "react"

import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"

import {
    CreatePurchaseRequestDTO,
    PurchaseRequestDetails,
} from "../../../transactions/purchase-requests/types/PurchaseRequests"
import { useCustomFieldsApi } from "../customFieldsApi"
import { selectPendingCustomFieldValues } from "../store"
import { CreateCustomFieldPayload } from "../types/CreateCustomFieldPayload"

export const useSavePRPendingCustomFieldValues = (organizationId: OrganizationId) => {
    const user = useAppSelector(selectUser)
    const pendingCustomFieldValues = useAppSelector(selectPendingCustomFieldValues)
    const customFieldsApi = useCustomFieldsApi()

    return useCallback(
        async (
            purchaseRequest: PurchaseRequestDetails | CreatePurchaseRequestDTO,
            updatedPurchaseRequest: PurchaseRequestDetails
        ) => {
            try {
                const customFieldsToCreate = updatedPurchaseRequest.lines
                    .map((line, lineIndex) => {
                        const temporaryId = purchaseRequest.lines[lineIndex]?.temporaryId
                        const customFields = temporaryId
                            ? Object.values(pendingCustomFieldValues[temporaryId] ?? {})
                            : null
                        const lineId = line.id
                        if (customFields && lineId) {
                            return customFields.map(
                                (customFieldPayload): CreateCustomFieldPayload => ({
                                    ...customFieldPayload,
                                    objectId: lineId,
                                    contextId: updatedPurchaseRequest.id,
                                })
                            )
                        }
                        return null
                    })
                    .filter(isDefined)
                    .flat()

                if (customFieldsToCreate.length > 0) {
                    await customFieldsApi.batchUpdateCustomFields(customFieldsToCreate)
                }
            } catch (error) {
                const errorMsg = `Error saving pending custom fields values for Purchase Request ${updatedPurchaseRequest.id}:`
                console.error(errorMsg, error)
                Sentry.captureException(error, {
                    tags: { organizationId },
                    extra: { purchaseRequest, updatedPurchaseRequest },
                })
            }
        },
        [pendingCustomFieldValues, customFieldsApi, user, organizationId]
    )
}
