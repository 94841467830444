import { LinearProgress } from "@mui/material"
import { FC } from "react"

import { Loader } from "~/components"
import { DocumentPreview } from "~/components/UploadDocument/Documents/DocumentPreview"
import { useFakeProgress } from "~/utils/hooks"

interface Props {
    pages: string[] | undefined
    loading?: boolean
}

export const PaymentMethodUploadPreview: FC<Props> = ({ pages, loading }) => {
    const { progress } = useFakeProgress({
        initialProgress: 0,
        autoStart: true,
        timeConstant: 15_000,
    })
    const progressBar = <LinearProgress variant="determinate" value={100 * progress} sx={{ width: "100%" }} />
    if (!pages || pages.length === 0) {
        if (loading) return progressBar
        return null
    }

    return (
        <>
            <DocumentPreview url={pages[0]}>{loading && <Loader />}</DocumentPreview>
            {loading && progressBar}
        </>
    )
}
