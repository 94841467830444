import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { BudgetDataI, TransactionType } from "~/domains/transactions/budget/types"

interface BudgetState {
    budgetsData: BudgetDataI[]
    budgetsFilter: string
    budgetDetails: BudgetDataI | null
    transactionsFilter: string
    loading: boolean
    error: string | null
    currentTab: string
    tags: TagObjectRecordI | undefined
    tagsLoading: boolean
}

const budgetState: BudgetState = {
    budgetsData: [],
    budgetsFilter: "",
    budgetDetails: null,
    transactionsFilter: "",
    loading: false,
    error: null,
    currentTab: TransactionType.PURCHASE_REQUEST,
    tags: undefined,
    tagsLoading: false,
}

export default budgetState
