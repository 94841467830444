import { Stack, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { defineMessages } from "react-intl"

import { Button, Card, SafeFormattedMessage } from "~/components"
import { CustomFormQuestion, CustomFormSection } from "~/domains/identity/custom-forms/types/CustomForms"

import { AddSectionModal } from "./EditorAddSectionModal"
import { QuestionModal } from "./QuestionModal"

const messages = defineMessages({
    addSection: {
        id: "smartForms.formEditor.addSection",
        defaultMessage: "Add section",
    },
    sectionTitle: {
        id: "smartForms.formEditor.sectionTitle",
        defaultMessage: "Section title",
    },
    addQuestion: {
        id: "smartForms.formEditor.addQuestion",
        defaultMessage: "Add question",
    },
    questionContent: {
        id: "smartForms.formEditor.questionContent",
        defaultMessage: "Question content",
    },
    fieldType: {
        id: "smartForms.formEditor.fieldType",
        defaultMessage: "Field type",
    },
})

const FORM_WIDTH = 800

export const FormEditor = () => {
    const [sections, setSections] = useState<CustomFormSection[]>([])
    const [addSectionOpen, setAddSectionOpen] = useState(false)
    const [questionModalOpen, setQuestionModalOpen] = useState(false)

    useEffect(() => {
        // TODO: fetch form data

        // WIP: just open the add section modal if there are no sections
        if (sections.length === 0) {
            setAddSectionOpen(true)
        }
    }, [sections.length])

    const handleAddSectionClose = () => {
        setAddSectionOpen(false)
    }

    const handleAddSectionOpen = () => {
        setAddSectionOpen(true)
    }

    const handleCreateSection = (title: string) => {
        setSections((prevSections) => [
            ...prevSections,
            {
                id: "1", // TODO: get real id
                title,
                order: prevSections.length + 1,
                questions: [],
            },
        ])
    }

    const handleCloseAddQuestion = () => {
        setQuestionModalOpen(false)
    }

    const handleCreateQuestion = (question: CustomFormQuestion) => {
        setSections((prevSections) => {
            const newSections = [...prevSections]
            newSections[newSections.length - 1].questions.push(question)
            return newSections
        })
        setQuestionModalOpen(false)
    }

    const renderQuestion = ({ id, questionContent }: CustomFormQuestion) => {
        return (
            <Card key={id} sx={{ backgroundColor: "var(--background-color)", margin: "var(--spacing-md)" }}>
                <Stack>
                    <Typography>{questionContent}</Typography>
                </Stack>
            </Card>
        )
    }

    const handleAddQuestion = () => {
        setQuestionModalOpen(true)
    }

    const renderSection = useCallback(
        ({ section }: { section: CustomFormSection }) => {
            return (
                <>
                    <Card key={section.id}>
                        <Stack direction="row" justifyContent="space-between">
                            <h4>{section.title}</h4>
                            <Button type="tertiary" onClick={handleAddQuestion}>
                                + Add question
                            </Button>
                        </Stack>
                        <Stack>
                            {section.questions.map((question) => {
                                return renderQuestion(question)
                            })}
                        </Stack>
                    </Card>
                    <QuestionModal
                        open={questionModalOpen}
                        close={handleCloseAddQuestion}
                        createQuestion={handleCreateQuestion}
                        exisitngQuestions={section.questions}
                    />
                </>
            )
        },
        [questionModalOpen]
    )

    return (
        <>
            <Stack className="main-box" maxWidth={FORM_WIDTH}>
                <Stack padding={1} direction="row" justifyContent="end">
                    <Button onClick={handleAddSectionOpen}>
                        <SafeFormattedMessage {...messages.addSection} />
                    </Button>
                </Stack>
                {sections.length > 0 ? (
                    <>
                        {sections.map((section) => {
                            return renderSection({ section })
                        })}
                    </>
                ) : (
                    <>This form in empty</>
                )}
            </Stack>
            <AddSectionModal
                open={addSectionOpen}
                close={handleAddSectionClose}
                createSection={handleCreateSection}
            ></AddSectionModal>
        </>
    )
}
