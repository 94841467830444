import * as t from "io-ts"

import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { Opaque } from "~/utils"

export type DocumentRelationId = Opaque<string, { readonly T: unique symbol }>

export const InvoiceRelationIO = t.type({
    relationId: t.string,
    purchaseOrderId: t.string,
})

type InvoiceRelationBaseI = t.TypeOf<typeof InvoiceRelationIO> & {
    relationId: DocumentRelationId
}

export type InvoiceRelationI = InvoiceRelationBaseI & {
    purchaseOrder?: PurchaseOrders
}
