/* eslint-disable max-lines */
import { ChipProps } from "@mui/material"
import { defineMessages } from "react-intl"

export const statusMessages = defineMessages({
    APPROVED: {
        id: "common.status.approved",
        defaultMessage: "Approved",
        color: "success",
    },
    PENDING: {
        id: "common.status.pending",
        defaultMessage: "Pending",
        color: "warning",
    },
    PENDING_VERIFICATION: {
        id: "common.status.pendingVerification",
        defaultMessage: "Pending Verification",
        color: "warning",
    },
    REJECTED: {
        id: "common.status.rejected",
        defaultMessage: "Rejected",
        color: "error",
    },
    ACTIVE: {
        id: "common.status.active",
        defaultMessage: "Active",
        color: "success",
    },
    INACTIVE: {
        id: "common.status.inactive",
        defaultMessage: "Inactive",
        color: "error",
    },
    BLOCKLISTED: {
        id: "common.status.blocklisted",
        defaultMessage: "Blocklisted",
        color: "warning",
    },
    PROCESSED: {
        id: "common.status.processed",
        defaultMessage: "Processed",
        color: "success",
    },
    NOTHING_TO_PROCESS: {
        id: "common.status.nothingToProcess",
        defaultMessage: "Nothing to Process",
        color: "default",
    },
    FAILED: {
        id: "common.status.failed",
        defaultMessage: "Failed",
        color: "error",
    },
    UNKNOWN: {
        id: "common.status.unknown",
        defaultMessage: "Unknown",
        color: "default",
    },
    NO_ATTACHMENT: {
        id: "common.status.noAttachment",
        defaultMessage: "No Attachment",
        color: "default",
    },
    SUCCESSFUL: {
        id: "common.status.successful",
        defaultMessage: "Successful",
        color: "success",
    },
    UNCHANGED: {
        id: "common.status.unchanged",
        defaultMessage: "Unchanged",
        color: "default",
    },
    SAVING: {
        id: "common.status.saving",
        defaultMessage: "Saving",
        color: "warning",
    },
    SAVED: {
        id: "common.status.saved",
        defaultMessage: "Saved",
        color: "success",
    },
    INITIATED: {
        id: "common.status.initiated",
        defaultMessage: "Initiated",
        color: "primary",
    },
    VALID: {
        id: "common.status.valid",
        defaultMessage: "Valid",
        color: "primary",
    },
    VALIDATED: {
        id: "common.status.validated",
        defaultMessage: "Validated",
        color: "success",
    },
    SCHEDULED: {
        id: "common.status.scheduled",
        defaultMessage: "Scheduled",
        color: "warning",
    },
    EXPIRED: {
        id: "common.status.expired",
        defaultMessage: "Expired",
        color: "error",
    },
    EXECUTED: {
        id: "common.status.executed",
        defaultMessage: "Executed",
        color: "success",
    },
    CANCELLED: {
        id: "common.status.cancelled",
        defaultMessage: "Cancelled",
        color: "default",
    },
    CONVERTED: {
        id: "common.status.converted",
        defaultMessage: "Converted",
        color: "success",
    },
    IN_RECU: {
        id: "common.status.inRecu",
        defaultMessage: "Received",
        color: "success",
    },
    NOT_PROCESSABLE: {
        id: "common.status.notProcessable",
        defaultMessage: "Not Processable",
        color: "warning",
    },
    DRAFT: {
        id: "common.status.draft",
        defaultMessage: "Draft",
        color: "default",
    },
    SUBMITTED: {
        id: "common.status.submitted",
        defaultMessage: "Submitted",
        color: "warning",
    },
    OPEN: {
        id: "common.status.open",
        defaultMessage: "Open",
        color: "success",
    },
    CLOSED: {
        id: "common.status.closed",
        defaultMessage: "Closed",
    },
    DELETED: {
        id: "common.status.deleted",
        defaultMessage: "Deleted",
        color: "error",
    },
    SUSPENDED: {
        id: "common.status.suspended",
        defaultMessage: "Suspended",
        color: "warning",
    },
    DUPLICATES: {
        id: "common.status.duplicates",
        defaultMessage: "Duplicates",
        color: "warning",
    },
    CONFIRMED: {
        id: "common.status.confirmed",
        defaultMessage: "Confirmed",
        color: "primary",
    },
    INFO_REQUESTED: {
        id: "common.status.infoRequested",
        defaultMessage: "Info Requested",
        color: "error",
    },
    PAYMENT_SCHEDULED: {
        id: "common.status.paymentScheduled",
        defaultMessage: "Payment Scheduled",
        color: "primary",
    },
    MARKED_AS_PAID: {
        id: "common.status.markedAsPaid",
        defaultMessage: "Marked as Paid",
        color: "success",
    },
    PAID: {
        id: "common.status.paid",
        defaultMessage: "Paid",
        color: "success",
    },
    DRAFT_supplier: {
        id: "common.status.draftSupplier",
        defaultMessage: "Draft (Supplier)",
        color: "default",
    },
    CONFIRMED_supplier: {
        id: "common.status.confirmedSupplier",
        defaultMessage: "Confirmed (Supplier)",
        color: "primary",
    },
    INFO_REQUESTED_supplier: {
        id: "common.status.infoRequestedSupplier",
        defaultMessage: "Info Requested (Supplier)",
        color: "error",
    },
    VALIDATED_supplier: {
        id: "common.status.validatedSupplier",
        defaultMessage: "Validated (Supplier)",
        color: "success",
    },
    PAYMENT_SCHEDULED_supplier: {
        id: "common.status.paymentScheduledSupplier",
        defaultMessage: "Payment Scheduled (Supplier)",
        color: "primary",
    },
    MARKED_AS_PAID_supplier: {
        id: "common.status.markedAsPaidSupplier",
        defaultMessage: "Marked as Paid (Supplier)",
        color: "success",
    },
    PAID_supplier: {
        id: "common.status.paidSupplier",
        defaultMessage: "Paid (Supplier)",
        color: "success",
    },
    REJECTED_supplier: {
        id: "common.status.rejectedSupplier",
        defaultMessage: "Rejected (Supplier)",
        color: "error",
    },
    INTERNALLY_APPROVED: {
        id: "common.status.internallyApproved",
        defaultMessage: "Internally Approved",
        color: "primary",
    },
    SHARED: {
        id: "common.status.shared",
        defaultMessage: "Shared",
        color: "primary",
    },
    MUTUALLY_ACCEPTED: {
        id: "common.status.mutuallyAccepted",
        defaultMessage: "Mutually Accepted",
        color: "success",
    },
    IN_PREPARATION: {
        id: "common.status.inPreparation",
        defaultMessage: "In Preparation",
        color: "warning",
    },
    SHIPPED: {
        id: "common.status.shipped",
        defaultMessage: "Shipped",
        color: "primary",
    },
    RECEIVED: {
        id: "common.status.received",
        defaultMessage: "Received",
        color: "success",
    },
    OUT_FOR_DELIVERY: {
        id: "common.status.outForDelivery",
        defaultMessage: "Out for Delivery",
        color: "primary",
    },
    REQUEST_DELIVERY: {
        id: "common.status.requestDelivery",
        defaultMessage: "Request Delivery",
    },
    DELIVERED: {
        id: "common.status.delivered",
        defaultMessage: "Delivered",
        color: "success",
    },
    PARTIALLY_RECEIVED: {
        id: "common.status.partiallyReceived",
        defaultMessage: "Partially Received",
        color: "success",
    },
    ERROR_STORING: {
        id: "common.status.errorStoring",
        defaultMessage: "Error Storing",
        color: "error",
    },
    SUCCESS: {
        id: "common.status.success",
        defaultMessage: "Success",
        color: "success",
    },
    SENT: {
        id: "common.status.sent",
        defaultMessage: "Sent",
        color: "primary",
    },
    IN_TRAITE_SE_CPP: {
        id: "common.status.chorusExternal.inTraiteSeCpp",
        defaultMessage: "Processed SE CPP",
        color: "success",
    },
    IN_EN_ATTENTE_TRAIT: {
        id: "common.status.chorusExternal.inEnAttenteTrait",
        defaultMessage: "In En Attente Trait",
        color: "warning",
    },
    IN_EN_COURS_TRAITE: {
        id: "common.status.chorusExternal.inEnCoursTrait",
        defaultMessage: "In En Cours Trait",
        color: "primary",
    },
    IN_INCIDENTE: {
        id: "common.status.chorusExternal.inIncidente",
        defaultMessage: "In Incidente",
        color: "warning",
    },
    IN_REJETE: {
        id: "common.status.chorusExternal.inRejete",
        defaultMessage: "In Rejete",
        color: "error",
    },
    IN_EN_ATTENTE_RET: {
        id: "common.status.chorusExternal.inEnAttenteRet",
        defaultMessage: "In En Attente Ret",
        color: "warning",
    },
    IN_INTEGRE: {
        id: "common.status.chorusExternal.inIntegre",
        defaultMessage: "In Integre",
        color: "success",
    },
    IN_DEPOT_PORTAIL_EN_ATTENTE_TRAITEMENT_SE_CPP: {
        id: "common.status.chorusExternal.inDepotPortailEnAttenteTraitementSeCpp",
        defaultMessage: "In Depot Portail En Attente Traitement Se Cpp",
        color: "warning",
    },
    IN_INTEGRE_PARTIEL: {
        id: "common.status.chorusExternal.inIntegrePartiel",
        defaultMessage: "In Integre Partiel",
        color: "warning",
    },
    SUSPENDUE: {
        id: "common.status.chorusExternal.suspendue",
        defaultMessage: "Suspendue",
        color: "warning",
    },
    A_RECYCLER: {
        id: "common.status.chorusExternal.aRecycler",
        defaultMessage: "A Recycler",
        color: "warning",
    },
    COMPLETEE: {
        id: "common.status.chorusExternal.completee",
        defaultMessage: "Completee",
        color: "success",
    },
    COMPTABILISEE: {
        id: "common.status.chorusExternal.comptabilisee",
        defaultMessage: "Comptabilisee",
        color: "success",
    },
    MISE_A_DISPOSITION: {
        id: "common.status.chorusExternal.miseADisposition",
        defaultMessage: "Mise A Disposition",
        color: "primary",
    },
    MISE_A_DISPOSITION_COMPTABLE: {
        id: "common.status.chorusExternal.miseADispositionComptable",
        defaultMessage: "Mise A Disposition Comptable",
        color: "primary",
    },
    MISE_EN_PAIEMENT: {
        id: "common.status.chorusExternal.miseEnPaiement",
        defaultMessage: "Mise En Paiement",
        color: "success",
    },
    REJETEE: {
        id: "common.status.chorusExternal.rejetee",
        defaultMessage: "Rejetee",
        color: "error",
    },
    SERVICE_FAIT: {
        id: "common.status.chorusExternal.serviceFait",
        defaultMessage: "Service Fait",
        color: "success",
    },
    MANDATEE: {
        id: "common.status.chorusExternal.mandatee",
        defaultMessage: "Mandatee",
        color: "success",
    },
    DEPOSEE: {
        id: "common.status.chorusExternal.deposee",
        defaultMessage: "Deposee",
        color: "primary",
    },
    PREFERRED: {
        id: "partners.organization.details.attributes.chip.preferred",
        defaultMessage: "preferred",
        color: "primary",
    },
    VERIFIED: {
        id: "partners.organization.details.attributes.chip.verified",
        defaultMessage: "verified",
        color: "success",
    },
    ALLOW_TO_SEND_PO: {
        id: "partners.organization.details.attributes.chip.allowToSendPO",
        defaultMessage: "authorized",
        color: "success",
    },
    ALLOW_TO_PAY_INVOICE: {
        id: "partners.organization.details.attributes.chip.allowToPayInvoice",
        defaultMessage: "authorized",
        color: "success",
    },
    REFUSED: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.refused",
        defaultMessage: "Refused",
        color: "error",
    },
    IN_REVIEW: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.inReview",
        defaultMessage: "In review",
        color: "warning",
    },
    CLOTURED: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.clotured",
        defaultMessage: "Clotured",
        color: "success",
    },
    EXCLUDED: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.excluded",
        defaultMessage: "Excluded",
        color: "error",
    },
    TO_QUALIFY: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.toQualify",
        defaultMessage: "To qualify",
        color: "warning",
    },
    AUTHORIZED: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.authorized",
        defaultMessage: "Authorized",
        color: "success",
    },
    REFERENCED: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.referenced",
        defaultMessage: "Referenced",
        color: "primary",
    },
    STRATEGIC: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.strategic",
        defaultMessage: "Strategic",
        color: "primary",
    },
    TO_MONITOR: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.toMonitor",
        defaultMessage: "To monitor",
        color: "warning",
    },
    BLOCKED: {
        id: "partners.bookofrelations.createPartnershipCollaborationStatus.blocked",
        defaultMessage: "Blocked",
        color: "error",
    },
})

export type Status = {
    id: string
    defaultMessage: string
    color: ChipProps["color"]
}

export const globalStatus = {
    ...statusMessages,
}
