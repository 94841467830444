import React, { useState } from "react"

import { SavePayloadType } from "~/domains/identity/organization/components/ModalOrganizationSelectorDetails/types"
import { PartnerContactsSelector } from "~/domains/identity/partners/components/PartnerSelectors/PartnerContactsSelector"
import { PartnerProfileContactI } from "~/domains/identity/partners/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

interface OrganizationContactFormProps {
    onSave?: (payload: SavePayloadType) => void
    organizationId?: string
}

export const OrganizationContactForm: React.FC<OrganizationContactFormProps> = ({ onSave, organizationId }) => {
    const [selectedContact, setSelectedContact] = useState<PartnerProfileContactI | null>(null)

    const currentUserOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const handleUpdateContact = (contact: PartnerProfileContactI | null) => {
        if (!onSave) return
        if (!contact) {
            onSave({ contact: { contactName: "", email: "" } })
            return
        }
        setSelectedContact(contact)

        if (contact.id) {
            const contactName = `${contact.lastName || ""} ${contact.firstName || ""}`.trim()
            onSave({ contact: { email: contact.email as string, contactName } })
        } else {
            onSave({ contact: { email: contact.email as string, contactName: "" } })
        }
    }

    return !organizationId ? null : (
        <PartnerContactsSelector
            currentOrganizationId={currentUserOrganizationId}
            partnerId={organizationId}
            selectedContact={selectedContact}
            onContactChange={handleUpdateContact}
        />
    )
}
