import {
    CreateCustomFieldObjectByNameRecord,
    CreateCustomFieldObjectPayload,
} from "~/domains/identity/custom-fields/types/CustomFieldValue"

export const usePendingCustomFieldsByFieldNames = (
    fieldNames: string[],
    pendingCustomFieldObject: CreateCustomFieldObjectByNameRecord
): CreateCustomFieldObjectPayload[] => {
    if (!pendingCustomFieldObject || !fieldNames) {
        return []
    }
    return fieldNames.map((fieldName) => {
        return pendingCustomFieldObject[fieldName]
    })
}
