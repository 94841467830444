import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { commonMessages } from "~/common-messages"
import { productFromApiAdapters } from "~/domains/transactions/catalog-v1/api/adapters/catalogFromApiAdapters"
import { productToApiAdapters } from "~/domains/transactions/catalog-v1/api/adapters/catalogToApiAdapters"
import {
    AddCatalogProductToCatalogPayload,
    CatalogProduct,
    CreateCatalogProductPayload,
    DeleteCatalogProductPayload,
    GetCatalogProductByIdQuery,
    GetCatalogProductsByCatalogIdQuery,
    GetCatalogProductsQuery,
    UpdateCatalogProductPayload,
} from "~/domains/transactions/catalog-v1/types/CatalogProduct.type"
import { PaginatedOffsetLimitResponse } from "~/types/Pagination"

const BASE_URL = import.meta.env.VITE_API_CATALOG_V1_URL

export const catalogProductApi = createApi({
    reducerPath: "catalogProductApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["Search", "CatalogProduct"],
    endpoints: (builder) => ({
        getProducts: builder.query<PaginatedOffsetLimitResponse<CatalogProduct>, GetCatalogProductsQuery>({
            query: ({ organizationId, status, offset, limit }) => ({
                url: `organizations/${organizationId}/products`,
                params: { status, offset, limit },
            }),
            transformResponse: (response: PaginatedOffsetLimitResponse<CatalogProduct>) => ({
                items: response.items.map(productFromApiAdapters),
                offset: response.offset,
                limit: response.limit,
                totalResults: response.totalResults,
            }),
            providesTags: ["CatalogProduct"],
        }),

        getProductsByCatalogId: builder.query<CatalogProduct[], GetCatalogProductsByCatalogIdQuery>({
            query: ({ organizationId, catalogId }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}/products`,
            }),
            transformResponse: (response: CatalogProduct[]) => response.map(productFromApiAdapters),
            providesTags: ["CatalogProduct"],
        }),

        getProductById: builder.query<CatalogProduct, GetCatalogProductByIdQuery>({
            query: ({ organizationId, productId, includeVariants }) => ({
                url: `organizations/${organizationId}/products/${productId}`,
                params: { includeVariants: includeVariants ?? false },
            }),
            transformResponse: (response: CatalogProduct) => productFromApiAdapters(response),
            providesTags: ["CatalogProduct"],
        }),

        createProduct: builder.mutation<CatalogProduct, CreateCatalogProductPayload>({
            query: ({ organizationId, product }) => ({
                url: `organizations/${organizationId}/products`,
                method: "POST",
                body: productToApiAdapters(product),
            }),
            transformResponse: productFromApiAdapters,
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            invalidatesTags: ["CatalogProduct"],
        }),

        updateProduct: builder.mutation<CatalogProduct, UpdateCatalogProductPayload>({
            query: ({ organizationId, productId, product }) => ({
                url: `organizations/${organizationId}/products/${productId}`,
                method: "PATCH",
                body: product,
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            invalidatesTags: ["CatalogProduct"],
        }),

        addProductToCatalog: builder.mutation<CatalogProduct, AddCatalogProductToCatalogPayload>({
            query: ({ organizationId, catalogId, productId }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}/products/${productId}`,
                method: "POST",
            }),
            invalidatesTags: ["CatalogProduct", "Search"],
        }),

        deleteProduct: builder.mutation<void, DeleteCatalogProductPayload>({
            query: ({ organizationId, productId }) => ({
                url: `organizations/${organizationId}/products/${productId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["CatalogProduct", "Search"],
        }),
    }),
})

export const {
    useGetProductsQuery,
    useGetProductsByCatalogIdQuery,
    useGetProductByIdQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useAddProductToCatalogMutation,
    useDeleteProductMutation,
} = catalogProductApi
