import { Stack, Tooltip } from "@mui/material"
import { Dispatch, SetStateAction } from "react"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { ActionsMenu, Button } from "~/components"
import { useHasPermissions } from "~/domains/identity/roles-permissions/store/hooks"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { InvoiceAuditLogs } from "~/domains/transactions/invoices-v1/components/InvoiceAuditLogs"
import { InvoiceLifeCycleNextButton } from "~/domains/transactions/invoices-v1/components/InvoiceLifeCycleNextButton"
import { INVOICE_EDIT_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { EDIT_INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"
import {
    ApproveInvoice,
    DeleteInvoice,
    DownloadPdfInvoice,
    EditInvoice,
    RejectInvoice,
} from "~/domains/transactions/invoices/components/Actions"
import { ResolveAllRequestsInvoice } from "~/domains/transactions/invoices/components/Actions/ResolveAllRequestsInvoice"
import { ValidateInvoice } from "~/domains/transactions/invoices/components/Actions/ValidateInvoice"
import { AuthorizationNameExtra, EventI, InvoiceBackendVersion, InvoiceI, InvoiceStatus, OrganizationId } from "~/types"

const messages = defineMessages({
    request: { id: "buyer.invoice.actions.button.request", defaultMessage: "Request update" },
    validate: { id: "buyer.invoice.actions.button.validate", defaultMessage: "Validate document" },
    removeApproval: { id: "buyer.invoice.actions.button.removeApproval", defaultMessage: "Remove approval" },
    schedule: { id: "buyer.invoice.actions.button.schedule", defaultMessage: "Schedule payment" },
    paymentDone: { id: "buyer.invoice.actions.button.paymentDone", defaultMessage: "Payment's done" },
    requestButtonTooltip: {
        id: "buyer.invoice.actions.button.request.tooltip",
        defaultMessage: "You need more information about this invoice? Send a request to your supplier!",
    },
    unmarkAsPaid: { id: "invoice.ocrInvoice.unmarkAsPaid", defaultMessage: "Unmark as paid" },
})

interface ActionsProps {
    organizationId?: OrganizationId
    invoice: InvoiceI
    status: InvoiceStatus
    handleDownloadPDF: () => void
    handleValidate: () => void
    setDisplayModal: Dispatch<SetStateAction<boolean>>
    handleMoveToTrash?: () => void
    handleUnmarkAsPaid?: () => void
    handleRemoveApproval?: () => void
    events?: EventI[]
    isReviewable?: boolean
}

interface ActionWithoutApproveProps {
    handleApprove?: undefined
    handleReject?: undefined
}

interface ActionApproveProps {
    handleApprove: () => void
    handleReject: () => void
}

type ActionsHeaderProps = ActionsProps & (ActionWithoutApproveProps | ActionApproveProps)

export function ActionsHeader({
    organizationId,
    invoice,
    handleValidate,
    handleApprove,
    handleReject,
    handleMoveToTrash,
    handleRemoveApproval,
    handleUnmarkAsPaid,
    setDisplayModal,
    isReviewable = false,
}: ActionsHeaderProps) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    /* const { status: pennylaneStatus } = useInvoicePennylaneSynchronizationStatus(invoice) */
    const { hasPermission: hasInvoicesApprovePermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationNameExtra.APPROVE,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )

    const status = invoice.status
    const isInvoiceConfirmed = status === InvoiceStatus.CONFIRMED
    const isInvoiceInfoRequested = status === InvoiceStatus.INFO_REQUESTED
    const isInvoiceDraft = status === InvoiceStatus.DRAFT
    /* const isPennylaneSyncFailure = pennylaneStatus?.pennylaneSyncOutcome === PennylaneSyncOutcome.FAILURE */

    const handleEdit = () => {
        if (!invoice) return

        if (invoice.version === InvoiceBackendVersion.V0) {
            navigate(generatePath(EDIT_INVOICE_BUYER_ROUTE, { invoiceId: invoice.id }))
        } else {
            navigate(generatePath(INVOICE_EDIT_ROUTE, { invoiceId: invoice.id }))
        }
    }

    const canDelete =
        invoice.version !== InvoiceBackendVersion.V1 && (isInvoiceDraft || isInvoiceConfirmed || isInvoiceInfoRequested)

    const canRequest = !isInvoiceInfoRequested

    const canRemoveApproval = hasInvoicesApprovePermission && status === InvoiceStatus.VALIDATED

    const canResolveAllRequestsInvoice = invoice.status === InvoiceStatus.INFO_REQUESTED

    const actions = [
        { render: <EditInvoice handleEdit={handleEdit} display="menu-item" /> },
        {
            render: <DownloadPdfInvoice pdfLink={invoice.fileLinks.pdfLink} display="menu-item" />,
        },
        ...(canDelete ? [{ render: <DeleteInvoice handleDelete={handleMoveToTrash} display="menu-item" /> }] : []),
    ]

    return (
        <Stack direction="row" spacing={1}>
            <ActionsMenu isActionIcon={false} actions={actions} />

            {/* TMP Removed */}
            {/* {isPennylaneSyncFailure && isInvoiceConfirmed && ( */}
            {/* )} */}

            {canRemoveApproval && (
                <Tooltip title={formatMessage(messages.removeApproval)} placement="bottom" arrow>
                    <div>
                        <Button
                            onClick={handleRemoveApproval}
                            text={formatMessage(messages.removeApproval)}
                            type="grey"
                        />
                    </div>
                </Tooltip>
            )}

            {canRequest && (
                <Tooltip title={formatMessage(messages.requestButtonTooltip)} arrow>
                    <div>
                        <Button
                            onClick={() => setDisplayModal(true)}
                            text={formatMessage(messages.request)}
                            type="grey"
                        />
                    </div>
                </Tooltip>
            )}

            {status === InvoiceStatus.MARKED_AS_PAID && (
                <Button onClick={handleUnmarkAsPaid} text={formatMessage(messages.unmarkAsPaid)} type="grey" />
            )}

            {canResolveAllRequestsInvoice && <ResolveAllRequestsInvoice invoice={invoice} />}

            {invoice.version !== InvoiceBackendVersion.V1 ? (
                <>
                    {isInvoiceConfirmed || isInvoiceInfoRequested ? (
                        <>
                            {isReviewable ? (
                                <>
                                    <ApproveInvoice handleApprove={handleApprove} />

                                    <RejectInvoice handleReject={handleReject} />
                                </>
                            ) : (
                                hasInvoicesApprovePermission && <ApproveInvoice handleApprove={handleValidate} />
                            )}
                        </>
                    ) : null}
                    {isInvoiceDraft && <ValidateInvoice handleValidate={handleValidate} />}
                </>
            ) : (
                <InvoiceLifeCycleNextButton />
            )}
            <InvoiceAuditLogs invoiceId={invoice.id} version={invoice.version} />
        </Stack>
    )
}
