import { Typography } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Card, ConfirmModal, Loader } from "~/components"
import { SelectDocumentType } from "~/components/UploadDocument/SelectDocumentType"
import { documentTypeMessages } from "~/components/UploadDocument/UploadDocument"
import { DocumentType } from "~/domains/identity/documents/types"
import { ImportingInvoiceI, InvoiceI, InvoiceId, InvoiceStatus, OrganizationId } from "~/types"

import { useUpdateInvoiceType } from "../hooks"

const messages = defineMessages({
    documentType: {
        id: "invoice.ocrInvoice.documentType",
        defaultMessage: "Document type",
    },
    confirmDocumentTypeUpdateTitle: {
        id: "invoice.ocrInvoice.confirmDocumentTypeUpdateTitle",
        defaultMessage: "Are you sure?",
    },
    confirmDocumentTypeUpdateMessage: {
        id: "invoice.ocrInvoice.confirmDocumentTypeUpdateMessage",
        defaultMessage:
            "Modifying the type of a document from an Invoice to a {documentType} will refuse the document and cannot be undone.{br} Are you sure you want to proceed?",
    },
    cannotChangeDocumentTypeToInvoice: {
        id: "invoice.ocrInvoice.cannotChangeDocumentTypeToInvoice",
        defaultMessage:
            "You cannot change the type of this document back to an invoice. Please, upload the document again as an invoice.",
    },
})

interface Props {
    invoiceId: InvoiceId
    invoice: ImportingInvoiceI | InvoiceI | undefined
    organizationId: OrganizationId | undefined
    onlySelector?: boolean
    showLabel?: boolean
    updateData: ((data: Partial<ImportingInvoiceI | InvoiceI>) => void) | ((data: Partial<InvoiceI | InvoiceI>) => void)
}

export const InvoiceTypeSelector: React.FC<Props> = ({
    invoiceId,
    invoice,
    organizationId,
    updateData,
    onlySelector = false,
    showLabel = true,
}) => {
    const { formatMessage } = useIntl()

    const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.INVOICE)
    useEffect(() => {
        if (invoice) {
            const type = invoice.invoiceType as DocumentType
            if (Object.values(DocumentType).includes(type)) {
                setDocumentType(type)
            }
        }
    }, [invoice])

    const [showConfirmDocumentTypeChangeModal, setShowConfirmDocumentTypeChangeModal] = useState<boolean>(false)
    useEffect(() => {
        setShowConfirmDocumentTypeChangeModal(
            documentType !== DocumentType.INVOICE && invoice?.status !== InvoiceStatus.REJECTED
        )
    }, [documentType, invoice?.status, documentType])

    const closeDocumentTypeChangeModal = useCallback(
        (success?: boolean) =>
            !success
                ? setDocumentType(DocumentType.INVOICE)
                : updateData({ status: InvoiceStatus.REJECTED, invoiceType: documentType }),
        [documentType, updateData]
    )
    const { updateInvoiceType, loading: updatingInvoiceType } = useUpdateInvoiceType(invoiceId, organizationId)
    const doUpdateInvoiceType = useCallback(async () => {
        await updateInvoiceType(documentType)
        setShowConfirmDocumentTypeChangeModal(false)
        return true
    }, [updateInvoiceType, documentType])
    const onDocumentTypeChange = useCallback(
        async (value: DocumentType) => {
            if (invoice?.invoiceType !== DocumentType.INVOICE && value === DocumentType.INVOICE) {
                toast.error(formatMessage(messages.cannotChangeDocumentTypeToInvoice))
            } else {
                setDocumentType(value)
                if (invoice?.status === InvoiceStatus.REJECTED && invoice.invoiceType !== DocumentType.INVOICE) {
                    await updateInvoiceType(value)
                    updateData({ status: InvoiceStatus.REJECTED, invoiceType: value })
                }
            }
        },
        [updateData, updateInvoiceType, formatMessage, invoice]
    )

    if (!organizationId) return null

    return onlySelector ? (
        <SelectDocumentType value={documentType} onChange={onDocumentTypeChange} showLabel={showLabel} size="small" />
    ) : (
        <Card title={formatMessage(messages.documentType)} expandable>
            <SelectDocumentType value={documentType} onChange={onDocumentTypeChange} showLabel={showLabel} />

            <ConfirmModal
                open={showConfirmDocumentTypeChangeModal}
                close={closeDocumentTypeChangeModal}
                onConfirm={doUpdateInvoiceType}
                title={formatMessage(messages.confirmDocumentTypeUpdateTitle)}
            >
                {updatingInvoiceType ? (
                    <Loader />
                ) : (
                    <Typography>
                        {formatMessage(messages.confirmDocumentTypeUpdateMessage, {
                            documentType: formatMessage(documentTypeMessages[documentType]),
                            br: <br />,
                        })}
                    </Typography>
                )}
            </ConfirmModal>
        </Card>
    )
}
