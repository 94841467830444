import {
    DataGridPremium,
    DataGridPremiumProps,
    GridInitialState,
    GridPaginationModel,
    GridSortModel,
    GridState,
    useGridApiRef,
} from "@mui/x-data-grid-premium"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { generatePath } from "react-router-dom"

import { Card, Loader } from "~/components"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { globalActions, selectDataGridCommonState, selectDataGridStateByStateKey } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const AUTO_SIZE_OPTIONS = {
    includeHeaders: true,
    includeOutliers: false,
    outliersFactor: 1,
    expand: true,
}

interface DataGridPremiumWithStateProps extends DataGridPremiumProps {
    stateKey: string
    initialState?: GridInitialState
    paginationModel?: GridPaginationModel
    onPaginationModelChange?: (paginationModel: GridPaginationModel) => void
    onSortModelChange?: (sortModel: GridSortModel) => void
}

export const DataGridPremiumWithState = ({
    stateKey,
    initialState = {},
    paginationModel,
    onPaginationModelChange,
    onSortModelChange,
    ...props
}: DataGridPremiumWithStateProps) => {
    const gridRef = useRef<HTMLDivElement>(null)
    const [isApiReady, setIsApiReady] = useState(false)
    const [gridSortModel, setGridSortModel] = useState<GridSortModel>(initialState.sorting?.sortModel || [])

    const dataGridApiRef = useGridApiRef()

    const dispatch = useAppDispatch()
    const gridInitialState = useAppSelector((state) => selectDataGridStateByStateKey(state, stateKey)) || initialState
    const gridCommonState = useAppSelector(selectDataGridCommonState)

    // Save state to Redux store
    useLayoutEffect(() => {
        const saveDataGridState = () => {
            if (!dataGridApiRef?.current?.exportState) return

            const currentState = dataGridApiRef.current.exportState()
            dispatch(globalActions.setDataGridState({ stateKey, state: currentState }))
        }

        window.addEventListener("beforeunload", saveDataGridState)

        return () => {
            window.removeEventListener("beforeunload", saveDataGridState)
            saveDataGridState()
        }
    }, [dataGridApiRef, stateKey, dispatch])

    // Middle click open row in a new tab
    useEffect(() => {
        const handleMiddleClick = (event: MouseEvent) => {
            if (event.button === 1 || event.ctrlKey || event.metaKey) {
                const row = (event.currentTarget as HTMLElement).closest(".MuiDataGrid-row")
                const rowId = row?.getAttribute("data-id")

                if (rowId) {
                    const url = generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: rowId })
                    window.open(url, "_blank")
                }
            }
        }

        const currentGridRef = gridRef.current

        const observer = new MutationObserver(() => {
            if (!currentGridRef) return

            const rows = currentGridRef?.querySelectorAll(".MuiDataGrid-row")
            if (!rows?.length) return

            rows.forEach((row) => {
                row.addEventListener("mousedown", handleMiddleClick as EventListener)
            })
        })

        if (currentGridRef) {
            observer.observe(currentGridRef, {
                childList: true,
                subtree: true,
            })
        }

        return () => {
            observer.disconnect()
            const rows = currentGridRef?.querySelectorAll(".MuiDataGrid-row")
            if (!rows?.length) return

            rows.forEach((row) => {
                row.removeEventListener("mousedown", handleMiddleClick as EventListener)
            })
        }
    }, [])

    // Auto size columns
    const autosizeColumns = useCallback(() => {
        if (!dataGridApiRef?.current?.autosizeColumns) return

        dataGridApiRef.current.autosizeColumns(AUTO_SIZE_OPTIONS)
    }, [dataGridApiRef])

    useLayoutEffect(() => {
        const rafId = window.requestAnimationFrame(autosizeColumns)

        return () => {
            window.cancelAnimationFrame(rafId)
        }
    }, [autosizeColumns])

    const handleSortModelChange = (newSortModel: GridSortModel) => {
        setGridSortModel(newSortModel)
        onSortModelChange?.(newSortModel)
        autosizeColumns()
    }

    const handleStateChange = (newState: GridState) => {
        const newDensity = newState.density.value
        dispatch(globalActions.setDataGridCommonState({ density: newDensity }))
    }

    // Add this effect to track API readiness
    useEffect(() => {
        if (!dataGridApiRef.current) return

        if (gridInitialState.sorting?.sortModel?.length) {
            setGridSortModel(gridInitialState.sorting?.sortModel)
        }
        setIsApiReady(true)
    }, [dataGridApiRef, gridInitialState.sorting?.sortModel])

    // Set initial pagination model
    useEffect(() => {
        const initialPaginationModel = gridInitialState?.pagination?.paginationModel
        const initialPageSize = initialPaginationModel?.pageSize || 0
        const initialPage = initialPaginationModel?.page || 0

        if (
            onPaginationModelChange &&
            initialPaginationModel &&
            (initialPage !== paginationModel?.page || initialPageSize !== paginationModel?.pageSize)
        ) {
            onPaginationModelChange(initialPaginationModel as GridPaginationModel)
        }
        // This should trigger only once at initial render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div ref={gridRef}>
            <Card sx={props.sx}>
                {!isApiReady ? (
                    <Loader />
                ) : (
                    <DataGridPremium
                        {...props}
                        key={gridRef.current?.id}
                        apiRef={dataGridApiRef}
                        initialState={gridInitialState}
                        sortModel={gridSortModel}
                        density={gridCommonState.density}
                        onSortModelChange={handleSortModelChange}
                        onStateChange={handleStateChange}
                        onPaginationModelChange={onPaginationModelChange}
                    />
                )}
            </Card>
        </div>
    )
}
