import { MessageDescriptor } from "react-intl"

import { BatchRecurrenceType } from "~/domains/payment/payment-batches/types"

export const batchRecurrenceTypesMessages: Record<BatchRecurrenceType, MessageDescriptor> = {
    [BatchRecurrenceType.DAILY]: {
        id: "common.recurrenceType.daily",
        defaultMessage: "Daily",
    },
    [BatchRecurrenceType.WEEKLY]: {
        id: "common.recurrenceType.weekly",
        defaultMessage: "Weekly",
    },
    [BatchRecurrenceType.MONTHLY]: {
        id: "common.recurrenceType.monthly",
        defaultMessage: "Monthly",
    },
    [BatchRecurrenceType.QUARTERLY]: {
        id: "common.recurrenceType.quarterly",
        defaultMessage: "Quarterly",
    },
    [BatchRecurrenceType.YEARLY]: {
        id: "common.recurrenceType.yearly",
        defaultMessage: "Yearly",
    },
    [BatchRecurrenceType.CUSTOM]: {
        id: "common.recurrenceType.custom",
        defaultMessage: "Custom",
    },
    [BatchRecurrenceType.ONESHOT]: {
        id: "common.recurrenceType.oneShot",
        defaultMessage: "One-shot",
    },
}
