import { MessageDescriptor } from "react-intl"

export enum SpiceDBObjectType {
    COMPANY = "company",
    ROLE = "role",
    USER = "user",
    TEAM = "team",
    PURCHASE_REQUEST = "purchase_request",
    CATALOG = "catalog",
}

export enum SpiceDBAuthorizationName {
    VIEW = "view",
    READ = "read",
    EDIT = "edit",
    UPDATE = "update",
    CREATE = "create",
    DELETE = "delete",
    APPROVE = "approve",
    CONVERT = "convert",
    OWNER = "owner",
    CHECK = "check",
}

export const SpiceDBSpecialAuthorization: Partial<Record<SpiceDBAuthorizationName, SpiceDBAuthorizationName[]>> = {
    [SpiceDBAuthorizationName.READ]: [SpiceDBAuthorizationName.VIEW],
    [SpiceDBAuthorizationName.EDIT]: [SpiceDBAuthorizationName.VIEW],
    [SpiceDBAuthorizationName.UPDATE]: [SpiceDBAuthorizationName.VIEW, SpiceDBAuthorizationName.EDIT],
    [SpiceDBAuthorizationName.APPROVE]: [SpiceDBAuthorizationName.VIEW],
    [SpiceDBAuthorizationName.CONVERT]: [SpiceDBAuthorizationName.VIEW],
    [SpiceDBAuthorizationName.OWNER]: [
        SpiceDBAuthorizationName.VIEW,
        SpiceDBAuthorizationName.EDIT,
        SpiceDBAuthorizationName.APPROVE,
    ],
    [SpiceDBAuthorizationName.CHECK]: [SpiceDBAuthorizationName.VIEW],
}

export const SpiceDBObjectAuthorizationName: Partial<
    Record<SpiceDBObjectType, Record<string, SpiceDBAuthorizationName[]>>
> = {
    [SpiceDBObjectType.PURCHASE_REQUEST]: {
        authorized: [
            SpiceDBAuthorizationName.VIEW,
            SpiceDBAuthorizationName.EDIT,
            SpiceDBAuthorizationName.APPROVE,
            SpiceDBAuthorizationName.CONVERT,
        ],
        unauthorized: [SpiceDBAuthorizationName.OWNER, SpiceDBAuthorizationName.CHECK],
    },
    [SpiceDBObjectType.CATALOG]: {
        authorized: [SpiceDBAuthorizationName.VIEW, SpiceDBAuthorizationName.CREATE, SpiceDBAuthorizationName.EDIT],
        unauthorized: [],
    },
}

export interface SpiceDBObjectReferenceI {
    objectType: SpiceDBObjectType
    objectId: string
}

interface SpiceDBObjectReferenceWithNameI {
    objectType: SpiceDBObjectType
    objectId: string
    name?: string
    email?: string
    membersNumber?: number
}

export interface SpiceDBPermissionsLiteI extends SpiceDBObjectReferenceWithNameI {
    permissions: SpiceDBAuthorizationName[]
}

export interface SpiceDBSharePermissionsBodyDto {
    objects: SpiceDBObjectReferenceI[]
    authorizations: SpiceDBAuthorizationName[]
    isFirstTimeShare: boolean
    creatorOrganizationId: string
    recepientOrganizationId: string
}

export enum SpiceDBPurchaseRequestPermission {
    VIEWER = "viewer",
    EDITOR = "editor",
    APPROVER = "approver",
    CHECKER = "checker",
    CONVERTER = "converter",
}

export interface AuthzedSharePermissionsBodyDto {
    objects: SpiceDBObjectReferenceI[]
    authorizations: string[]
    isFirstTimeShare: boolean
    creatorOrganizationId: string
    recipientOrganizationId: string
}

export interface SpiceDBWriteRelationshipsResponse {
    writtenAt?: string
}

export const sharedObjectPermissionsMessages: Partial<Record<SpiceDBObjectType, MessageDescriptor>> = {
    [SpiceDBObjectType.PURCHASE_REQUEST]: {
        id: "share.object.modal.purchaseRequest",
        defaultMessage: "this purchase request",
    },
    [SpiceDBObjectType.USER]: {
        id: "share.object.modal.user",
        defaultMessage: "this user",
    },
    [SpiceDBObjectType.TEAM]: {
        id: "share.object.modal.team",
        defaultMessage: "this team",
    },
    [SpiceDBObjectType.CATALOG]: {
        id: "share.object.modal.catalog",
        defaultMessage: "this catalog",
    },
}

export const sharedObjectPermissionsModalMessages: Record<string, MessageDescriptor> = {
    title: { id: "share.object.modal.title", defaultMessage: "Share {objectType}" },
    email: { id: "share.object.modal.email", defaultMessage: "Email" },
    supplierEmail: { id: "share.object.modal.supplierEmail", defaultMessage: "Supplier email" },
    buyerEmail: { id: "share.object.modal.buyerEmail", defaultMessage: "Buyer email" },
    selectOrganization: { id: "share.object.modal.selectOrganization", defaultMessage: "Select company" },
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
    close: { id: "share.object.modal.close", defaultMessage: "Close" },
    share: { id: "share.object.modal.share", defaultMessage: "Share" },
    supplier: {
        id: "purchase.organization.supplier",
        defaultMessage: "Supplier",
    },
    buyer: {
        id: "purchase.organization.buyer",
        defaultMessage: "Buyer",
    },
    confirmDeleteSharing: {
        id: "share.object.modal.confirmDeleteSharing",
        defaultMessage: "Are you sure you want to delete this sharing?",
    },
    confirmDeleteTitle: {
        id: "share.object.modal.deleteSharing",
        defaultMessage: "Delete a sharing",
    },
    confirmRemoveAllPermissionsTitle: {
        id: "share.object.modal.confirmRemoveAllPermissionsTitle",
        defaultMessage: "Remove all permissions",
    },
    confirmRemoveAllPermissions: {
        id: "share.object.modal.confirmRemoveAllPermissions",
        defaultMessage: "Are you sure you want to remove all permissions?",
    },
    can: {
        id: "share.object.modal.can",
        defaultMessage: "Can ",
    },
    membersCount: {
        id: "share.object.modal.membersCount",
        defaultMessage: "{membersCount, plural, =0 {# member} one {# member} other {# members}}",
    },
    sharePermissionsSuccess: {
        id: "share.object.modal.sharePermissionsSuccess",
        defaultMessage:
            "{count, plural, =0 {No permission shared} one {Permission shared} other {Permissions shared}} to {objectType}",
    },
    shareMultiPermissionsSuccess: {
        id: "share.object.modal.shareMultiPermissionsSuccess",
        defaultMessage: "{count, plural, =0 {No permission shared} one {Permission shared} other {Permissions shared}}",
    },
    removePermissionsSuccess: {
        id: "share.object.modal.removePermissionsSuccess",
        defaultMessage:
            "{count, plural, =0 {No permission removed} one {Permission removed} other {Permissions removed}} from {objectType}",
    },
    sharedObjectsText: {
        id: "share.object.modal.sharedObjectsText",
        defaultMessage: "Have access to {objectType}:",
    },
    addMembersOrTeams: {
        id: "share.object.modal.addMembersOrTeams",
        defaultMessage: "Add members or teams",
    },
    members: {
        id: "share.object.modal.members",
        defaultMessage: "Members",
    },
    teams: {
        id: "share.object.modal.teams",
        defaultMessage: "Teams",
    },
    permissions: { id: "share.object.modal.permissions", defaultMessage: "Permissions" },
    removeAllTooltip: {
        id: "share.object.modal.removeAllTooltip",
        defaultMessage:
            "You can remove only permissions you already have. Owner and checker permissions can't be removed here.",
    },
    collaborators: {
        id: "share.object.modal.collaborators",
        defaultMessage: "{count, plural, =0 {no collaborator} one {1 collaborator} other {# collaborators}}",
    },
    createSharingSuccess: {
        id: "share.object.create.success",
        defaultMessage: "{objectType} shared",
    },
    deleteSharingSuccess: {
        id: "share.object.delete.success",
        defaultMessage: "Sharing deleted",
    },
}

export const spiceDBAuthorizationMessages: Record<SpiceDBAuthorizationName, MessageDescriptor> = {
    [SpiceDBAuthorizationName.VIEW]: {
        id: "common.permission.view",
        defaultMessage: "view",
    },
    [SpiceDBAuthorizationName.READ]: {
        id: "common.permission.read",
        defaultMessage: "read",
    },
    [SpiceDBAuthorizationName.EDIT]: {
        id: "common.permission.edit",
        defaultMessage: "edit",
    },
    [SpiceDBAuthorizationName.UPDATE]: {
        id: "common.permission.update",
        defaultMessage: "update",
    },
    [SpiceDBAuthorizationName.CREATE]: {
        id: "common.permission.create",
        defaultMessage: "create",
    },
    [SpiceDBAuthorizationName.DELETE]: {
        id: "common.permission.delete",
        defaultMessage: "delete",
    },
    [SpiceDBAuthorizationName.APPROVE]: {
        id: "common.permission.approve",
        defaultMessage: "approve",
    },
    [SpiceDBAuthorizationName.CONVERT]: {
        id: "common.permission.convert",
        defaultMessage: "convert",
    },
    [SpiceDBAuthorizationName.CHECK]: {
        id: "common.permission.check",
        defaultMessage: "checker",
    },
    [SpiceDBAuthorizationName.OWNER]: {
        id: "common.permission.owner",
        defaultMessage: "owner",
    },
}
