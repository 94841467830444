import {
    FilterOptionsState,
    Input,
    Menu,
    MenuItem,
    PopoverOrigin,
    createFilterOptions,
    useAutocomplete,
} from "@mui/material"
import { FC, SyntheticEvent, useMemo, useRef } from "react"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"
import { Element as SlateElement, Transforms } from "slate"
import { useSlateStatic } from "slate-react"

import { isElementActionPlanTitle } from "~/domains/communication/chat/components/InputMessage/ActionPlan/isElementActionPlanTitle"
import { MessageActionPlanTitle } from "~/domains/communication/chat/types"
import { stopPropagation, stopPropagationAndPreventDefault } from "~/utils"

enum ActionPlanDefaultTypes {
    Environmental = "Environmental",
    Social = "Social",
    Governance = "Governance",
}

const defaultTypesMessages: Record<ActionPlanDefaultTypes, MessageDescriptor> = defineMessages({
    [ActionPlanDefaultTypes.Environmental]: {
        id: "communication.chat.actionPlan.type.environmental",
        defaultMessage: "Environmental",
    },
    [ActionPlanDefaultTypes.Social]: {
        id: "communication.chat.actionPlan.type.social",
        defaultMessage: "Social",
    },
    [ActionPlanDefaultTypes.Governance]: {
        id: "communication.chat.actionPlan.type.governance",
        defaultMessage: "Governance",
    },
})

const DEFAULT_OPTIONS = [
    ActionPlanDefaultTypes.Environmental,
    ActionPlanDefaultTypes.Social,
    ActionPlanDefaultTypes.Governance,
]

const filter = createFilterOptions<string>()

interface ActionPlanTypeProps {
    element: MessageActionPlanTitle
    onClose: () => void
}

const filterOptions = (options: string[], params: FilterOptionsState<string>) => {
    const filtered = filter(options, params)

    const { inputValue } = params
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option)
    if (inputValue !== "" && !isExisting) {
        filtered.push(inputValue)
    }

    return filtered
}

const getOptionLabel = (option: string) => option

const anchorOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "right",
}

const transformOrigin: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "right",
}

export const ActionPlanTypeAutocomplete: FC<ActionPlanTypeProps> = ({ element, onClose }) => {
    const { formatMessage } = useIntl()
    const inputRef = useRef<HTMLDivElement | null>(null)
    const editor = useSlateStatic()

    const options = useMemo(
        () => DEFAULT_OPTIONS.map((option) => formatMessage(defaultTypesMessages[option])),
        [formatMessage]
    )

    const onChange = (_event: SyntheticEvent, newValue: string | null) => {
        Transforms.setNodes(
            editor,
            {
                ...element,
                data: {
                    ...element.data,
                    type: newValue ?? "",
                },
            },
            {
                at: [],
                mode: "all",
                match: (n) => SlateElement.isElement(n) && isElementActionPlanTitle(n) && n.data.id === element.data.id,
            }
        )

        onClose()
    }
    const { getRootProps, getInputProps, getListboxProps, popupOpen, getOptionProps, groupedOptions } = useAutocomplete(
        {
            options,
            defaultValue: element.data.type,
            filterOptions,
            getOptionLabel,
            onChange,
            freeSolo: true,
            autoComplete: true,
            openOnFocus: true,
        }
    )
    const rootProps = getRootProps()
    const inputProps = getInputProps()

    const renderOption = (option: string, index: number) => {
        const { key, ...optionProps } = getOptionProps({ option, index })
        return (
            <MenuItem key={key} {...optionProps}>
                {option}
            </MenuItem>
        )
    }

    return (
        <div
            contentEditable={false}
            onClick={stopPropagation}
            onMouseDown={stopPropagation}
            onMouseUp={stopPropagation}
            onFocus={stopPropagationAndPreventDefault}
            onBlur={stopPropagationAndPreventDefault}
        >
            <div {...rootProps}>
                <Input ref={inputRef} inputProps={inputProps} />
            </div>

            <Menu
                contentEditable={false}
                open={popupOpen}
                anchorEl={inputRef.current}
                autoFocus={false}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                onMouseDown={stopPropagationAndPreventDefault}
                onFocus={stopPropagationAndPreventDefault}
                onBlur={stopPropagationAndPreventDefault}
            >
                {(groupedOptions as string[]).map(renderOption)}
            </Menu>
        </div>
    )
}
