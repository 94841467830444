import { Stack } from "@mui/material"
import { FC } from "react"

import { ActionsMenu } from "~/components"
import { ShareObjectPermissionsButton } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/ShareObjectPermissionsButton"
import { useHasPermissions } from "~/domains/identity/roles-permissions/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { SpiceDBObjectType } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { ApproveAllLines } from "~/domains/orchestration/flows-v0/components/ApprovalBlock"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"
import {
    CancelEditPR,
    ConvertPRtoPO,
    DeletePR,
    EditPR,
    SavePR,
    SumitPR,
} from "~/domains/transactions/purchase-requests/components/Actions"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { AuthorizationName, OrganizationId, SpiceDBPermissionsResult } from "~/types"

interface PropsPRActions {
    PR: PurchaseRequestDetails
    id: string
    status: PurchaseRequestStatus
    organizationId: OrganizationId
    mode: PurchaseViewType
    isConvertedToPO: boolean
    permissions?: SpiceDBPermissionsResult
}

export const ActionsHeader: FC<PropsPRActions> = ({
    PR,
    id,
    status,
    organizationId,
    mode,
    isConvertedToPO,
    permissions,
}) => {
    const { hasPermission: hasPREditPermission } = useHasPermissions(
        organizationId ?? "",
        AuthorizationName.UPDATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_REQUESTS
    )

    const hasSubmitPermission = !!(permissions?.submit && typeof permissions?.submit !== "undefined")
    const hasEditPermission = (permissions?.edit && typeof permissions?.edit !== "undefined") ?? false
    const hasDeletePermission = !!permissions?.delete

    const canEdit = !isConvertedToPO && hasPREditPermission
    const canDelete = (permissions?.delete && typeof permissions?.delete !== "undefined") ?? false
    const canSubmit = status === PurchaseRequestStatus.DRAFT
    const canConvertToPO = !!PR && !isConvertedToPO

    const actions = [
        ...(canDelete
            ? [
                  ...(canEdit
                      ? [{ render: <EditPR id={id} hasPermission={hasEditPermission} display="menu-item" /> }]
                      : []),
                  {
                      render: (
                          <ShareObjectPermissionsButton
                              objectId={id}
                              objectType={SpiceDBObjectType.PURCHASE_REQUEST}
                              organizationId={organizationId}
                              display="menu-item"
                          />
                      ),
                  },
                  {
                      render: (
                          <DeletePR
                              id={id}
                              organizationId={organizationId}
                              hasPermission={hasDeletePermission}
                              display="menu-item"
                          />
                      ),
                  },
              ]
            : []),
    ]

    return (
        <Stack direction="row" gap={1}>
            {mode === PurchaseViewType.VIEW ? (
                <>
                    <ActionsMenu isActionIcon={false} actions={actions} />

                    {canSubmit && (
                        <SumitPR organizationId={organizationId} id={id} hasPermission={hasSubmitPermission} />
                    )}

                    <ApproveAllLines purchaseRequest={PR} />

                    {canConvertToPO && <ConvertPRtoPO PR={PR} />}
                </>
            ) : (
                <>
                    <CancelEditPR id={PR.id} display="button" />
                    <SavePR id={id} organizationId={organizationId} />
                </>
            )}
        </Stack>
    )
}
