import { OrganizationId } from "~/types"
import { Features, isFeatureDisabled } from "~/utils/featureFlag"
import { isFeatureEnabled } from "~/utils/featureFlag"

export const getSidebarFeatureFlags = (currentOrganizationId: OrganizationId | undefined) => {
    const areFlowsEnabled = isFeatureEnabled(Features.Flows, currentOrganizationId)

    const areWorkflowsDisabled = areFlowsEnabled

    const isMetricsEnabled = isFeatureEnabled(Features.Metrics, currentOrganizationId)

    const isInvoiceV1Enabled = isFeatureEnabled(Features.InvoiceV1, currentOrganizationId)
    const isInvoiceV0Enabled = !isFeatureDisabled(Features.InvoiceV0, currentOrganizationId)

    const isDocumentsEnabled = isFeatureEnabled(Features.Documents, currentOrganizationId)

    const isCopilotEnabled = isFeatureEnabled(Features.ChatBot, currentOrganizationId)
    const isCustomFormsEnabled = isFeatureEnabled(Features.CustomForms, currentOrganizationId)
    const isCatalogV1Enabled = isFeatureEnabled(Features.Catalog, currentOrganizationId)
    const isCommunicationPageEnabled = isFeatureEnabled(Features.CommunicationPage, currentOrganizationId)
    const isNewPaymentEnabled = isFeatureEnabled(Features.Payment, currentOrganizationId)

    return {
        areFlowsEnabled,
        areWorkflowsDisabled,
        isMetricsEnabled,
        isInvoiceV1Enabled,
        isInvoiceV0Enabled,
        isDocumentsEnabled,
        isCopilotEnabled,
        isCustomFormsEnabled,
        isCatalogV1Enabled,
        isCommunicationPageEnabled,
        isNewPaymentEnabled,
    }
}
