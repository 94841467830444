import { Box, Stack } from "@mui/material"
import { X } from "react-feather"
import { defineMessages } from "react-intl"

import { Button, Modal, SafeFormattedMessage, Size } from "~/components"
import { PaymentBatchAddEditForm } from "~/domains/payment/payment-batches/components"
import { usePaymentBatchesManagement } from "~/domains/payment/payment-batches/hooks"
import { CreatePaymentBatch, PaymentBatch } from "~/domains/payment/payment-batches/types"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    addTitle: {
        id: "payment.batches.addTitle",
        defaultMessage: "Add payment batch",
    },
    editTitle: {
        id: "payment.batches.editTitle",
        defaultMessage: "Edit payment batch",
    },
})

interface PaymentBatchAddAndEditModalProps {
    selectedItem?: PaymentBatch | undefined
    open: boolean
    onClose: () => void
}

export const PaymentBatchAddAndEditModal = ({ selectedItem, open, onClose }: PaymentBatchAddAndEditModalProps) => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const userId = useAppSelector(selectUserId)
    const { isFetching, createPaymentBatch, updatePaymentBatch } = usePaymentBatchesManagement(
        { organizationId },
        { skipList: true }
    )

    const handleClose = () => {
        onClose?.()
    }

    const handleSave = async (formData: CreatePaymentBatch, resetForm: () => void) => {
        if (!organizationId) return

        const payload = {
            ...formData,
            ownerId: organizationId,
            creatorUserId: userId,
            ...(selectedItem?.id ? { id: selectedItem.id } : {}),
        }
        const success = selectedItem?.id ? await updatePaymentBatch(payload) : await createPaymentBatch(payload)

        if (!success) return

        onClose?.()
        resetForm()
    }

    const handleCancel = (resetForm: () => void) => {
        onClose?.()
        resetForm()
    }

    return (
        <Modal size={Size.MD} open={open} onClose={handleClose}>
            <Modal.Header>
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    <Box component="h4">
                        <SafeFormattedMessage {...(selectedItem ? messages.editTitle : messages.addTitle)} />
                    </Box>
                    <Button type="title" onClick={handleClose}>
                        <X size={24} color="var(--color-grey-light)" />
                    </Button>
                </Stack>
            </Modal.Header>
            <Modal.Content>
                <Stack gap={2}>
                    <PaymentBatchAddEditForm
                        selectedItem={selectedItem}
                        organizationId={organizationId as string}
                        loading={isFetching}
                        onCancel={handleCancel}
                        onSubmit={handleSave}
                    />
                </Stack>
            </Modal.Content>
        </Modal>
    )
}
