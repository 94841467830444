import { Autocomplete, MenuItem, Stack, TextField } from "@mui/material"
import { FC, useState } from "react"
import { X } from "react-feather"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { Button } from "~/components/Button/Button"
import { useApprovalReview } from "~/domains/orchestration/flows-v0/hooks/useApprovalReview"
import {
    ApprovalObjectType,
    RefusalReasonBody,
    RefusalReason as RefusalReasonType,
} from "~/domains/orchestration/flows-v0/types"

import { messages } from "./ApprovalBlock"

export const refusalMessages: Record<RefusalReasonType, MessageDescriptor> = defineMessages({
    [RefusalReasonType.INCORRECT_INFO]: {
        id: "refusalReason.INCORRECT_INFO",
        defaultMessage: "Incorrect Info",
    },
    [RefusalReasonType.BUDGET_EXCEEDED]: {
        id: "refusalReason.BUDGET_EXCEEDED",
        defaultMessage: "Budget Exceeded",
    },
    [RefusalReasonType.UNAUTHORIZED_REQUEST]: {
        id: "refusalReason.UNAUTHORIZED_REQUEST",
        defaultMessage: "Unauthorized Request",
    },
    [RefusalReasonType.DUPLICATE_SUBMISSION]: {
        id: "refusalReason.DUPLICATE_SUBMISSION",
        defaultMessage: "Duplicate Submission",
    },
    [RefusalReasonType.COMPLIANCE_VIOLATION]: {
        id: "refusalReason.COMPLIANCE_VIOLATION",
        defaultMessage: "Compliance Violation",
    },
    [RefusalReasonType.EXPIRED_REQUEST]: {
        id: "refusalReason.EXPIRED_REQUEST",
        defaultMessage: "Expired Request",
    },
    [RefusalReasonType.INVALID_PAYMENT_TERMS]: {
        id: "refusalReason.INVALID_PAYMENT_TERMS",
        defaultMessage: "Invalid Payment Terms",
    },
    [RefusalReasonType.INCOMPLETE_PROFILE]: {
        id: "refusalReason.INCOMPLETE_PROFILE",
        defaultMessage: "Incomplete Profile",
    },
    [RefusalReasonType.FAILED_CHECK]: {
        id: "refusalReason.FAILED_CHECK",
        defaultMessage: "Failed Check",
    },
    [RefusalReasonType.PENDING_PREVIOUS_OBLIGATIONS]: {
        id: "refusalReason.PENDING_PREVIOUS_OBLIGATIONS",
        defaultMessage: "Pending Previous Obligations",
    },
    [RefusalReasonType.HIGH_RISK_ASSESSMENT]: {
        id: "refusalReason.HIGH_RISK_ASSESSMENT",
        defaultMessage: "High Risk Assessment",
    },
    [RefusalReasonType.GEOGRAPHICAL_RESTRICTION]: {
        id: "refusalReason.GEOGRAPHICAL_RESTRICTION",
        defaultMessage: "Geographical Restriction",
    },
    [RefusalReasonType.SYSTEM_VALIDATION_FAILURE]: {
        id: "refusalReason.SYSTEM_VALIDATION_FAILURE",
        defaultMessage: "System Validation Failure",
    },
    [RefusalReasonType.MISSING_BANK_DETAILS]: {
        id: "refusalReason.MISSING_BANK_DETAILS",
        defaultMessage: "Missing Bank Details",
    },
    [RefusalReasonType.OTHER]: {
        id: "refusalReason.OTHER",
        defaultMessage: "Other",
    },
})

interface Props {
    organisationId: string
    objectId: string
    objectType: ApprovalObjectType
    onRefused: () => void
    onCancel: () => void
    onReviewed?: (result: boolean) => void
}

type ReasonOption = {
    id: string
    message: string
}

export const RefusalReason: FC<Props> = ({ organisationId, objectId, objectType, onRefused, onCancel, onReviewed }) => {
    const { formatMessage } = useIntl()
    const initialReason: RefusalReasonBody = {
        reason: "",
        message: "",
    }

    const [reasonObject, setReasonObject] = useState(initialReason)

    const handleCancel = () => {
        setReasonObject(initialReason)
        onCancel()
    }

    const { loading, handleRefuse, refusalReasons } = useApprovalReview({
        organisationId,
        objectId,
        objectType,
        onRefused,
        onReviewed,
    })

    const isButtonDisabled = loading

    const reasonOptions: ReasonOption[] =
        refusalReasons?.map((reason) => ({
            id: reason,
            message: refusalMessages[reason] ? formatMessage(refusalMessages[reason]) : reason,
        })) || []

    const renderInput = (props) => <TextField {...props} label={formatMessage(messages.refusalReason)} />
    const getOptionLabel = (option: ReasonOption) => option.message
    const isOptionEqualToValue = (option: ReasonOption, value: ReasonOption) => option.id === value.id

    const renderOption = (props, option: ReasonOption) => (
        <MenuItem {...props} key={option.id}>
            {option.message}
        </MenuItem>
    )

    const sendRefusal = () => {
        handleRefuse(reasonObject)
        setReasonObject(initialReason)
    }

    const handleReasonChange = (_, value: ReasonOption | null) => {
        setReasonObject((prev) => ({
            ...prev,
            reason: value?.id || "",
        }))
    }

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setReasonObject((prev) => ({
            ...prev,
            message: e.target.value,
        }))
    }

    const selectedReason = reasonOptions.find((option) => option.id === reasonObject.reason) || null

    return (
        <Stack spacing={2}>
            <Autocomplete
                isOptionEqualToValue={isOptionEqualToValue}
                value={selectedReason}
                options={reasonOptions}
                disabled={loading}
                onChange={handleReasonChange}
                getOptionLabel={getOptionLabel}
                renderInput={renderInput}
                renderOption={renderOption}
            />

            <TextField
                label={formatMessage(messages.refusalMessage)}
                value={reasonObject.message}
                onChange={handleMessageChange}
                multiline
                rows={3}
                fullWidth
                disabled={loading}
            />
            <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Button type="secondary" onClick={handleCancel} disabled={loading}>
                    <SafeFormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                    type="error-light"
                    onClick={sendRefusal}
                    disabled={isButtonDisabled}
                    disableDelay={0}
                    size="small"
                >
                    <SafeFormattedMessage {...messages.refuse} />
                    <X size={16} />
                </Button>
            </Stack>
        </Stack>
    )
}
