import { FC, PropsWithChildren, ReactNode, SyntheticEvent, useCallback, useState } from "react"
import { Editor, Element as SlateElement, Transforms } from "slate"

import { CheckListItem } from "~/components"
import { isElementActionPlanItem } from "~/domains/communication/chat/components/InputMessage/ActionPlan/isElementActionPlanItem"
import { ActionPlanCheckListI, MessageActionPlanItem } from "~/domains/communication/chat/types"

type BaseProps = PropsWithChildren & {
    label: ReactNode
    element: MessageActionPlanItem
}

type PropsWithMessage = BaseProps & {
    editor?: never
    updateActionPlanItem: (updateData: Partial<ActionPlanCheckListI>) => Promise<void>
}
type PropsWithEditor = BaseProps & {
    editor: Editor
    updateActionPlanItem?: never
}

type Props = PropsWithMessage | PropsWithEditor

export const ActionPlanCheckForm: FC<Props> = ({ label, element, children, editor, updateActionPlanItem }) => {
    const [checked, setChecked] = useState(element.data.checked)
    const onChange = useCallback(
        (_event: SyntheticEvent, checked: boolean) => {
            setChecked(checked)
            if (editor) {
                Transforms.setNodes(
                    editor,
                    {
                        ...element,
                        data: {
                            ...element.data,
                            checked,
                        },
                    },
                    {
                        at: [],
                        mode: "all",
                        match: (n) =>
                            SlateElement.isElement(n) && isElementActionPlanItem(n) && n.data.id === element.data.id,
                    }
                )
            } else {
                updateActionPlanItem({ checked })
            }
        },
        [updateActionPlanItem, editor, element]
    )
    return (
        <CheckListItem label={label} onChange={onChange} checked={checked} preventToggleOnClick>
            {children}
        </CheckListItem>
    )
}
