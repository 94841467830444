import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { FC } from "react"
import { defineMessages, useIntl } from "react-intl"

import { useMessageTemplates } from "~/domains/communication/chat/hooks"
import { MessageTemplateI } from "~/domains/communication/chat/types/MessageTemplate"
import { OrganizationId } from "~/types"

const messages = defineMessages({
    createFromTemplate: {
        id: "communication.messageTemplateSelector.createFromTemplate",
        defaultMessage: "Create message from a template",
    },
})

interface Props {
    value: MessageTemplateI | null
    onChange: (value: MessageTemplateI) => void
    organizationId: OrganizationId
}

export const MessageTemplateSelector: FC<Props> = ({ value, organizationId, onChange }) => {
    const { formatMessage } = useIntl()
    const templates = useMessageTemplates(organizationId)

    if (!templates || templates.length === 0) return null
    return (
        <FormControl variant="standard" margin="none">
            <InputLabel id="message_template">{formatMessage(messages.createFromTemplate)}</InputLabel>
            <Select
                id="message_template"
                margin="none"
                value={value ? value.id : ""}
                onChange={(event) => {
                    const template = templates.find((template) => template.id === event.target.value)
                    if (template) {
                        onChange(template)
                    }
                }}
            >
                {templates.map((template, i) => (
                    <MenuItem key={template.id} value={template.id}>
                        {template.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
