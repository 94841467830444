import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { commonMessages } from "~/common-messages"
import { ReprocessDocumentByInvoiceIdPayload, ReprocessDocumentPayload } from "~/domains/_shared/ocr/types"

const BASE_URL = import.meta.env.VITE_API_OCR_URL

export const ocrApi = createApi({
    reducerPath: "ocrApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    endpoints: (builder) => ({
        reprocessDocument: builder.query<boolean, ReprocessDocumentPayload>({
            query: ({ documentId }) => ({
                url: `document/${documentId}/reprocess`,
                responseHandler: "text",
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (data: string) => {
                return data === "OK"
            },
        }),
        reprocessDocumentByInvoiceId: builder.query<boolean, ReprocessDocumentByInvoiceIdPayload>({
            query: ({ invoiceId }) => ({
                url: `document/by-invoice-id/${invoiceId}/reprocess`,
                responseHandler: "text",
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (data: string) => {
                return data === "OK"
            },
        }),
        convertPdfToImages: builder.mutation<string[], File>({
            query: (file) => {
                const body = new FormData()
                body.append("file", file)
                return {
                    url: `pdf-to-images/process`,
                    formData: true,
                    body,
                    method: "POST",
                }
            },
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            transformResponse: (data) => {
                if (Array.isArray(data)) {
                    return data.filter((url) => typeof url === "string")
                }
                return []
            },
        }),
    }),
})

export const {
    useLazyReprocessDocumentQuery,
    useLazyReprocessDocumentByInvoiceIdQuery,
    useConvertPdfToImagesMutation,
} = ocrApi
