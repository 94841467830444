import { Stack, styled } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import { SafeFormattedMessage } from "~/components"
import { LeftChevronIcon } from "~/components/Icons"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { ToggleSideBarButton } from "~/domains/orchestration/flows/components/ToggleSidebarButton"
import { SIDE_BAR_TOP_POSITION, SIDE_BAR_WIDTH_RUN_EXPLORER } from "~/domains/orchestration/flows/constants"
import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { messages } from "~/domains/orchestration/flows/locale"
import { FlowId, RunId, RunItem } from "~/domains/orchestration/flows/types"

import { RunExplorerItem } from "./RunExplorerItem"
import { RunExplorerList } from "./RunExplorerList"
import "./RunExplorerSideBar.scss"

interface Props {
    isOpen: boolean
    flowId: FlowId
    handleToggleSideBar: () => void
}

const SideBarWrapper = styled("div")<{ width: number }>(({ width }) => ({
    width: `${width}px`,
    height: `calc(100vh - ${SIDE_BAR_TOP_POSITION}px)`,
    display: width === 0 ? "none" : "block",
}))

export const RunExplorerSideBar: FC<Props> = ({ handleToggleSideBar, flowId, isOpen }) => {
    const location = useLocation()
    const { state } = location
    const { runId = null } = state || {}
    const [currentRunId, setCurrentRunId] = useState<RunId | null>(runId)

    const dispatch = useEditorDispatch()
    const isRunSelected = currentRunId !== null

    const handleClickBack = () => {
        setCurrentRunId(null)
    }

    useEffect(() => {
        if (!isRunSelected) {
            dispatch({ type: "SET_RUN", payload: null })
        }
    }, [dispatch, isRunSelected])

    const handleClickRow = (run: RunItem) => {
        setCurrentRunId(run.id)
    }

    const sideBarWidth = isOpen ? SIDE_BAR_WIDTH_RUN_EXPLORER : 0

    return (
        <>
            <ToggleSideBarButton onClick={handleToggleSideBar} width={sideBarWidth} isOpen={isOpen} />
            <SideBarWrapper className="flows-run-explorer-sideBar" width={sideBarWidth}>
                <Stack
                    className="flows-run-explorer-sideBar-header"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {isRunSelected ? (
                        <IconButton type="close" onClick={handleClickBack}>
                            <LeftChevronIcon size={14} color="var(--primary-color)" strokeWidth="3" />
                        </IconButton>
                    ) : null}
                    <SafeFormattedMessage tagName="h4" {...messages.sideBar.explorerTitle} />
                </Stack>

                {isRunSelected ? (
                    <RunExplorerItem runId={currentRunId} />
                ) : (
                    <RunExplorerList handleClickRow={handleClickRow} flowId={flowId} />
                )}
            </SideBarWrapper>
        </>
    )
}
