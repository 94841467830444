import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { FC, useState } from "react"
import { useIntl } from "react-intl"

import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, CreateCustomFieldNode } from "~/domains/orchestration/flows/types"

import { ConfigurationNode } from "./ConfigurationNode"

export const CreateCustomFieldConfiguration: FC<ConfigurationProps<CreateCustomFieldNode>> = ({
    selectedNode,
    advancedFields,
}) => {
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const intl = useIntl()

    const handleChange = (field: keyof CreateCustomFieldNode) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            [field]: event.target.value,
        }))
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <Stack spacing={2} className={configurationNodeItemClassName}>
                <TextField
                    label={intl.formatMessage(messages.createCustomFieldConfiguration.customFieldNameLabel)}
                    value={currentNode.customFieldName}
                    onChange={handleChange("customFieldName")}
                    fullWidth
                />
                <TextField
                    label={intl.formatMessage(messages.createCustomFieldConfiguration.customFieldValueLabel)}
                    value={currentNode.customFieldValue || ""}
                    onChange={handleChange("customFieldValue")}
                    fullWidth
                />
            </Stack>

            <AdvancedFields<CreateCustomFieldNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
