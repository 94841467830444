import { Tooltip } from "@mui/material"
import { useState } from "react"
import { AlertTriangle, Trash2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { Button, SafeFormattedMessage } from "~/components"
import { ModalConfirm } from "~/components/ModalConfirm/ModalConfirm"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { PURCHASE_ORDERS_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types/ViewType"

const messages = defineMessages({
    label: { id: "purchase.orders.order.delete.deleteOrder", defaultMessage: "Delete this PO" },
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
    confirm: { id: "purchase.orders.order.delete.confirm", defaultMessage: "I want to delete this PO" },
    message: {
        id: "purchase.orders.order.delete.confirmMessage",
        defaultMessage: "Are you sure you want to delete this PO? This action is not reversible!",
    },
})

interface DeletePOProps {
    PO: PurchaseOrders
    viewType: ViewTypeI
    display?: "button" | "menu-item"
}

export const DeletePO = ({ PO, viewType, display = "button" }: DeletePOProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const [displayModal, setDisplayModal] = useState(false)

    const organization = useAppSelector(selectCurrentOrganization)

    const handleDelete = async () => {
        if (!organization?.id) return

        await purchaseOrdersApi.delete(organization.id, PO.id)
        navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType }))
    }

    const handleOpenModal = (): void => {
        setDisplayModal(true)
    }

    const handleCloseModal = (): void => {
        setDisplayModal(false)
    }

    return (
        <>
            {display === "button" ? (
                <Tooltip title={formatMessage(messages.label)}>
                    <span>
                        <Button type="error-light" onClick={handleOpenModal}>
                            <Trash2 color="var(--color-red)" size={18} />
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button type="menu-item" onClick={handleOpenModal}>
                    <Trash2 color="grey" size={16} />
                    <SafeFormattedMessage {...messages.label} />
                </Button>
            )}

            <ModalConfirm
                open={displayModal}
                message={formatMessage(messages.message)}
                icon={
                    <div className="icon-container red">
                        <AlertTriangle size={20} />
                    </div>
                }
                button1={<Button type="transparent" onClick={handleCloseModal} text={formatMessage(messages.cancel)} />}
                button2={<Button type="error" onClick={handleDelete} text={formatMessage(messages.confirm)} />}
            />
        </>
    )
}
