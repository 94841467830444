import { Stack, Typography } from "@mui/material"
import PropTypes from "prop-types"
import React, { memo, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { CheckFileTypeResult, UploadDocumentBox, boxSize } from "~/components/UploadDocument/UploadDocument"
import { useUploadDocument } from "~/components/UploadDocument/hooks"
import { useConvertPdfToImagesMutation } from "~/domains/_shared/ocr/api/ocrApi_RTK"
import { useDocumentsApi } from "~/domains/identity/documents/documentsApi"
import { useDocumentById } from "~/domains/identity/documents/hooks"
import { DocumentObjectType, DocumentType, UploadDocumentDTO } from "~/domains/identity/documents/types"
import { useProcessDocumentMutation } from "~/domains/orchestration/copilot/api/processDocumentApi"
import { PaymentMethodUploadPreview } from "~/domains/payment/payment-method-details/components/modal/PaymentMethodUploadPreview"
import {
    paymentMethodDetailsActions,
    selectSelectedItem,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { mapToEdit } from "~/domains/payment/payment-method-details/utils/mapPaymentMethodDetails"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { Result } from "~/types/Result"
import { checkMultiFileTypes } from "~/utils/upload"

const messages = defineMessages({
    description: {
        id: "payment.paymentMethodDetailsManagement.upload.description",
        defaultMessage: "Upload your document containing bank details, we will extract the information for you.",
    },
    loadingMessage: {
        id: "common.upload.loadingMessage",
        defaultMessage: "Upload in progress... This may take a few seconds.",
    },
    errorWrongFileType: {
        id: "common.upload.error.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept {FileTypes} files.",
    },
})

const ACCEPTED_FILE_TYPES = ["application/pdf", "image/png", "image/webp", "image/jpeg"]
const ACCEPT_FILE_DISPLAY = "PDF, PNG, JPG"

interface PaymentMethodUploadProps {
    organizationId: string
}

export const PaymentMethodUpload: React.FC<PaymentMethodUploadProps> = memo(({ organizationId }) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const [, setDocumentPreview] = useState<Result<string, string> | null>(null)

    const selectedPaymentMethodDetails = useAppSelector(selectSelectedItem)
    const [processDocument, { isLoading: processDocumentLoading }] = useProcessDocumentMutation()
    const [convertPdfToImages, { data: pdfAsImages, reset: resetPdfToImagesResult }] = useConvertPdfToImagesMutation()
    const [preview, setPreview] = useState<string | null>(null)

    const documentsApi = useDocumentsApi()
    const { uploadDocument } = useUploadDocument(organizationId)

    const { document } = useDocumentById(selectedPaymentMethodDetails?.documentId)

    useEffect(() => {
        const fetchDocumentPreview = async () => {
            if (!document?.latestFileVersion) return

            try {
                const imagePreview = await documentsApi.fetchImage(document?.latestFileVersion)
                setDocumentPreview(imagePreview)
            } catch (error) {
                console.error("Error fetching document preview:", error)
                setDocumentPreview({ success: true, result: document?.latestFileVersion })
            }
        }

        fetchDocumentPreview()
    }, [document?.latestFileVersion])

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult =>
            checkMultiFileTypes(
                file,
                ACCEPTED_FILE_TYPES,
                formatMessage(messages.errorWrongFileType, {
                    FileTypes: ACCEPT_FILE_DISPLAY,
                })
            ),
        [formatMessage]
    )

    const handleFile = useCallback(async (file: File) => {
        resetPdfToImagesResult()
        setPreview(null)
        if (file.type.startsWith("application/")) {
            convertPdfToImages(file)
        } else {
            const reader = new FileReader()
            reader.onload = function (event) {
                if (event.target && typeof event.target.result === "string") {
                    setPreview(event.target.result)
                }
            }
            reader.readAsDataURL(file)
        }
        const uploadPaymentMethodData = await processDocument({ file, organizationId }).unwrap()

        const payload: UploadDocumentDTO = {
            file,
            name: file.name,
            documentType: DocumentType.PAYMENT_METHOD_DETAILS,
            objectId: uploadPaymentMethodData?.paymentMethodDetails?.id ?? "",
            objectType: DocumentObjectType.PAYMENT,
            organizationIds: [organizationId],
        }
        const uploadedDocument = await uploadDocument(payload)

        const bankTransfer = mapToEdit(uploadPaymentMethodData?.paymentMethodDetails?.bankTransfer)

        dispatch(
            paymentMethodDetailsActions.setSelectedItem({
                paymentMethodType: PaymentMethodType.BANK_TRANSFER,
                ...bankTransfer,
                documentId: uploadedDocument?.id,
            })
        )
    }, [])

    const pages = preview ? [preview] : pdfAsImages

    return (
        <>
            {processDocumentLoading ? (
                <Stack gap={1}>
                    <PaymentMethodUploadPreview pages={pages} loading={true} />
                    <Typography color="primary.main">{formatMessage(messages.loadingMessage)}</Typography>
                </Stack>
            ) : (
                <>
                    {
                        /* 
                        Uncomment this when we have an image to display

                        documentPreview ? (
                        isResultSuccess(documentPreview) ? (
                            <DocumentPreview url={documentPreview.result} />
                        ) : (
                            <Alert severity="error">{documentPreview.error}</Alert>
                        )
                    )  : */ <Stack gap={2}>
                            <PaymentMethodUploadPreview pages={pages} />
                            <UploadDocumentBox
                                handleFile={handleFile}
                                conditionMessage={formatMessage(commonMessages.uploadTip, {
                                    FileTypes: ACCEPT_FILE_DISPLAY,
                                })}
                                checkFileType={checkFileType}
                                accept={ACCEPTED_FILE_TYPES.join(",")}
                                size={boxSize.MEDIUM}
                            />
                            <i>
                                <SafeFormattedMessage {...messages.description} />
                            </i>
                        </Stack>
                    }
                </>
            )}
        </>
    )
})

PaymentMethodUpload.displayName = "PaymentMethodUpload"
PaymentMethodUpload.propTypes = {
    organizationId: PropTypes.string.isRequired,
}
