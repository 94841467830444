import { Badge, Divider, Link, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { Download, FileText, Mail, Phone, UserCheck } from "react-feather"
import { MessageDescriptor, defineMessages, useIntl } from "react-intl"

import { Loader, TooltipWhite } from "~/components"
import { useUseGetManualVerificationsByIdQueryQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { ManualVerification, VerificationMethod } from "~/domains/payment/payment-method-details/types"

const messages = defineMessages({
    manualVerification: {
        id: "payment.paymentMethodDetailsList.manualVerification",
        defaultMessage: "Manual verification",
    },
    unknown: {
        id: "common.unknown",
        defaultMessage: "Unknown",
    },
})
const verificationMethodMessages: Record<VerificationMethod, MessageDescriptor> = {
    [VerificationMethod.PHONE]: {
        id: "payment.paymentMethodDetailsList.manualVerification.phone",
        defaultMessage: "Phone",
    },
    [VerificationMethod.EMAIL]: {
        id: "payment.paymentMethodDetailsList.manualVerification.email",
        defaultMessage: "Email",
    },
    [VerificationMethod.DOCUMENT]: {
        id: "payment.paymentMethodDetailsList.manualVerification.document",
        defaultMessage: "Document",
    },
}

interface ManualVerificationsProps {
    paymentMethodDetailsId: string
}

export const ManualVerifications = ({ paymentMethodDetailsId }: ManualVerificationsProps) => {
    const { formatMessage, formatDate } = useIntl()
    const { data, isLoading } = useUseGetManualVerificationsByIdQueryQuery(paymentMethodDetailsId, {
        skip: !paymentMethodDetailsId,
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const manualVerifications: ManualVerification[] = data?.items || []

    const groupedManualVerifications = useMemo(
        () =>
            manualVerifications.reduce<Record<string, ManualVerification[]>>((acc, record) => {
                const method = record.verification_method

                if (!acc[method]) {
                    acc[method] = []
                }

                acc[method].push(record)

                return acc
            }, {}),
        [manualVerifications]
    )

    const verifications = useMemo(
        () => (
            <Stack gap={2}>
                {Object.keys(groupedManualVerifications).map((method, index) => {
                    const v = groupedManualVerifications[method]

                    return (
                        <Stack key={method} gap={1}>
                            <Stack direction="row" gap={1} alignItems="center">
                                {method === VerificationMethod.EMAIL && (
                                    <Mail size={14} color="var(--color-grey-light)" />
                                )}
                                {method === VerificationMethod.PHONE && (
                                    <Phone size={14} color="var(--color-grey-light)" />
                                )}
                                {method === VerificationMethod.DOCUMENT && (
                                    <FileText size={14} color="var(--color-grey-light)" />
                                )}
                                <Typography component="div" color="var(--color-grey-light)">
                                    {formatMessage(
                                        verificationMethodMessages[method as VerificationMethod] ?? messages.unknown
                                    )}
                                </Typography>
                            </Stack>

                            {v.map(({ id, verification_notes, verification_date, verification_documents }) => (
                                <Stack key={id}>
                                    <Stack flexDirection="row">
                                        <Typography color="var(--color-grey)">
                                            {formatDate(verification_date, {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                second: "numeric",
                                            })}
                                        </Typography>
                                    </Stack>
                                    {verification_notes && (
                                        <Typography color="var(--color-grey)">{verification_notes}</Typography>
                                    )}
                                    {verification_documents?.url && (
                                        <Stack
                                            spacing={2}
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography
                                                component="span"
                                                color="var(--color-primary)"
                                                sx={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    maxWidth: "calc(100% - 40px)",
                                                }}
                                            >
                                                {verification_documents?.name}
                                            </Typography>
                                            <Link href={verification_documents?.url} marginTop="0 !important">
                                                <Download size={20} />
                                            </Link>
                                        </Stack>
                                    )}
                                </Stack>
                            ))}
                            {index < Object.keys(groupedManualVerifications).length - 1 && (
                                <Divider sx={{ marginTop: "var(--spacing-xxs)" }} color="var(--color-grey-light)" />
                            )}
                        </Stack>
                    )
                })}
            </Stack>
        ),
        [groupedManualVerifications]
    )

    if (isLoading) {
        return <Loader small />
    }

    if (!manualVerifications.length) {
        return null
    }

    return (
        <TooltipWhite sx={{ maxWidth: "300px" }} arrow title={verifications}>
            <Badge badgeContent={manualVerifications.length} color="primary">
                <UserCheck size={20} color="var(--color-grey-light)" />
            </Badge>
        </TooltipWhite>
    )
}
