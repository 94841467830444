import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab"
import { Typography, capitalize } from "@mui/material"
import dayjs from "dayjs"
import { defineMessages } from "react-intl"

import { ItemLabel, SafeFormattedMessage } from "~/components"
import UserAvatar from "~/components/Avatar/UserAvatar"
import { EventI } from "~/types"

const messages = defineMessages({
    eventsInvoiceOpened: {
        id: "events.type.invoice_opened",
        defaultMessage: "Invoice seen",
    },
    eventsInvoiceConfirmed: {
        id: "events.type.invoice_confirmed",
        defaultMessage: "Invoice sent",
    },
    eventsInvoiceRequestInfo: {
        id: "events.type.info_requested",
        defaultMessage: "Information requested",
    },
    eventsInvoiceUpdated: {
        id: "events.type.invoice_updated",
        defaultMessage: "Invoice updated",
    },
    eventsInvoiceValidated: {
        id: "events.type.invoice_validated",
        defaultMessage: "Invoice validated",
    },
    eventsInvoiceInvalidated: {
        id: "events.type.invoice_invalidated",
        defaultMessage: "Invoice invalidated",
    },
    eventsInvoicePaymentScheduled: {
        id: "events.type.invoice_scheduled",
        defaultMessage: "Payment scheduled",
    },
    eventsInvoiceInvoicePaid: {
        id: "events.type.invoice_paid",
        defaultMessage: "Invoice paid",
    },
    eventsMarkedAsPaid: {
        id: "events.type.marked_as_paid",
        defaultMessage: "Marked as paid",
    },
    eventsConfirmedAsPaid: {
        id: "events.type.confirmed_as_paid",
        defaultMessage: "Confirmed as paid",
    },
    eventsUnmarkedAsPaid: {
        id: "events.type.unmarked_as_paid",
        defaultMessage: "Unmarked as paid",
    },
})

const events = {
    invoice_opened: "eventsInvoiceOpened",
    created: "eventsInvoiceOpened",
    viewed: "eventsInvoiceOpened",
    invoice_confirmed: "eventsInvoiceConfirmed",
    info_requested: "eventsInvoiceRequestInfo",
    invoice_updated: "eventsInvoiceUpdated",
    updated: "eventsInvoiceUpdated",
    invoice_validated: "eventsInvoiceValidated",
    invoice_invalidated: "eventsInvoiceInvalidated",
    invoice_scheduled: "eventsInvoicePaymentScheduled",
    invoice_paid: "eventsInvoiceInvoicePaid",
    invoice_marked_as_paid: "eventsMarkedAsPaid",
    invoice_confirmed_as_paid: "eventsConfirmedAsPaid",
    invoice_unmarked_as_paid: "eventsUnmarkedAsPaid",
}

interface EventLogProps {
    eventLog: EventI
}

export const EventLog = ({ eventLog }: EventLogProps) => {
    const { user } = eventLog

    const event = events[eventLog.type] as keyof typeof events

    const distanceToNow = dayjs(eventLog.timestamp).fromNow()

    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot>
                    <UserAvatar email={user?.email} />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Typography>{user?.fullName}</Typography>
                <Typography variant="body2">
                    {event ? <SafeFormattedMessage {...messages[event]} /> : eventLog.type}
                </Typography>
                <ItemLabel>{capitalize(distanceToNow)}</ItemLabel>
            </TimelineContent>
        </TimelineItem>
    )
}
