import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { defineMessages } from "react-intl"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { commonMessages } from "~/common-messages"
import { productVariantFromApiAdapters } from "~/domains/transactions/catalog-v1/api/adapters/catalogFromApiAdapters"
import { productVariantToApiAdapters } from "~/domains/transactions/catalog-v1/api/adapters/catalogToApiAdapters"
import {
    CatalogProductVariant,
    CreateCatalogProductVariantPayload,
    DeleteCatalogProductVariantPayload,
    GetCatalogProductVariantByIdQuery,
    GetCatalogProductVariantsQuery,
    UpdateCatalogProductVariantPayload,
} from "~/domains/transactions/catalog-v1/types/CatalogProductVariant.type"
import { PaginatedOffsetLimitResponse } from "~/types/Pagination"

const messages = defineMessages({
    errorDeleteProductVariant: {
        id: "catalog.productVariantsVersionsTable.errorDeleteProduct",
        defaultMessage: "An error occurred while deleting the product",
    },
})

const BASE_URL = import.meta.env.VITE_API_CATALOG_V1_URL

export const catalogProductVariantApi = createApi({
    reducerPath: "catalogProductVariantApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["CatalogProductVariant"],
    endpoints: (builder) => ({
        getProductVariants: builder.query<
            PaginatedOffsetLimitResponse<CatalogProductVariant>,
            GetCatalogProductVariantsQuery
        >({
            query: ({ organizationId, productId, statusFilter }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants`,
                params: { statusFilter },
            }),
            transformResponse: (response: PaginatedOffsetLimitResponse<CatalogProductVariant>) => ({
                items: response.items.map(productVariantFromApiAdapters),
                offset: response.offset,
                limit: response.limit,
                totalResults: response.totalResults,
            }),
            providesTags: ["CatalogProductVariant"],
        }),

        getProductVariantById: builder.query<CatalogProductVariant, GetCatalogProductVariantByIdQuery>({
            query: ({ organizationId, productId, variantId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}`,
            }),
            transformResponse: (response: CatalogProductVariant) => productVariantFromApiAdapters(response),
            providesTags: ["CatalogProductVariant"],
        }),

        createProductVariant: builder.mutation<CatalogProductVariant, CreateCatalogProductVariantPayload>({
            query: ({ organizationId, productId, productVariant }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants`,
                method: "POST",
                body: productVariantToApiAdapters(productVariant),
            }),
            transformResponse: (response: CatalogProductVariant) => productVariantFromApiAdapters(response),
            invalidatesTags: ["CatalogProductVariant"],
        }),

        updateProductVariant: builder.mutation<CatalogProductVariant, UpdateCatalogProductVariantPayload>({
            query: ({ organizationId, productId, variantId, productVariant }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}`,
                method: "PATCH",
                body: productVariant,
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
            invalidatesTags: ["CatalogProductVariant"],
        }),

        deleteProductVariant: builder.mutation<void, DeleteCatalogProductVariantPayload>({
            query: ({ organizationId, productId, variantId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}`,
                method: "DELETE",
            }),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: messages.errorDeleteProductVariant,
                    catchSentryException: true,
                },
                ...response,
            }),

            invalidatesTags: ["CatalogProductVariant"],
        }),
    }),
})

export const {
    useCreateProductVariantMutation,
    useUpdateProductVariantMutation,
    useGetProductVariantsQuery,
    useGetProductVariantByIdQuery,
    useDeleteProductVariantMutation,
} = catalogProductVariantApi
