import * as t from "io-ts"

import { Result } from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"
import { optional } from "~/utils/io-ts"

import { AssessmentMetricsIO } from "./AssessmentMetrics"
import { BusinessTradingMetricsIO } from "./BusinessTradingMetrics"
import { DunsControlStatusIO } from "./DunsControlStatus"
import { FinancialMetricsIO } from "./FinancialMetrics"

export const OrganizationMetricsIO = t.partial({
    financials: optional(t.array(FinancialMetricsIO)),
    businessTrading: optional(t.array(BusinessTradingMetricsIO)),
    dnbAssessment: optional(AssessmentMetricsIO),
    dunsControlStatus: optional(DunsControlStatusIO),
})

export type OrganizationMetricsI = t.TypeOf<typeof OrganizationMetricsIO>

export const parseOrganizationMetrics = (data: unknown): Result<OrganizationMetricsI, ParsingErrorType> => {
    return genericParser(data, OrganizationMetricsIO)
}
