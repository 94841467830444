import { Tooltip } from "@mui/material"
import { useState } from "react"
import { AlertTriangle, Trash2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { Button, ModalConfirm, SafeFormattedMessage } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import { PURCHASE_REQUESTS_ROUTE } from "~/domains/transactions/purchase-requests/routes"

const messages = defineMessages({
    label: { id: "purchase.requests.request.delete.deleteRequest", defaultMessage: "Delete this PR" },
    button: { id: "common.delete", defaultMessage: "Delete" },
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
    confirm: { id: "purchase.requests.request.delete.confirm", defaultMessage: "I want to delete this PR" },
    message: {
        id: "purchase.requests.request.delete.confirmMessage",
        defaultMessage: "Are you sure you want to delete this PR ? This action is not reversible !",
    },
})

interface DeletePRProps {
    id: string
    organizationId: string
    hasPermission?: boolean
    display?: "menu-item" | "button"
}

export function DeletePR({ id, organizationId, hasPermission, display }: DeletePRProps) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const [displayModal, setDisplayModal] = useState(false)

    const canDelete = (hasPermission && typeof hasPermission !== "undefined") ?? false

    const handleOpenModal = () => {
        setDisplayModal(true)
    }

    const handleCloseModal = () => {
        setDisplayModal(false)
    }

    const handleDelete = async () => {
        await purchaseRequestApi.delete(organizationId, id)
        navigate(PURCHASE_REQUESTS_ROUTE)
    }

    return (
        <>
            {display === "button" ? (
                <Tooltip
                    arrow
                    title={formatMessage(!canDelete ? permissionMessages.errorNoAccessAdministrator : messages.button)}
                >
                    <span>
                        <Button type="grey" onClick={handleOpenModal} disabled={!canDelete}>
                            <Trash2 size={18} />
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button type="menu-item" onClick={handleOpenModal} disabled={!canDelete}>
                    <Trash2 size={18} />
                    <SafeFormattedMessage {...messages.button} />
                </Button>
            )}

            <ModalConfirm
                message={formatMessage(messages.message)}
                icon={
                    <div className="icon-container red">
                        <AlertTriangle size={20} />
                    </div>
                }
                button1={<Button type="transparent" onClick={handleCloseModal} text={formatMessage(messages.cancel)} />}
                button2={<Button type="error" onClick={handleDelete} text={formatMessage(messages.confirm)} />}
                open={displayModal}
            />
        </>
    )
}
