import { useMemo } from "react"

import { flattenTagsWithGroup } from "~/domains/analytics/tags/core/tagGroupsAndTags"
import { TagAndTagGroupNameI, TagGroupI } from "~/domains/analytics/tags/types"

export const useTagsWithTagGroupName = (tagGroups: TagGroupI[] | null) =>
    useMemo((): TagAndTagGroupNameI[] => {
        if (!tagGroups) return []

        return tagGroups.flatMap(({ tags, name, shortName }) => flattenTagsWithGroup(tags, name, shortName))
    }, [tagGroups])
