import { Stack, Tooltip, Typography } from "@mui/material"
import { Info } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Loader } from "~/components"
import { useManagePaymentTerms } from "~/domains/payment/payment-terms/hooks/useManagePaymentTerms"
import { ObjectType } from "~/domains/payment/types"

const messages = defineMessages({
    paymentTerms: {
        id: "payment.documentPaymentTerm.paymentTerms",
        defaultMessage: "Payment terms",
    },
})

interface PaymentTermInfoProps {
    paymentId: string
}

export const PaymentTermInfo = ({ paymentId }: PaymentTermInfoProps) => {
    const { formatMessage, locale } = useIntl()
    const { loading, objectPaymentTerm } = useManagePaymentTerms(
        { objectId: paymentId, objectType: ObjectType.PAYMENT },
        { showList: false }
    )

    if (!objectPaymentTerm) {
        return null
    }

    const { name, description_translations, description } = objectPaymentTerm

    if (loading) {
        return <Loader small />
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Stack key="payment-terms" spacing={1}>
                <Typography component="div" fontSize={12} color="var(--color-grey-light)">
                    {formatMessage(messages.paymentTerms)}
                </Typography>
                <Typography component="div" fontSize={14} color="var(--color-grey)">
                    <Stack direction="row" gap={1} alignItems="center">
                        {name ?? "-"}
                        <Tooltip arrow title={description_translations[locale] ?? description}>
                            <Info size={16} color="var(--color-grey-light)" />
                        </Tooltip>
                    </Stack>
                </Typography>
            </Stack>
        </Stack>
    )
}
