import { IconButton } from "@mui/material"
import { useRef } from "react"
import { Paperclip } from "react-feather"

const ACCEPTED_FILE_TYPES = ["application/pdf", "csv/*"]
const ACCEPTED_FILES = ACCEPTED_FILE_TYPES.join(",")

interface CopilotUploadDocumentProps {
    onUpload: (file: File) => void
}

export const CopilotUploadDocument = ({ onUpload }: CopilotUploadDocumentProps) => {
    const inputFileRef = useRef<HTMLInputElement>(null)

    const handleClickUploadFile = () => {
        inputFileRef.current?.click()
    }

    const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e?.target?.files) return
        const uploadedFile = e.target.files[0]
        if (!uploadedFile) return

        onUpload(uploadedFile)
    }

    return (
        <IconButton onClick={handleClickUploadFile}>
            <Paperclip />
            <input
                type="file"
                name="file"
                className="upload-document-file"
                accept={ACCEPTED_FILES}
                ref={inputFileRef}
                onChange={handleUploadFile}
            />
        </IconButton>
    )
}
