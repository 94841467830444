import { Box, Stack } from "@mui/material"
import React from "react"
import { ExternalLink } from "react-feather"
import { generatePath } from "react-router-dom"

import { StatusChip } from "~/components"
import { DateComponent } from "~/components/Date"
import { INVOICE_ROUTE } from "~/domains/transactions/invoices-v1/routes"
import { INVOICE_BUYER_ROUTE } from "~/domains/transactions/invoices/_views/buyer/routes"
import { INVOICE_SUPPLIER_ROUTE } from "~/domains/transactions/invoices/_views/supplier/routes"
import { InvoiceBackendVersion, InvoiceI, ViewTypeI } from "~/types"

interface InvoiceDuplicateWarningInvoiceProps {
    invoice: InvoiceI
}

export const InvoiceDuplicateWarningInvoice: React.FC<InvoiceDuplicateWarningInvoiceProps> = ({ invoice }) => {
    const viewType = location.pathname.includes(ViewTypeI.supplier) ? ViewTypeI.supplier : ViewTypeI.buyer

    const handleClick = () => {
        if (invoice.version === InvoiceBackendVersion.V1) {
            const link = generatePath(INVOICE_ROUTE, {
                invoiceId: invoice.id,
            })
            window.open(link, "_blank")
        } else {
            const link =
                viewType === ViewTypeI.supplier
                    ? generatePath(INVOICE_SUPPLIER_ROUTE, {
                          invoiceId: invoice.id,
                      })
                    : generatePath(INVOICE_BUYER_ROUTE, {
                          invoiceId: invoice.id,
                      })
            window.open(link, "_blank")
        }
    }

    return (
        <Stack
            onClick={handleClick}
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            className="cursor-pointer"
        >
            <StatusChip status={invoice.status}>{invoice.status}</StatusChip>
            <Box display="flex" alignItems="center" gap={2}>
                <small>
                    <DateComponent value={invoice.issueDate} />
                </small>
                <ExternalLink size={16} />
            </Box>
        </Stack>
    )
}
