import { VatRateI, VatRateId } from "~/domains/transactions/invoices-v1/types/VatRate"
import { TransactionVatRateI } from "~/domains/transactions/invoices-v1/types/vatApi.types"

export const vatRateFromApiAdapter = (taxDefinition: TransactionVatRateI): VatRateI => ({
    id: taxDefinition.id as VatRateId,
    rate: taxDefinition.rate,
    label: {
        translations: {
            en: taxDefinition.label,
        },
        defaultLanguage: "en",
    },
    countryCode: taxDefinition.country,
})
