import { FormControlLabel, Radio, SxProps, Theme, styled } from "@mui/material"
import React from "react"

const RadioIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 18,
    height: 18,
    border: `1px solid var(--color-silver-light)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "none",
    "&:before": {
        display: "block",
        width: 0,
        height: 0,
        backgroundColor: "white",
        content: '""',
        borderRadius: "50%",
        transition: theme.transitions.create(["width", "height"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    ".Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
    },
    "input:disabled ~ &": {
        boxShadow: "none",
        background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
    },
}))
const CheckedRadioIcon = styled(RadioIcon)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    "&:before": {
        width: 8,
        height: 8,
    },
}))

interface Props {
    checked?: boolean
    label: React.ReactNode
    disabled?: boolean
    labelPlacement?: "end" | "start" | "top" | "bottom"
    name?: string
    required?: boolean
    sx?: SxProps<Theme>
    value?: unknown
}

export const RadioButton: React.FC<Props> = (props) => (
    <FormControlLabel {...props} control={<Radio icon={<RadioIcon />} checkedIcon={<CheckedRadioIcon />} />} />
)
