import { Tooltip } from "@mui/material"
import { Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { useFetchPurchaseOrder, useUpdatePOStatus } from "~/domains/transactions/purchase-orders/store/hooks"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { PurchaseOrderStatus, PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    submit: { id: "purchase.orders.actionsHeader.submit", defaultMessage: "Submit" },
})

interface SubmitPOProps {
    PO: PurchaseOrders
}

export const SubmitPO = ({ PO }: SubmitPOProps) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    const organization = useAppSelector(selectCurrentOrganization)
    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization?.id)
    const { updateStatus } = useUpdatePOStatus(PO.id)

    const updatePurchaseOrderStatus = async (status: PurchaseOrderStatus) => {
        if (!organization) return

        dispatch(purchaseOrdersActions.updatePO())
        await updateStatus(organization.id, status)
        await fetchPurchaseOrder(PO.id)
        dispatch(purchaseOrdersActions.updatePOSuccess())
    }

    const handleSubmit = async () => {
        await updatePurchaseOrderStatus(PurchaseOrderStatus.SUBMITTED)
    }

    return (
        <Tooltip title={formatMessage(messages.submit)}>
            <span>
                <Button type="primary-light" onClick={handleSubmit}>
                    <Send size={18} />
                    <SafeFormattedMessage {...commonMessages.submit} />
                </Button>
            </span>
        </Tooltip>
    )
}
