import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"

import { InvoicesPage } from "./pages"
import { Invoice } from "./pages/[ID]"
import { InvoiceEdit } from "./pages/[ID]/edit"
import { INVOICES_V1_ROUTE, INVOICE_EDIT_ROUTE, INVOICE_ROUTE } from "./routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key={INVOICES_V1_ROUTE}>
            <Route path={INVOICES_V1_ROUTE} element={<InvoicesPage />} />
        </Route>,
        <Route element={<ProtectedRoutes />} key={INVOICE_EDIT_ROUTE}>
            <Route path={INVOICE_EDIT_ROUTE} element={<InvoiceEdit />} />
        </Route>,
        <Route element={<ProtectedRoutes />} key={INVOICE_ROUTE}>
            <Route path={INVOICE_ROUTE} element={<Invoice />} />
        </Route>,
    ],
}
