import styled from "@emotion/styled"
import { Stack } from "@mui/material"
import { PropsWithChildren } from "react"

interface DocumentPreviewProps extends PropsWithChildren {
    url: string
}

const PreviewContainer = styled("div")({
    aspectRatio: "164/232",
    position: "relative",
    borderRadius: "var(--border-radius-sm)",
    overflow: "hidden",
    cursor: "pointer",
})

const Overlay = styled(Stack)({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    borderRadius: "var(--border-radius-sm)",
})

const Preview = styled("figure", { shouldForwardProp: (propName) => propName !== "url" })<DocumentPreviewProps>(
    ({ url }) => ({
        width: "100%",
        height: "100%",
        backgroundImage: `url(${url})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "top center",
    })
)

const openInNewTab = (url: string) => () => window.open(url, "_blank")

export const DocumentPreview = ({ url, children }: DocumentPreviewProps) => (
    <PreviewContainer onClick={openInNewTab(url)}>
        <Preview url={url} />
        {children && (
            <Overlay alignItems="center" justifyContent="center">
                {children}
            </Overlay>
        )}
    </PreviewContainer>
)
