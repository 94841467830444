import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { CreatePaymentBatch } from "~/domains/payment/payment-batches/types"
import { ValidationSchema } from "~/domains/payment/types"

const messages = defineMessages({
    required: {
        id: "common.required",
        defaultMessage: "{field} is required",
    },
    invalid: {
        id: "common.invalid",
        defaultMessage: "{field} is invalid",
    },
    name: {
        id: "common.name",
        defaultMessage: "Name",
    },
    startDate: {
        id: "common.startDate",
        defaultMessage: "Start date",
    },
    recurrenceType: {
        id: "payment.batches.recurrenceType",
        defaultMessage: "Recurrence type",
    },
    defaultTotalAmount: {
        id: "payment.batches.defaultTotalAmount",
        defaultMessage: "Default total amount",
    },
})

export const batchFormValidationSchema: ValidationSchema<CreatePaymentBatch> = {
    name: {
        required: {
            value: true,
            message: (
                <SafeFormattedMessage
                    {...messages.required}
                    values={{ field: <SafeFormattedMessage {...messages.name} /> }}
                />
            ),
        },
    },
    recurrenceType: {
        required: {
            value: true,
            message: (
                <SafeFormattedMessage
                    {...messages.required}
                    values={{ field: <SafeFormattedMessage {...messages.recurrenceType} /> }}
                />
            ),
        },
    },
    startDate: {
        required: {
            value: true,
            message: (
                <SafeFormattedMessage
                    {...messages.required}
                    values={{ field: <SafeFormattedMessage {...messages.startDate} /> }}
                />
            ),
        },
        date: {
            value: true,
            message: (
                <SafeFormattedMessage
                    {...messages.invalid}
                    values={{ field: <SafeFormattedMessage {...messages.startDate} /> }}
                />
            ),
        },
    },
    defaultTotalAmount: {
        required: {
            value: true,
            message: (
                <SafeFormattedMessage
                    {...messages.required}
                    values={{ field: <SafeFormattedMessage {...messages.defaultTotalAmount} /> }}
                />
            ),
        },
        pattern: {
            value: /^(0|[1-9]\d*)(\.\d+)?$/,
            message: (
                <SafeFormattedMessage
                    {...messages.invalid}
                    values={{ field: <SafeFormattedMessage {...messages.defaultTotalAmount} /> }}
                />
            ),
        },
    },
}
