import { DocumentRelationDifferencesI } from "~/domains/transactions/_shared/document-relations/types/DocumentRelation"
import { documentsDifferencesKPIsFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/parts/documentsDifferencesKPIsFromApiAdapter"

export const documentsDifferencesFromApiAdapter = (
    documentsDifferences: DocumentRelationDifferencesI
): DocumentRelationDifferencesI => {
    return {
        relations: documentsDifferences.relations.map((relation) => ({
            relationId: relation.relationId,
            invoiceId: relation.invoiceId,
            purchaseOrderId: relation.purchaseOrderId,
            purchaseOrderKpis: relation.purchaseOrderKpis
                ? documentsDifferencesKPIsFromApiAdapter(relation.purchaseOrderKpis)
                : null,
            invoiceKpis: relation.invoiceKpis ? documentsDifferencesKPIsFromApiAdapter(relation.invoiceKpis) : null,
        })),
    }
}
