import { IconButton, Popover, PopoverOrigin } from "@mui/material"
import React, { useRef, useState } from "react"
import { Clock } from "react-feather"

import { EventLogs } from "~/components/EventLogs"
import { EventI } from "~/types"

interface Props {
    events: EventI[]
}

const ANCHOR_ORIGIN: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "right",
}

const TRANSFORM_ORIGIN: PopoverOrigin = {
    vertical: "top",
    horizontal: "right",
}

export const InvoiceEvents: React.FC<Props> = ({ events }) => {
    const [displayEventHistory, setDisplayEventHistory] = useState(false)

    const buttonRef = useRef<HTMLButtonElement>(null)

    const toggleEventHistory = () => {
        if (!events.length) return

        setDisplayEventHistory((value) => !value)
    }

    return (
        <>
            <IconButton ref={buttonRef} onClick={toggleEventHistory}>
                <Clock size={18} />
            </IconButton>
            <Popover
                open={displayEventHistory}
                anchorEl={buttonRef.current}
                onClose={toggleEventHistory}
                anchorOrigin={ANCHOR_ORIGIN}
                transformOrigin={TRANSFORM_ORIGIN}
                container={buttonRef.current}
            >
                <EventLogs events={events} />
            </Popover>
        </>
    )
}
