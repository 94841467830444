import { Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { defineMessages } from "react-intl"

import { Button, Modal, SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    addSection: {
        id: "smartForms.formEditor.addSection",
        defaultMessage: "Add section",
    },
    sectionTitle: {
        id: "smartForms.formEditor.sectionTitle",
        defaultMessage: "Section title",
    },
})

export const AddSectionModal = ({
    open,
    close,
    createSection,
}: {
    open: boolean
    close: () => void
    createSection: (title: string) => void
}) => {
    const [sectionName, setSectionName] = useState("")

    useEffect(() => {
        if (!open) {
            setSectionName("")
        }
    }, [open])

    const handleCreateSection = () => {
        if (sectionName) {
            createSection(sectionName)
            close()
        }
    }

    const handleSectionNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSectionName(e.target.value)
    }

    return (
        <Modal open={open}>
            <Modal.Header>
                <Typography variant="h2" gutterBottom>
                    <SafeFormattedMessage {...messages.addSection} />
                </Typography>
            </Modal.Header>
            <Stack padding={2}>
                <TextField
                    label={<SafeFormattedMessage {...messages.sectionTitle} />}
                    variant="outlined"
                    fullWidth
                    onChange={handleSectionNameChange}
                    value={sectionName}
                />
            </Stack>
            <Modal.Footer>
                <Button onClick={handleCreateSection}>
                    <SafeFormattedMessage id="common.create" defaultMessage="Create" />
                </Button>
                <Button type="neutral" onClick={close}>
                    <SafeFormattedMessage id="common.close" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
