import { Check } from "react-feather"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { Button } from "~/components"
import { useSegment } from "~/hooks"
import { useAppDispatch } from "~/store/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { InvoiceI } from "~/types"

const messages = defineMessages({
    markResolved: {
        id: "supplier.invoice.page.markResolved",
        defaultMessage: "Mark all requests as resolved",
    },
})

interface ResolveAllRequestsInvoiceProps {
    invoice: InvoiceI
}

export const ResolveAllRequestsInvoice = ({ invoice }: ResolveAllRequestsInvoiceProps) => {
    const dispatch = useAppDispatch()
    const { segmentTrack } = useSegment()

    const handleMarkAsResolved = async () => {
        segmentTrack("Requests Marked As Resolved", {
            invoice_id: invoice?.id,
        })
        dispatch(invoiceActions.markAsResolved())
    }

    return (
        <Button onClick={handleMarkAsResolved} type="primary-light">
            <Check size={16} />
            <SafeFormattedMessage {...messages.markResolved} />
        </Button>
    )
}
