import React from "react"

import { TagsSelector } from "~/domains/analytics/tags/components/TagsSelector"
import { OrganizationId } from "~/types"

import { useTagsForCreatingPurchaseOrder } from "../store/hooks/useTagsForCreatingPurchaseOrder"

interface Props {
    organizationId: OrganizationId
}

export const TagsForCreatingPurchaseOrder: React.FC<Props> = ({ organizationId }) => {
    const [selectedTags, setSelectedTags] = useTagsForCreatingPurchaseOrder()
    return (
        <TagsSelector
            organizationId={organizationId}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            tagsRecommandations={null}
        />
    )
}
