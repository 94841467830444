import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { commonMessages } from "~/common-messages"
import { catalogFromApiAdapters } from "~/domains/transactions/catalog-v1/api/adapters/catalogFromApiAdapters"
import { catalogToApiAdapters } from "~/domains/transactions/catalog-v1/api/adapters/catalogToApiAdapters"
import {
    Catalog,
    CreateCatalogPayload,
    DeleteCatalogPayload,
    GetCatalogByIdQuery,
    GetCatalogsQuery,
    UpdateCatalogPayload,
} from "~/domains/transactions/catalog-v1/types/Catalog.type"
import { PaginatedOffsetLimitResponse } from "~/types/Pagination"

const BASE_URL = import.meta.env.VITE_API_CATALOG_V1_URL

export const catalogApi = createApi({
    reducerPath: "catalogApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["Catalogs", "Catalog", "Search"],
    endpoints: (builder) => ({
        getCatalogs: builder.query<PaginatedOffsetLimitResponse<Catalog>, GetCatalogsQuery>({
            query: ({ organizationId, status, offset, limit }) => ({
                url: `organizations/${organizationId}/catalogs`,
                params: { status, offset, limit },
            }),
            providesTags: (result, error, { organizationId, status }) => [{ type: "Catalogs", organizationId, status }],
            transformResponse: (response: PaginatedOffsetLimitResponse<Catalog>) => ({
                items: response.items.map(catalogFromApiAdapters),
                offset: response.offset,
                limit: response.limit,
                totalResults: response.totalResults,
            }),
        }),

        getCatalogById: builder.query<Catalog, GetCatalogByIdQuery>({
            query: ({ organizationId, catalogId }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}`,
            }),
            transformResponse: (response: Catalog) => catalogFromApiAdapters(response),
            providesTags: ["Catalog"],
        }),

        createCatalog: builder.mutation<Catalog, CreateCatalogPayload>({
            query: ({ organizationId, catalog }) => ({
                url: `organizations/${organizationId}/catalogs`,
                method: "POST",
                body: catalogToApiAdapters(catalog),
            }),
            invalidatesTags: ["Catalogs", "Search"],
            transformResponse: (response: Catalog) => catalogFromApiAdapters(response),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
        }),

        updateCatalog: builder.mutation<Catalog, UpdateCatalogPayload>({
            query: ({ organizationId, catalogId, catalog }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}`,
                method: "PATCH",
                body: catalogToApiAdapters(catalog),
            }),
            invalidatesTags: (_, __, arg) => [
                { type: "Catalogs", organizationId: arg.organizationId },
                { type: "Catalog", id: arg.catalogId },
                "Search",
            ],
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
        }),

        deleteCatalog: builder.mutation<void, DeleteCatalogPayload>({
            query: ({ organizationId, catalogId }) => ({
                url: `organizations/${organizationId}/catalogs/${catalogId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Catalog", "Catalogs", "Search"],
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.error,
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
    }),
})

export const {
    useGetCatalogsQuery,
    useGetCatalogByIdQuery,
    useCreateCatalogMutation,
    useUpdateCatalogMutation,
    useDeleteCatalogMutation,
} = catalogApi
