import React, { useCallback, useEffect, useRef, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { getInitialFromName } from "~/components/Avatar/getInitialFromName"
import { DownChevronIcon } from "~/components/Icons"
import { PartnerOrganizationI } from "~/domains/identity/partners/types"
import { useCurrentOrganization } from "~/store/organization/hooks"
import { getInitials } from "~/store/organization/utils"
import { NO_ORGANIZATION_ID, OrganizationI } from "~/types"

import { OtherOrganizationI } from "../CompanyRegistrationFields"
import "./UserOrganizationsSelect.scss"

const messages = defineMessages({
    selectOrganization: {
        id: "buyer.import.page.me.selectOrganization",
        defaultMessage: "Please select one of your organizations",
    },
    otherOrganizationName: {
        id: "buyer.import.page.me.otherOrganizationName",
        defaultMessage: "Search another organization",
    },
})

interface OrganizationItemProps {
    organization: OrganizationI | OtherOrganizationI
    close: () => void
    onSelect: (organization: OrganizationI | OtherOrganizationI) => void
}

const OrganizationItem: React.FC<OrganizationItemProps> = ({ organization, close, onSelect }) => {
    const handleClick = useCallback(() => {
        onSelect(organization)
        close()
    }, [organization])

    return (
        <li className="user-organization-item" onClick={handleClick}>
            {organization.id !== NO_ORGANIZATION_ID ? (
                <figure className="no-image">{getInitialFromName(organization.name)}</figure>
            ) : null}
            <span>{organization.name}</span>
        </li>
    )
}

const getCurrentOrganization = (
    organizationId: string | null,
    organizations: (OrganizationI | PartnerOrganizationI)[]
) => {
    if (organizationId) {
        return organizations.find((organization) => organization.id === organizationId)
    }

    return null
}

interface Props {
    selectedOrganizationId?: string
    organizations: (OrganizationI | PartnerOrganizationI)[]
    onSelect: (data: OrganizationI | OtherOrganizationI) => void
    searchAnotherOrganization: boolean
    withOtherOrganization?: boolean
    classnames?: string
    readOnly?: boolean
}

export const UserOrganizationsSelect: React.FC<Props> = ({
    selectedOrganizationId,
    organizations,
    onSelect,
    searchAnotherOrganization,
    withOtherOrganization = true,
    classnames,
    readOnly,
}) => {
    const { formatMessage } = useIntl()
    const userCurrentOrganization = useCurrentOrganization(organizations)
    const selectedOrganization = !selectedOrganizationId
        ? userCurrentOrganization
        : getCurrentOrganization(selectedOrganizationId, organizations)
    const otherOrganization = {
        id: NO_ORGANIZATION_ID,
        name: formatMessage(messages.otherOrganizationName),
    }

    const [opened, setOpened] = useState<boolean>(false)
    const ignoreNextClick = useRef<boolean>(false)

    useEffect(() => {
        if (opened) {
            const onClick = () => {
                if (ignoreNextClick.current) {
                    ignoreNextClick.current = false
                } else {
                    setOpened(false)
                }
            }
            window.addEventListener("click", onClick)
            return () => {
                window.removeEventListener("click", onClick)
            }
        }
    }, [opened])

    const onRootClick = useCallback(() => {
        ignoreNextClick.current = true
    }, [ignoreNextClick])

    const handleClick = useCallback(() => {
        if (!readOnly) setOpened((prev) => !prev)
    }, [setOpened])

    const close = useCallback(() => setOpened(false), [setOpened])

    const isNotCurrentOrganization = useCallback(
        (organization: OrganizationI | PartnerOrganizationI, searchAnotherOrganization: boolean) =>
            organization.id !== NO_ORGANIZATION_ID &&
            (organization.id !== selectedOrganization?.id || searchAnotherOrganization),
        [selectedOrganization]
    )

    const renderOrganizationItem = useCallback(
        (organization: OrganizationI | OtherOrganizationI) => (
            <OrganizationItem organization={organization} close={close} onSelect={onSelect} key={organization.id} />
        ),
        [close, onSelect]
    )

    return (
        <div className={`user-organization-select ${classnames ?? ""}`} onClick={onRootClick}>
            <div className="user-organization-select-item" onClick={handleClick}>
                <div className="user-organization-item">
                    {selectedOrganization && !searchAnotherOrganization ? (
                        <>
                            <figure className="no-image">{getInitials(selectedOrganization.name)}</figure>
                            <span>{selectedOrganization.name}</span>
                        </>
                    ) : (
                        <span>{formatMessage(messages.selectOrganization)}</span>
                    )}
                </div>
                {!readOnly && (
                    <DownChevronIcon
                        size={20}
                        color="var(--color-grey)"
                        className={opened ? "organization-arrow-open" : "organization-arrow-close"}
                    />
                )}
            </div>
            {opened && (
                <ul className="user-organization-select-list">
                    {organizations
                        .filter((organization) => isNotCurrentOrganization(organization, searchAnotherOrganization))
                        .map(renderOrganizationItem)}
                    {withOtherOrganization ? renderOrganizationItem(otherOrganization) : null}
                </ul>
            )}
        </div>
    )
}
