import { MessageDescriptor, defineMessages } from "react-intl"

import { ViewTypeI } from "~/types"

export const nameColumnLabel: Record<ViewTypeI, MessageDescriptor> = defineMessages({
    [ViewTypeI.supplier]: {
        id: "account.documents.table.label.supplierName",
        defaultMessage: "Buyer",
    },
    [ViewTypeI.buyer]: {
        id: "account.documents.table.label.buyerName",
        defaultMessage: "Supplier",
    },
})

export const columnsLabels = defineMessages({
    labelTags: {
        id: "account.documents.table.label.tags",
        defaultMessage: "Tags",
    },
    labelStatus: {
        id: "account.documents.table.label.status",
        defaultMessage: "Status",
    },
    labelUpdateDate: {
        id: "account.documents.table.label.updateDate",
        defaultMessage: "Last Update",
    },
    labelIssueDate: {
        id: "account.documents.table.label.issueDate",
        defaultMessage: "Issue Date",
    },
    labelDueDate: {
        id: "account.documents.table.label.dueDate",
        defaultMessage: "Due Date",
    },
    labelElapsedTime: {
        id: "account.documents.table.label.elapsedTime",
        defaultMessage: "Elapsed time",
    },
    tooltipElapsedTime: {
        id: "account.documents.table.label.elapsedTooltip",
        defaultMessage:
            "Elapsed time is calculated as the difference between the invoice's issue date and today, excluding paid invoices.",
    },
    subtitleDays: {
        id: "account.documents.table.label.days",
        defaultMessage: "(days)",
    },
    labelReference: {
        id: "account.documents.table.label.ID",
        defaultMessage: "ID",
    },
    labelPONumber: {
        id: "account.documents.table.label.purchaseOrderNumber",
        defaultMessage: "P.O",
    },
    labelSource: {
        id: "account.documents.table.label.source",
        defaultMessage: "Source",
    },
    labelAmount: {
        id: "account.documents.table.label.amount",
        defaultMessage: "Total (tax incl)",
    },
    labelPaidAt: {
        id: "account.documents.table.label.paidAt",
        defaultMessage: "Paid At",
    },
    labelDescription: {
        id: "account.documents.table.label.description",
        defaultMessage: "Description",
    },
    labelActions: {
        id: "account.documents.table.label.actions",
        defaultMessage: " ",
    },
    labelPennylaneStatus: {
        id: "account.documents.table.label.pennylaneStatus",
        defaultMessage: "Sync. Pennylane",
    },
})
