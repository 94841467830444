import * as Sentry from "@sentry/browser"
import { defineMessage, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { getImportCompanyIdentifierOrEmptyString } from "~/store/invoice/core"
import { CreateOrganizationResponseI, ImportInvoiceCompanyInfoI } from "~/types"

const errorMessage = defineMessage({
    id: "organization.create.error",
    defaultMessage: "Une erreur est survenue lors de la création de l'organisation",
})

export const useCreateOrganizationWithoutRegistration = () => {
    const { formatMessage } = useIntl()

    const createNewOrganization = async (
        partner: ImportInvoiceCompanyInfoI
    ): Promise<CreateOrganizationResponseI | null> => {
        try {
            return await organizationApi.createOrganization(
                partner.name,
                partner.countryCode,
                getImportCompanyIdentifierOrEmptyString(partner)
            )
        } catch (error) {
            toast.error(formatMessage(errorMessage))
            Sentry.captureException(error, {
                extra: { partner },
            })
            return null
        }
    }

    return { createNewOrganization }
}
