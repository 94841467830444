import { Hash } from "react-feather"

import { GetTagByGroupConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { GetTagByGroupNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import { GetTagByGroupNode as GetTagByGroupNodeType, Node, NodeType } from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const getTagByGroupNode: Omit<GetTagByGroupNodeType, keyof Node> = {
    objectId: `{{ trigger_event.id }}`,
    tagGroupId: "",
    type: NodeType.GET_TAG_BY_GROUP_NODE,
    nextNode: null,
}

const advancedFields: (keyof typeof getTagByGroupNode)[] = [AllAdvancedFieldsUnion.OBJECT_ID]

const getTagByGroupConfig: Configuration<NodeType.GET_TAG_BY_GROUP_NODE> = {
    type: NodeType.GET_TAG_BY_GROUP_NODE,
    baseSlug: "get-tag-by-group",
    Icon: Hash,
    Configuration: GetTagByGroupConfiguration,
    advancedFields,
    Node: GetTagByGroupNode,
    factory: (props: FactoryProps): GetTagByGroupNodeType => ({
        ...getTagByGroupNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { getTagByGroupConfig, advancedFields as getTagByGroupAdvancedFields }
