import { TextField } from "@mui/material"
import { FC } from "react"
import { ChangeEvent } from "react"
import { defineMessages, useIntl } from "react-intl"

import { PartnerPaymentDetailsSelector } from "~/domains/identity/partners/components/PartnerSelectors"
import { PartnerProfilePaymentDetailI } from "~/domains/identity/partners/types"
import { OCRInputTypes } from "~/domains/transactions/invoices/components/OcrExtract/extract"
import { useToggleShowShapes } from "~/domains/transactions/invoices/components/OcrExtract/hooks"
import { PaidStatus } from "~/domains/transactions/invoices/components/Payment/PaidStatus"
import { displayIban } from "~/domains/transactions/invoices/hooks"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { ImportingInvoiceI, InvoicePaymentDetailsI, OrganizationId } from "~/types/"

import { IBANValidationWarning } from "./IbanValidationCheck"

const messages = defineMessages({
    suppliersIBAN: {
        id: "buyer.import.page.paymentDetails.suppliersIBAN",
        defaultMessage: "Supplier's IBAN",
    },
})

interface PaymentIbanInputProps {
    iban?: string | null
    partnerId?: OrganizationId | null
    updateData: ((data: Partial<ImportingInvoiceI>) => void) | ((data: Partial<InvoicePaymentDetailsI>) => void)
    isIbanDisabled: boolean
    isIbanRequired: boolean
    paidStatus: PaidStatus
}

export const PaymentIbanInput: FC<PaymentIbanInputProps> = ({
    iban,
    partnerId,
    updateData,
    isIbanDisabled,
    isIbanRequired,
    paidStatus,
}) => {
    const { formatMessage } = useIntl()
    const toggleShowShapes = useToggleShowShapes("iban", OCRInputTypes.PAYMENT)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const onPartnerPaymentDetailsChange = (data: PartnerProfilePaymentDetailI | null) => {
        updateData?.({ iban: data?.iban ?? "" })
    }

    const onIbanChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateData({
            iban: event.currentTarget.value,
        })
    }

    if (currentOrganizationId && partnerId) {
        return (
            <div className="invoice-field mb-12">
                <div className="invoice-field-container">
                    <PartnerPaymentDetailsSelector
                        currentOrganizationId={currentOrganizationId}
                        partnerId={partnerId}
                        onPaymentDetailChange={onPartnerPaymentDetailsChange}
                        label={formatMessage(messages.suppliersIBAN)}
                        isIbanRequired={isIbanRequired}
                        isIbanDisabled={isIbanDisabled}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="invoice-field">
                <div className="invoice-field-container">
                    <TextField
                        key={paidStatus}
                        id="paymentDetails.iban"
                        name="paymentDetails.iban"
                        label={formatMessage(messages.suppliersIBAN)}
                        placeholder="IBAN"
                        onChange={onIbanChange}
                        value={displayIban(iban)}
                        fullWidth
                        disabled={isIbanDisabled}
                        required={isIbanRequired}
                        onFocus={toggleShowShapes}
                    />
                </div>
            </div>
            <IBANValidationWarning iban={iban} />
        </>
    )
}
