/* eslint-disable complexity */

/* eslint-disable no-nested-ternary */
import { useCallback, useMemo } from "react"
import { Check, CheckCircle, Edit, FileText, Package, Send, Share2, Truck, UserCheck } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { StepI, Steps } from "~/components"
import {
    PurchaseOrderExtraProgressStatus,
    PurchaseOrderProgressStatusExtended,
} from "~/domains/transactions/_shared/components/Steps/Step"
import { selectPurchaseOrderRelationsState } from "~/domains/transactions/_shared/document-relations/store/documentRelationsSlice"
import { FulfillmentStatus, FulfillmentStatusOrder } from "~/domains/transactions/_shared/types/Purchases"
import { allLinesApproved } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import {
    PurchaseOrderApprovalStatus,
    PurchaseOrderProgressStatus,
    PurchaseOrderStatus,
    PurchaseOrderStatusOrder,
    PurchaseOrdersResponse,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useAppSelector } from "~/store/hooks"
import { ViewTypeI } from "~/types"

enum ApprovalStatusType {
    BUYER_APPROVAL = "buyerApprovalStatus",
    SUPPLIER_APPROVAL = "supplierApprovalStatus",
}

enum FulfillmentStatusType {
    BUYER_FULFILLMENT = "buyerFulfillment",
    SUPPLIER_FULFILLMENT = "supplierFulfillment",
}

const messages = defineMessages({
    approved: {
        id: "purchase.orders.order.steps.approved",
        defaultMessage: "Approved",
    },
    PODraft: {
        id: "purchase.orders.order.steps.PODraft",
        defaultMessage: "Draft",
    },
    POSubmitted: {
        id: "purchase.orders.order.steps.POSubmitted",
        defaultMessage: "Submitted",
    },
    POShared: {
        id: "purchase.orders.order.steps.POShared",
        defaultMessage: "Shared",
    },
    mutuallyAccepted: {
        id: "purchase.orders.order.steps.mutuallyAccepted",
        defaultMessage: "Accepted",
    },
    inPreparation: {
        id: "purchase.orders.order.steps.inPreparation",
        defaultMessage: "In preparation",
    },
    inDelivery: {
        id: "purchase.orders.order.steps.isTransit",
        defaultMessage: "In transit",
    },
    delivered: {
        id: "purchase.orders.order.steps.delivered",
        defaultMessage: "Delivered",
    },
    received: {
        id: "purchase.orders.order.steps.received",
        defaultMessage: "Received",
    },
    invoiced: {
        id: "purchase.orders.order.steps.invoiced",
        defaultMessage: "Invoiced",
    },
    items: {
        id: "purchase.orders.order.steps.items",
        defaultMessage: "{current}/{total} items",
    },
})

interface StepsProps {
    PO: PurchaseOrdersResponse
    viewType: ViewTypeI
}

export function StepsPurchaseOrder({ PO, viewType }: StepsProps) {
    const { formatMessage } = useIntl()
    const isBuyerView = viewType === ViewTypeI.buyer

    const isDraft = PO.status === PurchaseOrderStatus.DRAFT

    const { purchaseOrderRelations } = useAppSelector(selectPurchaseOrderRelationsState)
    const areAllLinesApproved = useMemo(() => allLinesApproved(PO.lines, viewType), [PO.lines, viewType])

    const getNumberOfLinesByProgress = useCallback(
        (progress: PurchaseOrderProgressStatusExtended | undefined): number => {
            const lines = PO.lines

            const getFilteredLines = (statusKey: ApprovalStatusType, approvalStatus: PurchaseOrderApprovalStatus) =>
                lines.filter((line) => line[statusKey] === approvalStatus).length

            const getFilteredFulfillmentLines = (
                fulfillmentKey: FulfillmentStatusType,
                minFulfillmentStatus: FulfillmentStatus
            ) =>
                lines.filter(
                    (line) =>
                        FulfillmentStatusOrder.indexOf(line[fulfillmentKey]) >=
                        FulfillmentStatusOrder.indexOf(minFulfillmentStatus)
                ).length

            if (progress === PurchaseOrderExtraProgressStatus.INVOICED && purchaseOrderRelations.length) {
                return lines.length
            }

            switch (progress) {
                case PurchaseOrderProgressStatus.SUBMITTED:
                    return lines.length

                case PurchaseOrderProgressStatus.INTERNALLY_APPROVED:
                    return getFilteredLines(ApprovalStatusType.BUYER_APPROVAL, PurchaseOrderApprovalStatus.APPROVED)

                case PurchaseOrderProgressStatus.SHARED:
                    return PO.status === PurchaseOrderStatus.OPEN
                        ? getFilteredLines(ApprovalStatusType.BUYER_APPROVAL, PurchaseOrderApprovalStatus.APPROVED)
                        : 0

                case PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED:
                    return getFilteredLines(ApprovalStatusType.SUPPLIER_APPROVAL, PurchaseOrderApprovalStatus.APPROVED)

                case PurchaseOrderProgressStatus.IN_PREPARATION:
                case PurchaseOrderProgressStatus.SHIPPED:
                case PurchaseOrderProgressStatus.RECEIVED: {
                    const buyerFulfillmentCount = getFilteredFulfillmentLines(
                        FulfillmentStatusType.BUYER_FULFILLMENT,
                        progress === PurchaseOrderProgressStatus.IN_PREPARATION
                            ? FulfillmentStatus.IN_PREPARATION
                            : progress === PurchaseOrderProgressStatus.SHIPPED
                              ? FulfillmentStatus.OUT_FOR_DELIVERY
                              : FulfillmentStatus.DELIVERED
                    )

                    const supplierFulfillmentCount = getFilteredFulfillmentLines(
                        FulfillmentStatusType.SUPPLIER_FULFILLMENT,
                        progress === PurchaseOrderProgressStatus.IN_PREPARATION
                            ? FulfillmentStatus.IN_PREPARATION
                            : progress === PurchaseOrderProgressStatus.SHIPPED
                              ? FulfillmentStatus.OUT_FOR_DELIVERY
                              : FulfillmentStatus.DELIVERED
                    )

                    return Math.max(buyerFulfillmentCount, supplierFulfillmentCount)
                }
                default:
                    return 0
            }
        },
        [PO.lines, PO.status, purchaseOrderRelations]
    )

    let steps: StepI[]
    if (isBuyerView) {
        steps = [
            {
                key: "submitted",
                name: formatMessage(messages.POSubmitted),
                progress: PurchaseOrderProgressStatus.SUBMITTED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.SUBMITTED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SUBMITTED)
                        ? "done"
                        : "next",
                icon: <Send size={18} />,
            },
            {
                key: "approved",
                name: formatMessage(messages.approved),
                progress: PurchaseOrderProgressStatus.INTERNALLY_APPROVED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.INTERNALLY_APPROVED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                        PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.INTERNALLY_APPROVED) &&
                    areAllLinesApproved
                        ? "done"
                        : "next",
                icon: <Check size={18} />,
            },
            {
                key: "POSent",
                name: formatMessage(commonMessages.sent),
                progress: PurchaseOrderProgressStatus.SHARED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.SHARED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                        PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHARED) ||
                    PO.status === PurchaseOrderStatus.OPEN
                        ? "done"
                        : "next",
                icon: <Share2 size={18} />,
            },
            {
                key: "mutuallyAccepted",
                name: formatMessage(messages.mutuallyAccepted),
                progress: PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED)
                        ? "done"
                        : "next",
                icon: <CheckCircle size={18} />,
            },
            {
                key: "inPreparation",
                name: formatMessage(messages.inPreparation),
                progress: PurchaseOrderProgressStatus.IN_PREPARATION,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.IN_PREPARATION),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.IN_PREPARATION)
                        ? "done"
                        : "next",
                icon: <Package size={18} />,
            },
            {
                key: "inDelivery",
                name: formatMessage(messages.inDelivery),
                progress: PurchaseOrderProgressStatus.SHIPPED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.SHIPPED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHIPPED)
                        ? "done"
                        : "next",
                icon: <Truck size={18} />,
            },
            {
                key: "received",
                name: formatMessage(messages.received),
                progress: PurchaseOrderProgressStatus.RECEIVED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.RECEIVED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.RECEIVED)
                        ? "done"
                        : "next",
                icon: <UserCheck size={18} />,
            },
            {
                key: "invoiced",
                name: formatMessage(messages.invoiced),
                progress: PurchaseOrderExtraProgressStatus.INVOICED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderExtraProgressStatus.INVOICED),
                totalLines: PO.lines.length,
                status: purchaseOrderRelations?.length ? "done" : "next",
                icon: <FileText size={18} />,
            },
        ]
        if (isDraft) {
            steps.unshift({
                key: "draft",
                name: formatMessage(messages.PODraft),
                status: "done",
                totalLines: PO.lines.length,
                icon: <Edit size={18} />,
            })
        }
    } else {
        steps = [
            {
                key: "received",
                name: formatMessage(messages.received),
                progress: PurchaseOrderProgressStatus.SHARED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.SHARED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHARED)
                        ? "done"
                        : "next",
                icon: <Send size={18} />,
            },
            {
                key: "approved",
                name: formatMessage(messages.mutuallyAccepted),
                progress: PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED)
                        ? "done"
                        : "next",
                icon: <Check size={18} />,
            },
            {
                key: "inPreparation",
                name: formatMessage(messages.inPreparation),
                progress: PurchaseOrderProgressStatus.IN_PREPARATION,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.IN_PREPARATION),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.IN_PREPARATION)
                        ? "done"
                        : "next",
                icon: <Package size={18} />,
            },
            {
                key: "inDelivery",
                name: formatMessage(messages.inDelivery),
                progress: PurchaseOrderProgressStatus.SHIPPED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.SHIPPED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHIPPED)
                        ? "done"
                        : "next",
                icon: <Truck size={18} />,
            },
            {
                key: "delivered",
                name: formatMessage(messages.delivered),
                progress: PurchaseOrderProgressStatus.RECEIVED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderProgressStatus.RECEIVED),
                totalLines: PO.lines.length,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.RECEIVED)
                        ? "done"
                        : "next",
                icon: <UserCheck size={18} />,
            },
            {
                key: "invoiced",
                name: formatMessage(messages.invoiced),
                progress: PurchaseOrderExtraProgressStatus.INVOICED,
                linesInProgress: getNumberOfLinesByProgress(PurchaseOrderExtraProgressStatus.INVOICED),
                totalLines: PO.lines.length,
                status: purchaseOrderRelations?.length ? "done" : "next",
                icon: <FileText size={18} />,
            },
        ]
    }

    return <Steps steps={steps} />
}
