import { capitalize } from "lodash"

import { lineItemFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/parts/lineItemFromApiAdapter"
import { organizationFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/parts/organizationFromApiAdapter"
import { InvoiceAPI } from "~/domains/transactions/invoices-v1/types/Invoice"
import { CurrencyCodes, InvoiceBackendVersion, InvoiceI, InvoicePaymentDetailsI, InvoiceStatus } from "~/types"

export const invoiceFromApiAdapter = (invoice: InvoiceAPI): InvoiceI => {
    const payer = invoice.parties?.[invoice.payerId] || { id: invoice.payerId }
    const seller = invoice.parties?.[invoice.sellerId] || { id: invoice.sellerId }

    return {
        version: InvoiceBackendVersion.V1,
        invoiceId: invoice.id,
        id: invoice.id,

        number: invoice.number,
        reference: invoice.number,

        // InvoiceI has no currency, this conflicts with DocumentI
        // Both InvoiceI and DocumentI are used in the same components
        currency: (Object.keys(invoice.totalAmountDue || {})[0] || CurrencyCodes.EUR) as CurrencyCodes,

        issuedAt: invoice.issuedAt,
        issueDate: invoice.issuedAt,
        dueDate: invoice.dueDate,

        notes: invoice.notes || "",
        description: invoice.notes || "",

        status: (invoice.lifecycleStatus?.toUpperCase() || InvoiceStatus.DRAFT) as InvoiceStatus,
        lifecycleStatus: capitalize(invoice.lifecycleStatus),

        parties: invoice.parties,

        payerId: invoice.payerId,
        buyerId: invoice.payerId,
        buyer: organizationFromApiAdapter(payer),

        sellerId: invoice.sellerId,
        supplierId: invoice.sellerId,
        supplier: organizationFromApiAdapter(seller),

        lines: (invoice.lineItems || []).map(lineItemFromApiAdapter),
        lineItems: (invoice.lineItems || []).map(lineItemFromApiAdapter),

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        totalAmountDue: invoice.totalPriceWithoutTax as any,
        total: Object.values(invoice.totalPriceWithoutTax).reduce((sum, amount) => sum + +amount, 0),

        tax: invoice.tax,
        totalPriceWithoutTax: invoice.totalPriceWithoutTax,
        totalExcludedTaxes: Object.values(invoice.totalPriceWithoutTax).reduce((sum, amount) => sum + +amount, 0),

        buyerTaxes: [],
        supplierTaxes: [],

        paidAt: "0",
        fileLinks: invoice.fileLinks || {
            pdfLink: "",
            bboxLink: "",
            imageLinks: [],
            originalImageLinks: [],
        },

        involvedPeople: (payer?.contacts || []).map((contact) => ({ userId: contact })),
        supplierInvolvedPeople: (seller?.contacts || []).map((contact) => ({ userId: contact })),

        signed: true,
        totalDiscount: Object.values(invoice.flatDiscount ?? {}).reduce((sum, amount) => sum + +amount, 0),
        flatDiscount: invoice.flatDiscount,
        discountRate: invoice.discountRate,
        paymentInstructions: invoice.paymentInstructions,
        paymentDetails: { iban: invoice.paymentInstructions || "" } as InvoicePaymentDetailsI,

        budgets: invoice.budgets || [],

        nextCursor: invoice.nextCursor,
    }
}
