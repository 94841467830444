import { Stack, Typography } from "@mui/material"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Card, SafeFormattedMessage } from "~/components"

import { useFormViewerContext } from "./FormViewerProvider"

const messages = defineMessages({
    nextSection: {
        id: "smartForms.nextSection",
        defaultMessage: "Next section",
    },
    previousSection: {
        id: "smartForms.previousSection",
        defaultMessage: "Previous section",
    },
    send: {
        id: "smartForms.buttonSend",
        defaultMessage: "Send",
    },
    formSent: {
        id: "smartForms.formSent",
        defaultMessage: "Form sent",
    },
})

export const FormViewerFooter = () => {
    const {
        formData,
        currentSection,
        setCurrentSection,
        currentSectionInvalid,
        validateSection,
        sendForm,
        surveyStateLoading,
    } = useFormViewerContext()

    if (!formData) {
        return null
    }

    const isLastSection = () => currentSection === (formData.sections || []).length - 1

    const nextSection = () => {
        const isSectionInvalid = validateSection()

        if (!isSectionInvalid) {
            setCurrentSection(currentSection + 1)
        }
    }

    const handleSendForm = () => {
        const isSectionInvalid = validateSection()

        if (!isSectionInvalid) {
            sendForm()
        }
    }

    return (
        <Card sx={{ position: "sticky", bottom: 0, marginTop: "var(--spacing-xs)" }}>
            <Stack justifyContent="space-between" direction="row" margin={1}>
                {currentSection > 0 ? (
                    <Button onClick={() => setCurrentSection(currentSection - 1)}>
                        <SafeFormattedMessage {...messages.previousSection} />
                    </Button>
                ) : (
                    <div></div>
                )}
                {currentSectionInvalid ? (
                    <Stack display="flex" direction="row" alignItems="center">
                        <Typography variant="body2" color="error">
                            <SafeFormattedMessage {...commonMessages.errorMissingRequiredFields} />
                        </Typography>
                    </Stack>
                ) : (
                    <></>
                )}
                {!isLastSection() ? (
                    <Button onClick={nextSection} disabled={surveyStateLoading}>
                        <SafeFormattedMessage {...messages.nextSection} />
                    </Button>
                ) : (
                    <div>
                        <Button className="flex-grow" disabled={surveyStateLoading} onClick={handleSendForm}>
                            <SafeFormattedMessage {...messages.send} />
                        </Button>
                    </div>
                )}
            </Stack>
        </Card>
    )
}
