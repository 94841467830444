import { Stack, Switch, Typography } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import {
    paymentMethodDetailsActions,
    selectShowDeletedPaymentMethodDetails,
} from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    showDeleted: {
        id: "payment.paymentMethodDetails.showDeleted",
        defaultMessage: "Show deleted",
    },
})

export const PaymentMethodDetailsDeletedToggle = () => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const showDeletedPaymentMethodDetails = useAppSelector(selectShowDeletedPaymentMethodDetails)

    const handleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        dispatch(paymentMethodDetailsActions.setShowDeleted(checked))
    }

    return (
        <Stack flexDirection="row" alignItems="center" gap={2}>
            <Typography component="p" color="var(--color-grey-light)">
                {formatMessage(messages.showDeleted)}
            </Typography>
            <Switch checked={showDeletedPaymentMethodDetails} color="primary" onChange={handleChange} />
        </Stack>
    )
}
