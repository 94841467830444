import { Tooltip } from "@mui/material"
import { Truck } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"
import { FulfillmentStatus } from "~/domains/transactions/_shared/types/Purchases"
import { useUpdateFulfillmentStatusMutation } from "~/domains/transactions/purchase-orders/api/purchaseOrderApi"
import { useFetchPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    inTransit: {
        id: "purchase.orders.order.action.inTransit",
        defaultMessage: "Mark all as in transit",
    },
})

interface OutForDeliveryPOProps {
    PO: PurchaseOrders
}

export const OutForDeliveryPO = ({ PO }: OutForDeliveryPOProps) => {
    const { formatMessage } = useIntl()

    const organization = useAppSelector(selectCurrentOrganization)
    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization?.id)
    const [updateFulfillmentStatus] = useUpdateFulfillmentStatusMutation()

    const handleOutForDelivery = async () => {
        if (!organization?.id) return

        const lines = PO.lines.map((line) => ({ id: line.id ?? "", quantity: line.quantity ?? 1 }))

        await updateFulfillmentStatus({
            organizationId: organization.id,
            purchaseOrderId: PO.id,
            lines,
            status: FulfillmentStatus.OUT_FOR_DELIVERY,
        })
        await fetchPurchaseOrder(PO.id)
    }

    return (
        <Tooltip title={formatMessage(messages.inTransit)}>
            <span>
                <Button type="primary-light" onClick={handleOutForDelivery}>
                    <Truck size={18} />
                    <SafeFormattedMessage {...messages.inTransit} />
                </Button>
            </span>
        </Tooltip>
    )
}
