import { styled } from "@mui/material"

export const EdgeLabel = styled("div")<{ labelX: number; labelY: number }>(({ labelX, labelY }) => ({
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
    // everything inside EdgeLabelRenderer has no pointer events by default
    // if you have an interactive element, set pointer-events: all
    width: "15%",
    height: "15%",
    borderRadius: "50%",
    pointerEvents: "all", // Change to none to allow dragging of underlying elements
    cursor: "initial",
    "&:hover button": {
        opacity: 1,
    },
}))
