import * as t from "io-ts"

import { optional } from "~/utils/io-ts"

export const AssessmentStandardRatingIO = t.partial({
    rating: optional(t.string),
    scoreDate: optional(t.string),
    financialStrength: optional(t.string),
    riskSegment: optional(t.string),
})

export const AssessmentMetricsIO = t.type({
    standardRating: AssessmentStandardRatingIO,
})

export type AssessmentStandardRatingI = t.TypeOf<typeof AssessmentStandardRatingIO>
export type AssessmentMetricsI = t.TypeOf<typeof AssessmentMetricsIO>
