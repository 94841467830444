import { Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { PaymentMethodDetails, PaymentMethodDetailsStatus } from "~/domains/payment/payment-method-details/types"

const messages = defineMessages({
    activeLabel: {
        id: "payment.paymentMethodDetails.infoCards.activeLabel",
        defaultMessage: "Active payment method(s)",
    },
    expiredLabel: {
        id: "payment.paymentMethodDetails.infoCards.expiredLabel",
        defaultMessage: "Expired payment method(s)",
    },
    totalLabel: {
        id: "payment.paymentMethodDetails.infoCards.totalLabel",
        defaultMessage: "Total payment method(s)",
    },
    typesLabel: {
        id: "payment.paymentMethodDetails.infoCards.typesLabel",
        defaultMessage: "Types of payment method(s)",
    },
})

interface CardI {
    label: string
    value: number
}

interface InfoCardsProps {
    items: PaymentMethodDetails[]
}

export const InfoCards = ({ items }: InfoCardsProps) => {
    const { formatMessage } = useIntl()
    const totalTypes = items.reduce((acc, item) => {
        if (item.payment_method_id) {
            acc.add(item.payment_method_id)
        }
        return acc
    }, new Set()).size

    const expiredItems = items.filter((item) => item.status === PaymentMethodDetailsStatus.EXPIRED).length
    const activeItems = items.filter(({ status }) => status === PaymentMethodDetailsStatus.ACTIVE).length
    const totalItems = items.filter(({ status }) => status !== PaymentMethodDetailsStatus.DELETED).length

    const cards: CardI[] = useMemo(
        () => [
            { label: formatMessage(messages.totalLabel), value: totalItems },
            { label: formatMessage(messages.activeLabel), value: activeItems },
            { label: formatMessage(messages.expiredLabel), value: expiredItems },
            { label: formatMessage(messages.typesLabel), value: totalTypes },
        ],
        [totalItems, activeItems, expiredItems, totalTypes, formatMessage]
    )

    return (
        <Stack flexDirection="row" gap={2}>
            {cards.map(({ label, value }) => (
                <Card key={label} sx={{ flex: "1" }}>
                    <Stack gap={2}>
                        <Typography color="var(--color-grey-light)" component="small">
                            {label}
                        </Typography>
                        <Typography color="var(--color-grey)" variant="h2">
                            {value}
                        </Typography>
                    </Stack>
                </Card>
            ))}
        </Stack>
    )
}
