import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"
import {
    PaymentBatchInstances,
    PaymentBatchesConfigurations,
    PaymentMethodDetailsPage,
} from "~/domains/payment/payment-batches/pages"

import { PAYMENT_BATCHES, PAYMENT_BATCH_INSTANCES, PAYMENT_METHOD_DETAILS } from "./routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="payment_batches_routes">
            <Route path={PAYMENT_BATCH_INSTANCES} element={<PaymentBatchInstances />} />
            <Route path={PAYMENT_BATCHES} element={<PaymentBatchesConfigurations />} />
            <Route path={PAYMENT_METHOD_DETAILS} element={<PaymentMethodDetailsPage />} />
        </Route>,
    ],
}
