import * as t from "io-ts"

import { TaskI, TaskId, TaskPriority, TaskStatus } from "~/domains/communication/tasks/types"
import { OrganizationId, UserId } from "~/types"
import { decodeAndCaptureErrors, optional } from "~/utils"

const TaskIO = t.intersection([
    t.type({
        id: t.string,
        title: t.string,
        status: t.string,
        public: optional(t.boolean),
        parties: t.array(t.string),
        followers: t.array(t.string),
        creation_date: t.string,
        last_update_date: t.string,
        organization_id: t.string,
    }),
    t.partial({
        description: optional(t.string),
        due_date: optional(t.string),
        priority: optional(t.string),
        assignee: optional(t.string),
        parent_task_id: optional(t.string),
        subTasks: optional(t.array(t.unknown)),
    }),
])

const TaskStatusIO = t.keyof<Record<TaskStatus, null>>({
    [TaskStatus.COMPLETED]: null,
    [TaskStatus.IN_PROGRESS]: null,
    [TaskStatus.PENDING]: null,
})

const TaskPriorityIO = t.keyof<Record<TaskPriority, null>>({
    [TaskPriority.LOW]: null,
    [TaskPriority.MEDIUM]: null,
    [TaskPriority.HIGH]: null,
    [TaskPriority.URGENT]: null,
})

export const taskFromApiAdapter = (data: unknown): TaskI => {
    const taskData = decodeAndCaptureErrors(data, TaskIO)
    return {
        id: taskData.id as TaskId,
        title: taskData.title ?? "",
        description: taskData.description ?? "",
        status: TaskStatusIO.is(taskData.status) ? (taskData.status as TaskStatus) : TaskStatus.PENDING,
        dueDate: taskData.due_date ?? null,
        priority: TaskPriorityIO.is(taskData.priority) ? (taskData.priority as TaskPriority) : null,
        assignee: taskData.assignee as UserId | null,
        public: !!taskData.public,
        parties: taskData.parties as OrganizationId[],
        parentTaskId: taskData.parent_task_id as TaskId | null,
        followers: taskData.followers as UserId[],
        creationDate: taskData.creation_date,
        lastUpdateDate: taskData.last_update_date,
        organizationId: taskData.organization_id as OrganizationId,
        subTasks: Array.isArray(taskData.subTasks) ? taskData.subTasks.map(taskFromApiAdapter) : null,
    }
}
