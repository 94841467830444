import { Avatar, AvatarGroup, AvatarGroupProps, AvatarProps } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Tag = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== "fullName",
})<AvatarProps & { fullName?: boolean }>(({ fullName }) => ({
    backgroundColor: "var(--color-grey-lighter)",
    width: fullName ? "auto !important" : "inherit",
    minWidth: fullName ? "auto !important" : "inherit",
    padding: fullName ? "0 10px" : "inherit",
    borderRadius: "32px",
    fontSize: "var(--font-size-sm)",
    boxShadow: "0 0 0 1px var(--color-grey-light) inset",
}))

export const TagGroup = (props: AvatarGroupProps) => <AvatarGroup {...props} spacing={10} />
