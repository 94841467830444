import React, { FC, useState } from "react"
import { Plus } from "react-feather"
import { useIntl } from "react-intl"
import { v4 as uuid } from "uuid"

import { Button, SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { Branch } from "~/domains/orchestration/flows/components/configuration/Branch"
import { messages } from "~/domains/orchestration/flows/locale"
import { BranchNode, ConfigurationProps } from "~/domains/orchestration/flows/types"
import type { Branch as BranchType } from "~/domains/orchestration/flows/types"

import "./BranchConfiguration.scss"
import { ConfigurationNode } from "./ConfigurationNode"

export type Branch = {
    id: string
    name: string
    conditionGroups: ConditionGroup[]
    nextNode: string | null
}

export type ConditionGroup = {
    id: string
    conditions: Condition[]
}

export type Condition = {
    id: string
    condition: string
}

const createBranch = (name: string): Branch => ({
    id: uuid(),
    name,
    conditionGroups: [],
    nextNode: null,
})

const createCondition = (condition: string): Condition => ({
    id: uuid(),
    condition,
})

const createConditionGroup = (conditions: string[]): ConditionGroup => ({
    id: uuid(),
    conditions: conditions.map(createCondition),
})

const adaptBranchGroupToBranch = (branch: Branch): BranchType => ({
    name: branch.name,
    conditions: branch.conditionGroups.map((g) => g.conditions.map((c) => c.condition)),
    nextNode: branch.nextNode ?? null,
})

export const BranchConfiguration: FC<ConfigurationProps<BranchNode>> = ({ selectedNode, advancedFields }) => {
    const { formatMessage } = useIntl()
    const [currentNode, setCurrentNode] = useState(selectedNode)

    const initialBranches = currentNode.branches.map((branch) => ({
        id: uuid(),
        name: branch.name,
        nextNode: branch.nextNode,
        conditionGroups: branch.conditions.map(createConditionGroup),
    }))

    const [branches, setBranches] = useState<Branch[]>(initialBranches)

    const updateNode = (newBranches: Branch[]) => {
        const adaptedBranches = newBranches.map(adaptBranchGroupToBranch)
        setCurrentNode({
            ...currentNode,
            branches: adaptedBranches,
        })
    }

    const handleChangeBranch = (branch: Branch) => {
        const newBranches = branches.map((b) => (b.id === branch.id ? branch : b))
        setBranches(newBranches)
        updateNode(newBranches)
    }

    const handleAddBranch = () => {
        const newBranch = createBranch(
            formatMessage(messages.branchConfiguration.branchTitle, {
                index: branches.length + 1,
            })
        )
        const newBranches = [...branches, newBranch]
        setBranches(newBranches)
        updateNode(newBranches)
    }

    const handleDeleteBranch = (branch: Branch) => {
        const newBranches = branches.filter((b) => b.id !== branch.id)
        setBranches(newBranches)
        updateNode(newBranches)
    }

    return (
        <ConfigurationNode selectedNode={currentNode}>
            {branches.map((branch) => (
                <Branch
                    key={branch.id}
                    branch={branch}
                    handleChange={handleChangeBranch}
                    handleDeleteBranch={handleDeleteBranch}
                />
            ))}
            <Button
                type="primary-light"
                onClick={handleAddBranch}
                className="flows-branchConfiguration-newConditionGroup"
            >
                <Plus size={18} />
                <SafeFormattedMessage {...messages.branchConfiguration.newBranchLabel} />
            </Button>

            <AdvancedFields<BranchNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
