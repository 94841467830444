import * as Sentry from "@sentry/react"
import { isRight } from "fp-ts/lib/Either"
import { Decoder } from "io-ts"

import { getValidationErrorsKey } from "~/utils/genericParser"

export function decodeAndCaptureErrors<T>(data: unknown, decoder: Decoder<unknown, T>): T {
    const result = decoder.decode(data)

    if (isRight(result)) return result.right

    const errorsKey = getValidationErrorsKey(result)
    Sentry.captureException(`Unable to parse ${decoder.name} data`, {
        extra: {
            data,
            errorsKey,
            parsingError: result.left,
        },
    })

    return data as T
}
