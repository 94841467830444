import { Avatar, FilterOptionsState, capitalize, createFilterOptions, styled } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { CREATION_ID, OCR_ID, OptionParams, PartnerProfileContactI } from "~/domains/identity/partners/types"
import { getInitials } from "~/store/organization/utils"

const messages = defineMessages({
    myBook: {
        id: "partners.bookofrelations.selectors.myBook",
        defaultMessage: "Partner",
    },
    emailAction: {
        id: "partners.bookofrelations.selectContacts.emailAction",
        defaultMessage: "Add",
    },
    quotedMessage: {
        id: "partners.bookofrelations.selectContacts.quotedMessage",
        defaultMessage: '"{value}"',
    },
})

const filter = createFilterOptions<PartnerProfileContactI>()

export const filterContactOptions = (
    options: PartnerProfileContactI[],
    params: FilterOptionsState<PartnerProfileContactI>
) => {
    const filtered = filter(options, params)
    if (params.inputValue !== "" && !filtered.some((contact) => contact.email === params.inputValue)) {
        filtered.push({ id: CREATION_ID, email: params.inputValue } as PartnerProfileContactI)
    }
    return filtered
}

const ContactContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
})

const ContactInfoContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
})

const AvatarStyle = {
    bgcolor: "var(--color-violet-lighter)",
    width: 28,
    height: 28,
    fontSize: 14,
    color: "var(--primary-color)",
    fontWeight: 900,
}

const getContactName = (option: PartnerProfileContactI | string | null) => {
    if (!option) {
        return ""
    }
    if (typeof option === "string") {
        return option
    }
    const capitalizedFirstName = capitalize(option.firstName ?? "")
    const capitalizedLastName = capitalize(option.lastName ?? "")
    return option.firstName || option.lastName
        ? `${capitalizedFirstName}${option.firstName ? ` ${capitalizedLastName}` : (capitalizedLastName ?? "")}`
        : (option.phoneNumber ?? "")
}

interface RenderOptionProps {
    params: OptionParams
    option: PartnerProfileContactI
}

export const RenderOption = ({ params, option }: RenderOptionProps) => {
    const { formatMessage } = useIntl()
    if (!option) return null

    const contactName = getContactName(option)
    const { key, ...otherParams } = params

    if (option.id === CREATION_ID || option.id === OCR_ID) {
        return (
            <li key={key} {...otherParams} className="autocomplete-contact">
                <ContactInfoContainer>
                    <div className="autocomplete-contact-actions">
                        <span className="autocomplete-contact-actions-label">
                            {formatMessage(messages.emailAction)}
                        </span>
                        <span>{formatMessage(messages.quotedMessage, { value: option.email })}</span>
                    </div>
                </ContactInfoContainer>
            </li>
        )
    }
    return (
        <li key={key} {...otherParams} className="autocomplete-contact">
            <div className="autocomplete-contact-group">
                <ContactContainer>
                    <Avatar sx={AvatarStyle}>{getInitials(contactName)}</Avatar>
                    <ContactInfoContainer>
                        <span className="autocomplete-contact-label">{contactName}</span>
                        {option.email && <span className="autocomplete-contact-email">{option.email}</span>}
                    </ContactInfoContainer>
                </ContactContainer>
            </div>
        </li>
    )
}
