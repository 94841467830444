import React, { useEffect, useMemo } from "react"

import { CompanyResult } from "~/domains/identity/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/domains/identity/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { ModalOrganizationSelectorDetails } from "~/domains/identity/organization/components/ModalOrganizationSelectorDetails"
import { SavePayloadType } from "~/domains/identity/organization/components/ModalOrganizationSelectorDetails/types"
import { useFetchOrganization } from "~/store/organization/hooks"
import { OrganizationAddressI, OrganizationId } from "~/types"

interface Props {
    organizationId: OrganizationId
    open: boolean
    onClose: () => void
    billingAddress?: OrganizationAddressI
    shippingAddress?: OrganizationAddressI
    onSave?: (payload: SavePayloadType) => void
    withOrganizationAddress?: boolean
    withSendingAddress?: boolean
    readOnly?: boolean
}

export const OrganizationDetailsModal: React.FC<Props> = ({
    organizationId,
    open,
    onClose,
    billingAddress,
    shippingAddress,
    withOrganizationAddress,
    withSendingAddress,
    onSave,
    readOnly,
}) => {
    const { organization, error } = useFetchOrganization(organizationId)

    useEffect(() => {
        if (error) {
            console.error(error)
        }
    }, [error])

    const company = useMemo<CompanyResult | undefined>(
        () =>
            organization
                ? {
                      type: CompanyAutocompleteType.Organization,
                      value: {
                          ...organization,
                          address: billingAddress ?? organization?.address,
                      },
                  }
                : undefined,
        [organization, billingAddress]
    )

    const handleSave = (payload: SavePayloadType) => {
        onSave && onSave(payload)
        onClose()
    }

    if (!company || error) {
        return null
    }

    return (
        <ModalOrganizationSelectorDetails
            open={open}
            onClose={onClose}
            company={company}
            shippingAddress={shippingAddress}
            withOrganizationAddress={withOrganizationAddress}
            withSendingAddress={withSendingAddress}
            onSave={handleSave}
            registrationInfoReadOnly
            readOnly={readOnly}
        />
    )
}
