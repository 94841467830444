import React, { useCallback } from "react"

import { MessageContent } from "~/domains/communication/chat/components/Message/MessageContent"
import { useSetMessageAsViewed, useSubscribeOnMessageUpdates } from "~/domains/communication/chat/hooks"
import { RoomId } from "~/domains/communication/chat/types"
import { MessageWithUserI } from "~/domains/communication/chat/types/Message"
import { UserI } from "~/types"

interface Props {
    message: MessageWithUserI
    roomId: RoomId
    currentUser: UserI
    selectMessage: (message: MessageWithUserI) => void
}

export const Message: React.FC<Props> = ({ message, roomId, currentUser, selectMessage }) => {
    const displayMessageViewers = useCallback(() => selectMessage(message), [selectMessage, message])

    useSetMessageAsViewed(currentUser, message)
    useSubscribeOnMessageUpdates(currentUser, message.id)

    return (
        <MessageContent
            displayMessageViewers={displayMessageViewers}
            currentUser={currentUser}
            message={message}
            roomId={roomId}
        />
    )
}
