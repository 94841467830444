import { Box, Stack, Typography, styled } from "@mui/material"
import { FC } from "react"

import { SafeFormattedMessage } from "~/components"
import { FLOW_NODE_LIST } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/locale"
import { NodeType } from "~/domains/orchestration/flows/types"

const listDirection = {
    row: "row",
    column: "column",
} as const

type Direction = (typeof listDirection)[keyof typeof listDirection]

interface Props {
    handleDragStart: (nodeType: NodeType) => (event: React.DragEvent<HTMLDivElement>) => void
    handleClick: (nodeType: NodeType) => void
    showLabels?: boolean
    direction?: Direction
}

const NodeListContainer = styled(Stack)<{ direction: Direction }>(({ direction }) => ({
    backgroundColor: "var(--sidebar-color)",
    padding: direction === listDirection.row ? "var(--spacing-md) var(--spacing-xl)" : "0px",
    width: "fit-content",
    borderRadius: "var(--border-radius-sm)",
}))

export const NodeList: FC<Props> = ({ handleDragStart, handleClick, direction = listDirection.row }) => {
    const handleNodeClick = (nodeType: NodeType) => (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        handleClick(nodeType)
    }

    return (
        <NodeListContainer gap={1} direction={direction}>
            {FLOW_NODE_LIST.map(({ type, Icon }) => (
                <Box
                    key={type}
                    className="flows-editor-sideBar-item"
                    gap={1}
                    onDragStart={handleDragStart(type)}
                    onClick={handleNodeClick(type)}
                    draggable
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Icon size={18} className="flows-editor-sideBar-item-icon" />
                        <Stack className="flows-editor-sideBar-item-content" gap={0.5}>
                            <SafeFormattedMessage tagName="h5" {...messages.nodeTitle[type]} />
                            <Typography variant="caption" lineHeight={1.4} color="text.secondary">
                                <SafeFormattedMessage {...messages.nodeDescription[type]} />
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            ))}
        </NodeListContainer>
    )
}
