import React, { useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Tabs } from "~/components"
import { BasicTabsTabProps } from "~/components/Tabs/BasicTabs"
import { TagGroupI } from "~/domains/analytics/tags/types"
import {
    AnalyticalItem,
    AnalyticsTable,
} from "~/domains/identity/custom-fields/components/AnalyticsTable/AnalyticsTable"
import { CurrencyCodes } from "~/types"

import { CustomFieldsTable } from "./CustomFieldsTable/CustomFieldsTable"
import { ESGCustomFieldsTable, ESGCustomFieldsTableProps } from "./ESGCustomFieldsTable"

const messages = defineMessages({
    items: { id: "purchase.orders.order.items", defaultMessage: "Items" },
    esgCustomFields: { id: "purchase.orders.order.esgCustomFields", defaultMessage: "ESG" },
    analytics: { id: "purchase.orders.order.analytics", defaultMessage: "Analytics" },
    customFields: { id: "purchase.orders.order.customFields", defaultMessage: "Custom Fields" },
})

enum TabsEnum {
    ITEMS,
    ANALYTICAL,
    ESG_CUSTOM_FIELDS,
    CUSTOM_FIELDS,
}

type LinesTabsProps<T extends AnalyticalItem> = React.PropsWithChildren &
    ESGCustomFieldsTableProps<T> & {
        renderLineTags: (line: T, tagGroupId?: string, usedTagGroups?: TagGroupI[]) => JSX.Element | null
        currency: CurrencyCodes
        hasCustomFields?: boolean
    }

export const LinesTabs = <T extends AnalyticalItem>({
    organizationId,
    children,
    currency,
    renderLineTags,
    hasCustomFields,
    ...props
}: LinesTabsProps<T>) => {
    const { formatMessage } = useIntl()
    const [tabValue, setTabValue] = useState(TabsEnum.ITEMS)

    const tabs: BasicTabsTabProps[] = organizationId
        ? [
              {
                  label: formatMessage(messages.items),
                  value: TabsEnum.ITEMS,
                  component: children,
              },
              {
                  label: formatMessage(messages.analytics),
                  value: TabsEnum.ANALYTICAL,
                  component: (
                      <AnalyticsTable
                          items={props.items}
                          currency={currency}
                          renderLineTags={renderLineTags}
                          organizationId={organizationId}
                      />
                  ),
              },
              {
                  label: formatMessage(messages.esgCustomFields),
                  value: TabsEnum.ESG_CUSTOM_FIELDS,
                  component: <ESGCustomFieldsTable {...props} organizationId={organizationId} />,
              },
          ]
        : []

    if (hasCustomFields) {
        tabs.push({
            label: formatMessage(messages.customFields),
            value: TabsEnum.CUSTOM_FIELDS,
            component: <CustomFieldsTable {...props} />,
        })
    }

    const handleChange = (value: string | number) => {
        setTabValue(value as TabsEnum)
    }

    if (!organizationId) {
        return <>{children}</>
    }

    return <Tabs defaultValue={tabValue} setChange={handleChange} tabs={tabs} />
}
