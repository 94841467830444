import { Tooltip } from "@mui/material"
import { useState } from "react"
import { Box } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage, Size } from "~/components"
import { ModalConfirm } from "~/components/ModalConfirm/ModalConfirm"
import { FulfillmentStatus } from "~/domains/transactions/_shared/types/Purchases"
import { useUpdateFulfillmentStatusMutation } from "~/domains/transactions/purchase-orders/api/purchaseOrderApi"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    label: {
        id: "purchase.orders.order.markInPreparation.label",
        defaultMessage: "Mark all as in preparation",
    },
    title: {
        id: "purchase.orders.order.markInPreparation.title",
        defaultMessage: `Mark all items as "In preparation"?`,
    },
    cancel: { id: "common.cancel", defaultMessage: "Cancel" },
    confirm: { id: "purchase.orders.order.markInPreparation.confirm", defaultMessage: "Yes" },
    message: {
        id: "purchase.orders.order.markInPreparation.confirmMessage",
        defaultMessage: `All items are now accepted by the buyer, do you want to mark them as "In preparation"?`,
    },
})

interface MarkAllInPreparationProps {
    PO: PurchaseOrders
}

export function MarkAllInPreparation({ PO }: MarkAllInPreparationProps) {
    const { formatMessage } = useIntl()
    const [displayModal, setDisplayModal] = useState(false)

    const organization = useAppSelector(selectCurrentOrganization)
    const [updateFulfillmentStatus] = useUpdateFulfillmentStatusMutation()

    const handleMarkAllInPreparation = () => {
        if (!organization) return

        const lines = PO.lines.map((line) => ({ id: line.id ?? "", quantity: line.quantity ?? 1 }))
        updateFulfillmentStatus({
            organizationId: organization?.id,
            purchaseOrderId: PO?.id,
            lines,
            status: FulfillmentStatus.IN_PREPARATION,
        })
    }

    const handleOpenModal = (): void => {
        setDisplayModal(true)
    }

    const handleCloseModal = (): void => {
        setDisplayModal(false)
    }

    return (
        <>
            <Tooltip arrow title={formatMessage(messages.label)}>
                <span>
                    <Button type="primary-light" onClick={handleOpenModal}>
                        <Box size={18} />
                        <SafeFormattedMessage {...messages.label} />
                    </Button>
                </span>
            </Tooltip>

            <ModalConfirm
                position="start"
                size={Size.MD}
                message={formatMessage(messages.message)}
                button1={<Button type="neutral" onClick={handleCloseModal} text={formatMessage(messages.cancel)} />}
                button2={
                    <Button type="error" onClick={handleMarkAllInPreparation} text={formatMessage(messages.confirm)} />
                }
                open={displayModal}
            />
        </>
    )
}
