import * as t from "io-ts"

import { MessageTemplateI, MessageTemplateId } from "~/domains/communication/chat/types/MessageTemplate"
import { OrganizationId, UserId } from "~/types"
import { Result, ResultSuccess, isResultError } from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"

export const MessageTemplateIO = t.type({
    id: t.string,
    name: t.string,
    organizationId: t.string,
    content: t.any,
    isGlobal: t.boolean,
    createdBy: t.string,
    createdAt: t.string,
    updatedAt: t.string,
})

export const messageTemplateFromApiAdapter = (data: unknown): Result<MessageTemplateI, ParsingErrorType> => {
    const result = genericParser(data, MessageTemplateIO)
    if (isResultError(result)) return result
    const apiMessageTemplate = result.result
    return ResultSuccess<MessageTemplateI>({
        id: apiMessageTemplate.id as MessageTemplateId,
        name: apiMessageTemplate.name,
        organizationId: apiMessageTemplate.organizationId as OrganizationId,
        content: apiMessageTemplate.content,
        isGlobal: apiMessageTemplate.isGlobal,
        createdBy: apiMessageTemplate.createdBy as UserId,
        updatedAt: apiMessageTemplate.updatedAt,
        createdAt: apiMessageTemplate.createdAt,
    })
}
