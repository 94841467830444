import { Breadcrumbs, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import { ChevronRight } from "react-feather"

import { Card } from "~/components"
import { Step } from "~/domains/transactions/_shared/components/Steps/Step"

export interface StepI {
    key: string
    name: string
    value?: string
    isCurrentLifecycle?: boolean
    done?: boolean
    status?: string
    rejected?: boolean
    description?: string
    onClick?: (status: string) => void
    icon?: React.ReactNode
    progress?: string
    linesInProgress?: number
    totalLines?: number
}

interface StepsProps {
    steps: StepI[]
}

export const Steps = ({ steps }: StepsProps) => {
    const [maxItems, setMaxItems] = useState(steps.length)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    useEffect(() => {
        setMaxItems(isMobile ? 3 : steps.length)
    }, [isMobile, steps.length])

    return (
        <Card className="w-full p-0">
            <Breadcrumbs separator={<ChevronRight color="var(--color-grey)" size={14} />} maxItems={maxItems}>
                {steps.map((step) => (
                    <Step
                        key={step.key}
                        name={step.name}
                        icon={step.icon}
                        status={step.status || (step.isCurrentLifecycle ? "next" : "done")}
                        description={step.description}
                        totalLines={step.totalLines}
                        linesInProgress={step.linesInProgress}
                        onClick={step.onClick}
                    />
                ))}
            </Breadcrumbs>
        </Card>
    )
}
