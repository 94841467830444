import { Tooltip } from "@mui/material"
import { Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { Button } from "~/components/Button/Button"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { useUpdatePurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/useUpdatePurchaseRequest"
import { useUpdatePurchaseRequestStatus } from "~/domains/transactions/purchase-requests/store/hooks/useUpdatePurchaseRequestStatus"
import { PurchaseRequestStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

const messages = defineMessages({
    send: {
        id: "purchase.requests.request.header.actions.send",
        defaultMessage: "Send",
    },
    confirm: {
        id: "purchase.requests.request.modalConfirm.title",
        defaultMessage: "Would you send the Purchase request for approval ?",
    },
    saveAsDraft: {
        id: "purchase.requests.request.modalConfirm.saveDraft",
        defaultMessage: "Save it as draft",
    },
    saveAndSend: {
        id: "purchase.requests.request.modalConfirm.send",
        defaultMessage: "Send for approval",
    },
    success: {
        id: "purchase.requests.request.modalConfirm.successSent",
        defaultMessage: "Purchase Request successfully sent for approval !",
    },
    backToList: {
        id: "purchase.requests.request.modalConfirm.backToList",
        defaultMessage: "Go back to purchase requests",
    },
    createNewPR: {
        id: "purchase.requests.request.modalConfirm.createNewPR",
        defaultMessage: "New Purchase request",
    },
})

interface SumitPRProps {
    organizationId: string
    id: string
    hasPermission: boolean
}

export const SumitPR = ({ organizationId, id, hasPermission }: SumitPRProps) => {
    const { formatMessage } = useIntl()

    const { updatePR } = useUpdatePurchaseRequest(organizationId, id)
    const { updatePRStatus } = useUpdatePurchaseRequestStatus(organizationId, id)

    const permissionLabel = formatMessage(permissionMessages.errorNoAccessAdministrator)

    const handleSend = async () => {
        await updatePR(false)
        await updatePRStatus(PurchaseRequestStatus.SUBMITTED)
    }

    return (
        <Tooltip arrow title={!hasPermission ? permissionLabel : formatMessage(messages.send)}>
            <span>
                <Button type="primary-light" disabled={!hasPermission} onClick={handleSend}>
                    <Send size={18} />
                    <SafeFormattedMessage {...commonMessages.submit} />
                </Button>
            </span>
        </Tooltip>
    )
}
