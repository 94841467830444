import * as t from "io-ts"

import { optional } from "~/utils/io-ts"

const CustomFieldTranslationsIO = t.partial({
    description: optional(t.string),
    name: optional(t.string),
})

export const CustomFieldIO = t.intersection([
    t.type({
        description: t.string,
        name: t.string,
        unit: t.array(t.string),
    }),
    t.partial({
        type: optional(t.string),
        translations: optional(t.record(t.string, CustomFieldTranslationsIO)),
    }),
])

export enum CustomFieldType {
    STRING = "string",
    NUMBER = "number",
}

export type CustomFieldTranslationsI = t.TypeOf<typeof CustomFieldTranslationsIO>

export interface CustomFieldI {
    id: string
    name: string
    description: string
    unit: string[]
    type: CustomFieldType
    translations: Record<string, CustomFieldTranslationsI> | null
}

export const CustomFieldsIO = t.record(t.string, CustomFieldIO)
