import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import dayjs from "dayjs"

import { prepareHeadersWithAuthorizationAndOrganizationId } from "~/api/prepareHeaders"
import { invoiceAuditLogFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/invoiceAuditLogFromApiAdapter"
import {
    GetInvoiceAuditLogQuery,
    InvoiceAuditLogAPI,
} from "~/domains/transactions/invoices-v1/api/types/invoiceAuditLogApi.type"
import { EventI } from "~/domains/transactions/invoices/types/InvoiceTypes"

const BASE_URL = import.meta.env.VITE_API_TRANSACTION_V2_INVOICES_URL + "invoices/"

export const invoiceAuditLogApi = createApi({
    reducerPath: "invoiceAuditLogApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorizationAndOrganizationId }),
    endpoints: (builder) => ({
        getInvoiceAuditLogs: builder.query<EventI[], GetInvoiceAuditLogQuery>({
            query: ({ invoiceId }) => ({ url: `${invoiceId}/audit-log` }),
            transformResponse: (response: InvoiceAuditLogAPI[]) =>
                response.map(invoiceAuditLogFromApiAdapter).sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp))),
        }),
    }),
})

export const { useGetInvoiceAuditLogsQuery } = invoiceAuditLogApi
