import { Tooltip } from "@mui/material"
import { Check } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    approve: { id: "buyer.invoice.actions.button.approve", defaultMessage: "Approve" },
    validateButtonTooltip: {
        id: "buyer.invoice.actions.button.validate.tooltip",
        defaultMessage: "Are all the details on this invoice correct? Please validate it!",
    },
})

interface ApproveInvoiceProps {
    handleApprove?: () => void
}

export const ApproveInvoice = ({ handleApprove }: ApproveInvoiceProps) => {
    const { formatMessage } = useIntl()

    return (
        <Tooltip title={formatMessage(messages.validateButtonTooltip)} arrow>
            <div>
                <Button onClick={handleApprove} type="primary-light">
                    <Check size={18} />
                    <SafeFormattedMessage {...messages.approve} />
                </Button>
            </div>
        </Tooltip>
    )
}
