import { AutocompleteRenderGroupParams, AutocompleteRenderOptionState, Box } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"

import { AutocompleteWithTags } from "~/components"
import { ObjectPermissions } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/ObjectPermissions"
import { useFetchObjectPermissions } from "~/domains/identity/roles-permissions/store/hooks"
import {
    SpiceDBObjectType,
    SpiceDBPermissionsLiteI,
    sharedObjectPermissionsModalMessages,
} from "~/domains/identity/roles-permissions/types/SpiceDB"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationMembers, useFetchOrganizationTeams } from "~/store/organization/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationMemberUserI, OrganizationTeamI } from "~/types"

interface Props {
    objectId: string
    objectType: SpiceDBObjectType
    selectedObjects: SpiceDBPermissionsLiteI[]
    setSelectedObjects: (objects: SpiceDBPermissionsLiteI[]) => void
}

const membersToSpiceDBPermissionsLiteI = (
    members: OrganizationMemberUserI[],
    sharedPermissions: SpiceDBPermissionsLiteI[]
): SpiceDBPermissionsLiteI[] => {
    const permissionsLookup = sharedPermissions.reduce((acc, { objectId, objectType, permissions }) => {
        if (objectType === SpiceDBObjectType.USER) {
            acc[objectId] = permissions
        }
        return acc
    }, {})

    return members.map(({ userId, user: { fullName, email } }) => {
        const permissions = permissionsLookup[userId] ?? []
        return {
            objectId: userId,
            objectType: SpiceDBObjectType.USER,
            name: fullName,
            email,
            permissions,
        }
    })
}

const teamsToSpiceDBPermissionsLiteI = (
    teams: OrganizationTeamI[],
    sharedPermissions: SpiceDBPermissionsLiteI[]
): SpiceDBPermissionsLiteI[] => {
    const permissionsLookup = sharedPermissions.reduce((acc, { objectId, objectType, permissions }) => {
        if (objectType === SpiceDBObjectType.TEAM) {
            acc[objectId] = permissions
        }
        return acc
    }, {})

    return teams.map(({ teamId, name, members }) => {
        const permissions = permissionsLookup[teamId] ?? []
        return {
            objectId: teamId,
            objectType: SpiceDBObjectType.TEAM,
            name,
            membersNumber: members.length,
            permissions,
        }
    })
}

const getOptionLabel = (object: SpiceDBPermissionsLiteI) => object.name ?? ""

const isOptionEqualToValue = (option: SpiceDBPermissionsLiteI, value: SpiceDBPermissionsLiteI) =>
    option.objectId === value.objectId && option.objectType === value.objectType

const groupBy = (option: SpiceDBPermissionsLiteI) => option.objectType

export const SearchObjects: React.FC<Props> = ({ objectId, objectType, selectedObjects, setSelectedObjects }) => {
    const { formatMessage } = useIntl()

    const currentOrganization = useAppSelector(selectCurrentOrganization)
    const [members] = useFetchOrganizationMembers(currentOrganization)
    const { teams: organizationTeams } = useFetchOrganizationTeams(currentOrganization?.id, true)

    const { sharedPermissions } = useFetchObjectPermissions(objectId, objectType)

    const objects = useMemo(() => {
        if (sharedPermissions && (members.length || organizationTeams.length)) {
            return [
                ...membersToSpiceDBPermissionsLiteI(members, sharedPermissions),
                ...teamsToSpiceDBPermissionsLiteI(organizationTeams, sharedPermissions),
            ]
        }
        return []
    }, [members, organizationTeams, sharedPermissions])

    const getGroupTitle = useCallback(
        (type: SpiceDBObjectType): string => {
            switch (type) {
                case SpiceDBObjectType.USER:
                    return formatMessage(sharedObjectPermissionsModalMessages.members)
                case SpiceDBObjectType.TEAM:
                    return formatMessage(sharedObjectPermissionsModalMessages.teams)
            }
            return ""
        },
        [formatMessage]
    )

    const renderGroup = useCallback(
        (params: AutocompleteRenderGroupParams) => (
            <li key={params.key}>
                <Box component="div" sx={{ fontSize: "12px", padding: "8px 16px", color: "var(--color-grey-light)" }}>
                    {getGroupTitle(params.group as SpiceDBObjectType)}
                </Box>
                <ul style={{ paddingLeft: 0 }}>{params.children}</ul>
            </li>
        ),
        [getGroupTitle]
    )

    const renderOption = (props: AutocompleteRenderOptionState, option: SpiceDBPermissionsLiteI) => {
        return (
            <Box component="li" {...props} key={option.objectId}>
                <ObjectPermissions
                    objectId={option.objectId}
                    objectType={option.objectType}
                    objectPermission={option}
                    disabled={true}
                />
            </Box>
        )
    }

    return (
        <AutocompleteWithTags
            classname="autocomplete-with-tags-primary"
            isChips={true}
            options={objects}
            selectedEntities={selectedObjects}
            setSelectedEntities={setSelectedObjects}
            numberOfTagsToShow={2}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            placeholder={formatMessage(sharedObjectPermissionsModalMessages.addMembersOrTeams)}
            groupBy={groupBy}
            renderGroup={renderGroup}
            renderOption={renderOption}
        />
    )
}
