import { Tooltip } from "@mui/material"
import { Download } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    downloadButtonTooltip: { id: "invoice.actions.button.download.tooltip", defaultMessage: "Download this invoice" },
    cannotDownloadFile: {
        id: "invoice.actions.button.cannotDownloadFile",
        defaultMessage: "This invoice has no PDF link",
    },
})

interface DownloadPdfInvoiceProps {
    pdfLink?: null | string
    display?: "menu-item" | "primary-light"
}

export const DownloadPdfInvoice = ({ pdfLink, display = "primary-light" }: DownloadPdfInvoiceProps) => {
    const { formatMessage } = useIntl()

    const handleDownloadPdf = () => {
        if (pdfLink) {
            window.location.href = pdfLink
        }
    }

    return (
        <Tooltip title={formatMessage(messages[pdfLink ? "downloadButtonTooltip" : "cannotDownloadFile"])} arrow>
            <div>
                <Button onClick={handleDownloadPdf} disabled={!pdfLink} type={display}>
                    <Download size={18} />
                    <SafeFormattedMessage {...commonMessages.download} />
                </Button>
            </div>
        </Tooltip>
    )
}
