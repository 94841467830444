import React from "react"
import { RenderElementProps } from "slate-react"

import { MentionElementI } from "~/domains/communication/chat/types"

type Props = Omit<RenderElementProps, "element" | "children"> & { element: MentionElementI }

export const MentionElement: React.FC<Props> = ({ attributes, element }) => {
    return <b {...attributes}>@{element.data.label}</b>
}
