import { TextField } from "@mui/material"
import { ChangeEvent, FC, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { Card } from "~/components"
import { OCRInputTypes } from "~/domains/transactions/invoices/components/OcrExtract/extract"
import { useToggleShowShapes } from "~/domains/transactions/invoices/components/OcrExtract/hooks"
import { useSaveBuffer } from "~/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { usePatchDraftInvoice } from "~/store/invoice/hooks/usePatchDraftInvoice"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { InvoiceI, InvoiceUserType } from "~/types"

const messages = defineMessages({
    description: {
        id: "purchase.requests.request.description",
        defaultMessage: "Description",
    },
})

interface InvoiceDescriptionEditProps extends JSX.IntrinsicAttributes {
    invoice: InvoiceI
}

export const InvoiceDescriptionEdit: FC<InvoiceDescriptionEditProps> = ({ invoice }) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const user = useAppSelector(selectUser)
    const toggleShowShapes = useToggleShowShapes("description", OCRInputTypes.TEXT)

    const saveAsDraftAction = usePatchDraftInvoice(invoice, InvoiceUserType.BUYER, user)
    const [description, setDescription] = useState(invoice?.description)

    const save = () => {
        dispatch(invoiceActions.updatePartialInvoice({ description, id: invoice.id }))
        saveAsDraftAction()
        return
    }

    const { setHasChanges } = useSaveBuffer(save)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value)
        setHasChanges(true)
    }

    return (
        <Card title={formatMessage(messages.description)} expandable>
            <TextField
                required
                id="description"
                name="description"
                label={formatMessage(messages.description)}
                placeholder={formatMessage(messages.description)}
                onChange={handleChange}
                value={description}
                fullWidth
                onFocus={toggleShowShapes}
            />
        </Card>
    )
}
