/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
    DataGridPremium,
    GridColDef,
    GridPaginationModel,
    GridRenderCellParams,
    GridSortDirection,
    GridSortModel,
    GridValueGetterParams,
} from "@mui/x-data-grid-premium"
import { useState } from "react"
import { FormattedDate, FormattedNumber } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Card, CurrencySymbol, Loader, SafeFormattedMessage, StatusChip } from "~/components"
import { useGetPaymentBatchInstancesByOrganizationQuery } from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { BatchInstanceLineActions } from "~/domains/payment/payment-batches/components"
import { PaymentBatchInstance } from "~/domains/payment/payment-batches/types"
import { PartyPaymentMethodDetails } from "~/domains/payment/payment-method-details/components"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "~/utils"

const columns: GridColDef[] = [
    {
        field: "name",
        minWidth: 100,
        flex: 2,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.name} />,
    },
    {
        field: "status",
        minWidth: 100,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatchInstance>) => params.row.status,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.status} />,
        renderCell: ({ value }: GridRenderCellParams) =>
            value ? <StatusChip status={value?.toLowerCase()}>{value?.toLowerCase()}</StatusChip> : "-",
    },
    {
        field: "totalAmount",
        minWidth: 120,
        flex: 1,
        valueGetter: ({ row }: GridValueGetterParams<PaymentBatchInstance>) => [row.totalAmount, row.currency],
        renderHeader: () => <SafeFormattedMessage {...commonMessages.totalAmount} />,
        renderCell: ({ value: [amount, currency] }: GridRenderCellParams) =>
            !amount ? "-" : <FormattedNumber value={amount} style="currency" currency={currency} />,
    },
    {
        field: "dateScheduled",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatchInstance>) => params.row.dateScheduled,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.dateScheduled} />,
        renderCell: (params: GridRenderCellParams<PaymentBatchInstance>) => (
            <FormattedDate value={params.row.dateScheduled ?? ""} day="numeric" month="numeric" year="numeric" />
        ),
    },
    {
        field: "dateExecuted",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatchInstance>) => params.row.dateExecuted,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.dateExecuted} />,
        renderCell: ({ row }: GridRenderCellParams<PaymentBatchInstance>) =>
            row.dateExecuted ? (
                <FormattedDate value={row.dateExecuted ?? ""} day="numeric" month="numeric" year="numeric" />
            ) : (
                "-"
            ),
    },
    {
        field: "currency",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatchInstance>) => params.row.currency,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.currency} />,
        renderCell: ({ value }: GridRenderCellParams) => (value ? <CurrencySymbol currency={value} /> : "-"),
    },
    {
        field: "paymentMethod",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatchInstance>) => [
            params.row.paymentMethodId,
            params.row.paymentMethodDetailsId,
        ],
        renderHeader: () => <SafeFormattedMessage {...commonMessages.paymentMethod} />,
        renderCell: ({ value: [paymentMethodId, paymentMethodDetailsId] }: GridRenderCellParams) => {
            if (!paymentMethodId && !paymentMethodDetailsId) {
                return "-"
            }

            return (
                <PartyPaymentMethodDetails
                    mode="inline"
                    paymentMethodId={paymentMethodId}
                    paymentMethodDetailsId={paymentMethodDetailsId}
                />
            )
        },
    },
    {
        field: "actions",
        sortable: false,
        filterable: false,
        resizable: false,
        disableColumnMenu: true,
        align: "center",
        width: 50,
        renderHeader: () => "",
        renderCell: ({ row }) => <BatchInstanceLineActions row={row} />,
    },
]

const initialState = {
    sortModel: [
        {
            field: "dateScheduled",
            sort: "desc" as GridSortDirection,
        },
    ],
    paginationModel: {
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    },
}

const getRowId = (row: PaymentBatchInstance) => row?.id ?? ""

export const PaymentBatchInstancesList = () => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [sortModel, setSortModel] = useState<GridSortModel>(initialState.sortModel)
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(initialState.paginationModel)
    const { page, pageSize } = paginationModel

    const { data, isFetching, isLoading } = useGetPaymentBatchInstancesByOrganizationQuery(
        {
            organizationId,
            sortBy: sortModel[0]?.field,
            sortOrder: sortModel[0]?.sort ?? "desc",
            page: page + 1,
            pageSize,
        },
        { skip: !organizationId, refetchOnMountOrArgChange: true }
    )
    const instances = data?.items ?? []

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPaginationModel(model)
    }

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model)
    }

    if (!organizationId) {
        return null
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <Card>
            <DataGridPremium
                columns={columns}
                rows={instances}
                getRowId={getRowId}
                rowCount={data?.total ?? 0}
                loading={isFetching}
                rowSelection={false}
                autoHeight
                pagination
                paginationMode="server"
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                sortModel={sortModel}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
            />
        </Card>
    )
}
