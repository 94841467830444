import { Draft, PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"

import { TagObjectI } from "~/domains/analytics/tags/types"
import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import { RootState } from "~/store"
import { AccountState, accountState } from "~/store/account/accountState"
import {
    InvoiceId,
    InvoiceStatus,
    InvoicesTab,
    NO_ORGANIZATION_ID,
    OrganizationId,
    ReceivedDocumentI,
    SentDocumentI,
    UserI,
    UserId,
    ViewTypeI,
} from "~/types"

const initialState = accountState

export interface UserAndOrganization {
    userId: UserId
    organizationId?: OrganizationId | undefined
}

export type UserOrOrganization =
    | {
          userId: UserId
          organizationId?: OrganizationId
      }
    | {
          userId?: undefined
          organizationId: OrganizationId
      }

const accountSlice = createSlice({
    name: "account",
    initialState: initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        displayModal(state) {
            state.displayModal = !state.displayModal
        },
        setHasSignedUp(state, action: PayloadAction<boolean>) {
            state.hasSignedUp = action.payload
        },
        setUserId(state, action: PayloadAction<UserId>) {
            state.userId = action.payload
        },
        setSelectedDocuments(state: Draft<AccountState>, action: PayloadAction<string[]>) {
            state.selectedDocuments = action.payload
        },
        fetchUser(state) {
            state.loading = true
        },
        fetchUserSuccess(state, action: PayloadAction<UserI>) {
            state.loading = false
            state.data = {
                ...state.data,
                ...action.payload,
            }
            if (state.data.organizations.length > 0) {
                state.data.organizations.push({ id: NO_ORGANIZATION_ID })
            }
            state.userId = action.payload.id ?? ""
            if (!action.payload.fullName) {
                state.displayModal = true
            }
        },
        fetchUserFailed(state) {
            state.loading = false
            state.error = true
            state.data = initialState.data
        },
        updateUser(state) {
            state.loading = true
        },
        updateUserSuccess(state, action: PayloadAction<UserI>) {
            state.loading = false
            state.data = {
                ...state.data,
                ...action.payload,
            }
            state.displayModal = false
        },
        updateUserFailed(state) {
            state.loading = false
        },
        updatePartialUser(state, action: PayloadAction<Partial<UserI>>) {
            state.data = {
                ...state.data,
                ...action.payload,
            }
        },
        disconnect() {
            return initialState
        },
        fetchSentDocuments(state, action: PayloadAction<UserOrOrganization & { showLoader: boolean }>) {
            state.fetchingSentDocuments = action.payload.showLoader
            if (
                state.sentDocumentsUserId !== action.payload.userId ||
                state.sentDocumentsOrganizationId !== action.payload.organizationId
            ) {
                state.sentDocumentsUserId = action.payload.userId
                state.sentDocumentsOrganizationId = action.payload.organizationId
                state.sentDocuments = []
            }
        },
        fetchSentDocumentsSuccess(state, action: PayloadAction<SentDocumentI[]>) {
            state.fetchingSentDocuments = false
            state.sentDocuments = action.payload
        },
        fetchSentDocumentsFailed(state, action: PayloadAction<string>) {
            state.fetchingSentDocuments = false
            state.fetchingSentDocumentsError = action.payload
        },
        fetchReceivedDocuments: {
            reducer: (state, action: PayloadAction<UserAndOrganization & { showLoader: boolean }>) => {
                state.fetchingReceivedDocuments = action.payload.showLoader
                if (
                    state.receivedDocumentsUserId !== action.payload.userId ||
                    state.receivedDocumentsOrganizationId !== action.payload.organizationId
                ) {
                    state.receivedDocumentsUserId = action.payload.userId
                    state.receivedDocumentsOrganizationId = action.payload.organizationId
                    state.receivedDocuments = []
                }
            },
            prepare: (payload: UserAndOrganization & { showLoader: boolean }) => {
                return { payload }
            },
        },
        fetchReceivedDocumentsSuccess(state, action: PayloadAction<ReceivedDocumentI[]>) {
            state.fetchingReceivedDocuments = false
            state.receivedDocuments = action.payload
        },
        fetchReceivedDocumentsFailed(state, action: PayloadAction<string>) {
            state.fetchingReceivedDocuments = false
            state.fetchingReceivedDocumentsError = action.payload
        },
        bulkValidate(state, action: PayloadAction<string[]>) {
            const documents = [...[], ...state.receivedDocuments]
            action.payload.forEach((id) => {
                const index = documents.findIndex((doc) => doc.invoiceId === id)
                documents[index].status = InvoiceStatus.VALIDATED
            })
            state.receivedDocuments = documents
        },
        bulkMarkAsPaid(state, action: PayloadAction<string[]>) {
            const documents = [...[], ...state.receivedDocuments]
            action.payload.forEach((id) => {
                const index = documents.findIndex((doc) => doc.invoiceId === id)
                documents[index].status = InvoiceStatus.MARKED_AS_PAID
            })
            state.receivedDocuments = documents
            return
        },
        bulkResolve(state, action: PayloadAction<string[]>) {
            const documents = [...[], ...state.sentDocuments]
            action.payload.forEach((id) => {
                const index = documents.findIndex((doc) => doc.invoiceId === id)
                documents[index].status = InvoiceStatus.VALIDATED
            })
            state.sentDocuments = documents
            return
        },
        bulkPaymentReceived(state, action: PayloadAction<string[]>) {
            const documents = [...[], ...state.sentDocuments]
            action.payload.forEach((id) => {
                const index = documents.findIndex((doc) => doc.invoiceId === id)
                documents[index].status = InvoiceStatus.PAID
            })
            state.sentDocuments = documents
            return
        },
        bulkAddTags(
            state,
            action: PayloadAction<{
                selected: string[]
                tagsToAdd: TagObjectI[]
                tagsToRemove: TagObjectI[]
                view: ViewTypeI
            }>
        ) {
            if (action.payload.view === ViewTypeI.buyer) {
                const documents = [...state.receivedDocuments]
                action.payload.selected.forEach((id) => {
                    const index = documents.findIndex((doc) => doc.invoiceId === id)
                    const currentTags = documents[index]?.tags ?? []
                    documents[index].tags = [
                        ...new Set([
                            ...currentTags.filter(
                                (currentTag) =>
                                    !action.payload.tagsToRemove.some(
                                        (tagToRemove) =>
                                            tagToRemove.objectId === id && tagToRemove.tagId === currentTag.tagId
                                    )
                            ),
                            ...action.payload.tagsToAdd.filter((tagObject) => tagObject.objectId === id),
                        ]),
                    ]
                })
                state.receivedDocuments = documents
            } else if (action.payload.view === ViewTypeI.supplier) {
                const documents = [...state.sentDocuments]
                action.payload.selected.forEach((id) => {
                    const index = documents.findIndex((doc) => doc.invoiceId === id)
                    const currentTags = documents[index]?.tags ?? []
                    documents[index].tags = [
                        ...new Set([
                            ...currentTags.filter(
                                (currentTag) =>
                                    !action.payload.tagsToRemove.some(
                                        (tagToRemove) =>
                                            tagToRemove.objectId === id && tagToRemove.tagId === currentTag.tagId
                                    )
                            ),
                            ...action.payload.tagsToAdd.filter((tagObject) => tagObject.objectId === id),
                        ]),
                    ]
                })
                state.sentDocuments = documents
            }
            return
        },
        bulkDownloadPayFile(state) {
            state.paymentFileDownloaded = false
            state.paymentFileDownloadedError = false
            return
        },
        bulkDownloadPayFileSuccess(state) {
            state.paymentFileDownloaded = true
            state.paymentFileDownloadedError = false
            return
        },
        bulkDownloadPayFileFailed(state) {
            state.paymentFileDownloadedError = true
            return
        },
        resetDownloadState(state) {
            state.paymentFileDownloaded = false
            state.paymentFileDownloadedError = false
        },
        bulkSuccess(state) {
            state.bulkSuccess = !state.bulkSuccess
        },
        addOrganization(state, action: PayloadAction<OrganizationId>) {
            state.data.organizations = [{ id: action.payload }, ...state.data.organizations]
        },
        deleteOrganization(state, action: PayloadAction<OrganizationId>) {
            const index = state.data.organizations.findIndex(
                (organizationItem) => organizationItem.id === action.payload
            )
            if (index >= 0) {
                state.data.organizations = [
                    ...state.data.organizations.slice(0, index),
                    ...state.data.organizations.slice(index + 1),
                ]
            }
        },
        acceptInvitation(state, action: PayloadAction<OrganizationId>) {
            state.data.invitations =
                state.data.invitations?.filter((invitation) => invitation.id !== action.payload) || []
            state.data.membershipRequests =
                state.data.membershipRequests?.filter((request) => request.id !== action.payload) || []
            state.data.organizations = [...state.data.organizations, { id: action.payload }]
        },
        refuseInvitation(state, action: PayloadAction<OrganizationId>) {
            state.data.invitations =
                state.data.invitations?.filter((invitation) => invitation.id !== action.payload) || []
        },
        acceptMembership(state, action: PayloadAction<OrganizationId>) {
            state.data.invitations =
                state.data.invitations?.filter((invitation) => invitation.id !== action.payload) || []
            state.data.membershipRequests =
                state.data.membershipRequests?.filter((request) => request.id !== action.payload) || []
            state.data.organizations = [...state.data.organizations, { id: action.payload }]
        },
        refuseMembership(state, action: PayloadAction<OrganizationId>) {
            state.data.membershipRequests =
                state.data.membershipRequests?.filter((request) => request.id !== action.payload) || []
        },
        setCurrentInvoicesTab(state, action: PayloadAction<InvoicesTab>) {
            state.currentInvoicesTab = action.payload
        },
        rejectInvoice(state, action: PayloadAction<{ invoiceId: InvoiceId; view: ViewTypeI }>) {
            if (action.payload.view === ViewTypeI.buyer) {
                const documents = [...[], ...state.receivedDocuments]
                const index = documents.findIndex((doc) => doc.invoiceId === action.payload.invoiceId)
                if (index !== -1) {
                    documents[index].status = InvoiceStatus.REJECTED
                    state.receivedDocuments = documents
                }
            } else if (action.payload.view === ViewTypeI.supplier) {
                const documents = [...[], ...state.sentDocuments]
                const index = documents.findIndex((doc) => doc.invoiceId === action.payload.invoiceId)
                if (index !== -1) {
                    documents[index].status = InvoiceStatus.REJECTED
                    state.sentDocuments = documents
                }
            }
        },
        rejectInvoices(state, action: PayloadAction<{ invoiceIds: InvoiceId[]; view: ViewTypeI }>) {
            if (action.payload.view === ViewTypeI.buyer) {
                const documents = state.receivedDocuments.map((doc) => {
                    return {
                        ...doc,
                        status: action.payload.invoiceIds.includes(doc.invoiceId) ? InvoiceStatus.REJECTED : doc.status,
                    }
                })
                state.receivedDocuments = documents
            } else if (action.payload.view === ViewTypeI.supplier) {
                const documents = state.sentDocuments.map((doc) => {
                    return {
                        ...doc,
                        status: action.payload.invoiceIds.includes(doc.invoiceId) ? InvoiceStatus.REJECTED : doc.status,
                    }
                })
                state.sentDocuments = documents
            }
        },
        quitOrganization(state, action: PayloadAction<OrganizationId>) {
            state.data.invitations =
                state.data.invitations?.filter((invitation) => invitation.id !== action.payload) || []
            state.data.organizations =
                state.data.organizations?.filter((organization) => organization.id !== action.payload) || []
        },
        setSentDocumentsTagsLoading(state, action: PayloadAction<boolean>) {
            state.sentDocumentsTagsLoading = action.payload
        },
        setSentDocumentsTags(state, action: PayloadAction<TagObjectRecordI | undefined>) {
            state.sentDocumentsTags = action.payload
            state.sentDocumentsTagsLoading = false
        },
        setReceivedDocumentsTagsLoading(state, action: PayloadAction<boolean>) {
            state.receivedDocumentsTagsLoading = action.payload
        },
        setReceivedDocumentsTags(state, action: PayloadAction<TagObjectRecordI | undefined>) {
            state.receivedDocumentsTags = action.payload
            state.receivedDocumentsTagsLoading = false
        },
    },
})

// Actions
export const accountActions = accountSlice.actions

// // Selectors
export const selectBulkSuccess = (state: RootState) => state.account.bulkSuccess
export const selectUserId = (state: RootState) => state.account.userId
export const selectUser = (state: RootState) => state.account.data
export const selectUserLoading = (state: RootState) => state.account.loading
export const selectDisplayModalFinalizeAccount = (state: RootState) => state.account.displayModal
export const selectUserHasSignedUp = (state: RootState) => state.account.hasSignedUp
export const selectSelectedDocuments = (state: RootState) => state.account.selectedDocuments
export const selectCurrentInvoicesTab = (state: RootState) => state.account.currentInvoicesTab

const selectReceivedDocumentsField = (state: RootState) => state.account.receivedDocuments
const selectReceivedDocumentsTags = (state: RootState) => state.account.receivedDocumentsTags
export const selectReceivedDocumentsTagsLoading = (state: RootState) => state.account.receivedDocumentsTagsLoading
const selectReceivedDocumentsLoading = (state: RootState) => state.account.fetchingReceivedDocuments
const selectReceivedDocumentsError = (state: RootState) => state.account.fetchingReceivedDocumentsError
export const selectReceivedDocuments = createSelector(
    [
        selectReceivedDocumentsField,
        selectReceivedDocumentsTags,
        selectReceivedDocumentsTagsLoading,
        selectReceivedDocumentsLoading,
        selectReceivedDocumentsError,
    ],
    (receivedDocuments, tags, tagsLoading, loading, error) => ({
        receivedDocuments,
        tags,
        tagsLoading,
        loading,
        error,
    })
)

const selectSentDocumentsField = (state: RootState) => state.account.sentDocuments
const selectSentDocumentsTags = (state: RootState) => state.account.sentDocumentsTags
export const selectSentDocumentsTagsLoading = (state: RootState) => state.account.sentDocumentsTagsLoading
const selectSentDocumentsLoading = (state: RootState) => state.account.fetchingSentDocuments
const selectSentDocumentsError = (state: RootState) => state.account.fetchingSentDocumentsError
export const selectSentDocuments = createSelector(
    [
        selectSentDocumentsField,
        selectSentDocumentsTags,
        selectSentDocumentsTagsLoading,
        selectSentDocumentsLoading,
        selectSentDocumentsError,
    ],
    (sentDocuments, tags, tagsLoading, loading, error) => ({
        sentDocuments,
        tags,
        tagsLoading,
        loading,
        error,
    })
)

export const selectPaymentFileDownloaded = (state: RootState) => state.account.paymentFileDownloaded
export const selectPaymentFileDownloadedError = (state: RootState) => state.account.paymentFileDownloadedError

// // Reducer
const accountReducer = accountSlice.reducer
export default accountReducer
