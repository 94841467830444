import { XYPosition } from "reactflow"

import {
    AddToBudgetNode,
    ApprovePurchaseOrderLineNode,
    ApprovePurchaseOrderNode,
    ApprovePurchaseRequestLineNode,
    ApprovePurchaseRequestNode,
    AssignTagGroupNode,
    BranchNode,
    CheckNode,
    ConditionalNode,
    ConvertPrToPoNode,
    CreateCustomFieldNode,
    CreateSurveyNode,
    CreateTaskNode,
    EditorNode,
    Event,
    EventTriggerNode,
    FetchCustomFieldsNode,
    FetchPartnershipNode,
    FitsToBudgetNode,
    FlowValidation,
    GetTagByGroupNode,
    IfNode,
    InvalidFlow,
    InvoiceToPurchaseOrderMatchNode,
    MappingNode,
    NodeType,
    ObjectType,
    RegularNode,
    RetractReviewsNode,
    Reviewer,
    SendEmailNode,
    SetInvoiceLifecycleStatusNode,
    SetPartnershipFieldNode,
    SetPaymentMethodDetailsFieldNode,
    TeamReviewer,
    TransactionType,
    UpdateCustomFieldNode,
    UpdateTripletexLedgerNode,
    UserReviewer,
    UserType,
    ValidFlow,
} from "./Flows"

// type guard to check if node is an EventTriggerNode
export const isEventTriggerNode = (node: EditorNode): node is EventTriggerNode =>
    node.type === NodeType.EVENT_TRIGGER_NODE

// type guard to check if node is an IfNode
export const isIfNode = (node: EditorNode): node is IfNode => node.type === NodeType.IF_NODE

// type guard to check if node is an SetPartnershipFieldNode
export const isSetPartnershipFieldNode = (node: EditorNode): node is SetPartnershipFieldNode =>
    node.type === NodeType.SET_PARTNERSHIP_FIELD_NODE

// type guard to check if string is Event
export const isEvent = (event: string): event is Event => Object.values(Event).includes(event as Event)

// type guard to check if string is ObjectType
export const isObjectType = (type: string): type is ObjectType => Object.values(ObjectType).includes(type as ObjectType)

// type guard to check if string is TransactionType
export const isTransactionType = (type: string): type is TransactionType =>
    Object.values(TransactionType).includes(type as TransactionType)

// type guard to check if node is CheckNode
export const isCheckNode = (node: EditorNode): node is CheckNode => node.type === NodeType.CHECK_NODE

// Add type guard for SendEmailNode
export const isSendEmailNode = (node: EditorNode): node is SendEmailNode => node.type === NodeType.SEND_EMAIL_NODE

// Add type guard for AddToBudgetNode
export const isAddToBudgetNode = (node: EditorNode): node is AddToBudgetNode =>
    node.type === NodeType.ADD_TO_BUDGET_NODE

// type guard to check if node is an InvoiceToPurchaseOrderMatchNode
export const isInvoiceToPurchaseOrderMatchNode = (node: EditorNode): node is InvoiceToPurchaseOrderMatchNode =>
    node.type === NodeType.INVOICE_TO_PURCHASE_ORDER_MATCH_NODE

// type guard to check if node is an SetInvoiceLifecycleStatusNode
export const isSetInvoiceLifecycleStatusNode = (node: EditorNode): node is SetInvoiceLifecycleStatusNode =>
    node.type === NodeType.SET_INVOICE_LIFECYCLE_STATUS_NODE

// type guard to check if node is an FitsToBudgetNode
export const isFitsToBudgetNode = (node: EditorNode): node is FitsToBudgetNode =>
    node.type === NodeType.FITS_TO_BUDGET_NODE

// type guard to check if node is an AssignTagGroupNode
export const isAssignTagGroupNode = (node: EditorNode): node is AssignTagGroupNode =>
    node.type === NodeType.ASSIGN_TAG_GROUP_NODE

// type guard to check if node is an UpdateTripletexLedgerNode
export const isUpdateTripletexLedgerNode = (node: EditorNode): node is UpdateTripletexLedgerNode =>
    node.type === NodeType.UPDATE_TRIPLETEX_LEDGER_NODE

// type guard to check if node is an ApprovePurchaseOrderNode
export const isApprovePurchaseOrderNode = (node: EditorNode): node is ApprovePurchaseOrderNode =>
    node.type === NodeType.APPROVE_PURCHASE_ORDER_NODE

// type guard to check if node is an ApprovePurchaseOrderLineNode
export const isApprovePurchaseOrderLineNode = (node: EditorNode): node is ApprovePurchaseOrderLineNode =>
    node.type === NodeType.APPROVE_PURCHASE_ORDER_LINE_NODE

// type guard to check if node is an ConvertPrToPoNode
export const isConvertPrToPoNode = (node: EditorNode): node is ConvertPrToPoNode =>
    node.type === NodeType.CONVERT_PR_TO_PO_NODE

// type guard to check if node is an ApprovePurchaseRequestNode
export const isApprovePurchaseRequestNode = (node: EditorNode): node is ApprovePurchaseRequestNode =>
    node.type === NodeType.APPROVE_PURCHASE_REQUEST_NODE

// type guard to check if node is an ApprovePurchaseRequestLineNode
export const isApprovePurchaseRequestLineNode = (node: EditorNode): node is ApprovePurchaseRequestLineNode =>
    node.type === NodeType.APPROVE_PURCHASE_REQUEST_LINE_NODE

// type guard to check if node is an BranchNode
export const isBranchNode = (node: EditorNode): node is BranchNode => node.type === NodeType.BRANCH_NODE

// type guard to check if node is an SetPaymentMethodDetailsFieldNode
export const isSetPaymentMethodDetailsFieldNode = (node: EditorNode): node is SetPaymentMethodDetailsFieldNode =>
    node.type === NodeType.SET_PAYMENT_METHOD_DETAILS_FIELD_NODE

// type guard to check if node is an CreateSurveyNode
export const isCreateSurveyNode = (node: EditorNode): node is CreateSurveyNode =>
    node.type === NodeType.CREATE_SURVEY_NODE

// type guard to check if node is an RetractReviewsNode
export const isRetractReviewsNode = (node: EditorNode): node is RetractReviewsNode =>
    node.type === NodeType.RETRACT_REVIEWS_NODE

// type guard to check if node is an FetchCustomFieldsNode
export const isFetchCustomFieldsNode = (node: EditorNode): node is FetchCustomFieldsNode =>
    node.type === NodeType.FETCH_CUSTOM_FIELDS_NODE

// type guard to check if node is an FetchPartnershipNode
export const isFetchPartnershipNode = (node: EditorNode): node is FetchPartnershipNode =>
    node.type === NodeType.FETCH_PARTNERSHIP_NODE

// type guard to check if node has nextNode
export const hasNextNode = (node: EditorNode): node is EditorNode & RegularNode =>
    "nextNode" in node && node.nextNode !== null

// type guard to check if node is conditional
export const isConditionalNode = (node: EditorNode): node is EditorNode & ConditionalNode =>
    "nextIfSuccess" in node && "nextIfFailure" in node

// type guard to check if node is mapping
export const isMappingNode = (node: EditorNode): node is EditorNode & MappingNode => node.type === NodeType.MAPPING_NODE

// type guard to check if node is getTagByGroupNode
export const isGetTagByGroupNode = (node: EditorNode): node is GetTagByGroupNode =>
    node.type === NodeType.GET_TAG_BY_GROUP_NODE

// type guard to check if node is regular
export const isRegularNode = (node: EditorNode): node is EditorNode & RegularNode =>
    "nextNode" in node && node.nextNode !== null

// type guard to check if metadata position is position
export const isPosition = (position: unknown): position is XYPosition => {
    if (typeof position !== "object" || position === null) return false
    if (!("x" in position) || !("y" in position)) return false
    if (typeof (position as XYPosition).x !== "number" || typeof (position as XYPosition).y !== "number") return false
    return true
}

// type guard to check if reviewer is a UserReviewer
export const isUserReviewer = (reviewer: Reviewer): reviewer is UserReviewer => reviewer.type === UserType.USER

// type guard to check if reviewer is a TeamReviewer
export const isTeamReviewer = (reviewer: Reviewer): reviewer is TeamReviewer => reviewer.type === UserType.TEAM

// type guard to check if flow is invalid
export const isFlowInvalid = (flowValidation: FlowValidation): flowValidation is InvalidFlow =>
    "error" in flowValidation

// type guard to check if flow is valid
export const isFlowValid = (flowValidation: FlowValidation): flowValidation is ValidFlow => "isValid" in flowValidation

// type guard to check if node is an UpdateCustomFieldNode
export const isUpdateCustomFieldNode = (node: EditorNode): node is UpdateCustomFieldNode =>
    node.type === NodeType.UPDATE_CUSTOM_FIELD_NODE

// type guard to check if node is an CreateTaskNode
export const isCreateTaskNode = (node: EditorNode): node is CreateTaskNode => node.type === NodeType.CREATE_TASK_NODE

// type guard to check if node is an CreateCustomFieldNode
export const isCreateCustomFieldNode = (node: EditorNode): node is CreateCustomFieldNode =>
    node.type === NodeType.CREATE_CUSTOM_FIELD_NODE
