import { Save } from "react-feather"
import { defineMessages } from "react-intl"
import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { useUpdatePurchaseRequest } from "~/domains/transactions/purchase-requests/store/hooks/useUpdatePurchaseRequest"

const messages = defineMessages({
    save: {
        id: "purchase.requests.request.header.actions.save",
        defaultMessage: "Save",
    },
})

interface SavePRProps {
    id: string
    organizationId: string
    display?: "button" | "menu-item"
}

export const SavePR = ({ id, organizationId, display = "button" }: SavePRProps) => {
    const navigate = useNavigate()
    const location = useLocation()

    const { updatePR } = useUpdatePurchaseRequest(organizationId, id)

    const handleSave = async (e: React.MouseEvent) => {
        e.preventDefault()
        await updatePR()
        const pathname = location.pathname.split("/edit")
        navigate(pathname[0])
    }
    return (
        <>
            {display === "button" ? (
                <Button type="primary-light" onClick={handleSave}>
                    <Save size={18} />
                    <SafeFormattedMessage {...commonMessages.save} />
                </Button>
            ) : (
                <Button type="menu-item" onClick={handleSave}>
                    <Save size={16} />
                    <SafeFormattedMessage {...messages.save} />
                </Button>
            )}
        </>
    )
}
