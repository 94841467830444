import React, { FC, useState } from "react"
import { Trash2 } from "react-feather"
import { useIntl } from "react-intl"

import { ConfirmModal } from "~/components"
import { messages } from "~/domains/orchestration/flows/locale"
import type { Flow } from "~/domains/orchestration/flows/types"

import { IconButton } from "./IconButton"

interface Props {
    hasFlowDeletePermission: boolean
    deleteCallback: (flow: Flow) => void
    flow: Flow
}

export const DeleteAction: FC<Props> = ({ hasFlowDeletePermission, deleteCallback, flow }) => {
    const { formatMessage } = useIntl()
    const [showConfirmModal, setShowConfirmModal] = useState(false)

    if (!hasFlowDeletePermission) {
        return null
    }

    const displayConfirmModal = () => {
        setShowConfirmModal(true)
    }

    const hideConfirmModal = () => setShowConfirmModal(false)

    const handleDeleteConfirmation = async (flow: Flow) => {
        deleteCallback(flow)
        hideConfirmModal()
    }

    return (
        <>
            <IconButton type="close" onClick={displayConfirmModal}>
                <Trash2 size={18} color="var(--color-red)" />
            </IconButton>
            <ConfirmModal
                title={formatMessage(messages.editor.confirmArchivedTitle)}
                open={showConfirmModal}
                close={hideConfirmModal}
                onConfirm={() => handleDeleteConfirmation(flow)}
            >
                {formatMessage(messages.editor.confirmArchivedMessage, { name: flow.name })}
            </ConfirmModal>
        </>
    )
}
