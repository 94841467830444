/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { PayloadAction } from "@reduxjs/toolkit"
import { all, call, put, select, takeLatest } from "redux-saga/effects"

import { UpdateStakeholderUserI, userApi } from "~/domains/identity/account/api/userApi"
import { rolesPermissionsActions } from "~/domains/identity/roles-permissions/store/rolesPermissionsSlice"
import { authActions } from "~/store/auth/authSlice"
import { errorsActions } from "~/store/errors/errorsSlice"
import { UserI } from "~/types"
import { Auth0CacheMode, auth0, removeNull } from "~/utils"

import { RootState } from ".."
import { accountActions } from "./accountSlice"

const getUser = (state: RootState) => state.account.data

function* updateUser(action: PayloadAction<UpdateStakeholderUserI>) {
    try {
        const user: UserI = yield select(getUser)
        const toUpdate: UpdateStakeholderUserI = {
            ...action.payload,
            ...removeNull(user),
        }
        const updatedUser = {
            ...user,
            toUpdate,
        }
        yield call(userApi.updateUser, {
            fullName: toUpdate.fullName,
            phoneNumber: toUpdate.phoneNumber ?? "",
        })

        yield put(accountActions.updateUserSuccess(updatedUser))
    } catch (error) {
        yield put(accountActions.updateUserFailed())
    }
}

function* fetchUser() {
    try {
        const response: UserI = yield call(userApi.getCurrentUser)
        if (!response) {
            yield call(auth0().logout, false)
            yield put(accountActions.fetchUserFailed())
            yield put(authActions.setIsLoading(false))
            yield put(authActions.displayModalReconnect(true))
        } else {
            const auth0Client = auth0()
            const { permissions } = yield auth0Client.getToken(Auth0CacheMode.OFF)
            if (permissions) {
                yield put(rolesPermissionsActions.setPermissions(permissions))
            }
            yield put(accountActions.fetchUserSuccess(response))
            yield put(errorsActions.setErrorAuth(false))
        }
    } catch (error) {
        yield call(auth0().logout, false)
        yield put(accountActions.fetchUserFailed())
        yield put(authActions.setIsLoading(false))
    }
}

function* fetchUserSuccess() {
    yield put(authActions.setIsConnected(true))
    yield put(authActions.setIsLoading(false))
}

export default function* invoiceSaga() {
    yield all([
        takeLatest(accountActions.fetchUserSuccess.type, fetchUserSuccess),
        takeLatest(accountActions.fetchUser.type, fetchUser),
        takeLatest(accountActions.updateUser.type, updateUser),
    ])
}
