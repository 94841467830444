import { Stack, TextField } from "@mui/material"
import cls from "classnames"
import { ChangeEvent, FC, Fragment, useState } from "react"
import { Plus, Trash2 } from "react-feather"
import { v4 as uuid } from "uuid"

import { Button, SafeFormattedMessage } from "~/components"
import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { messages } from "~/domains/orchestration/flows/locale"

import type { Branch as BranchType, ConditionGroup, Condition as ConditionType } from "./BranchConfiguration"
import { Condition } from "./Condition"
import { ConditionBuilder } from "./ConditionBuilder"

interface BranchProps {
    branch: BranchType
    handleChange: (branch: BranchType) => void
    handleDeleteBranch: (branch: BranchType) => void
}

export const Branch: FC<BranchProps> = ({ branch, handleChange, handleDeleteBranch }) => {
    const [showConditionBuilder, setShowConditionBuilder] = useState(false)
    const [currentGroup, setCurrentGroup] = useState<ConditionGroup | null>(null)
    const [currentCondition, setCurrentCondition] = useState<ConditionType | null>(null)

    const resetConditionBuilder = () => {
        setShowConditionBuilder(false)
        setCurrentGroup(null)
        setCurrentCondition(null)
    }

    const updateBranch = (conditionGroups: ConditionGroup[]) => {
        handleChange({
            ...branch,
            conditionGroups,
        })
    }

    const handleChangeCondition = (conditionGroup: ConditionGroup) => {
        const newConditionGroups = branch.conditionGroups.map((g) => (g.id === conditionGroup.id ? conditionGroup : g))
        updateBranch(newConditionGroups)
    }

    const handleDelete = (group: ConditionGroup) => () => {
        updateBranch(branch.conditionGroups.filter((g) => g.id !== group.id))
    }

    const handleNewCondition = (group: ConditionGroup) => {
        setCurrentCondition(null)
        setCurrentGroup(group)
        setShowConditionBuilder(true)
    }

    const handleSaveNewCondition = (condition: ConditionType) => {
        if (!currentGroup) return

        const isNewCondition = !currentGroup.conditions.some((c) => c.id === condition.id)
        const updatedConditions = isNewCondition
            ? [...currentGroup.conditions, condition]
            : currentGroup.conditions.map((c) => (c.id === condition.id ? condition : c))

        const updatedGroup = {
            ...currentGroup,
            conditions: updatedConditions,
        }

        updateBranch(branch.conditionGroups.map((g) => (g.id === currentGroup.id ? updatedGroup : g)))

        resetConditionBuilder()
    }

    const handleAddCondition = () => {
        updateBranch([
            ...branch.conditionGroups,
            {
                id: uuid(),
                conditions: [],
            },
        ])
    }

    const handleChangeBranchName = (e: ChangeEvent<HTMLInputElement>) => {
        handleChange({
            ...branch,
            name: e.target.value,
        })
    }

    const handleDeleteCurrentBranch = () => {
        handleDeleteBranch(branch)
    }

    const handleEditCondition = (condition: ConditionType) => {
        const group = branch.conditionGroups.find((g) => g.conditions.some((c) => c.id === condition.id))

        setShowConditionBuilder(true)
        setCurrentCondition(condition)
        setCurrentGroup(group || null)
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    if (showConditionBuilder) {
        return (
            <ConditionBuilder
                condition={currentCondition}
                handleCancel={resetConditionBuilder}
                handleSave={handleSaveNewCondition}
            />
        )
    }

    return (
        <>
            <Stack gap={1} className={configurationNodeItemClassName}>
                <Stack direction="row" justifyContent="space-between">
                    <TextField
                        value={branch.name}
                        onChange={handleChangeBranchName}
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                        }}
                    />

                    <IconButton onClick={handleDeleteCurrentBranch} type="grey-light" size="small">
                        <Trash2 size={12} color="var(--color-grey)" />
                    </IconButton>
                </Stack>

                {branch.conditionGroups.map((g, index) => (
                    <Fragment key={g.id}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            className="flows-branchConfiguration-orTitle"
                        >
                            <SafeFormattedMessage
                                tagName="span"
                                {...(index === 0
                                    ? messages.eventTriggerConfiguration.orConditionFirstLabel
                                    : messages.eventTriggerConfiguration.orConditionOtherLabel)}
                            />

                            <IconButton onClick={handleDelete(g)} type="grey-light" size="small">
                                <Trash2 size={12} color="var(--color-grey)" />
                            </IconButton>
                        </Stack>
                        <Condition
                            group={g}
                            handleChangeConditions={handleChangeCondition}
                            handleNewCondition={handleNewCondition}
                            handleEditCondition={handleEditCondition}
                        />
                    </Fragment>
                ))}
                <Button
                    type="primary-light"
                    onClick={handleAddCondition}
                    className="flows-branchConfiguration-newConditionGroup"
                >
                    <Plus size={18} />
                    <SafeFormattedMessage {...messages.branchConfiguration.newConditionGroupLabel} />
                </Button>
            </Stack>
        </>
    )
}
