import { styled } from "@mui/material"
import { HandleProps, Position } from "@xyflow/react"
import { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC, memo, useMemo } from "react"
import { Share2 } from "react-feather"

import { NODE_WIDTH } from "~/domains/orchestration/flows/constants"
import { BranchNode as BranchNodeType } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

const RotatedShare2 = styled(Share2)({
    transform: "rotate(90deg)",
})

const MemoizeNode = memo(Node)

const HANDLE_OFFSET = 100

const calculateHandlePosition = (branchCount: number, index: number) => {
    if (branchCount === 1) return "50%"
    const spacing = (NODE_WIDTH - HANDLE_OFFSET) / Math.max(branchCount - 1, 1)
    return `${50 + spacing * index}px`
}

export const BranchNode: FC<NodeProps<NodeType<BranchNodeType>>> = ({ data, selected }) => {
    const error = data.branches.length === 0

    const dynamicHandles = useMemo<HandleProps[]>(() => {
        const handles: HandleProps[] = data.branches.map((branch, index) => ({
            type: "source",
            isConnectableStart: true,
            id: `${data.slug}-{${index}}`,
            name: branch.name,
            position: Position.Bottom,
            style: {
                left: calculateHandlePosition(data.branches.length, index),
            },
        }))
        return [
            { type: "target", isConnectableEnd: true, position: Position.Top },
            {
                type: "source",
                position: Position.Right,
                isConnectableStart: true,
                id: `${data.slug}-default`,
            },
            ...handles,
        ]
    }, [data.branches])

    const information = ""

    return (
        <MemoizeNode
            type={data.type}
            name={data.name}
            information={information}
            icon={RotatedShare2}
            handles={dynamicHandles}
            selected={selected}
            error={error}
            slug={data.slug}
        ></MemoizeNode>
    )
}
