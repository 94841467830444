import { Stack } from "@mui/material"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button } from "~/components"
import { PaymentBatchAddAndEditModal } from "~/domains/payment/payment-batches/components"

const messages = defineMessages({
    addBatch: {
        id: "payment.batches.addBatch",
        defaultMessage: "+ Add batch",
    },
})

export const BatchesActions = () => {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    return (
        <Stack flexDirection="row" alignItems="center" justifyContent="flex-end">
            <Button type="primary" onClick={handleOpen}>
                {formatMessage(messages.addBatch)}
            </Button>
            <PaymentBatchAddAndEditModal open={open} onClose={handleClose} />
        </Stack>
    )
}
