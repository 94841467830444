import { CornerRightUp } from "react-feather"

import { UpdateCustomFieldConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { UpdateCustomFieldNode } from "~/domains/orchestration/flows/components/nodes"
import { Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    Node,
    NodeType,
    UpdateCustomFieldNode as UpdateCustomFieldNodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const updateCustomFieldNode: Omit<UpdateCustomFieldNodeType, keyof Node> = {
    customFieldId: "",
    value: "",
    type: NodeType.UPDATE_CUSTOM_FIELD_NODE,
    nextNode: null,
}

const advancedFields = []

const updateCustomFieldConfig: Configuration<NodeType.UPDATE_CUSTOM_FIELD_NODE> = {
    type: NodeType.UPDATE_CUSTOM_FIELD_NODE,
    baseSlug: "update-custom-field",
    Icon: CornerRightUp,
    Configuration: UpdateCustomFieldConfiguration,
    advancedFields,
    Node: UpdateCustomFieldNode,
    factory: (props: FactoryProps): UpdateCustomFieldNodeType => ({
        ...updateCustomFieldNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { updateCustomFieldConfig, advancedFields as updateCustomFieldAdvancedFields }
