import { OrganizationId, ReceivedDocumentI, SentDocumentI } from "~/types"

import axiosClient from "../../../../api/axiosClient"

const BASE_URL = import.meta.env.VITE_API_INVOICES_URL + "v1"
const SUPPLIERS_BASE_URL = `${BASE_URL}/suppliers`
const BUYERS_BASE_URL = `${BASE_URL}/buyers`

export const documentApi = {
    getSentDocuments: async (userId: string): Promise<SentDocumentI[]> => {
        const url = `${SUPPLIERS_BASE_URL}/${userId}/documents`
        return (await axiosClient.get(url)) as SentDocumentI[]
    },
    getReceivedDocuments: async (userId: string): Promise<ReceivedDocumentI[]> => {
        const url = `${BUYERS_BASE_URL}/${userId}/documents`
        return (await axiosClient.get(url)) as ReceivedDocumentI[]
    },
    async getSuppliersDocuments(organizationId: OrganizationId): Promise<SentDocumentI[]> {
        const url = `${SUPPLIERS_BASE_URL}/${organizationId}/organization-documents`
        return (await axiosClient.get(url)) as SentDocumentI[]
    },
    async getBuyersDocuments(organizationId: OrganizationId): Promise<ReceivedDocumentI[]> {
        const url = `${BUYERS_BASE_URL}/${organizationId}/organization-documents`
        return (await axiosClient.get(url)) as ReceivedDocumentI[]
    },
}
