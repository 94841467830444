import { messageFromApiAdapter } from "~/domains/communication/chat/api/adapters"
import { ResultSuccess, getResultSuccessValue, isResultSuccess } from "~/types/Result"
import { genericParser } from "~/utils"
import { isDefined } from "~/utils/isDefined"

import { RoomI, RoomIO, RoomInvolvedOrganizationI } from "./Room"

export const parseRoom = (data: unknown) => {
    const result = genericParser(data, RoomIO)
    if (isResultSuccess(result)) {
        return ResultSuccess<RoomI>({
            ...result.result,
            lastMessages: result.result.lastMessages ?? [],
            pinnedMessages:
                result.result.pinnedMessages
                    ?.map(messageFromApiAdapter)
                    .filter(isResultSuccess)
                    .map(getResultSuccessValue) ?? [],
            involvedOrganization: result.result.involvedOrganization
                ? (result.result.involvedOrganization.filter((orga) =>
                      isDefined(orga.organizationId)
                  ) as RoomInvolvedOrganizationI[])
                : [],
        })
    }
    return result
}
