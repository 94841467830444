import * as t from "io-ts"

import { optional } from "~/utils/io-ts"

import { TagGroupId } from "./TagGroup"

export const CreateTagIO = t.intersection([
    t.type({
        name: t.string,
        tagGroupId: t.string,
    }),
    t.partial({
        parentId: optional(t.string),
        description: optional(t.string),
        keywords: optional(t.string),
        externalId: optional(t.string),
        value: optional(t.string),
    }),
])

export type CreateTagI = t.TypeOf<typeof CreateTagIO> & {
    tagGroupId: TagGroupId
}
