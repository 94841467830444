import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId } from "~/api/prepareHeaders"
import { adaptSpiceDBPermissionsResult } from "~/domains/identity/roles-permissions/api/adapters/spiceDBAdapters"
import {
    GetObjectPermissionsQuery,
    ShareObjectPermissionsMutation,
    UserObjectPermissionsCheckQuery,
} from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { SpiceDBPermissionsLiteI } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { SpiceDBPermissionsResult } from "~/types"

const BASE_URL = import.meta.env.VITE_API_ROLES_PERMISSIONS_URL
const tags = ["ObjectPermissions"]

export const spiceDbApi = createApi({
    reducerPath: "spiceDbApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorizationAndOrganizationId }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getObjectPermissions: builder.query<SpiceDBPermissionsLiteI[], GetObjectPermissionsQuery>({
            query: ({ objectId, objectType }) => ({
                url: `spiceDB/${objectId}/${objectType}/permissions`,
            }),
            providesTags: tags,
        }),
        shareObjectPermissions: builder.mutation<SpiceDBPermissionsLiteI, ShareObjectPermissionsMutation>({
            query: ({ objectId, objectType, body }) => ({
                url: `spiceDB/${objectId}/${objectType}/permissions`,
                method: "POST",
                body,
            }),
            invalidatesTags: tags,
        }),
        removeObjectPermissions: builder.mutation<SpiceDBPermissionsLiteI, ShareObjectPermissionsMutation>({
            query: ({ objectId, objectType, body }) => ({
                url: `spiceDB/${objectId}/${objectType}/permissions`,
                method: "DELETE",
                body,
            }),
            invalidatesTags: tags,
        }),
        userObjectPermissionsCheck: builder.query<SpiceDBPermissionsResult, UserObjectPermissionsCheckQuery>({
            query: ({ userId, objectType, organizationId, authorizations }) => ({
                url: `spiceDB/${objectType}/users/${userId}/permissions`,
                method: "POST",
                body: { organizationId, authorizations },
            }),
            transformResponse: adaptSpiceDBPermissionsResult,
        }),
    }),
})

export const {
    useGetObjectPermissionsQuery,
    useShareObjectPermissionsMutation,
    useRemoveObjectPermissionsMutation,
    useUserObjectPermissionsCheckQuery,
} = spiceDbApi
