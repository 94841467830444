import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { defineMessages } from "react-intl"

import { prepareHeadersWithAuthorization } from "~/api/prepareHeaders"
import { copilotMessageFromApiAdapter } from "~/domains/orchestration/copilot/api/adapters/copilotMessageFromApiAdapter"
import { copilotThreadFromApiAdapter } from "~/domains/orchestration/copilot/api/adapters/copilotThreadFromApiAdapter"
import { MessageResult, ThreadResult } from "~/domains/orchestration/copilot/types/Copilot"
import { OrganizationId } from "~/types"

const errors = defineMessages({
    errorUpload: {
        id: "common.error.errorUploadingDocument",
        defaultMessage: "An error occurred while uploading the document.",
    },
})

const BASE_URL = import.meta.env.VITE_API_COPILOT_URL

export const copilotApi = createApi({
    reducerPath: "copilotApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: prepareHeadersWithAuthorization,
    }),
    endpoints: (builder) => ({
        createThread: builder.mutation<ThreadResult, { organizationId: OrganizationId; userId: string }>({
            query: ({ organizationId, userId }) => ({
                url: "threads",
                method: "POST",
                body: { organizationId, userId },
            }),
            transformResponse: (response) => copilotThreadFromApiAdapter(response),
        }),
        createMessage: builder.mutation<
            MessageResult,
            { threadId: string; userId: string; message: string; file?: File }
        >({
            query: ({ threadId, userId, message, file }) => {
                const formData = new FormData()
                formData.append("threadId", threadId)
                formData.append("userId", userId)
                formData.append("message", message)

                if (file) {
                    formData.append("files", file)
                }

                return {
                    url: "messages",
                    method: "POST",
                    body: formData,
                }
            },
            transformResponse: (response) => copilotMessageFromApiAdapter(response),
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: errors.errorUpload,
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
    }),
})

export const { useCreateThreadMutation, useCreateMessageMutation } = copilotApi
