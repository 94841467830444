// Former API

// Used in both APIs

export enum From {
    USER = "user",
    COPILOT = "copilot",
}

export interface Conversation {
    from: From
    message: string
    autoTextToSpeech?: boolean
}

export interface Completion {
    type: string
    item: string
    quantity: number
    price: number
    currency: string
    tax: number
    message: string
}

// New API

export interface ThreadResult {
    id: string
    userId: string
    organizationId: string
}

interface Line {
    description: string
    quantity: number
    taxRate: number
    unitPrice: number
    unitPriceExcludingTax: number
}

export interface FilledJson {
    description: string
    lines: Line[]
    supplierId: string
    requesterUserId: string
    expectedDeliveryDate: string
    shippingAddress: string
}

export interface MessageResult {
    message: string
    completion: Completion
    status: string
    filled_json: FilledJson
}
