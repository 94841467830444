import { FC, PropsWithChildren } from "react"

import { MessageContext } from "~/domains/communication/chat/components/MessageContext/MessageContext"
import { MessageI } from "~/domains/communication/chat/types"

interface Props extends PropsWithChildren {
    message: MessageI
}

export const MessageContextProvider: FC<Props> = ({ message, children }) => {
    return <MessageContext.Provider value={message}>{children}</MessageContext.Provider>
}
