import { X } from "react-feather"
import { AlertTriangle } from "react-feather"
import { Check, Pause, Play } from "react-feather"

import { RUN_STATUS_COLORS } from "~/domains/orchestration/flows/constants"
import { RunStatus } from "~/domains/orchestration/flows/types"

export const StatusIcon = ({ status }: { status: RunStatus }) => {
    switch (status) {
        case RunStatus.CANCELED:
            return <X size={16} color={RUN_STATUS_COLORS[RunStatus.CANCELED]} />
        case RunStatus.FAILED:
            return <AlertTriangle size={16} color={RUN_STATUS_COLORS[RunStatus.FAILED]} />
        case RunStatus.FINISHED:
            return <Check size={16} color={RUN_STATUS_COLORS[RunStatus.FINISHED]} />
        case RunStatus.RUNNING:
            return <Play size={16} color={RUN_STATUS_COLORS[RunStatus.RUNNING]} />
        case RunStatus.SUSPENDED:
            return <Pause size={16} color={RUN_STATUS_COLORS[RunStatus.SUSPENDED]} />
    }
}
