import { Checkbox, FormControlLabel, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import dayjs, { Dayjs } from "dayjs"
import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card, SkeletonInput } from "~/components"
import { PaidStatus } from "~/domains/transactions/invoices/components/Payment/PaidStatus"
import { PaymentIbanInput } from "~/domains/transactions/invoices/components/Payment/PaymentIbanInput"
import { useAppDispatch } from "~/store/hooks"
import { ocrActions } from "~/store/ocr/ocrSlice"
import {
    AlreadyPaidPaymentI,
    ImportingInvoiceI,
    InvoicePaymentDetailsI,
    OrganizationId,
    PaymentDetailsI,
} from "~/types/"

import "./Payment.scss"

const messages = defineMessages({
    title: {
        id: "buyer.import.page.paymentDetails.title",
        defaultMessage: "Payment",
    },
    suppliersIBAN: {
        id: "buyer.import.page.paymentDetails.suppliersIBAN",
        defaultMessage: "Supplier's IBAN",
    },
    requestIBAN: {
        id: "buyer.import.page.paymentDetails.requestIBAN",
        defaultMessage: "I don't have the IBAN, request it",
    },
    invoiceIs: {
        id: "buyer.import.page.paymentDetails.invoiceIs",
        defaultMessage: "This invoice is:",
    },
    paid: {
        id: "buyer.import.page.paymentDetails.paid",
        defaultMessage: "Paid",
    },
    notPaid: {
        id: "buyer.import.page.paymentDetails.notPaid",
        defaultMessage: "Not paid",
    },
    executionDate: {
        id: "buyer.import.page.paymentDetails.executionDate",
        defaultMessage: "Execution date (MM/DD/YYYY)",
    },
})

interface PaymentDetailsProps {
    hidden?: boolean
    paymentDetails: PaymentDetailsI | undefined | null
    updateData: ((data: Partial<ImportingInvoiceI>) => void) | ((data: Partial<InvoicePaymentDetailsI>) => void)
    dataLoaded: boolean
    partnerId?: OrganizationId | null
}

export function Payment({ hidden, paymentDetails, updateData, dataLoaded, partnerId }: PaymentDetailsProps) {
    const [alreadyPaid, setAlreadyPaid] = useState<PaidStatus>(PaidStatus.NOT_PAID)
    const [executionDate, setExecutionDate] = useState<string | null>(null)
    const [requestIBAN, setRequestIBAN] = useState<boolean>(false)
    const [pickerOpen, setPickerOpen] = useState<boolean>(false)

    const { formatMessage, locale } = useIntl()
    const dispatch = useAppDispatch()

    const isPaid = alreadyPaid === PaidStatus.PAID
    const isIbanDisabled = requestIBAN && !isPaid
    const isIbanRequired = !requestIBAN && !isPaid

    useEffect(() => {
        if (hidden && !paymentDetails?.iban) {
            setRequestIBAN(true)
            updateAlreadyPaidPaymentData({ requestIBAN: true })
        }
        return () => {
            dispatch(ocrActions.resetAlreadyPaidPaymentData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateAlreadyPaidPaymentData = useCallback(
        (data: Partial<AlreadyPaidPaymentI>) => {
            dispatch(ocrActions.updateAlreadyPaidPaymentData(data))
        },
        [dispatch]
    )

    const onIsPaidChange = useCallback(
        (event: MouseEvent<HTMLElement>, alreadyPaidText: PaidStatus) => {
            if (alreadyPaidText !== null) {
                const isAlreadyPaid = alreadyPaidText === PaidStatus.PAID
                updateAlreadyPaidPaymentData({
                    alreadyPaid: isAlreadyPaid,
                })
                if (isAlreadyPaid) {
                    updateAlreadyPaidPaymentData({ requestIBAN: false })
                }
                setAlreadyPaid(alreadyPaidText)
            }
        },
        [updateAlreadyPaidPaymentData]
    )

    const handleUpdateDueDate = useCallback(
        (newDate: Dayjs | null) => {
            if (newDate) {
                const newExecutionDate = newDate.format("YYYY-MM-DD")
                setExecutionDate(newExecutionDate)
                updateAlreadyPaidPaymentData({ executionDate: newExecutionDate })
            }
        },
        [updateAlreadyPaidPaymentData]
    )

    const handleRequestIBANChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setRequestIBAN(checked)
            updateAlreadyPaidPaymentData({ requestIBAN: checked })
        },
        [updateAlreadyPaidPaymentData]
    )
    const ibanInput = (
        <PaymentIbanInput
            iban={paymentDetails?.iban}
            partnerId={partnerId}
            updateData={updateData}
            isIbanDisabled={isIbanDisabled}
            isIbanRequired={isIbanRequired}
            paidStatus={alreadyPaid}
        />
    )
    return (
        <Card
            expandable
            title={formatMessage(messages.title)}
            {...(hidden
                ? {
                      sx: {
                          display: "none",
                      },
                  }
                : {})}
        >
            {!dataLoaded && (
                <>
                    <SkeletonInput />
                    <SkeletonInput />
                </>
            )}
            <div className="is-paid-buttons mb-12">
                <span>{formatMessage(messages.invoiceIs)}</span>
                <div className="buttons-container">
                    <ToggleButtonGroup color="primary" value={alreadyPaid} exclusive onChange={onIsPaidChange}>
                        <ToggleButton value={PaidStatus.PAID}>{formatMessage(messages.paid)}</ToggleButton>
                        <ToggleButton value={PaidStatus.NOT_PAID}>{formatMessage(messages.notPaid)}</ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            {isPaid ? (
                <>
                    <div className="invoice-field mb-12">
                        <div className="invoice-field-container">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                                <DatePicker
                                    open={pickerOpen}
                                    onOpen={() => setPickerOpen(true)}
                                    onClose={() => setPickerOpen(false)}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            onClick: () => setPickerOpen(true),
                                        },
                                    }}
                                    label={formatMessage(messages.executionDate)}
                                    value={executionDate ? dayjs(executionDate) : null}
                                    onChange={(newValue) => {
                                        handleUpdateDueDate(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    {ibanInput}
                </>
            ) : (
                <>
                    {ibanInput}
                    <div className="invoice-field mb-12">
                        <FormControlLabel
                            control={<Checkbox checked={requestIBAN} onChange={handleRequestIBANChange} />}
                            label={formatMessage(messages.requestIBAN)}
                        />
                    </div>
                </>
            )}
        </Card>
    )
}
