import { MenuItem, Stack, TextField, capitalize } from "@mui/material"
import * as Sentry from "@sentry/react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { MessageDescriptor, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Button, ConfirmModal, Loader, Modal, Size } from "~/components"
import { SharedObject } from "~/components/ShareObjectModal/SharedObject"
import { PartnerContactsSelector } from "~/domains/identity/partners/components/PartnerSelectors"
import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { usePartnerContactSave } from "~/domains/identity/partners/store/hooks"
import { PartnerProfileContactI } from "~/domains/identity/partners/types"
import {
    useRemoveSharedObjectMutation,
    useShareObjectMutation,
} from "~/domains/identity/roles-permissions/api/sharesApi"
import { useFetchSharedObjects } from "~/domains/identity/roles-permissions/store/hooks"
import { sharedObjectPermissionsModalMessages } from "~/domains/identity/roles-permissions/types/SpiceDB"
import { OrganizationSummary } from "~/domains/transactions/_shared/components/Organizations/Organizations"
import { selectPurchaseViewType } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { getOrCreateUserByEmail } from "~/store/users/utils"
import { OrganizationId, ViewTypeI } from "~/types"
import { SharedObjectType, SharedObjectWithName } from "~/types/SharedObjects"

const SharedObjectMemo = React.memo(SharedObject)

export const sharedObjectMessages: Record<SharedObjectType, MessageDescriptor> = {
    [SharedObjectType.PurchaseRequest]: {
        id: "share.object.modal.purchaseRequest",
        defaultMessage: "purchase request",
    },
    [SharedObjectType.PurchaseOrder]: {
        id: "share.object.modal.purchaseOrder",
        defaultMessage: "purchase order",
    },
    [SharedObjectType.Invoice]: {
        id: "share.object.modal.invoice",
        defaultMessage: "invoice",
    },
    [SharedObjectType.Partnership]: {
        id: "share.object.modal.partnership",
        defaultMessage: "partnership",
    },
    [SharedObjectType.Contract]: {
        id: "share.object.modal.contract",
        defaultMessage: "contract",
    },
}

interface Props {
    open: boolean
    close: () => void
    organizationId: OrganizationId
    objectType: SharedObjectType
    objectId: string
    buyerOrganization: OrganizationSummary | undefined
    supplierOrganization: OrganizationSummary | undefined
    onlyEmail?: boolean
    sendShareEmail: boolean
    callback?: () => void
}

export const ShareObjectModal: React.FC<Props> = ({
    open,
    close,
    organizationId,
    objectType,
    objectId,
    buyerOrganization,
    supplierOrganization,
    onlyEmail,
    sendShareEmail = true,
    callback,
}) => {
    const { formatMessage } = useIntl()
    const [sharedEmail, setSharedEmail] = useState("")
    const [targetOrganizationId, setTargetOrganizationId] = useState<OrganizationId>("")
    const [partnerId, setPartnerId] = useState<OrganizationId | null>(null)
    const [confirmDeleteSharing, setConfirmDeleteSharing] = useState(false)
    const [currentSharing, setCurrentSharing] = useState<SharedObjectWithName | null>(null)
    const [loadingTimeout, setLoadingTimeout] = useState(false)

    const viewType = useAppSelector(selectPurchaseViewType)
    const brandNames = useAppSelector(selectPartnersBrandNames)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const currentUser = useAppSelector(selectUser)

    const organizationSharings = useFetchSharedObjects(organizationId)
    const [selectedContact, setSelectedContact] = useState<PartnerProfileContactI | null>(null)

    const [createSharedObject, { isLoading: loading }] = useShareObjectMutation()
    const [removeSharedObject, { isLoading: loadingDelete }] = useRemoveSharedObjectMutation()

    const objectSharings = useMemo(
        () => organizationSharings.filter((share) => share.objectId === objectId && share.objectType === objectType),
        [organizationSharings, objectId, objectType]
    )

    useEffect(() => {
        const partnerIdFromViewType = viewType === ViewTypeI.buyer ? supplierOrganization?.id : buyerOrganization?.id
        setPartnerId(partnerIdFromViewType ?? null)
    }, [buyerOrganization?.id, supplierOrganization?.id, viewType])

    const handleClose = useCallback(() => {
        close()
        setSharedEmail("")
        setTargetOrganizationId("")
    }, [close, setSharedEmail, setTargetOrganizationId])

    const handleChangeTargetOrganization = useCallback(
        (event: React.ChangeEvent<{ value: string }>) => {
            setTargetOrganizationId(event.target.value)
        },
        [setTargetOrganizationId]
    )

    const handleDeleteSharing = useCallback(async () => {
        if (currentSharing) {
            try {
                await removeSharedObject({ organizationId, sharingId: currentSharing.id })
                setCurrentSharing(null)
                toast.success(formatMessage(sharedObjectPermissionsModalMessages.deleteSharingSuccess))
                return true
            } catch (error) {
                toast.error(formatMessage(commonMessages.error))
                Sentry.captureException(new Error(`Issue deleting sharing #${currentSharing.id} - ${error}`), {
                    extra: {
                        currentSharing,
                        organizationId,
                    },
                })
            }
        }

        setCurrentSharing(null)
        return false
    }, [currentSharing, organizationId, removeSharedObject, formatMessage])

    const handleContactSave = usePartnerContactSave(currentOrganizationId, partnerId)

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!sharedEmail.length) {
            return
        }

        setLoadingTimeout(true)
        try {
            const user = await getOrCreateUserByEmail({ email: sharedEmail })
            if (selectedContact && targetOrganizationId !== currentOrganizationId) {
                handleContactSave(selectedContact)
            }

            let sharedUserOrganizationId: string = targetOrganizationId
            if (onlyEmail && supplierOrganization && buyerOrganization) {
                sharedUserOrganizationId = (
                    viewType === ViewTypeI.buyer ? supplierOrganization.id : buyerOrganization.id
                ) as string
            }

            await createSharedObject({
                organizationId,
                objectId,
                objectType,
                userId: user.userId,
                userOrganizationId: sharedUserOrganizationId,
                sendShareEmail,
                creator: currentUser.id,
            })

            setSharedEmail("")
            setTargetOrganizationId("")
            setSelectedContact(null)

            toast.success(
                formatMessage(sharedObjectPermissionsModalMessages.createSharingSuccess, {
                    objectType: capitalize(formatMessage(sharedObjectMessages[objectType])),
                })
            )

            if (callback) {
                callback()
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoadingTimeout(false)
        }
    }

    const showConfirmDeleteSharing = useCallback((sharing: SharedObjectWithName) => {
        setConfirmDeleteSharing(true)
        setCurrentSharing(sharing)
    }, [])
    const hideConfirmDeleteSharing = useCallback(() => {
        setConfirmDeleteSharing(false)
    }, [setConfirmDeleteSharing])

    const getOrganizationName = (name: string, orgId?: string | null) => {
        const fallbackName = name ?? ""
        if (!orgId) return fallbackName
        return brandNames[orgId] ?? fallbackName
    }

    const onPartnerContactChange = useCallback(
        (contact: PartnerProfileContactI | null) => {
            setSelectedContact(contact)
            setSharedEmail(contact?.email ?? "")
        },
        [setSelectedContact, setSharedEmail]
    )

    return (
        <>
            <Modal sx={{ overflow: "visible" }} open={open} onClose={handleClose} size={Size.SM}>
                <Modal.Header>
                    <h4>
                        {formatMessage(sharedObjectPermissionsModalMessages.title, {
                            objectType: formatMessage(sharedObjectMessages[objectType]),
                        })}
                    </h4>
                </Modal.Header>
                <form onSubmit={onSubmit}>
                    <Modal.Content>
                        {loading || loadingDelete || loadingTimeout ? (
                            <Loader />
                        ) : (
                            <Stack direction="column" gap={2}>
                                {!onlyEmail && !!buyerOrganization && !!supplierOrganization && (
                                    <TextField
                                        label={formatMessage(sharedObjectPermissionsModalMessages.selectOrganization)}
                                        value={targetOrganizationId}
                                        onChange={handleChangeTargetOrganization}
                                        select
                                        required
                                    >
                                        {[buyerOrganization, supplierOrganization].map((option, index) => (
                                            <MenuItem key={option.id as string} value={option.id as string}>
                                                {getOrganizationName(option.name, option.id)} -{" "}
                                                {index === 0
                                                    ? formatMessage(sharedObjectPermissionsModalMessages.buyer)
                                                    : formatMessage(sharedObjectPermissionsModalMessages.supplier)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                {(!!targetOrganizationId || onlyEmail) && (
                                    <PartnerContactsSelector
                                        currentOrganizationId={currentOrganizationId}
                                        partnerId={partnerId === targetOrganizationId || onlyEmail ? partnerId : null}
                                        onContactChange={onPartnerContactChange}
                                        selectedContact={selectedContact}
                                    />
                                )}
                                <Stack direction="column" gap={2}>
                                    {objectSharings?.map((share) => (
                                        <SharedObjectMemo
                                            key={share.id}
                                            shareObject={share}
                                            callbackButton={showConfirmDeleteSharing}
                                        />
                                    ))}
                                </Stack>
                            </Stack>
                        )}
                    </Modal.Content>
                    <Modal.Footer>
                        <Button type="transparent" onClick={handleClose}>
                            {formatMessage(sharedObjectPermissionsModalMessages.cancel)}
                        </Button>
                        <Button buttonType="submit" disabled={loading || !sharedEmail.length}>
                            {formatMessage(sharedObjectPermissionsModalMessages.share)}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <ConfirmModal
                open={confirmDeleteSharing}
                close={hideConfirmDeleteSharing}
                onConfirm={handleDeleteSharing}
                title={formatMessage(sharedObjectPermissionsModalMessages.confirmDeleteTitle)}
            >
                {formatMessage(sharedObjectPermissionsModalMessages.confirmDeleteSharing)}
            </ConfirmModal>
        </>
    )
}
