import "dayjs/locale/fr"
import React, { Dispatch, SetStateAction, useEffect } from "react"
import { useCallback, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { AutocompleteWithTags, Button, Modal } from "~/components"
import { budgetApi } from "~/domains/transactions/budget/api/budgetApi"
import { transactionTypeMessages } from "~/domains/transactions/budget/core/messages"
import { AddTransactionPayloadI, BudgetDataI, BudgetDataWithMetricsI } from "~/domains/transactions/budget/types"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { InvoiceI, OrganizationId, ReceivedDocumentI } from "~/types"

const messages = defineMessages({
    title: { id: "transactions.modal.addToBudget.title", defaultMessage: "Add {transactionType} to a budget" },
    none: { id: "transactions.modal.addToBudget.none", defaultMessage: "None" },
    selectBudget: { id: "transactions.modal.addToBudget.selectBudget", defaultMessage: "Select a budget" },
    changesSaved: { id: "transactions.modal.addToBudget.changesSaved", defaultMessage: "Changes has been saved." },
    noChanges: {
        id: "transactions.modal.addToBudget.noChanges",
        defaultMessage: "There is no changes. Please select a new budget.",
    },
})

interface Props {
    organizationId: OrganizationId
    transaction: ReceivedDocumentI | PurchaseRequestDetails | PurchaseOrders | InvoiceI
    budgets: BudgetDataI[]
    transactionPayload: AddTransactionPayloadI
    open: boolean
    close: () => void
    handleSuccess: () => void
    draftBudgets?: BudgetDataWithMetricsI[]
    setDraftBudgets?: Dispatch<SetStateAction<BudgetDataWithMetricsI[]>>
}

export const ModalAddToBudget: React.FC<Props> = ({
    organizationId,
    budgets,
    open,
    close,
    transaction,
    transactionPayload,
    handleSuccess,
    draftBudgets,
    setDraftBudgets,
}) => {
    const { formatMessage } = useIntl()

    const [selectedBudgets, setSelectedBudgets] = useState<BudgetDataI[]>([])
    const [selectedOriginalBudgets, setSelectedOriginalBudgets] = useState<BudgetDataI[]>([])

    useEffect(() => {
        if (transaction.budgets) {
            setSelectedBudgets(transaction.budgets.map((b: BudgetDataWithMetricsI) => b.budget))
            setSelectedOriginalBudgets(transaction.budgets.map((b) => b.budget))
        }
    }, [transaction, setSelectedBudgets, setSelectedOriginalBudgets])

    const onSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const newBudgets = selectedBudgets.filter((b) => {
                return !selectedOriginalBudgets.find((budget) => budget.id === b.id)
            })

            const removedBudgets = selectedOriginalBudgets.filter((budget) => {
                return !selectedBudgets.find((b) => budget.id === b.id)
            })

            if (!transactionPayload.transactionRefId && setDraftBudgets) {
                const newBudgetsWithMetrics = newBudgets.map((budget) => {
                    return {
                        budget,
                        transaction: transactionPayload,
                    } as BudgetDataWithMetricsI
                })
                setDraftBudgets(newBudgetsWithMetrics)
            } else {
                if (newBudgets.length) {
                    await Promise.all(
                        newBudgets.map((budget) => {
                            return budgetApi.addBudgetTransaction(organizationId, budget.id, transactionPayload)
                        })
                    )
                }

                if (removedBudgets.length) {
                    await Promise.all(
                        removedBudgets.map((budget) => {
                            const budgetWithMetrics = transaction.budgets?.find((b) => b.budget.id === budget.id)
                            return budgetApi.deleteBudgetTransaction(
                                organizationId,
                                budgetWithMetrics?.transaction.id as string
                            )
                        })
                    )
                }
            }
            close()
            handleSuccess()
        },
        [selectedOriginalBudgets, selectedBudgets, organizationId, transactionPayload]
    )

    return (
        <Modal open={open} onClose={close}>
            <Modal.Header>
                <h4>
                    {formatMessage(messages.title, {
                        transactionType: transactionTypeMessages[transactionPayload.transactionType]
                            ? formatMessage(transactionTypeMessages[transactionPayload.transactionType])
                            : "",
                    })}
                </h4>
            </Modal.Header>
            <form onSubmit={onSubmit}>
                <Modal.Content>
                    <AutocompleteWithTags
                        options={budgets}
                        selectedEntities={selectedBudgets || draftBudgets}
                        setSelectedEntities={setSelectedBudgets}
                        numberOfTagsToShow={3}
                        getOptionLabel={(budget) => budget.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        placeholder={formatMessage(messages.selectBudget)}
                        classname="contacts-role-name mb-12"
                    />
                </Modal.Content>
                <Modal.Footer>
                    <Button type="transparent" onClick={() => close()}>
                        {formatMessage(commonMessages.cancel)}
                    </Button>
                    <Button type="primary" buttonType="submit">
                        {formatMessage(commonMessages.save)}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}
