import * as Sentry from "@sentry/browser"
import { useCallback, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { store } from "~/store"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId, OrganizationTeamI } from "~/types"
import { WHITE_SPACE_REGEXP } from "~/utils/string"

import { organizationActions, selectOrganizationTeamsState, selectOrganizationsFilters } from "../organizationSlice"

const noFilter = () => true

const getTeamsFilter = (filter: string) => {
    if (!filter) {
        return noFilter
    }
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
    return (team: OrganizationTeamI) => {
        const teamWords = `${team.name}`.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
        return filterWords.every((word) => teamWords.some((teamWord) => teamWord.indexOf(word) >= 0))
    }
}

const NO_TEAMS: OrganizationTeamI[] = []

export const useFetchOrganizationTeams = (organizationId?: OrganizationId, hasPermission?: boolean) => {
    const dispatch = useDispatch()
    const filters = useAppSelector(selectOrganizationsFilters)

    const state = useAppSelector(selectOrganizationTeamsState)
    const teams = organizationId && state.teams[organizationId] ? state.teams[organizationId] : NO_TEAMS
    const loading = organizationId && state.loading[organizationId] === true

    const fetchOrganizationTeams = useCallback(
        async (forceFetch?: boolean) => {
            if (!organizationId) return
            const { teams, loading } = selectOrganizationTeamsState(store.getState())
            if (!forceFetch && (teams[organizationId] || loading[organizationId])) {
                return
            }
            dispatch(
                organizationActions.fetchOrganizationTeams({
                    organizationId,
                })
            )
            try {
                const teams = await organizationApi.fetchOrganizationTeams(organizationId)
                dispatch(
                    organizationActions.fetchOrganizationTeamsSuccess({
                        organizationId,
                        teams,
                    })
                )
                return teams
            } catch (error) {
                dispatch(
                    organizationActions.fetchOrganizationTeamsError({
                        organizationId,
                        error: `${error}`,
                    })
                )
                console.error(
                    `Fetch Organization Team error for organizationId ${organizationId}: `,
                    error,
                    "payload:",
                    teams
                )
                Sentry.captureException("Fetch Organization Team error", {
                    tags: {
                        organizationId,
                    },
                    extra: {
                        errors: error,
                        payload: teams,
                    },
                })
            }
        },
        [organizationId]
    )

    useEffect(() => {
        hasPermission && fetchOrganizationTeams()
    }, [fetchOrganizationTeams, hasPermission])

    return useMemo(
        () => ({
            teams: teams.filter(getTeamsFilter(filters.teams)),
            loading,
            fetchOrganizationTeams,
        }),
        [teams, loading, fetchOrganizationTeams]
    )
}
