import dayjs from "dayjs"
import { FC } from "react"
import { useIntl } from "react-intl"

import { ExtractGenericDate } from "~/domains/transactions/invoices/_views/supplier/components/Extract/ExtractGenericDate"
import { extractMessages } from "~/domains/transactions/invoices/_views/supplier/components/Extract/extractMessages"
import { UpdateExtractDataFunction } from "~/domains/transactions/invoices/_views/supplier/components/Extract/types"

interface ExtractIssueDateProps {
    issueDate: string | undefined | null
    updateData: UpdateExtractDataFunction
    disabled?: boolean
}

export const ExtractIssueDate: FC<ExtractIssueDateProps> = ({ issueDate, updateData, disabled }) => {
    const { formatMessage } = useIntl()

    const onIssueDateChange = (newValue: dayjs.Dayjs | null) => {
        updateData({ issueDate: newValue ? newValue.format("YYYY-MM-DD") : undefined })
    }

    return (
        <ExtractGenericDate
            label={formatMessage(extractMessages.issueDate)}
            issueDate={issueDate}
            onChange={onIssueDateChange}
            disabled={disabled}
        />
    )
}
