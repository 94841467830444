import { createContext, useContext } from "react"

const defaultContext: ResizableGridContextType = {
    stateKey: "",
}

export type ResizableGridState = { [index: number]: number }

export interface ResizableGridContextType {
    stateKey: string
}

export const ResizableGridContext = createContext<ResizableGridContextType>(defaultContext)

export const useResizableGridContext = () => useContext(ResizableGridContext)
