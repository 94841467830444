import * as Sentry from "@sentry/react"
import { useCallback } from "react"
import { defineMessage, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { useCommunicationApi } from "~/domains/communication/chat"
import { communicationActions } from "~/domains/communication/chat/store/CommunicationSlice"
import { MessageId } from "~/domains/communication/chat/types"
import { useAppDispatch } from "~/store/hooks"
import { pipeResult } from "~/types/Result"

const errorMessage = defineMessage({
    id: "communication.message.delete.error",
    defaultMessage: "Failed to delete message",
})

export const useDeleteMessage = () => {
    const communicationApi = useCommunicationApi()
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()

    return useCallback(
        async (messageId: MessageId) => {
            const result = await communicationApi.deleteMessage(messageId)
            pipeResult(
                result,
                () => {
                    dispatch(communicationActions.deleteMessage(messageId))
                },
                (error) => {
                    Sentry.captureException(`Failed to delete message: ${error}`, { extra: { messageId, error } })
                    toast.error(formatMessage(errorMessage))
                }
            )
        },
        [communicationApi]
    )
}
