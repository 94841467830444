import { Grid, Tooltip } from "@mui/material"
import { useCallback, useState } from "react"
import { AlertCircle, CheckCircle, Clock, Edit, Eye } from "react-feather"
import { defineMessages } from "react-intl"

import { Chip, SafeFormattedMessage } from "~/components"
import { DocumentObjectType } from "~/domains/identity/documents/types"
import { SavePayloadType } from "~/domains/identity/organization/components/ModalOrganizationSelectorDetails/types"
import { ApprovalStatus } from "~/domains/orchestration/flows-v0/types/Approval"
import { ModalSelectOrganization } from "~/domains/transactions/_shared/components/ModalSelectOrganization/ModalSelectOrganization"
import { Address } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { OrganizationAddressI, OrganizationId } from "~/types"

import { OrganizationDetailsModal } from "./OrganizationDetailsModal"

const messages = defineMessages({
    currentUserSideLabel: {
        id: "purchase.organization.currentUserSideLabel",
        defaultMessage: "Me",
    },
    supplierPassed: {
        id: "purchase.organization.supplier.passed",
        defaultMessage: "This vendor is approved",
    },
    supplierPending: {
        id: "purchase.organization.supplier.pending",
        defaultMessage: "Validation pending",
    },
    supplierRefused: {
        id: "purchase.organization.supplier.refused",
        defaultMessage: "This vendor is refused",
    },
})

interface Props {
    title: string
    name: string | undefined
    id: OrganizationId | null
    objectType: DocumentObjectType
    isCurrentUserSide?: boolean
    billingAddress?: Address | null
    shippingAddress?: Address | null
    onSave?: (payload: SavePayloadType) => void
    withOrganizationAddress?: boolean
    withSendingAddress?: boolean
    showOrganizationSelector?: boolean
    selectOrganizationLabel?: string
    readOnly?: boolean
    partnerApprovalStatus?: ApprovalStatus
}

const addressToOrganizationAddress = (address: Address): OrganizationAddressI => ({
    addressLine: address.street,
    secondaryAddressLine: address.street2 ?? "",
    zipCode: address.zipCode,
    city: address.city,
    country: address.country,
})
export function Organization({
    title,
    name,
    id,
    isCurrentUserSide,
    billingAddress,
    shippingAddress,
    onSave,
    withOrganizationAddress = true,
    withSendingAddress = false,
    showOrganizationSelector,
    selectOrganizationLabel,
    readOnly,
    objectType,
    partnerApprovalStatus,
}: Props) {
    const [displayDetailsModal, setDisplayDetailsModal] = useState<boolean>(false)
    const [displaySelectOrganizationModal, setDisplaySelectOrganizationModal] = useState<boolean>(false)

    const showDetailsModal = useCallback(() => setDisplayDetailsModal(true), [])
    const hideDetailsModal = useCallback(() => setDisplayDetailsModal(false), [])

    const showSelectOrganizationModal = useCallback(() => setDisplaySelectOrganizationModal(true), [])
    const hideSelectOrganizationModal = useCallback(() => setDisplaySelectOrganizationModal(false), [])

    const getApprovalStatusIcon = (status: ApprovalStatus | undefined) => {
        switch (status) {
            case ApprovalStatus.PASSED:
                return {
                    icon: <CheckCircle color="var(--color-green)" size={20} />,
                    message: messages.supplierPassed,
                }
            case ApprovalStatus.REFUSED:
                return {
                    icon: <AlertCircle color="var(--color-red)" size={20} />,
                    message: messages.supplierRefused,
                }
            case ApprovalStatus.PENDING:
                return {
                    icon: <Clock color="var(--color-grey-light)" size={20} />,
                    message: messages.supplierPending,
                }
            default:
                return null
        }
    }

    const approvalStatusIcon = getApprovalStatusIcon(partnerApprovalStatus)

    const approvalStatus =
        partnerApprovalStatus && approvalStatusIcon ? (
            <Tooltip title={<SafeFormattedMessage {...approvalStatusIcon.message} />} placement="bottom">
                {approvalStatusIcon.icon}
            </Tooltip>
        ) : null

    return (
        <>
            <Grid container alignItems="center" gap={1} flexGrow={1}>
                <h4>{title}</h4>
                {isCurrentUserSide && (
                    <Chip variant="primary" size="medium" borderLess>
                        <SafeFormattedMessage {...messages.currentUserSideLabel} />
                    </Chip>
                )}
            </Grid>
            <div className="organization-body">
                {showOrganizationSelector ? (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={showSelectOrganizationModal}
                    >
                        <Grid item flexGrow={1}>
                            {!id && selectOrganizationLabel ? (
                                <span className="select-organization-label">{selectOrganizationLabel}</span>
                            ) : (
                                <Tooltip title={name} placement="bottom">
                                    <span className="truncate-text">{name}</span>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item marginRight={1}>
                            {approvalStatus}
                        </Grid>
                        <span>
                            <Edit size={20} />
                        </span>
                    </Grid>
                ) : (
                    <Grid container alignItems="center" justifyContent="space-between" onClick={showDetailsModal}>
                        <Grid item flexGrow={1}>
                            <Tooltip title={name} placement="bottom">
                                <span className="truncate-text">{name}</span>
                            </Tooltip>
                        </Grid>
                        <Grid item marginRight={1}>
                            {approvalStatus}
                        </Grid>
                        <span>{onSave ? <Edit size={20} /> : <Eye size={20} />}</span>
                    </Grid>
                )}
            </div>
            {displayDetailsModal && id && (
                <OrganizationDetailsModal
                    organizationId={id}
                    open={displayDetailsModal}
                    onClose={hideDetailsModal}
                    onSave={onSave}
                    withOrganizationAddress={withOrganizationAddress}
                    withSendingAddress={withSendingAddress}
                    shippingAddress={shippingAddress ? addressToOrganizationAddress(shippingAddress) : undefined}
                    billingAddress={billingAddress ? addressToOrganizationAddress(billingAddress) : undefined}
                    readOnly={readOnly}
                />
            )}
            {displaySelectOrganizationModal ? (
                <ModalSelectOrganization
                    objectType={objectType}
                    organizationId={id}
                    organizationName={name}
                    open={displaySelectOrganizationModal}
                    close={hideSelectOrganizationModal}
                />
            ) : null}
        </>
    )
}
