// import { BankConnectorType } from "~/types"
import { BankConnectorsI, ExecutionDate, PaymentStatus } from "~/domains/payment/types/PaymentTypes"

export interface PaymentState {
    loading: boolean
    bankConnectors: BankConnectorsI[]
    status: PaymentStatus | null
    updateTimestamp: string | null
    scheduleDate: Date | null
    bankConnectorSelected: BankConnectorsI | null
    executionDateSelected: ExecutionDate | null
    paymentUrl: string
    paymentInProgress: boolean
    initWs: boolean
}

export const paymentState: PaymentState = {
    loading: false,
    bankConnectors: [],
    status: null,
    updateTimestamp: null,
    scheduleDate: null,
    bankConnectorSelected: null,
    executionDateSelected: null,
    paymentInProgress: false,
    paymentUrl: "",
    initWs: false,
}
