import { TagObjectRecordI } from "~/domains/analytics/tags/types/TagObjectRecord"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
    PurchaseRequestSummary,
    PurchaseRequestsTab,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { CurrencyCodes, SpiceDBPermissionsResult } from "~/types"

export interface PurchaseRequestsState {
    loading: boolean
    error: string | null
    filter: string
    organizationId: string | null
    shouldUpdatePR: boolean
    list: PurchaseRequestSummary[]
    data: PurchaseRequestDetails
    tagsLoading: boolean
    tags: TagObjectRecordI | undefined
    currentPurchaseRequestsTab: PurchaseRequestsTab
    permissions: SpiceDBPermissionsResult | null
}

export const purchaseRequestsState: PurchaseRequestsState = {
    loading: false,
    error: null,
    shouldUpdatePR: false,
    filter: "",
    organizationId: null,
    list: [],
    tagsLoading: false,
    tags: undefined,
    currentPurchaseRequestsTab: PurchaseRequestsTab.ALL,
    permissions: null,
    data: {
        id: "",
        organizationId: "",
        supplierId: "",
        requesterUserId: "",
        totalAmount: 0,
        totalAmountExcludingTax: 0,
        taxRate: 0,
        status: PurchaseRequestStatus.DRAFT,
        description: "",
        currency: CurrencyCodes.EUR,
        recurrence: { type: "OneShot" },
        lines: [],
        documents: [],
        number: 0,
        purchaseOrder: null,
        expectedDeliveryDate: "",
        creationDate: "",
        submittedDate: null,
    },
}
