import { Stack } from "@mui/material"
import { MessageDescriptor, defineMessages } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    noResultFound: {
        id: "common.noResultFound",
        defaultMessage: "No result found",
    },
    clearSearch: {
        id: "common.clearSearch",
        defaultMessage: "Clear search",
    },
})

interface DataGridNoRowsProps {
    message?: MessageDescriptor
    resetSearchQuery?: () => void
}

export const DataGridNoRows = ({ resetSearchQuery, message }: DataGridNoRowsProps) => {
    return (
        <Stack direction="row" justifyContent="center" alignItems="center" minHeight={200}>
            <Stack alignItems="center" gap={3}>
                <SafeFormattedMessage {...(message || messages.noResultFound)} />

                {resetSearchQuery && (
                    <Button type="neutral" onClick={resetSearchQuery}>
                        <SafeFormattedMessage {...messages.clearSearch} />
                    </Button>
                )}
            </Stack>
        </Stack>
    )
}
