import * as Sentry from "@sentry/browser"
import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

import { DocumentDataI, UploadDocumentResponseV2I } from "~/components/UploadDocument/core/Documents"
import { UploadDocumentDTO } from "~/domains/identity/documents/types"
import { KYBType } from "~/domains/identity/kyb/components/KYB"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

import { documentApi } from "../Api/DocumentApi"
import { documentActions, selectDocuments } from "../store/documentSlice"

const messages = defineMessages({
    errorUploadDocument: {
        id: "purchase.requests.request.header.actions.errorUploadDocument",
        defaultMessage: "An error occurred while uploading document: {documentName}",
    },
})

export const useUploadDocument = (organizationId: OrganizationId, kybType?: KYBType) => {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()
    const { loadingUpload } = useAppSelector(selectDocuments)

    const uploadDocument = useCallback(
        async (payload: UploadDocumentDTO) => {
            if (!payload.file) return Promise.resolve(null)

            try {
                if (kybType) {
                    dispatch(documentActions.uploadDocumentByTypeLoading({ organizationId, type: kybType }))
                } else {
                    dispatch(documentActions.uploadDocumentLoading())
                }
                const result: UploadDocumentResponseV2I = await documentApi.uploadDocument(payload)
                const newDocument: DocumentDataI = {
                    id: result.id,
                    name: payload.name,
                    documentType: payload.documentType,
                    objectType: payload.objectType,
                    createdAt: result.createdAt,
                    updatedAt: result.updatedAt,
                    latestFileVersion: result.latestFileVersion,
                }
                if (kybType) {
                    dispatch(
                        documentActions.uploadDocumentByTypeSuccess({
                            organizationId,
                            type: kybType,
                            document: newDocument,
                        })
                    )
                } else {
                    dispatch(documentActions.uploadDocumentSuccess(newDocument))
                }

                return newDocument
            } catch (error) {
                if (kybType) {
                    dispatch(
                        documentActions.uploadDocumentByTypeFailed({
                            organizationId,
                            type: kybType,
                            error: `${error}`,
                        })
                    )
                } else {
                    dispatch(documentActions.uploadDocumentFailed(`${error}`))
                }
                Sentry.captureException("Upload documents error", {
                    extra: {
                        errors: error,
                        payload: payload,
                    },
                })
                toast.error(formatMessage(messages.errorUploadDocument, { documentName: payload.name }))
                return Promise.reject(error)
            }
        },
        [organizationId, kybType]
    )

    return { uploadDocument, loadingUpload }
}
