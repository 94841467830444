/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect, useState } from "react"

import { findTagRecursively } from "~/domains/analytics/tags/core/tagGroupsAndTags"
import { manageSelectedTags, useCreateTagObject, useDeleteTagObject } from "~/domains/analytics/tags/hooks"
import { useUpdateTagObject } from "~/domains/analytics/tags/hooks/useUpdateTagObject"
import { selectLinesTags, tagsActions } from "~/domains/analytics/tags/store/tagsSlice"
import { useTagsApi } from "~/domains/analytics/tags/tagsApi"
import { SelectedTagWithObjectIdI, TagGroupI, TagObjectI, TagObjectType } from "~/domains/analytics/tags/types"
import { AnalyticalItem } from "~/domains/identity/custom-fields/components/AnalyticsTable/AnalyticsTable"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { getResultSuccessValue, isResultSuccess } from "~/types/Result"
import { isDefined } from "~/utils/isDefined"

type TagsforLines = {
    loading: boolean
    selectedTags: SelectedTagWithObjectIdI[]
    setSelectedTags: (
        tagsOrFunction: SelectedTagWithObjectIdI[] | ((state: SelectedTagWithObjectIdI[]) => SelectedTagWithObjectIdI[])
    ) => void
    fetchTags: () => Promise<void>
}

const findTagInTagGroups = (tag: TagObjectI, tagGroups: TagGroupI[] | null): SelectedTagWithObjectIdI | null => {
    if (!tagGroups || !tagGroups.length) return null

    for (const tagGroup of tagGroups) {
        const foundTag = findTagRecursively(tagGroup.tags, tag.tagId)

        if (foundTag) {
            return {
                ...foundTag,
                objectId: tag.objectId,
                tagGroup,
            }
        }
    }

    return null
}

const lineHasId = (line: AnalyticalItem): line is AnalyticalItem & { id: string } => "id" in line

interface UseTagsForLinesProps<T extends AnalyticalItem> {
    lines: T[]
    organizationId?: string
    tagGroups: TagGroupI[] | null
    objectType: TagObjectType
}

export const useTagsForLines = ({
    lines,
    organizationId,
    tagGroups,
    objectType,
}: UseTagsForLinesProps<AnalyticalItem>): TagsforLines => {
    const dispatch = useAppDispatch()
    const tagsApi = useTagsApi()
    const tags = useAppSelector(selectLinesTags)
    const user = useAppSelector(selectUser)
    const [selectedTags, setSelectedTags] = useState<SelectedTagWithObjectIdI[]>([])
    const [loading, setLoading] = useState(false)

    const createTagObject = useCreateTagObject(organizationId, undefined, objectType)
    const updateTagObject = useUpdateTagObject(organizationId, undefined, objectType)
    const deleteTagObject = useDeleteTagObject(organizationId, undefined)

    useEffect(() => {
        setSelectedTags(tags)
    }, [tags])

    const setSelectedTagsWraper = useCallback(
        (fn: ((state: SelectedTagWithObjectIdI[]) => SelectedTagWithObjectIdI[]) | SelectedTagWithObjectIdI[]) => {
            setSelectedTags((previousState) => {
                const newSate = manageSelectedTags(previousState, fn, createTagObject, deleteTagObject, updateTagObject)
                dispatch(tagsActions.setLinesTags(newSate))
                // also store tags for pending lines if the lines are not saved yet
                lines.forEach((line) => {
                    if (line.temporaryId) {
                        dispatch(
                            tagsActions.setPendingLineTags({
                                lineId: line.temporaryId,
                                tags: newSate.filter(
                                    (tag) => (tag as SelectedTagWithObjectIdI).objectId === line.temporaryId
                                ),
                            })
                        )
                    }
                })
                return newSate
            })
        },
        [setSelectedTags, dispatch, user, createTagObject, updateTagObject, deleteTagObject, lines]
    )

    const fetchTags = useCallback(async () => {
        if (!lines.length || !organizationId) return

        setLoading(true)
        const results = await Promise.all(
            lines.filter(lineHasId).map((line) => tagsApi.getTagObjects(organizationId, line.id))
        )

        const tags = results.flatMap((res) => res.filter(isResultSuccess).map(getResultSuccessValue))
        const tagsWithObjectIds = tags
            .map((tag) => {
                const tagFromTagGroup = findTagInTagGroups(tag, tagGroups)
                if (tagFromTagGroup) {
                    return tagFromTagGroup
                }
                return null
            })
            .filter(isDefined)

        setLoading(false)
        setSelectedTags(tagsWithObjectIds)
        dispatch(tagsActions.setLinesTags(tagsWithObjectIds))
        // also store tags for pending lines if the lines are not saved yet
        lines.forEach((line) => {
            if (line.temporaryId) {
                dispatch(
                    tagsActions.setPendingLineTags({
                        lineId: line.temporaryId,
                        tags: tagsWithObjectIds.filter((tag) => tag.objectId === line.temporaryId),
                    })
                )
            }
        })
    }, [setSelectedTagsWraper, lines, organizationId, tagGroups])

    return { loading, selectedTags, setSelectedTags: setSelectedTagsWraper, fetchTags }
}
