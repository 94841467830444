import { ContractCheckI } from "~/domains/identity/documents/types/UnderstandingTypes"
import { InvoiceI, OrganizationI } from "~/types"
import { isValidIBANNumber } from "~/utils/iban"

// TODO: have a look if we need to update this
export const isRegistrationComplete = (organization: OrganizationI): boolean =>
    !!(
        organization.registration &&
        organization.registration.legalName &&
        organization.registration.preferredRegistrationNumber &&
        organization.registration.vatNumber
    )

export const isAddressComplete = (organization: OrganizationI): boolean =>
    !!(
        organization.address &&
        organization.address.addressLine &&
        organization.address.zipCode &&
        organization.address.city &&
        organization.address.country
    )

export const isAccountingContactComplete = (organization: OrganizationI): boolean =>
    !!(
        organization.accountingContact &&
        organization.accountingContact.firstName &&
        organization.accountingContact.lastName &&
        organization.accountingContact.email &&
        organization.accountingContact.phoneNumber
    )

export const areBankDetailsComplete = (organization: OrganizationI): boolean =>
    !!organization.bankDetails?.iban && isValidIBANNumber(organization.bankDetails.iban)

export const getOrganizationCompletenessProgress = (organization: OrganizationI) =>
    ((isRegistrationComplete(organization) ? 1 : 0) +
        (isAccountingContactComplete(organization) ? 1 : 0) +
        (isAddressComplete(organization) ? 1 : 0) +
        (areBankDetailsComplete(organization) ? 1 : 0)) /
    4

export const getInvoiceProgress = (
    invoice: InvoiceI,
    contractCheckDetail: ContractCheckI | null,
    isLoading: boolean,
    pennylaneStatusOutcomeSuccess?: boolean
): number => {
    let totalSteps = 2
    let completedSteps = 1

    if (invoice.signed) {
        completedSteps++
    }

    if (contractCheckDetail && contractCheckDetail.matchWithPaymentCondition && !isLoading) {
        totalSteps++
        completedSteps++
    }

    if (pennylaneStatusOutcomeSuccess !== undefined) {
        totalSteps++
        if (pennylaneStatusOutcomeSuccess) {
            completedSteps++
        }
    }

    return completedSteps / totalSteps
}

export const getInitials = (name: string): string =>
    name
        .split(/\s+/)
        .map((word) => (word.length > 0 ? word[0] : ""))
        .join("")
        .toLocaleUpperCase()
        .slice(0, 2)
