import * as Sentry from "@sentry/browser"
import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

import { useSavePartnershipPendingCustomFieldObjects } from "~/domains/identity/custom-fields/hooks/useSavePartnershipPendingCustomFieldObjects"
import { selectPendingCustomFieldObjects } from "~/domains/identity/custom-fields/store"
import { partnerApi } from "~/domains/identity/partners/api/partnerApi"
import { CreatePartnershipPayload, PartnershipTypeOption } from "~/domains/identity/partners/types"
import { hasPendingCustomFieldObjects } from "~/domains/transactions/_shared/utils/purchasesUtils"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

import { bookOfRelationsActions } from "../bookOfRelationsSlice"

const messages = defineMessages({
    createError: {
        id: "partners.bookofrelations.modal.create.createError",
        defaultMessage: "We encountered an error creating this partnership.",
    },
})

export const useCreatePartnership = (organizationId: OrganizationId) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const pendingCustomFieldObjects = useAppSelector(selectPendingCustomFieldObjects)
    const savePartnershipPendingCustomFieldObjects = useSavePartnershipPendingCustomFieldObjects(organizationId)

    const createPartnership = useCallback(
        async (payload: CreatePartnershipPayload, shouldCreateCF = false, attempt = 1) => {
            try {
                const partnership = await partnerApi.createPartnership(organizationId, payload)
                const hasPendingCFObjects = hasPendingCustomFieldObjects(pendingCustomFieldObjects)
                if (hasPendingCFObjects && shouldCreateCF) {
                    await savePartnershipPendingCustomFieldObjects(partnership)
                }
                if (partnership) {
                    dispatch(bookOfRelationsActions.fetchPartnershipDataSuccess([partnership]))
                }
                return partnership
            } catch (error) {
                if (attempt < 5) {
                    setTimeout(() => createPartnership(payload, shouldCreateCF, attempt + 1), 1000 * attempt)
                } else {
                    Sentry.captureException(error)
                    toast.error(formatMessage(messages.createError))
                }
            }
        },
        [organizationId, dispatch, savePartnershipPendingCustomFieldObjects]
    )

    const createPartnerships = useCallback(
        async (payload: CreatePartnershipPayload) => {
            if (payload.partnershipType === PartnershipTypeOption.BOTH) {
                await createPartnership({ ...payload, partnershipType: PartnershipTypeOption.SUPPLIER }, true)
                await createPartnership({ ...payload, partnershipType: PartnershipTypeOption.BUYER }, false)
            } else {
                await createPartnership(payload, payload.partnershipType === PartnershipTypeOption.SUPPLIER)
            }
        },
        [createPartnership]
    )

    return { createPartnerships }
}
