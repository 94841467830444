import { Tooltip } from "@mui/material"
import { useCallback, useState } from "react"
import { X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { ActionsMenu, Size } from "~/components"
import { Button } from "~/components/Button/Button"
import { ModalConfirm } from "~/components/ModalConfirm/ModalConfirm"
import { useDeleteMessage } from "~/domains/communication/chat/hooks/useDeleteMessage"
import { communicationActions } from "~/domains/communication/chat/store/CommunicationSlice"
import { selectMessageToUpdate } from "~/domains/communication/chat/store/selectors"
import { MessageWithUserI } from "~/domains/communication/chat/types/Message"
import { useAppDispatch, useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    message: {
        id: "communication.message.delete.confirmMessage",
        defaultMessage: "Are you sure you want to delete this message?",
    },
    title: {
        id: "communication.message.delete.confirmTitle",
        defaultMessage: "Delete message",
    },
    confirm: {
        id: "communication.message.delete.confirmButton",
        defaultMessage: "Delete message",
    },
})

interface MessageActionsProps {
    message: MessageWithUserI
}

export const MessageActions = ({ message }: MessageActionsProps) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const [confirmDeleteMessage, setConfirmDeleteMessage] = useState(false)
    const messageToUpdate = useAppSelector(selectMessageToUpdate)
    const isMessageToUpdate = messageToUpdate?.id === message.id
    const deleteMessage = useDeleteMessage()

    const handleConfirmDeleteMessage = () => setConfirmDeleteMessage(true)
    const handleCloseDeleteMessage = () => setConfirmDeleteMessage(false)

    const handleUpdateMessage = useCallback(() => {
        dispatch(communicationActions.setMessageToUpdate(isMessageToUpdate ? null : message))
    }, [dispatch, message, isMessageToUpdate])

    const handleResetUpdateMessage = () => dispatch(communicationActions.setMessageToUpdate(null))

    const handleDeleteMessage = useCallback(() => {
        deleteMessage(message.id)
        handleCloseDeleteMessage()
    }, [deleteMessage, message])

    const actions = [
        { label: formatMessage(commonMessages.edit), action: handleUpdateMessage },
        { label: formatMessage(commonMessages.delete), action: handleConfirmDeleteMessage },
    ]

    return (
        <>
            {isMessageToUpdate ? (
                <Tooltip title={formatMessage(commonMessages.cancelEdition)}>
                    <span>
                        <Button size="x-small" type="primary-light" onClick={handleResetUpdateMessage}>
                            <X size={16} />
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <ActionsMenu dataType={message} actions={actions} />
            )}
            <ModalConfirm
                position="start"
                size={Size.SM}
                message={formatMessage(messages.message)}
                title={formatMessage(messages.title)}
                button1={
                    <Button
                        type="transparent"
                        onClick={handleCloseDeleteMessage}
                        text={formatMessage(commonMessages.cancel)}
                    />
                }
                button2={<Button type="error" onClick={handleDeleteMessage} text={formatMessage(messages.confirm)} />}
                open={confirmDeleteMessage}
            />
        </>
    )
}
