import {
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Button, Modal, SafeFormattedMessage } from "~/components"
import {
    CustomFormQuestion,
    QuestionFieldType,
    QuestionFieldTypeEnum,
} from "~/domains/identity/custom-forms/types/CustomForms"

const messages = defineMessages({
    addQuestion: {
        id: "smartForms.formEditor.addQuestion",
        defaultMessage: "Add question",
    },
    questionContent: {
        id: "smartForms.formEditor.questionContent",
        defaultMessage: "Question content",
    },
    fieldType: {
        id: "smartForms.formEditor.fieldType",
        defaultMessage: "Field type",
    },
    dependentQuestion: {
        id: "smartForms.formEditor.dependentQuestion",
        defaultMessage: "Display when existing question is answered positivley:",
    },
    requiredQuestion: {
        id: "smartForms.formEditor.requiredQuestion",
        defaultMessage: "Required question",
    },
})

export const QuestionModal = ({
    open,
    close,
    createQuestion,
    exisitngQuestions,
}: {
    open: boolean
    close: () => void
    createQuestion: (question: CustomFormQuestion) => void
    exisitngQuestions: CustomFormQuestion[]
}) => {
    const [questionContent, setQuestionContent] = useState<CustomFormQuestion | null>(null)

    useEffect(() => {
        const newQuestion = {
            id: "1",
            questionContent: "",
            fieldType: QuestionFieldTypeEnum.TextField,
            required: false,
            order: 0,
            savedValue: "",
        }

        setQuestionContent(newQuestion as unknown as CustomFormQuestion)
    }, [open])

    const handleCreateQuestion = () => {
        createQuestion(questionContent as CustomFormQuestion)
    }

    const handleQuestionContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuestionContent((prev) => {
            if (prev) {
                return {
                    ...prev,
                    questionContent: e.target.value,
                }
            }
            return prev
        })
    }

    const handleFieldTypeChange = (e: SelectChangeEvent) => {
        setQuestionContent((prev) => {
            if (prev) {
                return {
                    ...prev,
                    fieldType: e.target.value as unknown as QuestionFieldType,
                }
            }
            return prev
        })
    }

    const handleRequiredChange = () => {
        setQuestionContent((prev) => {
            if (prev) {
                return {
                    ...prev,
                    required: !prev.required,
                }
            }
            return prev
        })
    }

    return (
        <Modal open={open}>
            <Modal.Header>
                <Typography component="h2">
                    <SafeFormattedMessage {...messages.addQuestion} />
                </Typography>
            </Modal.Header>
            <Stack margin={1}>
                <FormControl fullWidth>
                    <FormControlLabel
                        labelPlacement="top"
                        sx={{ alignItems: "start" }}
                        label={<SafeFormattedMessage {...messages.questionContent} />}
                        control={
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={questionContent?.questionContent}
                                onChange={handleQuestionContentChange}
                            />
                        }
                    />
                </FormControl>
            </Stack>
            <Stack direction="row" margin={1}>
                <FormControl fullWidth>
                    <FormControlLabel
                        labelPlacement="top"
                        sx={{ alignItems: "start" }}
                        label={<SafeFormattedMessage {...messages.fieldType} />}
                        control={
                            <Select
                                label="questionType"
                                variant="outlined"
                                fullWidth
                                onChange={handleFieldTypeChange}
                                value={questionContent?.fieldType?.toString()}
                            >
                                <MenuItem value="text">Short text</MenuItem>
                                <MenuItem value="textArea">Long text</MenuItem>
                                <MenuItem value="radio">Yes/No</MenuItem>
                                <MenuItem value="singleChoice">Single choice</MenuItem>
                                <MenuItem value="multiChoice">Multi choice</MenuItem>
                            </Select>
                        }
                    />
                </FormControl>
            </Stack>
            {exisitngQuestions && exisitngQuestions.length > 0 && (
                <Stack margin={1}>
                    <FormControlLabel
                        labelPlacement="top"
                        sx={{ alignItems: "start" }}
                        label={<SafeFormattedMessage {...messages.dependentQuestion} />}
                        control={
                            <Select variant="outlined" fullWidth>
                                {exisitngQuestions?.map((question) => (
                                    <MenuItem key={question.id} value={question.id}>
                                        {question.questionContent}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </Stack>
            )}
            <Modal.Footer className="mt-12">
                <FormControl fullWidth sx={{ justifyContent: "center", marginTop: "var(--spacing-md)" }}>
                    <FormControlLabel
                        sx={{ alignItems: "center" }}
                        labelPlacement="end"
                        label={<SafeFormattedMessage {...messages.requiredQuestion} />}
                        control={<Checkbox onChange={handleRequiredChange} checked={questionContent?.required} />}
                    />
                </FormControl>
                <Button onClick={handleCreateQuestion}>
                    <SafeFormattedMessage {...commonMessages.create} />
                </Button>

                <Button onClick={close} type="neutral">
                    <SafeFormattedMessage id="common.close" defaultMessage="Close" />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
