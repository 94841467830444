import { Tooltip } from "@mui/material"
import { X } from "react-feather"
import { useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { Button, SafeFormattedMessage } from "~/components"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { PURCHASE_ORDERS_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { PurchaseOrderStatus, PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { ViewTypeI } from "~/types/ViewType"

interface ClosePOProps {
    PO: PurchaseOrders
    viewType: ViewTypeI
    display?: "button" | "menu-item"
}

export const ClosePO = ({ PO, viewType, display = "button" }: ClosePOProps) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const organization = useAppSelector(selectCurrentOrganization)

    const handleClose = async () => {
        if (!organization?.id) return

        await purchaseOrdersApi.updateStatus(organization.id, PO.id, PurchaseOrderStatus.CLOSED)
        navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType }))
    }

    return (
        <>
            {display === "button" ? (
                <Tooltip title={formatMessage(commonMessages.close)}>
                    <span>
                        <Button type="error-light" onClick={handleClose}>
                            <X color="var(--color-red)" size={18} />
                            <SafeFormattedMessage {...commonMessages.close} />
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button type="menu-item" onClick={handleClose}>
                    <X color="grey" size={16} />
                    <SafeFormattedMessage {...commonMessages.close} />
                </Button>
            )}
        </>
    )
}
