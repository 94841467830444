import { isLeft } from "fp-ts/lib/Either"

import { validateCountryCodeOrSetUnknown } from "../../../../types/CountryCode"
import { CompanyI, CompanyIO } from "../types/CompanyTypes"
import { WhitePagesIdTypes } from "../types/WhitePages"

export const parseCompany = (companyData: unknown): CompanyI => {
    const result = CompanyIO.decode(companyData)
    if (isLeft(result)) {
        console.warn("Unexpected data return from API", result.left)
        throw new Error("Unexpected data return from API")
    }
    return {
        ...result.right,
        idType: result.right.idType as WhitePagesIdTypes,
        countryCode: validateCountryCodeOrSetUnknown(result.right.countryCode),
    }
}
