import { skipToken } from "@reduxjs/toolkit/query"
import React, { useEffect, useRef } from "react"

import { useGetDashboardMetricsUrlQuery } from "~/domains/analytics/metrics/api/metricsApi"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const PADDING = 10

interface MetricsDashboardEmbedProps {
    dashboardId: string
    supplierId?: string
}

export const MetricsDashboardEmbed = ({ dashboardId, supplierId }: MetricsDashboardEmbedProps) => {
    const elementRef = useRef<HTMLIFrameElement>(null)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { data: dashboardMetricsUrl } = useGetDashboardMetricsUrlQuery(
        currentOrganizationId
            ? {
                  dashboardId: dashboardId,
                  organizationId: currentOrganizationId,
                  supplierId: supplierId,
              }
            : skipToken
    )
    const handleResize = () => {
        if (elementRef.current) {
            elementRef.current.style.height = `${window.innerHeight - PADDING}px`
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const handleOnLoad = () => {
        handleResize()
    }
    return (
        <div>
            <iframe
                onLoad={handleOnLoad}
                ref={elementRef}
                src={dashboardMetricsUrl}
                frameBorder="0"
                width="100%"
            ></iframe>
        </div>
    )
}
