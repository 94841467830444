import { Box, Stack } from "@mui/material"
import { MessageDescriptor, useIntl } from "react-intl"

import { Button } from "~/components"
import { SafeFormattedMessage } from "~/components/SafeFormattedMessage/SafeFormattedMessage"
import { TooltipConditional } from "~/components/Tooltip/Tooltip"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"

interface DashboardCTAButtonProps {
    icon: React.ReactNode
    label: MessageDescriptor
    onClick: () => void
    disabled: boolean
}

export const DashboardCTAButton: React.FC<DashboardCTAButtonProps> = ({ icon, label, onClick, disabled }) => {
    const { formatMessage } = useIntl()

    return (
        <TooltipConditional
            condition={disabled}
            title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
            placement="top"
            arrow
        >
            <Box sx={{ flex: 1 }} display="flex" alignItems="center" justifyContent="center">
                <Button type="primary-light" sx={{ flex: 1, minHeight: 120 }} onClick={onClick} disabled={disabled}>
                    <Stack direction={{ xs: "row", sm: "column" }} gap={1} justifyContent="center" alignItems="center">
                        {icon}
                        <SafeFormattedMessage {...label} />
                    </Stack>
                </Button>
            </Box>
        </TooltipConditional>
    )
}
