import { MessageDescriptor } from "react-intl"

import { ProgressBarItem, ProgressBarItemDisplay } from "~/components"
import { AmountType, BudgetSimplifiedMetricsI, SumAmountI } from "~/domains/transactions/budget/types"

export const BudgetMetricsOrder: AmountType[] = [
    AmountType.PAID,
    AmountType.SCHEDULED,
    AmountType.ENGAGED,
    AmountType.PLANNED,
    AmountType.ASKED,
    AmountType.AVAILABLE,
    AmountType.OVERBUDGET,
]

const getMetricAmount = (sumAmount: SumAmountI | number | undefined, amountType: AmountType): number => {
    if (!sumAmount) return 0

    switch (amountType) {
        case AmountType.PAID:
            return (sumAmount as SumAmountI).sumAmountWithoutTax ?? 0
        case AmountType.SCHEDULED:
            return (sumAmount as SumAmountI).sumAmountWithoutTax ?? 0
        case AmountType.ENGAGED:
            return (sumAmount as SumAmountI).sumAmountWithoutTax ?? 0
        case AmountType.PLANNED:
            return (sumAmount as SumAmountI).sumAmountWithoutTax ?? 0
        case AmountType.ASKED:
            return (sumAmount as SumAmountI).sumAmountWithoutTax ?? 0
        case AmountType.AVAILABLE:
            return sumAmount as number
        case AmountType.OVERBUDGET:
            return sumAmount as number
    }
}

const getMetricColor = (amountType: AmountType): string => {
    switch (amountType) {
        case AmountType.PAID:
            return "var(--color-green)"
        case AmountType.SCHEDULED:
            return "var(--color-green-light)"
        case AmountType.ENGAGED:
            return "var(--primary-color)"
        case AmountType.PLANNED:
            return "var(--primary-color-medium-light)"
        case AmountType.ASKED:
            return "var(--primary-color-light)"
        case AmountType.AVAILABLE:
            return "var(--color-grey-lighter)"
        case AmountType.OVERBUDGET:
            return "var(--color-red)"
    }
}

export const budgetMetricsToProgressBarItems = (
    metrics: BudgetSimplifiedMetricsI | undefined,
    currentAmount: number
): ProgressBarItem[] => {
    if (!metrics) return []

    // Determine if we are overbudget
    const isOverbudget = metrics.available < 0

    // Calculate total of all metrics
    const totalMetricsAmount = currentAmount - metrics.available

    // Calculate the scaling factor if there's an overbudget
    const scalingFactor = isOverbudget ? 1 - Math.abs(metrics.available) / totalMetricsAmount : 1

    return BudgetMetricsOrder.filter((amountType) => {
        // Exclude OVERBUDGET from the array if `isOverbudget` is true, as it's handled by replacing AVAILABLE
        return !(isOverbudget && amountType === AmountType.OVERBUDGET)
    }).map((amountType) => {
        const baseValue = getMetricAmount(metrics[amountType.toLowerCase()], amountType)
        const scaledPercentage = isOverbudget
            ? (baseValue / totalMetricsAmount) * scalingFactor
            : baseValue / currentAmount

        const defaultItem = {
            label: amountType,
            value: baseValue,
            percentage: scaledPercentage,
            color: getMetricColor(amountType),
        }

        // Handle the "available" or "overbudget" case
        if (amountType === AmountType.AVAILABLE) {
            return isOverbudget
                ? {
                      label: AmountType.OVERBUDGET,
                      value: Math.abs(metrics.available),
                      percentage: Math.abs(metrics.available) / totalMetricsAmount,
                      color: getMetricColor(AmountType.OVERBUDGET),
                  }
                : defaultItem
        }

        // Default case for other items
        return defaultItem
    })
}

export const amountLabels: Record<AmountType, MessageDescriptor> = {
    [AmountType.PAID]: { id: "budgets.table.spent", defaultMessage: "Spent" },
    [AmountType.SCHEDULED]: { id: "budgets.table.scheduled", defaultMessage: "Scheduled" },
    [AmountType.ENGAGED]: { id: "budgets.table.engaged", defaultMessage: "Engaged" },
    [AmountType.PLANNED]: { id: "budgets.table.planned", defaultMessage: "Planned" },
    [AmountType.ASKED]: { id: "budgets.table.asked", defaultMessage: "Asked" },
    [AmountType.AVAILABLE]: { id: "budgets.table.available", defaultMessage: "Available" },
    [AmountType.OVERBUDGET]: { id: "budgets.table.overbudget", defaultMessage: "Over budget" },
}

export const updateCumulativePercentage = (data: ProgressBarItemDisplay[]): ProgressBarItemDisplay[] => {
    let cumulativePercentage = 0

    return data.map((item) => {
        const updatedItem = {
            ...item,
            percentage: item.percentage + cumulativePercentage,
        }
        cumulativePercentage += item.percentage
        return updatedItem
    })
}

export const getTotalForGaugeTitle = (metrics: BudgetSimplifiedMetricsI | undefined) => {
    const { paid, scheduled, engaged, planned, asked } = metrics ?? {}
    return (
        (paid?.sumAmountWithoutTax ?? 0) +
        (scheduled?.sumAmountWithoutTax ?? 0) +
        (engaged?.sumAmountWithoutTax ?? 0) +
        (planned?.sumAmountWithoutTax ?? 0) +
        (asked?.sumAmountWithoutTax ?? 0)
    )
}
