import { useEffect, useMemo, useState } from "react"

import { useGetAllSharedObjectsQuery } from "~/domains/identity/roles-permissions/api/sharesApi"
import { useFetchUsers } from "~/store/organization/hooks/useFetchUsers"
import { OrganizationId } from "~/types"
import { SharedObjectWithName } from "~/types/SharedObjects"

export const useFetchSharedObjects = (organizationId?: OrganizationId): SharedObjectWithName[] => {
    const [sharings, setSharings] = useState<SharedObjectWithName[]>([])

    const { data: sharedObjects = [] } = useGetAllSharedObjectsQuery(organizationId ?? "")
    const userIds = useMemo(() => sharedObjects.map((sharedObject) => sharedObject.userId), [sharedObjects])
    const { users } = useFetchUsers(userIds)

    useEffect(() => {
        if (sharedObjects.length && users.length) {
            const sharedObjectsWithNames = sharedObjects.map((sharedObject) => {
                const user = users.find((user) => user.id === sharedObject.userId)
                return {
                    ...sharedObject,
                    userFullName: user?.fullName ?? "",
                    userEmail: user?.email ?? "",
                }
            })
            setSharings(sharedObjectsWithNames)
        }
    }, [sharedObjects, users])

    return sharings
}
