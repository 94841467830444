import { useEffect } from "react"
import { defineMessages, useIntl } from "react-intl"

import { tagsActions } from "~/domains/analytics/tags/store/tagsSlice"
import { PurchaseOrderView } from "~/domains/transactions/purchase-orders/pages/[ID]"
import { useCreateDraftPurchaseOrder } from "~/domains/transactions/purchase-orders/store/hooks"
import { purchaseOrdersActions } from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { useTitle } from "~/hooks"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    htmlTitle: { id: "purchase.orders.order.htmlTitle", defaultMessage: "Purchase order" },
    titleEdit: { id: "purchase.orders.order.titleEdit", defaultMessage: "Edit PO" },
    titleView: { id: "purchase.orders.order.titleView", defaultMessage: "PO" },
    titleNew: { id: "purchase.orders.order.titleNew", defaultMessage: "New PO" },
    items: { id: "purchase.orders.order.items", defaultMessage: "Items" },
    esgCustomFields: { id: "purchase.orders.order.esgCustomFields", defaultMessage: "ESG" },
    taxLabel: { id: "purchase.orders.order.taxLabel", defaultMessage: "Tax excl." },
    purchaseRequest: { id: "purchase.orders.order.purchaseRequest", defaultMessage: "Purchase request" },
    cancel: { id: "purchase.orders.order.cancel", defaultMessage: "Cancel this PO" },
    addToBudget: { id: "purchase.orders.order.addToBudget", defaultMessage: "Add to budget" },
    budgets: { id: "purchase.orders.order.budgets", defaultMessage: "Budgets" },
    errorChangedOrganization: {
        id: "purchase.requests.request.errorChangedOrganization",
        defaultMessage: "You have changed the organization. This organization does not have this purchase request.",
    },
    tagsTitle: { id: "purchase.orders.order.tagsTitle", defaultMessage: "Tags" },
    currency: {
        id: "purchase.requests.modalNewPR.currency",
        defaultMessage: "Currency",
    },
})

export const PurchaseOrderNew = () => {
    const { formatMessage } = useIntl()
    const pageName = formatMessage(messages.htmlTitle)
    useTitle(pageName)

    const dispatch = useAppDispatch()
    const organization = useAppSelector(selectCurrentOrganization)

    const sharedEmail = false

    useEffect(() => {
        dispatch(purchaseOrdersActions.resetData())
        dispatch(tagsActions.resetPendingTags())

        return () => {
            dispatch(purchaseOrdersActions.resetData())
            dispatch(tagsActions.resetPendingTags())
        }
    }, [dispatch])

    const purchaseOrder = useCreateDraftPurchaseOrder(organization)

    if (!organization || !purchaseOrder) {
        return null
    }
    return (
        <PurchaseOrderView
            edit={true}
            newPO={true}
            isFromSharedEmail={sharedEmail}
            organization={organization}
            purchaseOrder={purchaseOrder}
        />
    )
}
