import {
    CREATION_ID,
    CreatePartnerProfileAddressI,
    CreatePartnerProfileContactI,
    CreatePartnerProfileI,
    CreatePartnerProfilePaymentDetailI,
    OCR_ID,
    PartnerProfileAddressI,
    PartnerProfileContactI,
    PartnerProfilePaymentDetailI,
} from "~/domains/identity/partners/types"

export const createNewAddress = (address?: Partial<CreatePartnerProfileAddressI>): CreatePartnerProfileAddressI => ({
    label: address?.label ?? "",
    contactLine: address?.contactLine ?? "",
    addressLine1: address?.addressLine1 ?? "",
    addressLine2: address?.addressLine2 ?? "",
    city: address?.city ?? "",
    zipCode: address?.zipCode ?? "",
    region: address?.region ?? "",
    country: address?.country ?? "",
    extra: address?.extra ?? "",
})

export const createNewContact = (contact?: Partial<CreatePartnerProfileContactI>): CreatePartnerProfileContactI => ({
    lastName: contact?.lastName ?? "",
    firstName: contact?.firstName ?? "",
    email: contact?.email ?? "",
    phoneNumber: contact?.phoneNumber ?? "",
    position: contact?.position ?? "",
})

export const createNewPaymentDetail = (
    paymentDetail?: Partial<CreatePartnerProfilePaymentDetailI>
): CreatePartnerProfilePaymentDetailI => ({
    label: paymentDetail?.label ?? "",
    iban: paymentDetail?.iban ?? "",
    bic: paymentDetail?.bic ?? "",
    bankName: paymentDetail?.bankName ?? "",
    verifiedStatus: paymentDetail?.verifiedStatus ?? undefined,
})

export const isPartnerProfileAddress = (
    address: PartnerProfileAddressI | CreatePartnerProfileAddressI
): address is PartnerProfileAddressI => "id" in address

export const isPartnerProfileContact = (
    contact: PartnerProfileContactI | CreatePartnerProfileContactI
): contact is PartnerProfileContactI => "id" in contact

export const isPartnerProfilePaymentDetail = (
    paymentDetails: PartnerProfilePaymentDetailI | CreatePartnerProfilePaymentDetailI
): paymentDetails is PartnerProfilePaymentDetailI => "id" in paymentDetails

export const computePartnerProfile = (
    partnerProfile: CreatePartnerProfileI | null,
    payload: Partial<CreatePartnerProfileI>,
    initiatorId?: string,
    partnerId?: string | null
): CreatePartnerProfileI => {
    const newPartnerProfile = {
        initiatorId: partnerProfile?.initiatorId ?? initiatorId ?? "",
        partnerId: partnerProfile?.partnerId ?? partnerId ?? "",
        brandName: partnerProfile?.brandName ?? "",
        description: partnerProfile?.description ?? "",
        paymentTerms: partnerProfile?.paymentTerms ?? "",
        responsibleUserId: partnerProfile?.responsibleUserId ?? "",
        contacts: partnerProfile?.contacts ?? [],
        addresses: partnerProfile?.addresses ?? [],
        paymentDetails: partnerProfile?.paymentDetails ?? [],
    }

    return { ...newPartnerProfile, ...payload }
}

const hasAtLeastOneFilledField = (obj: Record<string, any>): boolean =>
    obj && Object.values(obj).some((value) => value !== null && value !== "")

export const createPartnerProfileContactPayload = (
    contact: CreatePartnerProfileContactI | PartnerProfileContactI
): CreatePartnerProfileContactI | PartnerProfileContactI | null => {
    if (!hasAtLeastOneFilledField(contact)) {
        return null
    }
    if (!isPartnerProfileContact(contact)) {
        return contact
    }
    const { id, ...payload } = contact
    return id === CREATION_ID || id === OCR_ID ? payload : contact
}
