import { Stack } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { InvoicesInfiniteListTable } from "~/domains/transactions/invoices-v1/components/list/InvoicesInfiniteListTable"
import { InvoicesListHeader } from "~/domains/transactions/invoices-v1/components/list/InvoicesListHeader"
import { useTitle } from "~/hooks"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    title: { id: "invoices.title", defaultMessage: "Invoices" },
})

export const InvoicesPage = () => {
    const { formatMessage } = useIntl()

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)

    const pageName = formatMessage(messages.title)
    useTitle(pageName)

    return (
        <Stack className="main-box no-header" gap={2}>
            <InvoicesListHeader />

            <InvoicesInfiniteListTable organizationId={currentOrganizationId ?? ""} />
        </Stack>
    )
}
