import { Alert, LinearProgress, Stack, TextField } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { useWhitePagesApi } from "~/domains/identity/organization/api/whitePagesApi"
import { CompanyAutocomplete, CompanyResult } from "~/domains/identity/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/domains/identity/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { ManualCompanyInputTrigger } from "~/domains/identity/organization/components/CreateOrganizationContainer/ManualInputContainer"
import { useEstablishmentState } from "~/domains/identity/organization/hooks"
import { createEmptyPartner } from "~/domains/identity/partners/components/ModalCreatePartnership/helpers"
import { usePartnersAsOrganizations } from "~/domains/identity/partners/store/hooks"
import { PartnershipType } from "~/domains/identity/partners/types"
import { EstablishmentSelect } from "~/domains/transactions/invoices/_views/supplier/components/EstablishmentSelect"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { CompanyI, CountryCode, EstablishmentI, ImportInvoiceCompanyInfoI, OrganizationI } from "~/types"
import { isResultSuccess } from "~/types/Result"

const messages = defineMessages({
    // TODO: improve the text displayed in the label: "siret" if FR code, else should be "establishments"
    siretLabel: {
        id: "partners.bookofrelations.modal.create.siretLabel",
        defaultMessage: "Siret",
    },
    alreadyPartnership: {
        id: "partners.bookofrelations.modal.create.alreadyPartnership",
        defaultMessage: "This organization is already in your partners list",
    },
})

const NO_SUGGESTED_COMPANIES: CompanyI[] = []

interface PartnerOrganizationSelectorProps {
    companyResult: CompanyResult | undefined
    setCompanyResult: (companyResult: CompanyResult | undefined) => void
    partnerOrganization: ImportInvoiceCompanyInfoI
    onManualImportClick: () => void
    updateData: (data: any) => void
}

export const PartnerOrganizationSelector: React.FC<PartnerOrganizationSelectorProps> = ({
    companyResult,
    setCompanyResult,
    partnerOrganization,
    onManualImportClick,
    updateData,
}) => {
    const { formatMessage } = useIntl()

    const [establishementLoading, setEstablishementLoading] = useState(false)
    const [companyLoading, setCompanyLoading] = useState(false)
    const [countryCode, setCountryCode] = useState(CountryCode.FR)

    const [selectedEstablishment, setSelectedEstablishment] = useEstablishmentState(
        companyResult?.type === CompanyAutocompleteType.WhitePagesResult ? companyResult.value : undefined
    )

    const wp = useWhitePagesApi()

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { organizations: partnersOrganizations } = usePartnersAsOrganizations(
        PartnershipType.SUPPLIER,
        currentOrganizationId || ""
    )

    useEffect(() => {
        const countryCode = partnerOrganization?.countryCode
        if (countryCode) {
            setCountryCode(countryCode)
        }
    }, [partnerOrganization])

    const onCountryCodeChange = (countryCode: CountryCode) => setCountryCode(countryCode)

    const onEstablishmentSelectionChange = useCallback(
        async (establishment: EstablishmentI | undefined) => {
            setSelectedEstablishment(establishment)
            if (!establishment) {
                return updateData({
                    registrationNumber: undefined,
                })
            }
            setEstablishementLoading(true)
            try {
                const results = await wp.searchDnBCompanyFromRegistrationNumber(establishment.id, countryCode)
                if (isResultSuccess(results) && results.result.length > 0) {
                    updateData({
                        registrationNumber: results.result[0].id,
                    })
                }
            } finally {
                setEstablishementLoading(false)
            }
        },
        [wp, countryCode]
    )

    const onCompanyChange = useCallback(
        (company: CompanyResult | undefined, establishmentsToSelect?: EstablishmentI) => {
            setCompanyResult(company)
            onEstablishmentSelectionChange(establishmentsToSelect)
            if (!company) return updateData(createEmptyPartner())
            if (company.type === CompanyAutocompleteType.Organization) {
                updateData({
                    organizationId: company.value.id,
                    countryCode: company.value.registration.countryCode,
                    name: company.value.name,
                    registrationNumber:
                        company.value.registration.dunsNumber ??
                        company.value.registration.preferredRegistrationNumber?.registrationNumber,
                })
            } else if (company.type === CompanyAutocompleteType.WhitePagesResult) {
                const registrationNumber = establishmentsToSelect
                    ? establishmentsToSelect.id
                    : company.value.establishments && company.value.establishments.length > 0
                      ? undefined
                      : company.value.id
                updateData({
                    organizationId: undefined,
                    countryCode: company.value.countryCode,
                    name: company.value.name,
                    registrationNumber,
                    establishments: company.value.establishments,
                })
                if (!establishmentsToSelect && company.value.establishments?.length === 1) {
                    onEstablishmentSelectionChange(company.value.establishments[0])
                }
            }
        },
        []
    )

    const isSelectedOrganizationAlreadyPartnership = partnersOrganizations.some(
        (organization) =>
            partnerOrganization.registrationNumber ===
            organization.registration.preferredRegistrationNumber?.registrationNumber
    )

    return (
        <Stack gap={0}>
            <Stack gap={2}>
                <Stack>
                    <CompanyAutocomplete
                        company={companyResult}
                        setCompany={onCompanyChange}
                        countryCode={countryCode}
                        setCountryCode={onCountryCodeChange}
                        setCompanyLoading={setCompanyLoading}
                        organizations={partnersOrganizations}
                        suggestedCompanies={[]}
                    />
                    {companyLoading && <LinearProgress color="primary" />}
                </Stack>
                {partnerOrganization.establishments && partnerOrganization.establishments.length > 1 ? (
                    <Stack gap={2}>
                        <EstablishmentSelect
                            options={partnerOrganization.establishments}
                            value={selectedEstablishment}
                            onChange={onEstablishmentSelectionChange}
                        />
                        {establishementLoading && <LinearProgress color="primary" />}
                    </Stack>
                ) : (
                    selectedEstablishment && (
                        <TextField
                            value={selectedEstablishment?.id}
                            label={formatMessage(messages.siretLabel)}
                            disabled
                            aria-readonly
                            inputProps={{ readOnly: true }}
                        />
                    )
                )}
                {isSelectedOrganizationAlreadyPartnership && (
                    <Alert severity="info">
                        <SafeFormattedMessage {...messages.alreadyPartnership} />
                    </Alert>
                )}
            </Stack>
            <ManualCompanyInputTrigger onClick={onManualImportClick} />
        </Stack>
    )
}
