import { Timeline } from "@mui/lab"
import { Box } from "@mui/material"

import { EventI } from "~/types"

import { EventLog } from "./EventLog"

interface EventLogsProps {
    events: EventI[]
}

export const EventLogs = ({ events }: EventLogsProps) => {
    return (
        <Box maxHeight={600} overflow="auto" minWidth={200}>
            <Timeline>
                {events.map((event: EventI, key: number) => (
                    <EventLog eventLog={event} key={key} />
                ))}
            </Timeline>
        </Box>
    )
}
