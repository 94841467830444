import { Grid, Stack } from "@mui/material"
import { CreditCard } from "react-feather"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { HeaderH1 } from "~/components"
import { BatchesActions, PaymentBatchesList, PaymentsHeader } from "~/domains/payment/payment-batches/components"
import { PaymentsTabsI } from "~/domains/payment/payment-batches/types"

export const PaymentBatchesConfigurations = () => {
    const { formatMessage } = useIntl()
    return (
        <Stack gap={2}>
            <HeaderH1 title={formatMessage(commonMessages.payments)} icon={<CreditCard />} />
            <Grid className="main-box">
                <PaymentsHeader currentPage={PaymentsTabsI.PAYMENT_BATCHES} />
                <Stack gap={2}>
                    <BatchesActions />
                    <PaymentBatchesList />
                </Stack>
            </Grid>
        </Stack>
    )
}
