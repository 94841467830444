/* eslint-disable complexity */
import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { userApi } from "~/domains/identity/account/api/userApi"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { budgetApi } from "~/domains/transactions/budget/api/budgetApi"
import { TransactionType } from "~/domains/transactions/budget/types"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { organizationAddressToAddress } from "~/domains/transactions/purchase-orders/core/organizationAddressToAddress"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { OrganizationId } from "~/types"

import { purchaseOrdersActions } from "../purchaseOrdersSlice"

const messages = defineMessages({
    error: {
        id: "purchase.order.list.errorFetch",
        defaultMessage: "We can't retrieve the purchase order. Please contact your administrator",
    },
})

export const useFetchPurchaseOrder = (organizationId?: OrganizationId) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const currentOrganization = useAppSelector(selectCurrentOrganization)

    const fetchPurchaseOrder = useCallback(
        async (orderId?: string, fetchBudget?: boolean, getCompanies?: boolean) => {
            if (!orderId || !organizationId || (currentOrganization && currentOrganization.id !== organizationId)) {
                return null
            }
            try {
                dispatch(purchaseOrdersActions.fetchPO(orderId))
                const purchaseOrder = await purchaseOrdersApi.findById(organizationId, orderId)

                const { supplierId, requesterUserId, buyerId, shippingAddress, billingAddress } = purchaseOrder
                const supplier =
                    !currentOrganization || currentOrganization.id === buyerId
                        ? await organizationApi.getOrganizationById(supplierId)
                        : currentOrganization
                const buyer =
                    !currentOrganization || currentOrganization.id === supplierId
                        ? await organizationApi.getOrganizationById(buyerId)
                        : currentOrganization

                if (requesterUserId && requesterUserId !== "") {
                    const user = await userApi.findUsersByIds([requesterUserId])
                    purchaseOrder.requesterName = user[0]?.fullName ?? undefined
                }
                purchaseOrder.supplierName = supplier?.name ?? ""
                purchaseOrder.buyerName = buyer?.name ?? ""
                if (getCompanies) {
                    purchaseOrder.supplier = supplier
                    purchaseOrder.buyer = buyer
                }

                if (!shippingAddress || !billingAddress) {
                    dispatch(purchaseOrdersActions.needSaveShippingAndBillingAddress(true))
                    const buyerAddress = buyer?.address ? organizationAddressToAddress(buyer.address) : null
                    purchaseOrder.shippingAddress = shippingAddress ?? buyerAddress
                    purchaseOrder.billingAddress = billingAddress ?? buyerAddress
                }

                if (fetchBudget) {
                    const result = await budgetApi.getBudgetByTransaction(
                        organizationId,
                        orderId,
                        TransactionType.PURCHASE_ORDER
                    )
                    if (result.length) {
                        purchaseOrder.budgets = result
                    }
                }
                dispatch(purchaseOrdersActions.fetchPOSuccess(purchaseOrder))
                return purchaseOrder
            } catch (error) {
                console.error(error)
                dispatch(purchaseOrdersActions.reset())
                dispatch(purchaseOrdersActions.fetchPOFailed(formatMessage(messages.error)))
            }
        },
        [organizationId]
    )

    return { fetchPurchaseOrder }
}
