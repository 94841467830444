import { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/components"

import { BUDGETS_LIST, BUDGET_ROUTE } from "./routes"

const BudgetsPageLazy = lazy(() => import("./pages").then(({ BudgetsPage }) => ({ default: BudgetsPage })))
const BudgetDetailsPageLazy = lazy(() =>
    import("./pages/[ID]").then(({ BudgetDetailsPage }) => ({ default: BudgetDetailsPage }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="budgets_route">
            <Route path={BUDGETS_LIST} element={<BudgetsPageLazy />} />
            <Route path={BUDGET_ROUTE} element={<BudgetDetailsPageLazy />} />
        </Route>,
    ],
}
