import * as t from "io-ts"

import { optional } from "~/utils/io-ts"

export const PappersSiegeIO = t.intersection([
    t.type({
        siret: t.string,
        siret_formate: t.string,
        nic: t.union([t.number, t.string]),
    }),
    t.partial({
        numero_voie: optional(t.number),
        indice_repetition: optional(t.string),
        type_voie: optional(t.string),
        libelle_voie: optional(t.string),
        complement_adresse: optional(t.string),
        adresse_ligne_1: optional(t.string),
        adresse_ligne_2: optional(t.string),
        code_postal: optional(t.string),
        ville: optional(t.string),
        pays: optional(t.string),
        latitude: optional(t.number),
        longitude: optional(t.number),
    }),
])

export const PappersCollectiveConventionIO = t.type({
    nom: t.string,
    idcc: t.string,
    confirmee: t.boolean,
})

export const PappersResultIO = t.intersection([
    t.type({
        mention: t.string,
        siren: t.string,
        siren_formate: t.string,
        nom_entreprise: optional(t.string),
        personne_morale: t.boolean,
        code_naf: t.string,
        libelle_code_naf: t.string,
        domaine_activite: t.string,
        conventions_collectives: t.array(PappersCollectiveConventionIO),
        entreprise_cessee: t.union([t.boolean, t.number]),
        entreprise_employeuse: t.union([t.boolean, t.number]),
        categorie_juridique: t.string,
        forme_juridique: t.string,
        effectif: t.string,
        effectif_min: t.number,
        effectif_max: t.number,
        siege: PappersSiegeIO,
        villes: t.array(t.string),
    }),
    t.partial({
        denomination: optional(t.string),
        nom: optional(t.string),
        prenom: optional(t.string),
        sexe: optional(t.string),
        date_creation: optional(t.string), // format = YYYY-MM-DD
        date_creation_formate: optional(t.string),
        date_cessation: optional(t.string), // format = YYYY-MM-DD
        societe_a_mission: optional(t.boolean),
        tranche_effectif: optional(t.string),
        annee_effectif: optional(t.number),
        capital: optional(t.number),
        statut_rcs: optional(t.string),
        chiffre_affaires: optional(t.number),
        resultat: optional(t.number),
        effectifs_finances: optional(t.number),
        annee_finances: optional(t.union([t.string, t.number])),
        diffusable: optional(t.boolean),
    }),
])

export const PappersResultsIO = t.partial({
    resultats_nom_entreprise: optional(t.array(PappersResultIO)),
    resultats_denomination: optional(t.array(PappersResultIO)),
    resultats_nom_complet: optional(t.array(PappersResultIO)),
    resultats_representant: optional(t.array(PappersResultIO)),
    resultats_siren: optional(t.array(PappersResultIO)),
    resultats_siret: optional(t.array(PappersResultIO)),
})

export type PappersResultI = t.TypeOf<typeof PappersResultIO>
export type PappersResultsI = t.TypeOf<typeof PappersResultsIO>
