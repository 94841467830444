import { Chip, styled } from "@mui/material"
import * as Sentry from "@sentry/browser"
import React, { FC, useEffect, useMemo } from "react"
import { FormattedDate, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { DataTable } from "~/components/DataTable"
import { useGetRunsQuery } from "~/domains/orchestration/flows/api/runsApi"
import { StatusIcon } from "~/domains/orchestration/flows/components/StatusIcon"
import { RUN_STATUS_COLORS } from "~/domains/orchestration/flows/constants"
import { useOrganizationId } from "~/domains/orchestration/flows/hooks/"
import { messages } from "~/domains/orchestration/flows/locale"
import { FlowId, RunItem } from "~/domains/orchestration/flows/types"
import { OrderDirection } from "~/types"

interface Props {
    handleClickRow: (run: RunItem) => void
    flowId?: FlowId
}

const StatusChip = styled(Chip)<{ status: keyof typeof RUN_STATUS_COLORS }>`
    color: ${(props) => RUN_STATUS_COLORS[props.status]};
    border-color: ${(props) => RUN_STATUS_COLORS[props.status]};
    padding: var(--spacing-xs) var(--spacing-sm);
`

const RunStatusBadge: FC<{ run: RunItem }> = ({ run }) => {
    const { status } = run
    const { formatMessage } = useIntl()

    const statusLabel = formatMessage(messages.runStatus[status])

    return (
        <StatusChip
            status={status}
            icon={<StatusIcon status={status} />}
            label={statusLabel}
            size="small"
            variant="outlined"
        />
    )
}

export const RunExplorerList: FC<Props> = ({ handleClickRow, flowId }) => {
    const { formatMessage } = useIntl()

    const organizationId = useOrganizationId()

    const { data, error, isLoading, isError, refetch } = useGetRunsQuery(
        { flowId, limit: 100, offset: 0 },
        {
            refetchOnMountOrArgChange: true,
        }
    )

    const columns = useMemo(
        () => [
            {
                id: "status",
                getValue: (run: RunItem) => run.status,
                renderCell: (run: RunItem) => <RunStatusBadge run={run} />,
                label: formatMessage(messages.runsTable.status),
                sorter: true,
            },
            {
                id: "startedAt",
                getValue: (run: RunItem) => run.startedAt,
                label: formatMessage(messages.runsTable.startedAt),
                sorter: true,
                renderCell: (run: RunItem) => (
                    <FormattedDate value={run.startedAt} dateStyle="short" timeStyle="short" />
                ),
            },
            {
                id: "finishedAt",
                getValue: (run: RunItem) => run.finishedAt,
                label: formatMessage(messages.runsTable.finishedAt),
                sorter: true,
                renderCell: (run: RunItem) =>
                    run.finishedAt ? <FormattedDate value={run.finishedAt} dateStyle="short" timeStyle="short" /> : "-",
            },
        ],
        [formatMessage]
    )

    useEffect(() => {
        if (organizationId) {
            refetch()
        }
    }, [organizationId, refetch])

    if (isLoading) {
        return <Loader fullscreen />
    }
    if (isError) {
        toast.error(formatMessage(messages.error.loadingRuns))
        Sentry.captureException(error)
        return null
    }
    if (!data) {
        return null
    }

    return (
        <DataTable
            data={data.runs}
            defaultOrderBy="id"
            defaultOrderDirection={OrderDirection.ASC}
            columns={columns}
            handleClickRow={handleClickRow}
        />
    )
}
