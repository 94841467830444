import { useCallback } from "react"

import { selectPendingTags } from "~/domains/analytics/tags/store/tagsSlice"
import { useTagsApi } from "~/domains/analytics/tags/tagsApi"
import { CreateTagObjectI, TagObjectType } from "~/domains/analytics/tags/types"
import { selectUser } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

export const useSavePurchaseRequestPendingTags = (organizationId: OrganizationId) => {
    const tagsApi = useTagsApi()
    const pendingTags = useAppSelector(selectPendingTags)
    const user = useAppSelector(selectUser)

    return useCallback(
        (purchaseRequestId: string) => {
            return tagsApi.createObjectTags(
                organizationId,
                pendingTags.map(
                    (tag): CreateTagObjectI => ({
                        tagId: tag.tagId,
                        objectId: purchaseRequestId,
                        ratio: tag.ratio,
                        objectType: TagObjectType.PURCHASE_REQUEST,
                        objectContext: {
                            organizationId,
                            user,
                        },
                    })
                )
            )
        },
        [pendingTags, tagsApi, organizationId, user]
    )
}
