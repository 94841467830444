import { useEffect, useState } from "react"

import { useOrganizationTagGroups } from "~/domains/analytics/tags/hooks/useOrganizationTagGroups"
import { selectPendingTags } from "~/domains/analytics/tags/store/tagsSlice"
import { SelectedTagI, TagGroupI, TagObjectType } from "~/domains/analytics/tags/types"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { getMandatoryTagGroupIdsByTagObjectType } from "~/utils/mandatoryTagGroups"

export type UseHasMandatoryTagGroupIdsReturn = {
    hasMandatoryTagGroupIds: boolean
    missingTagGroups: TagGroupI[]
}

export const useHasMandatoryTagGroupIds = (
    objectType: TagObjectType,
    organizationId?: OrganizationId,
    selectedTags?: SelectedTagI[]
): UseHasMandatoryTagGroupIdsReturn => {
    const pendingTags = useAppSelector(selectPendingTags)
    const { tagGroups } = useOrganizationTagGroups(organizationId)
    const [missingTagGroups, setMissingTagGroups] = useState<TagGroupI[]>([])

    const tags = selectedTags || pendingTags

    // Helper function to find mandatory tag groups
    const findMandatoryTagGroups = (orgId: OrganizationId, type: TagObjectType, groups: TagGroupI[]): TagGroupI[] => {
        const mandatoryTagGroupIds = getMandatoryTagGroupIdsByTagObjectType(orgId, type)
        return groups.filter((tagGroup) => mandatoryTagGroupIds.includes(tagGroup.id as string))
    }

    useEffect(() => {
        if (!organizationId || !tagGroups || tagGroups.length === 0) {
            setMissingTagGroups([])
            return
        }

        // Find all mandatory tag groups for the given organization and object type
        const mandatoryTagGroups = findMandatoryTagGroups(organizationId, objectType, tagGroups)

        if (mandatoryTagGroups.length === 0) {
            setMissingTagGroups([])
            return
        }

        // If there are no pending tags, all mandatory tag groups are considered missing
        if (tags.length === 0) {
            setMissingTagGroups(mandatoryTagGroups)
            return
        }

        // Filter out mandatory tag groups that are not associated with any pending tags
        const remainingMandatoryTagGroups = mandatoryTagGroups.filter(
            (tagGroup) => !tags.some((tag) => tag.tagGroupId === tagGroup.id)
        )

        // Update the state with the remaining missing mandatory tag groups
        setMissingTagGroups(remainingMandatoryTagGroups)
    }, [tagGroups, organizationId, objectType, tags])

    return {
        hasMandatoryTagGroupIds: missingTagGroups.length === 0,
        missingTagGroups,
    }
}
