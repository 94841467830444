import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import { FormEvent, useState } from "react"
import { defineMessages, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { commonMessages } from "~/common-messages"
import { Button, Loader, SafeFormattedMessage } from "~/components"
import { useGetPaymentBatchesByOrganizationQuery } from "~/domains/payment/payment-batches/api/paymentBatchesApi"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    paymentBatch: {
        id: "payment.batches.paymentBatch",
        defaultMessage: "Payment batch",
    },
    addToBatch: {
        id: "payment.batches.addToBatch",
        defaultMessage: "Add to batch",
    },
    paymentBatchRequired: {
        id: "payment.batches.paymentBatchRequired",
        defaultMessage: "Please select a payment batch",
    },
    dateScheduledRequired: {
        id: "payment.batches.dateScheduledRequired",
        defaultMessage: "Please add a schedule date",
    },
})

interface ObjectAssignFormProps {
    loading: boolean
    onSubmit: (batchId: string, dateScheduled: string) => void
}

export const ObjectAssignForm = ({ loading = false, onSubmit }: ObjectAssignFormProps) => {
    const { formatMessage, locale } = useIntl()
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [selectedBatchId, setSelectedBatchId] = useState<string>("")
    const [dateScheduled, setDateScheduled] = useState<Dayjs | undefined>()

    const { data: batchesData, isLoading: isLoadingBatches } = useGetPaymentBatchesByOrganizationQuery(
        { organizationId },
        {
            skip: !organizationId,
        }
    )
    const batches = batchesData?.items ?? []

    const handleBatchChange = (event: SelectChangeEvent) => {
        setSelectedBatchId(event.target.value)
    }

    const handleChangeDateScheduled = (date: Dayjs | null) => {
        setDateScheduled(date || undefined)
    }

    const handleAddToBatch = (event: FormEvent) => {
        event.preventDefault()

        if (!selectedBatchId) {
            toast.warning(formatMessage(messages.paymentBatchRequired))
            return
        }

        if (!dateScheduled) {
            toast.warning(formatMessage(messages.dateScheduledRequired))
            return
        }

        onSubmit(selectedBatchId, dateScheduled?.format("YYYY-MM-DD") ?? "")
    }

    if (isLoadingBatches) {
        return <Loader small />
    }

    return (
        <Box component="form" noValidate onSubmit={handleAddToBatch}>
            <Stack gap={2}>
                <FormControl>
                    <InputLabel>
                        <SafeFormattedMessage {...messages.paymentBatch} />
                    </InputLabel>
                    <Select required disabled={loading} value={selectedBatchId} onChange={handleBatchChange}>
                        {batches.map((batch) => (
                            <MenuItem key={batch.id} value={batch.id}>
                                {batch.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    <DatePicker
                        views={["year", "month", "day"]}
                        label={formatMessage(commonMessages.dateScheduled)}
                        minDate={dayjs()}
                        format="YYYY-MM-DD"
                        disabled={loading}
                        value={dateScheduled}
                        slots={{ textField: TextField }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                required: true,
                            },
                        }}
                        onChange={handleChangeDateScheduled}
                    />
                </LocalizationProvider>

                <Button disabled={loading} buttonType="submit" type="primary-light" block>
                    <SafeFormattedMessage {...messages.addToBatch} />
                </Button>
            </Stack>
        </Box>
    )
}
