import { FC } from "react"

import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { InvoiceTypeSelector as InvoiceTypeSelector_Deprecated } from "~/domains/transactions/invoices/components/InvoiceTypeSelector"
import { useAppSelector } from "~/store/hooks"
import { selectOrganizationById } from "~/store/organization/organizationSlice"
import { ImportingInvoiceI, InvoiceI } from "~/types"

interface InvoiceTypeSelectorProps {
    invoice: InvoiceI
}

export const InvoiceTypeSelector: FC<InvoiceTypeSelectorProps> = ({ invoice }) => {
    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()
    const payerOrganization = useAppSelector((state) => selectOrganizationById(state, invoice.payerId))

    const handleUpdateInvoice = (data: Partial<InvoiceI | ImportingInvoiceI>) => {
        patchUpdateInvoice({ ...data, id: invoice.id } as Partial<InvoiceI> & Pick<InvoiceI, "id">)
    }

    return (
        <InvoiceTypeSelector_Deprecated
            invoiceId={invoice.id}
            invoice={invoice}
            organizationId={payerOrganization?.id}
            updateData={handleUpdateInvoice}
        />
    )
}
