export type RefusalReasons = {
    customReasons: string[]
    defaultReasons: string[]
}

export enum RefusalReason {
    INCORRECT_INFO = "INCORRECT_INFO",
    BUDGET_EXCEEDED = "BUDGET_EXCEEDED",
    UNAUTHORIZED_REQUEST = "UNAUTHORIZED_REQUEST",
    DUPLICATE_SUBMISSION = "DUPLICATE_SUBMISSION",
    COMPLIANCE_VIOLATION = "COMPLIANCE_VIOLATION",
    EXPIRED_REQUEST = "EXPIRED_REQUEST",
    INVALID_PAYMENT_TERMS = "INVALID_PAYMENT_TERMS",
    INCOMPLETE_PROFILE = "INCOMPLETE_PROFILE",
    FAILED_CHECK = "FAILED_CHECK",
    PENDING_PREVIOUS_OBLIGATIONS = "PENDING_PREVIOUS_OBLIGATIONS",
    HIGH_RISK_ASSESSMENT = "HIGH_RISK_ASSESSMENT",
    GEOGRAPHICAL_RESTRICTION = "GEOGRAPHICAL_RESTRICTION",
    SYSTEM_VALIDATION_FAILURE = "SYSTEM_VALIDATION_FAILURE",
    MISSING_BANK_DETAILS = "MISSING_BANK_DETAILS",
    OTHER = "OTHER",
}

export type RefusalReasonBody = {
    reason: string
    message: string
}
