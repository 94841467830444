import { useCallback } from "react"

import { useDuplicateTags } from "~/domains/analytics/tags/hooks/useDuplicateTags"
import { tagsActions } from "~/domains/analytics/tags/store/tagsSlice"
import { TagObjectType } from "~/domains/analytics/tags/types"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isResultSuccess } from "~/types/Result"

import { useDocumentRelationsApi } from "../documentRelationsApi"
import { documentRelationsActions } from "../store/documentRelationsSlice"

export const useAddRelationToInvoice = (organizationId?: OrganizationId, invoiceId?: string) => {
    const documentRelations = useDocumentRelationsApi()
    const { duplicateTags } = useDuplicateTags()
    const dispatch = useAppDispatch()

    return useCallback(
        async (purchaseOrderId: string) => {
            if (!organizationId || !invoiceId) return

            const result = await documentRelations.addRelationToInvoice(organizationId, invoiceId, {
                purchaseOrderId: purchaseOrderId,
            })
            if (isResultSuccess(result)) {
                dispatch(
                    documentRelationsActions.addInvoiceRelation({
                        relationId: result.result.relationId,
                        purchaseOrderId: purchaseOrderId,
                    })
                )
                // copy tags
                const duplicationResult = await duplicateTags(
                    organizationId,
                    purchaseOrderId,
                    invoiceId,
                    TagObjectType.PURCHASE_ORDER
                )
                if (duplicationResult) {
                    dispatch(tagsActions.tagsDuplicationSuccess())
                }
            }
            return result
        },
        [organizationId, invoiceId, dispatch]
    )
}
