import { TextFieldProps, styled } from "@mui/material"
import { TextField } from "@mui/material"
import { TypographyProps } from "@mui/material"
import { FontStyle, Variant } from "@mui/material/styles/createTypography"

const TextFieldStyled = styled(TextField, {
    shouldForwardProp: (prop) => prop !== "typographyVariant",
})<{ typographyVariant?: TypographyProps["variant"] }>(({ theme, typographyVariant }) => {
    const typography = theme.typography[typographyVariant || "body1"] as FontStyle

    return {
        "& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before, & .MuiInputBase-root::before, & .MuiInputBase-root:hover::before":
            {
                border: "none",
            },
        "& .MuiInputBase-root, & label + .css-49gwhw-MuiInputBase-root-MuiInput-root": {
            marginTop: 0,
        },
        "& .MuiInputLabel-root": {
            display: "none",
        },
        ...(typographyVariant && {
            "& .MuiInputBase-root": {
                ...typography,
            },
        }),
    }
})

type EditableTextProps = TextFieldProps & {
    typography?: Variant
}

export const EditableText = ({ typography, ...props }: EditableTextProps) => {
    return (
        <TextFieldStyled
            {...props}
            InputLabelProps={{
                shrink: true,
            }}
            value={props.value}
            typographyVariant={typography}
            variant={props.variant ?? "standard"}
        />
    )
}
