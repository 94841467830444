import { MessageDescriptor } from "react-intl"
import { toast } from "react-toastify"

import { UserProvisionAndJoinOrganizationI } from "~/domains/identity/account/api/userApi"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { getOrCreateUserByEmailAndJoinOrganization } from "~/store/users/utils"
import {
    CompanyDetailsI,
    ImportInvoiceCompanyInfoI,
    ImportingInvoiceI,
    InvoiceI,
    InvoiceLineI,
    InvoiceStatus,
    InvoiceUserType,
} from "~/types"
import { CountryCode, OrganizationI, OrganizationId, ProvisionnedUserI, UserId } from "~/types"

export const commonInvoiceMessages: Record<string, MessageDescriptor> = {
    supplierIdentifierUndefined: {
        id: "invoice.ocrInvoice.identifier.supplier.undefined",
        defaultMessage: "Supplier identifier is not defined: cannot confirm invoice without supplier identifier",
    },
    buyerIdentifierUndefined: {
        id: "invoice.ocrInvoice.identifier.buyer.undefined",
        defaultMessage: "Buyer identifier is not defined: cannot confirm invoice without buyer identifier",
    },
    missingData: {
        id: "invoice.ocrInvoice.missingData",
        defaultMessage: "Some required information is missing",
    },
    missingLinesData: {
        id: "invoice.ocrInvoice.missingLinesData",
        defaultMessage: "Some required information is missing from lines",
    },
}

export const getCompanyIdentifier = (companyDetails: CompanyDetailsI): string => {
    return companyDetails.dunsNumber || companyDetails.registrations.registrationNumber?.registrationNumber || ""
}

export const getImportCompanyIdentifier = (companyDetails: ImportInvoiceCompanyInfoI): string | null => {
    return companyDetails.dunsNumber || companyDetails.registrationNumber || null
}

export const getImportCompanyIdentifierOrEmptyString = (companyDetails: ImportInvoiceCompanyInfoI): string => {
    return getImportCompanyIdentifier(companyDetails) ?? ""
}

export const getImportCompanyIdentifierOrThrow = (
    companyDetails: ImportInvoiceCompanyInfoI,
    type: InvoiceUserType
): string => {
    const result = getImportCompanyIdentifier(companyDetails)
    if (!result) {
        throw new Error(`${type} identifier is not defined: cannot confirm invoice without ${type} identifier`)
    }
    return result
}

export interface CreateOrganizationInfos {
    name: string
    countryCode: CountryCode
    identifier: string
    vatNumber?: string | null
    registrationNumber?: string | null
    dunsNumber?: string | null
}

const getOrFoundOrganization = async (
    organization: OrganizationI | undefined,
    infos: CreateOrganizationInfos
): Promise<[OrganizationId, boolean]> => {
    if (
        organization &&
        (organization.registration?.dunsNumber || organization.registration?.preferredRegistrationNumber)
    ) {
        return [organization.id, false]
    }

    const organizationItem = await organizationApi.foundOrganization(
        infos.name,
        infos.countryCode ?? CountryCode.UNKNOWN,
        infos.identifier
    )
    if (infos.vatNumber || infos.dunsNumber || infos.registrationNumber) {
        await organizationApi.provideRegistration(organizationItem.id, {
            legalName: infos.name,
            countryCode: infos.countryCode,
            dunsNumber: infos.dunsNumber,
            vatNumber: infos.vatNumber ?? "",
            preferredRegistrationNumber: infos.registrationNumber
                ? {
                      registrationNumber: infos.registrationNumber,
                      registrationType: "UNKNOWN",
                  }
                : null,
        })
    }
    return [organizationItem.id, true]
}

type UserWithOptOrganization = {
    userId: UserId
    organizationId: OrganizationId | undefined
}

interface SetUserOrganizationIdResponse extends Omit<ProvisionnedUserI, "userId"> {
    organizationFounded?: boolean
    userId?: UserId
}

export const setUserOrganizationId = async (
    user: UserWithOptOrganization | undefined,
    userData: Omit<UserProvisionAndJoinOrganizationI, "email"> & { email?: string },
    userOrganization: OrganizationI | undefined,
    companyInfos: CreateOrganizationInfos
): Promise<SetUserOrganizationIdResponse> => {
    if (user) {
        const [userOrganizationId, organizationFounded] = await getOrFoundOrganization(userOrganization, companyInfos)
        return {
            ...user,
            organizationId: userOrganizationId,
            organizationFounded,
        }
    }
    if (userData.email) {
        const result = await getOrCreateUserByEmailAndJoinOrganization(userData as UserProvisionAndJoinOrganizationI)
        await organizationApi.provideRegistration(result.organizationId, {
            legalName: companyInfos.name,
            countryCode: companyInfos.countryCode,
            dunsNumber: companyInfos.dunsNumber,
            vatNumber: companyInfos.vatNumber ?? "",
            preferredRegistrationNumber: companyInfos.registrationNumber
                ? {
                      registrationNumber: companyInfos.registrationNumber,
                      registrationType: "UNKNOWN",
                  }
                : null,
        })
        return result
    }

    const organizationItem = await organizationApi.createOrganization(
        companyInfos.name,
        companyInfos.countryCode,
        userData.identifier || companyInfos.dunsNumber || companyInfos.registrationNumber || ""
    )
    return {
        organizationId: organizationItem.id,
    }
}

export type ConfirmInvoiceOptions =
    | {
          supplier: UserWithOptOrganization
          buyer?: undefined
      }
    | {
          supplier?: undefined
          buyer: UserWithOptOrganization
      }

export type OrganizationOptions = {
    buyerOrganization?: OrganizationI
    supplierOrganization?: OrganizationI
}

type CheckFormValidityResult = {
    mainForm: boolean
    invoiceLinesForm: boolean
    supplierIdentifier: boolean
    buyerIdentifier: boolean
}

const isValidLine = (line: InvoiceLineI) =>
    line.linePosition !== undefined &&
    line.linePosition !== null &&
    line.quantity !== undefined &&
    line.quantity !== null &&
    line.unitPrice !== undefined &&
    line.unitPrice !== null &&
    line.totalExcludedTaxes !== undefined &&
    line.totalExcludedTaxes !== null &&
    line.totalTax !== undefined &&
    line.totalTax !== null &&
    line.total !== undefined &&
    line.total !== null

const checkInvoiceLinesValidity = (lines: InvoiceLineI[]): boolean => {
    return lines.filter(isValidLine).length === lines.length
}

export const checkFormValidity = (
    invoice: InvoiceI | ImportingInvoiceI,
    formRef: React.RefObject<HTMLFormElement>,
    invoiceLinesFormRef: React.RefObject<HTMLFormElement>,
    formatMessage,
    isConfirmingInvoice = false
): { valid: boolean; errors: CheckFormValidityResult } => {
    const errors: CheckFormValidityResult = {
        mainForm: false,
        invoiceLinesForm: false,
        supplierIdentifier: false,
        buyerIdentifier: false,
    }
    if (invoice.status === InvoiceStatus.DRAFT && !isConfirmingInvoice) {
        return {
            valid: true,
            errors,
        }
    }
    const mainFormIsValid = !formRef.current || formRef.current.checkValidity()
    const invoiceLinesFormIsValid = !invoiceLinesFormRef.current || invoiceLinesFormRef.current.checkValidity()
    const supplierIdentifier = invoice.supplier.organizationId || getImportCompanyIdentifier(invoice.supplier)
    if (!supplierIdentifier) {
        toast.error(formatMessage(commonInvoiceMessages.supplierIdentifierUndefined))
        errors.supplierIdentifier = true
    }
    const buyerIdentifier = invoice.buyer.organizationId || getImportCompanyIdentifier(invoice.buyer)
    if (!buyerIdentifier) {
        toast.error(formatMessage(commonInvoiceMessages.buyerIdentifierUndefined))
        errors.buyerIdentifier = true
    }
    if (!mainFormIsValid || !invoiceLinesFormIsValid || !supplierIdentifier || !buyerIdentifier) {
        if (!mainFormIsValid) {
            formRef.current.reportValidity()
            errors.mainForm = true
        }
        if (!invoiceLinesFormIsValid) {
            invoiceLinesFormRef.current.reportValidity()
            errors.invoiceLinesForm = true
        }
        if (!mainFormIsValid || !invoiceLinesFormIsValid) {
            toast.error(formatMessage(commonInvoiceMessages.missingData))
        }
        return {
            valid: false,
            errors,
        }
    } else if (invoice.lines && !checkInvoiceLinesValidity(invoice.lines)) {
        errors.invoiceLinesForm = true
        toast.error(formatMessage(commonInvoiceMessages.missingLinesData))
        return {
            valid: false,
            errors,
        }
    }

    return {
        valid: true,
        errors,
    }
}
