import { Chip, Divider, IconButton, Stack, TextField } from "@mui/material"
import cls from "classnames"
import React, { ChangeEvent, FC, useRef, useState } from "react"
import { PlusCircle, User, X } from "react-feather"
import { useIntl } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { AdvancedFields } from "~/domains/orchestration/flows/components/configuration/AdvancedFields"
import { adaptExpressionToText, adaptTextToExpression } from "~/domains/orchestration/flows/core"
import { messages } from "~/domains/orchestration/flows/locale"
import { ConfigurationProps, EmailMailType, SendEmailNode } from "~/domains/orchestration/flows/types"
import { getEmailMailType } from "~/domains/orchestration/flows/utils"

import { ConfigurationNode } from "./ConfigurationNode"

const addEmailAdress = (r: SendEmailNode["recipientAddresses"], email: string, i: number | null) =>
    i !== null
        ? r.map((addr, index) => (index === i ? adaptTextToExpression(email) : addr))
        : [...r, adaptTextToExpression(email)]

export const SendEmailConfiguration: FC<ConfigurationProps<SendEmailNode>> = ({ selectedNode, advancedFields }) => {
    const { formatMessage } = useIntl()
    const [currentNode, setCurrentNode] = useState(selectedNode)
    const [emailInput, setEmailInput] = useState("")
    const [editingIndex, setEditingIndex] = useState<number | null>(null)

    const emailErrorRef = useRef<string | null>(null)

    const getEmailChipColor = (email: string): "error" | "default" | "warning" => {
        const emailMailType = getEmailMailType(email)
        switch (emailMailType) {
            case EmailMailType.ERROR:
                return "error"
            case EmailMailType.EXPRESSION:
                return "warning"
            default:
                return "default"
        }
    }

    const handleChangeSubject = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            subject: e.target.value,
        }))
    }

    const handleChangeBody = (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode((prev) => ({
            ...prev,
            body: e.target.value,
        }))
    }

    const addEmail = (email: string) => {
        const trimmedEmail = email.trim()
        if (!trimmedEmail) return

        emailErrorRef.current = null

        setCurrentNode((prev) => ({
            ...prev,
            recipientAddresses: addEmailAdress(prev.recipientAddresses, trimmedEmail, editingIndex),
        }))
        setEmailInput("")
        setEditingIndex(null)
    }

    const handleEmailInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault()
            addEmail(emailInput)
        }
    }

    const handleEmailInputBlur = () => {
        if (emailInput.trim()) {
            // Only add email if there's actual input
            addEmail(emailInput)
        }
    }

    const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmailInput(e.target.value)
        emailErrorRef.current = null
    }

    const handleDeleteEmail = (index: number) => () => {
        setCurrentNode((prev) => ({
            ...prev,
            recipientAddresses: prev.recipientAddresses.filter((_, i) => i !== index),
        }))
    }

    const handleEditEmail = (index: number) => () => {
        const emailText = adaptExpressionToText(currentNode.recipientAddresses[index])
        setEmailInput(emailText)
        setEditingIndex(index)
    }

    const configurationNodeItemClassName = cls("flows-configurationNode-item", "flows-editor-sideBar-item")

    return (
        <ConfigurationNode selectedNode={currentNode}>
            <Stack gap={2} className={configurationNodeItemClassName}>
                <SafeFormattedMessage tagName="h5" {...messages.sendEmailConfiguration.title} />

                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.subjectLabel)}
                    value={adaptExpressionToText(currentNode.subject)}
                    size="small"
                    onChange={handleChangeSubject}
                />

                <Stack gap={1}>
                    <Stack direction="row" gap={1} alignItems="center">
                        <TextField
                            label={formatMessage(messages.sendEmailConfiguration.toLabel)}
                            value={emailInput}
                            size="small"
                            type="email"
                            onChange={handleEmailInputChange}
                            onKeyDown={handleEmailInputKeyDown}
                            onBlur={handleEmailInputBlur}
                            error={!!emailErrorRef.current}
                            fullWidth
                        />
                        <IconButton onClick={() => addEmail(emailInput)} size="small">
                            <PlusCircle size={16} />
                        </IconButton>
                    </Stack>
                    <Divider />
                    <Stack direction="row" gap={0.5} flexWrap="wrap">
                        {currentNode.recipientAddresses.map((email, index) => (
                            <Chip
                                key={index}
                                label={adaptExpressionToText(email)}
                                onDelete={handleDeleteEmail(index)}
                                onClick={handleEditEmail(index)}
                                color={getEmailChipColor(email)}
                                deleteIcon={<X size={16} />}
                                icon={<User size={16} />}
                            />
                        ))}
                    </Stack>
                </Stack>
                <Divider />
                <TextField
                    label={formatMessage(messages.sendEmailConfiguration.bodyLabel)}
                    value={currentNode.body}
                    multiline
                    rows={10}
                    onChange={handleChangeBody}
                />
            </Stack>

            <AdvancedFields<SendEmailNode>
                fields={advancedFields}
                currentNode={currentNode}
                setCurrentNode={setCurrentNode}
            />
        </ConfigurationNode>
    )
}
