import { useCallback, useEffect, useState } from "react"

import { withSocketIOProvider } from "~/domains/_shared/subscriptions/components/SocketIOContext"
import { TransactionInBudgets } from "~/domains/transactions/_shared/components/TransactionInBudgets"
import { budgetApi } from "~/domains/transactions/budget/api/budgetApi"
import { BudgetDataWithMetricsI, TransactionType } from "~/domains/transactions/budget/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { useFetchInvoice, useSubscribeInvoiceUpdates } from "~/store/invoice/hooks"
import { invoiceActions } from "~/store/invoice/invoiceSlice"
import { selectCurrentOrganization } from "~/store/organization/organizationSlice"
import { InvoiceI, ViewTypeI } from "~/types"

interface InvoiceBudgetsProps extends JSX.IntrinsicAttributes {
    invoice: InvoiceI
}

export const InvoiceBudgets = withSocketIOProvider(({ invoice }: InvoiceBudgetsProps) => {
    const dispatch = useAppDispatch()

    const [draftBudgets, setDraftBudgets] = useState<BudgetDataWithMetricsI[]>([])
    const { buyerOrganization, refetchInvoice } = useFetchInvoice(invoice.id, ViewTypeI.buyer)
    useSubscribeInvoiceUpdates(buyerOrganization?.id, invoice.id)

    const organization = useAppSelector(selectCurrentOrganization)

    useEffect(() => {
        if (!invoice?.payerId) return
        ;(async () => {
            // This comes from useFetchInvoice
            // only handle buyer view for now
            const budgets = await budgetApi.getBudgetByTransaction(invoice.payerId, invoice.id, TransactionType.INVOICE)
            setDraftBudgets(budgets)
            dispatch(invoiceActions.updatePartialInvoice({ id: invoice.id, budgets }))
        })()
    }, [invoice?.sellerId, invoice?.id, dispatch])

    const handleSuccess = useCallback(async () => {
        dispatch(invoiceActions.updatePartialInvoice(invoice))
        await refetchInvoice()
    }, [dispatch, refetchInvoice])

    return (
        <TransactionInBudgets
            transaction={invoice}
            objectType={TransactionType.INVOICE}
            organizationId={organization?.id || ""}
            handleSuccess={handleSuccess}
            draftBudgets={draftBudgets}
            setDraftBudgets={setDraftBudgets}
        />
    )
})
