import { InvoiceFieldLevel } from "~/domains/transactions/invoices-v1/types/Invoice"
import { InvoiceI } from "~/domains/transactions/invoices/types/InvoiceTypes"

export const getTemplateLineCustomFields = (invoice: InvoiceI, organizationId: string) => {
    if (!invoice || !organizationId || !invoice.parties?.[organizationId]) return []

    const customFields = invoice.parties?.[organizationId]?.template?.customFields

    return (customFields || []).filter(({ level }) => level === InvoiceFieldLevel.Line)
}
