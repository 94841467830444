import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { defineMessages } from "react-intl"

import { prepareHeadersWithAuthorization } from "~/api/prepareHeaders"
import { OrganizationId } from "~/types"

const errors = defineMessages({
    errorUpload: {
        id: "common.error.errorUploadingDocument",
        defaultMessage: "An error occurred while uploading the document.",
    },
})

const BASE_URL = import.meta.env.VITE_API_DOCUMENT_INFO_EXTRACT_URL

export const processDocumentApi = createApi({
    reducerPath: "processDocumentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: prepareHeadersWithAuthorization,
    }),
    endpoints: (builder) => ({
        processDocument: builder.mutation<any, { file: File; organizationId: OrganizationId }>({
            query: ({ file, organizationId }) => {
                const formData = new FormData()
                formData.append("files", file)
                formData.append("data", JSON.stringify({ organizationId: organizationId ?? "" }))

                return {
                    url: "process_files",
                    method: "POST",
                    body: formData,
                }
            },
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: errors.errorUpload,
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
    }),
})

export const { useProcessDocumentMutation } = processDocumentApi
