
import { CreatePartnershipPayload, PartnershipTypeOption } from "~/domains/identity/partners/types"
import { CountryCode, ImportInvoiceCompanyInfoI } from "~/types"

export const createEmptyPartner = (countryCode = CountryCode.FR): ImportInvoiceCompanyInfoI => ({
    countryCode,
    name: "",
    contactName: "",
    organizationId: null,
})

export const createEmptyPartnershipPayload = (): CreatePartnershipPayload => ({
    partnerId: "",
    partnershipType: PartnershipTypeOption.SUPPLIER,
    preferredStatus: false,
    verifiedStatus: false,
})
