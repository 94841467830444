import { defineMessages } from "react-intl"

export const extractMessages = defineMessages({
    title: {
        id: "supplier.extract.title",
        defaultMessage: "Informations",
    },
    reference: {
        id: "supplier.extract.reference",
        defaultMessage: "Document ref.",
    },
    purchaseOrderNumber: {
        id: "supplier.extract.purchaseOrderNumber",
        defaultMessage: "Purchase order number",
    },
    deadLine: {
        id: "supplier.extract.deadline",
        defaultMessage: "Payment deadline",
    },
    issueDate: {
        id: "buyer.invoice.page.issueDate",
        defaultMessage: "issue date (MM/DD/YYYY)",
    },
    dueDate: {
        id: "buyer.invoice.page.dueDate",
        defaultMessage: "due date (MM/DD/YYYY)",
    },
    totalNoTax: {
        id: "supplier.extract.totalNoTax",
        defaultMessage: "Total (taxes excl.)",
    },
    totalDiscount: {
        id: "supplier.extract.totalDiscount",
        defaultMessage: "Total discount",
    },
    discountRate: {
        id: "supplier.extract.discountRate",
        defaultMessage: "Discount rate (%)",
    },
    total: {
        id: "supplier.extract.total",
        defaultMessage: "Total (taxes incl.)",
    },
    editLines: {
        id: "supplier.extract.editLine",
        defaultMessage: "View invoice items",
    },
})
