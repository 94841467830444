import { FormControlLabel, Grid, Radio, RadioGroup, capitalize } from "@mui/material"
import { ChangeEvent } from "react"
import { useIntl } from "react-intl"

import { partnershipTypeMessages } from "~/domains/identity/partners/components/ModalCreatePartnership/ModalCreatePartnership"
import { PartnershipTypeOption } from "~/domains/identity/partners/types"

interface PartnershipTypeSelectorProps {
    value: PartnershipTypeOption
    onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void
}

export const PartnershipTypeSelector: React.FC<PartnershipTypeSelectorProps> = ({ value, onChange }) => {
    const { formatMessage } = useIntl()

    return (
        <RadioGroup
            aria-labelledby="partnershipType-radio-group"
            name="partnershipType"
            value={value}
            onChange={onChange}
        >
            <Grid container alignItems="center" gap={1}>
                {Object.values(PartnershipTypeOption).map((type) => (
                    <FormControlLabel
                        key={type}
                        value={type}
                        control={<Radio />}
                        label={capitalize(formatMessage(partnershipTypeMessages[type]))}
                    />
                ))}
            </Grid>
        </RadioGroup>
    )
}
