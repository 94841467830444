import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { IntercomProvider } from "react-use-intercom"
import { PersistGate } from "redux-persist/integration/react"

import { InactivityDetection, Loader } from "~/components"
import RouterLayout from "~/components/Layout/RouterLayout"
import ocr from "~/domains/_shared/ocr"
import dashboard from "~/domains/analytics/dashboard"
import metrics from "~/domains/analytics/metrics"
import communication from "~/domains/communication/chat/routes"
import emails from "~/domains/communication/emails"
import tasks from "~/domains/communication/tasks"
import account from "~/domains/identity/account"
import smartForms from "~/domains/identity/custom-forms"
import documents from "~/domains/identity/documents"
import partners from "~/domains/identity/partners"
import rolesPermissions from "~/domains/identity/roles-permissions"
import prompt from "~/domains/orchestration/copilot"
import flows from "~/domains/orchestration/flows"
import workflow from "~/domains/orchestration/flows-v0"
import payments from "~/domains/payment/payment"
import paymentBatches from "~/domains/payment/payment-batches"
import paymentMethods from "~/domains/payment/payment-methods"
import budget from "~/domains/transactions/budget"
import catalog from "~/domains/transactions/catalog"
import invoices from "~/domains/transactions/invoices"
import invoicesV1 from "~/domains/transactions/invoices-v1"
import buyer from "~/domains/transactions/invoices/_views/buyer"
import supplier from "~/domains/transactions/invoices/_views/supplier"
import purchaseOrders from "~/domains/transactions/purchase-orders"
import purchaseRequests from "~/domains/transactions/purchase-requests"
import { LocalesProvider } from "~/i18n/LocalesProvider"
import { messages } from "~/i18n/messages"
import { ThemeProvider } from "~/providers/ThemeProvider"
import { persistor, store } from "~/store"
import { AppCrashFallback } from "~/utils/AppCrashFallback"
import ErrorBoundary from "~/utils/ErrorBoundary"
import { WithApiContext } from "~/utils/apiClient"

import "./assets/css/App.scss"

const modules = [
    buyer,
    supplier,
    account,
    flows,
    workflow,
    partners,
    ocr,
    purchaseRequests,
    purchaseOrders,
    invoices,
    invoicesV1,
    rolesPermissions,
    budget,
    prompt,
    emails,
    documents,
    dashboard,
    payments,
    paymentMethods,
    paymentBatches,
    metrics,
    catalog,
    smartForms,
    communication,
    tasks,
]
const modulesRoutes = modules.reduce(
    (routes: React.ReactElement[], module) => routes.concat(module.routes as React.ReactElement[]),
    []
)

const ENV = import.meta.env.VITE_ENV
const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID

export default function App() {
    return (
        <ErrorBoundary FallbackComponent={AppCrashFallback}>
            <Provider store={store}>
                <ThemeProvider>
                    <PersistGate loading={<Loader />} persistor={persistor}>
                        <LocalesProvider messages={messages} locale="en">
                            {/* <Segment writeKey={SEGMENT_KEY}> */}
                            <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={ENV !== "development"}>
                                <WithApiContext>
                                    <div className="App">
                                        <InactivityDetection />
                                        <BrowserRouter
                                            future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
                                        >
                                            <RouterLayout modulesRoutes={modulesRoutes} modulesNavItems={[]} />
                                        </BrowserRouter>
                                        <ToastContainer
                                            position="top-right"
                                            autoClose={5000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            closeOnClick
                                            rtl={false}
                                            pauseOnFocusLoss
                                            draggable
                                            pauseOnHover
                                        />
                                        {ENV !== "production" && (
                                            <div id="environment" className={ENV}>
                                                {ENV}
                                            </div>
                                        )}
                                    </div>
                                </WithApiContext>
                            </IntercomProvider>
                            {/* </Segment> */}
                        </LocalesProvider>
                    </PersistGate>
                </ThemeProvider>
            </Provider>
        </ErrorBoundary>
    )
}
