import { TextField } from "@mui/material"
import { ChangeEvent, FC } from "react"
import { useIntl } from "react-intl"

import { extractMessages } from "~/domains/transactions/invoices/_views/supplier/components/Extract/extractMessages"
import { UpdateExtractDataFunction } from "~/domains/transactions/invoices/_views/supplier/components/Extract/types"
import { OCRInputTypes } from "~/domains/transactions/invoices/components/OcrExtract/extract"
import { useToggleShowShapes } from "~/domains/transactions/invoices/components/OcrExtract/hooks"

interface ExtractReferenceFieldProps {
    reference: string
    updateData: UpdateExtractDataFunction
    disabled?: boolean
}

export const ExtractReferenceField: FC<ExtractReferenceFieldProps> = ({ reference, updateData, disabled }) => {
    const { formatMessage } = useIntl()
    const toggleShowShapes = useToggleShowShapes("reference", OCRInputTypes.TEXT)

    const onReferenceChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateData({
            reference: event.currentTarget.value,
        })
    }
    return (
        <TextField
            required
            id="reference"
            name="reference"
            label={formatMessage(extractMessages.reference)}
            placeholder={formatMessage(extractMessages.reference)}
            onChange={onReferenceChange}
            value={reference}
            disabled={disabled}
            onFocus={toggleShowShapes}
        />
    )
}
