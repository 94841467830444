import { PaletteOptions, alpha } from "@mui/material/styles"

// ----------------------------------------------------------------------

export type ColorSchema = "primary" | "secondary" | "info" | "success" | "warning" | "error"

declare module "@mui/material/styles/createPalette" {
    interface TypeBackground {
        neutral: string
    }
    interface SimplePaletteColorOptions {
        lighter: string
        darker: string
    }
    interface PaletteColor {
        lighter: string
        darker: string
    }
}

// SETUP COLORS

export const grey = {
    0: "#FFFFFF",
    100: "#F9FAFB",
    200: "#F4F6F8",
    300: "#DFE3E8",
    400: "#C4CDD5",
    500: "#555555",
    600: "#606266",
    700: "#454F5B",
    800: "#212B36",
    900: "#909399",
}

export const primary = {
    main: "#7B61FF",
    light: "#F1EEFF",
    lighter: "#F5F7FA",
    dark: "#DFD9FF",
    darker: "#DFD9FF",
}

export const secondary = {
    main: "#303133",
    light: "#F0F9EB",
    lighter: "#F0F9EB",
    dark: "#E1F3D8",
    darker: "#E1F3D8",
}

export const info = {
    lighter: "#CAFDF5",
    light: "#61F3F3",
    main: "#00B8D9",
    dark: "#006C9C",
    darker: "#003768",
    contrastText: "#FFFFFF",
}

export const success = {
    main: "#67C23A",
    light: "#F0F9EB",
    lighter: "#F0F9EB",
    dark: "#E1F3D8",
    darker: "#E1F3D8",
}

export const warning = {
    main: "#E6A23C",
    light: "#FDE2E2",
    lighter: "#FEF0F0",
    dark: "#FAECD8",
    darker: "#FAECD8",
}

export const error = {
    main: "#F56C6C",
    light: "#FEF0F0",
    lighter: "#FEF0F0",
    dark: "#FDE2E2",
    darker: "#FDE2E2",
}

export const common = {
    black: "#000000",
    white: "#FFFFFF",
}

export const action = {
    hover: alpha(grey[500], 0.08),
    rowOdd: alpha(grey[300], 0.08),
    selected: alpha(grey[500], 0.16),
    disabled: alpha(grey[500], 0.8),
    disabledBackground: alpha(grey[500], 0.24),
    focus: alpha(grey[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
}

const base = {
    primary,
    secondary,
    info,
    success,
    warning,
    error,
    grey,
    common,
    divider: alpha(grey[500], 0.2),
    action,
}

// ----------------------------------------------------------------------

export function palette(mode: "light" | "dark") {
    const light: PaletteOptions = {
        ...base,
        mode: "light",
        text: {
            primary: grey[800],
            secondary: grey[600],
            disabled: grey[500],
        },
        background: {
            paper: "#ffffff", // $sidebar-color
            default: "#f6f7fa", // $background-color
            neutral: grey[200],
        },
        action: {
            ...base.action,
            active: grey[600],
            hover: alpha(grey[400], 0.08),
            selected: alpha(grey[300], 0.08),
        },
    }

    const dark: PaletteOptions = {
        ...base,
        mode: "dark",
        text: {
            primary: "#FFFFFF",
            secondary: grey[500],
            disabled: grey[600],
        },
        background: {
            paper: "#1b1c1d", // $background-dark
            default: "#1b1c1d", // $background-dark
            neutral: alpha(grey[500], 0.12),
        },
        action: {
            ...base.action,
            active: grey[500],
            hover: alpha(grey[400], 0.08),
            selected: alpha(grey[300], 0.08),
        },
    }

    return mode === "light" ? light : dark
}
