import { FC } from "react"

import { SkeletonInput } from "~/components/Skeleton/SkeletonInput"

interface SkeletonInputStackProps {
    count: number
}

export const SkeletonInputStack: FC<SkeletonInputStackProps> = ({ count }) => (
    <>
        {[...Array(count)].map((_, index) => (
            <SkeletonInput key={index} />
        ))}
    </>
)
