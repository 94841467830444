import { useCallback } from "react"

import {
    computePartnerProfile,
    createPartnerProfileContactPayload,
    isPartnerProfileContact,
} from "~/domains/identity/partners/core"
import { CreatePartnerProfileContactI, PartnerProfileContactI } from "~/domains/identity/partners/types"
import { OrganizationId } from "~/types"

import { useCreatePartnerProfile } from "./useCreatePartnerProfile"
import { useCreatePartnerProfileContact } from "./useCreatePartnerProfileContact"
import { useFetchPartnerProfile } from "./useFetchPartnerProfile"

export const usePartnerContactSave = (
    currentOrganizationId: OrganizationId | undefined | null,
    partnerId: OrganizationId | undefined | null
) => {
    const { partnerProfile } = useFetchPartnerProfile(currentOrganizationId, partnerId)
    const { createPartnerProfile } = useCreatePartnerProfile()
    const { createPartnerProfileContact } = useCreatePartnerProfileContact()

    const handleContactSave = useCallback(
        (contact: CreatePartnerProfileContactI | PartnerProfileContactI) => {
            const contactPayload = createPartnerProfileContactPayload(contact)
            if (!currentOrganizationId || !contactPayload || isPartnerProfileContact(contactPayload)) return

            if (!partnerProfile) {
                const computedProfile = computePartnerProfile(
                    partnerProfile,
                    { contacts: [contactPayload] },
                    currentOrganizationId,
                    partnerId
                )
                createPartnerProfile(computedProfile)
            } else {
                createPartnerProfileContact(partnerProfile.initiatorId, partnerProfile.partnerId, contactPayload)
            }
        },
        [partnerProfile, currentOrganizationId, partnerId, createPartnerProfile, createPartnerProfileContact]
    )

    return handleContactSave
}
