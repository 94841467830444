import { Tooltip } from "@mui/material"
import * as Sentry from "@sentry/react"
import React, { FC, useCallback, useEffect, useState } from "react"
import { FileText } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import { Button, Loader, SafeFormattedMessage } from "~/components"
import { useDuplicateDocumentLinks } from "~/components/UploadDocument/hooks/useDuplicateDocumentLinks"
import { useFetchPartnership } from "~/domains/identity/partners/store/hooks"
import useSupplierAllowed from "~/domains/identity/partners/store/hooks/useSupplierAllowed"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { PURCHASE_ORDER_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import { useConvertToPurchaseOrder } from "~/domains/transactions/purchase-requests/store/hooks/useConvertToPurchaseOrder"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { PurchaseRequestConversionStatus } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"

const messages = defineMessages({
    toPO: {
        id: "purchase.requests.request.toPO",
        defaultMessage: "Convert to PO",
    },
    supplierNeeded: {
        id: "purchase.requests.request.supplierNeeded",
        defaultMessage: "You need to select a supplier before converting this PR to a PO",
    },
    deliveryDateNeeded: {
        id: "purchase.requests.request.deliveryDateNeeded",
        defaultMessage: "You need to select a delivery date before converting this PR to a PO",
    },
    supplierNotAllowed: {
        id: "purchase.requests.request.supplierNotAllowed",
        defaultMessage: "This supplier is not allowed to receive POs",
    },
    overBudget: {
        id: "purchase.requests.request.overBudget",
        defaultMessage: "This PR is over budget",
    },
})

interface ConvertPRtoPOProps {
    PR: PurchaseRequestDetails
}

export const ConvertPRtoPO: FC<ConvertPRtoPOProps> = ({ PR }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const { duplicateDocumentLinks } = useDuplicateDocumentLinks()
    const [supplierAllowed, setSupplierAllowed] = useState(false)
    const [overBudget, setOverBudget] = useState(false)

    const { convertToPO, convertLoading } = useConvertToPurchaseOrder(PR.organizationId)
    const convertPermission = PR.permissions?.convertToPurchaseOrder
    const { partnershipData, loading: partnershipDataLoading } = useFetchPartnership(PR.organizationId, PR.supplierId)
    const canConvert = (convertPermission && typeof convertPermission !== "undefined") ?? false

    const isSupplierAllowed = useSupplierAllowed(partnershipData, partnershipDataLoading)

    const handleConvertToPO = useCallback(async () => {
        if (!PR.supplierId) {
            return toast.error(formatMessage(messages.supplierNeeded))
        }
        if (!PR.expectedDeliveryDate) {
            return toast.error(formatMessage(messages.deliveryDateNeeded))
        }

        try {
            const purchaseOrder = await convertToPO(
                PR,
                PR.expectedDeliveryDate,
                PurchaseRequestConversionStatus.SUBMITTED
            )
            if (!purchaseOrder) return

            await duplicateDocumentLinks({ sourceObjectId: PR.id, targetObjectId: purchaseOrder.id })
            navigate(generatePath(PURCHASE_ORDER_ROUTE, { purchaseOrderId: purchaseOrder.id }))
        } catch (error) {
            console.error(error)
            Sentry.captureException(error, {
                extra: { purchaseRequest: PR },
            })
        }
    }, [PR, convertToPO, navigate, duplicateDocumentLinks, formatMessage])

    useEffect(() => {
        setSupplierAllowed(isSupplierAllowed)

        return () => {
            setSupplierAllowed(false)
        }
    }, [partnershipData, partnershipDataLoading, isSupplierAllowed])

    useEffect(() => {
        const budgetForCurrency = PR?.budgets?.find((budget) => budget?.budget?.currency === PR.currency)?.budget

        setOverBudget(
            !budgetForCurrency || (PR.totalAmount ? budgetForCurrency.currentAmount >= PR.totalAmount : false)
        )

        return () => {
            setOverBudget(false)
        }
    }, [PR])

    const disallowReason = () => {
        if (!canConvert) {
            return formatMessage(permissionMessages.errorNoAccessAdministrator)
        } else if (!supplierAllowed) {
            return formatMessage(messages.supplierNotAllowed)
        } else if (!overBudget) {
            return formatMessage(messages.overBudget)
        } else {
            return formatMessage(messages.toPO)
        }
    }

    const isButtonDisabled = !supplierAllowed || !canConvert || !overBudget

    const handleButtonClick = (e: React.MouseEvent) => {
        e.preventDefault()
        if (!isButtonDisabled) {
            handleConvertToPO()
        }
    }

    if (PR.status === PurchaseRequestStatus.APPROVED) {
        return convertLoading ? (
            <Loader small={true} />
        ) : (
            <Tooltip arrow title={disallowReason()}>
                <span>
                    <Button type="tertiary" className="status" disabled={isButtonDisabled} onClick={handleButtonClick}>
                        <FileText size={18} />
                        <SafeFormattedMessage {...messages.toPO} />
                    </Button>
                </span>
            </Tooltip>
        )
    }
    return null
}
