import { PartnerOrganizationI } from "~/domains/identity/partners/types"
import { CompanyI, CountryCode, OrganizationI } from "~/types"

export enum OrganizationPickerFromOcrResultType {
    ORGANIZATION,
    COMPANY,
}

export type OrganizationPickerFromOcrResult =
    | {
          type: OrganizationPickerFromOcrResultType.ORGANIZATION
          value: OrganizationI | PartnerOrganizationI
      }
    | {
          type: OrganizationPickerFromOcrResultType.COMPANY
          value: CompanyI
      }

export interface CompanyData {
    countryCode: CountryCode
    name?: string
    registrationNumber?: string
    taxNumber?: string
}
