import { DataGridPremium, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid-premium"
import { CheckCircle, XCircle } from "react-feather"
import { FormattedDate, FormattedNumber } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Card, CurrencySymbol, Loader, SafeFormattedMessage } from "~/components"
import { BatchLineActions } from "~/domains/payment/payment-batches/components"
import { usePaymentBatchesManagement } from "~/domains/payment/payment-batches/hooks"
import { BatchRecurrenceType, PaymentBatch } from "~/domains/payment/payment-batches/types"
import { batchRecurrenceTypesMessages } from "~/domains/payment/payment-batches/utils"
import { PartyPaymentMethodDetails } from "~/domains/payment/payment-method-details/components"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { PAGE_SIZE_OPTIONS } from "~/utils"

const columns: GridColDef[] = [
    {
        field: "name",
        type: "string",
        minWidth: 100,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatch>) => params.row.name,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.name} />,
    },
    {
        field: "description",
        type: "string",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatch>) => params.row.description,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.description} />,
        renderCell: ({ value }: GridRenderCellParams) => (value ? value : "-"),
    },
    {
        field: "recurrenceType",
        type: "string",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatch>) => params.row.recurrenceType,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.recurrenceType} />,
        renderCell: ({ value }: GridRenderCellParams) =>
            value ? <SafeFormattedMessage {...batchRecurrenceTypesMessages[value as BatchRecurrenceType]} /> : "-",
    },
    {
        field: "defaultTotalAmount",
        type: "string",
        minWidth: 120,
        flex: 1,
        valueGetter: ({ row }: GridValueGetterParams<PaymentBatch>) => [row.defaultTotalAmount, row.defaultCurrency],
        renderHeader: () => <SafeFormattedMessage {...commonMessages.defaultTotalAmount} />,
        renderCell: ({ value: [amount, currency] }: GridRenderCellParams) =>
            !amount ? "-" : <FormattedNumber value={amount} style="currency" currency={currency} />,
    },
    {
        field: "startDate",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatch>) => params.row.startDate,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.startDate} />,
        renderCell: (params: GridRenderCellParams<PaymentBatch>) => (
            <FormattedDate value={params.row.startDate ?? ""} day="numeric" month="numeric" year="numeric" />
        ),
    },
    {
        field: "endDate",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatch>) => params.row.endDate,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.endDate} />,
        renderCell: (params: GridRenderCellParams<PaymentBatch>) => (
            <FormattedDate value={params.row.endDate ?? ""} day="numeric" month="numeric" year="numeric" />
        ),
    },
    {
        field: "aggregatePayments",
        type: "boolean",
        minWidth: 100,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatch>) => params.row.aggregatePayments,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.aggregatePayments} />,
        renderCell: (params: GridRenderCellParams<PaymentBatch>) =>
            params.row.aggregatePayments ? (
                <CheckCircle color="var(--color-green)" size={16} />
            ) : (
                <XCircle color="var(--color-red)" size={16} />
            ),
    },
    {
        field: "collectPayments",
        type: "boolean",
        minWidth: 100,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatch>) => params.row.collectPayments,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.collectPayments} />,
        renderCell: (params: GridRenderCellParams<PaymentBatch>) =>
            params.row.collectPayments ? (
                <CheckCircle color="var(--color-green)" size={16} />
            ) : (
                <XCircle color="var(--color-red)" size={16} />
            ),
    },
    {
        field: "defaultCurrency",
        type: "string",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatch>) => params.row.defaultCurrency,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.defaultCurrency} />,
        renderCell: ({ value }: GridRenderCellParams) => (value ? <CurrencySymbol currency={value} /> : "-"),
    },
    {
        field: "paymentMethod",
        type: "string",
        minWidth: 120,
        flex: 1,
        valueGetter: (params: GridValueGetterParams<PaymentBatch>) => [
            params.row.defaultPaymentMethodId,
            params.row.defaultPaymentMethodDetailsId,
        ],
        renderHeader: () => <SafeFormattedMessage {...commonMessages.paymentMethod} />,
        renderCell: ({ value: [paymentMethodId, paymentMethodDetailsId] }: GridRenderCellParams) => {
            if (!paymentMethodId && !paymentMethodDetailsId) {
                return "-"
            }

            return (
                <PartyPaymentMethodDetails
                    mode="inline"
                    paymentMethodId={paymentMethodId}
                    paymentMethodDetailsId={paymentMethodDetailsId}
                />
            )
        },
    },
    {
        field: "actions",
        sortable: false,
        filterable: false,
        resizable: false,
        disableColumnMenu: true,
        align: "center",
        minWidth: 130,
        renderHeader: () => "",
        renderCell: (params: GridRenderCellParams) => <BatchLineActions row={params.row} />,
    },
]

export const PaymentBatchesList = () => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const { batches, isFetching, isLoading } = usePaymentBatchesManagement({ organizationId })

    if (!organizationId) {
        return null
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <Card>
            <DataGridPremium
                columns={columns}
                rows={batches}
                getRowId={(row: PaymentBatch) => row?.id ?? ""}
                rowCount={batches.length}
                loading={isFetching}
                rowSelection={false}
                autoHeight
                pagination
                paginationMode="client"
                pageSizeOptions={PAGE_SIZE_OPTIONS}
            />
        </Card>
    )
}
