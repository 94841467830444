import * as Sentry from "@sentry/browser"
import { useCallback, useEffect, useMemo } from "react"
import { defineMessage, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { useGetTaxDefinitionsQuery, useLazyGetTaxDefinitionsQuery } from "~/domains/transactions/invoices-v1/api/vatApi"
import { VatRateI, VatRateIO } from "~/domains/transactions/invoices-v1/types/VatRate"
import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { store } from "~/store"
import { globalActions, selectFetchingVatRates, selectVatRates } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { CountryCode, InvoiceBackendVersion, validateCountryCodeOrSetUnknown } from "~/types"
import { genericParser } from "~/utils"

const errorMessage = defineMessage({
    id: "invoice.vatRates.errorRetrievingVatRates",
    defaultMessage: "An error occured while retrieving the vat rates.",
})

const parseVatRates = (vatRate: unknown): VatRateI | null => {
    const result = genericParser(vatRate, VatRateIO)
    if (result.success) {
        return {
            ...result.result,
            countryCode: validateCountryCodeOrSetUnknown(result.result.countryCode),
        }
    } else {
        return null
    }
}

const vatRateDefined = (vatRate: VatRateI | null): vatRate is VatRateI => !!vatRate

export const useVatRates = (countryCode: CountryCode, backendVersion: InvoiceBackendVersion) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()
    const vatRates = useAppSelector(selectVatRates)
    const [fetch, { data: taxDefinitions }] = useLazyGetTaxDefinitionsQuery()

    const fetchVatRates = useCallback(async () => {
        const fetchingVatRates = selectFetchingVatRates(store.getState())
        if (!fetchingVatRates) {
            dispatch(globalActions.fetchVatRates())
            try {
                const results = await invoiceApi.getVatRates()
                const parsedResults = results.map(parseVatRates).filter(vatRateDefined)
                dispatch(globalActions.fetchVatRatesSuccess(parsedResults))
            } catch (error) {
                dispatch(globalActions.fetchVatRatesFailure())

                Sentry.captureException(`Error retrieving vatRates: ${error}`, {
                    extra: {
                        error,
                    },
                })
                toast.error(formatMessage(errorMessage))
            }
        }
    }, [dispatch])

    useEffect(() => {
        if (!vatRates && backendVersion === InvoiceBackendVersion.V0) {
            fetchVatRates()
        } else if (backendVersion === InvoiceBackendVersion.V1) {
            fetch(countryCode)
        }
    }, [fetchVatRates, vatRates, backendVersion, countryCode])

    return useMemo(
        () =>
            backendVersion === InvoiceBackendVersion.V0
                ? vatRates
                    ? vatRates.filter((vatRate) => vatRate.countryCode === countryCode)
                    : []
                : (taxDefinitions ?? []),
        [vatRates, countryCode, taxDefinitions, backendVersion]
    )
}
