import { CountryCode, ProvisionnedUserI, UserCompanyDetailsI, UserI } from "~/types"

import axiosClient from "../../../../api/axiosClient"

const STAKEHOLDERS_BASE_URL = import.meta.env.VITE_API_STAKEHOLDERS_URL
const USERS_BASE_URL = `${STAKEHOLDERS_BASE_URL}v1/users`

export interface UpdateUserI {
    email?: string
    name?: string
    phone?: string
    position?: string
    companyDetails?: UserCompanyDetailsI
}

export interface BuyerInfoI {
    email: string
    name: string
    companyDetails: UserCompanyDetailsI
    invoiceId: string
}

export interface UserProvisionI {
    email: string
    name?: string
}

export interface UserProvisionAndJoinOrganizationI {
    email: string
    name?: string
    identifier: string
    countryCode: CountryCode
}

export interface UpdateStakeholderUserI {
    fullName: string
    phoneNumber: string
}

export const userApi = {
    getCurrentUser(): Promise<UserI> {
        return axiosClient.get(`${USERS_BASE_URL}/current`, {
            validateStatus: (code) => (code >= 200 && code < 300) || code === 401,
        })
    },
    updateUser(updatedUserInfo: UpdateStakeholderUserI): Promise<boolean> {
        return axiosClient.post(`${USERS_BASE_URL}/provide-info`, updatedUserInfo)
    },
    findUsersByEmail(email: string): Promise<UserI[]> {
        return axiosClient.get(`${USERS_BASE_URL}?email=${encodeURIComponent(email)}`)
    },
    findUsersByIds(ids: string[]): Promise<UserI[]> {
        ids = ids.filter((id) => id)
        if (!ids.length) {
            return Promise.resolve([])
        }
        return axiosClient.get(`${USERS_BASE_URL}?ids=${encodeURIComponent(ids.join(","))}`)
    },
    provisionUserAccount(user: UserProvisionI): Promise<ProvisionnedUserI> {
        return axiosClient.post(`${USERS_BASE_URL}/provision`, user)
    },
    provisionUserAccountAndJoinOrganization(user: UserProvisionAndJoinOrganizationI): Promise<ProvisionnedUserI> {
        return axiosClient.post(`${USERS_BASE_URL}/provision-in-organization`, user)
    },
}
