import { useCallback } from "react"

import { useCommunicationApi } from "~/domains/communication/chat/communicationApi"
import { communicationActions } from "~/domains/communication/chat/store/CommunicationSlice"
import { CreateMessageTemplatePayload } from "~/domains/communication/chat/types/MessageTemplate"
import { useAppDispatch } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isResultSuccess } from "~/types/Result"

export const useSaveMessageAsTemplate = (organizationId: OrganizationId) => {
    const communicationApi = useCommunicationApi()
    const dispatch = useAppDispatch()
    return useCallback(
        async (payload: CreateMessageTemplatePayload) => {
            const result = await communicationApi.createTemplate(organizationId, payload)
            if (isResultSuccess(result)) {
                dispatch(communicationActions.addMessageTemplate({ organizationId, template: result.result }))
            }
            return result
        },
        [organizationId, communicationApi, dispatch]
    )
}
