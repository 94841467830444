import * as t from "io-ts"

import { optional } from "~/utils/io-ts"

export const PaginationInfoIO = t.intersection([
    t.type({
        page: t.number,
        totalResults: t.number,
        perPage: t.number,
        totalPages: t.number,
    }),
    t.partial({
        query: optional(t.string),
    }),
])

export type PaginationInfoI = t.TypeOf<typeof PaginationInfoIO>

export interface PaginationQuery {
    query?: string
    page?: number
    perPage?: number
}

export interface PaginationOffsetLimit {
    limit?: number
    offset?: number
}
