import { Stack, TextField } from "@mui/material"
import { ChangeEvent } from "react"
import { useIntl } from "react-intl"

import { Card, SafeFormattedMessage } from "~/components"
import { messages } from "~/domains/orchestration/flows/locale"
import { AdvancedFieldsValues } from "~/domains/orchestration/flows/types/Configuration"

interface Props<T> {
    fields: AdvancedFieldsValues<T>
    currentNode: T
    setCurrentNode: (node: T) => void
}

export function AdvancedFields<T>({ fields, currentNode, setCurrentNode }: Props<T>) {
    const { formatMessage } = useIntl()

    const handleChange = (field: keyof T) => (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentNode({
            ...currentNode,
            [field]: e.target.value,
        })
    }

    if (!fields.length) return null

    return (
        <Card
            expandable
            header={<SafeFormattedMessage {...messages.configurationNode.advanced} />}
            isExpandedByDefault={false}
        >
            <Stack gap={2}>
                {fields.map((field) => (
                    <TextField
                        key={field}
                        label={formatMessage(messages.advancedFields[field])}
                        value={currentNode[field]}
                        onChange={handleChange(field)}
                    />
                ))}
            </Stack>
        </Card>
    )
}
