import { SxProps, Theme, styled } from "@mui/material"
import { PropsWithChildren, forwardRef } from "react"

import { CrossIcon } from "~/components/Icons"
import { Variant, getBackgroundByVariant, getBorderColorByVariant, getColorByVariant } from "~/utils/colors"

export type Size = "large" | "medium" | "small" | "mini"

interface CommonProps {
    variant: Variant
    size: Size
    borderLess: boolean
}

interface BasicProps extends PropsWithChildren {
    variant?: Variant
    size?: Size
    sx?: SxProps<Theme>
    borderLess?: boolean
}

type Props = BasicProps &
    (
        | {
              removable?: false
              onRemove?: never
              onClick?: never
          }
        | { removable: true; onRemove: () => void; onClick?: never }
        | { removable?: false; onRemove?: never; onClick: () => void }
    )

const heightBySize: Record<Size, string> = {
    large: "32px",
    medium: "28px",
    small: "24px",
    mini: "20px",
}

const paddingBySize: Record<Size, string> = {
    large: "3px 10px",
    medium: "3px 10px",
    small: "1px 8px",
    mini: "0 5px",
}

const fontSizeBySize: Record<Size, string> = {
    large: "14px",
    medium: "14px",
    small: "12px",
    mini: "11px",
}

const ChipContainer = styled("span", {
    shouldForwardProp: (propsName) =>
        propsName !== "theme" && propsName !== "variant" && propsName !== "size" && propsName !== "borderLess",
})<CommonProps>(({ theme, variant, size = "medium", borderLess }) => ({
    fontFamily: "Noto Sans",
    fontSize: fontSizeBySize[size],
    fontWeight: 500,
    lineHeight: "22px",
    height: heightBySize[size],
    padding: paddingBySize[size],
    borderRadius: "4px",
    boxSizing: "border-box",
    border: `1px solid ${getBorderColorByVariant(variant, theme.palette)}`,
    color: getColorByVariant(variant, theme.palette),
    stroke: getColorByVariant(variant, theme.palette),
    background: getBackgroundByVariant(variant, theme.palette),
    gap: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    svg: {
        cursor: "pointer",
    },
    ...(borderLess && { border: "none" }),
}))

const Chip = forwardRef<HTMLSpanElement, Props>(
    ({ children, borderLess = false, variant, size = "medium", sx, removable, onRemove, onClick }, ref) => {
        return (
            <ChipContainer
                ref={ref}
                variant={variant}
                size={size}
                sx={sx}
                borderLess={borderLess}
                onClick={onClick}
                // Faking the MuiChip class to apply styles from ThemeProvider
                className="MuiChip-root"
            >
                {children}
                {removable && <CrossIcon onClick={onRemove} size={12} color="current" />}
            </ChipContainer>
        )
    }
)
Chip.displayName = "Chip"
export { Chip }
