import { Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"

import { Button, MenuItem, sideBarMenuItems } from "~/components"
import { SafeFormattedMessage } from "~/components"
import { PlusIcon } from "~/components/Icons"
import { getSidebarFeatureFlags } from "~/components/Sidebar/getSidebarFeatureFlags"
import { OcrPendingJobsQueue } from "~/domains/transactions/invoices/_views/ocr/components/OcrPendingJobsQueue"
import { HOME_ROUTE, UPLOAD_ROUTE } from "~/routes/routes"
import { selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

const ICON_SIZE = 18

interface SidebarNewUploadProps {
    organizationId?: string
}

export const SidebarNewUpload = ({ organizationId }: SidebarNewUploadProps) => {
    const navigate = useNavigate()

    const sideBarOpened = useAppSelector(selectKeepSidebarOpened)
    const { isInvoiceV1Enabled } = getSidebarFeatureFlags(organizationId)

    const handleNewUpload = () => {
        navigate(isInvoiceV1Enabled ? UPLOAD_ROUTE : HOME_ROUTE)
    }

    return (
        <Stack gap={1} paddingBlock={2} justifyContent="stretch" width="100%">
            <Button
                onClick={handleNewUpload}
                sx={{ borderRadius: "var(--border-radius-sm)", width: "100%" }}
                size="small"
            >
                <PlusIcon size={ICON_SIZE} color="white" />
                {sideBarOpened && <SafeFormattedMessage {...sideBarMenuItems[MenuItem.NEW_UPLOAD].message} />}
            </Button>
            <OcrPendingJobsQueue component="li" compact />
        </Stack>
    )
}
