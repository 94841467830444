import * as Sentry from "@sentry/browser"
import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import { partnerApi } from "~/domains/identity/partners/api/partnerApi"
import { CreatePartnerProfileIO } from "~/domains/identity/partners/api/partnerDto"
import { PartnerProfileI } from "~/domains/identity/partners/types"
import { useAppSelector } from "~/store/hooks"
import { isResultSuccess } from "~/types/Result"
import { genericParser } from "~/utils"

import { bookOfRelationsActions, selectPartnerProfile } from "../bookOfRelationsSlice"

type FetchPartnerProfileResult = {
    partnerProfile: PartnerProfileI | null
    loading: boolean
    error: string | null
}

export const useFetchPartnerProfile = (
    organizationId: string | undefined | null,
    partnerId: string | undefined | null
): FetchPartnerProfileResult => {
    const dispatch = useDispatch()
    const { partnerProfile, loading, error } = useAppSelector(selectPartnerProfile)

    useEffect(() => {
        if (
            organizationId &&
            partnerId &&
            !loading &&
            !error &&
            (!partnerProfile || partnerProfile.partnerId !== partnerId || partnerProfile.initiatorId !== organizationId)
        ) {
            dispatch(bookOfRelationsActions.fetchPartnerProfile())
            partnerApi
                .fetchPartnerProfile(organizationId, partnerId)
                .then((data) => {
                    if (!data) {
                        return dispatch(bookOfRelationsActions.fetchPartnerProfileSuccess(null))
                    }
                    const result = genericParser(data, CreatePartnerProfileIO)
                    if (isResultSuccess(result)) {
                        dispatch(bookOfRelationsActions.fetchPartnerProfileSuccess(data))
                    } else {
                        const errorMsg = `Issue parsing partner profile`
                        Sentry.captureException(new Error(errorMsg), {
                            tags: { organizationId },
                            extra: { error: error, partnerId, detailType: "partnerProfile" },
                        })
                    }
                })
                .catch((e) => {
                    const errorMsg = new Error(`Unable to fetch partner profile, error caught: ${error}`)
                    Sentry.captureException(errorMsg, {
                        tags: { organizationId },
                        extra: { error: e.message, errorMsg: error, partnerId, detailType: "partnerProfile" },
                    })
                    dispatch(bookOfRelationsActions.fetchPartnerProfileFailed(e.message))
                })
        }
    }, [organizationId, partnerId, dispatch])

    return useMemo(() => {
        return { partnerProfile, loading, error }
    }, [organizationId, partnerId, partnerProfile, loading, error])
}
