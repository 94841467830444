import { styled } from "@mui/material"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"

import { SelectedTagI, TagAssignmentRule, TagGroupI, TagI, TagId } from "~/domains/analytics/tags/types"

import { TagGroupLabel } from "./TagGroupLabel"
import { MultipleOptionsProps, SingleOptionProps, TagsList } from "./TagsList"

interface Props {
    tagGroup: TagGroupI
    unfilteredTags: TagI[]
    selectedTags: SelectedTagI[]
    setSelectedTags: Dispatch<SetStateAction<SelectedTagI[]>>
    highlightTag?: TagI | null
    defaultExpanded: boolean
}

const TagGroupContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: "100%",
})

export const TagGroup: React.FC<Props> = ({
    tagGroup,
    unfilteredTags,
    selectedTags,
    setSelectedTags,
    highlightTag,
    defaultExpanded,
}) => {
    const [expanded, setExpanded] = useState(false)
    const multiple = tagGroup.assignmentRule === TagAssignmentRule.MULTIPLE

    useEffect(() => {
        setExpanded(defaultExpanded)
    }, [defaultExpanded])

    const selectedId = useMemo(
        () =>
            selectedTags.reduce((acc: Set<TagId>, tag: TagI) => {
                acc.add(tag.tagId)
                return acc
            }, new Set<TagId>()),
        [selectedTags]
    )
    const findSelectedTag = (tag: TagI) => selectedId.has(tag.tagId)

    const onMultipleChange = useCallback(
        (tags: SelectedTagI[]) => {
            const newTags = [...selectedTags.filter((stateTag) => stateTag.tagGroupId !== tagGroup.tagGroupId), ...tags]
            setSelectedTags(newTags)
        },
        [setSelectedTags, selectedTags, tagGroup.tagGroupId]
    )

    const onSingleChange = useCallback(
        (tag: TagI | null) => {
            const newTags = [
                ...selectedTags.filter((stateTag) => stateTag.tagGroupId !== tagGroup.tagGroupId),
                ...(tag ? [tag] : []),
            ]
            setSelectedTags(newTags)
        },
        [setSelectedTags, selectedTags, tagGroup.tagGroupId]
    )

    const handleToggleExpanded = () => setExpanded((prev) => !prev)

    const props = multiple
        ? ({
              multiple: true,
              withRatio: true,
              ratioKey: "ratio",
              onChange: onMultipleChange,
              value: selectedTags.filter((selectedTag) => selectedTag.tagGroupId === tagGroup.tagGroupId),
          } as MultipleOptionsProps<SelectedTagI>)
        : ({
              multiple: false,
              onChange: onSingleChange,
              value: unfilteredTags.find(findSelectedTag) || null,
          } as SingleOptionProps<SelectedTagI>)

    return (
        <TagGroupContainer>
            <TagGroupLabel tagGroup={tagGroup} expanded={expanded} toggleExpanded={handleToggleExpanded} />
            {expanded && (
                <TagsList
                    items={tagGroup.tags}
                    labelKeys={["name", "value"]}
                    idKey="tagId"
                    highlightItem={highlightTag}
                    {...props}
                />
            )}
        </TagGroupContainer>
    )
}
