import { useCallback, useEffect } from "react"

import { useSocketIOContext } from "~/domains/_shared/subscriptions/components/SocketIOContext"
import { useCommunicationApi } from "~/domains/communication/chat"
import { messageFromApiAdapter } from "~/domains/communication/chat/api/adapters"
import { sortMessagesByDate } from "~/domains/communication/chat/core"
import { useMessagesWithUsers } from "~/domains/communication/chat/hooks/useMessagesWithUsers"
import { communicationActions } from "~/domains/communication/chat/store/CommunicationSlice"
import { selectMessagesState } from "~/domains/communication/chat/store/selectors"
import { MessageStatus, RoomId } from "~/domains/communication/chat/types"
import { selectUser } from "~/store/account/accountSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { isResultSuccess } from "~/types/Result"

export const useRoomMessages = (organizationId: OrganizationId, roomId: RoomId) => {
    const user = useAppSelector(selectUser)
    const { fetchingMessagesInRoom, messagesPerRoom } = useAppSelector(selectMessagesState)
    const communicationApi = useCommunicationApi()
    const dispatch = useAppDispatch()

    const { ioSocket, isConnected } = useSocketIOContext()

    const messagesInRoom = messagesPerRoom[roomId]
    const fetchingInRoom = fetchingMessagesInRoom[roomId]

    const messages = messagesInRoom?.messages
    const messagesWithUsers = useMessagesWithUsers(messages)

    const fetchMoreMessages = useCallback(async () => {
        if (
            !fetchingInRoom &&
            (!messagesInRoom ||
                !messagesInRoom.paginationInfo ||
                messagesInRoom.paginationInfo.total_pages > messagesInRoom.paginationInfo.current_page)
        ) {
            const page = messagesInRoom?.paginationInfo ? messagesInRoom.paginationInfo.current_page + 1 : 1
            dispatch(communicationActions.fetchMessageInRoom({ roomId }))
            const result = await communicationApi.getMessages(organizationId, roomId, page)
            if (isResultSuccess(result)) {
                dispatch(
                    communicationActions.fetchMessageInRoomSuccess({
                        roomId,
                        roomMessages: {
                            paginationInfo: result.result.pagination_info,
                            messages: result.result.messages.sort(sortMessagesByDate),
                        },
                    })
                )
            }
        }
    }, [organizationId, roomId, fetchingInRoom, messagesInRoom])

    useEffect(() => {
        if (!fetchingInRoom && !messagesInRoom) {
            fetchMoreMessages()
        }
    }, [fetchingInRoom, messagesInRoom])

    useEffect(() => {
        if (user && user.id) {
            ioSocket.on(roomId, (data) => {
                const message = messageFromApiAdapter(data)
                if (!isResultSuccess(message)) return

                if (message.result.status === MessageStatus.DELETE) {
                    return dispatch(communicationActions.deleteMessage(message.result.id))
                }
                dispatch(communicationActions.addMessageInRoom({ roomId, message: message.result }))
            })
            ioSocket.emit(`subscribe/chat`, { roomId, user })
            return () => {
                ioSocket.emit(`unsubscribe/chat`, { roomId, user })
            }
        }
    }, [ioSocket, isConnected, user])

    return { messagesInRoom: messagesWithUsers, loading: fetchingInRoom, fetchMoreMessages }
}
