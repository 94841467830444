import { PayloadAction } from "@reduxjs/toolkit"
import { all, call, put, select, takeLatest } from "redux-saga/effects"

import { userApi } from "~/domains/identity/account/api/userApi"
import { UserI } from "~/types"
import { fetchEntitiesInChunks } from "~/utils/fetchEntitiesInChunks"

import { selectUsers, usersActions } from "./usersSlice"

function* fetchUsers(action: PayloadAction<string[]>) {
    try {
        const currentUsers = yield select(selectUsers) as unknown as Record<string, UserI>
        const missingUserIds = action.payload.filter((userId) => !currentUsers[userId])
        if (missingUserIds.length > 0) {
            const response: UserI[] = yield call(fetchEntitiesInChunks, missingUserIds, userApi.findUsersByIds)
            yield put(usersActions.fetchUsersSuccess(response))
        } else {
            yield put(usersActions.fetchUsersSuccess([]))
        }
    } catch (e) {
        yield put(usersActions.fetchUsersFailed(`${e}`))
    }
}

export default function* usersSaga() {
    yield all([takeLatest(usersActions.fetchUsers.type, fetchUsers)])
}
