import * as t from "io-ts"

import { OrganizationId, TeamId, UserI, UserId } from "~/types"
import { Opaque } from "~/utils"
import { optional } from "~/utils/io-ts"

import { CustomElement } from "./Slate"

export type MessageId = Opaque<string, { readonly T: unique symbol }>

export const MessageContentTextIO = t.intersection([
    t.type({
        text: t.string,
    }),
    t.partial({
        children: optional(t.any),
    }),
])
export type MessageContentTextI = t.TypeOf<typeof MessageContentTextIO>

export interface MessageContentTypeI {
    type: string
    children: Array<MessageContentTypeI | MessageContentTextI>
}

export type MessageContentText = t.TypeOf<typeof MessageContentTextIO> & {
    type?: never
}

export type MessageContentCombined = CustomElement | MessageContentText | MentionElementI

export enum MessageContentType {
    Paragraph = "p",
    Mention = "mention",
    Checkbox = "checkbox",
    ActionPlan = "action-plan",
    ActionPlanTitle = "action-plan-title",
    ActionPlanItem = "action-plan-item",
}

export enum MentionTypes {
    USER = "user",
    TEAM = "team",
}
export type UserMentionI = {
    type: MentionTypes.USER
    value: UserId
    label: string
    organizationId: OrganizationId
}

export type TeamMentionI = {
    type: MentionTypes.TEAM
    value: TeamId
    label: string
    organizationId: OrganizationId
}

export type MentionI = UserMentionI | TeamMentionI

export type MentionElementI = {
    type: MessageContentType.Mention
    data: MentionI
    children: MessageContentText[]
}

export type MessageContentParagraph = {
    type: MessageContentType.Paragraph
    children: (MessageContentText | MentionElementI)[]
}

export type ActionPlanCheckListI = {
    id: string
    checked: boolean
    dueDate: string | null
}

export type MessageActionPlanTitle = {
    type: MessageContentType.ActionPlanTitle
    data: ActionPlanData
    children: MessageContentText[]
}

export type MessageActionPlanItem = {
    type: MessageContentType.ActionPlanItem
    data: ActionPlanCheckListI
    children: (MessageContentText | MentionElementI)[]
}

export type ActionPlanData = {
    id: string
    type: string
}

export type MessageActionPlan = {
    type: MessageContentType.ActionPlan
    children: [MessageActionPlanTitle, ...MessageActionPlanItem[]]
}

export type MessageViewI = {
    id: string
    messageId: MessageId
    userId: UserId
    viewed: boolean
    viewedAt: string
}

export enum MessageStatus {
    DELETE = "DELETE",
}
export type MessageI = {
    id: MessageId
    senderId: UserId
    views: MessageViewI[]
    content: (MessageContentParagraph | MessageActionPlan)[]
    createdAt: string
    updatedAt: string
    isPinned: boolean
    status?: MessageStatus
}
export type MessageWithUserI = MessageI & { user?: UserI }
