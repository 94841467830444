import { Box, Tab, Tabs } from "@mui/material"
import { SyntheticEvent } from "react"
import { MessageDescriptor, useIntl } from "react-intl"

export type HeaderNavigationItem = {
    label: MessageDescriptor
    value: string
    onClick: () => void
    hidden?: boolean
}

interface HeaderNavigationProps {
    items: HeaderNavigationItem[]
    selectedIndex: number
    onChange: (value: string) => void
}

export const HeaderNavigation = ({ items, selectedIndex, onChange }: HeaderNavigationProps) => {
    const { formatMessage } = useIntl()

    const handleChange = (e: SyntheticEvent, index: number) => {
        const newValue = items[index].value
        onChange(newValue)
    }

    return (
        <Box justifyContent="center" paddingBlock={2} width="100%">
            <Tabs value={selectedIndex} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                {items.map(
                    (item, index) =>
                        !item.hidden && <Tab key={index} label={formatMessage(item.label)} onClick={item.onClick} />
                )}
            </Tabs>
        </Box>
    )
}
