import { MessageDescriptor, defineMessages } from "react-intl"

import { DocumentType } from "~/domains/identity/documents/types"

export const documentTypeMessages: Record<DocumentType, MessageDescriptor> = defineMessages({
    [DocumentType.CONTRACT]: { id: "documents.documentTypes.contract", defaultMessage: "Contract" },
    [DocumentType.INVOICE]: { id: "documents.documentTypes.invoice", defaultMessage: "Invoice" },
    [DocumentType.QUOTE]: { id: "documents.documentTypes.quote", defaultMessage: "Quote" },
    [DocumentType.PURCHASE_REQUEST]: {
        id: "documents.documentTypes.purchaseRequest",
        defaultMessage: "Purchase Request",
    },
    [DocumentType.PURCHASE_ORDER]: {
        id: "documents.documentTypes.purchaseOrder",
        defaultMessage: "Purchase Order",
    },
    [DocumentType.DELIVERY_RECEIPT]: {
        id: "documents.documentTypes.deliveryReceipt",
        defaultMessage: "Delivery Receipt",
    },
    [DocumentType.GOODS_RECEIPT]: {
        id: "documents.documentTypes.goodsReceipt",
        defaultMessage: "Goods Receipt",
    },
    [DocumentType.BUSINESS_IDENTITY_PROOF]: {
        id: "documents.documentTypes.businessIdentityProof",
        defaultMessage: "Business Identity Proof",
    },
    [DocumentType.ECOVADIS]: {
        id: "documents.documentTypes.ecovadis",
        defaultMessage: "Ecovadis",
    },
    [DocumentType.ISO]: {
        id: "documents.documentTypes.iso",
        defaultMessage: "ISO",
    },
    [DocumentType.DNB]: {
        id: "documents.documentTypes.dnb",
        defaultMessage: "D&B",
    },
    [DocumentType.BANK_ACCOUNT_DETAILS]: {
        id: "documents.documentTypes.iban",
        defaultMessage: "IBAN",
    },
    [DocumentType.PAYMENT_METHOD_DETAILS]: {
        id: "documents.documentTypes.paymentMethodDetails",
        defaultMessage: "Payment Method Details",
    },
    [DocumentType.OTHER]: { id: "documents.documentTypes.other", defaultMessage: "Other" },
})
