import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { DatePickerSlotsComponentsProps } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import { useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { QuestionValue } from "~/domains/identity/custom-forms/types/CustomForms"

interface CustomFormsDateInputProps {
    onValueChanged: (value: QuestionValue) => void
    savedValue?: QuestionValue
    disabled?: boolean
    isInvalid?: boolean
    minDate?: string
    maxDate?: string
}

export const CustomFormsDateInput = ({
    onValueChanged,
    savedValue,
    disabled,
    minDate,
    maxDate,
    isInvalid,
}: CustomFormsDateInputProps) => {
    const { locale } = useIntl()
    const slotProps: DatePickerSlotsComponentsProps<dayjs.Dayjs> = {
        textField: {
            variant: "outlined",
            error: isInvalid,
            helperText: isInvalid ? <SafeFormattedMessage {...commonMessages.errorRequiredField} /> : "",
        },
    }

    const handleChange = (newValue: dayjs.Dayjs | null) => {
        if (newValue?.isValid()) {
            onValueChanged(newValue.format("YYYY-MM-DD"))
        } else {
            onValueChanged("") // this will invalidate the question in provider in case it's required
        }
    }

    const datesLimits = { minDate: minDate ? dayjs(minDate) : undefined, maxDate: maxDate ? dayjs(maxDate) : undefined }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
            <DatePicker
                disabled={disabled}
                value={savedValue ? dayjs(savedValue as string) : null}
                slotProps={slotProps}
                format="YYYY-MM-DD"
                onChange={handleChange}
                {...datesLimits}
            />
        </LocalizationProvider>
    )
}
