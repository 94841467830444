import React, { createContext, useContext, useEffect } from "react"

import { GlobalFilterType, TagFilterProps, globalActions } from "~/store/global/globalSlice"
import { useAppDispatch } from "~/store/hooks"

interface FilterPropsContext {
    getFilterProps: (type: GlobalFilterType) => TagFilterProps | null
}

const FilterPropsContext = createContext<FilterPropsContext | undefined>(undefined)

interface FilterProviderProps {
    registerTypes: GlobalFilterType[]
    getFilterProps: (type: GlobalFilterType) => TagFilterProps | null
    children: React.ReactNode
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ registerTypes, getFilterProps, children }) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(globalActions.setFilterTypes(registerTypes))

        return () => {
            dispatch(globalActions.resetFilterTypes())
        }
    }, [dispatch, registerTypes])

    return <FilterPropsContext.Provider value={{ getFilterProps }}>{children}</FilterPropsContext.Provider>
}

export const useFilterProps = (): FilterPropsContext | null => {
    return useContext(FilterPropsContext) || null
}
