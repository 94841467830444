import { FC, useState } from "react"
import { defineMessages } from "react-intl"

import { Button, Chip, SafeFormattedMessage } from "~/components"
import { ActionPlanTypeAutocomplete } from "~/domains/communication/chat/components/InputMessage/ActionPlan/ActionPlanTypeAutocomplete"
import { useContextMessage } from "~/domains/communication/chat/components/MessageContext"
import { MessageActionPlanTitle } from "~/domains/communication/chat/types"

const messages = defineMessages({
    setActionPlanType: {
        id: "communication.chat.actionPlan.type.setActionPlanType",
        defaultMessage: "Set action plan type",
    },
})

interface ActionPlanTypeProps {
    element: MessageActionPlanTitle
}

export const ActionPlanType: FC<ActionPlanTypeProps> = ({ element }) => {
    const [autocompleteVisible, setAutocompleteVisible] = useState(false)
    const onClose = () => setAutocompleteVisible(false)
    const message = useContextMessage()

    if (autocompleteVisible) return <ActionPlanTypeAutocomplete element={element} onClose={onClose} />
    if (!element.data.type && message) return null
    if (!element.data.type)
        return (
            <Button size="x-small" type="title" onClick={() => setAutocompleteVisible(true)}>
                <SafeFormattedMessage {...messages.setActionPlanType} />
            </Button>
        )
    return (
        <Chip size="small" onClick={() => setAutocompleteVisible(true)}>
            {element.data.type}
        </Chip>
    )
}
