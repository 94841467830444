/* eslint-disable no-nested-ternary */
import { AutocompleteRenderGroupParams, styled } from "@mui/material"
import React from "react"
import { defineMessages } from "react-intl"

import { SafeFormattedMessage } from "~/components"
import { PartnerContactGroup } from "~/domains/identity/partners/types"

const messages = defineMessages({
    emailCreationGroupHeader: {
        id: "partners.bookofrelations.selectContacts.emailCreationGroupHeader",
        defaultMessage: "Keep typing an email to add a contact",
    },
    companyContactsGroupHeader: {
        id: "partners.bookofrelations.selectContacts.companyBookGroupHeader",
        defaultMessage: "{CompanyName}'s contacts",
    },
    defaultGroupHeader: {
        id: "partners.bookofrelations.selectContacts.defaultGroupHeader",
        defaultMessage: "My partner's contacts",
    },
})

const GroupHeader = styled("div")(() => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: "var(--color-grey-light)",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "18px",
    backgroundColor: "white",
}))

const GroupItems = styled("ul")({
    padding: 0,
})

interface RenderGroupOptionProps {
    params: AutocompleteRenderGroupParams
    partnerCompanyName?: string | null
}

export const RenderGroup = ({ params, partnerCompanyName }: RenderGroupOptionProps) => {
    const group = params.group as PartnerContactGroup

    return (
        <li>
            <GroupHeader>
                {group === PartnerContactGroup.CREATION ? (
                    <SafeFormattedMessage {...messages.emailCreationGroupHeader} />
                ) : group === PartnerContactGroup.PARTNER && partnerCompanyName ? (
                    <SafeFormattedMessage
                        {...messages.companyContactsGroupHeader}
                        values={{ CompanyName: partnerCompanyName }}
                    />
                ) : (
                    <SafeFormattedMessage {...messages.defaultGroupHeader} />
                )}
            </GroupHeader>
            <GroupItems>{params.children}</GroupItems>
        </li>
    )
}
