import { Tooltip } from "@mui/material"
import React, { useMemo } from "react"

import { buildTagHierarchalPath } from "~/domains/analytics/tags/core/tagGroupsAndTags"
import { TagGroupI } from "~/domains/analytics/tags/types"
import { TagI } from "~/domains/analytics/tags/types"

interface TagTooltipPathProps {
    tag: TagI
    tagGroups: TagGroupI[] | null
    hasParent: boolean
}

// Helper function to render path segments with separators and optional ellipsis
const renderPath = (path: string[], addEllipsis = false): JSX.Element[] => {
    return path.reduce<JSX.Element[]>((acc, segment, index) => {
        acc.push(<span key={segment}>{segment}</span>)

        if (index < path.length - 1) {
            acc.push(
                <span key={`separator-${segment}`} className="separator">
                    {" > "}
                </span>
            )
        }

        // Add ellipsis and separator before the last segment if `addEllipsis` is true
        if (addEllipsis && index === path.length - 2) {
            acc.push(<span key="ellipsis">...</span>)
            acc.push(
                <span key={`separator-ellipsis`} className="separator">
                    {" > "}
                </span>
            )
        }

        return acc
    }, [])
}

export const TagTooltipPath: React.FC<TagTooltipPathProps> = ({ tag, tagGroups, hasParent }): JSX.Element | string => {
    const { fullPath, reducedPath } = useMemo(() => {
        const defaultPath = { fullPath: [], reducedPath: [] }
        return hasParent ? (buildTagHierarchalPath(tag, tagGroups) ?? defaultPath) : defaultPath
    }, [hasParent, tag, tagGroups])

    const renderedFullPath = useMemo(() => renderPath(fullPath), [fullPath])
    const renderedReducedPath = useMemo(
        () => renderPath(reducedPath, fullPath.length > reducedPath.length),
        [reducedPath, fullPath]
    )

    if (!fullPath.length || !reducedPath.length) return "-"

    return (
        <div>
            {fullPath.length > reducedPath.length ? (
                <Tooltip title={<div>{renderedFullPath}</div>}>
                    <div>{renderedReducedPath}</div>
                </Tooltip>
            ) : (
                renderedFullPath
            )}
        </div>
    )
}
