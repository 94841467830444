import { Box, Stack, Tooltip, Typography } from "@mui/material"
import { CheckCircle } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, Card, Loader } from "~/components"
import { ActivePayment } from "~/domains/payment/payment-method-details/components"
import { XMLGenerator } from "~/domains/payment/payment/components/DocumentPayment"
import { useManagePayment } from "~/domains/payment/payment/hooks/useManagePayment"
import { PaymentStatus } from "~/domains/payment/payment/types"

const messages = defineMessages({
    updatePayment: {
        id: "payment.document.paymentDetails.updatePayment",
        defaultMessage: "Update",
    },
    paymentDetails: {
        id: "payment.document.paymentDetails",
        defaultMessage: "Payment details",
    },
    markAsPaid: {
        id: "payment.document.paymentDetails.markAsPaid",
        defaultMessage: "Mark as paid",
    },
    warningMessage: {
        id: "payment.document.paymentDetails.warningMessage",
        defaultMessage: "Notify your vendor that you have executed the payment",
    },
    paymentExecuted: {
        id: "payment.document.paymentDetails.paymentExecuted",
        defaultMessage: "You've set the invoice as paid",
    },
})

interface DocumentPaymentProps {
    documentPaid: boolean
    documentId: string
    onPaymentUpdate?: (documentId: string) => void
    onMarkAsPaid?: () => void
}

export const DocumentPayment = ({ documentPaid, documentId, onPaymentUpdate, onMarkAsPaid }: DocumentPaymentProps) => {
    const { formatMessage } = useIntl()
    const { payment, isLoading, isUpdatingPayment, editPayment } = useManagePayment({ documentId }, { skip: true })

    const handleUpdatePayment = () => {
        if (onPaymentUpdate) {
            onPaymentUpdate(documentId)
        }
    }

    const handleMarkAsPaid = async () => {
        await editPayment({
            status: PaymentStatus.EXECUTED,
            date_validated: new Date().toISOString(),
            date_scheduled: null,
            value_date_expected: null,
        })
        if (onMarkAsPaid) {
            onMarkAsPaid()
        }
    }

    const UpdatePaymentButton = () => (
        <Button size="x-small" type="title" onClick={handleUpdatePayment}>
            {formatMessage(messages.updatePayment)}
        </Button>
    )

    if (isLoading) {
        return (
            <Card title={formatMessage(messages.paymentDetails)}>
                <Loader small />
            </Card>
        )
    }

    if (!payment?.id) {
        return (
            <Card>
                <UpdatePaymentButton />
            </Card>
        )
    }

    return (
        <Card
            header={
                <Box display="flex" gap={1} alignItems="center">
                    <Typography variant="h4">{formatMessage(messages.paymentDetails)}</Typography>
                    {!documentPaid && <UpdatePaymentButton />}
                </Box>
            }
            expandable
            sx={{
                width: "100%",
            }}
        >
            <Stack spacing={2} flexDirection="column">
                <ActivePayment payment={payment} />
                {documentPaid ? (
                    <Box
                        component="div"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap={2}
                    >
                        <CheckCircle size={48} color="var(--color-green)" />
                        <Typography component="h2" textAlign="center" color="var(--color-grey-light)">
                            {formatMessage(messages.paymentExecuted)}
                        </Typography>
                    </Box>
                ) : (
                    <Stack spacing={2}>
                        {payment.destination_cash_id && <XMLGenerator paymentId={payment.id} />}
                        {payment.status !== PaymentStatus.EXECUTED && (
                            <Tooltip arrow placement="bottom" title={formatMessage(messages.warningMessage)}>
                                <span>
                                    <Button
                                        type="primary-light"
                                        block
                                        disabled={isUpdatingPayment}
                                        onClick={handleMarkAsPaid}
                                    >
                                        {formatMessage(messages.markAsPaid)}
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                    </Stack>
                )}
            </Stack>
        </Card>
    )
}
