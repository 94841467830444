const regEmoji =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
export const cleanName = (text: string) => {
    return text.replace(/"/g, "").replace(regEmoji, "").replace(/\t/g, " ").replace(/  +/g, " ")
}

export const nameNormalized = (name: string) => {
    const cleanedName = cleanName(name)
    return (
        cleanedName
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            // .replace(/\W/g, "-")
            .replace(/---/g, "-")
            .replace(/--/g, "-")
            .replace(/^-/, "")
            .replace(/-$/, "")
    )
}

export const emailValid = (email: string) => {
    return /^[\w_!#$%&'*+/=?`{|}~^.-]+@[\w.-]+$/.test(email)
}

export const toSnakeCase = (input: string): string => {
    return input
        .replace(/([A-Z])/g, "_$1")
        .toLowerCase()
        .replace(/^_/, "")
}

export const WHITE_SPACE_REGEXP = /[^a-zA-Z0-9_-]+/g
