import * as t from "io-ts"

import { optional } from "~/utils/io-ts"

import { TagAssignmentRule } from "./TagAssignmentRule"

export const CreateTagGroupIO = t.intersection([
    t.type({
        name: t.string,
    }),
    t.partial({
        assignmentRule: optional(t.string),
        description: optional(t.string),
        shortName: optional(t.string),
    }),
])

export type CreateTagGroupI = t.TypeOf<typeof CreateTagGroupIO> & {
    assignmentRule?: TagAssignmentRule
}
