import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { OrganisationEstablishmentResponse } from "~/domains/identity/organization/types/OrganisationV2Types"

import { organisationEstablishmentFromApiAdapter } from "./adapters/organisationEstablishmentFromApiAdapter"

const BASE_URL = import.meta.env.VITE_API_ORG_V2_URL

export const organizationV2Api = createApi({
    reducerPath: "organizationV2Api",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ["Organization"],
    endpoints: (builder) => ({
        getOrganizationEstablishmentBySiret: builder.query<OrganisationEstablishmentResponse, { siret: string }>({
            query: ({ siret }) => ({
                url: `/organization-relationships/establishments/${siret}`,
            }),
            providesTags: ["Organization"],
            transformResponse: (response: OrganisationEstablishmentResponse) => {
                return organisationEstablishmentFromApiAdapter(response)
            },
        }),
    }),
})

export const { useGetOrganizationEstablishmentBySiretQuery } = organizationV2Api
