import { Tooltip } from "@mui/material"
import { X } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    refuse: { id: "buyer.invoice.actions.button.refuse", defaultMessage: "Refuse" },
    refuseButtonTooltip: {
        id: "buyer.invoice.actions.button.refuse.tooltip",
        defaultMessage: "The invoice does not correspond to what you expected? Refuse it.",
    },
})

interface RejectInvoiceProps {
    handleReject?: () => void
}

export const RejectInvoice = ({ handleReject }: RejectInvoiceProps) => {
    const { formatMessage } = useIntl()

    return (
        <Tooltip title={formatMessage(messages.refuseButtonTooltip)} arrow>
            <div>
                <Button onClick={handleReject} type="error-light">
                    <X size={18} />
                    <SafeFormattedMessage {...messages.refuse} />
                </Button>
            </div>
        </Tooltip>
    )
}
