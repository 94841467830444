import { Tooltip } from "@mui/material"
import { Edit2 } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { generatePath, useNavigate } from "react-router-dom"

import { Button, SafeFormattedMessage } from "~/components"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { PURCHASE_REQUEST_EDIT_ROUTE } from "~/domains/transactions/purchase-requests/routes"

const messages = defineMessages({
    edit: {
        id: "purchase.requests.request.header.actions.edit",
        defaultMessage: "Edit",
    },
})

interface EditPRProps {
    id: string
    hasPermission?: boolean
    display?: "menu-item" | "button"
}

export function EditPR({ id, hasPermission, display }: EditPRProps) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const canEdit = (hasPermission && typeof hasPermission !== "undefined") ?? false

    const handleEdit = () => {
        navigate(generatePath(PURCHASE_REQUEST_EDIT_ROUTE, { purchaseRequestId: id }))
    }

    return (
        <>
            {display === "button" ? (
                <Tooltip
                    arrow
                    title={formatMessage(!canEdit ? permissionMessages.errorNoAccessAdministrator : messages.edit)}
                >
                    <span>
                        <Button type="grey" onClick={handleEdit} disabled={!canEdit}>
                            <Edit2 size={18} />
                        </Button>
                    </span>
                </Tooltip>
            ) : (
                <Button type="menu-item" onClick={handleEdit} disabled={!canEdit}>
                    <Edit2 size={18} />
                    <SafeFormattedMessage {...messages.edit} />
                </Button>
            )}
        </>
    )
}
