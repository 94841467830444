interface OcrShapeI {
    id: string
    coordinates: number[][]
}

interface OcrContentI {
    id: string
    content: string
}

export interface OcrDataI {
    shapes: OcrShapeI[]
    contents: OcrContentI[]
}

interface OcrTextSegmentI {
    startIndex?: number | string | null
    endIndex?: number | string | null
}

interface OcrTextAnchorI {
    textSegments?: OcrTextSegmentI[] | null
    content?: string | null
}

interface OcrVerticeI {
    x: number
    y: number
}

enum Orientation {
    ORIENTATION_UNSPECIFIED = 0,
    PAGE_UP = 1,
    PAGE_RIGHT = 2,
    PAGE_DOWN = 3,
    PAGE_LEFT = 4,
}

interface OcrBoundingPolyI {
    vertices: OcrVerticeI[]
    normalizedVertices: OcrVerticeI[]
}

interface OcrLayoutI {
    textAnchor?: OcrTextAnchorI | null
    confidence: number
    boundingPoly?: OcrBoundingPolyI | null
    orientation: Orientation | keyof typeof Orientation
}

interface OcrParagraphI {
    layout?: OcrLayoutI | null
}

interface OcrPageI {
    pageNumber: number
    paragraphs: OcrParagraphI[]
    layout?: OcrLayoutI | null
}

export interface RawDocumentI {
    pages?: OcrPageI[] | null
    text: string
}

export enum OCRInputTypes {
    BUYER,
    SUPPLIER,
    PAYMENT,
    AUTOCOMPLETE,
    NUMBER,
    AMOUNT,
    CUSTOM_FIELD,
    TEXT,
}

export function extractMultipleData(ocr: RawDocumentI | undefined | null, images: string[]): OcrDataI[] {
    if (!ocr || !ocr.pages) return []

    return ocr.pages.map((_: OcrPageI, i: number) => ({
        ...extractData(ocr, i),
        image: images[i],
    }))
}

const getPage = (document: RawDocumentI, pageIndex: number): OcrPageI | null =>
    document.pages && document.pages?.length > pageIndex ? document.pages[pageIndex] : null

const getParagraphs = (page: OcrPageI): OcrParagraphI[] => page.paragraphs ?? []

const getPageVertices = (page: OcrPageI, verticesIndex: number): OcrVerticeI | null => {
    if (!page.layout?.boundingPoly?.vertices || page.layout.boundingPoly.vertices.length <= verticesIndex) return null
    return page.layout.boundingPoly.vertices[verticesIndex]
}

const NO_CONTENT = {
    contents: [],
    shapes: [],
}

export function extractData(ocr: RawDocumentI, pageIndex = 0) {
    const page = getPage(ocr, pageIndex)
    if (!page) return NO_CONTENT

    const contents: OcrContentI[] = [],
        shapes: OcrShapeI[] = [],
        paragraphs = getParagraphs(page)

    if (!paragraphs.length) {
        return {
            contents,
            shapes,
        }
    }

    paragraphs.map((paragraph, index) => {
        if (
            !page.layout &&
            paragraph.layout?.boundingPoly?.vertices &&
            paragraph.layout?.boundingPoly?.vertices.length > 3
        ) {
            shapes.push({
                id: `${index}`,
                coordinates: convertCoordsToPixel(
                    paragraph.layout.boundingPoly.vertices[2],
                    paragraph.layout?.boundingPoly?.normalizedVertices
                ),
            })
        } else {
            shapes.push({
                id: `${index}`,
                coordinates: convertCoordsToPixel(
                    getPageVertices(page, 2),
                    paragraph.layout?.boundingPoly?.normalizedVertices
                ),
            })
        }
        contents.push({
            id: `${index}`,
            content: ocr.text
                ? convertSegmentsToContent(paragraph.layout?.textAnchor?.textSegments, ocr.text)
                : (paragraph?.layout?.textAnchor?.content ?? ""),
        })
    })

    return {
        contents,
        shapes,
    }
}

function convertCoordsToPixel(
    layoutVertices: OcrVerticeI | null,
    coords: OcrVerticeI[] | null | undefined
): number[][] {
    if (!coords || !coords.length || !layoutVertices) {
        return []
    }

    return coords.map((c, index) => [
        addPixelsToVertices(layoutVertices, index, "x", c.x),
        addPixelsToVertices(layoutVertices, index, "y", c.y),
    ])
}

function addPixelsToVertices(
    layoutVertices: OcrVerticeI,
    index: number,
    xOrY: "x" | "y",
    vertices: number,
    nbPixels = 4
) {
    switch (index) {
        case 0: // top-left
            return (vertices * layoutVertices[xOrY] - nbPixels) / layoutVertices[xOrY]
        case 1: // bottom-left
            return (vertices * layoutVertices[xOrY] + (xOrY === "x" ? nbPixels : -nbPixels)) / layoutVertices[xOrY]
        case 2: // bottom-right
            return (vertices * layoutVertices[xOrY] + nbPixels) / layoutVertices[xOrY]
        case 3: // top-right
            return (vertices * layoutVertices[xOrY] + (xOrY === "x" ? -nbPixels : nbPixels)) / layoutVertices[xOrY]
    }

    return vertices
}

const parseFloatIfText = (number: number | string): number => (typeof number === "number" ? number : parseFloat(number))

function convertSegmentsToContent(segments: OcrTextSegmentI[] | null | undefined, text: string) {
    if (!segments || !segments.length) {
        return ""
    }

    return segments
        .map(({ startIndex, endIndex }) => {
            startIndex = startIndex ? parseFloatIfText(startIndex) : 0
            endIndex = endIndex ? parseFloatIfText(endIndex) : text.length
            return text.substring(startIndex, endIndex)
        })
        .join("\n")
}

export function convertStringToNumber(text: string) {
    const float = parseFloat(text.replace(/(\s|€|eur|\$|£)/gi, "").replace(",", "."))
    return float && !isNaN(float) ? float : 0
}

export function getOCRDataWithoutOCR(images: string[]) {
    return images.map((image) => ({
        contents: [],
        shapes: [],
        image,
    }))
}
