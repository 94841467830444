import { Badge, Grid, IconButton, InputBase, Paper } from "@mui/material"
import classNames from "classnames"
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react"
import { Send } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { CopilotSpeechToText } from "~/domains/orchestration/copilot/components/CopilotSpeechToText"
import { Conversation } from "~/domains/orchestration/copilot/types/Copilot"

import { CopilotUploadDocument } from "./CopilotUploadDocument"

const messages = defineMessages({
    askQuestion: {
        id: "copilot.askQuestion",
        defaultMessage: "Ask a question or get some help",
    },
})

interface CopilotPromptBarProps {
    displayMenu: boolean
    file: File | undefined
    conversation: Conversation[]
    message: string
    handleChangeMessage: Dispatch<SetStateAction<string>>
    handleUpload: (file: File) => void
    handlePressEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void
    handleSendMessage: () => void
    setTextToSpeechEnabled: (value: boolean) => void
}

export const CopilotPromptBar: FC<CopilotPromptBarProps> = ({
    displayMenu,
    file,
    conversation,
    message,
    handleChangeMessage,
    handleUpload,
    handlePressEnter,
    handleSendMessage,
    setTextToSpeechEnabled,
}) => {
    const { formatMessage } = useIntl()

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => handleChangeMessage(event.currentTarget.value)

    return (
        <Paper
            component="form"
            className={classNames("prompt-container", {
                "sidebar-opened": displayMenu,
                centered: !conversation.length,
            })}
        >
            <Grid container gap={2}>
                <Badge color="secondary" badgeContent={file ? 1 : 0}>
                    <CopilotUploadDocument onUpload={handleUpload} />
                </Badge>
                <InputBase
                    required
                    id="message"
                    name="message"
                    placeholder={formatMessage(messages.askQuestion)}
                    onChange={onInputChange}
                    value={message}
                    onKeyDown={handlePressEnter}
                    sx={{ flexGrow: 1 }}
                />

                <CopilotSpeechToText
                    handleChangeMessage={handleChangeMessage}
                    handleSendMessage={handleSendMessage}
                    setTextToSpeechEnabled={setTextToSpeechEnabled}
                />

                <IconButton color="primary" onClick={handleSendMessage}>
                    <Send />
                </IconButton>
            </Grid>
        </Paper>
    )
}
