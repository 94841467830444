import { Autocomplete, TextField } from "@mui/material"
import React, { FC, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { useOrganizationId } from "~/domains/orchestration/flows/hooks"
import { messages } from "~/domains/orchestration/flows/locale"
import { BudgetNode } from "~/domains/orchestration/flows/types"
import { BudgetDataI } from "~/domains/transactions/budget/types"
import { useFetchBudgetsData } from "~/store/budget/hooks"

interface Props {
    currentNode: BudgetNode
    handleChange: (props: BudgetNode) => void
}

export const SharedBudgetConfiguration: FC<Props> = ({ currentNode, handleChange }) => {
    const { formatMessage } = useIntl()

    const organizationId = useOrganizationId()
    const { budgetsData } = useFetchBudgetsData({ organizationId, withMetrics: false })

    const [budget, setBudget] = useState<BudgetDataI | null>(null)

    const handleChangeBudget = (_, value: BudgetDataI | null) => {
        setBudget(value)
        handleChange({
            ...currentNode,
            budgetId: value?.id || "",
            metadata: {
                ...currentNode.metadata,
                additionalInformation: value?.name || "",
            },
        })
    }

    useEffect(() => {
        const currentBudget = budgetsData.find((b) => b.id === currentNode.budgetId)

        if (currentBudget) {
            setBudget(currentBudget)
        }
    }, [budgetsData, currentNode.budgetId])

    return (
        <>
            <Autocomplete
                options={budgetsData}
                value={budget}
                onChange={handleChangeBudget}
                getOptionLabel={(b: BudgetDataI) => b.name}
                isOptionEqualToValue={(option: BudgetDataI, value: BudgetDataI) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={formatMessage(messages.sharedBudgetConfiguration.selectBudgetsLabel)}
                    />
                )}
            />

            {/* TODO: add these once we have the API for them 

            <TextField
                select
                required
                label={formatMessage(messages.sharedBudgetConfiguration.transactionTypeLabel)}
                value={currentNode.transactionType ?? ""}
                onChange={handleChangeTransactionType}
                fullWidth
            >
                {TRANSACTION_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                        {formatMessage(messages.transactionType[type])}
                    </MenuItem>
                ))}
            </TextField>
            */}
        </>
    )
}
