import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorization } from "~/api/prepareHeaders"
import { vatRateFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/vatRateFromApiAdapter"
import { VatRateI } from "~/domains/transactions/invoices-v1/types/VatRate"
import { parseTransactionVatRate } from "~/domains/transactions/invoices-v1/types/vatApi.types"
import { CountryCode } from "~/types"
import { getResultSuccessValue, isResultSuccess } from "~/types/Result"

const BASE_URL = import.meta.env.VITE_API_TRANSACTION_V2_INVOICES_URL

export const vatApiV1 = createApi({
    reducerPath: "vatApi",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: prepareHeadersWithAuthorization,
    }),
    endpoints: (builder) => ({
        getTaxDefinitions: builder.query<VatRateI[], string>({
            query: (countryCode: CountryCode) => ({
                url: "tax-definitions",
                params: { country: countryCode },
            }),
            transformResponse: (response: unknown): VatRateI[] => {
                if (!Array.isArray(response)) {
                    return []
                }
                return response
                    .map(parseTransactionVatRate)
                    .filter(isResultSuccess)
                    .map(getResultSuccessValue)
                    .map(vatRateFromApiAdapter)
            },
        }),
    }),
})

export const { useGetTaxDefinitionsQuery, useLazyGetTaxDefinitionsQuery } = vatApiV1
