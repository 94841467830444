import { BudgetId } from "~/domains/transactions/budget/types"
import {
    /*OrganizationId, */
    TeamId,
    UserId,
} from "~/types"

import { ApiWorkflowId } from "./ApiWorkflow"

export enum WorkflowBlockType {
    START_BLOCK = "START_BLOCK",
    CHECK_CONDITION_BLOCK = "CHECK_CONDITION_BLOCK",
    BRANCH_CONDITION_BLOCK = "BRANCH_CONDITION_BLOCK",
    BRANCH_BLOCK = "BRANCH_BLOCK",
    VALIDATION_BLOCK = "VALIDATION_BLOCK",
    LINE_CHECK_CONDITION_BLOCK = "LINE_CHECK_CONDITION_BLOCK",
    BUDGET_NODE = "BUDGET_NODE",

    PLACEHOLDER_BLOCK = "PLACEHOLDER_BLOCK",
    UNKNOWN = "UNKNOWN",
}

export enum WorkflowTriggerType {
    InvoiceReceived = "INVOICE RECEIVED",
    PurchaseRequestReceived = "PURCHASE REQUEST RECEIVED",
}

export enum WorkflowFilterStringOperation {
    EQUAL = "EQUAL",
    NOT_EQUAL = "NOT_EQUAL",
}

export enum WorkflowFilterListOperation {
    CONTAINS = "CONTAINS",
    DOES_NOT_CONTAIN = "DOES_NOT_CONTAIN",
}

export enum WorkflowFilterReferenceOperation {
    IS = "IS",
    IS_NOT = "IS_NOT",
}

export enum WorkflowFilterTagsOperation {
    CONTAINS_ANY_OF = "CONTAINS_ANY_OF", // is true as long as at least one of the tags in the condition is present on the invoice
    CONTAINS_NONE_OF = "CONTAINS_NONE_OF", // is true only if none of the tags in the condition are on the invoice
    CONTAINS_ALL_OF = "CONTAINS_ALL_OF", // is true only if all of the tags in the condition are on the invoice
}

export enum WorkflowFilterNumberOperation {
    LESS_THAN = "LESS_THAN",
    LESS_THAN_OR_EQUAL_TO = "LESS_THAN_OR_EQUAL_TO",
    EQUAL = "EQUAL",
    GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO",
    GREATER_THAN = "GREATER_THAN",
    DOES_NOT_EQUAL = "DOES_NOT_EQUAL",
}

export enum WorkflowFilterUnknownOperation {
    UNKNOWN = "UNKNOWN",
}

// export enum WorkflowFilterDateOperation {
//     OLDER_THAN = "OLDER_THAN",
//     WEEKDAY_IS = "WEEKDAY_IS",
// }

export type WorkflowFilterOperation =
    | WorkflowFilterStringOperation
    | WorkflowFilterNumberOperation
    | WorkflowFilterListOperation
    | WorkflowFilterReferenceOperation
    | WorkflowFilterTagsOperation
    | WorkflowFilterUnknownOperation
// | WorkflowFilterDateOperation

export enum FilterFieldProperty {
    AMOUNT = "AMOUNT",
    LINE_AMOUNT = "LINE_AMOUNT",
    SUPPLIER = "SUPPLIER",
    TAGS = "TAGS",
    TAGS_V2 = "TAGS_V2",
    AUTHOR = "AUTHOR",
    UNKNOWN = "UNKNOWN",
}

export interface WorkflowFilterI {
    propertyType: FilterFieldType
    property: FilterFieldProperty
    operation: WorkflowFilterOperation
    value?: string | number | string[] | null
}

export interface WorkflowInvoiceFilter extends WorkflowFilterI {
    operation: WorkflowFilterOperation
}

// export interface WorkflowInvoiceStatusFilter extends WorkflowInvoiceFilter {
//     property: "reference"
//     operation: WorkflowFilterStringOperation
// }

// export interface WorkflowInvoiceSupplierFilter extends WorkflowInvoiceFilter {
//     property: "supplier"
//     operation: WorkflowFilterStringOperation
// }

export interface WorkflowInvoiceAmountFilter extends WorkflowInvoiceFilter {
    property: FilterFieldProperty.AMOUNT
    operation: WorkflowFilterNumberOperation
}

// export interface WorkflowInvoiceDateFilter extends WorkflowInvoiceFilter {
//     property: "dueDate"
//     operation: WorkflowFilterDateOperation
// }

interface WorkflowTriggerI {
    type: WorkflowTriggerType
    filters: WorkflowFilterI[]
}

export type WorkflowTrigger = WorkflowTriggerI & {
    type: WorkflowTriggerType
    // filters: WorkflowInvoiceFilter[]
}

export type WorkflowStarterBlockI = {
    id: string
    type: WorkflowBlockType.START_BLOCK
    child: WorkflowBlockI
    trigger: WorkflowTrigger | null
}

export enum FilterFieldType {
    STRING_CONDITION = "STRING_CONDITION",
    NUMERICAL_CONDITION = "NUMERICAL_CONDITION",
    TAG_CONDITION = "TAG_CONDITION",
}

export enum WorkflowFilterType {
    NUMERICAL_CONDITION = "NUMERICAL_CONDITION",
    REFERENCE_CONDITION = "REFERENCE_CONDITION",
    LIST_CONDITION = "LIST_CONDITION",
    STRING_CONDITION = "STRING_CONDITION",
}

export enum WorkflowSelectionUsage {
    MODIFICATION = "MODIFICATION",
    DELETION = "DELETION",
}

export type WorkflowFilterTypes =
    | {
          field: FilterFieldProperty.AMOUNT
          fieldType: WorkflowFilterType.NUMERICAL_CONDITION
      }
    | {
          field: FilterFieldProperty.LINE_AMOUNT
          fieldType: WorkflowFilterType.NUMERICAL_CONDITION
      }
    | {
          field: FilterFieldProperty.SUPPLIER
          fieldType: WorkflowFilterType.REFERENCE_CONDITION
      }
    | {
          field: FilterFieldProperty.TAGS
          fieldType: WorkflowFilterType.LIST_CONDITION
      }
    | {
          field: FilterFieldProperty.TAGS_V2
          fieldType: WorkflowFilterType.REFERENCE_CONDITION
      }
    | {
          field: FilterFieldProperty.AUTHOR
          fieldType: WorkflowFilterType.REFERENCE_CONDITION
      }
    | {
          field: FilterFieldProperty.UNKNOWN
          fieldType: WorkflowFilterType.STRING_CONDITION
      }

export type ReferenceConditionFieldType =
    | FilterFieldProperty.SUPPLIER
    | FilterFieldProperty.AUTHOR
    | FilterFieldProperty.TAGS_V2
export type SingleReferenceConditionFieldType = FilterFieldProperty.TAGS

export type CreateWorkflowBlockI =
    | {
          type:
              | WorkflowBlockType.BRANCH_CONDITION_BLOCK
              | WorkflowBlockType.BRANCH_BLOCK
              | WorkflowBlockType.CHECK_CONDITION_BLOCK
              | WorkflowBlockType.LINE_CHECK_CONDITION_BLOCK
              | WorkflowBlockType.UNKNOWN
          validationType?: never
          action?: never
      }
    | {
          type: WorkflowBlockType.VALIDATION_BLOCK
          validationType: WorkflowValidationType
          action?: never
      }
    | {
          type: WorkflowBlockType.BUDGET_NODE
          action: WorkflowBudgetAction
          validationType?: never
      }

export type CreateWorkflowBlockWithIdI = CreateWorkflowBlockI & {
    id: string
}

export enum WorkflowMatchType {
    ALL = "ALL",
    ANY = "ANY",
}

export type WorkflowOldConditionI = {
    conditions: WorkflowFilterI[]
    child: WorkflowBlockI
    matchType: WorkflowMatchType
}

export type WorkflowBranchConditionBlockI = {
    id: string
    type: WorkflowBlockType.BRANCH_CONDITION_BLOCK
    conditionGroups: WorkflowOldConditionI[]
}

export type WorkflowBranchGroupI = {
    conditions: WorkflowFilterI[]
}

export type WorkflowBranchI = {
    name?: string
    groups: WorkflowBranchGroupI[]
    child: WorkflowBlockI
}

export type WorkflowBranchBlockI = {
    id: string
    type: WorkflowBlockType.BRANCH_BLOCK
    branches: WorkflowBranchI[]
}

export enum WorkflowReviewersTypeI {
    USER = "USER",
    TEAM = "TEAM",
}

export enum WorkflowValidationType {
    REJECTION = "REJECTION",
    VALIDATION = "VALIDATION",
}

export type WorkflowTeamReviewersI = {
    type: WorkflowReviewersTypeI.TEAM
    id: TeamId
}

export type WorkflowUserReviewersI = {
    type: WorkflowReviewersTypeI.USER
    id: UserId
}

export type WorkflowReviewersI = WorkflowTeamReviewersI | WorkflowUserReviewersI

export type ConditionBlockI = {
    reviewers: WorkflowReviewersI[]
    thresholdNumber: number
}

export type WorkflowCheckConditionBlockI = {
    id: string
    type: WorkflowBlockType.CHECK_CONDITION_BLOCK
    condition: ConditionBlockI
    accepted: WorkflowBlockI | null
    refused: WorkflowBlockI | null
}

export type WorkflowLineConditionGroup = {
    conditions: WorkflowFilterI[]
    matchType: WorkflowMatchType
    lineApproveCondition: ConditionBlockI
}

export type WorkflowLineCheckConditionBlockI = {
    id: string
    type: WorkflowBlockType.LINE_CHECK_CONDITION_BLOCK
    child: WorkflowBlockI | null
    conditionGroups: WorkflowLineConditionGroup[]
}

export type WorkflowValidationBlockI = {
    id: string
    type: WorkflowBlockType.VALIDATION_BLOCK
    validationType: WorkflowValidationType
}

export enum WorkflowBudgetAction {
    ADD_TO_BUDGET = "ADD_TO_BUDGET",
    UNKNOWN = "UNKNOWN",
}

export type WorkflowBudgetBlockI = {
    id: string
    type: WorkflowBlockType.BUDGET_NODE
    budgetId: BudgetId
    action: WorkflowBudgetAction
    child: WorkflowBlockI | null
}

export type WorkflowPlaceholderBlockI = {
    id: string
    type: WorkflowBlockType.PLACEHOLDER_BLOCK
}

export type WorkflowUnknownBlockI = {
    id: string
    type: WorkflowBlockType.UNKNOWN
}

export type WorkflowBlockI =
    | WorkflowCheckConditionBlockI
    | WorkflowBranchConditionBlockI
    | WorkflowBranchBlockI
    | WorkflowValidationBlockI
    | WorkflowLineCheckConditionBlockI
    | WorkflowBudgetBlockI
    | WorkflowPlaceholderBlockI
    | WorkflowUnknownBlockI
export type AnyWorkflowBlockI = WorkflowStarterBlockI | WorkflowBlockI

export enum WorkflowType {
    INVOICE = "INVOICE",
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    UNKNOWN = "UNKNOWN",
}

export type WorkflowI = {
    id: ApiWorkflowId
    type: WorkflowType
    workflowName: string
    starter: WorkflowStarterBlockI
    enabled: boolean
}

export interface WorkflowApprovePayload {
    invoiceId: string
    workflowId: string
}

export interface WorkflowReviewsI {
    reviewers: WorkflowReviewersI[]
    approvals: WorkflowReviewersI[]
    refusals: WorkflowReviewersI[]
    workflowIds: string[]
}
