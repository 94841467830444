import { useCallback } from "react"
import { defineMessages, useIntl } from "react-intl"
import { useDispatch } from "react-redux"

import { userApi } from "~/domains/identity/account/api/userApi"
import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api"
import {
    purchaseOrdersActions,
    selectPurchaseOrders,
    selectPurchaseOrdersError,
    selectPurchaseOrdersLoading,
} from "~/domains/transactions/purchase-orders/store/purchaseOrdersSlice"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId, ViewTypeI } from "~/types"
import { fetchEntitiesInChunks } from "~/utils/fetchEntitiesInChunks"

const messages = defineMessages({
    error: {
        id: "purchase.orders.list.errorFetch",
        defaultMessage: "We can't retrieve the purchase orders. Please contact your administrator",
    },
})

export const useFetchOrganizationPurchaseOrders = (view?: ViewTypeI | string, organizationId?: OrganizationId) => {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()
    const purchaseOrders = useAppSelector(selectPurchaseOrders)
    const loading = useAppSelector(selectPurchaseOrdersLoading)
    const error = useAppSelector(selectPurchaseOrdersError)

    const fetchPurchaseOrders = useCallback(
        async (withNames: boolean = true) => {
            if (!organizationId) return

            try {
                dispatch(purchaseOrdersActions.fetchPOs())
                const purchaseOrders = await purchaseOrdersApi.findAll(organizationId)

                if (withNames) {
                    const { organizationIdsWithDuplicates, userIdsWithDuplicates } = purchaseOrders.reduce<{
                        organizationIdsWithDuplicates: Set<string>
                        userIdsWithDuplicates: Set<string>
                    }>(
                        (acc, po) => {
                            if (po.buyerId) acc.organizationIdsWithDuplicates.add(po.buyerId)
                            if (po.supplierId) acc.organizationIdsWithDuplicates.add(po.supplierId)
                            if (po.requesterUserId) acc.userIdsWithDuplicates.add(po.requesterUserId)
                            return acc
                        },
                        {
                            organizationIdsWithDuplicates: new Set<string>(),
                            userIdsWithDuplicates: new Set<string>(),
                        }
                    )

                    // Convert sets to arrays
                    const organizationIds = Array.from(organizationIdsWithDuplicates)
                    const userIds = Array.from(userIdsWithDuplicates)

                    // Fetch organizations and users in chunks
                    const organizations = organizationIds.length
                        ? await fetchEntitiesInChunks(organizationIds, organizationApi.fetchOrganizationsByIds)
                        : []
                    const users = userIds.length ? await fetchEntitiesInChunks(userIds, userApi.findUsersByIds) : []

                    // Enrich purchase orders
                    const POWithNames = purchaseOrders.map((po) => {
                        const supplier = organizations.find((organization) => organization.id === po.supplierId)
                        const buyer = organizations.find((organization) => organization.id === po.buyerId)
                        const user = users.find((user) => user.id === po.requesterUserId)
                        return {
                            ...po,
                            supplierName: supplier?.name ?? "",
                            buyerName: buyer?.name ?? "",
                            requesterName: user?.fullName ?? "",
                        }
                    })

                    // Filter purchase orders based on view
                    const POWithNamesFiltered = view
                        ? POWithNames.filter((po) => {
                              if (view === ViewTypeI.supplier) {
                                  return po.supplierId === organizationId
                              }
                              return po.buyerId === organizationId
                          })
                        : POWithNames

                    dispatch(purchaseOrdersActions.fetchPOsSuccess(POWithNamesFiltered))
                    return POWithNamesFiltered
                } else {
                    const POWithFakeNames = purchaseOrders.map((po) => {
                        return {
                            ...po,
                            supplierName: "",
                            buyerName: "",
                            requesterName: "",
                        }
                    })

                    dispatch(purchaseOrdersActions.fetchPOsSuccess(POWithFakeNames))
                    return POWithFakeNames
                }
            } catch (error) {
                dispatch(purchaseOrdersActions.fetchPOsFailed(formatMessage(messages.error)))
            }
        },
        [organizationId, view, dispatch, formatMessage]
    )

    return { purchaseOrders, loading, error, fetchPurchaseOrders }
}
