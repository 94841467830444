import { Box, Stack, styled } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { Loader, TooltipWhite } from "~/components"
import { useGetPaymentMethodDetailsScoreQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { CurrencyCodes } from "~/types"

const messages = defineMessages({
    totalAmount: {
        id: "payment.paymentMethodDetails.lineScore.totalAmount",
        defaultMessage: "Total amount",
    },
    totalPayments: {
        id: "payment.paymentMethodDetails.lineScore.totalPayments",
        defaultMessage: "Total payments",
    },
    successfulPayments: {
        id: "payment.paymentMethodDetails.lineScore.successfulPayments",
        defaultMessage: "Successful payments",
    },
    lastPaymentDate: {
        id: "payment.paymentMethodDetails.lineScore.lastPaymentDate",
        defaultMessage: "Last payment date",
    },
})

const ItemLabel = styled("div")({
    fontSize: "var(--font-size-sm)",
    color: "var(--color-grey-light)",
})

const ItemValue = styled("div")({
    fontSize: "var(--font-size-md)",
    color: "var(--color-grey)",
})

const TrustScore = styled("div")<TrustScoreProps>(({ score }) => ({
    padding: "3px 5px",
    borderRadius: "var(--border-radius-xs)",
    color: score && score < 50 ? "var(--color-red)" : "var(--color-green)",
    backgroundColor: score && score < 50 ? "var(--color-red-lighter)" : "var(--color-green-lighter)",
}))

interface TrustScoreProps {
    score?: number
}

interface LineScoreProps {
    paymentMethodDetailsId: string
    currency: CurrencyCodes | null
}

export const LineScore = ({ paymentMethodDetailsId, currency }: LineScoreProps) => {
    const { formatDate, formatMessage, formatNumber } = useIntl()
    const { data, isLoading } = useGetPaymentMethodDetailsScoreQuery(paymentMethodDetailsId, {
        skip: !paymentMethodDetailsId,
    })

    if (isLoading) {
        return <Loader small />
    }

    if (!data || !data.trust_score) {
        return "-"
    }

    const { total_amount, total_payments, trust_score, successful_payments, last_payment_date } = data

    return (
        <TooltipWhite
            arrow
            title={
                <Stack spacing={1}>
                    <Box component="div">
                        <ItemLabel>{formatMessage(messages.totalAmount)}</ItemLabel>
                        <ItemValue>
                            {total_amount
                                ? `${formatNumber(total_amount, {
                                      style: "currency",
                                      currency: currency || CurrencyCodes.EUR,
                                  })}`
                                : "-"}
                        </ItemValue>
                    </Box>
                    <Box component="div">
                        <ItemLabel>{formatMessage(messages.totalPayments)}</ItemLabel>
                        <ItemValue>{total_payments || "-"}</ItemValue>
                    </Box>
                    <Box component="div">
                        <ItemLabel>{formatMessage(messages.successfulPayments)}</ItemLabel>
                        <ItemValue>{successful_payments || "-"}</ItemValue>
                    </Box>
                    <Box component="div">
                        <ItemLabel>{formatMessage(messages.lastPaymentDate)}</ItemLabel>
                        <ItemValue>{last_payment_date ? formatDate(last_payment_date) : "-"}</ItemValue>
                    </Box>
                </Stack>
            }
        >
            <TrustScore score={trust_score}>{trust_score}/100</TrustScore>
        </TooltipWhite>
    )
}
