import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import {
    CustomForm,
    CustomFormSurvey,
    SendFormResponse,
    UserSurveyApiResponse,
    UserSurveyState,
    UserSurveyStats,
} from "~/domains/identity/custom-forms/types/CustomForms"

const BASE_URL = import.meta.env.VITE_API_CUSTOM_FORMS_URL

export const customFormsApi = createApi({
    reducerPath: "customFormsApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["OrganisationCustomForms", "CustomForm", "PartnerCustomForms", "CustomFormStats", "SurveyState"],
    endpoints: (builder) => ({
        getOrganisationCustomForms: builder.query<CustomForm[], { organisationId: string }>({
            query: ({ organisationId }) => ({
                url: `${organisationId}/forms/`,
            }),
            providesTags: ["OrganisationCustomForms"],
        }),
        getFormByToken: builder.query<UserSurveyApiResponse, { formToken: string }>({
            query: ({ formToken }) => ({
                url: `user-surveys/${formToken}/`,
            }),
        }),
        sendFormToPartner: builder.mutation<
            SendFormResponse,
            {
                organisationId: string
                formId: string
                respondentContacts: Array<{ email: string; userId: string }>
                respondentOrganizationId: string
                dueDate?: string
            }
        >({
            query: ({ organisationId, formId, respondentContacts, respondentOrganizationId, dueDate }) => ({
                url: `${organisationId}/forms/${formId}/surveys`,
                method: "POST",
                body: { respondentContacts, respondentOrganizationId, dueTimestamp: dueDate },
            }),
        }),
        getCustomForm: builder.query<CustomForm, { organisationId: string; formId: string }>({
            query: ({ organisationId, formId }) => ({
                url: `${organisationId}/forms/${formId}`,
            }),
            providesTags: ["CustomForm"],
        }),
        getCustomFormSurveys: builder.query<CustomFormSurvey[], { organisationId: string; formId: string }>({
            query: ({ organisationId, formId }) => ({
                url: `${organisationId}/forms/${formId}/surveys`,
            }),
            providesTags: ["CustomForm"],
        }),
        getCustomFormSurveysForPartner: builder.query<
            CustomFormSurvey[],
            { organisationId: string; respondentOrganizationId: string }
        >({
            query: ({ organisationId, respondentOrganizationId }) => ({
                url: `${organisationId}/forms/surveys/?respondent-organization-id=${respondentOrganizationId}`,
            }),
            providesTags: ["PartnerCustomForms"],
        }),
        saveUserAnswer: builder.mutation<
            { success: string },
            { surveyToken: string; questionId: string; answer: unknown }
        >({
            query: ({ surveyToken, questionId, answer }) => ({
                url: `user-surveys/${surveyToken}/answers`,
                method: "POST",
                body: { questionId, answer },
            }),
            invalidatesTags: ["CustomForm", "SurveyState"],
        }),
        getCustomFormStats: builder.query<
            UserSurveyStats[],
            { organisationId: string; formId?: string; partnerOrganisationId?: string }
        >({
            query: ({ organisationId, formId, partnerOrganisationId }) => {
                const params = new URLSearchParams()
                // Conditionally add query params
                if (formId) params.append("form-id", formId)
                if (partnerOrganisationId) params.append("respondent-organization-id", partnerOrganisationId)

                return {
                    url: `${organisationId}/forms/surveys/stats?${params.toString()}`,
                }
            },
            providesTags: ["CustomFormStats"],
        }),
        getSurveyState: builder.query<UserSurveyState, { surveyToken: string }>({
            query: ({ surveyToken }) => ({
                url: `user-surveys/${surveyToken}/state`,
            }),
            providesTags: ["SurveyState"],
        }),
        completeForm: builder.mutation<{ success: string }, { surveyToken: string }>({
            query: ({ surveyToken }) => ({
                url: `user-surveys/${surveyToken}/completion`,
                method: "POST",
            }),
            invalidatesTags: ["CustomForm"],
        }),
    }),
})

export const {
    useGetOrganisationCustomFormsQuery,
    useGetFormByTokenQuery,
    useSendFormToPartnerMutation,
    useGetCustomFormQuery,
    useGetCustomFormSurveysQuery,
    useSaveUserAnswerMutation,
    useGetCustomFormSurveysForPartnerQuery,
    useGetCustomFormStatsQuery,
    useGetSurveyStateQuery,
    useCompleteFormMutation,
} = customFormsApi
