import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import {
    useApproveObjectMutation,
    useGetObjectChecksQuery,
    useRefusalReasonsQuery,
    useRefuseObjectMutation,
    useRetractAllReviewsMutation,
    useRetractReviewMutation,
} from "~/domains/orchestration/flows-v0/api/approvalApi"
import { RefusalReasonBody } from "~/domains/orchestration/flows-v0/types"
import {
    ApprovalApiResponse,
    ApprovalObjectType,
    ApprovalResult,
    ApprovalReviewerI,
} from "~/domains/orchestration/flows-v0/types/Approval"
import { selectUserId } from "~/store/account/accountSlice"
import { useAppSelector } from "~/store/hooks"
import { useFetchOrganizationTeams } from "~/store/organization/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { OrganizationTeamI } from "~/types"

interface UseApprovalReviewProps {
    organisationId: string
    objectId: string
    objectType: ApprovalObjectType
    onReviewed?: (approved: boolean) => void
    onApproved?: () => void
    onRefused?: () => void
    onRetract?: () => void
    onRetractAll?: () => void
    onApprovalRequired?: () => void
}

const HTTP_STATUS_NO_CONTENT = 204

const isPartnerPaymentDetails = (pathname: string) => {
    return pathname.includes("partners") && pathname.includes("payment-details")
}

const isUserReviewer = (reviewer: ApprovalReviewerI) => {
    return reviewer.type === "User" && reviewer.userId !== undefined
}

const isTeamReviewer = (reviewer: ApprovalReviewerI) => {
    return reviewer.type === "Team" && reviewer.teamId !== undefined
}

const isCurrentUserReviewer = (reviewers: ApprovalReviewerI[], userId: string, teams: OrganizationTeamI[]) =>
    reviewers.some(
        (reviewer) =>
            (isUserReviewer(reviewer) && reviewer.userId === userId) ||
            (isTeamReviewer(reviewer) && teams?.some((team) => team.teamId === reviewer.teamId))
    )
const isCurrentUserReviewRequired = (reviewers: ApprovalReviewerI[], userId: string, teams: OrganizationTeamI[]) => {
    return reviewers.some(
        (reviewer) =>
            (isUserReviewer(reviewer) && reviewer.userId === userId) ||
            (isTeamReviewer(reviewer) &&
                teams?.some(
                    (team) => team.teamId === reviewer.teamId && team.members.some((member) => member.userId === userId)
                ))
    )
}
export const useApprovalReview = ({
    organisationId,
    objectId,
    objectType,
    onReviewed,
    onApproved,
    onRefused,
    onRetract,
    onRetractAll,
    onApprovalRequired,
}: UseApprovalReviewProps) => {
    const [loading, setLoading] = useState(true)
    const [isReviewing, setIsReviewing] = useState(false)
    const [reviewed, setReviewed] = useState(false)
    const [userIds, setUsersIds] = useState<string[]>([])
    const [approvalResult, setApprovalResult] = useState(ApprovalResult.PENDING)
    const [isUserChecks, setIsUserChecks] = useState(true)
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId) || ""
    const location = useLocation()
    const userId = useAppSelector(selectUserId)

    const isPartnerPaymentDetailApproval = isPartnerPaymentDetails(location.pathname)
    const reviewersId = isPartnerPaymentDetailApproval ? currentOrganizationId : organisationId

    const { users } = useGetAllUsersQuery(userIds)
    const { teams } = useFetchOrganizationTeams(reviewersId, true)

    const tempObjectType = isPartnerPaymentDetailApproval
        ? ApprovalObjectType.PARTNER_PAYMENT_METHOD_DETAILS
        : objectType

    const checkParams = {
        organisationId: isPartnerPaymentDetailApproval ? currentOrganizationId : organisationId,
        objectId,
        objectType: tempObjectType,
    }

    const { data: progressResponse } = useGetObjectChecksQuery(
        { ...checkParams, userChecks: isUserChecks },
        {
            skip: !objectId,
            refetchOnMountOrArgChange: true,
        }
    )

    const { data: allReasons = { customReasons: [], defaultReasons: [] } } = useRefusalReasonsQuery(checkParams, {
        skip: !objectId,
    })

    const { customReasons, defaultReasons } = allReasons
    const refusalReasons = customReasons.length > 0 ? customReasons : defaultReasons

    const [approveObject] = useApproveObjectMutation()
    const [refuseObject] = useRefuseObjectMutation()

    const [retractReview, { isLoading: refuseLoading }] = useRetractReviewMutation()
    const [retractAllReviews, { isLoading: retractAllLoading }] = useRetractAllReviewsMutation()

    const alreadyReviewed = useCallback(
        (response: ApprovalApiResponse) => {
            return response.checks.some((check) =>
                [...check.review.approvers, ...check.review.refusers].some((reviewer) => reviewer.userId === userId)
            )
        },
        [userId]
    )

    const isApprovalRequired = progressResponse
        ? progressResponse.checks.some((check) => isCurrentUserReviewer(check.reviewers, userId, teams))
        : false

    const isCurrentUserApprovalRequired = progressResponse
        ? progressResponse.checks.some((check) => isCurrentUserReviewRequired(check.reviewers, userId, teams))
        : false

    useEffect(() => {
        if (progressResponse) {
            const ids = progressResponse.checks.flatMap((check) => check.reviewers).map((reviewer) => reviewer.userId)
            setUsersIds(ids.filter((id) => id !== undefined))
            if (onApprovalRequired && isApprovalRequired) {
                onApprovalRequired()
            }
        }
    }, [progressResponse, isApprovalRequired, onApprovalRequired])

    useEffect(() => {
        if (userId && progressResponse) {
            setLoading(false)
            setReviewed(alreadyReviewed(progressResponse))
        }
    }, [progressResponse, userId, alreadyReviewed])

    const handleApprove = async () => {
        setIsReviewing(true)
        const response = await approveObject({ objectId, objectType: tempObjectType }).unwrap()
        if (response === HTTP_STATUS_NO_CONTENT) {
            setApprovalResult(ApprovalResult.APPROVED)
            onApproved?.()
            setIsReviewing(false)
            setReviewed(true)
            onReviewed?.(true)
        }
    }

    const handleUpdateApproval = async () => {
        const response = await retractReview({ objectId, objectType }).unwrap()
        if (response === HTTP_STATUS_NO_CONTENT) {
            onRetract?.()
        }
        setReviewed(false)
        setApprovalResult(ApprovalResult.PENDING)
    }

    const handleRetractAll = async () => {
        const response = await retractAllReviews({ objectId, objectType }).unwrap()
        if (response === HTTP_STATUS_NO_CONTENT) {
            onRetractAll?.()
        }
        setReviewed(false)
        setApprovalResult(ApprovalResult.PENDING)
    }

    const handleRefuse = async (body?: RefusalReasonBody) => {
        setIsReviewing(true)

        const response = await refuseObject({
            objectId,
            objectType: tempObjectType,
            body,
        }).unwrap()

        if (response === HTTP_STATUS_NO_CONTENT) {
            setApprovalResult(ApprovalResult.REFUSED)
            onRefused?.()
            setIsReviewing(false)
            setReviewed(true)
            onReviewed?.(false)
        }
    }

    const handleUserChecks = useCallback(() => {
        setIsUserChecks((prev) => !prev)
    }, [])

    return {
        loading,
        isReviewing,
        isUserChecks,
        reviewed,
        approvalResult,
        progressResponse,
        users,
        teams,
        refuseLoading,
        retractAllLoading,
        refusalReasons,
        handleApprove,
        handleRefuse,
        handleUpdateApproval,
        handleRetractAll,
        handleUserChecks,
        setApprovalResult,
        isApprovalRequired,
        isCurrentUserApprovalRequired,
    }
}
