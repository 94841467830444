import React from "react"

import { BulkActionButton } from "~/components/BulkActions/BulkActions"
import { BulkButtonContent } from "~/components/BulkActions/BulkButtonContent"
import { Button } from "~/components/Button/Button"

interface BulkButtonProps {
    button: BulkActionButton
    selected: string[]
    loading?: boolean
}

export const BulkButton: React.FC<BulkButtonProps> = ({ button, selected, loading }) => {
    const { type, hasPermission, onClick } = button
    const disabled = loading || typeof hasPermission === "undefined" ? false : !hasPermission

    const handleClick = () => {
        onClick && onClick(selected)
    }

    return (
        <Button
            sx={{ padding: "2px 12px", minHeight: "40px" }}
            disabled={disabled}
            type={type ?? "primary-light"}
            onClick={handleClick}
        >
            <BulkButtonContent button={button} />
        </Button>
    )
}
