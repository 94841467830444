import { Bar, Container } from "@column-resizer/react"
import { useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"
import React, { Fragment, ReactElement, useState } from "react"
import { useSelector } from "react-redux"

import { ResizableGridContext } from "~/components/Layout/ResizableGridContext"
import { RootState } from "~/store"
import { selectResizableGridState } from "~/store/global/globalSlice"

import { ResizableColProps } from "./ResizableCol"

interface ResizableGridProps {
    children: ReactElement<ResizableColProps>[]
    stateKey: string
}

export const ResizableGrid = ({ children, stateKey }: ResizableGridProps) => {
    const theme = useTheme()
    const [isResizing, setIsResizing] = useState(false)

    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))

    const resizableGridState = useSelector((state: RootState) => selectResizableGridState(state, stateKey))

    if (!children?.length) return null

    return isMobile ? (
        <>
            {children.map((child, index) => (
                <Fragment key={index}>{child}</Fragment>
            ))}
        </>
    ) : (
        <ResizableGridContext.Provider value={{ stateKey }}>
            <Container className="w-full">
                {children.map((child, index) => (
                    <Fragment key={index}>
                        {React.cloneElement(child, {
                            defaultSize: resizableGridState?.[index],
                        })}
                        {index < children.length - 1 && (
                            <Bar
                                onStatusChanged={setIsResizing}
                                className={classNames("resizer-bar", isResizing && "resizer-bar-active")}
                            />
                        )}
                    </Fragment>
                ))}
            </Container>
        </ResizableGridContext.Provider>
    )
}
