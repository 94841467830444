import { SpiceDBAuthorizationName } from "~/domains/identity/roles-permissions/types/SpiceDB"

export enum AuthorizationName {
    READ = "read",
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
}

export enum AuthorizationNameExtra {
    EDIT = "edit",
    APPROVE = "approve",
    CONVERT_TO_PO = "convertToPurchaseOrder",
    CONVERT = "convert",
    CHECK = "check",
    SUBMIT = "submit",
}

export type SpiceDBPermissionsResult = {
    [key in AuthorizationName | AuthorizationNameExtra]?: boolean
}

export type UnifiedAuthorizationName = AuthorizationName | AuthorizationNameExtra | SpiceDBAuthorizationName
