import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import fileDownload from "js-file-download"

import { prepareHeadersWithAuthorization } from "~/api/prepareHeaders"
import {
    assignedObjectFromApiAdapter,
    assignedObjectToApiAdapter,
    paymentBatchFromApiAdapter,
    paymentBatchInstanceFromApiAdapter,
    paymentBatchToApiAdapter,
} from "~/domains/payment/payment-batches/api/adapters"
import {
    AssignedObject,
    PaymentBatch,
    PaymentBatchInstance,
    ServerAssignedObject,
    ServerPaymentBatch,
    ServerPaymentBatchInstance,
} from "~/domains/payment/payment-batches/types"
import { GridQueryParams, PaginatedResponse, XMLVersion } from "~/domains/payment/types"
import { toSnakeCase } from "~/utils/string"

const BASE_URL = import.meta.env.VITE_API_PAYMENT_URL_V2

const paymentBatchesTags = ["PaymentBatches"]
const paymentBatchInstancesTags = ["PaymentBatchInstances"]
const paymentBatchAssignedObjectsTags = ["PaymentBatchAssignedObjects"]

const tags = [...paymentBatchesTags, ...paymentBatchInstancesTags, ...paymentBatchAssignedObjectsTags]

type AddObjectToBatch = {
    assignedObject: AssignedObject
    batchId: string
}

export const paymentBatchesApi = createApi({
    reducerPath: "paymentBatchesApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders: prepareHeadersWithAuthorization }),
    tagTypes: tags,
    endpoints: (builder) => ({
        getPaymentBatchesByOrganization: builder.query<PaginatedResponse<PaymentBatch>, unknown>({
            query: ({ organizationId }) => ({
                url: `/payment_transaction_batch/by_organization/${organizationId}`,
            }),
            providesTags: paymentBatchesTags,
            transformResponse: (response: PaginatedResponse<ServerPaymentBatch>) => {
                return {
                    ...response,
                    items: response.items.map(paymentBatchFromApiAdapter),
                } as PaginatedResponse<PaymentBatch>
            },
        }),
        createPaymentBatch: builder.mutation<PaymentBatch, Partial<PaymentBatch>>({
            query: (payment: PaymentBatch) => ({
                url: "/payment_transaction_batch",
                method: "POST",
                body: paymentBatchToApiAdapter(payment),
            }),
            invalidatesTags: paymentBatchesTags,
            transformResponse: (response: ServerPaymentBatch) => paymentBatchFromApiAdapter(response),
            transformErrorResponse: (response) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
        updatePaymentBatch: builder.mutation<PaymentBatch, Partial<PaymentBatch>>({
            query: (payment: PaymentBatch) => ({
                url: `/payment_transaction_batch/${payment.id}`,
                method: "PUT",
                body: paymentBatchToApiAdapter(payment),
            }),
            invalidatesTags: paymentBatchesTags,
            transformResponse: (response: ServerPaymentBatch) => paymentBatchFromApiAdapter(response),
            transformErrorResponse: (response) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
        deletePaymentBatch: builder.mutation({
            query: (id: string) => ({
                url: `/payment_transaction_batch/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: paymentBatchesTags,
            transformErrorResponse: (response) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
        getPaymentBatchInstancesByOrganization: builder.query<PaginatedResponse<PaymentBatchInstance>, GridQueryParams>(
            {
                query: ({ organizationId, sortBy, sortOrder, page, pageSize }) => ({
                    url: `/payment_transaction_batch_instance`,
                    params: {
                        owner_id: organizationId,
                        ...(sortBy && { sort_by: toSnakeCase(sortBy) }),
                        ...(sortOrder && { sort_order: sortOrder }),
                        ...(page && { page }),
                        ...(pageSize && { page_size: pageSize }),
                    },
                }),
                providesTags: paymentBatchInstancesTags,
                transformResponse: (response: PaginatedResponse<ServerPaymentBatchInstance>) => {
                    return {
                        ...response,
                        items: response.items.map(paymentBatchInstanceFromApiAdapter),
                    } as PaginatedResponse<PaymentBatchInstance>
                },
            }
        ),
        getPaymentBatchInstance: builder.query<PaymentBatchInstance, unknown>({
            query: (id: string) => ({
                url: `/payment_transaction_batch_instance/${id}`,
            }),
            providesTags: paymentBatchInstancesTags,
            transformResponse: (response: ServerPaymentBatchInstance) => paymentBatchInstanceFromApiAdapter(response),
        }),
        updateBatchInstance: builder.mutation<PaymentBatchInstance, Partial<PaymentBatchInstance>>({
            query: (payment: PaymentBatchInstance) => ({
                url: `/payment_transaction_batch_instance/${payment.id}`,
                method: "PUT",
                body: payment,
            }),
            invalidatesTags: paymentBatchInstancesTags,
            transformResponse: (response: ServerPaymentBatchInstance) => paymentBatchInstanceFromApiAdapter(response),
            transformErrorResponse: (response) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
        executeAllPayments: builder.mutation({
            query: (id: string) => ({
                url: `/payment_transaction_batch_instance/${id}/execute_payments`,
                method: "POST",
            }),
            invalidatesTags: paymentBatchInstancesTags,
            transformErrorResponse: (response) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
        downloadPaymentFile: builder.query<void, { id: string; version?: XMLVersion }>({
            query: ({ id, version = XMLVersion.V03 }) => ({
                url: `/payment_transaction_batch_instance/${id}/generate_iso_20022`,
                headers: {
                    Accept: "application/xml",
                },
                params: { version },
                responseHandler: async (response) => {
                    if (!response.ok) {
                        return await response.json()
                    }

                    return response.blob()
                },
            }),
            transformResponse: (blob: Blob) => {
                if (blob) {
                    fileDownload(blob, "payment_batch_iso20022.xml")
                }
            },
            transformErrorResponse: (response: FetchBaseQueryError) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
        getAssignedObjectsByObjectId: builder.query<PaginatedResponse<AssignedObject>, { objectId: string }>({
            query: ({ objectId }) => ({
                url: `/payment_transaction_batch_instance_assigned_object/by_object/${objectId}`,
            }),
            providesTags: paymentBatchAssignedObjectsTags,
            transformResponse: (response: PaginatedResponse<ServerAssignedObject>) => {
                return {
                    ...response,
                    items: response.items.map(assignedObjectFromApiAdapter),
                } as PaginatedResponse<AssignedObject>
            },
        }),
        createAssignedObject: builder.mutation<AssignedObject, Partial<AssignedObject>>({
            query: (assignedObject: AssignedObject) => ({
                url: "/payment_transaction_batch_instance_assigned_object",
                method: "POST",
                body: assignedObjectToApiAdapter(assignedObject),
            }),
            invalidatesTags: paymentBatchAssignedObjectsTags,
            transformResponse: (response: ServerAssignedObject) => assignedObjectFromApiAdapter(response),
            transformErrorResponse: (response) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
        addObjectToBatch: builder.mutation<AssignedObject, AddObjectToBatch>({
            query: ({ assignedObject, batchId }) => ({
                url: `/payment_transaction_batch/${batchId}/add_object`,
                method: "POST",
                body: assignedObjectToApiAdapter(assignedObject),
            }),
            invalidatesTags: [...paymentBatchesTags, ...paymentBatchAssignedObjectsTags],
            transformResponse: (response: ServerAssignedObject) => assignedObjectFromApiAdapter(response),
            transformErrorResponse: (response) => ({
                meta: {
                    catchSentryException: true,
                },
                ...response,
            }),
        }),
    }),
})

export const {
    useGetPaymentBatchesByOrganizationQuery,
    useCreatePaymentBatchMutation,
    useUpdatePaymentBatchMutation,
    useDeletePaymentBatchMutation,
    useGetPaymentBatchInstancesByOrganizationQuery,
    useGetPaymentBatchInstanceQuery,
    useUpdateBatchInstanceMutation,
    useExecuteAllPaymentsMutation,
    useLazyDownloadPaymentFileQuery,
    useGetAssignedObjectsByObjectIdQuery,
    useCreateAssignedObjectMutation,
    useAddObjectToBatchMutation,
} = paymentBatchesApi
