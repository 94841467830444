import { TagObjectType } from "~/domains/analytics/tags/types"
import { OrganizationId } from "~/types"

const eiffageTagGroups = {
    // Eiffage staging
    "6673ee7e4d459a2ad69b16dc": {
        // Purchase family tag group
        "6f366015-5e7f-4e97-b0fc-e9d478c04b5e": [
            TagObjectType.INVOICE,
            TagObjectType.PURCHASE_REQUEST,
            TagObjectType.PURCHASE_ORDER,
        ],
    },
    // Eiffage production
    "673b1cd159797801c4ea7184": {
        // Purchase family tag group
        "3f555a0e-1072-4e40-a66d-9b48283f1e94": [
            TagObjectType.INVOICE,
            TagObjectType.PURCHASE_REQUEST,
            TagObjectType.PURCHASE_ORDER,
        ],
    },
}

const vivienTagGroups = {
    // Vivien staging
    "64edd35bdd74306a85f11cd3": {
        // Account tag group
        "adc7fd74-78f5-48bf-b710-2f7f2de06f6e": [
            TagObjectType.INVOICE,
            TagObjectType.PURCHASE_REQUEST,
            TagObjectType.PURCHASE_ORDER,
        ],
        // Account tag group
        "d38ecddb-38c4-41f1-86e1-6514a44287fb": [TagObjectType.PURCHASE_REQUEST],
    },
}

const mandatoryTagGroups: Record<OrganizationId, Partial<Record<string, TagObjectType[]>>> = {
    ...eiffageTagGroups,
    ...vivienTagGroups,
}

export const getMandatoryTagGroupIdsByTagObjectType = (
    organizationId: OrganizationId,
    tagObjectType: TagObjectType
): string[] => {
    const organizationMandatoryTagGroups = mandatoryTagGroups[organizationId]
    if (!organizationMandatoryTagGroups) return []

    return (
        Object.keys(organizationMandatoryTagGroups).filter(
            (tagGroupId) => organizationMandatoryTagGroups[tagGroupId]?.includes(tagObjectType) ?? false
        ) ?? []
    )
}
