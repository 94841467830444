import { Chip, Stack, styled } from "@mui/material"

import { BulkActionButton } from "~/components/BulkActions/BulkActions"

const ExtraSmallChip = styled(Chip)({
    fontSize: "var(--font-size-xs)",
    padding: "2px",
    height: "20px",
    "& > span": {
        padding: "0 var(--spacing-xxs)",
    },
})

interface BulkButtonContentProps {
    button: BulkActionButton
}

export const BulkButtonContent: React.FC<BulkButtonContentProps> = ({ button }) => {
    const { label, icon, count } = button
    const showExtraSmallChip = count !== undefined && count > 0
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            {label}
            {showExtraSmallChip && <ExtraSmallChip color="primary" size="small" label={count} />}
            <Stack flex={1}>{icon}</Stack>
        </Stack>
    )
}
