/*
    This file contains the adapters for converting API responses to Editor nodes and edges.
*/
import { Edge, MarkerType, Node } from "@xyflow/react"

import { nodeConfig } from "~/domains/orchestration/flows/core/"
import {
    Configuration,
    EditorNode,
    Event,
    Flow,
    Handle,
    NodeType,
    ObjectType,
    TaskPriority,
    TaskStatus,
    TransactionType,
    hasNextNode,
    isBranchNode,
    isConditionalNode,
} from "~/domains/orchestration/flows/types"

/**
 *
 * @param flow
 * @param options { run: Run, currentNode: FlowNode }
 * @returns Node[]
 * @description Adapts the API response to Editor nodes
 */

const adaptFlowToNodes = (flow: Flow | null, options?: { currentNode?: EditorNode }): Node[] => {
    if (!flow) return []

    return flow.nodes.map<Node>((node: EditorNode) => {
        let ports: Handle[] = []

        if (isConditionalNode(node)) {
            ports = [
                {
                    id: `${node.slug}-source`,
                },

                {
                    id: `${node.slug}-failure`,
                },
                {
                    id: `${node.slug}-success`,
                },
            ]
        } else if (isBranchNode(node)) {
            ports = [
                {
                    id: `${node.slug}-default`,
                },
                {
                    id: `${node.slug}-source`,
                },
                ...node.branches.map<Handle>((branch, index) => ({
                    id: `${node.slug}-{${index}}`,
                })),
            ]
        } else if (hasNextNode(node)) {
            ports = [
                {
                    id: `${node.slug}-source`,
                },

                {
                    id: `${node.slug}-target`,
                },
            ]
        }

        const configuration: Configuration<NodeType> = nodeConfig[node.type]

        return {
            id: node.slug,
            type: configuration.type,
            data: {
                ...node,
                ports,
            },
            position: node.metadata.position,
            selected: options?.currentNode?.slug == node.slug,
        }
    })
}

/**
 *
 * @param flow
 * @param opticleons { readOnly: boolean }
 * @returns Edge[]
 * @description Adapts the API response to Editor edges
 */
const adaptFlowToEdges = (flow: Flow | null, options?: { readOnly: boolean }): Edge[] => {
    if (!flow) return []

    return flow.nodes.flatMap<Edge>((node: EditorNode) => {
        const commonEdgeProperties = {
            animated: true,
            ...(!options?.readOnly && { type: "button" }),
            style: {
                stroke: "var(--color-primary)",
                strokeWidth: 2,
            },
            markerEnd: {
                type: MarkerType.ArrowClosed,
                color: "var(--color-primary)",
                width: 22,
                height: 22,
            },
        }

        const successEdgeProperties = {
            ...commonEdgeProperties,
            id: `${node.slug}-success`,
            sourceHandle: `${node.slug}-success`,
        }

        const failureEdgeProperties = {
            ...commonEdgeProperties,
            id: `${node.slug}-failure`,
            sourceHandle: `${node.slug}-failure`,
        }

        if (isConditionalNode(node)) {
            return [
                {
                    source: node.slug,
                    ...successEdgeProperties,
                    target: node.nextIfSuccess ? node.nextIfSuccess : "",
                },
                {
                    source: node.slug,
                    ...failureEdgeProperties,
                    target: node.nextIfFailure ? node.nextIfFailure : "",
                },
            ]
        }
        if (isBranchNode(node)) {
            // TODO: handle default branch

            const defaultEdge = {
                source: node.slug,
                ...commonEdgeProperties,
                ...(options?.readOnly
                    ? { type: "withLabel" }
                    : {
                          type: "buttonWithLabel",
                      }),
                id: `${node.slug}-default`,
                sourceHandle: `${node.slug}-default`,
                target: node.default,
            }

            const edges = node.branches.map((branch, index) => ({
                source: node.slug,
                ...commonEdgeProperties,
                ...(options?.readOnly
                    ? { type: "withLabel" }
                    : {
                          type: "buttonWithLabel",
                      }),
                id: `${node.slug}-{${index}}`,
                sourceHandle: `${node.slug}-{${index}}`,
                target: branch.nextNode ? branch.nextNode : "",
            }))

            return [...edges, defaultEdge]
        }
        if (hasNextNode(node)) {
            return [
                {
                    source: node.slug,
                    ...commonEdgeProperties,
                    id: node.slug,
                    target: node.nextNode ? node.nextNode : "",
                },
            ]
        }

        return []
    })
}

// This a simple way to use expression cf : https://www.notion.so/get-flowie/Flows-expressions-1267fb2d6e9180709954d1e61460eeef
// For now, we just display the expression as a string
// It needs to be beetween single quotes (e.g. 'My message')

const adaptTextToExpression = (value: string) => `'${value}'`

const adaptExpressionToText = (value: string) => value.replace(/'/g, "").replace(/\\/g, "")

const adaptStringToTaskPriority = (value: string): TaskPriority | undefined => {
    switch (value) {
        case TaskPriority.LOW:
            return TaskPriority.LOW
        case TaskPriority.MEDIUM:
            return TaskPriority.MEDIUM
        case TaskPriority.HIGH:
            return TaskPriority.HIGH
        case TaskPriority.URGENT:
            return TaskPriority.URGENT
    }
}

const adaptStringToTaskStatus = (value: string): TaskStatus | undefined => {
    switch (value) {
        case TaskStatus.PENDING:
            return TaskStatus.PENDING
        case TaskStatus.IN_PROGRESS:
            return TaskStatus.IN_PROGRESS
        case TaskStatus.COMPLETED:
            return TaskStatus.COMPLETED
    }
}
const adaptEventTypeToObjectType = (event: Event): ObjectType | null => {
    switch (event) {
        case Event.BUDGET_CREATED:
            return ObjectType.BUDGET
        case Event.BUDGET_UPDATED:
            return ObjectType.BUDGET
        case Event.INVOICE_CREATED:
            return ObjectType.INVOICE
        case Event.INVOICE_UPDATED:
            return ObjectType.INVOICE
        case Event.BUYER_PURCHASE_ORDER_CREATED:
            return ObjectType.PURCHASE_ORDER
        case Event.SUPPLIER_PURCHASE_ORDER_CREATED:
            return ObjectType.PURCHASE_ORDER
        case Event.PARTNERSHIP_CREATED:
            return ObjectType.ORGANIZATION_RELATIONSHIP
        case Event.BUYER_PURCHASE_ORDER_LINE_CREATED:
            return ObjectType.PURCHASE_ORDER_LINE
        case Event.SUPPLIER_PURCHASE_ORDER_LINE_CREATED:
            return ObjectType.PURCHASE_ORDER_LINE
        case Event.PURCHASE_REQUEST_CREATED:
            return ObjectType.PURCHASE_REQUEST
        case Event.PURCHASE_REQUEST_UPDATED:
            return ObjectType.PURCHASE_REQUEST
        case Event.PURCHASE_REQUEST_LINE_CREATED:
            return ObjectType.PURCHASE_REQUEST_LINE
        case Event.PAYMENT_METHOD_DETAILS_CREATED:
            return ObjectType.PAYMENT_METHOD_DETAILS
        case Event.PARTNER_PAYMENT_METHOD_DETAILS_CREATED:
            return ObjectType.PARTNER_PAYMENT_METHOD_DETAILS
        default:
            return null
    }
}

const adaptEventTypeToTransactionType = (event: Event): TransactionType | null => {
    switch (event) {
        case Event.INVOICE_CREATED:
            return TransactionType.INVOICE
        case Event.INVOICE_UPDATED:
            return TransactionType.INVOICE
        case Event.BUYER_PURCHASE_ORDER_CREATED:
            return TransactionType.PURCHASE_ORDER
        case Event.SUPPLIER_PURCHASE_ORDER_CREATED:
            return TransactionType.PURCHASE_ORDER
        case Event.BUYER_PURCHASE_ORDER_LINE_CREATED:
            return TransactionType.PURCHASE_ORDER
        case Event.SUPPLIER_PURCHASE_ORDER_LINE_CREATED:
            return TransactionType.PURCHASE_ORDER
        case Event.PURCHASE_REQUEST_CREATED:
            return TransactionType.PURCHASE_REQUEST
        case Event.PURCHASE_REQUEST_UPDATED:
            return TransactionType.PURCHASE_REQUEST
        case Event.PURCHASE_REQUEST_LINE_CREATED:
            return TransactionType.PURCHASE_REQUEST
        default:
            return null
    }
}

export {
    adaptFlowToNodes,
    adaptFlowToEdges,
    adaptTextToExpression,
    adaptExpressionToText,
    adaptStringToTaskPriority,
    adaptStringToTaskStatus,
    adaptEventTypeToObjectType,
    adaptEventTypeToTransactionType,
}
