import { useEffect, useMemo } from "react"

import { useLazyGetFlowQuery } from "~/domains/orchestration/flows/api/flowsApi"
import { useGetRunQuery } from "~/domains/orchestration/flows/api/runsApi"
import { useEditorDispatch } from "~/domains/orchestration/flows/context/editorContext"
import { Run } from "~/domains/orchestration/flows/types"

export const useRunQueryWithFlowData = (runId: string) => {
    const dispatch = useEditorDispatch()
    const {
        data: run,
        isLoading: isRunLoading,
        isError: isRunError,
        isSuccess: isRunSuccess,
        error: runError,
        refetch,
    } = useGetRunQuery({ runId })

    const [
        getFlow,
        { data: flow, isLoading: isFlowLoading, isError: isFlowError, isSuccess: isFlowSuccess, error: flowError },
    ] = useLazyGetFlowQuery()

    useEffect(() => {
        const fetchFlow = async (r: Run) => {
            const { isSuccess, data } = await getFlow({ flowId: r.flowId, params: { version: r.flowVersion } })

            if (isSuccess) {
                dispatch({ type: "SET_FLOW", payload: data })
            }
        }

        if (run?.flowId && run?.flowVersion) {
            dispatch({ type: "SET_RUN", payload: run })
            fetchFlow(run)
        }
    }, [run?.flowId, run?.flowVersion, refetch, run, getFlow, dispatch])

    const isLoading = isRunLoading || isFlowLoading
    const isError = isRunError || isFlowError
    const isSuccess = isRunSuccess && isFlowSuccess
    const error = runError || flowError

    // filter pathtaken with nodes that are in group nodes

    const adaptedData = useMemo<Run | null>(() => {
        if (!run || !flow) return null

        const nodeSlugs = flow?.nodes.map((n) => n.slug)

        return {
            ...run,
            state: {
                ...run.state,
                pathTaken: run.state.pathTaken
                    .filter((step) => nodeSlugs.includes(step))
                    .map((step) => flow?.nodes.find((s) => s.slug === step)?.name || step),
            },
        }
    }, [run, flow])

    if (!run) {
        return { data: null, isLoading: false, isError: false, isSuccess: false, refetch }
    }

    return { data: adaptedData, isLoading, isError, isSuccess, error, refetch }
}
