import { useCallback, useContext } from "react"

import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { ocrActions, selectHasOcr, selectOcrCurrentInput } from "~/store/ocr/ocrSlice"

import { OcrExtractContext } from "../OcrExtractContext"
import { OCRInputTypes } from "../extract"

export function useOcrExtractControllers() {
    const dispatch = useAppDispatch()

    const currentInput = useAppSelector(selectOcrCurrentInput)
    const hasOcr = useAppSelector(selectHasOcr)
    const { setCallback } = useContext(OcrExtractContext)

    const resetShowShapes = useCallback(() => {
        dispatch(ocrActions.updateOcrShowShapes(false))
        dispatch(ocrActions.updateOcrCurrentInput(""))
        dispatch(ocrActions.updateOcrCurrentInputType(null))
        setCallback(null)
    }, [dispatch, setCallback])

    const toggleShowShapes = useCallback(
        (inputName: string, inputType: OCRInputTypes) => {
            if (inputName === currentInput) {
                resetShowShapes()
            } else {
                dispatch(ocrActions.updateOcrShowShapes(true))
                dispatch(ocrActions.updateOcrCurrentInput(inputName))
                dispatch(ocrActions.updateOcrCurrentInputType(inputType))
                setCallback(null)
            }
        },
        [dispatch, resetShowShapes, setCallback]
    )

    const setHasOcr = useCallback(
        (hasOcr: boolean) => {
            dispatch(ocrActions.updateHasOcr(hasOcr))
        },
        [dispatch]
    )

    return {
        toggleShowShapes,
        resetShowShapes,
        hasOcr,
        setHasOcr,
    }
}
