import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import { DocumentI, InvoiceI, InvoiceStatus, ReceivedDocumentI, SentDocumentI } from "~/types"

dayjs.extend(relativeTime)

const calculateTimeDifferenceInDays = (startDate: Date, endDate: Date): number => {
    return dayjs(endDate).diff(dayjs(startDate), "day")
}

export const getTimeToPay = (invoice: ReceivedDocumentI | SentDocumentI | DocumentI): number | null => {
    if (!invoice.paidDate || !invoice.issueDate) {
        return null
    }

    const issueDate = dayjs(invoice.issueDate).toDate()
    const paidDate = dayjs(invoice.paidDate).toDate()

    return calculateTimeDifferenceInDays(issueDate, paidDate)
}

export const getElapsedTime = (invoice: ReceivedDocumentI | SentDocumentI | DocumentI | InvoiceI): number | null => {
    if (
        !invoice.issueDate ||
        invoice.status?.includes(InvoiceStatus.REJECTED) ||
        invoice.status?.includes(InvoiceStatus.PAID)
    ) {
        return null
    }

    const issueDate = dayjs(invoice.issueDate).toDate()
    const today = dayjs().toDate()

    return issueDate >= today ? 0 : calculateTimeDifferenceInDays(issueDate, today)
}

export const distanceToIssueDate = (invoice: InvoiceI): string | null => {
    const issueDate = dayjs(invoice.issueDate).toDate()

    return dayjs(issueDate).fromNow(true)
}

export const getDueTime = (
    invoice: ReceivedDocumentI | SentDocumentI | DocumentI | InvoiceI,
    onlyOverdue = false
): number | null => {
    if (
        !invoice.dueDate ||
        invoice.status?.includes(InvoiceStatus.REJECTED) ||
        invoice.status?.includes(InvoiceStatus.PAID)
    ) {
        return null
    }

    const dueDate = dayjs(invoice.dueDate).toDate()
    const today = dayjs(invoice.dueDate).toDate()

    return dueDate >= today && onlyOverdue ? null : calculateTimeDifferenceInDays(dueDate, today)
}
