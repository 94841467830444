/* eslint-disable complexity */
import { Stack, useMediaQuery, useTheme } from "@mui/material"
import classNames from "classnames"
import React, { useCallback, useState } from "react"
import { ChevronLeft, ChevronRight, X as CloseMenu, LogIn } from "react-feather"
import { useIntl } from "react-intl"

import { Button, TooltipConditional } from "~/components"
import { SafeFormattedMessage } from "~/components"
import { LogoFlowie } from "~/components/OrganizationLogo/LogoFlowie"
import { SideBarMenuItem } from "~/components/Sidebar/SideBarMenuItem"
import { SidebarNewUpload } from "~/components/Sidebar/SidebarNewUpload"
import { getSidebarFeatureFlags } from "~/components/Sidebar/getSidebarFeatureFlags"
import { MenuItem, sideBarMenuItems } from "~/components/Sidebar/sideBarMenuItems"
import { useSidebarPermissions } from "~/components/Sidebar/useSidebarPermissions"
import { SelectOrganization } from "~/domains/identity/account/components/Organization/SelectOrganization/SelectOrganization"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { authActions, selectIsConnected } from "~/store/auth/authSlice"
import { globalActions, selectDisplayMenu, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

import "./Sidebar.scss"

const ICON_SIZE = 18

export const Sidebar = () => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const [open] = useState(false)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const isConnected = useAppSelector(selectIsConnected)
    const keepOpen = useAppSelector(selectKeepSidebarOpened)
    const displayMenu = useAppSelector(selectDisplayMenu)
    const sidebarClasses = classNames("sidebar", {
        ["open"]: displayMenu,
        ["keep-open"]: keepOpen || displayMenu,
    })
    const dispatch = useAppDispatch()

    const {
        areFlowsEnabled,
        areWorkflowsDisabled,
        isMetricsEnabled,
        isInvoiceV1Enabled,
        isInvoiceV0Enabled,
        isDocumentsEnabled,
        isCopilotEnabled,
        isCustomFormsEnabled,
        isCatalogV1Enabled,
        isCommunicationPageEnabled,
        isNewPaymentEnabled,
    } = getSidebarFeatureFlags(currentOrganizationId)

    const {
        hasBudgetsPermission,
        hasWorkflowsPermission,
        hasPOsPermission,
        hasInvoicesPermission,
        hasBookOfRelationsPermission,
    } = useSidebarPermissions(currentOrganizationId)

    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
    const isOpen = open || isMobile || keepOpen

    const toggleKeepSidebarOpened = useCallback(() => {
        dispatch(globalActions.toggleKeepSidebarOpened())
    }, [dispatch])

    const handleDisplayHamburgerMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        dispatch(globalActions.setDisplayMenu())
    }

    const handleLogin = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault()
            dispatch(authActions.openPopupSignIn())
        },
        [dispatch]
    )

    return (
        <nav className={sidebarClasses}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                {!isConnected ? <LogoFlowie /> : <SelectOrganization hideOrganizationName={!isOpen} />}

                {isMobile && (
                    <Button type="transparent" size="small" onClick={handleDisplayHamburgerMenu}>
                        <CloseMenu size={22} />
                    </Button>
                )}

                <div className="expand-sidebar" onClick={toggleKeepSidebarOpened}>
                    {isOpen ? (
                        <ChevronLeft size={16} color="var(--color-grey-light)" />
                    ) : (
                        <ChevronRight size={16} color="var(--color-grey-light)" />
                    )}
                </div>
            </Stack>

            <SidebarNewUpload organizationId={currentOrganizationId} />

            <hr />

            <ul>
                <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.DASHBOARD]} disabled={false} />

                <TooltipConditional
                    condition={!hasInvoicesPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.INBOX]} disabled={!hasInvoicesPermission} />
                </TooltipConditional>

                <hr />

                <TooltipConditional
                    condition={!isConnected}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    <SideBarMenuItem
                        menuItem={sideBarMenuItems[MenuItem.PURCHASE_REQUESTS]}
                        disabled={!isConnected || !currentOrganizationId}
                    />
                </TooltipConditional>

                <TooltipConditional
                    condition={!hasPOsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    <SideBarMenuItem
                        menuItem={sideBarMenuItems[MenuItem.PURCHASE_ORDERS]}
                        disabled={!hasPOsPermission}
                    />
                </TooltipConditional>

                {isInvoiceV0Enabled && (
                    <TooltipConditional
                        condition={!hasInvoicesPermission}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                    >
                        <SideBarMenuItem
                            menuItem={sideBarMenuItems[MenuItem.INVOICES]}
                            disabled={!hasInvoicesPermission}
                        />
                    </TooltipConditional>
                )}

                {isInvoiceV1Enabled && (
                    <TooltipConditional
                        condition={!hasInvoicesPermission}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                    >
                        <SideBarMenuItem
                            menuItem={sideBarMenuItems[MenuItem.INVOICES_V1]}
                            disabled={!hasInvoicesPermission}
                        />
                    </TooltipConditional>
                )}

                {isCatalogV1Enabled && (
                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.CATALOG]} disabled={!isCatalogV1Enabled} />
                )}

                {isDocumentsEnabled && (
                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.DOCUMENTS]} disabled={!isDocumentsEnabled} />
                )}

                {isCustomFormsEnabled && (
                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.SURVEY]} disabled={!isCustomFormsEnabled} />
                )}

                <hr />

                {!areWorkflowsDisabled && (
                    <TooltipConditional
                        condition={!hasWorkflowsPermission}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                    >
                        <SideBarMenuItem
                            menuItem={sideBarMenuItems[MenuItem.WORKFLOWS]}
                            disabled={!hasWorkflowsPermission}
                        />
                    </TooltipConditional>
                )}

                {/* TEMP: New version of Flows are behind a feature flag */}

                {areFlowsEnabled && (
                    <TooltipConditional
                        condition={!hasWorkflowsPermission}
                        title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                    >
                        <SideBarMenuItem
                            menuItem={sideBarMenuItems[MenuItem.FLOWS]}
                            disabled={!hasWorkflowsPermission}
                        />
                    </TooltipConditional>
                )}

                <TooltipConditional
                    condition={!hasBookOfRelationsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    <SideBarMenuItem
                        menuItem={sideBarMenuItems[MenuItem.PARTNERS]}
                        disabled={!hasBookOfRelationsPermission}
                    />
                </TooltipConditional>

                {isNewPaymentEnabled && (
                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.PAYMENTS]} disabled={!hasInvoicesPermission} />
                )}

                <TooltipConditional
                    condition={!hasBudgetsPermission}
                    title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                >
                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.BUDGETS]} disabled={!hasBudgetsPermission} />
                </TooltipConditional>

                {isMetricsEnabled && hasBudgetsPermission && (
                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.METRICS]} disabled={!hasBudgetsPermission} />
                )}
                {isCommunicationPageEnabled && (
                    <SideBarMenuItem
                        menuItem={sideBarMenuItems[MenuItem.COMMUNICATION]}
                        disabled={!isCommunicationPageEnabled}
                    />
                )}

                {isCopilotEnabled && (
                    <>
                        <hr />
                        <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.COPILOT]} disabled={!isCopilotEnabled} />
                    </>
                )}
            </ul>

            <ul className="nav-profile">
                <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.SETTINGS]} disabled={!isConnected} />
                {isConnected && (
                    <SideBarMenuItem menuItem={sideBarMenuItems[MenuItem.ACCOUNT]} disabled={!isConnected} />
                )}
            </ul>

            {!isConnected && (
                <ul>
                    <li>
                        <TooltipConditional
                            condition={!isOpen}
                            type="primary"
                            title={formatMessage(sideBarMenuItems[MenuItem.LOGIN].message)}
                            placement="right"
                            arrow
                        >
                            <a href="#" onClick={handleLogin}>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <LogIn size={ICON_SIZE} />
                                    {isOpen && <SafeFormattedMessage {...sideBarMenuItems[MenuItem.LOGIN].message} />}
                                </Stack>
                            </a>
                        </TooltipConditional>
                    </li>
                </ul>
            )}
        </nav>
    )
}
