import styled from "@emotion/styled"
import { Stack } from "@mui/material"
import { Image } from "react-feather"

const StyledImagePlaceholder = styled(Stack)`
    max-width: 100%;
    max-height: 100%;
    background-color: var(--color-grey-lighter);
`

interface ImagePlaceholderProps {
    height?: string
    width?: string
}

export const ImagePlaceholder = ({ height = "100px", width = "100px" }: ImagePlaceholderProps) => {
    return (
        <StyledImagePlaceholder
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            height={height}
            width={width}
        >
            <Image size={18} />
        </StyledImagePlaceholder>
    )
}
