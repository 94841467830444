import { Grid } from "@mui/material"
import { useRef } from "react"
import { CreditCard } from "react-feather"
import { useIntl } from "react-intl"
import { Navigate } from "react-router-dom"

import { commonMessages } from "~/common-messages"
import { HeaderH1 } from "~/components"
import { ORGANIZATIONS_ROUTE } from "~/domains/identity/account/routes"
import { PaymentsHeader } from "~/domains/payment/payment-batches/components"
import { PaymentsTabsI } from "~/domains/payment/payment-batches/types"
import { useGetPaymentMethodDetailsQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { PaymentMethodDetails } from "~/domains/payment/payment-method-details/components"
import { selectShowDeletedPaymentMethodDetails } from "~/domains/payment/payment-method-details/store/paymentMethodDetailsSlice"
import { ObjectType } from "~/domains/payment/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { Features, isFeatureEnabled } from "~/utils/featureFlag"

const pagination = {
    page: 1,
    page_size: 50,
}

export const PaymentMethodDetailsPage = () => {
    const { formatMessage } = useIntl()
    const hasErrorRef = useRef(false)
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const showDeleted = useAppSelector(selectShowDeletedPaymentMethodDetails)

    const { data, isLoading, isError } = useGetPaymentMethodDetailsQuery(
        { ...pagination, owner: organizationId, include_deleted: showDeleted },
        {
            skip: !organizationId || hasErrorRef.current,
            pollingInterval: 1000,
            refetchOnMountOrArgChange: true,
        }
    )
    const items = data?.items ?? []
    hasErrorRef.current = isError

    if (!organizationId) {
        return null
    }

    if (!isFeatureEnabled(Features.Payment, organizationId)) {
        return <Navigate to={ORGANIZATIONS_ROUTE} />
    }

    return (
        <>
            <HeaderH1 title={formatMessage(commonMessages.payments)} icon={<CreditCard />} />
            <Grid className="main-box">
                <PaymentsHeader currentPage={PaymentsTabsI.PAYMENT_METHOD_DETAILS} />
                <PaymentMethodDetails
                    isLoading={isLoading}
                    organizationId={organizationId}
                    currentOrganizationId={organizationId}
                    objectType={ObjectType.ORGANIZATION}
                    items={items}
                />
            </Grid>
        </>
    )
}
