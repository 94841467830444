import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material"
import { ReactElement } from "react"

interface TooltipConditionalProps extends TooltipProps {
    children: ReactElement
    condition: boolean
    type?: "default" | "primary" | "success" | "warning" | "danger" | "white"
}

export const TooltipConditional = ({
    children,
    condition,
    type = "default",
    ...tooltipProps
}: TooltipConditionalProps) => {
    if (!condition) {
        return children
    }

    switch (type) {
        case "primary":
            return <TooltipPrimary {...tooltipProps}>{children}</TooltipPrimary>
        case "success":
            return <TooltipSuccess {...tooltipProps}>{children}</TooltipSuccess>
        case "warning":
            return <TooltipWarning {...tooltipProps}>{children}</TooltipWarning>
        case "danger":
            return <TooltipDanger {...tooltipProps}>{children}</TooltipDanger>
        case "white":
            return <TooltipWhite {...tooltipProps}>{children}</TooltipWhite>
        default:
            return <Tooltip {...tooltipProps}>{children}</Tooltip>
    }
}

export const TooltipPrimary = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "var(--color-primary)",
        backgroundColor: "var(--color-primary-light)",
        fontSize: "var(--font-size-md)",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "var(--color-primary-light)",
    },
})

export const TooltipWarning = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "var(--color-yellow)",
        backgroundColor: "var(--color-yellow-light)",
        fontSize: "var(--font-size-md)",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "var(--color-yellow-light)",
    },
})

export const TooltipSuccess = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "var(--color-green)",
        backgroundColor: "var(--color-green-light)",
        fontSize: "var(--font-size-md)",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "var(--color-green-light)",
    },
})

export const TooltipDanger = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "var(--color-red)",
        backgroundColor: "var(--color-red-light)",
        fontSize: "var(--font-size-md)",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "var(--color-red-light)",
    },
})

export const TooltipWhite = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "none",
        backgroundColor: "var(--sidebar-color)",
        border: "none",
        boxShadow: "1px 0px 10px rgba(0, 0, 0, 0.25)",
        minWidth: "200px",
        color: "var(--secondary-color)",
        padding: "var(--spacing-md)",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "var(--color-white)",
    },
})
