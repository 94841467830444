import { IconButton, styled } from "@mui/material"
import React from "react"
import { ChevronDown, ChevronUp } from "react-feather"

import { TagGroupI } from "~/domains/analytics/tags/types"

interface Props {
    tagGroup: TagGroupI
    expanded: boolean
    toggleExpanded: () => void
}

const TagGroupLabelContainer = styled("div")<{ hasTags: boolean }>(({ hasTags }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    cursor: hasTags ? "pointer" : "default",
}))

const Label = styled("div")(({ theme }) => ({
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.palette.grey[700],
    minHeight: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginLeft: "8px",
}))

const Spacer = styled("div")({
    width: "36px",
})

export const TagGroupLabel: React.FC<Props> = ({ tagGroup, expanded, toggleExpanded }) => {
    const hasTags = !!tagGroup.tags.length
    return (
        <TagGroupLabelContainer hasTags={hasTags} onClick={toggleExpanded}>
            {hasTags ? (
                <IconButton>{expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}</IconButton>
            ) : (
                <Spacer />
            )}
            <Label>{tagGroup.name}</Label>
        </TagGroupLabelContainer>
    )
}
