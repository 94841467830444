import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"

import { PurchaseOrders } from "./pages"
import { PurchaseOrder } from "./pages/[ID]"
import { PurchaseOrderNew } from "./pages/[ID]/create"
import {
    PURCHASE_ORDERS_ROUTE,
    PURCHASE_ORDER_EDIT_ROUTE,
    PURCHASE_ORDER_NEW_ROUTE,
    PURCHASE_ORDER_ROUTE,
} from "./routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="pos_route">
            <Route path={PURCHASE_ORDERS_ROUTE} element={<PurchaseOrders />} />
            <Route path={PURCHASE_ORDER_EDIT_ROUTE} element={<PurchaseOrder edit={true} />} />
            <Route path={PURCHASE_ORDER_NEW_ROUTE} element={<PurchaseOrderNew />} />
        </Route>,
        <Route key="pos_unsafe_route">
            <Route path={PURCHASE_ORDER_ROUTE} element={<PurchaseOrder />} />
        </Route>,
    ],
}
