import * as t from "io-ts"

import { MessageIO, messageFromApiAdapter } from "~/domains/communication/chat/api/adapters/messageFromApiAdapter"
import { MessagesListI } from "~/domains/communication/chat/types/MessagesList"
import { Result, ResultSuccess, getResultSuccessValue, isResultSuccess } from "~/types/Result"
import { ParsingErrorType, genericParser } from "~/utils"

const PaginationInfoIO = t.type({
    current_page: t.number,
    per_page: t.number,
    total_items: t.number,
    total_pages: t.number,
})

export const MessagesListIO = t.type({
    messages: t.array(MessageIO),
    pagination_info: PaginationInfoIO,
})

export const messageListFromApiAdapter = (data: unknown): Result<MessagesListI, ParsingErrorType> => {
    const result = genericParser(data, MessagesListIO)
    if (isResultSuccess(result)) {
        return ResultSuccess({
            ...result.result,
            messages: result.result.messages
                .map(messageFromApiAdapter)
                .filter(isResultSuccess)
                .map(getResultSuccessValue),
        })
    }
    return result
}
