import { Dayjs } from "dayjs"

import { PaymentMethodType } from "~/domains/payment/payment-methods/types"
import { CountryCode, CurrencyCodes } from "~/types"

import { CardCategory } from "./PaymentMethodDetails"

export type Rule<T> = {
    value: T
    message?: React.ReactNode
}

export type ValidationRule<T> = {
    required?: Rule<boolean>
    minLength?: Rule<number>
    maxLength?: Rule<number>
    pattern?: Rule<RegExp>
    date?: Rule<boolean>
    custom?: (value: any, values?: T | undefined) => Rule<string | undefined>
}

export type ValidationSchema<T extends object> = {
    [K in keyof T]?: ValidationRule<T>
}

export interface FormStateBase {
    id?: string
    paymentMethodType?: PaymentMethodType
    paymentMethodId?: string | null
    kid?: string
    documentId?: string
}

export interface CardFormState extends FormStateBase {
    name: string
    currency: CurrencyCodes
    country: CountryCode | null
    cardCategory: CardCategory | null
    cardExpirationDate: Dayjs | null
    cardNumber: string
}

export interface BankTransferFormState extends FormStateBase {
    bankAccountNumber: string
    bankName: string
    bankKey: string
    sortCode: string
    iban: string
    bicSwift: string
    holderName: string
    currency: CurrencyCodes
    country: CountryCode | null
}

export enum MandateStatus {
    PENDING = "pending",
    ACTIVE = "active",
    INACTIVE = "inactive",
}

export enum PaymentMethodDetailsFormType {
    MODAL = "modal",
    INLINE = "inline",
}

export interface DirectDebitFormState extends FormStateBase {
    mandateReference: string
    mandateDate: Dayjs | null
    mandateStatus: MandateStatus
    bankAccountNumber: string
    bankName: string
    holderName: string
    currency: CurrencyCodes
    country: CountryCode | null
}

export type FormState = CardFormState | BankTransferFormState | DirectDebitFormState
