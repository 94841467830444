import { type Edge, type Node } from "@xyflow/react"
import ELK, { ElkNode, LayoutOptions } from "elkjs/lib/elk.bundled.js"

import { NODE_MIN_HEIGHT } from "~/domains/orchestration/flows/constants"
import { NODE_WIDTH } from "~/domains/orchestration/flows/constants"
import { Handle, NodeType } from "~/domains/orchestration/flows/types"

// elk layouting options can be found here:
// https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html

const layoutOptions: LayoutOptions = {
    "elk.algorithm": "layered",
    "elk.direction": "DOWN",
    "elk.layered.spacing.baseValue": "150",
    // these options to force vertical alignment
    "elk.layered.nodePlacement.strategy": "SIMPLE",
    "elk.layered.crossingMinimization.strategy": "INTERACTIVE",
}

const elk = new ELK()

// uses elkjs to give each node a layouted position
export const getLayoutedNodes = async (nodes: Node[], edges: Edge[]) => {
    const children = nodes.map(({ id, data, type }) => {
        const ports = data.ports as Handle[]

        if (type === NodeType.BRANCH_NODE) {
            ports.sort((a, b) => a.id.localeCompare(b.id))
        }

        return {
            id: id,
            width: NODE_WIDTH,
            height: NODE_MIN_HEIGHT,
            ports,
        }
    })

    const elkEdges = edges.map((e) => ({
        id: e.id,
        sources: [e.sourceHandle || e.source],
        targets: [e.targetHandle || e.target],
        container: "root",
    }))

    const filteredEdges = elkEdges.filter((e) => e.sources && e.targets.some(Boolean))

    const graph = {
        id: "root",
        children,
        edges: filteredEdges,
        layoutOptions,
    } as ElkNode

    const layoutedGraph = await elk.layout(graph)

    const layoutedNodes: Node[] = nodes.map((node) => {
        const layoutedNode = layoutedGraph.children?.find((lgNode) => lgNode.id === node.id)
        if (!layoutedNode) return node

        const { x = 0, y = 0 } = layoutedNode

        return {
            ...node,
            position: { x, y },
        }
    })

    return layoutedNodes
}
