import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-premium"

import { PAGE_SIZE } from "~/constants/pagination"

export const initialState = {
    columns: {
        columnVisibilityModel: {
            id: false,
        },
    },
    pagination: {
        paginationModel: { pageSize: PAGE_SIZE },
    },
}

export const sortModel: GridSortModel = [
    {
        field: "creationDate",
        sort: "desc",
    },
]

export const filterModel: GridFilterModel = {
    items: [],
}
