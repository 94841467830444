import { useEffect } from "react"

import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { parseInvoice } from "~/domains/transactions/invoices/types/InvoiceParsers"
import { store } from "~/store"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { InvoiceI, InvoiceId } from "~/types"

import { invoiceActions, selectInvoicesById } from "../invoiceSlice"

type InvoiceState = {
    invoice: InvoiceI | null
    loading: boolean
    error: string | null
}

const INITIAL_STATE: InvoiceState = { invoice: null, loading: true, error: null }

export const useInvoicesByIds = (invoiceIds: InvoiceId[] | undefined | null) => {
    const invoicesById = useAppSelector(selectInvoicesById)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (invoiceIds && invoiceIds.length > 0) {
            invoiceIds.forEach((invoiceId) => {
                const currentState = selectInvoicesById(store.getState())[invoiceId]
                if (!currentState || (!currentState.loading && !currentState.invoice && !currentState.error)) {
                    dispatch(invoiceActions.fetchInvoiceById(invoiceId))
                    invoiceApi
                        .getById(invoiceId)
                        .then(parseInvoice)
                        .then((invoice) => dispatch(invoiceActions.fetchInvoiceByIdSuccess(invoice)))
                        .catch((e) => dispatch(invoiceActions.fetchInvoiceByIdFailed({ invoiceId, error: e.message })))
                }
            })
        }
    }, [invoiceIds, dispatch])

    const invoicesState = invoiceIds?.reduce(
        (acc, id) => {
            acc[id] = invoicesById[id] ?? INITIAL_STATE
            return acc
        },
        {} as Record<InvoiceId, InvoiceState>
    )

    return {
        invoices: invoicesState ?? {},
        loading: invoiceIds?.some((id) => invoicesById[id]?.loading) ?? false,
        error: invoiceIds?.map((id) => invoicesById[id]?.error).filter((error) => error != null) ?? null,
    }
}
