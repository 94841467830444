import { FormLabel, Grid, Switch, capitalize } from "@mui/material"
import { ChangeEvent } from "react"
import { defineMessages, useIntl } from "react-intl"

import { CreatePartnershipPayload } from "~/domains/identity/partners/types"

const messages = defineMessages({
    preferredStatus: {
        id: "partners.bookofrelations.modal.create.preferredStatus",
        defaultMessage: "Mark as preferred",
    },
    verifiedStatus: {
        id: "partners.bookofrelations.modal.create.verifiedStatus",
        defaultMessage: "Mark as verified",
    },
})

interface PartnershipStatusSwitchesProps {
    partnershipPayload: CreatePartnershipPayload
    updatePartnershipPayload: (e: ChangeEvent<HTMLInputElement>) => void
}

export const PartnershipStatusSwitches: React.FC<PartnershipStatusSwitchesProps> = ({
    partnershipPayload,
    updatePartnershipPayload,
}) => {
    const { formatMessage } = useIntl()

    const preferredStatus = partnershipPayload.preferredStatus
    const verifiedStatus = partnershipPayload.verifiedStatus

    return (
        <Grid container alignItems="center" sx={{ mb: 1 }}>
            <Grid container alignItems="center" gap={1}>
                <FormLabel>{capitalize(formatMessage(messages.preferredStatus))}</FormLabel>
                <Switch name="preferredStatus" checked={preferredStatus} onChange={updatePartnershipPayload} />
            </Grid>
            <Grid container alignItems="center" gap={1}>
                <FormLabel>{capitalize(formatMessage(messages.verifiedStatus))}</FormLabel>
                <Switch name="verifiedStatus" checked={verifiedStatus} onChange={updatePartnershipPayload} />
            </Grid>
        </Grid>
    )
}
