import React from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"
import { EditorProvider } from "~/domains/orchestration/flows/context/editorContext"
import { Run } from "~/domains/orchestration/flows/pages/Run"
import { RunsList } from "~/domains/orchestration/flows/pages/RunsList"
import { RunExplorer } from "~/domains/orchestration/flows/pages/[ID]/RunExplorer"

import { FlowsList } from "./pages/FlowsList"
import { Editor } from "./pages/[ID]/Editor"
import { EDITOR, FLOWS_LIST, RUN, RUNS_LIST, RUN_EXPLORER } from "./routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="flows_route">
            <Route path={FLOWS_LIST} element={<FlowsList />} />
            <Route
                path={EDITOR}
                element={
                    <EditorProvider>
                        <Editor />
                    </EditorProvider>
                }
            />
            <Route path={RUNS_LIST} element={<RunsList />} />
            <Route path={RUN} element={<Run />} />
            <Route
                path={RUN_EXPLORER}
                element={
                    <EditorProvider>
                        <RunExplorer />
                    </EditorProvider>
                }
            />
        </Route>,
    ],
}
