import { AppBar, Container, Toolbar } from "@mui/material"
import classNames from "classnames"
import React, { ReactNode } from "react"

import { UserActions } from "~/components/Header/UserActions"
import { selectIsConnected } from "~/store/auth/authSlice"
import { useAppSelector } from "~/store/hooks"

import "./Header.scss"

interface HeaderProps extends React.PropsWithChildren {
    hideUser?: boolean
    rightChildren?: ReactNode
    noHeaderSpacerRight?: boolean
    className?: string
}

export function Header({ children, rightChildren, className, noHeaderSpacerRight = false }: HeaderProps) {
    const isConnected = useAppSelector(selectIsConnected)
    const classes = classNames("header", className)

    return (
        <>
            <AppBar position="fixed" className={classes}>
                <Container maxWidth={false}>
                    <Toolbar disableGutters>
                        {children}

                        {noHeaderSpacerRight ? null : (
                            <>
                                {rightChildren}
                                <UserActions isConnected={isConnected} />
                            </>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    )
}

export default Header
