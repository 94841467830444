import { Tab, Tabs, capitalize } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { SafeFormattedMessage } from "~/components"
import { useGetOrganizationInvoiceLifecycleQuery } from "~/domains/transactions/invoices-v1/api/invoiceLifeCycleApi"
import { LifecycleGroup } from "~/domains/transactions/invoices-v1/types/Invoice"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { Variant } from "~/utils/colors"

const messages = defineMessages({
    dueWithin7Days: { id: "invoices.dueWithin7Days", defaultMessage: "Due within 7 days" },
})

interface InvoicesListTabsProps {
    activeTab: string
    onChange: (property: string, value: string) => void
}

export const InvoicesListTabs = ({ activeTab, onChange }: InvoicesListTabsProps) => {
    const { formatMessage } = useIntl()

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const { data: invoiceLifecycle } = useGetOrganizationInvoiceLifecycleQuery(currentOrganizationId || "")

    const getTabs = (il?: [string, { group: LifecycleGroup; index: number; variant: Variant }][]) => {
        const translateLabel = (name: string) => {
            const message = commonMessages[name]
            return message ? formatMessage(message) : capitalize(name)
        }

        return (il || []).map(([status, { variant }]) => ({
            label: translateLabel(status),
            property: "status",
            value: status,
            variant,
        }))
    }

    const tabs = [
        {
            label: <SafeFormattedMessage {...commonMessages.all} />,
            property: "status",
            value: "",
        },
        ...getTabs(invoiceLifecycle),
        {
            label: <SafeFormattedMessage {...messages.dueWithin7Days} />,
            property: "dueWithin7Days",
            value: "dueWithin7Days",
        },
    ]

    const handleChangeTab = (e: React.SyntheticEvent, newValue: string) => {
        const property = (e.currentTarget as HTMLButtonElement).dataset.property || "status"
        onChange(property, newValue)
    }

    return (
        <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            aria-label="basic tabs"
            scrollButtons
            variant="scrollable"
            allowScrollButtonsMobile
        >
            {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} data-property={tab.property} value={tab.value} />
            ))}
        </Tabs>
    )
}
