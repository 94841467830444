import { FC, useMemo } from "react"

import { InvoiceEvents } from "~/components/InvoiceEvents/InvoiceEvents"
import { useGetInvoiceAuditLogsQuery } from "~/domains/transactions/invoices-v1/api/invoiceAuditLogApi"
import { useAppSelector } from "~/store/hooks"
import { selectInvoiceEvents } from "~/store/invoice/invoiceSlice"
import { useGetAllUsersQuery } from "~/store/users/hooks"
import { InvoiceBackendVersion } from "~/types"

interface InvoiceAuditLogsProps {
    invoiceId: string
    version: InvoiceBackendVersion
}

export const InvoiceAuditLogs: FC<InvoiceAuditLogsProps> = ({ invoiceId, version }) => {
    const { data: auditLogs } = useGetInvoiceAuditLogsQuery(
        { invoiceId },
        { skip: version !== InvoiceBackendVersion.V1 }
    )

    const events = useAppSelector(selectInvoiceEvents)

    const allInvolvedUsers = useMemo(() => (auditLogs || []).map((event) => event.userId), [auditLogs])
    const { users } = useGetAllUsersQuery(allInvolvedUsers)

    const auditLogsWithUsers = auditLogs?.map((event) => ({
        ...event,
        user: users.find((user) => user.id === event.userId),
    }))

    const combinedEvents = [...events, ...(auditLogsWithUsers || [])]

    return <InvoiceEvents events={combinedEvents} />
}
