import { Stack, Typography } from "@mui/material"
import { useState } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Button, Card, Loader } from "~/components"
import { useGetPaymentMethodDetailsQuery } from "~/domains/payment/payment-method-details/api/paymentMethodDetailsApi"
import { PaymentMethodDetailsCreateOrEditModal } from "~/domains/payment/payment-method-details/components/"
import { PaymentMethodDetailsSelector } from "~/domains/payment/payment-method-details/components/DocumentPaymentSection/PaymentMethodDetailsSelector"
import { PaymentMethodDetails, PaymentMethodDetailsFormType } from "~/domains/payment/payment-method-details/types"
import { PaymentMethodType } from "~/domains/payment/payment-methods/types"

const messages = defineMessages({
    payer: {
        id: "payment.payerPaymentMethodDetails.payer",
        defaultMessage: "Payer",
    },
    paymentMethod: {
        id: "payment.payerPaymentMethodDetails.paymentMethod",
        defaultMessage: "Payment method",
    },
    addPaymentMethod: {
        id: "payment.payerPaymentMethodDetails.addPaymentMethod",
        defaultMessage: "+ Add a payment method",
    },
})

interface PayerPaymentMethodDetailsProps {
    payerId: string
    loading?: boolean
    label?: string
    formType?: PaymentMethodDetailsFormType
    selectedPaymentMethodDetailsId: string | null
    onPaymentMethodChanged: (id: string, paymentMethodId?: string | null) => void
}

export const PayerPaymentMethodDetails = ({
    payerId,
    label,
    selectedPaymentMethodDetailsId,
    onPaymentMethodChanged,
    formType = PaymentMethodDetailsFormType.MODAL,
    loading = false,
}: PayerPaymentMethodDetailsProps) => {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState(false)
    const { data, isLoading, isFetching } = useGetPaymentMethodDetailsQuery({ owner: payerId })
    const items = data?.items ?? []

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handlePaymentMethdDetailsChanged = (id: string) => {
        const item = items.find((item) => item.id === id)
        onPaymentMethodChanged(id, item?.payment_method_id)
    }

    const handlePaymentMethodDetailsCreated = ({ id, payment_method_id }: PaymentMethodDetails) => {
        if (onPaymentMethodChanged) {
            onPaymentMethodChanged(id, payment_method_id)
        }
    }

    if (isLoading || isFetching) {
        return <Loader small />
    }

    return (
        <Card>
            <Stack spacing={2}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4">{label ?? formatMessage(messages.payer)}</Typography>
                    <Button size="x-small" type="title" disabled={loading || open} onClick={handleOpen}>
                        {formatMessage(messages.addPaymentMethod)}
                    </Button>
                </Stack>

                {!open && items.length > 0 && (
                    <PaymentMethodDetailsSelector
                        label={formatMessage(messages.paymentMethod)}
                        value={selectedPaymentMethodDetailsId}
                        items={items}
                        loading={loading}
                        onItemSelect={handlePaymentMethdDetailsChanged}
                    />
                )}
                {open && (
                    <PaymentMethodDetailsCreateOrEditModal
                        activeTab={PaymentMethodType.CARD}
                        open
                        formType={formType}
                        organizationId={payerId}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        onPaymentMethodDetailsCreated={handlePaymentMethodDetailsCreated}
                    />
                )}
            </Stack>
        </Card>
    )
}
