import dayjs from "dayjs"

import {
    ApiRole,
    ApiRoleTemplate,
    ApiRoleWithEntityIds,
    RoleTemplate,
    RoleWithEntityIds,
} from "~/domains/identity/roles-permissions/types/RolesPermissions"
import { isRoleWithEntityIds } from "~/domains/identity/roles-permissions/utils/permissions"

const sortRoles = (roleA: ApiRole | ApiRoleWithEntityIds, roleB: ApiRole | ApiRoleWithEntityIds): number => {
    const dateA = dayjs(roleA.createdAt)
    const dateB = dayjs(roleB.createdAt)
    return dateB.valueOf() - dateA.valueOf()
}

export const adaptRolesFromApi = (roles: (ApiRole | ApiRoleWithEntityIds)[]): RoleWithEntityIds[] =>
    roles.sort(sortRoles).map((role) => ({
        id: role.id,
        name: role.name,
        description: role.description,
        organizationId: role.organizationId,
        creator: role.creator,
        userIds: isRoleWithEntityIds(role) ? role.userIds : [],
        teamIds: isRoleWithEntityIds(role) ? role.teamIds : [],
        permissionSetId: role.permissionSetId,
        roleTemplateId: role.roleTemplateId,
        permissions: role.permissions,
        createdAt: role.createdAt,
        isRoleTemplate: false,
    })) ?? []

export const adaptRoleTemplatesFromApi = (roleTemplates: ApiRoleTemplate[]): RoleTemplate[] =>
    roleTemplates.map((roleTemplate) => ({
        id: roleTemplate.id,
        name: roleTemplate.name,
        description: roleTemplate.description,
        permissionSetId: roleTemplate.permissionSetId,
        permissions: roleTemplate.permissions,
        isRoleTemplate: true,
    })) ?? []
