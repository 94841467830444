import { InvoiceAuditLogAPI } from "~/domains/transactions/invoices-v1/api/types/invoiceAuditLogApi.type"
import { EventI } from "~/domains/transactions/invoices/types/InvoiceTypes"

export const invoiceAuditLogFromApiAdapter = (auditLog: InvoiceAuditLogAPI): EventI => {
    return {
        type: auditLog.action,
        userId: auditLog.actionBy,
        timestamp: auditLog.createdAt,
    }
}
