import { PaymentBatch, ServerPaymentBatch } from "~/domains/payment/payment-batches/types"

export const paymentBatchFromApiAdapter = (paymentBatch: ServerPaymentBatch): PaymentBatch => {
    return {
        id: paymentBatch.id,
        name: paymentBatch.name,
        description: paymentBatch.description,
        recurrenceType: paymentBatch.recurrence_type,
        startDate: paymentBatch.start_date,
        endDate: paymentBatch.end_date,
        creatorUserId: paymentBatch.creator_user_id,
        recurrenceInterval: paymentBatch.recurrence_interval,
        aggregatePayments: paymentBatch.aggregate_payments,
        collectPayments: paymentBatch.collect_payments,
        ownerId: paymentBatch.owner_id,
        defaultTotalAmount: paymentBatch.default_total_amount,
        defaultCurrency: paymentBatch.default_currency,
        defaultPaymentMethodId: paymentBatch.default_payment_method_id,
        defaultPaymentMethodDetailsId: paymentBatch.default_payment_method_details_id,
    }
}

export const paymentBatchToApiAdapter = (paymentBatch: PaymentBatch): ServerPaymentBatch => {
    const { defaultPaymentMethodId, defaultPaymentMethodDetailsId } = paymentBatch
    return {
        id: paymentBatch.id,
        name: paymentBatch.name,
        description: paymentBatch.description,
        recurrence_type: paymentBatch.recurrenceType,
        start_date: paymentBatch.startDate,
        end_date: paymentBatch.endDate,
        creator_user_id: paymentBatch.creatorUserId,
        recurrence_interval: paymentBatch.recurrenceInterval,
        aggregate_payments: paymentBatch.aggregatePayments,
        collect_payments: paymentBatch.collectPayments,
        owner_id: paymentBatch.ownerId,
        default_total_amount: paymentBatch.defaultTotalAmount,
        default_currency: paymentBatch.defaultCurrency,
        ...(defaultPaymentMethodId ? { default_payment_method_id: defaultPaymentMethodId } : {}),
        ...(defaultPaymentMethodDetailsId ? { default_payment_method_details_id: defaultPaymentMethodDetailsId } : {}),
    }
}
