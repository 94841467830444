import { styled } from "@mui/material"
import { FC } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"

import { IconButton } from "~/domains/orchestration/flows/components/IconButton"
import { SIDE_BAR_TOP_POSITION } from "~/domains/orchestration/flows/constants"

interface ToggleSideBarButtonProps {
    width: number
    isOpen: boolean
    onClick: () => void
}
const ToggleButton = styled(IconButton)<{ width: number }>(({ width = 0 }) => ({
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "20px",
    minHeight: "24px",
    top: `${SIDE_BAR_TOP_POSITION}px`,
    right: `${width}px`,
    marginRight: "-10px",
    backgroundColor: "var(--color-white)",
    border: "1px solid var(--color-border-workflow)",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    transition: "right 0.2s ease-in-out",
}))

export const ToggleSideBarButton: FC<ToggleSideBarButtonProps> = ({ width, isOpen, onClick }) => {
    return (
        <ToggleButton onClick={onClick} width={width}>
            {isOpen ? (
                <ChevronRight size={16} color="var(--color-grey-light)" />
            ) : (
                <ChevronLeft size={16} color="var(--color-grey-light)" />
            )}
        </ToggleButton>
    )
}
