/* eslint-disable no-nested-ternary */
import { Section } from "@column-resizer/react"
import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material"
import { useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { useResizableGridContext } from "~/components/Layout/ResizableGridContext"
import { useSaveBuffer } from "~/hooks/useSaveBuffer"
import { globalActions } from "~/store/global/globalSlice"

export interface ResizableColProps {
    children: React.ReactNode
    minSize?: number
    maxSize?: number
    defaultSize?: number
    gap?: number
    className?: string
}

export const ResizableCol = ({
    children,
    minSize,
    maxSize,
    defaultSize,
    gap = 2,
    className = "",
}: ResizableColProps) => {
    const theme = useTheme()
    const { stateKey } = useResizableGridContext()
    const [size, setSize] = useState(defaultSize)
    const sectionRef = useRef<HTMLDivElement>(null)

    const dispatch = useDispatch()

    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))

    const save = () => {
        if (!size || !sectionRef.current) return

        const parentElement = sectionRef.current?.parentElement
        const siblings = Array.from(parentElement?.children || []).filter(
            (child) => (child as HTMLElement).dataset.itemType === "SECTION"
        )
        const index = siblings.indexOf(sectionRef.current)

        dispatch(globalActions.setResizableGridState({ index, size, stateKey }))
    }

    const { setHasChanges } = useSaveBuffer(save)

    const handleSizeChange = (s: number) => {
        setSize(s)
        setHasChanges(true)
    }

    return isMobile ? (
        <Grid item className={className}>
            <Stack gap={gap}>{children}</Stack>
        </Grid>
    ) : (
        <Section
            minSize={minSize}
            maxSize={maxSize}
            defaultSize={defaultSize}
            ref={sectionRef}
            onSizeChanged={handleSizeChange}
            className={className}
        >
            <Stack gap={gap}>{children}</Stack>
        </Section>
    )
}
