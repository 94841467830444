import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/auth/ProtectedRoute"
import { PaymentsPage } from "~/domains/payment/payment/pages"
import { PAYMENTS } from "~/domains/payment/payment/routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="payments_routes">
            <Route path={PAYMENTS} element={<PaymentsPage />} />
        </Route>,
    ],
}
