import { BaseTextFieldProps, FormControl, FormControlOwnProps } from "@mui/material"
import React from "react"

import { Loader } from "~/components"
import { VatRateI, VatRateId } from "~/domains/transactions/invoices-v1/types/VatRate"
import { CountryCode, InvoiceBackendVersion } from "~/types"

import { useVatRates } from "../../hooks"
import { SelectVatRate } from "../SelectVatRate"
import { InvoiceLineTotalTaxField } from "./InvoiceLineTotalTaxField"

type FieldProps = BaseTextFieldProps & FormControlOwnProps

interface Props extends FieldProps {
    backendVersion: InvoiceBackendVersion
    countryCode: CountryCode
    vatRateId: VatRateId | null
    totalTax: number
    onTotalTaxChange: (value: number) => void
    onVatRateChange: (value: VatRateI | null) => void
    readonly?: boolean
    hideLabel?: boolean
}

export const InvoiceLineTaxField: React.FC<Props> = ({
    backendVersion,
    countryCode,
    vatRateId,
    onVatRateChange,
    totalTax,
    onTotalTaxChange,
    readonly,
    hideLabel,
    ...formControlProps
}) => {
    const vatRates = useVatRates(countryCode, backendVersion)

    if (!vatRates) {
        return (
            <FormControl {...(formControlProps as FormControlOwnProps)}>
                <Loader small />
            </FormControl>
        )
    }
    if (vatRates.length === 0) {
        return (
            <InvoiceLineTotalTaxField
                {...formControlProps}
                className={formControlProps.classes?.root}
                value={totalTax}
                onChange={onTotalTaxChange}
                inputProps={{
                    readOnly: readonly,
                }}
                aria-readonly={readonly}
                hideLabel={hideLabel}
            />
        )
    }
    const actualVatRate = vatRates.some((vatRate) => vatRate.id === vatRateId)
    return (
        <SelectVatRate
            {...(formControlProps as FormControlOwnProps)}
            value={actualVatRate ? vatRateId : null}
            onChange={onVatRateChange}
            vatRates={vatRates}
            readonly={readonly}
            hideLabel={hideLabel}
        />
    )
}
