import { IconButton } from "@mui/material"
import { Dispatch, FC, SetStateAction } from "react"
import { Mic } from "react-feather"

import { useSpeechToText } from "~/domains/orchestration/copilot/hooks"

interface SpeechToTextProps {
    handleChangeMessage: Dispatch<SetStateAction<string>>
    handleSendMessage: () => void
    setTextToSpeechEnabled: (value: boolean) => void
}

export const CopilotSpeechToText: FC<SpeechToTextProps> = ({
    handleChangeMessage,
    handleSendMessage,
    setTextToSpeechEnabled,
}) => {
    const { isActive, toggle, isEnabled } = useSpeechToText(
        handleChangeMessage,
        handleSendMessage,
        setTextToSpeechEnabled
    )

    if (!isEnabled) return null

    return (
        <IconButton onClick={toggle}>
            <Mic color={isActive ? "var(--color-red)" : "var(--secondary-color)"} />
        </IconButton>
    )
}
