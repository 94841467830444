import dayjs from "dayjs"
import React, { useEffect, useMemo, useState } from "react"

import { ValidationRule, ValidationSchema } from "~/domains/payment/payment-method-details/types"

type Errors<T extends object> = {
    [K in keyof T]?: React.ReactNode
}

export const useFormValidation = <T extends object>(values: T, validationSchema: ValidationSchema<T>) => {
    const [errors, setErrors] = useState<Errors<T>>({})

    const stableValidationSchema = useMemo(() => validationSchema, [validationSchema])

    const validateRequired = (fieldKey: keyof T, value: any, rules: ValidationRule<T>) => {
        if (rules.required?.value && !value) {
            return rules.required.message ?? `${String(fieldKey)} is required`
        }
    }

    const validatePattern = (fieldKey: keyof T, value: any, rules: ValidationRule<T>) => {
        if (rules.pattern?.value && typeof value === "string" && !rules.pattern.value.test(value)) {
            return rules.pattern.message ?? `${String(fieldKey)} is invalid`
        }
    }

    const validateMinLength = (fieldKey: keyof T, value: any, rules: ValidationRule<T>) => {
        if (rules.minLength?.value && typeof value === "string" && value.length < rules.minLength.value) {
            return (
                rules.minLength.message ?? `${String(fieldKey)} should be at least ${rules.minLength.value} characters`
            )
        }
    }

    const validateMaxLength = (fieldKey: keyof T, value: any, rules: ValidationRule<T>) => {
        if (rules.maxLength?.value && typeof value === "string" && value.length > rules.maxLength.value) {
            return (
                rules.maxLength.message ?? `${String(fieldKey)} should be at most ${rules.maxLength.value} characters`
            )
        }
    }

    const validateDate = (fieldKey: keyof T, value: any, rules: ValidationRule<T>) => {
        if (rules.date?.value && dayjs.isDayjs(value) && !value.isValid()) {
            return rules.date.message ?? `${String(fieldKey)} is invalid`
        }
    }

    const validateCustom = (_: keyof T, value: any, rules: ValidationRule<T>) => {
        if (rules.custom) {
            const customError = rules.custom(value, values)
            if (customError.value) {
                return customError.message
            }
        }
    }

    const validateField = (fieldKey: keyof T, value: any, rules: ValidationRule<T>) => {
        return (
            validateRequired(fieldKey, value, rules) ||
            validatePattern(fieldKey, value, rules) ||
            validateMinLength(fieldKey, value, rules) ||
            validateMaxLength(fieldKey, value, rules) ||
            validateDate(fieldKey, value, rules) ||
            validateCustom(fieldKey, value, rules)
        )
    }

    const validate = () => {
        const newErrors: Errors<T> = {}

        Object.keys(stableValidationSchema).forEach((key) => {
            const fieldKey = key as keyof T
            const value = values[fieldKey]
            const rules = stableValidationSchema[fieldKey]

            if (!rules) return

            const error = validateField(fieldKey, value, rules)
            if (error) {
                newErrors[fieldKey] = error
            }
        })

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    useEffect(() => {
        setErrors((prevErrors) => {
            const updatedErrors: Errors<T> = {}
            Object.keys(prevErrors).forEach((key) => {
                const fieldKey = key as keyof T
                if (!(prevErrors[fieldKey] && values[fieldKey])) {
                    updatedErrors[fieldKey] = prevErrors[fieldKey]
                }
            })
            return updatedErrors
        })
    }, [values])

    return { errors, validate }
}
