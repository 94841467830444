import { useCallback } from "react"

import { useCustomFieldsApi } from "~/domains/identity/custom-fields/customFieldsApi"
import { customFieldsActions } from "~/domains/identity/custom-fields/store"
import { CustomFieldI } from "~/domains/identity/custom-fields/types/CustomField"
import { useAppDispatch } from "~/store/hooks"

export const useUpdateCustomFieldValue = (objectId: string, customField: CustomFieldI | null) => {
    const customFieldsApi = useCustomFieldsApi()
    const dispatch = useAppDispatch()

    return useCallback(
        async (customFieldId: string | null, value: string) => {
            if (!customField || !customFieldId) return
            await customFieldsApi.updateCustomFieldValue(customFieldId, value)
            dispatch(customFieldsActions.updateCustomFieldValue({ objectId, customField, value }))
        },
        [dispatch, customFieldsApi, objectId, customField]
    )
}
