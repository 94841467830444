import { InputAdornment, Stack, TextField, Tooltip } from "@mui/material"
import React, { ChangeEventHandler, MouseEventHandler, ReactNode, useCallback } from "react"
import { Search } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { ActionsMenu, Button, Card, TooltipConditional } from "~/components"
import { useFilterProps } from "~/components/Filters/FilterProvider"
import { TagFilter } from "~/components/Filters/TagsFilter"
import { isTagFilterProps } from "~/components/Filters/utils/filters"
import { permissionMessages } from "~/domains/identity/roles-permissions/utils/permissions"
import { GlobalFilterType, selectFilterTypes } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"

const messages = defineMessages({
    filterLabel: {
        id: "dataTable.filters.SearchFieldLabel",
        defaultMessage: "Search",
    },
})

export interface DatatableFiltersActions {
    label: ReactNode
    action: () => void
    disabled?: boolean
}

interface DatatableFiltersProps {
    onChangeFilter: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    valueFilter: string
    onClickButton?: MouseEventHandler<HTMLButtonElement> | undefined
    labelButton?: string
    iconButton?: React.ReactNode
    actionsMenu?: boolean
    actions?: DatatableFiltersActions[]
    className?: string
    hasPermission?: boolean
}

export const DatatableFilters: React.FC<DatatableFiltersProps> = ({
    onChangeFilter,
    valueFilter,
    onClickButton,
    labelButton,
    iconButton,
    actionsMenu,
    actions,
    className,
    hasPermission,
}) => {
    const { formatMessage } = useIntl()
    const filterTypes = useAppSelector(selectFilterTypes)
    const filterProps = useFilterProps()

    const disabled = hasPermission === false

    const renderFilter = useCallback(
        (type: GlobalFilterType): React.ReactNode | null => {
            if (filterTypes.length === 0) return null

            const props = filterProps?.getFilterProps(type)
            if (!props) return null

            switch (type) {
                case GlobalFilterType.TAGS:
                    if (isTagFilterProps(props)) {
                        return <TagFilter key={type} {...props} />
                    }
            }

            return null
        },
        [filterProps, filterTypes]
    )

    return (
        <Card className={className} sx={{ width: "100%", marginBottom: 2 }}>
            <Stack alignItems="center" spacing={2} direction="row" width="100%" justifyContent="space-between">
                <TextField
                    name="datatable-filter"
                    placeholder={formatMessage(messages.filterLabel)}
                    onChange={onChangeFilter}
                    value={valueFilter}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="var(--color-grey-light)" size={14} />
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                />
                <Stack direction="row" alignItems="center" spacing={2}>
                    {filterTypes.map(renderFilter)}
                    {actions && actionsMenu && (
                        <TooltipConditional
                            title={formatMessage(permissionMessages.errorNoAccessAdministrator)}
                            condition={disabled}
                            placement="top"
                            arrow
                        >
                            <div>
                                <ActionsMenu
                                    actions={actions}
                                    labelButton={labelButton}
                                    disabled={disabled}
                                    buttonType="primary"
                                    size="small"
                                />
                            </div>
                        </TooltipConditional>
                    )}

                    {!actionsMenu && !actions && labelButton && onClickButton && (
                        <Tooltip
                            title={hasPermission ? null : formatMessage(permissionMessages.errorNoAccessAdministrator)}
                        >
                            <div>
                                <Button type="primary" onClick={onClickButton} disabled={disabled}>
                                    {labelButton}
                                    {iconButton}
                                </Button>
                            </div>
                        </Tooltip>
                    )}
                </Stack>
            </Stack>
        </Card>
    )
}
