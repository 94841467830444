import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"

const usePartnerBrandname = (partnerOrganizationId: OrganizationId | undefined, defaultName?: string): string => {
    const brandNames = useAppSelector(selectPartnersBrandNames)
    const fallback = defaultName ?? ""
    if (partnerOrganizationId) {
        return brandNames[partnerOrganizationId] ?? fallback
    }
    return fallback
}

export default usePartnerBrandname
