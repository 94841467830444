import { MessageDescriptor, defineMessages } from "react-intl"

import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types"

const approvalObjectType: Record<ApprovalObjectType, MessageDescriptor> = defineMessages({
    [ApprovalObjectType.INVOICE]: {
        id: "approval.objectType.invoice",
        defaultMessage: "Invoice",
    },
    [ApprovalObjectType.ORGANIZATION_RELATIONSHIP]: {
        id: "approval.objectType.partner",
        defaultMessage: "Partner",
    },
    [ApprovalObjectType.BUDGET]: {
        id: "approval.objectType.budget",
        defaultMessage: "Budget",
    },
    [ApprovalObjectType.PURCHASE_REQUEST]: {
        id: "approval.objectType.pr",
        defaultMessage: "Purchase request",
    },
    [ApprovalObjectType.PURCHASE_ORDER]: {
        id: "approval.objectType.po",
        defaultMessage: "Purchase order",
    },
    [ApprovalObjectType.PAYMENT_METHOD_DETAILS]: {
        id: "approval.objectType.paymentMethodDetails",
        defaultMessage: "Payment method details",
    },
    [ApprovalObjectType.CONTACT]: {
        id: "approval.objectType.contact",
        defaultMessage: "Contact",
    },
    [ApprovalObjectType.PAYMENT]: {
        id: "approval.objectType.payment",
        defaultMessage: "Payment",
    },
    [ApprovalObjectType.PURCHASE_ORDER_LINE]: {
        id: "approval.objectType.poLine",
        defaultMessage: "Purchase order line",
    },
    [ApprovalObjectType.PURCHASE_REQUEST_LINE]: {
        id: "approval.objectType.prLine",
        defaultMessage: "Purchase request line",
    },
    [ApprovalObjectType.PARTNER_PAYMENT_METHOD_DETAILS]: {
        id: "approval.objectType.partnerPaymentMethodDetails",
        defaultMessage: "Partner payment method details",
    },
})

const customRefusalReasons = defineMessages({
    title: {
        id: "approval.customRefusalReasons.title",
        defaultMessage: "Add custom refusal reasons",
    },
    editReason: {
        id: "approval.customRefusalReasons.editReason",
        defaultMessage: "Edit reason",
    },
    addReason: {
        id: "approval.customRefusalReasons.addReason",
        defaultMessage: "Type a new refusal reason",
    },
    error: {
        id: "approval.customRefusalReasons.error",
        defaultMessage: "Failed to save custom refusal reasons",
    },
})

const objectTypeSelector = defineMessages({
    title: {
        id: "approval.objectTypeSelector.title",
        defaultMessage: "Custom refusal reasons",
    },
    selectObjectType: {
        id: "approval.objectTypeSelector.label",
        defaultMessage: "Select object type",
    },
    customRefusalReasonsAlert: {
        id: "approval.objectTypeSelector.alert",
        defaultMessage: "This will replace the default refusal reasons for this object type.",
    },
})

export { approvalObjectType, customRefusalReasons, objectTypeSelector }
