import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { CatalogProduct } from "~/domains/transactions/catalog-v1/types/CatalogProduct.type"
import { CatalogSearchQuery } from "~/domains/transactions/catalog-v1/types/CatalogProductSearch.type"
import { CatalogProductVariant } from "~/domains/transactions/catalog-v1/types/CatalogProductVariant.type"
import { CatalogProductVariantPrice } from "~/domains/transactions/catalog-v1/types/CatalogProductVariantPrice.type"
import { PaginatedProductVersionI, ProductId, ProductVersionI } from "~/domains/transactions/catalog/types"
import { RootState } from "~/store"
import { OrganizationId } from "~/types"
import { FetchError } from "~/utils/apiClient/errors"

import { CatalogState } from "./catalogStore"

const initialState: CatalogState = {
    productVersionsByContext: {},
    searchQuery: {},
    selectedVariant: null,
    selectedProduct: null,
    selectedPrice: null,
}

const defaultProductVersionState = {
    loading: false,
    pagination: null,
    selectedCatalogs: [],
    products: [],
    query: "",
}

const catalogSlice = createSlice({
    name: "catalog",
    initialState,
    reducers: {
        fetchCatalog(
            state,
            { payload: { context, organizationId } }: PayloadAction<{ context: string; organizationId: OrganizationId }>
        ) {
            state.productVersionsByContext[context] = {
                ...(state.productVersionsByContext[context] ?? defaultProductVersionState),
                organizationId: organizationId,
                loading: true,
                fetchError: null,
            }
        },
        fetchCatalogSuccess(
            state,
            { payload: { context, result } }: PayloadAction<{ context: string; result: PaginatedProductVersionI }>
        ) {
            state.productVersionsByContext[context] = {
                ...(state.productVersionsByContext[context] ?? defaultProductVersionState),
                loading: false,
                pagination: result.pagination,
                products: result.results,
            }
        },
        fetchCatalogFailure(
            state,
            {
                payload: { context, error },
            }: PayloadAction<{ context: string; error: FetchError<PaginatedProductVersionI> }>
        ) {
            if (state.productVersionsByContext[context]) {
                state.productVersionsByContext[context] = {
                    ...state.productVersionsByContext[context],
                    loading: false,
                    fetchError: error,
                }
            }
        },
        setCatalogQueryFilter(
            state,
            { payload: { context, query } }: PayloadAction<{ context: string; query: string }>
        ) {
            state.productVersionsByContext[context] = {
                ...(state.productVersionsByContext[context] ?? defaultProductVersionState),
                query,
            }
        },
        addProduct(
            state,
            { payload: { context, product } }: PayloadAction<{ context: string; product: ProductVersionI }>
        ) {
            const currentState = state.productVersionsByContext[context] ?? defaultProductVersionState
            state.productVersionsByContext[context] = {
                ...currentState,
                products: [product, ...currentState.products],
            }
        },
        addProducts(
            state,
            { payload: { context, products } }: PayloadAction<{ context: string; products: ProductVersionI[] }>
        ) {
            const currentState = state.productVersionsByContext[context] ?? defaultProductVersionState
            state.productVersionsByContext[context] = {
                ...currentState,
                products: [...products, ...currentState.products],
            }
        },
        updateProduct(
            state,
            { payload: { context, product } }: PayloadAction<{ context: string; product: ProductVersionI }>
        ) {
            const currentState = state.productVersionsByContext[context] ?? defaultProductVersionState
            state.productVersionsByContext[context] = {
                ...currentState,
                products: currentState.products.map((currentProduct) =>
                    currentProduct.id === product.id ? product : currentProduct
                ),
            }
        },
        deleteProduct(
            state,
            { payload: { context, productId } }: PayloadAction<{ context: string; productId: ProductId }>
        ) {
            const currentState = state.productVersionsByContext[context]
            if (currentState) {
                state.productVersionsByContext[context] = {
                    ...currentState,
                    products: currentState.products.filter((currentProduct) => currentProduct.productId !== productId),
                }
            }
        },
        // V1
        setSearchQuery(state, { payload: { query } }: PayloadAction<{ query: CatalogSearchQuery | null }>) {
            state.searchQuery = query === null ? { reset: true } : { ...state.searchQuery, ...query, reset: false }
        },
        setSelectedVariant(state, actions: PayloadAction<{ variant: CatalogProductVariant | null }>) {
            state.selectedVariant = actions.payload.variant
        },
        setSelectedProduct(state, actions: PayloadAction<{ product: CatalogProduct | null }>) {
            state.selectedProduct = actions.payload.product
        },
        setSelectedPrice(state, actions: PayloadAction<{ price: CatalogProductVariantPrice | null }>) {
            state.selectedPrice = actions.payload.price
        },
    },
})

export const catalogActions = catalogSlice.actions
export const catalogReducer = catalogSlice.reducer

export const selectProductsVersionsByContext = (state: RootState) => state.catalog.productVersionsByContext
export const selectSearchQuery = (state: RootState) => state.catalog.searchQuery
export const selectSelectedVariant = (state: RootState) => state.catalog.selectedVariant
export const selectSelectedProduct = (state: RootState) => state.catalog.selectedProduct
export const selectSelectedPrice = (state: RootState) => state.catalog.selectedPrice
