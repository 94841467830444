import { Stack } from "@mui/material"
import classNames from "classnames"
import { defineMessages, useIntl } from "react-intl"

import { AmountInList } from "~/domains/transactions/_shared/components/AmountInList"
import { CurrencyCodes } from "~/types"

import "./TotalAmount.scss"

const messages = defineMessages({
    taxLabel: { id: "common.taxLabel", defaultMessage: "Tax excl." },
})

interface TotalAmountProps {
    amount: number
    currency: CurrencyCodes
    size?: "small" | "normal"
    taxLabel?: string
    variant?: "default" | "primary" | "secondary"
}

export function TotalAmount({ amount, currency, taxLabel, variant = "default", size = "normal" }: TotalAmountProps) {
    const { formatMessage } = useIntl()

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            flexWrap="wrap"
            className={classNames("total-amount", variant, size)}
        >
            <AmountInList amount={amount} currency={currency} />
            <small>({taxLabel || formatMessage(messages.taxLabel)})</small>
        </Stack>
    )
}
