import * as Sentry from "@sentry/browser"
import { useCallback } from "react"
import { defineMessage, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { organizationApi } from "~/domains/identity/organization/api/organisationApi"
import { CountryCode, OrganizationRegistrationI } from "~/types"

const errorMessage = defineMessage({
    id: "organization.create.error",
    defaultMessage: "Une erreur est survenue lors de la création de l'organisation",
})

export const useCreateOrganization = () => {
    const { formatMessage } = useIntl()

    return useCallback(
        async (
            name: string,
            countryCode: CountryCode,
            identifier: string,
            registration?: OrganizationRegistrationI
        ) => {
            try {
                const result = await organizationApi.createOrganization(name, countryCode, identifier)
                if (registration) {
                    await organizationApi.provideRegistration(result.id, registration)
                }
                return result
            } catch (error) {
                toast.error(formatMessage(errorMessage))
                Sentry.captureException(error, {
                    extra: { name, countryCode, identifier, registration },
                })
                return null
            }
        },
        []
    )
}
