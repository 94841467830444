import { PaymentBatchInstance, ServerPaymentBatchInstance } from "~/domains/payment/payment-batches/types"

export const paymentBatchInstanceFromApiAdapter = (
    paymentBatchInstance: ServerPaymentBatchInstance
): PaymentBatchInstance => {
    return {
        id: paymentBatchInstance.id,
        paymentTransactionBatchId: paymentBatchInstance.payment_transaction_batch_id,
        name: paymentBatchInstance.name,
        ownerId: paymentBatchInstance.owner_id,
        dateScheduled: paymentBatchInstance.date_scheduled,
        dateExecuted: paymentBatchInstance.date_executed,
        status: paymentBatchInstance.status,
        totalAmount: paymentBatchInstance.total_amount,
        maxTotalAmount: paymentBatchInstance.max_total_amount,
        responsibleId: paymentBatchInstance.responsible_id,
        currency: paymentBatchInstance.currency,
        paymentMethodId: paymentBatchInstance.payment_method_id,
        paymentMethodDetailsId: paymentBatchInstance.payment_method_details_id,
    }
}

export const paymentBatchInstanceToApiAdapter = (
    paymentBatchInstance: PaymentBatchInstance
): ServerPaymentBatchInstance => {
    const { paymentMethodId, paymentMethodDetailsId } = paymentBatchInstance
    return {
        id: paymentBatchInstance.id,
        payment_transaction_batch_id: paymentBatchInstance.paymentTransactionBatchId,
        name: paymentBatchInstance.name,
        owner_id: paymentBatchInstance.ownerId,
        date_scheduled: paymentBatchInstance.dateScheduled,
        date_executed: paymentBatchInstance.dateExecuted,
        status: paymentBatchInstance.status,
        total_amount: paymentBatchInstance.totalAmount,
        max_total_amount: paymentBatchInstance.maxTotalAmount,
        responsible_id: paymentBatchInstance.responsibleId,
        currency: paymentBatchInstance.currency,
        ...(paymentMethodId ? { payment_method_id: paymentMethodId } : {}),
        ...(paymentMethodDetailsId ? { payment_method_details_id: paymentMethodDetailsId } : {}),
    }
}
