import { Feather } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { HeaderH1 } from "~/components"
import { CustomFormsList } from "~/domains/identity/custom-forms/components/CustomFormsList"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"

const messages = defineMessages({
    title: {
        id: "smartForms.header.title",
        defaultMessage: "Smart forms",
    },
})
export const OrganisationCustomForms = () => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const { formatMessage } = useIntl()

    return (
        <>
            <HeaderH1 title={formatMessage(messages.title)} icon={<Feather />} />
            {organizationId && <CustomFormsList organizationId={organizationId} />}
        </>
    )
}
