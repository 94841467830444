import * as Sentry from "@sentry/react"
import { useCallback } from "react"

import { useApproveObjectMutation, useRefuseObjectMutation } from "~/domains/orchestration/flows-v0/api/approvalApi"
import { ApprovalObjectType } from "~/domains/orchestration/flows-v0/types/Approval"

export const useProcessBulkReviewItems = () => {
    const [approveMutation] = useApproveObjectMutation()
    const [refuseMutation] = useRefuseObjectMutation()

    const processBulkReviewItems = useCallback(
        async (objectIds: string[], isApprove: boolean, objectType: ApprovalObjectType) => {
            try {
                // Create a promise array for all mutations
                const promises = objectIds.map(
                    (objectId) =>
                        isApprove
                            ? approveMutation({ objectId, objectType }) // Approve object
                            : refuseMutation({ objectId, objectType }) // Refuse object
                )
                // Wait for all promises to resolve
                return await Promise.all(promises)
            } catch (error) {
                Sentry.captureException(error)
                throw error // Rethrow the error to handle it further up if needed
            }
        },
        [approveMutation, refuseMutation]
    )

    return { processBulkReviewItems }
}
