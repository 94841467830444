export type CustomForm = {
    id: string
    name: string
    createdAt: string
    sections: CustomFormSection[]
}

export interface CustomFormSection {
    id: string
    title: string
    order: number
    questions: CustomFormQuestion[]
}

export interface UserSurveyApiResponse {
    id: string
    form: UserSurveyForm
    answers: UserSurveyAnswer[]
    status: CustomFormSurveyStatus
}

export interface SendFormResponse {
    id: string
    formId: string
    token: string
    email: string
    userId: string
    dueTimestamp: string
}

export interface CustomFormSurvey {
    id: string
    formId: string
    token: string
    respondentContacts: CustomFormResponder[]
    dueTimestamp: string
    createdAt: string
    respondentOrganizationId: string
    status: CustomFormSurveyStatus
    updatedAt: string
}

export interface CustomFormSurveyStatus {
    InProgress?: {
        percentage: number
    }
    // WIP: these are empty objects for now
    Completed?: object
    Created?: object
}

export interface CustomFormQuestion {
    id: string
    order: number
    questionContent: string
    required: boolean
    fieldType: QuestionFieldType
    savedValue: QuestionValue
    invalid?: boolean
}

export interface CustomFormSection {
    id: string
    title: string
    order: number
    questions: CustomFormQuestion[]
}

// These are used by form viewer:
export interface UserSurveyForm {
    id: string
    name: string
    createdAt: string
    sections: CustomFormSection[]
}

export type QuestionValue = string | number | string[] | Date | boolean

export type QuestionFieldType =
    | { YesNo: string }
    | { TextField: string }
    | { TextArea: string }
    | { NumericField: { minValue: number; maxValue: number; unit: string } }
    | { SingleChoice: { choices: string[] } }
    | { MultiChoice: { choices: string[] } }
    | { DateField: { minDate: string; maxDate: string } }

export enum QuestionFieldTypeEnum {
    YesNo = "YesNo",
    TextField = "TextField",
    TextArea = "TextArea",
    NumericField = "NumericField",
    SingleChoice = "SingleChoice",
    MultiChoice = "MultiChoice",
    DateField = "DateField",
}

export interface UserSurveyAnswer {
    questionId: string
    answer: {
        TextFieldAnswer?: {
            value: string
        }
        TextAreaAnswer?: {
            value: string
        }
        NumericFieldAnswer?: {
            value: number
        }
        MultiChoiceAnswer?: {
            value: string[]
        }
        SingleChoiceAnswer?: {
            value: string
        }
        YesNoAnswer?: {
            value: boolean
        }
    }
}

export interface CustomFormResponder {
    userId: string
    email: string
}

export interface FormViewerContextType {
    formData: UserSurveyForm | null
    currentSection: number
    setCurrentSection: React.Dispatch<React.SetStateAction<number>>
    formSent: boolean
    viewMode?: string
    currentSectionInvalid: boolean
    validateSection: () => boolean
    handleSaveAnswer: (question: CustomFormQuestion, value: QuestionValue) => void
    focusQuestionId: string | null
    hiddenQuestions: string[]
    surveyStateLoading: boolean
    sendForm: () => void
}

export interface UserSurveyStats extends CustomFormSurvey {
    id: string
    alertScore: number
    metrics: UserSurveyStatsMetrics
}

export interface UserSurveyStatsMetrics {
    sections: UserSurveySection[]
    total: UserSurveyMetric
}

export interface UserSurveySection {
    sectionId: string
    name: string
    metric: UserSurveyMetric
}

export interface UserSurveyMetric {
    value: number
    maxAvailable: number
}

export interface UserSurveyState {
    status: CustomFormSurveyStatus
    questions: UserSurveyQuestionState[]
}

export interface UserSurveyQuestionState {
    questionId: string
    visible: boolean
    hiddenReason?: string
}
