import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"

import { useTagsApi } from "~/domains/analytics/tags/tagsApi"
import {
    CreateTagObjectI,
    SelectedTagI,
    SelectedTagWithObjectIdI,
    TagGroupI,
    TagI,
    TagId,
    TagObjectI,
    TagObjectType,
} from "~/domains/analytics/tags/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { Result, getResultSuccessValue, isResultSuccess } from "~/types/Result"
import { ParsingErrorType } from "~/utils"
import { isDefined } from "~/utils/isDefined"

import { selectDuplicationSuccessful, tagsActions } from "../store/tagsSlice"
import { useCreateTagObject } from "./useCreateTagObject"
import { useDeleteTagObject } from "./useDeleteTagObject"
import { useUpdateTagObject } from "./useUpdateTagObject"

const reduceByTagId = (acc: Set<TagId>, tag: TagI) => {
    acc.add(tag.tagId)
    return acc
}

const groupTagsFromTagGroup = (acc: Record<TagId, TagI>, tagGroup: TagGroupI) => {
    return tagGroup.tags.reduce((acc, tag) => {
        acc[tag.tagId] = tag
        return acc
    }, acc)
}

type TagSelection = {
    tagId: TagId
    ratio?: number
}

export const manageSelectedTags = (
    previousState: (SelectedTagI | SelectedTagWithObjectIdI)[] | null,
    fn:
        | ((state: (SelectedTagI | SelectedTagWithObjectIdI)[]) => (SelectedTagI | SelectedTagWithObjectIdI)[])
        | (SelectedTagI | SelectedTagWithObjectIdI)[],
    createTagObject: (
        payload: Omit<CreateTagObjectI, "objectId">,
        objId?: string | string[]
    ) => Promise<
        Result<TagObjectI, ParsingErrorType> | PromiseSettledResult<Result<TagObjectI, ParsingErrorType>>[] | undefined
    >,
    deleteTagObject: (
        tagId: TagId,
        objId?: string | string[]
    ) => Promise<boolean | PromiseSettledResult<boolean>[] | undefined>,
    updateTagObject: (
        payload: Omit<CreateTagObjectI, "objectId">,
        objId?: string | string[]
    ) => Promise<boolean | PromiseSettledResult<boolean>[] | undefined>
) => {
    const previousStateTagIds = (previousState || []).reduce(reduceByTagId, new Set<TagId>())
    const newState = Array.isArray(fn) ? fn : fn(previousState ?? [])
    const newStateTagIds = newState.reduce(reduceByTagId, new Set<TagId>())
    const deletedTags = (previousState || []).filter((tag) => !newStateTagIds.has(tag.tagId))
    const addedTags = newState.filter((tag) => !previousStateTagIds.has(tag.tagId))
    const updatedTags = newState.filter(
        (tag) =>
            previousStateTagIds.has(tag.tagId) && tag.ratio !== previousState?.find((t) => t.tagId === tag.tagId)?.ratio
    )
    deletedTags.forEach((tagToDelete) => {
        deleteTagObject(tagToDelete.tagId, "objectId" in tagToDelete ? tagToDelete.objectId : undefined)
    })
    updatedTags.forEach((tagToUpdate) => {
        updateTagObject(
            { tagId: tagToUpdate.tagId, ratio: tagToUpdate.ratio },
            "objectId" in tagToUpdate ? tagToUpdate.objectId : undefined
        )
    })
    addedTags.forEach((tagToAdd) => {
        createTagObject(
            {
                tagId: tagToAdd.tagId,
                ratio: tagToAdd.ratio,
                objectContext: tagToAdd.fromRecommandation
                    ? { fromRecommandation: tagToAdd.fromRecommandation }
                    : undefined,
            },
            "objectId" in tagToAdd ? tagToAdd.objectId : undefined
        )
    })
    return newState
}

export const useTagsForObject = (
    organizationId: OrganizationId,
    objectId: string | string[],
    userId: string,
    tagGroups: TagGroupI[] | null,
    objectType?: TagObjectType,
    context?: any,
    contextId?: string
): [TagI[] | null, Dispatch<SetStateAction<TagI[]>>] => {
    const tagsApi = useTagsApi()
    const [selectedTagIds, setSelectedTagIds] = useState<TagSelection[] | null>(null)
    const [selectedTags, setSelectedTags] = useState<SelectedTagI[] | null>(null)
    const reloadTags = useAppSelector(selectDuplicationSuccessful)
    const dispatch = useAppDispatch()

    const getTagsForObject = useCallback(async () => {
        if (Array.isArray(objectId)) {
            setSelectedTagIds([])
        } else if (objectId) {
            const res = await tagsApi.getTagObjects(organizationId, objectId)
            setSelectedTagIds(
                res
                    .filter(isResultSuccess)
                    .map(getResultSuccessValue)
                    .map((tagObject) => ({ tagId: tagObject.tagId, ratio: tagObject.ratio ?? undefined }))
            )
        }
    }, [tagsApi, organizationId, objectId])

    useEffect(() => {
        if (tagGroups && selectedTagIds) {
            const tagsById = tagGroups.reduce(groupTagsFromTagGroup, {})
            setSelectedTags(
                selectedTagIds
                    .map((selectedTag) => ({ ...tagsById[selectedTag.tagId], ratio: selectedTag.ratio }))
                    .filter(isDefined)
            )
        }
    }, [tagGroups, selectedTagIds])

    const createTagObject = useCreateTagObject(organizationId, objectId, objectType, context, contextId)
    const updateTagObject = useUpdateTagObject(organizationId, objectId, objectType, context, contextId)
    const deleteTagObject = useDeleteTagObject(organizationId, objectId)

    const setSelectedTagsWraper = useCallback(
        (fn: ((state: SelectedTagI[]) => SelectedTagI[]) | SelectedTagI[]) => {
            setSelectedTags((previousState) =>
                manageSelectedTags(previousState, fn, createTagObject, deleteTagObject, updateTagObject)
            )
        },
        [setSelectedTags, userId, createTagObject, updateTagObject, deleteTagObject]
    )

    useEffect(() => {
        getTagsForObject()
    }, [getTagsForObject])

    // reloading of tags
    useEffect(() => {
        if (reloadTags) {
            getTagsForObject()
            dispatch(tagsActions.resetTagsDuplicationSuccess())
        }
    }, [reloadTags, getTagsForObject])

    return [selectedTags, setSelectedTagsWraper]
}
