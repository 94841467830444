import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { bookOfRelationsActions } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import {
    ComplianceProcessReadDto,
    ComplianceProcessUpdateDto,
    ElliproComplianceRiskI,
    LsegComplianceRiskI,
} from "~/domains/identity/partners/types/OrganizationComplianceRisk"
import { PartnersDataI, PartnersReadDto } from "~/domains/identity/partners/types/Partners"
import { store } from "~/store"
import { OrganizationId } from "~/types"

const BASE_URL = import.meta.env.VITE_API_BOOK_OF_RELATIONS_URL + "v1"

export const partnersApiV1 = createApi({
    reducerPath: "partnersApiV1",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        getPartners: builder.query<PartnersDataI[], PartnersReadDto>({
            query: ({ organizationId, withMetrics, withDetails }) => {
                if (!organizationId) {
                    return { url: "" }
                }

                return {
                    url: `/organizations/${organizationId}/partners`,
                    params: {
                        ...(withMetrics && { withMetrics: true }),
                        ...(withDetails && { withDetails: true }),
                    },
                }
            },
            transformResponse: (response: PartnersDataI[]) => {
                const organizationNames: Record<OrganizationId, string> = {}

                response.forEach((partner: PartnersDataI) => {
                    organizationNames[partner.organizationId] = partner.brandName || partner.organizationName
                })
                store.dispatch(bookOfRelationsActions.setPartnerBrandNames(organizationNames))
                store.dispatch(bookOfRelationsActions.setPartners(response))

                return response
            },
        }),
        getComplianceProcesses: builder.query<ElliproComplianceRiskI | LsegComplianceRiskI, ComplianceProcessReadDto>({
            query: ({ organizationId, complianceProcessId, complianceProcess, internalIds }) => {
                if (!organizationId || (!internalIds?.length && !complianceProcessId)) {
                    return { url: "" }
                }

                let url = `/organizations/${organizationId}/${complianceProcess}`

                if (complianceProcessId) {
                    url += `/${complianceProcessId}`
                }

                return {
                    url,
                    params: internalIds ? { internalIds: internalIds.join(",") } : undefined,
                }
            },
        }),
        updatePartnerComplianceRisk: builder.mutation<void, ComplianceProcessUpdateDto>({
            query: ({ organizationId, complianceProcess, complianceProcessId, complianceRiskDto }) => ({
                url: `/organizations/${organizationId}/${complianceProcess}/${complianceProcessId}`,
                method: "PATCH",
                body: complianceRiskDto,
            }),
        }),
    }),
})

export const {
    useGetPartnersQuery,
    useLazyGetPartnersQuery,
    useGetComplianceProcessesQuery,
    useUpdatePartnerComplianceRiskMutation,
} = partnersApiV1
