import { InvoiceFieldLevel } from "~/domains/transactions/invoices-v1/types/Invoice"
import { formatCustomFields } from "~/domains/transactions/invoices-v1/utils/formatCustomFields"
import { InvoiceI, InvoiceLineI } from "~/domains/transactions/invoices/types/InvoiceTypes"

export const appendInvoiceCustomFieldsToLines = (invoice: InvoiceI, organizationId: string) => {
    return (invoice.lineItems || []).map((line) => {
        return appendInvoiceCustomFieldsToLine(line, invoice, organizationId)
    })
}

export const appendInvoiceCustomFieldsToLine = (line: InvoiceLineI, invoice: InvoiceI, organizationId: string) => {
    if (!line || !organizationId) return line

    const party = invoice.parties?.[organizationId || ""]
    const lineParty = line.parties?.[organizationId]

    const customFieldsFormatted = formatCustomFields(party, InvoiceFieldLevel.Line, lineParty)

    return {
        ...line,
        customFields: customFieldsFormatted,
    }
}
