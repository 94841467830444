import { Catalog } from "~/domains/transactions/catalog-v1/types/Catalog.type"
import { CatalogProduct } from "~/domains/transactions/catalog-v1/types/CatalogProduct.type"
import {
    CatalogSearch,
    CatalogSearchAPIResponse,
} from "~/domains/transactions/catalog-v1/types/CatalogProductSearch.type"
import { CatalogProductVariant } from "~/domains/transactions/catalog-v1/types/CatalogProductVariant.type"
import { CatalogProductVariantPrice } from "~/domains/transactions/catalog-v1/types/CatalogProductVariantPrice.type"

export const catalogFromApiAdapters = (catalog: Catalog): Catalog => {
    return {
        id: catalog.id,
        organizationId: catalog.organizationId,
        supplierId: catalog.supplierId,
        name: catalog.name,
        nameTranslations: catalog.nameTranslations,
        description: catalog.description,
        descriptionTranslations: catalog.descriptionTranslations,
        catalogType: catalog.catalogType,
        isActive: catalog.isActive,
        accessType: catalog.accessType,
        sharedWith: catalog.sharedWith,
        createdAt: catalog.createdAt,
        updatedAt: catalog.updatedAt,
    }
}

export const productFromApiAdapters = (product: CatalogProduct): CatalogProduct => {
    return {
        id: product.id,
        catalogId: product.catalogId,
        defaultPrice: priceFromApiAdapters(product.defaultPrice),
        prices: (product.prices || []).map(priceFromApiAdapters),
        defaultMedia: product.defaultMedia,
        media: product.media,
        variants: (product.variants || []).map(productVariantFromApiAdapters),
        organizationId: product.organizationId,
        sku: product.sku,
        label: product.label,
        labelTranslations: product.labelTranslations,
        description: product.description,
        descriptionTranslations: product.descriptionTranslations,
        status: product.status,
        unitQuantity: product.unitQuantity,
        brand: product.brand,
        isDefault: product.isDefault,
        createdAt: product.createdAt,
        updatedAt: product.updatedAt,
    }
}

export const productVariantFromApiAdapters = (productVariant: CatalogProductVariant): CatalogProductVariant => {
    return {
        id: productVariant.id,
        productId: productVariant.productId,
        sku: productVariant.sku,
        label: productVariant.label,
        labelTranslations: productVariant.labelTranslations,
        description: productVariant.description,
        descriptionTranslations: productVariant.descriptionTranslations,
        defaultPrice: priceFromApiAdapters(productVariant.defaultPrice),
        defaultPriceId: productVariant.defaultPriceId,
        defaultMedia: productVariant.media?.[0],
        features: productVariant.features,
        status: productVariant.status,
        media: productVariant.media,
        createdAt: productVariant.createdAt,
        updatedAt: productVariant.updatedAt,
        isDefault: productVariant.isDefault,
    }
}

export const priceFromApiAdapters = (price: CatalogProductVariantPrice): CatalogProductVariantPrice => {
    return {
        id: price?.id,
        variantId: price?.variantId,
        amount: price?.amount,
        currency: price?.currency,
        countryCode: price?.countryCode,
        minOrderThreshold: price?.minOrderThreshold,
        maxOrderThreshold: price?.maxOrderThreshold,
        incotermType: price?.incotermType,
        effectiveDate: price?.effectiveDate,
        expirationDate: price?.expirationDate,
        eligibleOrganizations: price?.eligibleOrganizations,
        createdAt: price?.createdAt,
        updatedAt: price?.updatedAt,
        isDefault: price?.isDefault,
    }
}

export const searchProductFromApiAdapters = (searchResult: CatalogSearchAPIResponse): CatalogSearch => {
    const defaultVariant = searchResult.product.variants[0]

    return {
        product: productFromApiAdapters(searchResult.product),
        variant: productVariantFromApiAdapters(defaultVariant),
        productId: searchResult.product.id,
        variantId: defaultVariant.id,
        catalogId: searchResult.product.catalogId,
        hostingCatalogs: searchResult.hostingCatalogs,
        matchingVariants: searchResult.matchingVariants,
    }
}
