import React from "react"

import { MetricsDashboardEmbed } from "~/domains/analytics/metrics/components/MetricsDashboardEmbed"
import { METRICS_DASHBOARD_ID } from "~/domains/analytics/metrics/types/Metrics"

export const Metrics = () => {
    return (
        <div>
            <MetricsDashboardEmbed dashboardId={METRICS_DASHBOARD_ID} />
        </div>
    )
}
