import { Stack } from "@mui/material"
import { FC, Fragment } from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { TotalAmount } from "~/domains/transactions/_shared/components/TotalAmount/TotalAmount"
import { InvoiceI } from "~/types"
import { CurrencyCodes } from "~/types"

const messages = defineMessages({
    discountLabel: { id: "common.discount", defaultMessage: "Discount" },
})

interface InvoiceTotalAmountProps {
    invoice: InvoiceI
}

export const InvoiceTotalAmount: FC<InvoiceTotalAmountProps> = ({ invoice }) => {
    const { formatMessage } = useIntl()

    const hasDiscount = Object.values(invoice.flatDiscount || {}).some((discount) => +discount > 0)

    return (
        <Card>
            <Stack spacing={1.5}>
                {Object.entries(invoice.totalAmountDue || {}).map(([currency, amount]) => (
                    <Fragment key={currency}>
                        <TotalAmount amount={+amount} currency={currency as CurrencyCodes} />
                        {invoice.flatDiscount && hasDiscount ? (
                            <TotalAmount
                                amount={+invoice.flatDiscount[currency]}
                                currency={currency as CurrencyCodes}
                                variant="primary"
                                taxLabel={formatMessage(messages.discountLabel)}
                                size="small"
                            />
                        ) : null}
                    </Fragment>
                ))}
            </Stack>
        </Card>
    )
}
