import { TextField, Typography } from "@mui/material"
import React from "react"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { PurchaseViewType } from "~/domains/transactions/_shared/types/Purchases"

const messages = defineMessages({
    description: {
        id: "purchase.requests.request.description",
        defaultMessage: "Description",
    },
})

interface Props {
    description: string
    pageMode?: PurchaseViewType
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export function Description({ description, pageMode = PurchaseViewType.VIEW, handleChange }: Props) {
    const { formatMessage } = useIntl()
    const editable = pageMode === PurchaseViewType.EDIT || pageMode === PurchaseViewType.ADD

    return (
        <Card title={formatMessage(messages.description)}>
            {editable ? (
                <TextField
                    required
                    id="description"
                    name="description"
                    label={formatMessage(messages.description)}
                    placeholder={formatMessage(messages.description)}
                    onChange={handleChange}
                    value={description}
                    inputProps={{ readOnly: !editable }}
                    fullWidth
                    className="mb-12"
                    aria-readonly={!editable}
                />
            ) : (
                <Typography paddingBlock={2}>{description}</Typography>
            )}
        </Card>
    )
}
