import { Stack, styled } from "@mui/material"
import React from "react"
import { ChevronLeft } from "react-feather"
import { useIntl } from "react-intl"

import { ObjectPermissions } from "~/domains/identity/roles-permissions/components/ShareObjectPermissionsModal/ObjectPermissions"
import {
    SpiceDBObjectType,
    SpiceDBPermissionsLiteI,
    sharedObjectPermissionsModalMessages,
} from "~/domains/identity/roles-permissions/types/SpiceDB"

const SharedObjectsHeader = styled(Stack)({
    margin: "8px 0 16px 0",
    cursor: "pointer",
})

interface Props {
    objectId: string
    objectType: SpiceDBObjectType
    sharedPermissions?: SpiceDBPermissionsLiteI[] | null
    setShowOnlyList: (showOnlyList: boolean) => void
}

export const OnlyListView: React.FC<Props> = ({ objectId, objectType, sharedPermissions, setShowOnlyList }) => {
    const { formatMessage } = useIntl()
    const hideList = () => setShowOnlyList(false)

    return (
        <>
            <SharedObjectsHeader direction="row" alignItems="center" gap={2} onClick={hideList}>
                <ChevronLeft size={24} />
                <span>
                    {formatMessage(sharedObjectPermissionsModalMessages.collaborators, {
                        count: sharedPermissions?.length ?? 0,
                    })}
                </span>
            </SharedObjectsHeader>
            <Stack direction="column" gap={1}>
                {sharedPermissions?.map((object) => (
                    <ObjectPermissions
                        key={object.objectId}
                        objectId={objectId}
                        objectType={objectType}
                        objectPermission={object}
                    />
                ))}
            </Stack>
        </>
    )
}
