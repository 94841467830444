/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Chip, capitalize } from "@mui/material"
import React, { useMemo } from "react"
import { MessageDescriptor, useIntl } from "react-intl"

import { commonMessages } from "~/common-messages"
import { globalStatus } from "~/constants/globalStatus"

interface StatusChipProps {
    status: string
    children: React.ReactNode | MessageDescriptor | string
}

export const StatusChip: React.FC<StatusChipProps> = ({ status = "", children }) => {
    const { formatMessage } = useIntl()

    const label = useMemo(() => {
        if (globalStatus[status]?.id) {
            return formatMessage(globalStatus[status])
        }

        if (commonMessages[status]) {
            return formatMessage(commonMessages[status])
        }

        if (children && typeof children === "object" && "id" in children) {
            return formatMessage(children)
        }

        if (typeof children === "string") {
            return capitalize(children.replace("_", " ").toLowerCase())
        }

        return ""
    }, [status, children, formatMessage])

    const statusColor = globalStatus[status]?.color || "default"

    return <Chip color={statusColor} label={label} variant="outlined" />
}
