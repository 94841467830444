import { lazy } from "react"
import { Route } from "react-router-dom"

import { ProtectedRoutes } from "~/components"
import {
    CATALOG_PRODUCT_ROUTE,
    CATALOG_V1_ROUTE,
    CATALOG_VARIANT_CREATE_ROUTE,
    CATALOG_VARIANT_ROUTE,
    CATALOG_VARIANT_VIEW_ROUTE,
} from "~/domains/transactions/catalog-v1/routes"

const PageCatalog = lazy(() =>
    import("~/domains/transactions/catalog-v1/pages").then(({ Catalog }) => ({
        default: Catalog,
    }))
)

const PageProduct = lazy(() =>
    import("~/domains/transactions/catalog-v1/pages").then(({ Product }) => ({
        default: Product,
    }))
)

const PageProductVariant = lazy(() =>
    import("~/domains/transactions/catalog-v1/pages").then(({ ProductVariant }) => ({
        default: ProductVariant,
    }))
)

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="profile_route">
            <Route path={CATALOG_V1_ROUTE} element={<PageCatalog />} />
            <Route path={CATALOG_VARIANT_ROUTE} element={<PageCatalog />} />
            <Route path={CATALOG_VARIANT_CREATE_ROUTE} element={<PageCatalog />} />
            <Route path={CATALOG_PRODUCT_ROUTE} element={<PageProduct />} />
            <Route path={CATALOG_VARIANT_VIEW_ROUTE} element={<PageProductVariant />} />
        </Route>,
    ],
}
