import * as Sentry from "@sentry/browser"
import { useCallback } from "react"
import { defineMessage, useIntl } from "react-intl"
import { toast } from "react-toastify"

import { invoiceApi } from "~/domains/transactions/invoices/api/invoiceApi"
import { getImportCompanyIdentifierOrEmptyString } from "~/store/invoice/core"
import { useCreateOrganization } from "~/store/organization/hooks"
import {
    ImportInvoiceCompanyInfoI,
    ImportingInvoiceI,
    InvoiceI,
    InvoiceUserType,
    NO_ORGANIZATION_ID,
    UpdateDraftInvoicePayload,
    UserI,
} from "~/types"

const errorMessage = defineMessage({
    id: "invoice.error.saveDraft",
    defaultMessage: "An error occured while saving the draft invoice",
})

const constructOrganizationPayload = (companyInfo: ImportInvoiceCompanyInfoI, isInitiator: boolean, user: UserI) => ({
    organizationId:
        companyInfo.organizationId && companyInfo.organizationId !== NO_ORGANIZATION_ID
            ? companyInfo.organizationId
            : null,
    involvedUserIds: isInitiator && user.id ? [user.id] : [],
    name: companyInfo.name,
    contactName: isInitiator ? user.fullName : companyInfo.contactName,
    registrations: {
        registrationNumber: companyInfo.registrationNumber ?? null,
        vatNumber: companyInfo.taxId ?? null,
    },
})

export const usePatchDraftInvoice = (
    invoice: ImportingInvoiceI | InvoiceI | null,
    initiator: InvoiceUserType,
    user: UserI
) => {
    const { formatMessage } = useIntl()
    const createOrganization = useCreateOrganization()

    const saveAsDraftAction = useCallback(
        async (isCreation?: boolean, newInvoice?: Partial<InvoiceI>) => {
            if (!invoice) return false

            const isBuyerInitiator = initiator === InvoiceUserType.BUYER
            const buyer = constructOrganizationPayload(invoice.buyer, isBuyerInitiator, user)
            const supplier = constructOrganizationPayload(invoice.supplier, !isBuyerInitiator, user)

            if (isCreation && !buyer.organizationId) {
                const result = await createOrganization(
                    buyer.name,
                    invoice.buyer.countryCode,
                    getImportCompanyIdentifierOrEmptyString(invoice.buyer)
                )
                buyer.organizationId = result?.id ?? null
            }
            if (isCreation && !supplier.organizationId) {
                const result = await createOrganization(
                    supplier.name,
                    invoice.supplier.countryCode,
                    getImportCompanyIdentifierOrEmptyString(invoice.supplier)
                )
                supplier.organizationId = result?.id ?? null
            }

            if (newInvoice) {
                invoice = { ...invoice, ...newInvoice }
            }

            try {
                await invoiceApi.saveDraft(invoice.id, {
                    ...invoice,
                    initiator,
                    buyer,
                    supplier,
                } as UpdateDraftInvoicePayload)
                return true
            } catch (error) {
                Sentry.captureException(error)
                toast.error(formatMessage(errorMessage))
                return false
            }
        },
        [invoice, initiator, user]
    )

    return saveAsDraftAction
}
