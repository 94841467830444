import { useCallback, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"

import { useGetBulkObjectsTags } from "~/domains/analytics/tags/hooks/useGetBulkObjectsTags"
import { selectSelectedTagsForFilter } from "~/domains/analytics/tags/store/tagsSlice"
import { SelectedTagI } from "~/domains/analytics/tags/types/Tag"
import { budgetApi } from "~/domains/transactions/budget/api/budgetApi"
import { BudgetDataI } from "~/domains/transactions/budget/types"
import { getIsConnected } from "~/store/auth/authSlice"
import { budgetActions, selectBudgetsData } from "~/store/budget/budgetSlice"
import { useAppSelector } from "~/store/hooks"
import { NO_ORGANIZATION_ID } from "~/types"
import { WHITE_SPACE_REGEXP } from "~/utils/string"

type FetchBudgetsResult = {
    budgetsData: BudgetDataI[]
    filteredBudgets?: BudgetDataI[]
    budgetsTree: BudgetDataI[]
    filteredBudgetsTree?: BudgetDataI[]
    loading: boolean
    error: string | null
    reFetchBudgetsData: () => void
}

const noFilter = () => true

const getBudgetFilter = (filter: string, selectedTagsForFilter: SelectedTagI[]) => {
    if ((!filter || !filter.length) && !selectedTagsForFilter.length) {
        return noFilter
    }
    const filterWords = filter.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
    return (budget: BudgetDataI) => {
        const nameWords = `${budget.name}`.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
        const descriptionWords = `${budget.description}`.toLocaleLowerCase().split(WHITE_SPACE_REGEXP)
        const budgetWords = [...nameWords, ...descriptionWords]

        const hasSelectedTags = selectedTagsForFilter.length
            ? selectedTagsForFilter.every((selectedTag) => budget.tags?.some((tag) => tag.tagId === selectedTag.tagId))
            : true

        const matchesSearchWords = filterWords.every((word) =>
            budgetWords.some((budgetWord) => budgetWord.indexOf(word) >= 0)
        )

        return hasSelectedTags && matchesSearchWords
    }
}

const buildBudgetTree = (flatBudgets: BudgetDataI[]): BudgetDataI[] => {
    const budgetMap = new Map<string, BudgetDataI & { children: BudgetDataI[] }>()

    // Step 1: Initialize map with budgets
    flatBudgets.forEach((budget) => {
        budgetMap.set(budget.id, { ...budget, children: [] })
    })

    // Step 2: Assign children to their respective parents
    const rootBudgets: BudgetDataI[] = []
    flatBudgets.forEach((budget) => {
        if (budget.parentBudgetId) {
            const parent = budgetMap.get(budget.parentBudgetId)
            if (parent) {
                parent.children.push(budgetMap.get(budget.id)!)
            }
        } else {
            rootBudgets.push(budgetMap.get(budget.id)!)
        }
    })

    return rootBudgets
}

const buildBudgetTreeWithParents = (flatBudgets: BudgetDataI[], filteredIds: Set<string>): BudgetDataI[] => {
    const budgetMap = new Map<string, BudgetDataI & { children: BudgetDataI[] }>()

    // Step 1: Initialize map with budgets
    flatBudgets.forEach((budget) => {
        budgetMap.set(budget.id, { ...budget, children: [] })
    })

    // Step 2: Add children to their parents, ensuring we include all ancestors
    const rootBudgets: BudgetDataI[] = []
    const visited = new Set<string>()

    const addToHierarchy = (budget: BudgetDataI): void => {
        if (visited.has(budget.id)) return // Prevent re-processing
        visited.add(budget.id)

        const budgetWithChildren = budgetMap.get(budget.id)
        if (!budgetWithChildren) return

        if (budget.parentBudgetId) {
            const parent = budgetMap.get(budget.parentBudgetId)
            if (parent) {
                parent.children.push(budgetWithChildren)
                addToHierarchy(parent) // Recursively add parents
            }
        } else {
            rootBudgets.push(budgetWithChildren)
        }
    }

    // Step 3: Add filtered budgets and their ancestors to the hierarchy
    filteredIds.forEach((id) => {
        const budget = budgetMap.get(id)
        if (budget) addToHierarchy(budget)
    })

    return rootBudgets
}

interface UseFetchBudgetsDataProps {
    organizationId: string | undefined
    withMetrics?: boolean
    withTags?: boolean
    withSubBudgets?: boolean
}

export const useFetchBudgetsData = ({
    organizationId,
    withMetrics = true,
    withTags = false,
    withSubBudgets = false,
}: UseFetchBudgetsDataProps) => {
    const dispatch = useDispatch()
    const selectedTagsForFilter = useAppSelector(selectSelectedTagsForFilter)
    const { budgetsData, budgetsFilter, loading, error } = useAppSelector(selectBudgetsData)
    const isConnected = useAppSelector(getIsConnected)
    const { getBulkObjectsTags } = useGetBulkObjectsTags(organizationId)

    const fetchBudgetsData = useCallback(async () => {
        if (organizationId === NO_ORGANIZATION_ID || !isConnected) {
            dispatch(budgetActions.fetchBudgetsDataSuccess([]))
        } else if (organizationId) {
            try {
                dispatch(budgetActions.fetchBudgetsData())
                const data = await budgetApi.fetchBudgetsData(organizationId, withMetrics, withSubBudgets)
                dispatch(budgetActions.fetchBudgetsDataSuccess(data))

                if (withTags) {
                    dispatch(budgetActions.setTagsLoading(true))
                    const budgetsObjectsTags = await getBulkObjectsTags(
                        data.map((budget) => budget.id),
                        true
                    )

                    const budgetsDataWithTags = data.map((budgetData) => ({
                        ...budgetData,
                        tags: budgetsObjectsTags?.[budgetData.id],
                    }))

                    dispatch(budgetActions.fetchBudgetsDataSuccess(budgetsDataWithTags))
                    dispatch(budgetActions.setTags(budgetsObjectsTags))
                    dispatch(budgetActions.setTagsLoading(false))
                }
            } catch (e) {
                dispatch(budgetActions.fetchBudgetsDataFailed(`${e}`))
            }
        }
    }, [organizationId])

    useEffect(() => {
        fetchBudgetsData()
    }, [fetchBudgetsData])

    const budgetsTree = useMemo(() => buildBudgetTree(budgetsData), [budgetsData])
    const filteredBudgetsTree = useMemo(() => {
        if (!budgetsFilter && !selectedTagsForFilter.length) return budgetsTree

        const filteredFlatBudgets = budgetsData.filter(getBudgetFilter(budgetsFilter, selectedTagsForFilter))
        const filteredIds = new Set(filteredFlatBudgets.map((budget) => budget.id))

        return buildBudgetTreeWithParents(budgetsData, filteredIds)
    }, [budgetsData, budgetsFilter, selectedTagsForFilter, budgetsTree])

    return useMemo((): FetchBudgetsResult => {
        if (budgetsFilter || selectedTagsForFilter.length) {
            const filteredBudgets = budgetsData.filter(getBudgetFilter(budgetsFilter, selectedTagsForFilter))
            return {
                budgetsData,
                filteredBudgets,
                budgetsTree,
                filteredBudgetsTree,
                loading,
                error,
                reFetchBudgetsData: fetchBudgetsData,
            }
        }
        return { budgetsData, budgetsTree, loading, error, reFetchBudgetsData: fetchBudgetsData }
    }, [
        organizationId,
        budgetsData,
        budgetsFilter,
        filteredBudgetsTree,
        loading,
        error,
        selectedTagsForFilter,
        fetchBudgetsData,
    ])
}
