import * as t from "io-ts"

import { PaginatedResponse } from "~/types/Pagination"
import { decodeAndCaptureErrors } from "~/utils/io-ts/decodeAndCaptureErrors"

const PaginatedResponseIO = t.type({
    items: t.array(t.unknown),
    page: t.number,
    page_size: t.number,
    total: t.number,
    total_pages: t.number,
})

export const parsePaginatedResponse = <T>(data: unknown, adapter: (data: unknown) => T): PaginatedResponse<T> => {
    const paginatedData = decodeAndCaptureErrors(data, PaginatedResponseIO)
    return {
        page: paginatedData.page,
        page_size: paginatedData.page_size,
        total: paginatedData.total,
        total_pages: paginatedData.total_pages,
        items: Array.isArray(paginatedData.items) ? paginatedData.items.map(adapter) : [],
    }
}
