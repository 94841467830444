import { PlusCircle } from "react-feather"

import { CreateCustomFieldConfiguration } from "~/domains/orchestration/flows/components/configuration"
import { CreateCustomFieldNode } from "~/domains/orchestration/flows/components/nodes"
import { AllAdvancedFieldsUnion, Configuration, FactoryProps } from "~/domains/orchestration/flows/types/Configuration"
import {
    CreateCustomFieldNode as CreateCustomFieldNodeType,
    Node,
    NodeType,
} from "~/domains/orchestration/flows/types/Flows"

import { createCommonNodeProperties } from "./commonConfig"

const createCustomFieldNode: Omit<CreateCustomFieldNodeType, keyof Node> = {
    objectId: "",
    customFieldName: "",
    customFieldValue: "",
    type: NodeType.CREATE_CUSTOM_FIELD_NODE,
    nextNode: null,
}

const advancedFields: AllAdvancedFieldsUnion[] = [AllAdvancedFieldsUnion.OBJECT_ID]

const createCustomFieldConfig: Configuration<NodeType.CREATE_CUSTOM_FIELD_NODE> = {
    type: NodeType.CREATE_CUSTOM_FIELD_NODE,
    baseSlug: "create-custom-field",
    Icon: PlusCircle,
    Configuration: CreateCustomFieldConfiguration,
    advancedFields,
    Node: CreateCustomFieldNode,
    factory: (props: FactoryProps): CreateCustomFieldNodeType => ({
        ...createCustomFieldNode,
        ...createCommonNodeProperties(props.slug, props.position, props.name),
    }),
}

export { createCustomFieldConfig, advancedFields as createCustomFieldAdvancedFields }
