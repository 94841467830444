import { Editor, Transforms } from "slate"

import { MentionElementI, MentionI, MessageContentType } from "~/domains/communication/chat/types"

export const insertMention = (editor: Editor, mention: MentionI) => {
    const mentionElement: MentionElementI = {
        type: MessageContentType.Mention,
        data: mention,
        children: [{ text: mention.label }],
    }
    Transforms.insertNodes(editor, mentionElement)
    Transforms.move(editor)
}
