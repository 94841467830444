import { Tooltip } from "@mui/material"
import { Check } from "react-feather"
import { defineMessages, useIntl } from "react-intl"

import { Button, SafeFormattedMessage } from "~/components"

const messages = defineMessages({
    import: { id: "buyer.invoice.actions.button.import", defaultMessage: "Import document" },
    importButtonTooltip: {
        id: "buyer.invoice.actions.button.import.tooltip",
        defaultMessage: "Confirm the import of this invoice !",
    },
})

interface ValidateInvoiceProps {
    handleValidate?: () => void
    display?: "menu-item" | "button"
}

export const ValidateInvoice = ({ handleValidate, display = "button" }: ValidateInvoiceProps) => {
    const { formatMessage } = useIntl()

    return display === "button" ? (
        <Tooltip title={formatMessage(messages.importButtonTooltip)} arrow>
            <div>
                <Button onClick={handleValidate} type="primary-light">
                    <Check size={18} />
                    <SafeFormattedMessage {...messages.import} />
                </Button>
            </div>
        </Tooltip>
    ) : (
        <div>
            <Button onClick={handleValidate} type="menu-item">
                <Check size={18} />
                <SafeFormattedMessage {...messages.import} />
            </Button>
        </div>
    )
}
