import { Alert, Grid } from "@mui/material"
import { defineMessages, useIntl } from "react-intl"

import { Card } from "~/components"
import { PaymentTermsSelector } from "~/domains/payment/payment-terms/components"
import { useManagePaymentTerms } from "~/domains/payment/payment-terms/hooks/useManagePaymentTerms"
import { PaymentTerm } from "~/domains/payment/payment-terms/types"
import { ObjectType } from "~/domains/payment/types"

const messages = defineMessages({
    title: {
        id: "payment.paymentTerms.title",
        defaultMessage: "Payment terms",
    },
    selectDefaultPaymentTerms: {
        id: "payment.paymentTerms.selectDefaultPaymentTerms",
        defaultMessage: "Default payment terms",
    },
    paymentTermsAlert: {
        id: "payment.paymentTerms.paymentTermsAlert",
        defaultMessage: "This payment terms will be used by default on outgoing invoices.",
    },
})

interface DefaultPaymentTermsProps {
    organizationId: string
    currentOrganizationId: string
    objectType: ObjectType
}

export const DefaultPaymentTerms = ({
    organizationId,
    currentOrganizationId,
    objectType,
}: DefaultPaymentTermsProps) => {
    const { formatMessage } = useIntl()
    const objectId =
        organizationId === currentOrganizationId ? organizationId : `${currentOrganizationId}-${organizationId}`

    const {
        loading,
        paymentTerms,
        objectPaymentTerm,
        defaultPaymentTermObject,
        createPaymentTermObject,
        updatePaymentTermObject,
    } = useManagePaymentTerms({ objectId, objectType }, { showList: true })

    const handlePaymentTermsChange = async (paymentTerm: PaymentTerm | null) => {
        if (paymentTerm && !objectPaymentTerm) {
            await createPaymentTermObject({
                payment_term_id: paymentTerm.id,
                object_id: objectId,
                object_type: objectType,
                is_default: true,
            }).unwrap()
        }
        if (paymentTerm && defaultPaymentTermObject) {
            await updatePaymentTermObject({
                id: defaultPaymentTermObject.id,
                payment_term_id: paymentTerm.id,
                object_type: objectType,
            }).unwrap()
        }
    }

    return (
        <Card title={formatMessage(messages.title)}>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item sm={4}>
                    <PaymentTermsSelector
                        items={paymentTerms}
                        loading={loading}
                        disabled={loading}
                        value={objectPaymentTerm?.id ?? ""}
                        label={formatMessage(messages.selectDefaultPaymentTerms)}
                        onChange={handlePaymentTermsChange}
                    />
                </Grid>
                <Grid item sm={8}>
                    <Alert sx={{ display: "flex", height: "100%", alignItems: "center" }} severity="info">
                        {formatMessage(messages.paymentTermsAlert)}
                    </Alert>
                </Grid>
            </Grid>
        </Card>
    )
}
