import { Stack, TextField } from "@mui/material"
import "dayjs/locale/fr"
import { ChangeEvent, useEffect } from "react"
import { useIntl } from "react-intl"

import { Card, SkeletonInputStack } from "~/components"
import { EditAmount } from "~/domains/transactions/invoices/_views/supplier/components/EditAmount"
import { ExtractDueDate } from "~/domains/transactions/invoices/_views/supplier/components/Extract/ExtractDueDate"
import { ExtractIssueDate } from "~/domains/transactions/invoices/_views/supplier/components/Extract/ExtractIssueDate"
import { ExtractPurchaseOrderNumber } from "~/domains/transactions/invoices/_views/supplier/components/Extract/ExtractPurchaseOrderNumber"
import { ExtractReferenceField } from "~/domains/transactions/invoices/_views/supplier/components/Extract/ExtractReferenceField"
import { extractMessages } from "~/domains/transactions/invoices/_views/supplier/components/Extract/extractMessages"
import { UpdateExtractDataFunction } from "~/domains/transactions/invoices/_views/supplier/components/Extract/types"
import { getOcrInvoiceCurrency } from "~/domains/transactions/invoices/core"
import {
    CurrencyCodes,
    DocumentWithDueDate,
    DocumentWithPrice,
    DocumentWithPurchaseOrderNumber,
    DocumentWithReference,
    DocumentWithVersion,
    InvoiceBackendVersion,
    InvoiceI,
} from "~/types/"

interface ExtractProps {
    invoice: InvoiceI
    ocrData: DocumentWithPurchaseOrderNumber &
        DocumentWithReference &
        DocumentWithDueDate &
        DocumentWithPrice &
        DocumentWithVersion
    updateData: UpdateExtractDataFunction
    updateTotal?: (amount: number) => void
    updateTotalExcludedTaxes?: (amount: number) => void
    updateDueDate?: (date: string) => void
    dataLoaded: boolean
    disabled?: boolean
}

export function Extract({ invoice, ocrData, updateData, dataLoaded, disabled }: ExtractProps) {
    const { formatMessage } = useIntl()
    const currency = getOcrInvoiceCurrency(ocrData)

    useEffect(() => {
        if (ocrData && !ocrData.total && ocrData.totalExcludedTaxes) {
            updateData({ total: ocrData.totalExcludedTaxes })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ocrData])

    const createAmountHandler =
        (key: keyof Omit<Omit<DocumentWithPrice, "totalAmountDue">, "FlatDiscount">) => (newAmount: number | null) => {
            if (newAmount !== null) {
                updateData({ [key]: newAmount })
            }
        }
    const handleUpdateAmountExcludingTaxes = createAmountHandler("totalExcludedTaxes")
    const handleUpdateAmountTotalDiscount = createAmountHandler("totalDiscount")
    const handleUpdateAmountIncludingTaxes = createAmountHandler("total")
    const handleUpdateAmountFlatDiscount = (newAmount: number | null) => {
        if (currency && newAmount !== null) {
            updateData({ flatDiscount: { [currency]: newAmount.toString() } as Record<CurrencyCodes, string> })
        }
    }

    const handleUpdateAmountDiscountRate = (event: ChangeEvent<HTMLInputElement>) => {
        const newAmount = event.target.value
        if (newAmount !== null) {
            updateData({ discountRate: newAmount })
        }
    }

    if (!ocrData) {
        return null
    }

    return (
        <Card title={formatMessage(extractMessages.title)} expandable>
            <Stack spacing={2}>
                {!dataLoaded ? (
                    <SkeletonInputStack count={7} />
                ) : (
                    <>
                        <ExtractReferenceField
                            reference={ocrData?.reference ?? ""}
                            updateData={updateData}
                            disabled={disabled}
                        />

                        <ExtractPurchaseOrderNumber
                            purchaseOrderNumber={ocrData?.purchaseOrderNumber ?? ""}
                            updateData={updateData}
                            disabled={disabled}
                        />

                        <ExtractIssueDate issueDate={ocrData?.issueDate} updateData={updateData} disabled={disabled} />
                        <ExtractDueDate dueDate={ocrData?.dueDate} updateData={updateData} disabled={disabled} />

                        <EditAmount
                            value={ocrData.totalExcludedTaxes ?? 0}
                            id="totalExcludedTaxes"
                            placeholder={formatMessage(extractMessages.totalNoTax)}
                            handleUpdate={handleUpdateAmountExcludingTaxes}
                            disabled={disabled}
                            currency={currency}
                        />
                        {invoice.version === InvoiceBackendVersion.V1 ? (
                            <>
                                <TextField
                                    value={+(ocrData?.discountRate || 0)}
                                    id="discountRate"
                                    label={formatMessage(extractMessages.discountRate)}
                                    placeholder={formatMessage(extractMessages.discountRate)}
                                    disabled={disabled}
                                    onChange={handleUpdateAmountDiscountRate}
                                />
                                <EditAmount
                                    value={+(ocrData?.flatDiscount?.[currency || "EUR"] || 0)}
                                    id="flatDiscount"
                                    placeholder={formatMessage(extractMessages.totalDiscount)}
                                    handleUpdate={handleUpdateAmountFlatDiscount}
                                    disabled={disabled}
                                    currency={currency}
                                />
                            </>
                        ) : (
                            <EditAmount
                                value={ocrData.totalDiscount ?? 0}
                                id="totalDiscount"
                                placeholder={formatMessage(extractMessages.totalDiscount)}
                                handleUpdate={handleUpdateAmountTotalDiscount}
                                disabled={disabled}
                                currency={currency}
                            />
                        )}

                        <EditAmount
                            value={ocrData.total ?? 0}
                            id="total"
                            placeholder={formatMessage(extractMessages.total)}
                            handleUpdate={handleUpdateAmountIncludingTaxes}
                            disabled={disabled}
                            currency={currency}
                        />
                    </>
                )}
            </Stack>
        </Card>
    )
}
