import { DocumentRelationKpiAmount } from "~/domains/transactions/_shared/document-relations/types/DocumentRelation"
import { DocumentRelationKpiAmounts } from "~/domains/transactions/_shared/document-relations/types/DocumentRelation"
import { documentsDifferencesKPIFromApiAdapter } from "~/domains/transactions/invoices-v1/api/adapters/parts/documentsDifferencesKPIFromApiAdapter"
import { CurrencyCodes } from "~/types"

export const documentsDifferencesKPIsFromApiAdapter = (
    kpis: DocumentRelationKpiAmounts
): DocumentRelationKpiAmounts => {
    return {
        totalAmount: Object.entries(kpis.totalAmount).reduce(
            (acc, [currency, kpi]) => {
                acc[currency] = documentsDifferencesKPIFromApiAdapter(kpi)
                return acc
            },
            {} as Record<CurrencyCodes, DocumentRelationKpiAmount>
        ),
        amountExcludingTax: Object.entries(kpis.amountExcludingTax).reduce(
            (acc, [currency, kpi]) => {
                acc[currency] = documentsDifferencesKPIFromApiAdapter(kpi)
                return acc
            },
            {} as Record<CurrencyCodes, DocumentRelationKpiAmount>
        ),
        tax: Object.entries(kpis.tax).reduce(
            (acc, [currency, kpi]) => {
                acc[currency] = documentsDifferencesKPIFromApiAdapter(kpi)
                return acc
            },
            {} as Record<CurrencyCodes, DocumentRelationKpiAmount>
        ),
    }
}
