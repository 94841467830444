import { MessageDescriptor } from "react-intl"

import { commonMessages } from "~/common-messages"

import { TransactionType } from "../types"

export const budgetDetailsTabsMessages: Record<TransactionType, MessageDescriptor> = {
    [TransactionType.INVOICE]: { id: "budget.budgetDetails.tabs.invoices", defaultMessage: "Invoices" },
    [TransactionType.INVOICE_V1]: commonMessages.invoicesV1,
    [TransactionType.PURCHASE_REQUEST]: {
        id: "budget.budgetDetails.tabs.purchaseRequests",
        defaultMessage: "Purchase requests",
    },
    [TransactionType.PURCHASE_ORDER]: {
        id: "budget.budgetDetails.tabs.purchaseOrders",
        defaultMessage: "Purchase orders",
    },
    [TransactionType.PAYMENT]: {
        id: "budget.budgetDetails.tabs.payments",
        defaultMessage: "Payments",
    },
    [TransactionType.DEPOSIT]: {
        id: "budget.budgetDetails.tabs.deposits",
        defaultMessage: "Deposits",
    },
}

export const transactionTypeMessages: Record<TransactionType, MessageDescriptor> = {
    [TransactionType.INVOICE]: { id: "budget.budgetDetails.transactionType.invoice", defaultMessage: "an invoice" },
    [TransactionType.INVOICE_V1]: {
        id: "budget.budgetDetails.transactionType.invoice",
        defaultMessage: "an invoice",
    },
    [TransactionType.PURCHASE_REQUEST]: {
        id: "budget.budgetDetails.transactionType.purchaseRequest",
        defaultMessage: "a purchase request",
    },
    [TransactionType.PURCHASE_ORDER]: {
        id: "budget.budgetDetails.transactionType.purchaseOrder",
        defaultMessage: "a purchase order",
    },
    [TransactionType.PAYMENT]: {
        id: "budget.budgetDetails.transactionType.payment",
        defaultMessage: "a payment",
    },
    [TransactionType.DEPOSIT]: {
        id: "budget.budgetDetails.transactionType.deposit",
        defaultMessage: "a deposit",
    },
}

export const transactionTypePluralMessages: Record<TransactionType, MessageDescriptor> = {
    [TransactionType.INVOICE]: {
        id: "budget.budgetDetails.transactionType.invoice.plural",
        defaultMessage: "invoices",
    },
    [TransactionType.INVOICE_V1]: {
        id: "budget.budgetDetails.transactionType.invoice.plural",
        defaultMessage: "invoices",
    },
    [TransactionType.PURCHASE_REQUEST]: {
        id: "budget.budgetDetails.transactionType.purchaseRequest.plural",
        defaultMessage: "purchase requests",
    },
    [TransactionType.PURCHASE_ORDER]: {
        id: "budget.budgetDetails.transactionType.purchaseOrder.plural",
        defaultMessage: "purchase orders",
    },
    [TransactionType.PAYMENT]: {
        id: "budget.budgetDetails.transactionType.payment.plural",
        defaultMessage: "payments",
    },
    [TransactionType.DEPOSIT]: {
        id: "budget.budgetDetails.transactionType.deposit.plural",
        defaultMessage: "deposits",
    },
}
