import { IconButton, styled } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { MessageDescriptor, defineMessages } from "react-intl"

import { Loader, SafeFormattedMessage } from "~/components"
import { LeftChevronIcon } from "~/components/Icons"
import { LightButton } from "~/components/LightButton"
import { registrationNumberIsPrefered } from "~/domains/identity/account/components/Company/FoundOrganization"
import { CompanyAutocomplete, CompanyResult } from "~/domains/identity/organization/components/CompanyAutocomplete"
import { CompanyAutocompleteType } from "~/domains/identity/organization/components/CompanyAutocomplete/CompanyAutocompleteField"
import { OrganizationItem } from "~/domains/identity/organization/components/OrganizationItem"
import { selectPartnersBrandNames } from "~/domains/identity/partners/store/bookOfRelationsSlice"
import { CollaborationStatus, PartnerOrganizationI } from "~/domains/identity/partners/types"
import { EstablishmentSelect } from "~/domains/transactions/invoices/_views/supplier/components/EstablishmentSelect"
import { useAppSelector } from "~/store/hooks"
import { CompanyI, CountryCode, EstablishmentI, OrganizationI, ViewTypeI } from "~/types"

import { CreateOrganizationForm } from "./CreateOrganizationForm"
import { MatchingValue } from "./MatchingValue"
import { CompanyData, OrganizationPickerFromOcrResult, OrganizationPickerFromOcrResultType } from "./types"

const messages = defineMessages({
    editButton: {
        id: "transactions.invoices.ocr.OrganizationPicker.editButton",
        defaultMessage: "Edit",
    },
    importOrganizationTitle: {
        id: "transactions.invoices.ocr.OrganizationPicker.importOrganizationTitle",
        defaultMessage: "Import an organization from our database",
    },
    createCompanyManually: {
        id: "transactions.invoices.ocr.OrganizationPicker.createCompanyManuallyTitle",
        defaultMessage: "Create a new company manually",
    },
    searchLabel: {
        id: "transactions.invoices.ocr.OrganizationPicker.searchCompanyLabel",
        defaultMessage: "Search the corresponding company",
    },
    dontFindIt: {
        id: "transactions.invoices.ocr.OrganizationPicker.dontFindCompany",
        defaultMessage: "You don’t find it? ",
    },
    inputManuallyCTA: {
        id: "transactions.invoices.ocr.OrganizationPicker.manuallyInputCompany",
        defaultMessage: "Input manually a new company +",
    },
    corporateName: {
        id: "transactions.invoices.ocr.OrganizationPicker.corporateName",
        defaultMessage: "Corporate name",
    },
    registrationNumber: {
        id: "transactions.invoices.ocr.OrganizationPicker.registrationNumber",
        defaultMessage: "Business Registration Number",
    },
    taxNumber: {
        id: "transactions.invoices.ocr.OrganizationPicker.taxNumber",
        defaultMessage: "Tax number",
    },
})

const titleMessages: Record<ViewTypeI, MessageDescriptor> = defineMessages({
    [ViewTypeI.supplier]: {
        id: "purchase.organization.supplier",
        defaultMessage: "Supplier",
    },
    [ViewTypeI.buyer]: {
        id: "purchase.organization.buyer",
        defaultMessage: "Buyer",
    },
})

const WHITE_SPACE = /\W+/g

const matchesAny = (source: string, targets: (string | undefined)[]): boolean => {
    const lowerSource = source.toLowerCase()
    return targets.some((target) => {
        if (!target) return false
        const lowerTarget = target.toLowerCase()
        return (
            lowerSource === lowerTarget ||
            lowerSource.split(WHITE_SPACE).every((word) => lowerTarget.split(WHITE_SPACE).includes(word))
        )
    })
}

interface Props {
    value: OrganizationPickerFromOcrResult | null
    onChange: (value: OrganizationPickerFromOcrResult | null) => void
    setCountryCode: (countryCode: CountryCode) => void
    ocrData: CompanyData
    options: (OrganizationI | PartnerOrganizationI)[]
    suggestedCompanies?: CompanyI[] | undefined
    viewType: ViewTypeI | React.ReactNode
    disabled?: boolean
}

const MainContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "4px",
})

const Title = styled("h4")({
    marginBottom: "var(--spacing-sm) !important",
})

const OrganizationPickerContainer = styled("div")({
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
    height: "56px",
    padding: "8px 16px",
    borderRadius: "var(--border-radius-xs)",
    border: "1px solid var(--border-color)",
    background: "var(--color-bg-input)",
})

const MatchingResults = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
    flexWrap: "wrap",
})

const SearchLabel = styled("label")({
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "var(--color-grey-light)",
})

const ManualInputContainer = styled("div")({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "var(--color-grey-light)",
    display: "flex",
    gap: "4px",
})

const hasCollaborationStatus = (
    option: OrganizationI | PartnerOrganizationI
): option is PartnerOrganizationI & { value: { collaborationStatus: string } } => {
    return (
        "collaborationStatus" in option &&
        Object.values(CollaborationStatus).includes(option.collaborationStatus as CollaborationStatus)
    )
}

export const OrganizationPickerFromOCR: React.FC<Props> = ({
    value,
    onChange,
    ocrData,
    viewType,
    options,
    suggestedCompanies,
    setCountryCode,
    disabled,
}) => {
    const [editValue, setEditValue] = useState<boolean>(false)
    const [manualImport, setManualImport] = useState<boolean>(false)
    const [companyLoading, setCompanyLoading] = useState<boolean>(false)
    const [company, setCompany] = useState<CompanyResult>()
    const [selectedEstablishment, setSelectedEstablishment] = useState<EstablishmentI>()

    const onChangeRef = useRef(onChange)

    const brandNames = useAppSelector(selectPartnersBrandNames)

    useEffect(() => {
        if (editValue === true) {
            setSelectedEstablishment(undefined)
            setCompany(undefined)
        }
    }, [editValue])

    useEffect(() => {
        onChangeRef.current = onChange
    }, [onChange])

    useEffect(() => {
        if (!company) return

        if (company.type === CompanyAutocompleteType.Organization) {
            onChangeRef.current({
                type: OrganizationPickerFromOcrResultType.ORGANIZATION,
                value: company.value,
            })
            setEditValue(false)
            return
        }

        if (!company.value.establishments) {
            onChangeRef.current({
                type: OrganizationPickerFromOcrResultType.COMPANY,
                value: company.value,
            })
            setEditValue(false)
            return
        }

        if (selectedEstablishment) {
            onChangeRef.current({
                type: OrganizationPickerFromOcrResultType.COMPANY,
                value: {
                    ...company.value,
                    id: selectedEstablishment.id,
                    registrationNumbers: [
                        {
                            registrationNumber: selectedEstablishment.id,
                            registrationType: "SIRET",
                            isPreferred: true,
                        },
                    ],
                },
            })
            setEditValue(false)
        }
    }, [company, selectedEstablishment])

    const suggestedStrings = useMemo(() => {
        const result: string[] = []
        if (ocrData.name) {
            result.push(ocrData.name)
        }
        if (ocrData.registrationNumber) {
            result.push(ocrData.registrationNumber)
        }
        if (ocrData.taxNumber) {
            result.push(ocrData.taxNumber)
        }
        return result
    }, [ocrData])

    const onCompanyChange = useCallback(
        (result: CompanyResult | undefined, establishmentToSelect?: EstablishmentI | undefined) => {
            setCompany(result)
            if (establishmentToSelect) {
                setSelectedEstablishment(establishmentToSelect)
            } else if (
                result?.type === CompanyAutocompleteType.WhitePagesResult &&
                result.value.establishments?.length === 1
            ) {
                setSelectedEstablishment(result.value.establishments[0])
            }
        },
        []
    )

    const onOrganizationCreated = useCallback((organization: OrganizationI) => {
        onChangeRef.current({
            type: OrganizationPickerFromOcrResultType.ORGANIZATION,
            value: organization,
        })
        setManualImport(false)
        setEditValue(false)
    }, [])

    const onEditClick = useCallback(() => setEditValue(true), [])
    const onManualImportClick = useCallback(() => setManualImport(true), [])
    const cancelManualImport = useCallback(() => setManualImport(false), [])

    const getCollaborationStatus = (result: OrganizationPickerFromOcrResult) => {
        const option = options.find((val) => val.id === result.value.id)

        if (option && hasCollaborationStatus(option)) {
            return option.collaborationStatus
        }
        return undefined
    }

    const formattedViewType =
        typeof viewType === "string" && (viewType === ViewTypeI.buyer || viewType === ViewTypeI.supplier) ? (
            <SafeFormattedMessage {...titleMessages[viewType]} />
        ) : (
            viewType
        )

    if (value && !editValue && !manualImport) {
        return (
            <MainContainer>
                <Title>{formattedViewType}</Title>
                <OrganizationPickerContainer>
                    <OrganizationItem
                        name={brandNames[value.value.id] ?? value.value.name}
                        countryCode={
                            value.type === OrganizationPickerFromOcrResultType.ORGANIZATION
                                ? value.value.registration.countryCode
                                : value.value.countryCode
                        }
                        registrationNumber={
                            value.type === OrganizationPickerFromOcrResultType.ORGANIZATION
                                ? value.value.registration.preferredRegistrationNumber?.registrationNumber
                                : value.value.registrationNumbers.find(registrationNumberIsPrefered)?.registrationNumber
                        }
                    />
                    {!disabled ? (
                        <LightButton onClick={onEditClick}>
                            <SafeFormattedMessage {...messages.editButton} />
                        </LightButton>
                    ) : null}
                </OrganizationPickerContainer>
                <MatchingResults>
                    {ocrData.name && (
                        <MatchingValue
                            isMatching={matchesAny(
                                ocrData.name ?? "",
                                [value.value.name, brandNames[value.value.id]].filter(Boolean)
                            )}
                            field="name"
                        />
                    )}
                    {ocrData.registrationNumber && (
                        <MatchingValue
                            isMatching={
                                ocrData.registrationNumber ===
                                (value.type === OrganizationPickerFromOcrResultType.ORGANIZATION
                                    ? value.value.registration.preferredRegistrationNumber?.registrationNumber
                                    : value.value.registrationNumbers.find(registrationNumberIsPrefered)
                                          ?.registrationNumber)
                            }
                            field="registrationNumber"
                        />
                    )}
                    <MatchingValue
                        isMatching={getCollaborationStatus(value) === CollaborationStatus.ACTIVE}
                        field="collaborationStatus"
                    />
                </MatchingResults>
            </MainContainer>
        )
    }
    if (manualImport) {
        return (
            <MainContainer>
                <Title>
                    <IconButton onClick={cancelManualImport}>
                        <LeftChevronIcon />
                    </IconButton>
                    <SafeFormattedMessage {...messages.createCompanyManually} />
                </Title>
                <CreateOrganizationForm
                    companyData={ocrData}
                    onOrganizationCreated={onOrganizationCreated}
                    cancel={cancelManualImport}
                />
            </MainContainer>
        )
    }

    return (
        <MainContainer>
            <Title>{formattedViewType}</Title>

            <SearchLabel>
                <SafeFormattedMessage {...messages.searchLabel} />
            </SearchLabel>

            <CompanyAutocomplete
                organizations={options}
                suggestedCompanies={suggestedCompanies}
                countryCode={ocrData.countryCode}
                setCountryCode={setCountryCode}
                setCompanyLoading={setCompanyLoading}
                company={company}
                setCompany={onCompanyChange}
                suggestedStrings={suggestedStrings}
            />

            {companyLoading && <Loader small />}

            {company?.type === CompanyAutocompleteType.WhitePagesResult &&
                company.value.establishments &&
                company.value.establishments.length > 1 && (
                    <EstablishmentSelect
                        options={company.value.establishments}
                        value={selectedEstablishment}
                        onChange={setSelectedEstablishment}
                    />
                )}

            <ManualInputContainer>
                <SafeFormattedMessage {...messages.dontFindIt} />
                <LightButton onClick={onManualImportClick}>
                    <SafeFormattedMessage {...messages.inputManuallyCTA} />
                </LightButton>
            </ManualInputContainer>
        </MainContainer>
    )
}
