import { capitalize } from "@mui/material"
import { MessageDescriptor } from "react-intl"

import {
    SpiceDBAuthorizationName,
    SpiceDBObjectAuthorizationName,
    SpiceDBObjectType,
    SpiceDBPermissionsLiteI,
    SpiceDBSpecialAuthorization,
    sharedObjectPermissionsModalMessages,
    spiceDBAuthorizationMessages,
} from "~/domains/identity/roles-permissions/types/SpiceDB"
import { SpiceDBPermissionsResult } from "~/types"

export const renderSelectValue = (
    selected: SpiceDBAuthorizationName[],
    defaultLabel: MessageDescriptor | null,
    formatMessage: (descriptor: MessageDescriptor) => string
): string => {
    if (!selected.length) {
        return defaultLabel ? formatMessage(defaultLabel) : ""
    }

    const selectedCopy = [...selected]
    const orderedSelected = selectedCopy.sort(
        (a, b) =>
            Object.values(SpiceDBAuthorizationName).indexOf(a) - Object.values(SpiceDBAuthorizationName).indexOf(b)
    )

    let value = ""
    const hasOwner = orderedSelected.includes(SpiceDBAuthorizationName.OWNER)
    const hasCheck = orderedSelected.includes(SpiceDBAuthorizationName.CHECK)

    if (hasOwner || hasCheck) {
        value = `${hasOwner ? formatMessage(spiceDBAuthorizationMessages[SpiceDBAuthorizationName.OWNER]) : ""}`
        value += hasCheck
            ? `${value ? ", " : ""}${formatMessage(spiceDBAuthorizationMessages[SpiceDBAuthorizationName.CHECK])}`
            : ""
    }

    const remainingSelected = orderedSelected.filter(
        (authorization) =>
            authorization !== SpiceDBAuthorizationName.OWNER && authorization !== SpiceDBAuthorizationName.CHECK
    )

    if (remainingSelected.length) {
        if (value) {
            value += `, +${remainingSelected.length}`
        } else {
            value = renderMultipleValues(
                `${formatMessage(sharedObjectPermissionsModalMessages.can)} ${formatMessage(
                    spiceDBAuthorizationMessages[remainingSelected[0]]
                )}`,
                remainingSelected.slice(1)
            )
        }
    }

    return capitalize(value)
}

const renderMultipleValues = (firstString: string, remainingValues: SpiceDBAuthorizationName[]) => {
    return `${firstString}${remainingValues.length ? `, +${remainingValues.length}` : ""}`
}

export const removeAllPermissionsExceptUnauthorized = (
    objectType: SpiceDBObjectType,
    permissions: SpiceDBAuthorizationName[],
    userPermissions: SpiceDBAuthorizationName[]
): SpiceDBAuthorizationName[] => {
    return permissions.filter(
        (permission) =>
            SpiceDBObjectAuthorizationName[objectType]?.unauthorized.includes(permission) ||
            !hasUserAuthorization(userPermissions, permission)
    )
}

export const removeAllUnauthorizedPermissions = (
    objectType: SpiceDBObjectType,
    permissions: SpiceDBAuthorizationName[],
    userPermissions: SpiceDBAuthorizationName[]
): SpiceDBAuthorizationName[] => {
    return permissions.filter(
        (permission) =>
            SpiceDBObjectAuthorizationName[objectType]?.authorized.includes(permission) &&
            hasUserAuthorization(userPermissions, permission)
    )
}

export const getCurrentUserPermissions = (
    userId: string,
    userGlobalPermissions: SpiceDBPermissionsResult | null,
    sharedPermissions?: SpiceDBPermissionsLiteI[]
): SpiceDBAuthorizationName[] => {
    if (!sharedPermissions || !userGlobalPermissions) {
        return []
    }
    const userPermissions = spiceDBPermissionsResultToSpiceDBAuthorizationName(userGlobalPermissions)
    const filteredPermissions = sharedPermissions.reduce<SpiceDBAuthorizationName[]>(
        (acc, { objectId, objectType, permissions }) => {
            if (objectType === SpiceDBObjectType.USER && objectId === userId) {
                acc = acc.concat(permissions)
            }
            return acc
        },
        []
    )

    return filteredPermissions.length || userPermissions.length
        ? Array.from(new Set(filteredPermissions.concat(userPermissions)))
        : []
}

const spiceDBPermissionsResultToSpiceDBAuthorizationName = (
    userGlobalPermissions: SpiceDBPermissionsResult
): SpiceDBAuthorizationName[] => {
    const validPermissions = new Set(Object.values(SpiceDBAuthorizationName))
    return Object.keys(userGlobalPermissions ?? {}).reduce<SpiceDBAuthorizationName[]>((acc, permission) => {
        if (validPermissions.has(permission as SpiceDBAuthorizationName) && userGlobalPermissions[permission]) {
            acc.push(permission as SpiceDBAuthorizationName)
        }
        return acc
    }, [])
}

export const hasUserAuthorization = (
    userPermissions: SpiceDBAuthorizationName[],
    authorization: SpiceDBAuthorizationName
) => {
    // Direct authorization check
    if (userPermissions.includes(authorization)) {
        return true
    }

    // Check for special authorizations
    return userPermissions.some((permission) => SpiceDBSpecialAuthorization[permission]?.includes(authorization))
}
