import { Chip } from "@mui/material"
import { styled } from "@mui/system"
import * as Sentry from "@sentry/browser"
import { ReactFlowProvider } from "@xyflow/react"
import React, { FC, useEffect, useRef } from "react"
import { useIntl } from "react-intl"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { Loader } from "~/components"
import { useLazyGetFlowQuery } from "~/domains/orchestration/flows/api/flowsApi"
import { ActionBar } from "~/domains/orchestration/flows/components/ActionBar"
import { EditorPanel } from "~/domains/orchestration/flows/components/editor/EditorPanel"
import { useFlowLatestVersion, useOrganizationId, usePermission } from "~/domains/orchestration/flows/hooks/"
import { messages } from "~/domains/orchestration/flows/locale"
import { FLOWS_LIST } from "~/domains/orchestration/flows/routes"
import { type FlowId } from "~/domains/orchestration/flows/types"
import { useTitle } from "~/hooks"

const StyledLoader = styled("div")({
    height: "calc(100vh - 24px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
})

const StyledChipContainer = styled("div")({
    width: "100%",
    display: "flex",
    marginTop: "var(--spacing-sx)",
    justifyContent: "center",
    padding: "var(--spacing-sx)",
})

export const Editor: FC = () => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const { flowId = "" as FlowId } = useParams<{ flowId: FlowId }>()

    const flowVersion = useFlowLatestVersion(flowId)

    const organizationId = useOrganizationId()
    const organizationIdRef = useRef(organizationId)

    const pageName = formatMessage(messages.editor.title)
    useTitle(pageName)

    const { hasWorkflowDeletePermission, hasWorkflowUpdatePermission, permissionError } = usePermission()

    const [triggerGetFlow, { data: flow, isLoading, isError, error }] = useLazyGetFlowQuery()

    useEffect(() => {
        if (flowVersion) {
            triggerGetFlow({ flowId, params: { version: flowVersion } })
        }
    }, [flowVersion, triggerGetFlow, flowId])

    // Handle organization changes
    useEffect(() => {
        if (!organizationIdRef.current) {
            organizationIdRef.current = organizationId
        }

        if (organizationIdRef.current !== organizationId) {
            navigate(generatePath(FLOWS_LIST))
        }
    }, [organizationId])

    if (isLoading) {
        return (
            <StyledLoader>
                <Loader />
            </StyledLoader>
        )
    }

    if (isError) {
        toast.error(formatMessage(messages.error.loadingFlow))
        Sentry.captureException(error)
        return null
    }

    if (permissionError) {
        toast.error(formatMessage(messages.error.permission))
        Sentry.captureException("Flow editor permission error")
        return null
    }

    if (!flow) {
        return null
    }

    return (
        <>
            <ActionBar
                key={flow.version}
                flow={flow}
                allowDelete={hasWorkflowDeletePermission}
                allowEdit={hasWorkflowUpdatePermission}
            />

            {flow.archived && (
                <StyledChipContainer>
                    <Chip label={formatMessage(messages.editor.archived)} variant="outlined" />
                </StyledChipContainer>
            )}

            <ReactFlowProvider>
                <EditorPanel flow={flow} hasWorkflowUpdatePermission={hasWorkflowUpdatePermission} />
            </ReactFlowProvider>
        </>
    )
}
