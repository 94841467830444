import { Stack } from "@mui/material"
import { defineMessages } from "react-intl"

import { Card, SafeFormattedMessage } from "~/components"

import { FormItem } from "./FormItem"
import { FormViewerFooter } from "./FormViewerFooter"
import { FormViewerProvider, useFormViewerContext } from "./FormViewerProvider"

interface FormViewerProps {
    formToken: string
    viewMode?: string
}

const FORM_WIDTH = 800

const messages = defineMessages({
    formSent: {
        id: "smartForms.formSent",
        defaultMessage: "Form sent",
    },
})

export const FormViewer = ({ formToken, viewMode }: FormViewerProps) => {
    const FormSection = () => {
        const { formData, currentSection } = useFormViewerContext()
        const section = formData?.sections[currentSection]

        if (!section) {
            return null
        }

        return (
            <Card sx={{ padding: "var(--spacing-lg)" }} title={section.title}>
                {section.questions.map((q) => (
                    <FormItem disabled={viewMode === "true"} key={q.id} question={q} />
                ))}
            </Card>
        )
    }

    const FormWrapper = () => {
        const { formData, formSent } = useFormViewerContext()
        return (
            <Stack className="main-box" maxWidth={FORM_WIDTH}>
                <h2 className="mb-12">{formData?.name}</h2>
                {formSent ? (
                    <Card>
                        <SafeFormattedMessage {...messages.formSent} />
                    </Card>
                ) : (
                    <>
                        <FormSection />
                        <FormViewerFooter />
                    </>
                )}
            </Stack>
        )
    }

    return (
        <FormViewerProvider formToken={formToken} viewMode={viewMode}>
            <FormWrapper />
        </FormViewerProvider>
    )
}
