import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { prepareHeadersWithAuthorizationAndOrganizationId as prepareHeaders } from "~/api/prepareHeaders"
import { commonMessages } from "~/common-messages"
import {
    CreateCatalogProductVariantMediaPayload,
    DeleteCatalogProductVariantMediaPayload,
    GetCatalogProductVariantMediaQuery,
    UpdateCatalogProductVariantMediaPayload,
} from "~/domains/transactions/catalog-v1/types/CatalogProductVariantMedia.type"

const BASE_URL = import.meta.env.VITE_API_CATALOG_V1_URL

export const catalogProductVariantMediaApi = createApi({
    reducerPath: "catalogProductVariantMediaApi",
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, prepareHeaders }),
    tagTypes: ["Search"],
    endpoints: (builder) => ({
        getProductVariantMedia: builder.query<string, GetCatalogProductVariantMediaQuery>({
            query: ({ organizationId, productId, variantId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/media`,
            }),
        }),

        createProductVariantMedia: builder.mutation<string, CreateCatalogProductVariantMediaPayload>({
            query: ({ organizationId, productId, variantId, media }) => {
                const formData = new FormData()
                formData.append("file", media)

                return {
                    url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/media`,
                    method: "POST",
                    body: formData,
                }
            },
            invalidatesTags: ["Search"],
            transformErrorResponse: (response) => ({
                meta: {
                    errorMessage: commonMessages.errorUpload,
                    catchSentryException: true,
                },
                ...response,
            }),
        }),

        updateProductVariantMedia: builder.mutation<string, UpdateCatalogProductVariantMediaPayload>({
            query: ({ organizationId, productId, variantId, mediaId, isDefault }) => {
                return {
                    url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/media/${mediaId}`,
                    method: "PATCH",
                    body: { isDefault },
                }
            },
        }),

        deleteProductVariantMedia: builder.mutation<void, DeleteCatalogProductVariantMediaPayload>({
            query: ({ organizationId, productId, variantId, mediaId }) => ({
                url: `organizations/${organizationId}/products/${productId}/variants/${variantId}/media/${mediaId}`,
                method: "DELETE",
            }),
        }),
    }),
})

export const {
    useGetProductVariantMediaQuery,
    useCreateProductVariantMediaMutation,
    useUpdateProductVariantMediaMutation,
    useDeleteProductVariantMediaMutation,
} = catalogProductVariantMediaApi
