import { HandleProps, Position } from "@xyflow/react"
import { NodeProps, Node as NodeType } from "@xyflow/react"
import React, { FC } from "react"
import { Zap } from "react-feather"
import { useIntl } from "react-intl"

import { messages } from "~/domains/orchestration/flows/locale"
import { Event, EventTriggerNode as EventTriggerNodeType, isEvent } from "~/domains/orchestration/flows/types"

import { Node } from "./Node"

const handles: HandleProps[] = [
    {
        type: "source",
        position: Position.Bottom,
    },
]

export const EventTriggerNode: FC<NodeProps<NodeType<EventTriggerNodeType>>> = ({ data, selected }) => {
    const { formatMessage } = useIntl()

    const error = !isEvent(data.event ?? "")

    // Run when an invoice is created

    const information = !error
        ? formatMessage(messages.eventTriggerNode.information, {
              event: formatMessage(messages.event[data.event as Event]).toLowerCase(),
          })
        : ""

    return (
        <Node
            type={data.type}
            name={data.name}
            information={information}
            icon={Zap}
            handles={handles}
            selected={selected}
            error={error}
            slug={data.slug}
        ></Node>
    )
}
