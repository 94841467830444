import { ChipOwnProps, Palette } from "@mui/material"

export type Variant = ChipOwnProps["color"] | "neutral"
type PaletteColorType = "light" | "dark" | "main"

export const getBorderColorByVariant = (
    variant: Variant,
    palette: Palette,
    paletteColor: PaletteColorType = "dark"
) => {
    if (variant === "success") {
        return palette.success[paletteColor]
    } else if (variant === "warning") {
        return palette.warning[paletteColor]
    } else if (variant === "primary") {
        return palette.primary[paletteColor]
    } else if (variant === "error") {
        return palette.error[paletteColor]
    } else {
        return "#E9E9EB"
    }
}

export const getBackgroundByVariant = (
    variant: Variant,
    palette: Palette,
    paletteColor: PaletteColorType = "light"
) => {
    if (variant === "success") {
        return palette.success[paletteColor]
    } else if (variant === "warning") {
        return palette.warning[paletteColor]
    } else if (variant === "primary") {
        return palette.primary[paletteColor]
    } else if (variant === "error") {
        return palette.error[paletteColor]
    } else {
        return "linear-gradient(0deg, #F4F4F5, #F4F4F5),linear-gradient(0deg, #E9E9EB, #E9E9EB)"
    }
}

export const getColorByVariant = (variant: Variant, palette: Palette, paletteColor: PaletteColorType = "main") => {
    if (variant === "success") {
        return palette.success[paletteColor]
    } else if (variant === "warning") {
        return palette.warning[paletteColor]
    } else if (variant === "primary") {
        return palette.primary[paletteColor]
    } else if (variant === "error") {
        return palette.error[paletteColor]
    } else {
        return palette.grey[900]
    }
}
