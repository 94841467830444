/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
    DataGridPremium,
    GridColDef,
    GridPaginationModel,
    GridRenderCellParams,
    GridValueGetterParams,
} from "@mui/x-data-grid-premium"
import { useState } from "react"
import { FormattedDate, FormattedNumber, defineMessages } from "react-intl"

import { commonMessages } from "~/common-messages"
import { Card, Loader, SafeFormattedMessage, StatusChip } from "~/components"
import { PartyPaymentMethodDetails } from "~/domains/payment/payment-method-details/components"
import { useGetPaymentsByOrganizationQuery } from "~/domains/payment/payment/api/paymentApi"
import { PaymentLinkedTransaction } from "~/domains/payment/payment/components"
import { PaymentUI } from "~/domains/payment/payment/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "~/utils"

const messages = defineMessages({
    myPaymentMethod: {
        id: "payment.document.paymentDetails.buyerPaymentMethod",
        defaultMessage: "My payment method",
    },
    vendorPaymentMethod: {
        id: "payment.document.paymentDetails.vendorPaymentMethod",
        defaultMessage: "Vendor payment method",
    },
})

const getRowId = (row: PaymentUI) => row?.id ?? ""

const renderPaymentMethodCell = ({ value }: GridRenderCellParams) => {
    if (!value) {
        return "-"
    }

    return <PartyPaymentMethodDetails mode="inline" paymentMethodDetailsId={value} />
}

const columns: GridColDef[] = [
    {
        field: "id",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.transaction} />,
        renderCell: ({ row: { id, paymentMetadata } }: GridRenderCellParams) => (
            <PaymentLinkedTransaction paymentId={id} transactionNumber={paymentMetadata?.invoiceNumber} />
        ),
    },
    {
        field: "amountSentData",
        minWidth: 120,
        flex: 1,
        valueGetter: ({ row }: GridValueGetterParams<PaymentUI>) => [
            row.amountSentData?.amount,
            row.amountSentData?.currency,
        ],
        renderHeader: () => <SafeFormattedMessage {...commonMessages.totalAmount} />,
        renderCell: ({ value: [amount, currency] }: GridRenderCellParams) =>
            !amount ? "-" : <FormattedNumber value={amount} style="currency" currency={currency} />,
    },
    {
        field: "status",
        minWidth: 100,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.status} />,
        renderCell: ({ value }: GridRenderCellParams) =>
            value ? <StatusChip status={value}>{value}</StatusChip> : "-",
    },
    {
        field: "dateCreated",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.createdDate} />,
        renderCell: ({ row: { dateCreated } }: GridRenderCellParams<PaymentUI>) =>
            dateCreated ? (
                <FormattedDate value={dateCreated ?? ""} day="numeric" month="numeric" year="numeric" />
            ) : (
                "-"
            ),
    },
    {
        field: "dateScheduled",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...commonMessages.dateScheduled} />,
        renderCell: ({ row: { dateScheduled } }: GridRenderCellParams<PaymentUI>) =>
            dateScheduled ? (
                <FormattedDate value={dateScheduled ?? ""} day="numeric" month="numeric" year="numeric" />
            ) : (
                "-"
            ),
    },
    {
        field: "originCashId",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...messages.myPaymentMethod} />,
        renderCell: renderPaymentMethodCell,
    },
    {
        field: "destinationCashId",
        minWidth: 120,
        flex: 1,
        renderHeader: () => <SafeFormattedMessage {...messages.vendorPaymentMethod} />,
        renderCell: renderPaymentMethodCell,
    },
]

export const PaymentsList = () => {
    const organizationId = useAppSelector(selectCurrentOrganizationId)
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    })
    const { page, pageSize } = paginationModel
    const { data, isFetching, isLoading } = useGetPaymentsByOrganizationQuery(
        { organizationId, page: page + 1, pageSize },
        {
            skip: !organizationId,
        }
    )
    const payments = data?.items ?? []

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPaginationModel(model)
    }

    if (!organizationId) {
        return null
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <Card>
            <DataGridPremium
                columns={columns}
                rows={payments}
                getRowId={getRowId}
                rowCount={data?.total ?? 0}
                loading={isFetching}
                rowSelection={false}
                autoHeight
                pagination
                paginationMode="server"
                disableRowSelectionOnClick
                pageSizeOptions={PAGE_SIZE_OPTIONS}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
            />
        </Card>
    )
}
