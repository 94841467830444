import { styled } from "@mui/material"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"

import { TooltipWhite } from "~/components"
import { CrossIcon } from "~/components/Icons"
import { FromRecommandationValues, SelectedTagI } from "~/domains/analytics/tags/types"

import { TagTooltip } from "./TagTooltip"

interface Props {
    tag: SelectedTagI
    removeTag?: (tag: SelectedTagI) => void
    setTagToEdit?: React.Dispatch<React.SetStateAction<SelectedTagI | null>>
}

export const TagContainer = styled("div")<{ hasRatio: boolean }>(({ hasRatio }) => ({
    display: "flex",
    gap: "var(--spacing-xxs)",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "var(--color-grey-lighter)",
    padding: "2px 6px 2px 9px",
    borderRadius: "var(--border-radius-sx)",
    fontSize: "var(--font-size-md)",
    fontWeight: "var(--font-weight-lg)",
    lineHeight: "22px",
    cursor: hasRatio ? "pointer" : "default",
}))

const AutoContainer = styled("span")({
    textTransform: "lowercase",
    fontVariant: "small-caps",
    fontWeight: 800,
    backgroundColor: "var(--color-bg-input)",
    lineHeight: "1em",
    padding: "0 2px 1px",
    borderRadius: "var(--border-radius-xxs)",
})

const RatioContainer = styled("span")({
    color: "var(--color-grey-light)",
})

export const Tag: React.FC<Props> = ({ tag, removeTag, setTagToEdit }) => {
    const { formatNumber } = useIntl()

    const onCrossClick = useCallback(
        (event: React.MouseEvent<SVGSVGElement>) => {
            event.stopPropagation()
            if (removeTag) {
                removeTag(tag)
            }
        },
        [removeTag, tag]
    )

    const onTagClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            if (tag.ratio && setTagToEdit) {
                event.stopPropagation()
                setTagToEdit(tag)
            }
        },
        [tag, setTagToEdit]
    )

    return (
        <TooltipWhite title={<TagTooltip tag={tag} />} placement="top" arrow>
            <TagContainer hasRatio={typeof tag.ratio === "number"} onClick={onTagClick}>
                {tag.fromRecommandation === FromRecommandationValues.AUTO && <AutoContainer>AUTO</AutoContainer>}
                <span>{tag.name}</span>
                {typeof tag.ratio === "number" ? (
                    <RatioContainer>{` (${formatNumber(tag.ratio, {
                        style: "percent",
                        maximumFractionDigits: 0,
                    })})`}</RatioContainer>
                ) : null}
                {removeTag && <CrossIcon size={18} color="var(--color-grey)" onClick={onCrossClick} />}
            </TagContainer>
        </TooltipWhite>
    )
}
