import { useCallback, useState } from "react"
import { defineMessage, useIntl } from "react-intl"

import { FetchErrorType, useWorkflowsApi } from "~/domains/orchestration/flows-v0/api/workflowsApi"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectPurchaseRequestWorkflowCheck, workflowActions } from "~/store/workflow/workflowSlice"
import { OrganizationId } from "~/types"
import { getResultErrorValue, isResultSuccess } from "~/types/Result"

const errorMessage = defineMessage({
    id: "workflow.purchaseRequest.errorFetchingCheck",
    defaultMessage: "Unable to fetch workflow check status",
})

export const useFetchPurchaseRequestWorkflows = (
    organizationId: OrganizationId | undefined,
    purchaseRequestId: string | undefined
) => {
    const dispatch = useAppDispatch()
    const { formatMessage } = useIntl()
    const workflowsApi = useWorkflowsApi()
    const purchaseRequestWorkflowCheck = useAppSelector(selectPurchaseRequestWorkflowCheck)
    const purchaseRequestChecks = purchaseRequestId ? purchaseRequestWorkflowCheck[purchaseRequestId] : undefined

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const fetchPurchaseRequestChecks = useCallback(
        async (retry: number = 0) => {
            if (purchaseRequestId && organizationId) {
                setLoading(true)
                setError(undefined)
                const result = await workflowsApi.getPurchaseRequestChecks(organizationId, purchaseRequestId)
                if (isResultSuccess(result)) {
                    dispatch(
                        workflowActions.setPurchaseRequestWorkflowChecks({ purchaseRequestId, check: result.result })
                    )
                } else {
                    const error = getResultErrorValue(result)
                    if (error.type === FetchErrorType.HTTP_REQUEST_ERROR && error.code === 404 && retry < 5) {
                        window.setTimeout(() => fetchPurchaseRequestChecks(retry + 1), 200)
                    } else {
                        setError(formatMessage(errorMessage))
                    }
                }
                setLoading(false)
            }
        },
        [dispatch, workflowsApi, organizationId]
    )

    return { purchaseRequestChecks, fetchPurchaseRequestChecks, loading, error }
}
