import { IconButton } from "@mui/material"
import { forwardRef } from "react"
import { ChevronDown, MoreVertical } from "react-feather"

import { Button, ButtonType } from "~/components"

interface ActionsMenuButtonProps {
    handleClick: (event: React.MouseEvent<HTMLButtonElement & MouseEvent>) => void
    disabled?: boolean
    labelButton?: string
    buttonType?: ButtonType
    icon?: React.ReactNode
    isActionIcon?: boolean
    size?: "small" | "medium"
}

export const ActionsMenuButton = forwardRef<HTMLButtonElement, ActionsMenuButtonProps>(
    ({
        handleClick,
        disabled,
        labelButton,
        buttonType,
        icon = <MoreVertical size={18} className="svg-grey" />,
        isActionIcon,
        size = "medium",
    }) => {
        if (labelButton) {
            return (
                <Button type={buttonType} onClick={handleClick} disabled={disabled} size={size}>
                    {labelButton}
                    <ChevronDown size={18} className="svg-white" />
                </Button>
            )
        }

        return isActionIcon ? (
            <IconButton disabled={disabled} onClick={handleClick} size={size}>
                {icon}
            </IconButton>
        ) : (
            <Button type={buttonType} onClick={handleClick} disabled={disabled} size={size}>
                {icon}
            </Button>
        )
    }
)

ActionsMenuButton.displayName = "ActionsMenuButton"
