import React from "react"
import { useIntl } from "react-intl"

import { CurrencyCodes, isKnownCurrencyCode } from "~/types"

interface Props {
    amount?: number | null
    currency?: CurrencyCodes
}

export const AmountInList: React.FC<Props> = ({ amount, currency }) => {
    const { formatNumber } = useIntl()

    const value = formatNumber(amount ?? 0, {
        style: "currency",
        currency: currency && isKnownCurrencyCode(currency) ? currency : CurrencyCodes.EUR,
    })

    return (
        <span className="boxed-number boxed-number-grey" title={value}>
            {value}
        </span>
    )
}
