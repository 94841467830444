import { Checkbox, FormControlLabel, Stack, styled } from "@mui/material"
import { FC, PropsWithChildren, ReactNode, SyntheticEvent } from "react"

interface CheckListItemProps extends PropsWithChildren {
    label: ReactNode
    checked: boolean
    onChange: (event: SyntheticEvent, checked: boolean) => void
    preventToggleOnClick?: boolean
}

const StyledFormControlLabel = styled(FormControlLabel)({
    width: "100%",
    display: "flex",
    "& > div": {
        flex: "1 1 auto",
    },
})

const CheckItem = styled("span", { shouldForwardProp: (prop) => prop !== "checked" })<{ checked: boolean }>(
    ({ checked }) => ({
        flex: "1 1 auto",
        position: "relative",
        textDecoration: checked ? "line-through" : "none",
    })
)

const stopPropagationAndPreventDefault = (event: SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()
}

export const CheckListItem: FC<CheckListItemProps> = ({ onChange, label, checked, preventToggleOnClick, children }) => {
    return (
        <StyledFormControlLabel
            onChange={onChange}
            control={<Checkbox checked={checked} />}
            slotProps={{
                typography: {
                    component: "div",
                },
            }}
            label={
                <Stack
                    direction="row"
                    gap={1}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    onClick={preventToggleOnClick ? stopPropagationAndPreventDefault : undefined}
                >
                    <CheckItem checked={checked}>{label}</CheckItem>
                    {children}
                </Stack>
            }
        />
    )
}
