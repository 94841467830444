import { FC, useEffect, useState } from "react"

import { usePatchUpdateInvoiceMutation } from "~/domains/transactions/invoices-v1/api/invoiceApi"
import { Payment } from "~/domains/transactions/invoices/components/Payment"
import { InvoiceI } from "~/types"
import { ImportingInvoiceI } from "~/types"

const DEBOUNCE_TIME = 500

interface InvoicePaymentEditProps {
    invoice: InvoiceI
}

export const InvoicePaymentEdit: FC<InvoicePaymentEditProps> = ({ invoice }) => {
    const [patchUpdateInvoice] = usePatchUpdateInvoiceMutation()
    const [paymentDetails, setPaymentDetails] = useState(invoice.paymentDetails)
    const [debouncedPaymentDetails, setDebouncedPaymentDetails] = useState(paymentDetails)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (debouncedPaymentDetails !== invoice.paymentDetails) {
                patchUpdateInvoice({ paymentDetails: debouncedPaymentDetails, id: invoice.id })
            }
        }, DEBOUNCE_TIME)

        return () => clearTimeout(timeoutId)
    }, [debouncedPaymentDetails, paymentDetails, patchUpdateInvoice, invoice.id])

    const handleUpdateInvoicePayment = (payment: Partial<ImportingInvoiceI>) => {
        setPaymentDetails(payment)
        setDebouncedPaymentDetails(payment)
    }

    return <Payment dataLoaded={true} paymentDetails={paymentDetails} updateData={handleUpdateInvoicePayment} />
}
